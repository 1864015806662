import React from 'react';

import { IconButton, StyleProps } from '@chakra-ui/react';
import { FaSyncAlt } from 'react-icons/fa';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import { useCrudListContext } from './CrudListContext';

export interface CrudListRefreshButtonProps extends StyleProps {}

export const CrudListRefreshButton: React.FC<CrudListRefreshButtonProps> = ({
  ...styleProps
}) => {
  const { t } = useTranslation();
  const { isLoading, refreshList } = useCrudListContext();

  return (
    <IconButton
      aria-label={t('common:refresh')}
      icon={<FaSyncAlt />}
      isDisabled={isLoading}
      onClick={refreshList}
      {...styleProps}
    />
  );
};
