import React, { useCallback, useEffect, useMemo } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  Link,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { DataType, Destination, Id } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { DropdownButtonAction, DropdownButton } from '@m3ter-com/ui-components';

import { OtherListIds } from '@/types/lists';

import { getCrudRouteName, CrudRouteType } from '@/routes/crud';
import { NotificationDefinition } from '@/store/store';
import { addEntityLink } from '@/store/utils/linkEntity';
import {
  loadNotificationDestinations,
  reset,
  selectDestinationIds,
  selectError,
  selectIsLoading,
} from '@/store/features/events-notifications/notifications';
import { NamedLink } from '@/components/common/navigation/NamedLink';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch';
import { ActiveStatusBadge } from '@/components/common/data/ActiveStatusBadge';
import { EntityMultiSelectModal } from '@/components/common/data/EntitySelectModal';

const addDestinationsModalSearchFields = ['name', 'code', 'url'];

interface NotificationRuleDestinationsProps {
  notificationRuleId: Id;
}

export const NotificationRuleDestinations: React.FC<
  NotificationRuleDestinationsProps
> = ({ notificationRuleId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const destinationIds = useSelector(selectDestinationIds);
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);

  useEffect(() => {
    dispatch(loadNotificationDestinations(notificationRuleId));

    return () => {
      dispatch(reset());
    };
  }, [notificationRuleId, dispatch]);

  const listColumns = useMemo<Array<ColumnDefinition<Destination>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (item) => (
          <Link
            as={CrudDetailsLink}
            dataType={DataType.Destination}
            id={item.id}
          >
            {item.name}
          </Link>
        ),
      },
      {
        id: 'active',
        header: t('forms:labels.status'),
        accessor: (item) => (
          <ActiveStatusBadge
            active={item.active}
            inactiveLabel={t('common:inactive')}
            ml={0}
          />
        ),
      },
      {
        id: 'code',
        header: t('forms:labels.code'),
        accessor: 'code',
      },
      {
        id: 'url',
        accessor: 'url',
        header: t('forms:labels.url'),
      },
      {
        id: 'description',
        accessor: 'description',
        header: t('forms:labels.description'),
      },
    ],
    [t]
  );

  const {
    isOpen: isAddDestinationsModalOpen,
    onOpen: onAddDestinationsModalOpen,
    onClose: onAddDestinationsModalClose,
  } = useDisclosure();

  const actions = useMemo<Array<DropdownButtonAction>>(
    () => [
      {
        key: 'add-destination-link',
        label: t('features:destinations.linkDestination'),
        onClick: onAddDestinationsModalOpen,
      },
      {
        key: 'create-destination',
        label: t('forms:buttons.createEntity', {
          entityName: t('common:destination'),
        }),
        as: NamedLink,
        name: getCrudRouteName(DataType.Destination, CrudRouteType.Create),
      },
    ],
    [onAddDestinationsModalOpen, t]
  );

  const addDestinationsModalColumns = useMemo<
    Array<ColumnDefinition<Destination>>
  >(
    () => [
      { id: 'name', accessor: 'name', header: t('forms:labels.name') },
      { id: 'code', accessor: 'code', header: t('forms:labels.code') },
      { id: 'url', accessor: 'url', header: t('forms:labels.url') },
    ],
    [t]
  );

  const onNotificationRuleLinkSuccess = useMemo<NotificationDefinition>(
    () => ({
      type: 'success',
      message: t('notifications:notificationRuleLinkedSuccess'),
      removeAfter: 5000,
    }),
    [t]
  );

  const linkedDestinationIds = useMemo(
    () => destinationIds ?? [],
    [destinationIds]
  );

  const params = useMemo(
    () => ({ ids: linkedDestinationIds }),
    [linkedDestinationIds]
  );

  const isDestinationDisabled = useCallback(
    (destination: Destination) =>
      linkedDestinationIds.some((id) => destination.id === id),
    [linkedDestinationIds]
  );

  const onDestinationSelect = useCallback(
    (ids: Array<Id>) => {
      const idsToLink = ids.filter((id) => !linkedDestinationIds.includes(id));
      onAddDestinationsModalClose();
      if (idsToLink.length > 0) {
        dispatch(
          addEntityLink(
            DataType.NotificationRule,
            notificationRuleId,
            DataType.Destination,
            idsToLink,
            onNotificationRuleLinkSuccess
          )
        );
      }
    },
    [
      dispatch,
      notificationRuleId,
      onAddDestinationsModalClose,
      onNotificationRuleLinkSuccess,
      linkedDestinationIds,
    ]
  );

  return (
    <Card>
      <CardHeader>
        <Flex justify="space-between" alignItems="center">
          <Heading size="md">{t('features:integrations.destinations')}</Heading>
          <DropdownButton actions={actions} />
        </Flex>
      </CardHeader>
      <CardBody>
        <LoadingErrorContentSwitch
          isLoading={isLoading}
          error={error}
          showContentOnError
        >
          <CrudList<Destination>
            columns={listColumns}
            listId={OtherListIds.LinkedDestinations}
            dataType={DataType.Destination}
            params={params}
          />
        </LoadingErrorContentSwitch>
        <EntityMultiSelectModal<Destination>
          columns={addDestinationsModalColumns}
          dataType={DataType.Destination}
          isItemDisabled={isDestinationDisabled}
          isOpen={isAddDestinationsModalOpen}
          onClose={onAddDestinationsModalClose}
          onConfirm={onDestinationSelect}
          searchFields={addDestinationsModalSearchFields}
          selected={linkedDestinationIds}
        />
      </CardBody>
    </Card>
  );
};
