import React, { ComponentProps } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { formatInTimeZone } from 'date-fns-tz';

import { shortDateFormatKey } from '@m3ter-com/console-core/utils';
import { DatePicker } from '@m3ter-com/ui-components';

import useDateFormatter from '@/hooks/util/useDateFormatter';

export interface FormDatePickerProps
  extends Omit<ComponentProps<typeof DatePicker>, 'value' | 'onChange'> {
  name: string;
  stripTime?: boolean;
}

export const FormDatePicker: React.FC<FormDatePickerProps> = ({
  name,
  showTimeSelect,
  stripTime = false,
  ...props
}) => {
  const { control } = useFormContext();
  const { timeZone } = useDateFormatter();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <DatePicker
          value={value}
          onChange={(newDate: string | null) => {
            if (newDate && stripTime) {
              // Some API endpoints need just a date-string in the format `YYYY-MM-DD`.
              // the stripTime prop tells us this is a field for one of those APIs so we
              // format the ISO date-time string into that format.
              // We need to do this formatting in the org's timezone since the ISO string is
              // formatted to UTC and we need the date they selected in the org's timezone, not UTC.
              const timeStrippedDateString = formatInTimeZone(
                newDate,
                timeZone,
                shortDateFormatKey
              );
              onChange(timeStrippedDateString);
            } else {
              onChange(newDate);
            }
          }}
          showTimeSelect={showTimeSelect && !stripTime}
          timeZone={timeZone}
          {...props}
        />
      )}
    />
  );
};
