import React, { useCallback, useMemo, useState } from 'react';

import {
  Avatar,
  Badge,
  Box,
  Card,
  CardBody,
  Grid,
  Link,
} from '@chakra-ui/react';

import { DataType, User } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds } from '@/types/lists';

import useDateFormatter from '@/hooks/util/useDateFormatter';
import { ActiveStatusBadge } from '@/components/common/data/ActiveStatusBadge';
import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import { UserOverview } from '@/components/features/access/user/UserOverview';

const searchFields = ['firstName', 'lastName', 'email'];

export const UsersAdmin: React.FC = () => {
  const { t } = useTranslation();
  const { toLongDate } = useDateFormatter();

  const userColumns = useMemo<Array<ColumnDefinition<User>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: ({ firstName, lastName, id }: User) => {
          const fullName = `${firstName} ${lastName}`;

          return (
            <React.Fragment>
              <Avatar name={fullName} verticalAlign="middle" size="sm" mr={4} />
              <Link as={CrudDetailsLink} dataType={DataType.User} id={id}>
                {fullName}
              </Link>
            </React.Fragment>
          );
        },
      },
      {
        id: 'support-user',
        header: '',
        accessor: ({ supportUser }: User) =>
          supportUser && <Badge ml={2}>{t('common:supportUser')}</Badge>,
      },
      {
        id: 'firstName',
        header: t('forms:labels.firstName'),
        accessor: 'firstName',
        defaultHidden: true,
      },
      {
        id: 'lastName',
        header: t('forms:labels.lastName'),
        accessor: 'lastName',
        defaultHidden: true,
      },
      {
        id: 'email',
        header: t('forms:labels.emailAddress'),
        accessor: 'email',
      },
      {
        id: 'user-status',
        header: t('forms:labels.status'),
        accessor: ({ dtEndAccess }: User) => {
          const isUserActive =
            !dtEndAccess || new Date(dtEndAccess) > new Date(Date.now());
          return <ActiveStatusBadge ml={0} active={isUserActive} />;
        },
      },
      {
        id: 'access-end-date',
        header: t('forms:labels.accessEndDate'),
        accessor: ({ dtEndAccess }) =>
          dtEndAccess ? toLongDate(dtEndAccess) : '-',
      },
    ],
    [t, toLongDate]
  );

  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  const onSelectedUserChange = useCallback(
    (_newSelectedUserIds: Array<string>, newSelectedUsers: Array<User>) => {
      setSelectedUser(newSelectedUsers[0]);
    },
    []
  );
  const selectedUserIds = useMemo(
    () => (selectedUser ? [selectedUser.id] : []),
    [selectedUser]
  );

  return (
    <Grid gap={4} templateColumns="75% 1fr">
      <CrudList<User>
        columns={userColumns}
        dataType={DataType.User}
        listId={EntityRouteListIds.User}
        onSelectedItemsChange={onSelectedUserChange}
        searchFields={searchFields}
        selectedItems={selectedUserIds}
        selectionType="single"
      />
      <Card textAlign="center" h="fit-content">
        <CardBody>
          {selectedUser ? (
            <UserOverview user={selectedUser} />
          ) : (
            <Box py="100px">{t('features:users.selectUser')}</Box>
          )}
        </CardBody>
      </Card>
    </Grid>
  );
};
