import React, { PropsWithChildren } from 'react';

import { Grid, StackDivider, StyleProps, VStack } from '@chakra-ui/react';

// TODO: Update this and the FormField component so that we can ensure labels,
// help text, inputs etc are all aligned on each row at all times.
// https://m3ter.atlassian.net/browse/CON-545

interface FormMultiRowWrapperProps {
  hasFields: boolean;
  emptyContentMessage?: string;
}

interface FormMultiRowWGridrapperProps extends StyleProps {
  columnCount: number;
}

export const FormMultiRowGridWrapper: React.FC<
  PropsWithChildren<FormMultiRowWGridrapperProps>
> = ({ columnCount, children, ...styles }) => {
  return (
    <Grid
      gap={4}
      gridTemplateColumns={`repeat(${columnCount}, 1fr) 40px`}
      role="listitem"
      width="100%"
      {...styles}
    >
      {children}
    </Grid>
  );
};

export const FormMultiRowWrapper: React.FC<
  PropsWithChildren<FormMultiRowWrapperProps>
> = ({ hasFields, emptyContentMessage, children }) => {
  if (hasFields) {
    return (
      <VStack
        w="100%"
        role="list"
        alignItems="stretch"
        spacing={4}
        divider={<StackDivider />}
      >
        {children}
      </VStack>
    );
  }

  return emptyContentMessage ? <p>{emptyContentMessage}</p> : null;
};
