import React, { useMemo } from 'react';

import { StatementDefinition } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds } from '@/types/lists';

import { CrudList, ColumnDefinition } from '@/components/common/crud/CrudList';

const searchFields = ['name'];

export const StatementDefinitionsListRoute: React.FC = () => {
  const { t } = useTranslation();

  const columns = useMemo<Array<ColumnDefinition<StatementDefinition>>>(
    () => [
      { id: 'name', header: t('forms:labels.name'), accessor: 'name' },
      {
        id: 'measures',
        header: t('features:statements.measures'),
        accessor: ({ measures }) => measures.length,
      },
      {
        id: 'dimensions',
        header: t('features:statements.dimensions'),
        accessor: ({ dimensions }) => dimensions.length,
      },
    ],
    [t]
  );

  return (
    <CrudList<StatementDefinition>
      listId={EntityRouteListIds.StatementDefinition}
      columns={columns}
      searchFields={searchFields}
    />
  );
};
