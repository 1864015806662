import { call, put, select, StrictEffect } from 'redux-saga/effects';

import {
  DataType,
  AnalyticsJob,
  AnalyticsJobStatus,
  AnalyticsJobType,
  AnalyticsJobTypeToResponseData,
} from '@m3ter-com/m3ter-api';

import type { AnyAction } from 'redux';
import { ReportType } from '@/types/data';

import { extractAnalyticsJobError } from '@/util/error';
import {
  buildBillingBasedRecurringRevenueReportRequestBody,
  buildMonthOnMonthReportRequestBody,
  buildRecurringRevenueReportRequestBody,
  buildPrepaymentsStatusReportRequestBody,
  buildTotalContractValueReportRequestBody,
  ReportRequestBodyFactory,
} from '@/util/reports';
import { performDataAction } from '@/services/api';
import { takeLatestWithCancel } from '@/store/util.saga';
import {
  selectOrgTimezone,
  selectCurrentOrgId,
} from '@/store/app/bootstrap/bootstrap';
import { getAnalyticsResult } from '@/store/features/analytics/analyticsJobs.saga';

import {
  loadReportData,
  loadReportDataFailure,
  loadReportDataSuccess,
  LoadReportDataAction,
  resetReportState,
  ResetReportStateAction,
} from './reports';

type ReportRequestBodyFactoryMap = {
  [RT in ReportType]: ReportRequestBodyFactory<RT>;
};

const reportRequestBodyFactoryMap: ReportRequestBodyFactoryMap = {
  [AnalyticsJobType.BillingBasedRecurringRevenueReport]:
    buildBillingBasedRecurringRevenueReportRequestBody,
  [AnalyticsJobType.MonthOnMonthReport]: buildMonthOnMonthReportRequestBody,
  [AnalyticsJobType.RecurringRevenueReport]:
    buildRecurringRevenueReportRequestBody,
  [AnalyticsJobType.PrepaymentsStatusReport]:
    buildPrepaymentsStatusReportRequestBody,
  [AnalyticsJobType.TotalContractValueReport]:
    buildTotalContractValueReportRequestBody,
};

export function* loadReportExportUrlSaga(
  analyticsJobId: string
): Generator<StrictEffect, string | undefined, any> {
  try {
    const organizationId = yield select(selectCurrentOrgId);
    const csvAnalyticsJob: AnalyticsJob<ReportType> = yield call(
      performDataAction,
      DataType.AnalyticsJob,
      'getCsvDownloadUrl',
      { organizationId, id: analyticsJobId }
    );
    return csvAnalyticsJob.downloadUrl;
  } catch (e) {
    return undefined;
  }
}

export function* loadReportDataSaga<RT extends ReportType>(
  action: LoadReportDataAction<RT>
): Generator<StrictEffect, void, any> {
  try {
    const orgTimezone: string = yield select(selectOrgTimezone);
    const requestBodyFactory: ReportRequestBodyFactory<RT> =
      reportRequestBodyFactoryMap[action.payload.reportType];
    const requestBody = yield call(
      requestBodyFactory,
      action.payload.filterState,
      orgTimezone
    );

    const analyticsJob: AnalyticsJob<ReportType> = yield call(
      getAnalyticsResult,
      action.payload.reportType,
      requestBody
    );
    if (
      analyticsJob.status !== AnalyticsJobStatus.Succeeded ||
      !analyticsJob.data
    ) {
      throw new Error(
        analyticsJob.failedReason || 'Failed to load report data.'
      );
    }

    let exportUrl: string | undefined;
    if (action.payload.loadExportUrl) {
      exportUrl = yield call(loadReportExportUrlSaga, analyticsJob.id);
    }

    yield put(
      loadReportDataSuccess(
        action.payload.reportType,
        analyticsJob.data as AnalyticsJobTypeToResponseData[RT],
        exportUrl
      )
    );
  } catch (error) {
    let exportUrl: string | undefined;
    const { analyticsJobId, canLoadExportUrl, errorMessage } =
      extractAnalyticsJobError(error);
    if (analyticsJobId && canLoadExportUrl) {
      // A 413 response is returned when the results payload would be too large.
      // In this case, the analytics job API can still generate a CSV so we should try
      // to load the export URL at this point if we need it.
      exportUrl = yield call(loadReportExportUrlSaga, analyticsJobId);
    }

    yield put(
      loadReportDataFailure(
        action.payload.reportType,
        { code: undefined, message: errorMessage },
        exportUrl
      )
    );
  }
}

export default function* monthOnMonthSaga() {
  // With the loadReportData action, we want to do a few funky things.
  // 1. We want to only allow one instance of the loadReportDataSaga to be
  // running at any one time, per ReportType.
  // E.g. We want to be able to load the MonthOnMonthReport data at the same time
  // as loading the TotalContractValueReport data, but if another loadReportData
  // action is dispatched for the MonthOnMonthReport, we want the saga that is polling
  // for that data to be cancelled and a new one to be kicked off.
  // 2. We want any instance of the loadReportDataSaga to be cancelled if a resetReportState
  // action with a matching ReportType is dispatched.
  //
  // To do all of this, we use the takeLatestWithCancel effect once per ReportType.
  yield takeLatestWithCancel(
    (action: AnyAction) =>
      action.type === loadReportData.type &&
      (action as LoadReportDataAction<ReportType>).payload.reportType ===
        AnalyticsJobType.BillingBasedRecurringRevenueReport,
    (action: AnyAction) =>
      action.type === resetReportState.type &&
      (action as ResetReportStateAction<ReportType>).payload.reportType ===
        AnalyticsJobType.BillingBasedRecurringRevenueReport,
    loadReportDataSaga
  );
  yield takeLatestWithCancel(
    (action: AnyAction) =>
      action.type === loadReportData.type &&
      (action as LoadReportDataAction<ReportType>).payload.reportType ===
        AnalyticsJobType.MonthOnMonthReport,
    (action: AnyAction) =>
      action.type === resetReportState.type &&
      (action as ResetReportStateAction<ReportType>).payload.reportType ===
        AnalyticsJobType.MonthOnMonthReport,
    loadReportDataSaga
  );
  yield takeLatestWithCancel(
    (action: AnyAction) =>
      action.type === loadReportData.type &&
      (action as LoadReportDataAction<ReportType>).payload.reportType ===
        AnalyticsJobType.RecurringRevenueReport,
    (action: AnyAction) =>
      action.type === resetReportState.type &&
      (action as ResetReportStateAction<ReportType>).payload.reportType ===
        AnalyticsJobType.RecurringRevenueReport,
    loadReportDataSaga
  );
  yield takeLatestWithCancel(
    (action: AnyAction) =>
      action.type === loadReportData.type &&
      (action as LoadReportDataAction<ReportType>).payload.reportType ===
        AnalyticsJobType.PrepaymentsStatusReport,
    (action: AnyAction) =>
      action.type === resetReportState.type &&
      (action as ResetReportStateAction<ReportType>).payload.reportType ===
        AnalyticsJobType.PrepaymentsStatusReport,
    loadReportDataSaga
  );
  yield takeLatestWithCancel(
    (action: AnyAction) =>
      action.type === loadReportData.type &&
      (action as LoadReportDataAction<ReportType>).payload.reportType ===
        AnalyticsJobType.TotalContractValueReport,
    (action: AnyAction) =>
      action.type === resetReportState.type &&
      (action as ResetReportStateAction<ReportType>).payload.reportType ===
        AnalyticsJobType.TotalContractValueReport,
    loadReportDataSaga
  );
}
