import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DataType,
  Aggregation,
  CompoundAggregation,
  Id,
  Counter,
  Meter,
  Plan,
  PlanTemplate,
} from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

import { createSelectById } from '@/store/data/data';

export interface PricingDataState {
  isLoading: boolean;
  aggregationId?: Id;
  compoundAggregationId?: Id;
  counterId?: Id;
  planId?: Id;
  planTemplateId?: Id;
  meterId?: Id;
  error?: AppError;
}

interface LoadPricingRelatedDataPayload {
  aggregationId?: Id;
  compoundAggregationId?: Id;
  counterId?: Id;
  planId?: Id;
  planTemplateId?: Id;
}

export type LoadPricingRelatedDataAction =
  PayloadAction<LoadPricingRelatedDataPayload>;

interface LoadPricingRelatedDataSuccessPayload {
  aggregationId?: Id;
  compoundAggregationId?: Id;
  counterId?: Id;
  meterId?: Id;
  planTemplateId?: Id;
  planId?: Id;
}

export type LoadPricingRelatedDataSuccessAction =
  PayloadAction<LoadPricingRelatedDataSuccessPayload>;

export type LoadPricingRelatedDataFailureAction = PayloadAction<
  void,
  string,
  never,
  AppError
>;

const name = 'features/pricing/pricingData';

const initialState: PricingDataState = {
  isLoading: false,
};

const pricingDataSlice = createSlice({
  name,
  initialState,
  reducers: {
    loadPricingRelatedData: {
      reducer: (
        _state: PricingDataState,
        _action: LoadPricingRelatedDataAction
      ) => ({
        ...initialState,
        isLoading: true,
      }),
      prepare: (
        aggregationId?: Id,
        compoundAggregationId?: Id,
        counterId?: Id,
        planId?: Id,
        planTemplateId?: Id
      ) => ({
        payload: {
          aggregationId,
          compoundAggregationId,
          counterId,
          planId,
          planTemplateId,
        },
      }),
    },
    loadPricingRelatedDataSuccess: {
      reducer: (
        state: PricingDataState,
        action: LoadPricingRelatedDataSuccessAction
      ) => {
        state.isLoading = false;
        state.aggregationId = action.payload.aggregationId;
        state.compoundAggregationId = action.payload.compoundAggregationId;
        state.meterId = action.payload.meterId;
        state.counterId = action.payload.counterId;
        state.planId = action.payload.planId;
        state.planTemplateId = action.payload.planTemplateId;
      },
      prepare: (
        aggregationId?: Id,
        compoundAggregationId?: Id,
        meterId?: Id,
        counterId?: Id,
        planId?: Id,
        planTemplateId?: Id
      ) => ({
        payload: {
          aggregationId,
          compoundAggregationId,
          meterId,
          counterId,
          planId,
          planTemplateId,
        },
      }),
    },
    loadPricingRelatedDataFailure: {
      reducer: (
        state: PricingDataState,
        action: LoadPricingRelatedDataFailureAction
      ) => {
        state.isLoading = false;
        state.error = action.error;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    reset: () => initialState,
  },
});

// Export actions.
export const {
  loadPricingRelatedData,
  loadPricingRelatedDataSuccess,
  loadPricingRelatedDataFailure,
  reset,
} = pricingDataSlice.actions;

// Selectors

const selectPricingDataState = (state: {
  features: { pricing: { pricingData: PricingDataState } };
}): PricingDataState => state.features.pricing.pricingData;

export const selectIsLoading = createSelector(
  selectPricingDataState,
  (state) => state.isLoading
);

export const selectPlanId = createSelector(
  selectPricingDataState,
  (state) => state.planId
);

export const selectPlan = createSelector(
  selectPlanId,
  createSelectById<Plan>(DataType.Plan),
  (planId, selectById) => selectById(planId)
);

export const selectPlanTemplateId = createSelector(
  selectPricingDataState,
  (state) => state.planTemplateId
);

export const selectPlanTemplate = createSelector(
  selectPlanTemplateId,
  createSelectById<PlanTemplate>(DataType.PlanTemplate),
  (planTemplateId, selectById) => selectById(planTemplateId)
);

export const selectAggregationId = createSelector(
  selectPricingDataState,
  (state) => state.aggregationId
);

export const selectAggregation = createSelector(
  selectAggregationId,
  createSelectById<Aggregation>(DataType.Aggregation),
  (aggregationId, selectById) => selectById(aggregationId)
);

export const selectCompoundAggregationId = createSelector(
  selectPricingDataState,
  (state) => state.compoundAggregationId
);

export const selectCompoundAggregation = createSelector(
  selectCompoundAggregationId,
  createSelectById<CompoundAggregation>(DataType.CompoundAggregation),
  (compoundAggregationId, selectById) => selectById(compoundAggregationId)
);

export const selectMeterId = createSelector(
  selectPricingDataState,
  (state) => state.meterId
);

export const selectMeter = createSelector(
  selectMeterId,
  createSelectById<Meter>(DataType.Meter),
  (meterId, selectById) => selectById(meterId)
);

export const selectItemCounterId = createSelector(
  selectPricingDataState,
  (state) => state.counterId
);

export const selectItemCounter = createSelector(
  selectItemCounterId,
  createSelectById<Counter>(DataType.Counter),
  (counterId, selectById) => selectById(counterId)
);

export const selectError = createSelector(
  selectPricingDataState,
  (state) => state.error
);

export default pricingDataSlice.reducer;
