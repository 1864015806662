import React, { useState, useCallback, PropsWithChildren } from 'react';

import copy from 'copy-to-clipboard';

import { Button } from '../Button';

export interface CopyToClipboardProps {
  value: string;
  copyText?: string;
  copiedText?: string;
}

export const CopyToClipboard: React.FC<
  PropsWithChildren<CopyToClipboardProps>
> = ({ value, copyText = 'Copy', copiedText = 'Copied', children }) => {
  const [copied, setCopied] = useState(false);

  const onCopy = useCallback(() => {
    copy(value, {
      format: 'text/plain',
      onCopy: () => {
        setCopied(true);
      },
    });
  }, [value]);

  return (
    <React.Fragment>
      {children}
      <Button variant="link" size="sm" ml={2} onClick={onCopy}>
        {copied ? copiedText : copyText}
      </Button>
    </React.Fragment>
  );
};
