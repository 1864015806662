import React from 'react';

import { DataType, CompoundAggregation } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormInput,
  FormSection,
  FormSwitch,
  NameCodeFields,
} from '@m3ter-com/console-core/components';

import compoundAggregationSchema from '@/validation/compoundAggregation';
import { RoundingSelect } from '@/components/forms/RoundingSelect';
import {
  DataTypeFormEntitySelect,
  FormEntitySelect,
} from '@/components/forms/FormEntitySelect';
import { FormCustomFieldsEditorDefaults } from '@/components/forms/FormCustomFieldsEditorDefaults';

import { CompoundAggregationFormCalculationField } from './CompoundAggregationFormCalculationField';

export interface CompoundAggregationFormProps {
  onSave: (item: CompoundAggregation) => void;
  onCancel: () => void;
  isSaving?: boolean;
  isEdit?: boolean;
  initialValues?: Partial<CompoundAggregation>;
}

export const CompoundAggregationForm: React.FC<
  CompoundAggregationFormProps
> = ({
  onSave,
  onCancel,
  isSaving = false,
  isEdit = false,
  initialValues = {},
}) => {
  const { t } = useTranslation();
  const entityName = t('common:compoundAggregation');

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={compoundAggregationSchema}
    >
      <FormStack mb={4}>
        <FormSection heading={t('common:entityDetails', { entityName })}>
          <FormField
            name="productId"
            label={t('common:product')}
            control={
              FormEntitySelect as DataTypeFormEntitySelect<DataType.Product>
            }
            isClearable
            dataType={DataType.Product}
            placeholder={`(${t('common:global')})`}
            accessor="name"
            detailAccessor="code"
          />
          <NameCodeFields />
        </FormSection>
        <FormSection heading={t('forms:labels.entitySettings', { entityName })}>
          <CompoundAggregationFormCalculationField />
          <FormField
            isRequired
            name="unit"
            label={t('forms:labels.unit')}
            control={FormInput}
          />
          <FormField
            isRequired
            name="quantityPerUnit"
            label={t('forms:labels.quantityPerUnit')}
            control={FormInput}
            type="number"
          />
          <RoundingSelect />
          <FormField
            name="evaluateNullAggregations"
            label={t('forms:labels.evaluateNullAggregations')}
            control={FormSwitch}
            helpText={t('forms:helpText.evaluateNullAggregations')}
          />
        </FormSection>
        <FormCustomFieldsEditorDefaults
          dataType={DataType.CompoundAggregation}
        />
      </FormStack>
      <FormActions
        cancelText={t('common:cancel')}
        submitText={
          isEdit
            ? t('forms:buttons.updateEntity', { entityName })
            : t('forms:buttons.createEntity', { entityName })
        }
        isSaving={isSaving}
        onCancel={onCancel}
      />
    </Form>
  );
};
