import React, { useCallback } from 'react';

import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  forwardRef,
} from '@chakra-ui/react';

export interface InputProps extends ChakraInputProps {}

export const Input = forwardRef<InputProps, 'input'>(
  ({ type = 'text', ...inputProps }, ref) => {
    const onWheel = useCallback((event: React.WheelEvent<HTMLInputElement>) => {
      // Prevents the value of a number input being changed by scrolling
      // without effecting page-scrolling behaviour.
      (event.target as HTMLInputElement).blur();
    }, []);

    return (
      <ChakraInput ref={ref} onWheel={onWheel} type={type} {...inputProps} />
    );
  }
);

// Make Chakra treat this like its own Input.
// https://github.com/chakra-ui/chakra-ui/issues/2269
Input.id = 'Input';
