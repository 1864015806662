import React, { useCallback } from 'react';

import {
  Divider,
  Flex,
  HStack,
  Heading,
  Link,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Trans } from 'react-i18next';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, FormStack } from '@m3ter-com/ui-components';
import { Form, FormField, FormInput } from '@m3ter-com/console-core/components';
import { FederatedSignInFormValues } from '@m3ter-com/console-core/types';

import type { TFunction } from 'i18next';

import useAuth from '@/hooks/auth/useAuth';
import federatedSignInFormSchema from '@/validation/federatedSignInForm';
import { NamedLink } from '@/components/common/navigation/NamedLink';
import { AuthSubmitButton } from '@/components/features/auth/AuthSubmitButton';

const emptyObject = {};

export const FederatedSignInForm: React.FC = () => {
  const { t } = useTranslation();
  const { onFederatedSignIn, isLoading } = useAuth(true);

  const onSubmit = useCallback(
    (data: FederatedSignInFormValues) => {
      onFederatedSignIn(data.email);
    },
    [onFederatedSignIn]
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={emptyObject}
      validationSchema={federatedSignInFormSchema}
    >
      <FormStack spacing={4}>
        <Flex w="100%" align="center" justify="center">
          <VStack spacing={4} alignItems="stretch">
            <Heading size="md" textAlign="center">
              {t('features:auth.signInWithCorporateId')}
            </Heading>
            <Text whiteSpace="pre-wrap" textAlign="center">
              <Trans
                t={t as TFunction}
                i18nKey="features:auth.singleSignOnInfomation"
                components={{
                  ssoDocsLink: (
                    <Link
                      isExternal
                      href="https://www.m3ter.com/docs/guides/authenticating-with-the-platform/m3ter-single-sign-on"
                    />
                  ),
                }}
              />
            </Text>
          </VStack>
        </Flex>
        <FormField
          autoFocus
          isRequired
          name="email"
          type="email"
          label={t('forms:labels.emailAddress')}
          control={FormInput}
          placeholder={t('features:auth.emailPlaceholder')}
          helpText={t('forms:helpText.caseSensitiveEmailAddress')}
        />
        <AuthSubmitButton
          isLoading={isLoading}
          buttonText={t('features:auth.continue')}
        />
        <Stack w="100%" spacing={6}>
          <HStack>
            <Divider />
            <Text textStyle="sm" whiteSpace="nowrap">
              {t('features:auth.signInWith')}
            </Text>
            <Divider />
          </HStack>
          <Button w="100%" as={NamedLink} name="auth.sign-in">
            {t('features:auth.emailAndPassword')}
          </Button>
        </Stack>
      </FormStack>
    </Form>
  );
};
