import {
  mode,
  SystemStyleObject,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools';
import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const parts = ['wrapper', 'list', 'listItem'];

const baseStyleWrapper: SystemStyleObject = {
  position: 'relative',
};

const baseStyleList: SystemStyleFunction = (props) => ({
  position: 'absolute',
  width: '100%',
  mt: 1,
  py: 2,
  bg: 'white',
  boxShadow: mode('sm', 'dark-lg')(props),
  borderRadius: 'md',
  borderWidth: 1,
  maxHeight: 250,
  overflowY: 'scroll',
  zIndex: 1,
});

const baseStyleListItem: SystemStyleObject = {
  backgroundColor: 'inherit',
  color: 'black',
  py: 1,
  px: 4,
  '> span': {
    fontSize: 'sm',
    color: 'text-subtle-light',
    marginLeft: 2,
  },
  ':hover': {
    backgroundColor: 'misc-hover-light',
    '> span': {
      color: 'gray.100',
    },
  },
};

const combobox: ComponentMultiStyleConfig = {
  parts,
  baseStyle: (props) => ({
    wrapper: baseStyleWrapper,
    list: baseStyleList(props),
    listItem: baseStyleListItem,
  }),
};

export default combobox;
