import React, { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import {
  createIntegrationCredential,
  DataType,
  IntegrationConfigDestinationSchema,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormFileInput,
  FormSection,
} from '@m3ter-com/console-core/components';

import useAppMutation from '@/hooks/data/useAppMutation';
import useOrg from '@/hooks/data/crud/useOrg';
import { useCrudContext } from '@/components/common/crud/CrudContext';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import { IntegrationConfigParameterField } from '@/components/features/integrations/IntegrationConfigParameterField';

export interface IntegrationCredentialConfigureInternalContentProps {
  destinationSchema: IntegrationConfigDestinationSchema;
  name: string;
  onBack: () => void;
  onCancel: () => void;
}

const initialValues = {};

// For some integration destinations, we only need to render a form with a couple of fields for things like API
// keys / secrets. This component handles rendering those fields and creating the credential.
export const IntegrationCredentialConfigureInternalContent: React.FC<
  IntegrationCredentialConfigureInternalContentProps
> = ({ destinationSchema, name, onBack, onCancel }) => {
  const { t } = useTranslation();

  // These integration credentials use non-standard create endpoints (one for each destination) so we use a custom
  // API method here instead of using the standard `CrudCreate` functionality
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { currentOrgId: organizationId } = useOrg();
  const { basePath } = useCrudContext();
  const { error, isPending, mutate } = useAppMutation({
    mutationFn: createIntegrationCredential,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DataType.IntegrationCredential],
      });
      navigate(basePath);
    },
  });

  // This is temporarily needed until the endpoint which provides the form
  // field schemas is updated to return a new schema for file inputs.
  const renderPrivateKeyFileInput =
    destinationSchema.destination.toLowerCase() === 'netsuite';

  const onSubmit = useCallback(
    (credentialAuthParameters: Record<string, any>) => {
      mutate({
        body: {
          name,
          type: destinationSchema.authType,
          ...credentialAuthParameters,
        },
        destination: destinationSchema.destination,
        organizationId,
      });
    },
    [destinationSchema, mutate, name, organizationId]
  );

  if (!destinationSchema) {
    return null;
  }

  return (
    <React.Fragment>
      {!!error && <ErrorAlert error={error} />}
      <Form initialValues={initialValues} onSubmit={onSubmit}>
        <FormStack>
          <FormSection
            heading={`${destinationSchema.displayName} ${t(
              'features:integrations.connection'
            )}`}
          >
            {destinationSchema.authConfigOptions?.map((authParameterSchema) => (
              <IntegrationConfigParameterField
                key={authParameterSchema.name}
                name={authParameterSchema.name}
                schema={authParameterSchema}
              />
            ))}
            {renderPrivateKeyFileInput && (
              <FormField
                name="privateKey"
                label={t('features:integrations.uploadNetsuiteCredentials')}
                control={FormFileInput}
                accept=".pem"
              />
            )}
          </FormSection>
          <FormActions
            backText={t('common:back')}
            cancelText={t('common:cancel')}
            isSaving={isPending}
            onBack={onBack}
            onCancel={onCancel}
            submitText={t('forms:buttons.createEntity', {
              entityName: t('features:integrations.credential'),
            })}
          />
        </FormStack>
      </Form>
    </React.Fragment>
  );
};
