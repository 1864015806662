import React from 'react';

import { NamedRoute } from '@/types/routes';

import { PricingSegmentsEditorRoute } from '@/components/routes/pricing/editor/PricingSegmentsEditorRoute';

export default (): NamedRoute => ({
  path: 'segments',
  name: 'pricing.editor.segments',
  element: <PricingSegmentsEditorRoute />,
});
