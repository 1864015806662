import { useCallback, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';

import { DataType, Id, UserGroup } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { NotificationDefinition } from '@/store/store';
import {
  addEntityLink,
  removeEntityLink,
  selectIsLinkingEntity,
  selectIsUnlinkingEntity,
} from '@/store/utils/linkEntity';

import useUserGroupList from './useUserGroupList';

const useUserGroupControl = (userId: Id) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedUserGroupIds, setSelectedUserGroupIds] = useState<Array<Id>>(
    []
  );
  const {
    isOpen: isUserGroupModalOpen,
    onClose: onCloseUserGroupModal,
    onOpen: onOpenUserGroupModal,
  } = useDisclosure();

  const { userGroups } = useUserGroupList(userId);

  const isUserGroupDisabled = useCallback(
    (item: UserGroup) => userGroups.some((group) => group.id === item.id),
    [userGroups]
  );

  const userAddedToUserGroupsSuccessNotification =
    useMemo<NotificationDefinition>(
      () => ({
        type: 'success',
        message: t('notifications:userAddedToUserGroupsSuccess'),
        removeAfter: 5000,
      }),
      [t]
    );
  const userRemovedFromUserGroupsSuccessNotification =
    useMemo<NotificationDefinition>(
      () => ({
        type: 'success',
        message: t('notifications:userRemovedFromUserGroupsSuccess'),
        removeAfter: 5000,
      }),
      [t]
    );

  const isAddingUserToUserGroupSelector = useMemo(
    () => selectIsLinkingEntity(DataType.User, DataType.UserGroup),
    []
  );
  const isAddingUserToUserGroup = useSelector(isAddingUserToUserGroupSelector);

  const isRemovingUserFromUserGroupSelector = useMemo(
    () => selectIsUnlinkingEntity(DataType.UserGroup, DataType.User),
    []
  );
  const isRemovingUserFromUserGroup = useSelector(
    isRemovingUserFromUserGroupSelector
  );

  const onAddUserToUserGroups = useCallback(
    (userGroupIds: Array<Id>) => {
      if (userGroupIds.length > 0) {
        dispatch(
          addEntityLink(
            DataType.User,
            userId,
            DataType.UserGroup,
            userGroupIds,
            userAddedToUserGroupsSuccessNotification
          )
        );
      }
      onCloseUserGroupModal();
    },
    [
      dispatch,
      onCloseUserGroupModal,
      userAddedToUserGroupsSuccessNotification,
      userId,
    ]
  );

  const onRemoveUserFromUserGroups = useCallback(() => {
    if (selectedUserGroupIds.length > 0) {
      dispatch(
        removeEntityLink(
          DataType.UserGroup,
          selectedUserGroupIds,
          DataType.User,
          userId,
          userRemovedFromUserGroupsSuccessNotification
        )
      );
    }
    setSelectedUserGroupIds([]);
  }, [
    dispatch,
    selectedUserGroupIds,
    userId,
    userRemovedFromUserGroupsSuccessNotification,
  ]);

  return {
    isAddingUserToUserGroup,
    isUserGroupDisabled,
    isUserGroupModalOpen,
    isRemovingUserFromUserGroup,
    onAddUserToUserGroups,
    onCloseUserGroupModal,
    onOpenUserGroupModal,
    onRemoveUserFromUserGroups,
    selectedUserGroupIds,
    setSelectedUserGroupIds,
  };
};

export default useUserGroupControl;
