import React, { ReactElement, ReactNode } from 'react';

import { Text } from '@chakra-ui/react';

import {
  Plan,
  PlanAndPlanTemplateCommon,
  PlanTemplate,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

type PlanAndPlanTemplateCommonKey = keyof PlanAndPlanTemplateCommon;

export interface PlanOrTemplateValueProps<
  K extends PlanAndPlanTemplateCommonKey
> {
  fieldName: K;
  format: (value: Plan[K] | PlanTemplate[K]) => ReactNode;
  plan: Plan;
  planTemplate: PlanTemplate;
  defaultValue?: Plan[K] | PlanTemplate[K];
}

export const PlanOrTemplateValue = <K extends PlanAndPlanTemplateCommonKey>({
  defaultValue,
  fieldName,
  format,
  plan,
  planTemplate,
}: PlanOrTemplateValueProps<K>): ReactElement<any, any> | null => {
  const { t } = useTranslation();

  const planValue = plan[fieldName];
  const planTemplateValue = planTemplate[fieldName];

  if (planValue !== undefined) {
    return (
      <React.Fragment>
        <Text>{format(planValue)}</Text>
        <Text variant="annotation">
          {t('features:plans.planTemplateDefault')}:{' '}
          <s>{format(planTemplateValue ?? defaultValue)}</s>
        </Text>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Text>{format(planTemplateValue ?? defaultValue)}</Text>
      <Text variant="annotation">{t('features:plans.fromPlanTemplate')}</Text>
    </React.Fragment>
  );
};
