import type { ReactNode } from 'react';

export type CellValue = string | number | JSX.Element | ReactNode;
export type RowItemRawValue = string | number;
export interface RowCell {
  columnId: string;
  value: CellValue;
  align?: 'left' | 'center' | 'right';
}

export interface RowItem extends Record<string, any> {}
export type RowItemAccessor<D extends RowItem = RowItem> =
  | keyof D
  | ((item: D) => CellValue);
export type RowItemRawValueAccessor<D extends RowItem = RowItem> =
  | keyof D
  | ((item: D) => RowItemRawValue);

export function getRowCellRawValue<D extends RowItem = RowItem>(
  item: D,
  accessor: RowItemRawValueAccessor<D>
): RowItemRawValue {
  return typeof accessor === 'function' ? accessor(item) : item[accessor];
}
