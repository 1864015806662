import React, { useMemo } from 'react';

import { DataType, Balance, BalanceTransaction } from '@m3ter-com/m3ter-api';

import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { useCrudRetrieveData } from '@/components/common/crud/CrudRetrieveContext';
import { BalanceTransactionForm } from '@/components/features/accounts/BalanceTransactionForm';

const initialValues: Partial<BalanceTransaction> = {};

export const BalanceTransactionsCreateRoute: React.FC = () => {
  // These routes have to be under the balance retrieve route.
  const balance = useCrudRetrieveData<Balance>(DataType.Balance);

  const pathParams = useMemo(
    () => ({
      balanceId: balance.id,
    }),
    [balance]
  );

  const extraData = useMemo(() => ({ currency: balance.currency }), [balance]);

  return (
    <CrudCreate<BalanceTransaction>
      form={BalanceTransactionForm}
      initialValues={initialValues}
      pathParams={pathParams}
      extraData={extraData}
    />
  );
};
