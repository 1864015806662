import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataType, Id, PathParams } from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';
import type { BaseItemPayload } from './index';

import {
  CompletionMeta,
  NotificationDefinition,
  RequestMeta,
} from '@/store/store';

export interface DeleteState {
  isLoading: boolean;
  error?: AppError;
}

export interface DeleteItemPayload extends BaseItemPayload {
  pathParams?: PathParams;
}

export type DeleteItemAction = PayloadAction<
  DeleteItemPayload,
  string,
  RequestMeta
>;

export type DeleteItemSuccessAction = PayloadAction<
  BaseItemPayload,
  string,
  CompletionMeta
>;

export type DeleteItemFailureAction = PayloadAction<
  BaseItemPayload,
  string,
  CompletionMeta,
  AppError
>;

interface DeleteItemsPayload {
  dataType: DataType;
  ids: Array<Id>;
}

export type DeleteItemsAction = PayloadAction<
  DeleteItemsPayload,
  string,
  RequestMeta
>;

export type DeleteItemsSuccessAction = PayloadAction<
  DeleteItemsPayload,
  string,
  CompletionMeta
>;

export type DeleteItemsFailureAction = PayloadAction<
  DeleteItemsPayload,
  string,
  CompletionMeta,
  AppError
>;

const initialState: DeleteState = {
  isLoading: false,
};

const deleteSlice = createSlice({
  name: 'crud/delete',
  initialState,
  reducers: {
    deleteItem: {
      reducer: (state: DeleteState, _action: DeleteItemAction) => {
        state.isLoading = true;
      },
      prepare: (
        dataType: DataType,
        id: string,
        successNotification?: NotificationDefinition,
        failureNotification?: NotificationDefinition,
        redirectTo?: string,
        pathParams?: PathParams
      ) => ({
        payload: { dataType, id, pathParams },
        meta: {
          onSuccess: {
            redirectTo,
            notification: successNotification,
          },
          onFailure: {
            notification: failureNotification,
          },
        },
      }),
    },
    deleteItemSuccess: {
      reducer: (state: DeleteState, _action: DeleteItemSuccessAction) => {
        state.isLoading = false;
        state.error = undefined;
      },
      prepare: (dataType: DataType, id: string, meta?: any) => ({
        payload: { dataType, id },
        meta,
      }),
    },
    deleteItemFailure: {
      reducer: (state: DeleteState, action: DeleteItemFailureAction) => {
        state.isLoading = false;
        state.error = action.error;
      },
      prepare: (
        dataType: DataType,
        id: string,
        error: AppError,
        meta?: any
      ) => ({
        payload: { dataType, id },
        error,
        meta,
      }),
    },
    deleteItems: {
      reducer: (state: DeleteState, _action: DeleteItemsAction) => {
        state.isLoading = true;
      },
      prepare: (
        dataType: DataType,
        ids: Array<Id>,
        successNotification?: NotificationDefinition,
        failureNotification?: NotificationDefinition
      ) => ({
        payload: { dataType, ids },
        meta: {
          onSuccess: {
            notification: successNotification,
          },
          onFailure: {
            notification: failureNotification,
          },
        },
      }),
    },
    deleteItemsSuccess: {
      reducer: (state: DeleteState, _action: DeleteItemsSuccessAction) => {
        state.isLoading = false;
        state.error = undefined;
      },
      prepare: (dataType: DataType, ids: Array<Id>, meta?: any) => ({
        payload: { dataType, ids },
        meta,
      }),
    },
    deleteItemsFailure: {
      reducer: (state: DeleteState, action: DeleteItemsFailureAction) => {
        state.isLoading = false;
        state.error = action.error;
      },
      prepare: (
        dataType: DataType,
        ids: Array<Id>,
        error: AppError,
        meta?: any
      ) => ({
        payload: { dataType, ids },
        error,
        meta,
      }),
    },
  },
});

export const {
  deleteItem,
  deleteItemSuccess,
  deleteItemFailure,
  deleteItems,
  deleteItemsSuccess,
  deleteItemsFailure,
} = deleteSlice.actions;

export default deleteSlice.reducer;
