import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';

import { DataType, Entity, ExternalMapping } from '@m3ter-com/m3ter-api';

import { extractError } from '@/util/error';
import { listAllData } from '@/store/data/data.saga';

import {
  loadUnmappedEntities,
  loadUnmappedEntitiesFailure,
  loadUnmappedEntitiesSuccess,
  LoadUnmappedEntitiesAction,
} from './externalMappingsData';

export function* loadUnmappedEntitiesSaga(
  action: LoadUnmappedEntitiesAction
): Generator<StrictEffect, void, any> {
  const { dataType, externalSystem, externalTable, m3terEntityType } =
    action.payload;

  try {
    const allEntities: Array<Entity> = yield call(listAllData, dataType);
    const existingMappings: Array<ExternalMapping> = yield call(
      listAllData,
      DataType.ExternalMapping,
      { externalSystemId: externalSystem, m3terEntity: m3terEntityType }
    );

    const allEntityIds = allEntities.map((entity) => entity.id);
    const mappedEntityIds = existingMappings
      .filter(
        (existingMapping) => existingMapping.externalTable === externalTable
      )
      .map((externalMapping) => externalMapping.m3terId);
    const unmappedEntityIds = allEntityIds.filter(
      (entityId) => !mappedEntityIds.includes(entityId)
    );

    yield put(
      loadUnmappedEntitiesSuccess(
        m3terEntityType,
        externalSystem,
        externalTable,
        unmappedEntityIds
      )
    );
  } catch (error) {
    yield put(
      loadUnmappedEntitiesFailure(
        m3terEntityType,
        externalSystem,
        externalTable,
        extractError(error)
      )
    );
  }
}

export default function* externalMappingsDataSaga() {
  yield takeLatest(loadUnmappedEntities.type, loadUnmappedEntitiesSaga);
}
