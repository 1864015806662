import React, { useCallback, useEffect, useMemo, useState } from 'react';

import isEqual from 'lodash/isEqual';

import { AggregationSegment, Pricing } from '@m3ter-com/m3ter-api';

export enum SortingDirection {
  Ascending = 'asc',
  Descending = 'desc',
  None = 'none',
}

export interface SegmentedPricingRowData {
  segment: AggregationSegment;
  planTemplatePricings: Array<Pricing>;
  planPricings?: Array<Pricing>;
}

export interface SegmentedPricingFilterState {
  [fieldName: string]: string;
}

export interface SegmentedPricingSortingState {
  fieldName: string;
  direction: SortingDirection;
}

const PAGE_SIZE = 10;

const usePricingSegmentsEditor = (
  segmentedFields: Array<string>,
  segments: Array<AggregationSegment>,
  planPricings: Array<Pricing>,
  planTemplatePricings: Array<Pricing>
) => {
  // Build a map of all segmented field names to all of the unique
  // values used in a segment for each of those field names.
  const segmentedFieldValues = useMemo(
    () =>
      segmentedFields.reduce<Record<string, Array<string>>>(
        (result, fieldName) => {
          const fieldValues = new Set<string>();
          segments.forEach((segment) => {
            const fieldValue = segment[fieldName] as string | undefined;
            if (fieldValue) {
              fieldValues.add(fieldValue);
            }
          });

          return {
            ...result,
            [fieldName]: Array.from(fieldValues),
          };
        },
        {}
      ),
    [segmentedFields, segments]
  );

  // Build a map of all segmented field names to the current value we want to filter
  // on for that field. An empty string is uses as the default value (no filtering).
  const [filterState, setFilterSate] = useState<SegmentedPricingFilterState>(
    () =>
      segmentedFields.reduce((result, fieldName) => {
        return {
          ...result,
          [fieldName]: '',
        };
      }, {} as Record<string, string>)
  );
  const onFieldFilterSelect = useCallback(
    (fieldName: string, newFieldValue: string) => {
      setFilterSate((currentFilterState) => ({
        ...currentFilterState,
        [fieldName]: newFieldValue,
      }));
    },
    []
  );

  // Keep a state of the field name that we are currently sorting by, as well
  // as the current sorting direction for that field.
  const [sortingState, setSortingState] =
    useState<SegmentedPricingSortingState>({
      fieldName: '',
      direction: SortingDirection.None,
    });
  const onSortButtonClick = useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >((event: React.MouseEvent<HTMLButtonElement>) => {
    const fieldName = event.currentTarget.getAttribute('data-field-name');
    if (fieldName) {
      setSortingState((currentSortingState) => {
        let nextSortingDirection: SortingDirection;
        if (
          currentSortingState.fieldName !== fieldName ||
          currentSortingState.direction === SortingDirection.None
        ) {
          nextSortingDirection = SortingDirection.Ascending;
        } else if (
          currentSortingState.direction === SortingDirection.Ascending
        ) {
          nextSortingDirection = SortingDirection.Descending;
        } else {
          nextSortingDirection = SortingDirection.None;
        }

        return {
          fieldName,
          direction: nextSortingDirection,
        };
      });
    }
  }, []);

  const filteredAndSortedSegments = useMemo<Array<AggregationSegment>>(() => {
    let finalSegments = segments;

    // Filter out all of the fields that are being acively filtered
    // from the filterState.
    const activeFilters = Object.entries(filterState).reduce(
      (result, [fieldName, filterValue]) => {
        if (filterValue) {
          result.push({ fieldName, filterValue });
        }

        return result;
      },
      [] as Array<{ fieldName: string; filterValue: string }>
    );
    // For each field filter that is being applied, omit the segments where
    // that are missing a value for that field, or where the segment's value
    // for that field does not equal the selected filter value.
    if (activeFilters.length > 0) {
      finalSegments = finalSegments.filter((segment) =>
        activeFilters.every(
          (filter) =>
            !!segment[filter.fieldName] &&
            segment[filter.fieldName] === filter.filterValue
        )
      );
    }

    // Only sort the filtered segments if we have a field to sort by,
    // and a sorting direction selected.
    const isActivelySorted =
      !!sortingState.fieldName &&
      sortingState.direction !== SortingDirection.None;
    if (isActivelySorted) {
      finalSegments = [...finalSegments].sort((segmentA, segmentB) => {
        const segmentAFieldValue: string | undefined =
          segmentA[sortingState.fieldName];
        const segmentBFieldValue: string | undefined =
          segmentB[sortingState.fieldName];

        // If the selected field to sort on is not present on either of
        // the segments, we cannot sort these segments effectively, so we
        // push it to the bottom of the page.
        if (!segmentAFieldValue || !segmentBFieldValue) {
          return -1;
        }

        return sortingState.direction === SortingDirection.Ascending
          ? segmentAFieldValue.localeCompare(segmentBFieldValue)
          : segmentBFieldValue.localeCompare(segmentAFieldValue);
      });
    }

    return finalSegments;
  }, [filterState, segments, sortingState]);

  const [currentPage, setCurrentPage] = useState(1);
  const pageCount =
    Math.ceil(filteredAndSortedSegments.length / PAGE_SIZE) || 1;
  useEffect(() => {
    setCurrentPage((pageNumber) => Math.min(pageNumber, pageCount));
  }, [pageCount]);
  const onNextPage = useCallback(() => {
    setCurrentPage((pageNumber) => pageNumber + 1);
  }, []);
  const onPageChange = useCallback((pageNumber: number) => {
    setCurrentPage(pageNumber);
  }, []);

  const pageData = useMemo<Array<SegmentedPricingRowData>>(() => {
    const pageStartIndex = (currentPage - 1) * PAGE_SIZE;
    const pageEndIndex = currentPage * PAGE_SIZE;
    const pageSegments = filteredAndSortedSegments.slice(
      pageStartIndex,
      pageEndIndex
    );

    return pageSegments.map<SegmentedPricingRowData>((segment) => {
      const segmentFilter = (pricing: Pricing) =>
        isEqual(pricing.segment, segment);

      const matchingPlanPricings = planPricings.filter(segmentFilter);
      const matchingPlanTemplatePricings =
        planTemplatePricings.filter(segmentFilter);

      return {
        segment,
        planPricings: matchingPlanPricings,
        planTemplatePricings: matchingPlanTemplatePricings,
      };
    });
  }, [
    planPricings,
    planTemplatePricings,
    currentPage,
    filteredAndSortedSegments,
  ]);

  return {
    currentPage,
    filterState,
    pageCount,
    pageData,
    segmentedFieldValues,
    sortingState,
    onNextPage,
    onFieldFilterSelect,
    onPageChange,
    onSortButtonClick,
  };
};

export default usePricingSegmentsEditor;
