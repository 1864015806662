import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { PlanTemplate } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds } from '@/types/lists';

import {
  ColumnDefinition,
  CrudList,
  CrudListFooter,
  CrudListHeader,
  CrudListTable,
} from '@/components/common/crud/CrudList';
import { selectSelectedProductId } from '@/store/products/products';
import { ProductSelector } from '@/components/features/products/ProductSelector';

const searchFields = ['name', 'code'];

export const PlanTemplatesListRoute: React.FC = () => {
  const { t } = useTranslation();

  const columns = useMemo<Array<ColumnDefinition<PlanTemplate>>>(
    () => [
      { id: 'name', header: t('forms:labels.name'), accessor: 'name' },
      { id: 'code', header: t('forms:labels.code'), accessor: 'code' },
    ],
    [t]
  );
  const productId = useSelector(selectSelectedProductId)!;
  const params = useMemo(() => ({ productId }), [productId]);

  return (
    <CrudList
      listId={EntityRouteListIds.PlanTemplate}
      params={params}
      searchFields={searchFields}
    >
      <CrudListHeader>
        <ProductSelector />
      </CrudListHeader>
      <CrudListTable columns={columns} />
      <CrudListFooter />
    </CrudList>
  );
};
