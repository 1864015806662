import React from 'react';

import { NamedRoute } from '@/types/routes';

import { OrganizationIndexRoute } from '@/components/routes/organization/OrganizationIndexRoute';

import setupAccountsRoute from './accounts';
import setupAlertsRoute from './alerts';
import setupAnalyticsRoute from './analytics';
import setupBillingRoute from './billing';
import setupIntegrationsRoute from './integrations';
import setupNotificationsRoute from './notifications';
import setupPricingRoute from './pricing';
import setupProductsRoute from './products';
import setupReportsRoute from './reports';
import setupSettingRoute from './settings';
import setupUsageRoute from './usage';

export const ROOT_ORG_PATH = 'org';

export default (): NamedRoute => ({
  name: 'organization',
  path: `${ROOT_ORG_PATH}/:orgId`,
  children: [
    {
      name: 'dashboard',
      index: true,
      element: <OrganizationIndexRoute />,
    },
    setupAccountsRoute(),
    setupAlertsRoute(),
    setupAnalyticsRoute(),
    setupBillingRoute(),
    setupIntegrationsRoute(),
    setupNotificationsRoute(),
    setupPricingRoute(),
    setupProductsRoute(),
    setupReportsRoute(),
    setupSettingRoute(),
    setupUsageRoute(),
  ],
});
