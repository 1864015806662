import { combineReducers } from 'redux';

import dataExplorerReducer from './data-explorer/dataExplorer';
import reportsReducer from './reports/reports';

const analyticsReducer = combineReducers({
  dataExplorer: dataExplorerReducer,
  reports: reportsReducer,
});

export default analyticsReducer;
