import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  DataType,
  DataTypeToEntity,
  ExternalMappingEntityType,
} from '@m3ter-com/m3ter-api';
import { SelectOption } from '@m3ter-com/ui-components';

import { ExternalMappingEntityTypeToDataType } from '@/types/data';

import { Accessor, getAccessorValue } from '@/util/data';
import { selectById } from '@/store/data/data';
import {
  loadUnmappedEntities as loadUnmappedEntitiesAction,
  selectIsLoadingUnmappedEntities,
  selectUnmappedEntities,
  selectUnmappedEntitiesError,
} from '@/store/features/integrations/external-mappings/externalMappingsData';

// For some external mapping entity types, we can load all the available
// entities that haven't been mapped for the selected external system.
// We keep a map of the data types and accesors needed for these entity
// types so we can grab those entities from state and show them as options.
interface ExternalMappingUnmappedEntitiesParams<
  E extends keyof ExternalMappingEntityTypeToDataType
> {
  accessor: Accessor<
    DataTypeToEntity[ExternalMappingEntityTypeToDataType[E]],
    string
  >;
  dataType: ExternalMappingEntityTypeToDataType[E];
  detailAccessor?: Accessor<
    DataTypeToEntity[ExternalMappingEntityTypeToDataType[E]],
    string
  >;
}
const externalMappingM3terEntityParamsMap: {
  [E in keyof ExternalMappingEntityTypeToDataType]: ExternalMappingUnmappedEntitiesParams<E>;
} = {
  [ExternalMappingEntityType.Account]: {
    dataType: DataType.Account,
    accessor: 'name',
    detailAccessor: 'code',
  },
  [ExternalMappingEntityType.Aggregation]: {
    dataType: DataType.Aggregation,
    accessor: 'name',
    detailAccessor: 'code',
  },
  [ExternalMappingEntityType.CompoundAggregation]: {
    dataType: DataType.CompoundAggregation,
    accessor: 'name',
    detailAccessor: 'code',
  },
  [ExternalMappingEntityType.Contract]: {
    dataType: DataType.Contract,
    accessor: 'name',
  },
  [ExternalMappingEntityType.Organization]: {
    dataType: DataType.Organization,
    accessor: 'organizationName',
  },
  [ExternalMappingEntityType.Meter]: {
    dataType: DataType.Meter,
    accessor: 'name',
    detailAccessor: 'code',
  },
  [ExternalMappingEntityType.Plan]: {
    dataType: DataType.Plan,
    accessor: 'name',
    detailAccessor: 'code',
  },
  [ExternalMappingEntityType.Product]: {
    dataType: DataType.Product,
    accessor: 'name',
    detailAccessor: 'code',
  },
};

const useExternalMappingM3terEntityOptions = <
  E extends keyof ExternalMappingEntityTypeToDataType
>(
  m3terEntityType?: E | ExternalMappingEntityType,
  externalSystem?: string,
  externalTable?: string,
  initialM3terId?: string
) => {
  const canGetEntityOptions =
    !!m3terEntityType &&
    !!externalSystem &&
    !!externalTable &&
    m3terEntityType in externalMappingM3terEntityParamsMap;
  const m3terEntityParams:
    | ExternalMappingUnmappedEntitiesParams<E>
    | undefined = canGetEntityOptions
    ? externalMappingM3terEntityParamsMap[m3terEntityType as E]
    : undefined;

  const isLoadingUnmappedEntities = useSelector(
    selectIsLoadingUnmappedEntities
  );
  const errorSelector = useCallback(
    (state: any) => {
      if (canGetEntityOptions) {
        return selectUnmappedEntitiesError(
          m3terEntityType as E,
          externalSystem,
          externalTable
        )(state);
      }
      return undefined;
    },
    [canGetEntityOptions, externalSystem, externalTable, m3terEntityType]
  );
  const error = useSelector(errorSelector);
  const unmappedEntitiesSelector = useCallback(
    (state: any) => {
      if (canGetEntityOptions && m3terEntityParams) {
        return selectUnmappedEntities(
          m3terEntityType as E,
          m3terEntityParams.dataType,
          externalSystem,
          externalTable
        )(state);
      }
      return [];
    },
    [
      canGetEntityOptions,
      m3terEntityParams,
      m3terEntityType,
      externalSystem,
      externalTable,
    ]
  );
  const unmappedEntities = useSelector(unmappedEntitiesSelector);
  const initialM3terEntitySelector = useCallback(
    (state: any) => {
      if (initialM3terId && canGetEntityOptions && m3terEntityParams) {
        return selectById<
          DataTypeToEntity[ExternalMappingEntityTypeToDataType[E]]
        >(
          m3terEntityParams.dataType,
          initialM3terId
        )(state);
      }
      return undefined;
    },
    [canGetEntityOptions, initialM3terId, m3terEntityParams]
  );
  const initialM3terEntity = useSelector(initialM3terEntitySelector);

  const entityOptions = useMemo<Array<SelectOption>>(() => {
    if (canGetEntityOptions && m3terEntityParams) {
      const options = unmappedEntities.map<SelectOption>((entity) => ({
        label: getAccessorValue(entity, m3terEntityParams.accessor),
        secondaryLabel: m3terEntityParams.detailAccessor
          ? getAccessorValue(entity, m3terEntityParams.detailAccessor)
          : undefined,
        value: entity.id,
      }));
      if (initialM3terEntity) {
        options.unshift({
          label: getAccessorValue(
            initialM3terEntity,
            m3terEntityParams.accessor
          ),
          secondaryLabel: m3terEntityParams.detailAccessor
            ? getAccessorValue(
                initialM3terEntity,
                m3terEntityParams.detailAccessor
              )
            : undefined,
          value: initialM3terEntity.id,
        });
      }
      return options;
    }
    return [];
  }, [
    canGetEntityOptions,
    initialM3terEntity,
    m3terEntityParams,
    unmappedEntities,
  ]);

  const dispatch = useDispatch();
  const loadUnmappedEntities = useCallback(() => {
    if (canGetEntityOptions && m3terEntityParams) {
      dispatch(
        loadUnmappedEntitiesAction(
          m3terEntityType as E,
          m3terEntityParams.dataType,
          externalSystem,
          externalTable
        )
      );
    }
  }, [
    canGetEntityOptions,
    m3terEntityParams,
    dispatch,
    m3terEntityType,
    externalSystem,
    externalTable,
  ]);

  return {
    entityOptions,
    error,
    isLoading: isLoadingUnmappedEntities,
    loadUnmappedEntities,
  };
};

export default useExternalMappingM3terEntityOptions;
