import React, { Suspense } from 'react';

import { Outlet } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { TabbedLinks } from '@m3ter-com/ui-components';

import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';
import { NavTabLink } from '@/components/common/navigation/NavTabLink';

export const CustomerInsightsRootRoute: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <BreadcrumbItem title={t('features:customerInsights.title')} />
      <TabbedLinks mb={4}>
        <NavTabLink to="" activeOnlyIfExact>
          {t('common:overview')}
        </NavTabLink>
        <NavTabLink to="account-details">
          {t('features:customerInsights.accountDetails')}
        </NavTabLink>
      </TabbedLinks>
      <Suspense fallback={<Spinner />}>
        <Outlet />
      </Suspense>
    </>
  );
};
