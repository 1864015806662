import React from 'react';

import { Counter } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { ItemCounterDetails } from '@/components/features/item-counters/ItemCounterDetails';

interface ItemCountersDetailsRouteElementProps {
  data: Counter;
}

const ItemCountersDetailsRouteElement: React.FC<
  ItemCountersDetailsRouteElementProps
> = ({ data: itemCounter }) => (
  <ItemCounterDetails showEditButton data={itemCounter} />
);

export const ItemCountersDetailsRoute: React.FC = () => (
  <CrudDetails<Counter> component={ItemCountersDetailsRouteElement} />
);
