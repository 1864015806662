import wretch, { ConfiguredMiddleware, Wretch } from 'wretch';
import queryStringAddon, { QueryStringAddon } from 'wretch/addons/queryString';

import { Id, PathParams, QueryParams } from '../types';

import { buildPath } from '../util/path';
import { cleanParams } from '../util/params';

type Client = QueryStringAddon & Wretch<QueryStringAddon>;

type Headers = Record<string, string>;

export interface ApiRequest {
  path: string;
  pathParams?: PathParams;
  queryParams?: QueryParams;
  headers?: Record<string, string>;
  body?: object;
  signal?: AbortSignal;
}

interface ApiConfig {
  endpoint: string;
  ingestEndpoint?: string;
  headers?: () => Promise<Headers>;
  organizationId?: Id;
}

class ApiClass {
  client: Client | undefined;

  ingestClient: Client | undefined;

  organizationId: Id | undefined;

  configure(config: ApiConfig) {
    this.organizationId = config.organizationId;

    const headersMiddleware: ConfiguredMiddleware =
      (next) => async (url, options) => {
        const headers = await config.headers?.();
        return next(url, {
          ...options,
          headers: { ...options.headers, ...headers },
        });
      };

    const middlewares = [];
    if (config.headers) {
      middlewares.push(headersMiddleware);
    }

    this.client = wretch(config.endpoint)
      .addon(queryStringAddon)
      .middlewares([headersMiddleware]);

    if (config.ingestEndpoint) {
      this.ingestClient = wretch(config.ingestEndpoint)
        .addon(queryStringAddon)
        .middlewares([headersMiddleware]);
    }
  }

  setOrganizationId(organizationId: Id) {
    this.organizationId = organizationId;
  }

  getOrganizationId() {
    return this.organizationId;
  }
}

// Default instance.
export const API = new ApiClass();

export const getClient = ({
  path,
  pathParams = {},
  queryParams = {},
  headers = {},
  body,
  signal,
}: ApiRequest) => {
  if (!API.client) {
    throw new Error('Call API.configure before attempting to make requests');
  }

  // If the path is absolute, replace the base URL.
  const replace = path.startsWith('http://') || path.startsWith('https://');

  const client = API.client
    .options({ signal })
    .url(buildPath(path, pathParams), replace)
    .headers(headers)
    .query(cleanParams(queryParams));

  return body ? client.json(body) : client;
};

const getIngestClient = ({
  path,
  pathParams = {},
  headers = {},
  body = {},
  signal,
}: ApiRequest) => {
  if (!API.ingestClient) {
    throw new Error(
      'Call API.configure with an ingestEndpoint before attempting to make ingest requests'
    );
  }

  return API.ingestClient
    .options({ signal })
    .url(buildPath(path, pathParams))
    .headers(headers)
    .json(body);
};

// Base get, post, put etc. methods.

export const get = (request: ApiRequest) => getClient(request).get().json();

export const post = (request: ApiRequest) => getClient(request).post().json();

export const put = (request: ApiRequest) => getClient(request).put().json();

export const patch = (request: ApiRequest) => getClient(request).patch().json();

export const del = (request: ApiRequest) => getClient(request).delete().json();

// Ingest submission.

export const ingestPost = (request: ApiRequest) =>
  getIngestClient(request).post().json();
