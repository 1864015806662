import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useResolvedPath } from 'react-router-dom';

import { addBreadcrumb, removeBreadcrumb } from '@/store/page/page';
import { useTabContext } from '@/components/common/navigation/TabbedRoute';

export interface BreadcrumbItemProps {
  title: string;
  hasNoRoute?: boolean;
}

export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  title,
  hasNoRoute = false,
}) => {
  // Get the resolved path to the current route.
  const resolvedPath = useResolvedPath('');
  const dispatch = useDispatch();
  const to = !hasNoRoute ? resolvedPath.pathname : undefined;

  // Breadcrumb items directly under tabbed routes currently showing tabs shouldn't show
  // in the trail, to avoid duplication; their title will be in the tab itself.
  const { showTabs = false } = useTabContext();

  useEffect(() => {
    dispatch(addBreadcrumb(title, showTabs, to));
    return () => {
      dispatch(removeBreadcrumb(title, showTabs, to));
    };
  }, [dispatch, to, title, showTabs]);

  return null;
};
