import React, { useCallback } from 'react';

import { DataType, Id } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, Confirmation } from '@m3ter-com/ui-components';

import useEntityDelete from '@/hooks/data/crud/useEntityDelete';

export interface EntitiesBulkDeleteActionProps {
  dataType: DataType;
  isDisabled?: boolean;
  selectedItems: Array<Id>;
  clearSelectedItems: () => void;
}

export const EntitiesBulkDeleteAction: React.FC<
  EntitiesBulkDeleteActionProps
> = ({ dataType, isDisabled = false, selectedItems, clearSelectedItems }) => {
  const { t } = useTranslation();

  const { deleteItems, isLoading } = useEntityDelete(dataType);
  const onDeleteSelected = useCallback(() => {
    deleteItems(selectedItems);
    clearSelectedItems();
  }, [deleteItems, clearSelectedItems, selectedItems]);

  return (
    <Confirmation
      message={t('common:deleteSelectedConfirmation')}
      onConfirm={onDeleteSelected}
      trigger={
        <Button
          isDisabled={isDisabled || selectedItems.length === 0}
          isLoading={isLoading}
        >
          {t('common:deleteSelected')}
        </Button>
      }
    />
  );
};
