import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataType, Account, Bill } from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

import { createSelectByIds } from '@/store/data/data';

export interface BillHistoryState {
  accountIds: Array<string>;
  accountBillIds: Array<string>;
  isLoadingAccounts: boolean;
  isLoadingBills: boolean;
  error?: AppError;
}

export type LoadAccountsAction = PayloadAction<undefined>;

export type LoadAccountsFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

interface LoadAccountsSuccessPayload {
  accountIds: Array<string>;
}

export type LoadAccountsSuccessAction =
  PayloadAction<LoadAccountsSuccessPayload>;

interface UpdateSelectedAccountIdPayload {
  accountId: string;
}

export type UpdateSelectedAccountIdAction =
  PayloadAction<UpdateSelectedAccountIdPayload>;

interface UpdateSelectedAccountIdSuccessPayload {
  accountBillIds: Array<string>;
}

export type UpdateSelectedAccountIdSuccessAction =
  PayloadAction<UpdateSelectedAccountIdSuccessPayload>;

export type UpdateSelectedAccountIdFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

const name = 'features/billing/billHistory';

export const initialState: BillHistoryState = {
  accountIds: new Array<string>(),
  accountBillIds: new Array<string>(),
  isLoadingAccounts: false,
  isLoadingBills: false,
};

const billHistoryState = createSlice({
  name,
  initialState,
  reducers: {
    loadAccounts: (state: BillHistoryState, _action: LoadAccountsAction) => {
      state.isLoadingAccounts = true;
    },
    loadAccountsFailure: {
      reducer: (state: BillHistoryState, action: LoadAccountsFailureAction) => {
        state.error = action.error;
        state.isLoadingAccounts = false;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    loadAccountsSuccess: {
      reducer: (state: BillHistoryState, action: LoadAccountsSuccessAction) => {
        state.error = undefined;
        state.accountIds = action.payload.accountIds;
        state.isLoadingAccounts = false;
      },
      prepare: (accountIds: Array<string>) => ({ payload: { accountIds } }),
    },
    resetBillingHistory: () => initialState,
    updateSelectedAccountId: {
      reducer: (
        state: BillHistoryState,
        _action: UpdateSelectedAccountIdAction
      ) => {
        state.isLoadingBills = true;
      },
      prepare: (accountId: string) => ({ payload: { accountId } }),
    },
    updateSelectedAccountIdFailure: {
      reducer: (
        state: BillHistoryState,
        action: UpdateSelectedAccountIdFailureAction
      ) => {
        state.error = action.error;
        state.isLoadingBills = false;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    updateSelectedAccountIdSuccess: {
      reducer: (
        state: BillHistoryState,
        action: UpdateSelectedAccountIdSuccessAction
      ) => {
        state.error = undefined;
        state.accountBillIds = action.payload.accountBillIds;
        state.isLoadingBills = false;
      },
      prepare: (accountBillIds: Array<string>) => ({
        payload: { accountBillIds },
      }),
    },
  },
});

// Actions
export const {
  loadAccounts,
  loadAccountsFailure,
  loadAccountsSuccess,
  resetBillingHistory,
  updateSelectedAccountId,
  updateSelectedAccountIdFailure,
  updateSelectedAccountIdSuccess,
} = billHistoryState.actions;

// Selectors
const selectBillHistoryState = (state: {
  features: { billing: { billHistory: BillHistoryState } };
}) => state.features.billing.billHistory;

export const selectAccountIds = createSelector(
  selectBillHistoryState,
  (state) => state.accountIds
);

export const selectAccounts = createSelector(
  selectAccountIds,
  createSelectByIds<Account>(DataType.Account),
  (accountIds, selectByIds) => selectByIds(accountIds)
);

export const selectAccountBillIds = createSelector(
  selectBillHistoryState,
  (state) => state.accountBillIds
);

export const selectAccountBills = createSelector(
  selectAccountBillIds,
  createSelectByIds<Bill>(DataType.Bill),
  (accountBillIds, selectByIds) => selectByIds(accountBillIds)
);

export const selectIsLoadingAccounts = createSelector(
  selectBillHistoryState,
  (state) => state.isLoadingAccounts
);

export const selectIsLoadingBills = createSelector(
  selectBillHistoryState,
  (state) => state.isLoadingBills
);

export const selectBillHistoryError = createSelector(
  selectBillHistoryState,
  (state) => state.error
);

// Export the slice reducer
export default billHistoryState.reducer;
