import { SystemStyleObject } from '@chakra-ui/theme-tools';
import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const parts = ['container', 'row', 'headerCell', 'cell', 'paginationContainer'];

const baseStyleCell: SystemStyleObject = {
  whiteSpace: 'nowrap',
};

const staticTable: ComponentMultiStyleConfig = {
  parts,
  baseStyle: {
    headerCell: {
      ...baseStyleCell,
    },
    cell: {
      ...baseStyleCell,
    },
    row: {
      _hover: {
        bg: 'misc-hover',
      },
    },
  },
};

export default staticTable;
