import React from 'react';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';

const LazyIntegrationEventsListRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationEventsListRoute" */ '@/components/routes/integrations/events/IntegrationEventsListRoute'
  ).then((module) => ({ default: module.IntegrationEventsListRoute }))
);

export default (): NamedRoute => ({
  path: 'integration-events',
  name: 'integrations.events',
  element: <LazyIntegrationEventsListRoute />,
});
