import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import {
  IconButton,
  InputGroup,
  InputRightElement,
  useDisclosure,
} from '@chakra-ui/react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { Input, InputProps } from '@m3ter-com/ui-components';

export interface FormPasswordInputProps extends Omit<InputProps, 'type'> {
  name: string;
  togglePasswordVisibilityLabel?: string;
  onPasswordChange?: (value: string) => void;
}

export const FormPasswordInput: React.FC<FormPasswordInputProps> = ({
  name,
  onPasswordChange,
  togglePasswordVisibilityLabel = 'Toggle password visibility',
  ...props
}) => {
  const { control } = useFormContext();

  const { isOpen: isPasswordVisible, onToggle: onTogglePasswordVisibility } =
    useDisclosure();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value = '', onChange } }) => (
        <InputGroup>
          <Input
            value={value}
            onChange={(event) => {
              onChange(event);
              onPasswordChange?.(event.target.value);
            }}
            {...props}
            type={isPasswordVisible ? 'text' : 'password'}
          />
          <InputRightElement>
            <IconButton
              variant="text"
              aria-label={togglePasswordVisibilityLabel}
              icon={
                isPasswordVisible ? (
                  <FaEyeSlash color="black" />
                ) : (
                  <FaEye color="black" />
                )
              }
              onClick={onTogglePasswordVisibility}
            />
          </InputRightElement>
        </InputGroup>
      )}
    />
  );
};
