import React, { PropsWithChildren, useRef } from 'react';

import { Button } from '@chakra-ui/react';
import { useDay } from '@datepicker-react/hooks';

import { useCalendarContext } from './CalendarContext';

export interface CalendarCellProps {
  date: Date;
}

export const CalendarCell: React.FC<PropsWithChildren<CalendarCellProps>> = ({
  date,
  ...otherProps
}) => {
  const dayRef = useRef<HTMLButtonElement>(null);
  const {
    focusedDate,
    showHoverRange,
    isDateBlocked,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useCalendarContext();

  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    onMouseEnter,
    onClick,
  } = useDay({
    date,
    dayRef,
    focusedDate,
    isDateBlocked,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  });

  return (
    <Button
      ref={dayRef}
      variant={isSelected || isSelectedStartOrEnd ? 'solid' : 'ghost'}
      bgColor={showHoverRange && isWithinHoverRange ? 'misc-hover' : undefined}
      borderRadius={0}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      {...otherProps}
    />
  );
};
