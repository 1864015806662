import React, { useMemo } from 'react';

import { ButtonProps, HStack, Placement } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, Confirmation } from '@m3ter-com/ui-components';

export interface FormActionsWithConfirmationProps {
  submitText: string;
  onSave: (data: any) => void;
  showConfirmation?: boolean;
  cancelText?: string;
  confirmationMessage?: string;
  confirmationPlacement?: Placement;
  isSaving?: boolean;
  onCancel?: () => void;
}

const confirmationConfirmButtonProps: ButtonProps = {
  type: 'button',
};

const confirmationCancelButtonProps: ButtonProps = {
  type: 'button',
};

export const FormActionsWithConfirmation: React.FC<
  FormActionsWithConfirmationProps
> = ({
  submitText,
  onSave,
  showConfirmation = true,
  cancelText,
  confirmationMessage,
  confirmationPlacement,
  isSaving = false,
  onCancel,
}) => {
  const { handleSubmit } = useFormContext();
  const onConfirm = useMemo(() => handleSubmit(onSave), [handleSubmit, onSave]);

  const { t } = useTranslation();

  return (
    <HStack>
      {showConfirmation ? (
        <Confirmation
          trigger={
            <Button intent="primary" type="button" isLoading={isSaving}>
              {submitText}
            </Button>
          }
          message={confirmationMessage}
          onConfirm={onConfirm}
          cancelText={t('common:no')}
          confirmText={t('common:yes')}
          confirmButtonProps={confirmationConfirmButtonProps}
          cancelButtonProps={confirmationCancelButtonProps}
          placement={confirmationPlacement}
        />
      ) : (
        <Button intent="primary" type="submit" isLoading={isSaving}>
          {submitText}
        </Button>
      )}
      {onCancel && (
        <Button type="button" variant="ghost" onClick={onCancel}>
          {cancelText}
        </Button>
      )}
    </HStack>
  );
};
