import React, { useEffect, useMemo } from 'react';

import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HStack, Link, Text } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Button,
  DataTable,
  DataTableColumnDefinition,
} from '@m3ter-com/ui-components';

import { ConnectedEvents } from '@/types/data';

import {
  loadNotificationEventsData,
  selectError,
  selectIsLoading,
  selectNotificationEventsData,
} from '@/store/features/events-notifications/notifications';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch';
import { NamedLink } from '@/components/common/navigation/NamedLink';
import useDateFormatter from '@/hooks/util/useDateFormatter';

export const ConnectedEventsListRoute: React.FC = () => {
  const { t } = useTranslation();
  const { toLongDateTime } = useDateFormatter();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadNotificationEventsData());
  }, [dispatch]);

  const routeName = 'notification-rule-by-event-name';
  const viewAllRouteName = 'unfiltered-notification-rules';

  const notificationEvents = useSelector(selectNotificationEventsData);
  const error = useSelector(selectError);
  const isLoading = useSelector(selectIsLoading);

  const columns = useMemo<Array<DataTableColumnDefinition<ConnectedEvents>>>(
    () => [
      {
        id: 'eventName',
        header: t('features:notifications.eventNames'),
        accessor: (connectedEvent) =>
          !!connectedEvent.eventName && (
            <Link
              as={NamedLink}
              name={routeName}
              params={{ eventName: connectedEvent.eventName }}
            >
              {connectedEvent.eventName}
            </Link>
          ),
      },
      {
        id: 'triggeredEventsCount',
        header: t('features:notifications.triggeredEventCount'),
        accessor: 'triggeredEventsCount',
      },
      {
        id: 'mostRecentEventDate',
        header: t('features:notifications.lastSeen'),
        accessor: (connectedEvent) =>
          toLongDateTime(connectedEvent.mostRecentEventDate),
      },
    ],
    [t, toLongDateTime]
  );

  if (!isLoading && !!notificationEvents && notificationEvents.length === 0) {
    return <Navigate to="view-all" />;
  }

  return (
    <>
      <HStack justifyContent="flex-end" alignItems="center" spacing={4}>
        <Button as={NamedLink} name={viewAllRouteName}>
          {t('common:viewAll')}
        </Button>
      </HStack>
      <LoadingErrorContentSwitch error={error} isLoading={isLoading}>
        {!!notificationEvents && (
          <DataTable<ConnectedEvents>
            columnDefinitions={columns}
            idAccessor="eventName"
            items={notificationEvents}
            isLoading={isLoading}
            emptyContent={
              <HStack justifyContent="center" alignItems="center">
                <Text>
                  {t('errors:generic.noDataToDisplay', {
                    entityName: '$t(common:events)',
                  })}
                </Text>
              </HStack>
            }
          />
        )}
      </LoadingErrorContentSwitch>
    </>
  );
};
