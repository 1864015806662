import React from 'react';

import { Heading } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { selectTitle } from '@/store/page/page';

export interface PageTitleProps {}

export const PageTitle: React.FC<PageTitleProps> = () => {
  const title = useSelector(selectTitle);
  return title ? <Heading size="lg">{title}</Heading> : null;
};
