import React, { useMemo } from 'react';

import { DataType, OrganizationAdmin, UserAdmin } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormInput,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import { AddressFields } from '@/components/forms/AddressFields';
import userOrganizationSchema from '@/validation/userOrganization';
import {
  DataTypeFormEntitySelect,
  FormEntitySelect,
} from '@/components/forms/FormEntitySelect';

export interface OrganizationAdminFormProps
  extends BaseFormProps<OrganizationAdmin> {}

export interface OrganizationAdminFormExtraData {
  isEdit: boolean;
}

const defaultInitialValues: Partial<OrganizationAdmin> = {};

export const OrganizationAdminForm: React.FC<OrganizationAdminFormProps> = ({
  onSave,
  onCancel,
  isEdit = false,
  isSaving = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();
  const entityName = t('common:organization');

  const userAccessor = (user: UserAdmin) =>
    `${user.firstName} ${user.lastName}`;

  const extraValidationData = useMemo(() => ({ isEdit }), [isEdit]);

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={userOrganizationSchema}
      extraValidationData={extraValidationData}
    >
      <FormStack>
        <FormSection heading={t('common:entityDetails', { entityName })}>
          {!isEdit && (
            <FormField
              isRequired
              name="ownerId"
              label={t('features:organizations.orgOwner')}
              control={
                FormEntitySelect as DataTypeFormEntitySelect<DataType.UserAdmin>
              }
              dataType={DataType.UserAdmin}
              accessor={userAccessor}
              helpText={t('forms:helpText.organizationAdminOwner')}
            />
          )}
          <FormField
            isRequired
            name="organizationName"
            label={t('features:organizations.name')}
            control={FormInput}
          />
          <FormField
            isRequired
            name="shortName"
            label={t('features:organizations.shortName')}
            control={FormInput}
          />
          <FormField
            isRequired={!isEdit} // We want to enforce Customers are assigned but not when a org already exists without one.
            name="customerId"
            label={t('common:customer')}
            control={
              FormEntitySelect as DataTypeFormEntitySelect<DataType.Customer>
            }
            dataType={DataType.Customer}
            accessor="name"
            helpText={t('forms:helpText.organizationAdminCustomer')}
          />
        </FormSection>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: t('forms:labels.address'),
          })}
        >
          <AddressFields showExtraAddressLines={false} />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={
            !isEdit
              ? t('forms:buttons.createEntity', { entityName })
              : t('forms:buttons.updateEntity', { entityName })
          }
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
