import React from 'react';

import { Link } from 'react-router-dom';
import { HStack } from '@chakra-ui/react';

import {
  DataType,
  Id,
  SupportAccess,
  UnsavedEntity,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormField,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import { FormDatePicker } from '@/components/forms/FormDatePicker';
import {
  DataTypeFormEntityMultiSelect,
  FormEntityMultiSelect,
} from '@/components/forms/FormEntityMultiSelect';

export interface SupportAccessUpdateFormValues
  extends UnsavedEntity<SupportAccess> {
  permissionPolicyIds: Array<Id>;
}

export interface SupportAccessFormProps
  extends BaseFormProps<SupportAccessUpdateFormValues> {
  returnUrl: string;
}

const defaultInitialValues: Partial<SupportAccessUpdateFormValues> = {};

export const SupportAccessForm: React.FC<SupportAccessFormProps> = ({
  onSave,
  returnUrl,
  isSaving = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();

  return (
    <Form initialValues={initialValues} onSubmit={onSave}>
      <FormStack>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: t('supportAccess'),
          })}
        >
          <FormField
            isRequired
            name="dtEndSupportUsersAccess"
            label={t('forms:labels.supportAccessEndDate')}
            helpText={t('forms:helpText.supportAccessEndDate')}
            control={FormDatePicker}
          />
          <FormField
            name="permissionPolicyIds"
            label={t('common:permissionPolicies')}
            control={
              FormEntityMultiSelect as DataTypeFormEntityMultiSelect<DataType.PermissionPolicy>
            }
            dataType={DataType.PermissionPolicy}
            helpText={t('forms:helpText.supportAccessPermissions')}
            accessor="name"
          />
        </FormSection>
        <HStack>
          <Button intent="primary" type="submit" isLoading={isSaving}>
            {t('forms:buttons.updateEntity', {
              entityName: t('common:supportAccess'),
            })}
          </Button>
          <Button variant="ghost" as={Link} to={returnUrl}>
            {t('common:cancel')}
          </Button>
        </HStack>
      </FormStack>
    </Form>
  );
};
