import React, { PropsWithChildren } from 'react';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button } from '@m3ter-com/ui-components';

export interface EntitySelectModalWrapperProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onConfirm: () => void;
}

export const EntitySelectModalWrapper: React.FC<
  PropsWithChildren<EntitySelectModalWrapperProps>
> = ({ children, isOpen, title, onClose, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay>
        <ModalContent data-testid="entity-select-modal" minW="50vw" p={4}>
          <ModalHeader>{title}</ModalHeader>
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            <Button
              data-testid="entity-select-modal-confirm-button"
              type="button"
              intent="primary"
              onClick={onConfirm}
              mr={3}
            >
              {t('common:confirm')}
            </Button>
            <Button
              data-testid="entity-select-modal-cancel-button"
              type="button"
              onClick={onClose}
            >
              {t('common:cancel')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
