import React from 'react';

import { Spinner, VStack } from '@chakra-ui/react';

import { Plan } from '@m3ter-com/m3ter-api';

import usePlanDetails from '@/hooks/features/plans/usePlanDetails';
import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { PlanDetails } from '@/components/features/pricing/PlanDetails';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import { PlanDetailsPricingGrid } from '@/components/features/pricing/PlanDetailsPricingGrid';

interface PlanDetailsRouteElementProps {
  data: Plan;
}

const PlanDetailsRouteElement: React.FC<PlanDetailsRouteElementProps> = ({
  data: plan,
}) => {
  const {
    error,
    extraPricingAggregations,
    extraPricingCompoundAggregations,
    extraPricingItemCounters,
    isLoading,
    isLoadingExtraUsageEntities,
    pricingData,
  } = usePlanDetails(plan.planTemplateId, plan.id);

  if (isLoading || !pricingData) {
    return <Spinner />;
  }

  return (
    <VStack spacing={4} flex={2} alignItems="stretch">
      <PlanDetails
        showEditButton
        showIsCustomDetails
        plan={plan}
        planTemplate={pricingData.planTemplates[0]}
      />
      {!!error && <ErrorAlert error={error} />}
      <PlanDetailsPricingGrid
        canEditPricing
        canEditTemplatePricing={false}
        isLoading={isLoading || isLoadingExtraUsageEntities}
        extraPricingAggregations={extraPricingAggregations}
        extraPricingCompoundAggregations={extraPricingCompoundAggregations}
        extraPricingItemCounters={extraPricingItemCounters}
        pricingData={pricingData}
      />
    </VStack>
  );
};

export const PlansDetailsRoute: React.FC = () => (
  <CrudDetails<Plan> component={PlanDetailsRouteElement} />
);
