import { createSelector } from '@reduxjs/toolkit';

import { SupportAccess } from '@m3ter-com/m3ter-api';

import createFormSlice, {
  CreateAction,
  FormState,
} from '@/store/forms/createFormSlice';
import { SupportAccessUpdateFormValues } from '@/components/features/access/support-access/SupportAccessForm';

export interface SupportAccessUpdateState extends FormState {}

export type UpdateSupportAccessAction =
  CreateAction<SupportAccessUpdateFormValues>;

const supportAccessUpdateSlice = createFormSlice<
  SupportAccess,
  SupportAccessUpdateFormValues
>('features/access/supportAccessUpdate');

export const {
  create: updateSupportAccess,
  createSuccess: updateSupportAccessSuccess,
  createFailure: updateSupportAccessFailure,
} = supportAccessUpdateSlice.actions;

const selectSupportAccessUpdateState = (state: {
  features: { access: { supportAccessUpdate: SupportAccessUpdateState } };
}): SupportAccessUpdateState => state.features.access.supportAccessUpdate;

export const selectIsSaving = createSelector(
  selectSupportAccessUpdateState,
  (state) => state.isSaving
);

export const selectError = createSelector(
  selectSupportAccessUpdateState,
  (state) => state.error
);

export default supportAccessUpdateSlice.reducer;
