export enum DataExplorerDataType {
  BillingAccounts = 'BILLING_ACCOUNTS', // Shouldn't be used until DWH improvements have been made
  BillingData = 'BILLING_DATA',
  BillingProducts = 'BILLING_PRODUCTS', // Shouldn't be used until DWH improvements have been made
  CommitmentsAccounts = 'COMMITMENTS_ACCOUNTS', // Shouldn't be used until DWH improvements have been made
  CommitmentsData = 'COMMITMENTS_DATA',
  CommitmentsLineItemsData = 'COMMITMENTS_LINE_ITEMS_DATA',
  CommitmentsObligationsData = 'COMMITMENTS_OBLIGATIONS_DATA',
  UsageData = 'USAGE_DATA',
  UsageMeterDimensions = 'USAGE_METER_DIMENSIONS',
  UsageMeters = 'USAGE_METERS',
}

export enum FeatureCluster {
  Accounts = 'accounts',
  Admin = 'admin',
  Alerts = 'alerts',
  Analytics = 'analytics',
  Billing = 'billing',
  Integrations = 'integrations',
  Metering = 'metering',
  Pricing = 'pricing',
  Products = 'products',
  Settings = 'settings',
  Usage = 'usage',
}

export enum HttpMethod {
  Delete = 'DEL',
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
}

export enum ReportDateRange {
  LastTwoMonths = 'LAST_TWO_MONTHS',
  LastThreeMonths = 'LAST_THREE_MONTHS',
  LastFourMonths = 'LAST_FOUR_MONTHS',
  LastSixMonths = 'LAST_SIX_MONTHS',
  LastYear = 'LAST_YEAR',
  LastTwoYears = 'LAST_TWO_YEARS',
  LastThreeYears = 'LAST_THREE_YEARS',
  Custom = 'CUSTOM',
}
