import { PathParams } from '../types';

const pathPlaceholderRegexp = /:(\w+)/g;

const baseOrgPath = '/organizations/:organizationId';

export const getOrganizationPath = (path: string = ''): string =>
  `${baseOrgPath}${path}`;

export const buildPath = (
  placeholderPath: string,
  pathParams: PathParams
): string =>
  placeholderPath.replace(pathPlaceholderRegexp, (_fullMatch, paramKey) => {
    if (paramKey in pathParams) {
      return String(pathParams[paramKey]);
    }
    throw new Error(`Path param '${paramKey}' not provided.`);
  });
