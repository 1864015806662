import {
  addMonths,
  endOfMonth,
  startOfMonth,
  subMonths,
  subYears,
} from 'date-fns';

import {
  AnalyticsJobTimePeriodType,
  AnalyticsJobType,
  AnalyticsJobTypeToRequestBody,
  BillLineItemType,
  DateTimeISOString,
  Id,
  RecurringRevenueReportMonthConfig,
} from '@m3ter-com/m3ter-api';
import { copySystemDateInTimeZone } from '@m3ter-com/console-core/utils';

import {
  BillingBasedRecurringRevenueReportFilterState,
  MonthOnMonthReportFilterState,
  RecurringRevenueReportFilterState,
  ReportDateRange,
  ReportType,
  ReportTypeToFilterState,
  ReportValueChangeIndicatorState,
  TotalContractValueReportFilterState,
} from '@/types/data';

const getSytemReportDateRange = (
  dateRange: ReportDateRange
): { endDate: Date; startDate: Date } => {
  if (dateRange === ReportDateRange.Custom) {
    throw new Error(
      'Cannot calculate dates for a custom date range selection.'
    );
  }

  const endDate = startOfMonth(new Date(Date.now()));
  switch (dateRange) {
    case ReportDateRange.LastThreeYears:
      return {
        endDate,
        startDate: subYears(endDate, 3),
      };
    case ReportDateRange.LastTwoYears:
      return {
        endDate,
        startDate: subYears(endDate, 2),
      };
    case ReportDateRange.LastYear:
      return {
        endDate,
        startDate: subYears(endDate, 1),
      };
    case ReportDateRange.LastSixMonths:
      return {
        endDate,
        startDate: subMonths(endDate, 6),
      };
    case ReportDateRange.LastFourMonths:
      return {
        endDate,
        startDate: subMonths(endDate, 4),
      };
    case ReportDateRange.LastThreeMonths:
      return {
        endDate,
        startDate: subMonths(endDate, 3),
      };
    default:
      return {
        endDate,
        startDate: subMonths(endDate, 2),
      };
  }
};
export const getReportDateRange = (
  dateRange: ReportDateRange,
  timeZone: string
): { endDate: DateTimeISOString; startDate: DateTimeISOString } => {
  const systemDates = getSytemReportDateRange(dateRange);
  return {
    endDate: copySystemDateInTimeZone(
      systemDates.endDate,
      timeZone
    ).toISOString(),
    startDate: copySystemDateInTimeZone(
      systemDates.startDate,
      timeZone
    ).toISOString(),
  };
};

export const getReportValueChangeIndicatorState = (
  currentValue: number,
  previousValue: number
): ReportValueChangeIndicatorState => {
  let currencyChangeVariant: 'good' | 'bad' | 'neutral' | undefined;
  let percentageChangeVariant: 'good' | 'bad' | 'neutral' | undefined;

  const currencyChange = currentValue - previousValue || 0; // Prevent negative zero
  if (currencyChange === 0) {
    currencyChangeVariant = 'neutral';
    percentageChangeVariant = 'neutral';
  } else {
    currencyChangeVariant = currencyChange > 0 ? 'good' : 'bad';
    percentageChangeVariant = currencyChange > 0 ? 'good' : 'bad';
  }
  // If we have changed from 0 -> some number, we cannot calculate a sensible
  // percentage change (n/0 = ?), so we return NaN which implies we should
  // show no change value but we still return a variant so we can show a symbol
  // or icon to indicate whether the change was +ve or -ve.
  const percentageChange =
    previousValue === 0 && currentValue !== 0
      ? NaN
      : currencyChange / previousValue || 0; // Prevent negative zer0

  return {
    currencyChange,
    currencyChangeVariant,
    percentageChange,
    percentageChangeVariant,
  };
};

export type ReportRequestBodyFactory<RT extends ReportType> = (
  filterState: ReportTypeToFilterState[RT],
  timeZone: string
) => AnalyticsJobTypeToRequestBody[RT];

export const getBillingBasedRecurringRevenueReportDateRange = (
  filterState: BillingBasedRecurringRevenueReportFilterState,
  timeZone: string
) => {
  // Given a single month and year, we construct a start date and
  // end date that can be used with the API to get the recurring
  // revenue for the month in question.
  const systemStartDate = new Date(
    filterState.reportYear,
    filterState.reportMonth
  );
  const systemEndDate = addMonths(systemStartDate, 1);

  return {
    endDate: copySystemDateInTimeZone(systemEndDate, timeZone).toISOString(),
    startDate: copySystemDateInTimeZone(
      systemStartDate,
      timeZone
    ).toISOString(),
  };
};

export const getBillingBasedRecurringRevenueReportDefaultFilterState = (
  queryAccountCode?: Id
): BillingBasedRecurringRevenueReportFilterState => {
  // Default to last month since the current month isn't guaranteed
  // to have billing data to pull from.
  const initialReportDate = subMonths(startOfMonth(new Date(Date.now())), 1);
  return {
    accounts: queryAccountCode ? [queryAccountCode] : [],
    includeAllAccounts: false,
    prorate: true,
    reportMonth: initialReportDate.getMonth(),
    reportYear: initialReportDate.getFullYear(),
    shiftToUsage: false,
  };
};

export const getMonthOnMonthReportDateRange = (
  filterState: MonthOnMonthReportFilterState,
  timeZone: string
) => {
  // Either given one of the provided date range options or a start month
  // / year and end month / year, we build a start date and end date that
  // can be used with the API to get billing summaries for the date range
  // in question.
  if (filterState.dateRange !== ReportDateRange.Custom) {
    return getReportDateRange(filterState.dateRange, timeZone);
  }

  // Need to check for the months being undefined here since 0 is a
  // valid month when working with the Date constructor.
  // I don't think we care about year 0.
  const canCalculateDateRange =
    filterState.customDateRangeEndMonth !== undefined &&
    !!filterState.customDateRangeEndYear &&
    filterState.customDateRangeStartMonth !== undefined &&
    !!filterState.customDateRangeStartYear;
  if (!canCalculateDateRange) {
    throw new Error(
      'Start and end months / years must be provided if using a custom date range.'
    );
  }

  return {
    endDate: copySystemDateInTimeZone(
      new Date(
        filterState.customDateRangeEndYear!,
        filterState.customDateRangeEndMonth! + 1
      ),
      timeZone
    ).toISOString(),
    startDate: copySystemDateInTimeZone(
      new Date(
        filterState.customDateRangeStartYear!,
        filterState.customDateRangeStartMonth!
      ),
      timeZone
    ).toISOString(),
  };
};

export const getMonthOnMonthReportDefaultFilterState =
  (): MonthOnMonthReportFilterState => {
    // Default to previous month -> this month for a custom report date range
    const endDate = startOfMonth(new Date(Date.now()));
    const startDate = subMonths(endDate, 1);
    return {
      accounts: [],
      customDateRangeEndMonth: endDate.getMonth(),
      customDateRangeEndYear: endDate.getFullYear(),
      customDateRangeStartMonth: startDate.getMonth(),
      customDateRangeStartYear: startDate.getFullYear(),
      dateRange: ReportDateRange.LastTwoMonths,
      lineItemTypes: [],
      products: [],
    };
  };

export const getRecurringRevenueReportDate = (
  filterState: RecurringRevenueReportFilterState,
  timeZone: string
): DateTimeISOString => {
  const systemReportDate = endOfMonth(
    new Date(filterState.reportYear, filterState.reportMonth)
  );
  return copySystemDateInTimeZone(systemReportDate, timeZone).toISOString();
};

export const getRecurringRevenueReportDefaultFilterState = (
  queryAccountCode?: Id
): RecurringRevenueReportFilterState => {
  // Default to last month since the current month isn't guaranteed
  // to have billing data to pull from.
  const initialReportDate = subMonths(startOfMonth(new Date(Date.now())), 1);
  return {
    accounts: queryAccountCode ? [queryAccountCode] : [],
    monthConfig: RecurringRevenueReportMonthConfig.Calendar,
    reportMonth: initialReportDate.getMonth(),
    reportYear: initialReportDate.getFullYear(),
  };
};

export const getTotalContractValueReportDateRange = (
  filterState: TotalContractValueReportFilterState,
  timeZone: string
): { endDate: DateTimeISOString; startDate: DateTimeISOString } => {
  if (filterState.dateRange !== ReportDateRange.Custom) {
    return getReportDateRange(filterState.dateRange, timeZone);
  }

  if (!filterState.customEndDate || !filterState.customStartDate) {
    throw new Error(
      'Start and end dates must be provided if using a custom date range.'
    );
  }
  return {
    endDate: filterState.customEndDate,
    startDate: filterState.customStartDate,
  };
};

export const getTotalContractValueReportDefaultFilterState =
  (): TotalContractValueReportFilterState => {
    const initialCustomDateRange = getSytemReportDateRange(
      ReportDateRange.LastThreeYears
    );
    return {
      accounts: [],
      customEndDate: initialCustomDateRange.endDate.toISOString(),
      customStartDate: initialCustomDateRange.startDate.toISOString(),
      dateRange: ReportDateRange.LastThreeYears,
    };
  };

export const buildBillingBasedRecurringRevenueReportRequestBody: ReportRequestBodyFactory<
  AnalyticsJobType.BillingBasedRecurringRevenueReport
> = (filterState, timeZone) => {
  const { startDate, endDate } = getBillingBasedRecurringRevenueReportDateRange(
    filterState,
    timeZone
  );
  return {
    accountCodes: filterState.accounts,
    includeAllAccounts: filterState.includeAllAccounts,
    period: {
      endDate,
      startDate,
      type: AnalyticsJobTimePeriodType.Custom,
    },
    prorate: filterState.prorate,
    shiftToUsage: filterState.shiftToUsage,
  };
};

export const buildMonthOnMonthReportRequestBody: ReportRequestBodyFactory<
  AnalyticsJobType.MonthOnMonthReport
> = (filterState, timeZone) => {
  const { startDate, endDate } = getMonthOnMonthReportDateRange(
    filterState,
    timeZone
  );
  return {
    accountCodes: filterState.accounts,
    period: {
      endDate,
      startDate,
      type: AnalyticsJobTimePeriodType.Custom,
    },
    productNames: filterState.products,
    lineItemTypes:
      filterState.lineItemTypes.length === 0
        ? Object.values(BillLineItemType)
        : filterState.lineItemTypes,
  };
};

export const buildRecurringRevenueReportRequestBody: ReportRequestBodyFactory<
  AnalyticsJobType.RecurringRevenueReport
> = (filterState, timeZone) => {
  const reportDate = getRecurringRevenueReportDate(filterState, timeZone);
  return {
    accountCodes: filterState.accounts,
    monthConfig: filterState.monthConfig,
    reportDate,
  };
};

export const buildPrepaymentsStatusReportRequestBody: ReportRequestBodyFactory<
  AnalyticsJobType.PrepaymentsStatusReport
> = (filterState) => {
  // Until we get a new endpoint that allows grabbing all active commitments,
  // we are using the data explorer endpoint for this report. This endpoint
  // grabs commitments by filtering for those with a startDate between two
  // dates. To try and capture all relevant commitments, we default to the
  // last 3 years here.
  const now = new Date(Date.now());
  const threeYearsAgo = subYears(now, 3);
  return {
    dimensions: [
      {
        name: 'account',
        filter:
          filterState.accounts.length === 0 ? ['*'] : filterState.accounts,
      },
    ],
    period: {
      endDate: now.toISOString(),
      startDate: threeYearsAgo.toISOString(),
      type: AnalyticsJobTimePeriodType.Custom,
    },
  };
};

export const buildTotalContractValueReportRequestBody: ReportRequestBodyFactory<
  AnalyticsJobType.TotalContractValueReport
> = (filterState, timeZone) => {
  const { endDate, startDate } = getTotalContractValueReportDateRange(
    filterState,
    timeZone
  );
  return {
    accountCodes: filterState.accounts,
    period: {
      endDate,
      startDate,
      type: AnalyticsJobTimePeriodType.Custom,
    },
  };
};
