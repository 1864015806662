import { ColorHues, Colors } from '@chakra-ui/react';

const m3terBlue: Partial<ColorHues> = {
  50: '#E5F3FF',
  100: '#BED7F3',
  200: '#96BCE9',
  300: '#6CA1E0',
  400: '#4586D7',
  500: '#2F6CBE',
  600: '#235495',
  700: '#183C6A',
  800: '#08192D',
  900: '#010C1A',
};

const m3terGreen: Partial<ColorHues> = {
  50: '#EDF7ED',
  100: '#CDE9CD',
  200: '#ADDBAD',
  300: '#8DCE8D',
  400: '#6DC06D',
  500: '#4DB24D',
  600: '#3E8E3E',
  700: '#2E6B2E',
  800: '#1F471F',
  900: '#0F240F',
};

const indigo: Partial<ColorHues> = {
  50: '#ecefff',
  100: '#cbd0ea',
  200: '#a9b1d7',
  300: '#8892c4',
  400: '#6672b2',
  500: '#4d5999',
  600: '#3b4578',
  700: '#2a3157',
  800: '#181e37',
  900: '#070919',
};

const colors: Colors = {
  blue: m3terBlue,
  green: m3terGreen,
  indigo,
};

export default colors;
