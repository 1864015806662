import { WhatsNew } from '@/types/docs';

const DOCS_API_ENDPOINT = 'https://www.m3ter.com/api/docs';

export const getWhatsNew = async (): Promise<Array<WhatsNew>> => {
  const res = await fetch(`${DOCS_API_ENDPOINT}/whats-new`);

  if (res.ok) {
    const json = await res.json();
    return json.data;
  }

  // If something goes wrong we'd rather show nothing than an error.
  return [];
};
