import React, { useMemo } from 'react';

import { DataType, Bill, DebitLineItem } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { useCrudRetrieveData } from '@/components/common/crud/CrudRetrieveContext';
import { DebitLineItemForm } from '@/components/features/billing/DebitLineItemForm/DebitLineItemForm';

export const DebitLineItemsEditRoute: React.FC = () => {
  // These routes have to be under the bill retrieve route
  // so we can apply the debit to that bill.
  const bill = useCrudRetrieveData<Bill>(DataType.Bill);

  const pathParams = useMemo(
    () => ({
      billId: bill.id,
    }),
    [bill]
  );

  return (
    <CrudEdit<DebitLineItem> form={DebitLineItemForm} pathParams={pathParams} />
  );
};
