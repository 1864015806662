import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudRoute } from '@/components/common/crud/CrudRoute';

export const ContractsRoute: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CrudRoute dataType={DataType.Contract} title={t('common:contracts')} />
  );
};
