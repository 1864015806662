import React, {
  createContext,
  useContext,
  useMemo,
  PropsWithChildren,
  ReactElement,
} from 'react';

import {
  DataType,
  Entity,
  PathParams,
  QueryParams,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudActionProps } from '@/types/crud';
import {
  ListFilterDefinition,
  ListSearchCriteria,
  ListSearchOperator,
  ListSortCriteria,
} from '@/types/lists';

import { Accessor } from '@/util/data';
import useEntityNamings, { EntityNamings } from '@/hooks/util/useEntityNamings';
import useListSizePreference, {
  UseListSizePreferenceReturn,
} from '@/hooks/data/useListSizePreference';
import useEntityList, {
  UseEntityListOptions,
  UseEntityListReturn,
} from '@/hooks/data/crud/useEntityList';
import { useCrudContext } from '@/components/common/crud/CrudContext';

export interface CrudListContextValues<E extends Entity>
  extends CrudActionProps<E>,
    UseEntityListReturn<E>,
    UseListSizePreferenceReturn {
  actionName?: string;
  createRouteLabel: string;
  createRouteParams?: Record<string, any>;
  dataType: DataType;
  editRouteName?: string;
  entityNamings: EntityNamings;
  filterDefinitions?: Array<ListFilterDefinition<E>>;
  isItemDeletable?: Accessor<E, boolean>;
  isItemEditable?: Accessor<E, boolean>;
  knownPageCount: number;
  listId: string;
  onDelete?: (entity: E) => void;
  params?: QueryParams;
  pathParams?: PathParams;
  relationships?: Array<string>;
  searchFields?: Array<string>;
}

export interface CrudListContextProviderProps<E extends Entity>
  extends CrudActionProps<E> {
  actionName?: string;
  createRouteLabel?: string;
  createRouteParams?: Record<string, any>;
  dataType?: DataType;
  filterDefinitions?: Array<ListFilterDefinition<E>>;
  initialFilterCriteria?: ListSearchCriteria;
  initialSearchCriteria?: ListSearchCriteria;
  initialSortCriteria?: ListSortCriteria;
  isItemDeletable?: Accessor<E, boolean>;
  isItemEditable?: Accessor<E, boolean>;
  listId: string;
  loadOnMount?: boolean;
  params?: QueryParams;
  pathParams?: PathParams;
  relationships?: Array<string>;
  searchFields?: Array<string>;
  searchOperator?: ListSearchOperator;
}

const CrudListContext = createContext<CrudListContextValues<any> | null>(null);

export function CrudListContextProvider<E extends Entity>({
  children,
  actionName,
  createRouteName: propsCreateRouteName,
  createRouteLabel,
  dataType: propsDataType,
  detailsRouteName: propsDetailsRouteName,
  editRouteName: propsEditRouteName,
  initialFilterCriteria,
  initialSearchCriteria,
  initialSortCriteria,
  listId,
  loadOnMount,
  onDelete: propsOnDelete,
  params,
  pathParams,
  relationships,
  searchFields,
  searchOperator,
  ...passThroughProps
}: PropsWithChildren<CrudListContextProviderProps<E>>): ReactElement<
  any,
  any
> | null {
  const { t } = useTranslation();

  const crudContext = useCrudContext<E>();
  const useContextCrudData = !propsDataType;
  const createRouteName = useContextCrudData
    ? crudContext.createRouteName
    : propsCreateRouteName;
  const dataType = useContextCrudData ? crudContext.dataType : propsDataType;
  const detailsRouteName = useContextCrudData
    ? crudContext.detailsRouteName
    : propsDetailsRouteName;
  const editRouteName = useContextCrudData
    ? crudContext.editRouteName
    : propsEditRouteName;
  const onDelete = useContextCrudData ? crudContext.onDelete : propsOnDelete;

  const { listSize, onListSizeChange } = useListSizePreference(listId);
  const listQueryParams = useMemo<QueryParams>(
    () => ({
      pageSize: listSize,
      ...params,
    }),
    [listSize, params]
  );

  const listOptions = useMemo<UseEntityListOptions>(
    () => ({
      actionName,
      initialFilterCriteria,
      initialSearchCriteria,
      initialSortCriteria,
      loadOnMount,
      pathParams,
      queryParams: listQueryParams,
      relationships,
      searchOperator,
    }),
    [
      actionName,
      initialFilterCriteria,
      initialSearchCriteria,
      initialSortCriteria,
      listQueryParams,
      loadOnMount,
      pathParams,
      relationships,
      searchOperator,
    ]
  );
  const {
    allEntities,
    currentPage,
    currentPageEntities,
    error,
    filterCriteria,
    filterList,
    hasMore,
    isLoading,
    knownPageCount,
    loadList,
    loadNextListPage,
    loadSpecificListPage,
    refreshList,
    searchCriteria,
    searchList,
    sortCriteria,
    sortList,
  } = useEntityList<E>(dataType, listId, listOptions);

  const entityNamings = useEntityNamings(dataType);

  const contextValue = useMemo<CrudListContextValues<E>>(
    () => ({
      actionName,
      allEntities,
      currentPage,
      currentPageEntities,
      createRouteName,
      createRouteLabel:
        createRouteLabel ??
        t('forms:buttons.createEntity', {
          entityName: entityNamings.singular,
        }),
      dataType,
      detailsRouteName,
      editRouteName,
      entityNamings,
      error,
      filterCriteria,
      filterList,
      hasMore,
      isLoading,
      knownPageCount,
      listId,
      listSize,
      loadList,
      loadNextListPage,
      loadSpecificListPage,
      onDelete,
      onListSizeChange,
      params,
      pathParams,
      refreshList,
      relationships,
      searchFields,
      searchCriteria,
      searchList,
      sortCriteria,
      sortList,
      ...passThroughProps,
    }),
    [
      actionName,
      allEntities,
      currentPage,
      currentPageEntities,
      createRouteName,
      createRouteLabel,
      dataType,
      detailsRouteName,
      editRouteName,
      entityNamings,
      error,
      filterCriteria,
      filterList,
      hasMore,
      isLoading,
      knownPageCount,
      listId,
      listSize,
      loadList,
      loadNextListPage,
      loadSpecificListPage,
      onDelete,
      onListSizeChange,
      params,
      pathParams,
      refreshList,
      relationships,
      searchFields,
      searchCriteria,
      searchList,
      sortCriteria,
      sortList,
      passThroughProps,
      t,
    ]
  );

  return (
    <CrudListContext.Provider value={contextValue}>
      {children}
    </CrudListContext.Provider>
  );
}

export const useCrudListContext = <E extends Entity>() =>
  useContext(CrudListContext) as CrudListContextValues<E>;
