import { SystemStyleObject, ComponentMultiStyleConfig } from '@chakra-ui/react';
import { SystemStyleFunction } from '@chakra-ui/theme-tools';

const parts = [
  'item',
  'link',
  'icon',
  'group',
  'groupTitle',
  'header',
  'divider',
];

const baseStyleItem: SystemStyleFunction = (props) => ({
  marginBottom: 2,
  paddingTop: 2,
  paddingRight: 2,
  paddingBottom: 2,
  paddingLeft: 6,
  borderRadius: '20px 0 0 20px',
  color: 'chakra-body-text',
  bg: props.active ? 'chakra-body-bg' : 'transparent',
  _hover: {
    color: 'green-adaptive',
    bg: 'chakra-body-bg',
  },
});

const baseStyleLink: SystemStyleFunction = (props) => ({
  ...baseStyleItem(props),
  display: 'block',
});

const baseStyleIcon: SystemStyleObject = {
  display: 'inline-block',
  marginRight: 2,
  verticalAlign: 'middle',
};

const baseStyleGroupTitle: SystemStyleFunction = (props) => ({
  ...baseStyleItem(props),
});

const baseStyleGroupPanel: SystemStyleObject = {
  paddingLeft: 6,
};

const baseStyleHeader: SystemStyleObject = {
  textTransform: 'uppercase',
  fontWeight: 'bold',
};

const baseStyleDivider: SystemStyleObject = {
  border: 0,
  borderBottom: '2px solid',
  borderColor: 'chakra-border-color',
  my: 1,
};

const navigation: ComponentMultiStyleConfig = {
  parts,
  baseStyle: (props) => ({
    link: baseStyleLink(props),
    icon: baseStyleIcon,
    groupTitle: baseStyleGroupTitle(props),
    groupPanel: baseStyleGroupPanel,
    header: baseStyleHeader,
    divider: baseStyleDivider,
  }),
};

export default navigation;
