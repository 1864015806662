import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DataType,
  Id,
  Aggregation,
  CompoundAggregation,
  Counter,
  CounterPricing,
  Plan,
  PlanTemplate,
  Pricing,
} from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

import { uniq } from '@/util/array';
import { createSelectByIds } from '@/store/data/data';

export interface PlanEditorState {
  isLoading: boolean;
  isRestoring: boolean;
  isAddPlansLoading: boolean;
  isAddUsageEntityLoading: boolean;
  isDuplicationInProgress: boolean;
  selectedPlanIds: Array<string>;
  selectedPlanTemplateIds: Array<string>;
  linkedPlanTemplateIds: Array<string>;
  requiredAggregationIds: Array<string>;
  selectedAggregationIds: Array<string>;
  requiredCompoundAggregationIds: Array<string>;
  selectedCompoundAggregationIds: Array<string>;
  requiredItemCounterIds: Array<string>;
  selectedItemCounterIds: Array<string>;
  pricingIds: Array<string>;
  itemCounterPricingIds: Array<string>;
  error?: AppError;
  missingDataTypes?: Array<DataType>;
}

interface LoadInitialDataPayload {
  productId: string;
  addPlanId?: string;
  addPlanTemplateId?: string;
}
export type LoadInitialDataAction = PayloadAction<LoadInitialDataPayload>;

interface LoadInitialDataSuccessPayload {
  missingDataTypes: Array<DataType>;
}
export type LoadInitialDataSuccessAction =
  PayloadAction<LoadInitialDataSuccessPayload>;

export type LoadInitialDataFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

interface RestoreSelectionsPayload {
  productId: string;
}
export type RestoreSelectionsAction = PayloadAction<RestoreSelectionsPayload>;

interface RestoreSelectionsSuccessPayload {
  requiredAggregationIds: Array<string>;
  selectedAggregationIds: Array<string>;
  requiredCompoundAggregationIds: Array<string>;
  selectedCompoundAggregationIds: Array<string>;
  requiredItemCounterIds: Array<string>;
  selectedItemCounterIds: Array<string>;
  linkedPlanTemplateIds: Array<string>;
  planIds: Array<string>;
  planTemplateIds: Array<string>;
  pricingIds: Array<string>;
  itemCounterPricingIds: Array<string>;
}

export type RestoreSelectionsSuccessAction =
  PayloadAction<RestoreSelectionsSuccessPayload>;

interface AddPlansPayload {
  planIds: Array<string>;
}

export type AddPlansAction = PayloadAction<AddPlansPayload>;

interface AddPlansSuccessPayload {
  aggregationIds: Array<string>;
  compoundAggregationIds: Array<string>;
  itemCounterIds: Array<string>;
  linkedPlanTemplateIds: Array<string>;
  planIds: Array<string>;
  pricingIds: Array<string>;
  itemCounterPricingIds: Array<string>;
}

export type AddPlansSuccessAction = PayloadAction<AddPlansSuccessPayload>;

interface RemovePlanPayload {
  planId: string;
}

export type RemovePlanAction = PayloadAction<RemovePlanPayload>;

interface AddPlanTemplatesPayload {
  planTemplateIds: Array<string>;
}

export type AddPlanTemplatesAction = PayloadAction<AddPlanTemplatesPayload>;

interface AddPlanTemplatesSuccessPayload {
  aggregationIds: Array<string>;
  compoundAggregationIds: Array<string>;
  itemCounterIds: Array<string>;
  planTemplateIds: Array<string>;
  pricingIds: Array<string>;
  itemCounterPricingIds: Array<string>;
}

export type AddPlanTemplatesSuccessAction =
  PayloadAction<AddPlanTemplatesSuccessPayload>;

interface RemovePlanTemplatePayload {
  planTemplateId: string;
}

export type RemovePlanTemplateAction = PayloadAction<RemovePlanTemplatePayload>;
export type RemoveLinkedPlanTemplateAction =
  PayloadAction<RemovePlanTemplatePayload>;

interface AddAggregationPayload {
  aggregationIds: Array<string>;
}

export type AddAggregationAction = PayloadAction<AddAggregationPayload>;

interface AddAggregationSuccessPayload {
  aggregationIds: Array<string>;
}

export type AddAggregationSuccessAction =
  PayloadAction<AddAggregationSuccessPayload>;

interface AddCompoundAggregationPayload {
  compoundAggregationIds: Array<string>;
}

export type AddCompoundAggregationAction =
  PayloadAction<AddCompoundAggregationPayload>;

interface AddCompoundAggregationSuccessPayload {
  compoundAggregationIds: Array<string>;
}

export type AddCompoundAggregationSuccessAction =
  PayloadAction<AddCompoundAggregationSuccessPayload>;

interface AddItemCounterPayload {
  itemCounterIds: Array<string>;
}

export type AddItemCounterAction = PayloadAction<AddItemCounterPayload>;

interface AddItemCounterSuccessPayload {
  itemCounterIds: Array<string>;
}

export type AddItemCounterSuccessAction =
  PayloadAction<AddItemCounterSuccessPayload>;

interface DuplicatePlanPayload {
  planId: string;
  newCode: string;
  newName: string;
}

export type DuplicatePlanAction = PayloadAction<DuplicatePlanPayload>;

interface DuplicatePlanSuccessPayload {
  newPlanId: string;
  newPricingIds: Array<string>;
  newItemCounterPricingIds: Array<string>;
}

export type DuplicatePlanSuccessAction =
  PayloadAction<DuplicatePlanSuccessPayload>;

interface DuplicatePlanTemplatePayload {
  planTemplateId: string;
  newName: string;
  newCode?: string;
}

export type DuplicatePlanTemplateAction =
  PayloadAction<DuplicatePlanTemplatePayload>;

interface DuplicatePlanTemplateSuccessPayload {
  newPlanTemplateId: string;
  newPricingIds: Array<string>;
  newItemCounterPricingIds: Array<string>;
}

export type DuplicatePlanTemplateSuccessAction =
  PayloadAction<DuplicatePlanTemplateSuccessPayload>;

export type DuplicationFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

interface AddPricingPayload {
  pricingId: string;
}

export type AddPricingAction = PayloadAction<AddPricingPayload>;

interface AddItemCounterPricingPayload {
  itemCounterPricingId: string;
}

export type AddItemCounterPricingAction =
  PayloadAction<AddItemCounterPricingPayload>;

interface RemovePricingsPayload {
  pricingIds: Array<Id>;
  itemCounterPricingIds: Array<Id>;
}

export type RemovePricingsAction = PayloadAction<RemovePricingsPayload>;

export enum RemoveFromLocation {
  All = 'ALL',
  Required = 'REQUIRED',
  UserSelected = 'USER_SELECTED',
}
interface RemoveUsageEnititesPayload {
  aggregationIds: Array<Id>;
  compoundAggregationIds: Array<Id>;
  itemCounterIds: Array<Id>;
  removeFrom: RemoveFromLocation;
}

export type RemoveUsageEntitiesAction =
  PayloadAction<RemoveUsageEnititesPayload>;

type KeysWithIds = keyof {
  [P in keyof PlanEditorState as PlanEditorState[P] extends Array<string>
    ? P
    : never]: P;
};

const name = 'features/pricing/planEditor';

// Merges IDs without adding duplicates.
const mergeIds = (
  state: PlanEditorState,
  key: KeysWithIds,
  ids: Array<string>
) => {
  if (ids.length > 0) {
    state[key] = uniq([...state[key], ...ids]);
  }
};

const filterIds = (sourceIds: Array<Id>, idsToRemove: Array<Id>): Array<Id> =>
  sourceIds.filter((id) => !idsToRemove.includes(id));

const shouldRemove = (
  removeFrom: RemoveFromLocation,
  location: RemoveFromLocation
): boolean => removeFrom === RemoveFromLocation.All || removeFrom === location;

const updateStateIds = (
  state: PlanEditorState,
  ids: Array<Id>,
  removeFrom: RemoveFromLocation,
  selectedKey: KeysWithIds,
  requiredKey: KeysWithIds
): void => {
  if (ids.length > 0) {
    if (shouldRemove(removeFrom, RemoveFromLocation.UserSelected)) {
      state[selectedKey] = filterIds(state[selectedKey], ids);
    }
    if (shouldRemove(removeFrom, RemoveFromLocation.Required)) {
      state[requiredKey] = filterIds(state[requiredKey], ids);
    }
  }
};

const initialState: PlanEditorState = {
  isLoading: false,
  isRestoring: false,
  isAddPlansLoading: false,
  isAddUsageEntityLoading: false,
  isDuplicationInProgress: false,
  linkedPlanTemplateIds: [],
  requiredAggregationIds: [],
  selectedAggregationIds: [],
  requiredCompoundAggregationIds: [],
  selectedCompoundAggregationIds: [],
  requiredItemCounterIds: [],
  selectedItemCounterIds: [],
  selectedPlanIds: [],
  selectedPlanTemplateIds: [],
  pricingIds: [],
  itemCounterPricingIds: [],
};

const planEditorSlice = createSlice({
  name,
  initialState,
  reducers: {
    loadInitialData: {
      reducer: (state: PlanEditorState, _action: LoadInitialDataAction) => {
        state.isLoading = true;
        state.error = undefined;
      },
      prepare: (
        productId: string,
        addPlanId?: string,
        addPlanTemplateId?: string
      ) => ({
        payload: { productId, addPlanId, addPlanTemplateId },
      }),
    },
    loadInitialDataSuccess: {
      reducer: (
        state: PlanEditorState,
        action: LoadInitialDataSuccessAction
      ) => {
        state.isLoading = false;
        state.missingDataTypes = action.payload.missingDataTypes;
      },
      prepare: (missingDataTypes: Array<DataType>) => ({
        payload: { missingDataTypes },
      }),
    },
    loadInitialDataFailure: {
      reducer: (
        state: PlanEditorState,
        action: LoadInitialDataFailureAction
      ) => {
        state.isLoading = false;
        state.error = action.error;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    restoreSelections: {
      reducer: (state: PlanEditorState, _action: RestoreSelectionsAction) => {
        state.isRestoring = true;
      },
      prepare: (productId: string) => ({
        payload: { productId },
      }),
    },
    restoreSelectionsSuccess: {
      reducer: (
        state: PlanEditorState,
        action: RestoreSelectionsSuccessAction
      ) => {
        const {
          planIds,
          planTemplateIds,
          linkedPlanTemplateIds,
          requiredAggregationIds,
          selectedAggregationIds,
          requiredCompoundAggregationIds,
          selectedCompoundAggregationIds,
          requiredItemCounterIds,
          selectedItemCounterIds,
          pricingIds,
          itemCounterPricingIds,
        } = action.payload;

        state.isRestoring = false;

        mergeIds(state, 'requiredAggregationIds', requiredAggregationIds);
        mergeIds(state, 'selectedAggregationIds', selectedAggregationIds);
        mergeIds(
          state,
          'requiredCompoundAggregationIds',
          requiredCompoundAggregationIds
        );
        mergeIds(
          state,
          'selectedCompoundAggregationIds',
          selectedCompoundAggregationIds
        );
        mergeIds(state, 'requiredItemCounterIds', requiredItemCounterIds);
        mergeIds(state, 'selectedItemCounterIds', selectedItemCounterIds);
        mergeIds(state, 'linkedPlanTemplateIds', linkedPlanTemplateIds);
        mergeIds(state, 'selectedPlanIds', planIds);
        mergeIds(state, 'selectedPlanTemplateIds', planTemplateIds);
        mergeIds(state, 'pricingIds', pricingIds);
        mergeIds(state, 'itemCounterPricingIds', itemCounterPricingIds);
      },
      prepare: (
        requiredAggregationIds: Array<string> = [],
        selectedAggregationIds: Array<string> = [],
        requiredCompoundAggregationIds: Array<string> = [],
        selectedCompoundAggregationIds: Array<string> = [],
        requiredItemCounterIds: Array<string> = [],
        selectedItemCounterIds: Array<string> = [],
        linkedPlanTemplateIds: Array<string> = [],
        planIds: Array<string> = [],
        planTemplateIds: Array<string> = [],
        pricingIds: Array<string> = [],
        itemCounterPricingIds: Array<string> = []
      ) => ({
        payload: {
          requiredAggregationIds,
          selectedAggregationIds,
          requiredCompoundAggregationIds,
          selectedCompoundAggregationIds,
          requiredItemCounterIds,
          selectedItemCounterIds,
          linkedPlanTemplateIds,
          planIds,
          planTemplateIds,
          pricingIds,
          itemCounterPricingIds,
        },
      }),
    },
    addPlans: {
      reducer: (state: PlanEditorState, _action: AddPlansAction) => {
        state.isAddPlansLoading = true;
      },
      prepare: (planIds: Array<string>) => ({ payload: { planIds } }),
    },
    addPlansSuccess: {
      reducer: (state: PlanEditorState, action: AddPlansSuccessAction) => {
        const {
          aggregationIds,
          compoundAggregationIds,
          itemCounterIds,
          linkedPlanTemplateIds,
          planIds,
          pricingIds,
          itemCounterPricingIds,
        } = action.payload;

        state.isAddPlansLoading = false;
        state.error = undefined;

        mergeIds(state, 'requiredAggregationIds', aggregationIds);
        mergeIds(
          state,
          'requiredCompoundAggregationIds',
          compoundAggregationIds
        );
        mergeIds(state, 'requiredItemCounterIds', itemCounterIds);
        mergeIds(state, 'selectedPlanIds', planIds);
        mergeIds(state, 'linkedPlanTemplateIds', linkedPlanTemplateIds);
        mergeIds(state, 'pricingIds', pricingIds);
        mergeIds(state, 'itemCounterPricingIds', itemCounterPricingIds);
      },
      prepare: (
        aggregationIds: Array<string>,
        compoundAggregationIds: Array<string>,
        itemCounterIds: Array<string>,
        linkedPlanTemplateIds: Array<string>,
        planIds: Array<string>,
        pricingIds: Array<string>,
        itemCounterPricingIds: Array<string>
      ) => ({
        payload: {
          aggregationIds,
          compoundAggregationIds,
          itemCounterIds,
          linkedPlanTemplateIds,
          planIds,
          pricingIds,
          itemCounterPricingIds,
        },
      }),
    },
    removePlan: {
      reducer: (state: PlanEditorState, action: RemovePlanAction) => {
        const { planId } = action.payload;
        state.selectedPlanIds = state.selectedPlanIds.filter(
          (id) => id !== planId
        );
      },
      prepare: (planId: string) => ({ payload: { planId } }),
    },
    addPlanTemplates: {
      reducer: (state: PlanEditorState, _action: AddPlanTemplatesAction) => {
        state.isAddPlansLoading = true;
      },
      prepare: (planTemplateIds: Array<string>) => ({
        payload: { planTemplateIds },
      }),
    },
    addPlanTemplatesSuccess: {
      reducer: (
        state: PlanEditorState,
        action: AddPlanTemplatesSuccessAction
      ) => {
        const {
          aggregationIds,
          compoundAggregationIds,
          itemCounterIds,
          planTemplateIds,
          pricingIds,
          itemCounterPricingIds,
        } = action.payload;

        state.isAddPlansLoading = false;
        state.error = undefined;

        mergeIds(state, 'requiredAggregationIds', aggregationIds);
        mergeIds(
          state,
          'requiredCompoundAggregationIds',
          compoundAggregationIds
        );
        mergeIds(state, 'requiredItemCounterIds', itemCounterIds);
        mergeIds(state, 'selectedPlanTemplateIds', planTemplateIds);
        mergeIds(state, 'pricingIds', pricingIds);
        mergeIds(state, 'itemCounterPricingIds', itemCounterPricingIds);
      },
      prepare: (
        aggregationIds: Array<string>,
        compoundAggregationIds: Array<string>,
        itemCounterIds: Array<string>,
        planTemplateIds: Array<string>,
        pricingIds: Array<string>,
        itemCounterPricingIds: Array<string>
      ) => ({
        payload: {
          aggregationIds,
          compoundAggregationIds,
          itemCounterIds,
          planTemplateIds,
          pricingIds,
          itemCounterPricingIds,
        },
      }),
    },
    removePlanTemplate: {
      reducer: (state: PlanEditorState, action: RemovePlanTemplateAction) => {
        const { planTemplateId } = action.payload;
        state.selectedPlanTemplateIds = state.selectedPlanTemplateIds.filter(
          (id) => id !== planTemplateId
        );
      },
      prepare: (planTemplateId: string) => ({ payload: { planTemplateId } }),
    },
    removeLinkedPlanTemplate: {
      reducer: (
        state: PlanEditorState,
        action: RemoveLinkedPlanTemplateAction
      ) => {
        const { planTemplateId } = action.payload;
        state.linkedPlanTemplateIds = state.linkedPlanTemplateIds.filter(
          (id) => id !== planTemplateId
        );
      },
      prepare: (planTemplateId: string) => ({ payload: { planTemplateId } }),
    },
    addAggregations: {
      reducer: (state: PlanEditorState, _action: AddAggregationAction) => {
        state.isAddUsageEntityLoading = true;
      },
      prepare: (aggregationIds: Array<string>) => ({
        payload: { aggregationIds },
      }),
    },
    addAggregationsSuccess: {
      reducer: (
        state: PlanEditorState,
        action: AddAggregationSuccessAction
      ) => {
        const { aggregationIds } = action.payload;
        state.isAddUsageEntityLoading = false;
        state.error = undefined;

        mergeIds(state, 'selectedAggregationIds', aggregationIds);
      },
      prepare: (aggregationIds: Array<string>) => ({
        payload: { aggregationIds },
      }),
    },
    addCompoundAggregations: {
      reducer: (
        state: PlanEditorState,
        _action: AddCompoundAggregationAction
      ) => {
        state.isAddUsageEntityLoading = true;
      },
      prepare: (compoundAggregationIds: Array<string>) => ({
        payload: { compoundAggregationIds },
      }),
    },
    addCompoundAggregationsSuccess: {
      reducer: (
        state: PlanEditorState,
        action: AddCompoundAggregationSuccessAction
      ) => {
        const { compoundAggregationIds } = action.payload;
        state.isAddUsageEntityLoading = false;
        state.error = undefined;

        mergeIds(
          state,
          'selectedCompoundAggregationIds',
          compoundAggregationIds
        );
      },
      prepare: (compoundAggregationIds: Array<string>) => ({
        payload: { compoundAggregationIds },
      }),
    },
    addItemCounters: {
      reducer: (state: PlanEditorState, _action: AddItemCounterAction) => {
        state.isAddUsageEntityLoading = true;
      },
      prepare: (itemCounterIds: Array<string>) => ({
        payload: { itemCounterIds },
      }),
    },
    addItemCountersSuccess: {
      reducer: (
        state: PlanEditorState,
        action: AddItemCounterSuccessAction
      ) => {
        const { itemCounterIds } = action.payload;
        state.isAddUsageEntityLoading = false;
        state.error = undefined;

        mergeIds(state, 'selectedItemCounterIds', itemCounterIds);
      },
      prepare: (itemCounterIds: Array<string>) => ({
        payload: { itemCounterIds },
      }),
    },
    removeUsageEntities: {
      reducer: (state: PlanEditorState, action: RemoveUsageEntitiesAction) => {
        const {
          aggregationIds,
          compoundAggregationIds,
          itemCounterIds,
          removeFrom,
        } = action.payload;

        updateStateIds(
          state,
          aggregationIds,
          removeFrom,
          'selectedAggregationIds',
          'requiredAggregationIds'
        );
        updateStateIds(
          state,
          compoundAggregationIds,
          removeFrom,
          'selectedCompoundAggregationIds',
          'requiredCompoundAggregationIds'
        );
        updateStateIds(
          state,
          itemCounterIds,
          removeFrom,
          'selectedItemCounterIds',
          'requiredItemCounterIds'
        );
      },
      prepare: (
        aggregationIds: Array<Id>,
        compoundAggregationIds: Array<Id>,
        itemCounterIds: Array<Id>,
        removeFrom: RemoveFromLocation = RemoveFromLocation.All
      ) => ({
        payload: {
          aggregationIds,
          compoundAggregationIds,
          itemCounterIds,
          removeFrom,
        },
      }),
    },
    duplicatePlan: {
      reducer: (state: PlanEditorState, _action: DuplicatePlanAction) => {
        state.isDuplicationInProgress = true;
      },
      prepare: (planId: Id, newName: string, newCode: string) => ({
        payload: { planId, newName, newCode },
      }),
    },
    duplicatePlanSuccess: {
      reducer: (state: PlanEditorState, action: DuplicatePlanSuccessAction) => {
        const { newPlanId, newPricingIds, newItemCounterPricingIds } =
          action.payload;
        state.isDuplicationInProgress = false;
        // We don't need to merge IDs as they will all be new.
        state.selectedPlanIds.push(newPlanId);
        state.pricingIds.push(...newPricingIds);
        state.itemCounterPricingIds.push(...newItemCounterPricingIds);
        state.error = undefined;
      },
      prepare: (
        newPlanId: Id,
        newPricingIds: Array<Id>,
        newItemCounterPricingIds: Array<Id>
      ) => ({
        payload: { newPlanId, newPricingIds, newItemCounterPricingIds },
      }),
    },
    duplicatePlanTemplate: {
      reducer: (
        state: PlanEditorState,
        _action: DuplicatePlanTemplateAction
      ) => {
        state.isDuplicationInProgress = true;
      },
      prepare: (planTemplateId: Id, newName: string, newCode?: string) => ({
        payload: { planTemplateId, newName, newCode },
      }),
    },
    duplicatePlanTemplateSuccess: {
      reducer: (
        state: PlanEditorState,
        action: DuplicatePlanTemplateSuccessAction
      ) => {
        const { newPlanTemplateId, newPricingIds, newItemCounterPricingIds } =
          action.payload;
        state.isDuplicationInProgress = false;
        // We don't need to merge IDs as they will all be new.
        state.selectedPlanTemplateIds.push(newPlanTemplateId);
        state.pricingIds.push(...newPricingIds);
        state.itemCounterPricingIds.push(...newItemCounterPricingIds);
        state.error = undefined;
      },
      prepare: (
        newPlanTemplateId: Id,
        newPricingIds: Array<Id>,
        newItemCounterPricingIds: Array<Id>
      ) => ({
        payload: { newPlanTemplateId, newPricingIds, newItemCounterPricingIds },
      }),
    },
    duplicationFailure: {
      reducer: (state: PlanEditorState, action: DuplicationFailureAction) => {
        state.error = action.error;
        state.isDuplicationInProgress = false;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    addPricing: {
      reducer: (state: PlanEditorState, action: AddPricingAction) => {
        const { pricingId } = action.payload;
        state.pricingIds.push(pricingId);
      },
      prepare: (pricingId: Id) => ({
        payload: { pricingId, dataType: DataType.Pricing },
      }),
    },
    addItemCounterPricing: {
      reducer: (
        state: PlanEditorState,
        action: AddItemCounterPricingAction
      ) => {
        const { itemCounterPricingId } = action.payload;
        state.itemCounterPricingIds.push(itemCounterPricingId);
      },
      prepare: (itemCounterPricingId: Id) => ({
        payload: {
          itemCounterPricingId,
          dataType: DataType.CounterPricing,
        },
      }),
    },
    removePricings: {
      reducer: (state: PlanEditorState, action: RemovePricingsAction) => {
        const { pricingIds, itemCounterPricingIds } = action.payload;
        state.pricingIds = state.pricingIds.filter(
          (id) => !pricingIds.includes(id)
        );
        state.itemCounterPricingIds = state.itemCounterPricingIds.filter(
          (id) => !itemCounterPricingIds.includes(id)
        );
      },
      prepare: (pricingIds: Array<Id>, itemCounterPricingIds: Array<Id>) => ({
        payload: { pricingIds, itemCounterPricingIds },
      }),
    },
    reset: () => initialState,
  },
});

// Export actions.

export const {
  reset,
  loadInitialData,
  loadInitialDataSuccess,
  loadInitialDataFailure,
  restoreSelections,
  restoreSelectionsSuccess,
  addPlans,
  addPlansSuccess,
  removePlan,
  addPlanTemplates,
  addPlanTemplatesSuccess,
  removePlanTemplate,
  removeLinkedPlanTemplate,
  addAggregations,
  addAggregationsSuccess,
  addCompoundAggregations,
  addCompoundAggregationsSuccess,
  addItemCounters,
  addItemCountersSuccess,
  removeUsageEntities,
  duplicatePlan,
  duplicatePlanSuccess,
  duplicatePlanTemplate,
  duplicatePlanTemplateSuccess,
  duplicationFailure,
  addPricing,
  addItemCounterPricing,
  removePricings,
} = planEditorSlice.actions;

// Selectors

const selectPlanEditorState = (state: {
  features: { pricing: { planEditor: PlanEditorState } };
}): PlanEditorState => state.features.pricing.planEditor;

export const selectIsLoading = createSelector(
  selectPlanEditorState,
  (planEditorState) => planEditorState.isLoading
);

export const selectIsRestoring = createSelector(
  selectPlanEditorState,
  (planEditorState) => planEditorState.isRestoring
);

export const selectMissingDataTypes = createSelector(
  selectPlanEditorState,
  (planEditorState) => planEditorState.missingDataTypes
);

export const selectIsAddPlansLoading = createSelector(
  selectPlanEditorState,
  (planEditorState) => planEditorState.isAddPlansLoading
);

export const selectIsAddUsageEntitiesLoading = createSelector(
  selectPlanEditorState,
  (planEditorState) => planEditorState.isAddUsageEntityLoading
);

export const selectSelectedPlanIds = createSelector(
  selectPlanEditorState,
  (planEditorState) => planEditorState.selectedPlanIds
);

export const selectSelectedPlans = createSelector(
  selectSelectedPlanIds,
  createSelectByIds<Plan>(DataType.Plan),
  (planIds, selectByIds) => selectByIds(planIds)
);

export const selectSelectedPlanTemplateIds = createSelector(
  selectPlanEditorState,
  (planEditorState) => planEditorState.selectedPlanTemplateIds
);

export const selectSelectedPlanTemplates = createSelector(
  selectSelectedPlanTemplateIds,
  createSelectByIds<PlanTemplate>(DataType.PlanTemplate),
  (planTemplateIds, selectByIds) => selectByIds(planTemplateIds)
);

export const selectLinkedPlanTemplateIds = createSelector(
  selectPlanEditorState,
  (planEditorState) => planEditorState.linkedPlanTemplateIds
);

export const selectLinkedPlanTemplates = createSelector(
  selectLinkedPlanTemplateIds,
  createSelectByIds<PlanTemplate>(DataType.PlanTemplate),
  (planTemplateIds, selectByIds) => selectByIds(planTemplateIds)
);

export const selectRequiredAggregationIds = createSelector(
  selectPlanEditorState,
  (planEditorState) => planEditorState.requiredAggregationIds
);

export const selectSelectedAggregationIds = createSelector(
  selectPlanEditorState,
  (planEditorState) => planEditorState.selectedAggregationIds
);

export const selectCombinedAggregationIds = createSelector(
  selectRequiredAggregationIds,
  selectSelectedAggregationIds,
  (requiredAggregationIds, selectedAggregationIds) =>
    uniq([...requiredAggregationIds, ...selectedAggregationIds])
);

export const selectRequiredAggregations = createSelector(
  selectRequiredAggregationIds,
  createSelectByIds<Aggregation>(DataType.Aggregation),
  (aggregationIds, selectByIds) => selectByIds(aggregationIds)
);

export const selectSelectedAggregations = createSelector(
  selectSelectedAggregationIds,
  createSelectByIds<Aggregation>(DataType.Aggregation),
  (aggregationIds, selectByIds) => selectByIds(aggregationIds)
);

export const selectCombinedAggregations = createSelector(
  selectCombinedAggregationIds,
  createSelectByIds<Aggregation>(DataType.Aggregation),
  (aggregationIds, selectByIds) => selectByIds(aggregationIds)
);

export const selectRequiredCompoundAggregationIds = createSelector(
  selectPlanEditorState,
  (state) => state.requiredCompoundAggregationIds
);

export const selectSelectedCompoundAggregationIds = createSelector(
  selectPlanEditorState,
  (state) => state.selectedCompoundAggregationIds
);

export const selectCombinedCompoundAggregationsIds = createSelector(
  selectRequiredCompoundAggregationIds,
  selectSelectedCompoundAggregationIds,
  (requiredCompoundAggregationIds, selectedCompoundAggregationIds) =>
    uniq([...requiredCompoundAggregationIds, ...selectedCompoundAggregationIds])
);

export const selectRequiredCompoundAggregations = createSelector(
  selectRequiredCompoundAggregationIds,
  createSelectByIds<CompoundAggregation>(DataType.CompoundAggregation),
  (compoundAggregationIds, selectByIds) => selectByIds(compoundAggregationIds)
);

export const selectSelectedCompoundAggregations = createSelector(
  selectSelectedCompoundAggregationIds,
  createSelectByIds<CompoundAggregation>(DataType.CompoundAggregation),
  (compoundAggregationIds, selectByIds) => selectByIds(compoundAggregationIds)
);

export const selectCombinedCompoundAggregations = createSelector(
  selectCombinedCompoundAggregationsIds,
  createSelectByIds<CompoundAggregation>(DataType.CompoundAggregation),
  (compoundAggregationIds, selectByIds) => selectByIds(compoundAggregationIds)
);

export const selectRequiredItemCounterIds = createSelector(
  selectPlanEditorState,
  (state) => state.requiredItemCounterIds
);

export const selectSelectedItemCounterIds = createSelector(
  selectPlanEditorState,
  (state) => state.selectedItemCounterIds
);

export const selectCombinedItemCounterIds = createSelector(
  selectRequiredItemCounterIds,
  selectSelectedItemCounterIds,
  (requiredItemCounterIds, selectedItemCounterIds) =>
    uniq([...requiredItemCounterIds, ...selectedItemCounterIds])
);

export const selectRequiredItemCounters = createSelector(
  selectRequiredItemCounterIds,
  createSelectByIds<Counter>(DataType.Counter),
  (itemCounterIds, selectByIds) => selectByIds(itemCounterIds)
);

export const selectSelectedItemCounters = createSelector(
  selectSelectedItemCounterIds,
  createSelectByIds<Counter>(DataType.Counter),
  (itemCounterIds, selectByIds) => selectByIds(itemCounterIds)
);

export const selectCombinedItemCounters = createSelector(
  selectCombinedItemCounterIds,
  createSelectByIds<Counter>(DataType.Counter),
  (itemCounterIds, selectByIds) => selectByIds(itemCounterIds)
);

export const selectPricingIds = createSelector(
  selectPlanEditorState,
  (planEditorState) => planEditorState.pricingIds
);

export const selectPricings = createSelector(
  selectPricingIds,
  createSelectByIds<Pricing>(DataType.Pricing),
  (pricingIds, selectByIds) => selectByIds(pricingIds)
);
export const selectItemCounterPricingIds = createSelector(
  selectPlanEditorState,
  (planEditorState) => planEditorState.itemCounterPricingIds
);

export const selectItemCounterPricings = createSelector(
  selectItemCounterPricingIds,
  createSelectByIds<CounterPricing>(DataType.CounterPricing),
  (itemCounterPricingIds, selectByIds) => selectByIds(itemCounterPricingIds)
);

export const selectIsDuplicationInProgress = createSelector(
  selectPlanEditorState,
  (planEditorState) => planEditorState.isDuplicationInProgress
);

export const selectError = createSelector(
  selectPlanEditorState,
  (planEditorState) => planEditorState.error
);

export default planEditorSlice.reducer;
