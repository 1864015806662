import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { NotificationType } from '@/types/notifications';

import { addNotification } from '@/store/notifications/notifications';

export interface NotificationOptions {
  description: string;
  status?: NotificationType;
  duration?: number;
}

const useNotification = () => {
  const dispatch = useDispatch();

  // API a close match to Chakra's `useToast` to make migrating simpler.
  const toast = useCallback(
    (options: NotificationOptions) => {
      const { description, duration, status } = options;
      dispatch(addNotification(description, status, duration));
    },
    [dispatch]
  );

  return {
    toast,
  };
};

export default useNotification;
