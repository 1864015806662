import React, { ReactNode } from 'react';

import {
  Stepper as ChakraStepper,
  Step as ChakraStep,
  StepperProps as ChakraStepperProps,
  StepTitle,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepSeparator,
} from '@chakra-ui/react';

export const Stepper: React.FC<ChakraStepperProps> = (props) => (
  <ChakraStepper {...props} />
);

export interface StepProps {
  title: ReactNode;
}

export const Step: React.FC<StepProps> = ({ title }) => (
  <ChakraStep>
    <StepIndicator>
      <StepStatus
        complete={<StepIcon />}
        incomplete={<StepNumber />}
        active={<StepNumber />}
      />
    </StepIndicator>
    <StepSeparator />
    <StepTitle>{title}</StepTitle>
  </ChakraStep>
);
