import { call, put, StrictEffect, take, takeLatest } from 'redux-saga/effects';

import { DataType, AccountPlan, Id, Plan } from '@m3ter-com/m3ter-api';

import type { AnyAction } from 'redux';

import { extractError } from '@/util/error';
import { takeLatestWithCancel } from '@/store/util.saga';
import { listAllData, retrieveData } from '@/store/data/data.saga';
import {
  addEntityLink,
  addEntityLinkFailure,
  AddEntityLinkFailureAction,
  addEntityLinkSuccess,
  AddEntityLinkSuccessAction,
} from '@/store/utils/linkEntity';

import {
  loadAllPricingDataByPlanGroup,
  LoadAllPricingDataByPlanGroupReturn,
  loadAllPricingDataByPlans,
  LoadAllPricingDataByPlansReturn,
  loadAllPricingDataByPlanTemplates,
  LoadAllPricingDataByPlanTemplatesReturn,
} from './pricingUtils.saga';
import {
  loadAccountPlanPricingData,
  loadPlanPricingData,
  loadPricingDataFailure,
  loadPricingDataSuccess,
  LoadAccountPlanPricingDataAction,
  LoadPlanPricingDataAction,
  addPricingUsageEntities,
  addPlansToPlanGroup,
  AddPlansToPlanGroupAction,
  addPlansToPlanGroupFailure,
  reset,
  loadAndAddExtraUsageEntities,
  LoadExtraUsageEntitiesAction,
  loadExtraUsageEntitiesSuccess,
  loadExtraUsageEntitiesFailure,
} from './planDetails';

function* loadAndAddInitialUsageEntities(
  addAggregationId?: Id,
  addCompoundAggregationId?: Id,
  addItemCounterId?: Id
): Generator<StrictEffect, void, any> {
  if (addAggregationId) {
    yield call(retrieveData, DataType.Aggregation, addAggregationId);
    yield put(addPricingUsageEntities([addAggregationId], false));
  }

  if (addCompoundAggregationId) {
    yield call(
      retrieveData,
      DataType.CompoundAggregation,
      addCompoundAggregationId
    );
    yield put(addPricingUsageEntities([addCompoundAggregationId], true));
  }
  if (addItemCounterId) {
    yield call(retrieveData, DataType.Counter, addItemCounterId);
    yield put(addPricingUsageEntities([addItemCounterId]));
  }
}

export function* loadAndAddExtraUsageEntitiesSaga(
  action: LoadExtraUsageEntitiesAction
): Generator<StrictEffect, void, any> {
  const {
    extraAggregationIds,
    extraCompoundAggregationIds,
    extraItemCounterIds,
  } = action.payload;
  try {
    if (extraAggregationIds && extraAggregationIds.length > 0) {
      yield call(listAllData, DataType.Aggregation, {
        ids: extraAggregationIds,
      });
      yield put(addPricingUsageEntities(extraAggregationIds, false));
    }

    if (extraCompoundAggregationIds && extraCompoundAggregationIds.length > 0) {
      yield call(listAllData, DataType.CompoundAggregation, {
        ids: extraCompoundAggregationIds,
      });
      yield put(addPricingUsageEntities(extraCompoundAggregationIds, true));
    }

    if (extraItemCounterIds && extraItemCounterIds.length > 0) {
      yield call(listAllData, DataType.Counter, {
        ids: extraItemCounterIds,
      });
      yield put(addPricingUsageEntities(extraItemCounterIds));
    }
    yield put(loadExtraUsageEntitiesSuccess());
  } catch (error) {
    yield put(loadExtraUsageEntitiesFailure(extractError(error)));
  }
}

export function* addPlansToPlanGroupSaga(
  action: AddPlansToPlanGroupAction
): Generator<StrictEffect, void, any> {
  try {
    yield put(
      addEntityLink(
        DataType.PlanGroup,
        action.payload.planGroupId,
        DataType.Plan,
        action.payload.planIds
      )
    );
    // Wait for the failure / success action from adding the plans to the plan group.
    const addEntityLinkResultAction:
      | AddEntityLinkFailureAction
      | AddEntityLinkSuccessAction = yield take(
      (awaitedAction: AnyAction) =>
        (awaitedAction.type === addEntityLinkSuccess.type ||
          awaitedAction.type === addEntityLinkFailure.type) &&
        awaitedAction.payload.parentDataType === DataType.PlanGroup &&
        awaitedAction.payload.childDataType === DataType.Plan
    );
    if (addEntityLinkResultAction.type === addEntityLinkFailure.type) {
      yield put(addPlansToPlanGroupFailure());
      return;
    }

    // If the plans were added succesfully, re-load all related data
    const pricingData: LoadAllPricingDataByPlanGroupReturn = yield call(
      loadAllPricingDataByPlanGroup,
      action.payload.planGroupId
    );
    const {
      aggregationIds,
      compoundAggregationIds,
      itemCounterIds,
      pricingIds,
      itemCounterPricingIds,
      planIds,
      linkedPlanTemplateIds,
      planGroupId,
    } = pricingData;
    yield put(
      loadPricingDataSuccess(
        aggregationIds,
        compoundAggregationIds,
        itemCounterIds,
        pricingIds,
        itemCounterPricingIds,
        planIds,
        linkedPlanTemplateIds,
        planGroupId
      )
    );
  } catch (error) {
    yield put(loadPricingDataFailure(extractError(error)));
  }
}

export function* loadAccountPlanPricingDataSaga(
  action: LoadAccountPlanPricingDataAction
): Generator<StrictEffect, void, any> {
  const {
    accountPlanId,
    addAggregationId,
    addCompoundAggregationId,
    addItemCounterId,
  } = action.payload;

  try {
    let aggregationIds: Array<Id>;
    let compoundAggregationIds: Array<Id>;
    let itemCounterIds: Array<Id>;
    let pricingIds: Array<Id>;
    let itemCounterPricingIds: Array<Id>;
    let planIds: Array<Id>;
    let planTemplateIds: Array<Id>;
    let planGroupId: Id | undefined;

    const accountPlan: AccountPlan = yield call(
      retrieveData,
      DataType.AccountPlan,
      accountPlanId
    );

    if (accountPlan.planGroupId) {
      const pricingData: LoadAllPricingDataByPlanGroupReturn = yield call(
        loadAllPricingDataByPlanGroup,
        accountPlan.planGroupId
      );
      ({
        aggregationIds,
        compoundAggregationIds,
        itemCounterIds,
        pricingIds,
        itemCounterPricingIds,
        planIds,
        linkedPlanTemplateIds: planTemplateIds,
        planGroupId,
      } = pricingData);
    } else {
      const plan: Plan = yield call(
        retrieveData,
        DataType.Plan,
        accountPlan.planId! // An account plan must have either a planGroupId or planId
      );
      const pricingData: LoadAllPricingDataByPlansReturn = yield call(
        loadAllPricingDataByPlans,
        [plan.id]
      );
      ({
        aggregationIds,
        compoundAggregationIds,
        itemCounterIds,
        pricingIds,
        itemCounterPricingIds,
        planIds,
        linkedPlanTemplateIds: planTemplateIds,
      } = pricingData);
    }

    // Load any usage entities that is new and needs adding
    // to the pricing grid.
    yield call(
      loadAndAddInitialUsageEntities,
      addAggregationId,
      addCompoundAggregationId,
      addItemCounterId
    );

    yield put(
      loadPricingDataSuccess(
        aggregationIds,
        compoundAggregationIds,
        itemCounterIds,
        pricingIds,
        itemCounterPricingIds,
        planIds,
        planTemplateIds,
        planGroupId
      )
    );
  } catch (error) {
    yield put(loadPricingDataFailure(extractError(error)));
  }
}

export function* loadPlanPricingDataSaga(
  action: LoadPlanPricingDataAction
): Generator<StrictEffect, void, any> {
  const {
    planId,
    planTemplateId,
    addAggregationId,
    addCompoundAggregationId,
    addItemCounterId,
  } = action.payload;
  try {
    let pricingIds: Array<Id>;
    let itemCounterPricingIds: Array<Id>;
    let aggregationIds: Array<Id>;
    let compoundAggregationIds: Array<Id>;
    let itemCounterIds: Array<Id>;

    if (planId) {
      const pricingsData: LoadAllPricingDataByPlansReturn = yield call(
        loadAllPricingDataByPlans,
        [planId]
      );
      ({
        pricingIds,
        itemCounterPricingIds,
        aggregationIds,
        compoundAggregationIds,
        itemCounterIds,
      } = pricingsData);
    } else {
      const pricingsData: LoadAllPricingDataByPlanTemplatesReturn = yield call(
        loadAllPricingDataByPlanTemplates,
        [planTemplateId]
      );
      ({
        pricingIds,
        itemCounterPricingIds,
        aggregationIds,
        compoundAggregationIds,
        itemCounterIds,
      } = pricingsData);
    }

    // Load any aggregation or compound aggregation that is new and needs adding
    // to the pricing grid.
    yield call(
      loadAndAddInitialUsageEntities,
      addAggregationId,
      addCompoundAggregationId,
      addItemCounterId
    );

    yield put(
      loadPricingDataSuccess(
        aggregationIds,
        compoundAggregationIds,
        itemCounterIds,
        pricingIds,
        itemCounterPricingIds,
        planId ? [planId] : [],
        [planTemplateId]
      )
    );
  } catch (error) {
    yield put(loadPricingDataFailure(extractError(error)));
  }
}

export default function* planDetailsSaga() {
  yield takeLatest(addPlansToPlanGroup.type, addPlansToPlanGroupSaga);
  yield takeLatest(
    loadAndAddExtraUsageEntities.type,
    loadAndAddExtraUsageEntitiesSaga
  );

  // If we jump between account plan and plan (template) details pages
  // we need to cancel any running loads or we create a race condition
  // between the loading finishing and the reset action. Use the reset
  // action to determine if we need to cancel the task.
  yield takeLatestWithCancel(
    loadAccountPlanPricingData.type,
    reset.type,
    loadAccountPlanPricingDataSaga
  );
  yield takeLatestWithCancel(
    loadPlanPricingData.type,
    reset.type,
    loadPlanPricingDataSaga
  );
}
