import { API } from 'aws-amplify';

import { DataType, Entity } from '@m3ter-com/m3ter-api';

import { FeatureCluster, HttpMethod } from '@/types/data';

const apiMethodsByType = {
  DEL: API.del.bind(API),
  GET: API.get.bind(API),
  POST: API.post.bind(API),
  PUT: API.put.bind(API),
} as const;

export interface ApiError extends Error {
  response?: {
    data: any;
    status: number;
  };
}

export interface ListResponse<E extends Entity = Entity> {
  data: Array<E>;
  nextToken?: string;
}

type ApiMethodKey = keyof typeof apiMethodsByType;

interface DataAction {
  httpMethod: ApiMethodKey;
  path: string;
}

type DataActionMap = Record<string, DataAction>;

interface DataTypeConfig {
  path: string;
  actions?: DataActionMap;
}

type DataTypeConfigMap = Partial<Record<DataType, DataTypeConfig>>;

interface FeatureConfig {
  entityTypeConfig?: DataTypeConfigMap;
  actions?: DataActionMap;
}

type FeatureConfigMap = Record<FeatureCluster, FeatureConfig>;

export type Param = string | boolean | number | undefined | null;
export type PathParams = Record<string, Param>;
export type Params = Record<string, Param | Array<Param>>;

type QueryParams = Record<string, any>;

const baseOrgPath = '/organizations/:organizationId';

const immutableDataActions: DataActionMap = {
  create: {
    httpMethod: HttpMethod.Post,
    path: '',
  },
  list: {
    httpMethod: HttpMethod.Get,
    path: '',
  },
  retrieve: {
    httpMethod: HttpMethod.Get,
    path: '/:id',
  },
};

const commonDataActions: DataActionMap = {
  ...immutableDataActions,
  delete: {
    httpMethod: HttpMethod.Delete,
    path: '/:id',
  },
  update: {
    httpMethod: HttpMethod.Put,
    path: '/:id',
  },
};

const commonDataActionsWithSearch: DataActionMap = {
  ...commonDataActions,
  search: {
    httpMethod: HttpMethod.Get,
    path: '/search',
  },
};

const commonSingletonActions: DataActionMap = {
  retrieve: {
    httpMethod: HttpMethod.Get,
    path: '',
  },
  update: {
    httpMethod: HttpMethod.Put,
    path: '',
  },
};

const featureConfigMap: FeatureConfigMap = {
  [FeatureCluster.Accounts]: {
    entityTypeConfig: {
      [DataType.Account]: {
        path: `${baseOrgPath}/accounts`,
        actions: {
          ...commonDataActionsWithSearch,
          listChildren: {
            httpMethod: HttpMethod.Get,
            path: '/:accountId/children',
          },
          endDateBillingEntities: {
            httpMethod: HttpMethod.Put,
            path: '/:accountId/enddatebillingentities',
          },
        },
      },
      [DataType.Balance]: {
        path: `${baseOrgPath}/balances`,
        actions: commonDataActions,
      },
      [DataType.BalanceTransaction]: {
        path: `${baseOrgPath}/balances/:balanceId/transactions`,
        actions: {
          create: {
            httpMethod: HttpMethod.Post,
            path: '',
          },
          list: {
            httpMethod: HttpMethod.Get,
            path: '',
          },
        },
      },
      [DataType.Commitment]: {
        path: `${baseOrgPath}/commitments`,
        actions: commonDataActions,
      },
      [DataType.Contract]: {
        path: `${baseOrgPath}/contracts`,
        actions: {
          ...commonDataActions,
          endDateBillingEntities: {
            httpMethod: HttpMethod.Put,
            path: '/:contractId/enddatebillingentities',
          },
        },
      },
      [DataType.CounterAdjustment]: {
        path: `${baseOrgPath}/counteradjustments`,
        actions: commonDataActions,
      },
    },
  },
  [FeatureCluster.Alerts]: {
    entityTypeConfig: {
      [DataType.Alert]: {
        path: `${baseOrgPath}/alerts`,
        actions: commonDataActions,
      },
    },
  },
  [FeatureCluster.Admin]: {
    entityTypeConfig: {
      [DataType.Customer]: {
        path: '/customers',
        actions: commonDataActions,
      },
      [DataType.OrganizationAdmin]: {
        path: '',
        actions: {
          create: {
            httpMethod: HttpMethod.Post,
            path: '/organizations',
          },
          update: {
            httpMethod: HttpMethod.Put,
            path: '/organizations/:id',
          },
          list: {
            httpMethod: HttpMethod.Get,
            path: '/organizations',
          },
          retrieve: {
            httpMethod: HttpMethod.Get,
            path: '/organizations/:id',
          },
          addOrgUser: {
            httpMethod: HttpMethod.Post,
            path: '/organizations/:id/users',
          },
          addOrgUserPermission: {
            httpMethod: HttpMethod.Post,
            path: '/organizations/:id/permissionpolicies/:permissionId/addtouser/admin',
          },
        },
      },
      [DataType.UserAdmin]: {
        path: '',
        actions: {
          create: {
            httpMethod: HttpMethod.Post,
            path: '/management/users',
          },
          list: {
            httpMethod: HttpMethod.Get,
            path: '/management/users',
          },
        },
      },
    },
  },
  [FeatureCluster.Analytics]: {
    entityTypeConfig: {
      [DataType.AnalyticsJob]: {
        path: `${baseOrgPath}/analytics/jobs`,
        actions: {
          ...commonDataActions,
          getCsvDownloadUrl: {
            path: '/:id/csv',
            httpMethod: HttpMethod.Get,
          },
        },
      },
      [DataType.DataExplorerSavedQuery]: {
        path: `${baseOrgPath}/dataexplorer/selections`,
        actions: commonDataActions,
      },
    },
    actions: {
      /**
       * API Methods for the Data Explorer (Usage)
       */
      getDataExplorerUsageMeters: {
        httpMethod: HttpMethod.Get,
        path: `${baseOrgPath}/dataexplorer/meters`,
      },
      getDataExplorerUsageMeterDimensionValues: {
        httpMethod: HttpMethod.Post,
        path: `${baseOrgPath}/dataexplorer/mergedmeterdimensions`,
      },
      getDataExplorerUsageData: {
        httpMethod: HttpMethod.Post,
        path: `${baseOrgPath}/dataexplorer/usagedata`,
      },
      getDataExplorerUsageDataExportLink: {
        httpMethod: HttpMethod.Post,
        path: `${baseOrgPath}/dataexplorer/usagedata/download/csv`,
      },
      /**
       * API Methods for the Data Explorer (Billing)
       */
      getDataExplorerBillingAccounts: {
        httpMethod: HttpMethod.Get,
        path: `${baseOrgPath}/dataexplorer/bills/accounts`,
      },
      getDataExplorerBillingData: {
        httpMethod: HttpMethod.Post,
        path: `${baseOrgPath}/dataexplorer/bills`,
      },
      getDataExplorerBillingDataExportLink: {
        httpMethod: HttpMethod.Post,
        path: `${baseOrgPath}/dataexplorer/bills/download/csv`,
      },
      getDataExplorerBillingProducts: {
        httpMethod: HttpMethod.Get,
        path: `${baseOrgPath}/dataexplorer/bills/products`,
      },
      /**
       * API Methods for the Data Explorer (Commitments)
       */
      getDataExplorerCommitmentsAccounts: {
        httpMethod: HttpMethod.Get,
        path: `${baseOrgPath}/dataexplorer/commitments/accounts`,
      },
      getDataExplorerCommitmentsData: {
        httpMethod: HttpMethod.Post,
        path: `${baseOrgPath}/dataexplorer/commitments/v2`,
      },
      getDataExplorerCommitmentsDataExportLink: {
        httpMethod: HttpMethod.Post,
        path: `${baseOrgPath}/dataexplorer/commitments/download/csv`,
      },
      getDataExplorerCommitmentsLineItemsData: {
        httpMethod: HttpMethod.Post,
        path: `${baseOrgPath}/dataexplorer/commitments/v2/lineitems`,
      },
      getDataExplorerCommitmentsLineItemsDataExportLink: {
        httpMethod: HttpMethod.Post,
        path: `${baseOrgPath}/dataexplorer/commitments/lineitems/download/csv`,
      },
      getDataExplorerCommitmentsObligationsData: {
        httpMethod: HttpMethod.Post,
        path: `${baseOrgPath}/dataexplorer/commitments/obligations`,
      },
      getDataExplorerCommitmentsObligationsDataExportLink: {
        httpMethod: HttpMethod.Post,
        path: `${baseOrgPath}/dataexplorer/commitments/obligations/download/csv`,
      },
      getDataExplorerCommitmentsRemainingData: {
        httpMethod: HttpMethod.Post,
        path: `${baseOrgPath}/dataexplorer/commitments/remaining`,
      },
    },
  },
  [FeatureCluster.Billing]: {
    entityTypeConfig: {
      [DataType.Bill]: {
        path: `${baseOrgPath}/bills`,
        actions: {
          ...commonDataActionsWithSearch,
          approveAllBills: {
            httpMethod: HttpMethod.Post,
            path: '/approve',
          },
          getAllBillsForAccount: {
            httpMethod: HttpMethod.Get,
            path: '/accountid/:accountId',
          },
          getAllBillsInPeriod: {
            httpMethod: HttpMethod.Get,
            path: '/billingperiod/:lastDateInBillingPeriod/:billingFrequency',
          },
          updateBillStatus: {
            httpMethod: HttpMethod.Put,
            path: '/:billId/status',
          },
          lockBill: {
            httpMethod: HttpMethod.Put,
            path: '/:billId/lock',
          },
          getCsvStatementUrl: {
            httpMethod: HttpMethod.Get,
            path: '/:billId/statement/csv',
          },
          getJsonStatementUrl: {
            httpMethod: HttpMethod.Get,
            path: '/:billId/statement/json',
          },
          downloadBillsUrl: {
            httpMethod: HttpMethod.Post,
            path: '/download/csv/url',
          },
        },
      },
      [DataType.BillConfig]: {
        path: `${baseOrgPath}/billconfig`,
        actions: commonSingletonActions,
      },
      [DataType.BillJob]: {
        path: `${baseOrgPath}/billjobs`,
        actions: {
          ...immutableDataActions,
          generateBills: {
            httpMethod: HttpMethod.Post,
            path: '',
          },
          recalculateBills: {
            httpMethod: HttpMethod.Post,
            path: '/recalculate',
          },
          cancelBillJob: {
            httpMethod: HttpMethod.Post,
            path: '/:billJobId/cancel',
          },
        },
      },
      [DataType.BillLineItem]: {
        path: `${baseOrgPath}/lineitems`,
        actions: {
          list: {
            httpMethod: HttpMethod.Get,
            path: '',
          },
        },
      },
      [DataType.CreditLineItem]: {
        path: `${baseOrgPath}/bills/:billId/creditlineitems`,
        actions: commonDataActions,
      },
      [DataType.DebitLineItem]: {
        path: `${baseOrgPath}/bills/:billId/debitlineitems`,
        actions: commonDataActions,
      },
      [DataType.StatementDefintion]: {
        path: `${baseOrgPath}/statementdefinitions`,
        actions: commonDataActions,
      },
      [DataType.StatementJob]: {
        path: `${baseOrgPath}/statementjobs`,
        actions: {
          ...immutableDataActions,
          cancelStatementJob: {
            httpMethod: HttpMethod.Post,
            path: '/:statementJobId/cancel',
          },
        },
      },
    },
  },
  [FeatureCluster.Metering]: {
    entityTypeConfig: {
      [DataType.MeasurmentsDeletion]: {
        path: `${baseOrgPath}/measurementsDeletions`,
        actions: immutableDataActions,
      },
    },
  },
  [FeatureCluster.Pricing]: {
    entityTypeConfig: {
      [DataType.AccountPlan]: {
        path: `${baseOrgPath}/accountplans`,
        actions: commonDataActions,
      },
      [DataType.CounterPricing]: {
        path: `${baseOrgPath}/counterpricings`,
        actions: commonDataActions,
      },
      [DataType.Pricing]: {
        path: `${baseOrgPath}/pricings`,
        actions: commonDataActions,
      },
      [DataType.Plan]: {
        path: `${baseOrgPath}/plans`,
        actions: commonDataActions,
      },
      [DataType.PlanGroup]: {
        path: `${baseOrgPath}/plangroups`,
        actions: commonDataActions,
      },
      [DataType.PlanGroupLink]: {
        path: `${baseOrgPath}/plangrouplinks`,
        actions: commonDataActions,
      },
      [DataType.PlanTemplate]: {
        path: `${baseOrgPath}/plantemplates`,
        actions: commonDataActions,
      },
    },
  },
  [FeatureCluster.Products]: {
    entityTypeConfig: {
      [DataType.Product]: {
        path: `${baseOrgPath}/products`,
        actions: commonDataActions,
      },
    },
  },
  [FeatureCluster.Integrations]: {
    entityTypeConfig: {
      [DataType.ExternalMapping]: {
        path: `${baseOrgPath}/externalmappings`,
        actions: {
          ...commonDataActions,
          getAllMappingsForSpecificEntity: {
            httpMethod: HttpMethod.Get,
            path: `/external/:m3terEntityType/:m3terEntityId`,
          },
          getAllMappingsForEntityTypeAndSystem: {
            httpMethod: HttpMethod.Get,
            path: `/allexternal/:externalSystem/:m3terEntityType`,
          },
        },
      },
      [DataType.ExternalMappingConfig]: {
        path: `${baseOrgPath}/externalmappingconfiguration`,
        actions: commonSingletonActions,
      },
      [DataType.Integration]: {
        path: `${baseOrgPath}/integrationconfigs`,
        actions: {
          ...commonDataActions,
          fetchAvailableIntegrations: {
            httpMethod: HttpMethod.Get,
            path: '/available',
          },
          fetchLinkedIntegrations: {
            httpMethod: HttpMethod.Get,
            path: '/entity/:entityType',
          },
        },
      },
      [DataType.NotificationRule]: {
        path: `${baseOrgPath}/notifications/configurations`,
        actions: commonDataActions,
      },
      [DataType.M3terEvent]: {
        path: `${baseOrgPath}/events`,
        actions: {
          ...commonDataActions,
          fetchAvailableEventTypes: {
            httpMethod: HttpMethod.Get,
            path: `/types`,
          },
          actionEvents: {
            httpMethod: HttpMethod.Put,
            path: `/action`,
          },
        },
      },
    },
    actions: {
      evaluateNotificationRule: {
        httpMethod: HttpMethod.Post,
        path: `${baseOrgPath}/notifications/evaluate`,
      },
      getExternalEntities: {
        httpMethod: HttpMethod.Get,
        path: `${baseOrgPath}/externalmappingconfiguration/values/:externalSystem/:m3terEntityType`,
      },
      fetchLatestIntegrationRun: {
        httpMethod: HttpMethod.Get,
        path: `${baseOrgPath}/integrationruns/:entityType/latest/:id`,
      },
      fetchIntegrationRuns: {
        httpMethod: HttpMethod.Get,
        path: `${baseOrgPath}/integrationruns/:entityType/:id`,
      },
      fetchIntegrationRunLogs: {
        httpMethod: HttpMethod.Get,
        path: `${baseOrgPath}/integrationruns/logs/:id`,
      },
    },
  },
  [FeatureCluster.Settings]: {
    entityTypeConfig: {
      [DataType.CreditReason]: {
        path: `${baseOrgPath}/picklists/creditreasons`,
        actions: commonDataActions,
      },
      [DataType.Currency]: {
        path: `${baseOrgPath}/picklists/currency`,
        actions: commonDataActions,
      },
      [DataType.CurrentUser]: {
        path: '/user',
        actions: {
          ...commonSingletonActions,
          acceptTerms: {
            httpMethod: HttpMethod.Put,
            path: '/accept-terms-and-conditions',
          },
        },
      },
      [DataType.DebitReason]: {
        path: `${baseOrgPath}/picklists/debitreasons`,
        actions: commonDataActions,
      },
      [DataType.Invitation]: {
        path: `${baseOrgPath}/invitations`,
        actions: immutableDataActions,
      },
      [DataType.Organization]: {
        path: '',
        actions: {
          create: {
            httpMethod: HttpMethod.Post,
            path: '/user/organizations',
          },
          delete: {
            httpMethod: HttpMethod.Delete,
            path: '/user/organizations/:id',
          },
          list: {
            httpMethod: HttpMethod.Get,
            path: '/user/organizations',
          },
          retrieve: {
            httpMethod: HttpMethod.Get,
            path: '/user/organizations/:id',
          },
          update: {
            httpMethod: HttpMethod.Put,
            path: '/user/organizations/:id',
          },
        },
      },
      [DataType.OrganizationConfig]: {
        path: `${baseOrgPath}/organizationconfig`,
        actions: commonSingletonActions,
      },
      [DataType.OrganizationCustomFields]: {
        path: `${baseOrgPath}/customfields`,
        actions: commonSingletonActions,
      },
      [DataType.PermissionPolicy]: {
        path: baseOrgPath,
        actions: {
          create: {
            httpMethod: HttpMethod.Post,
            path: '/permissionpolicies',
          },
          delete: {
            httpMethod: HttpMethod.Delete,
            path: '/permissionpolicies/:id',
          },
          list: {
            httpMethod: HttpMethod.Get,
            path: '/permissionpolicies',
          },
          retrieve: {
            httpMethod: HttpMethod.Get,
            path: '/permissionpolicies/:id',
          },
          update: {
            httpMethod: HttpMethod.Put,
            path: '/permissionpolicies/:id',
          },
          listServiceUserPermissions: {
            httpMethod: HttpMethod.Get,
            path: '/serviceusers/:id/permissions',
          },
          listSupportAccessPermissions: {
            httpMethod: HttpMethod.Get,
            path: '/support-permissions',
          },
          listUserPermissions: {
            httpMethod: HttpMethod.Get,
            path: '/users/:id/permissions',
          },
          listUserGroupPermissions: {
            httpMethod: HttpMethod.Get,
            path: '/resourcegroups/user/:id/permissions',
          },
        },
      },
      [DataType.ServiceUser]: {
        path: `${baseOrgPath}/serviceusers`,
        actions: {
          ...commonDataActions,
          getCredentials: {
            httpMethod: HttpMethod.Get,
            path: '/:serviceUserId/credentials',
          },
          createCredential: {
            httpMethod: HttpMethod.Post,
            path: '/:serviceUserId/credentials',
          },
          removeCredential: {
            httpMethod: HttpMethod.Delete,
            path: '/:serviceUserId/credentials/:credentialId',
          },
          makeCredentialInactive: {
            httpMethod: HttpMethod.Put,
            path: '/:serviceUserId/credentials/:credentialId/inactivate',
          },
          makeCredentialActive: {
            httpMethod: HttpMethod.Put,
            path: '/:serviceUserId/credentials/:credentialId/activate',
          },
        },
      },
      [DataType.SupportAccess]: {
        path: `${baseOrgPath}/support`,
        actions: commonSingletonActions,
      },
      [DataType.SupportOrganization]: {
        path: '',
        actions: {
          list: {
            httpMethod: HttpMethod.Get,
            path: '/support',
          },
        },
      },
      [DataType.TransactionType]: {
        path: `${baseOrgPath}/picklists/transactiontypes`,
        actions: commonDataActions,
      },
      [DataType.User]: {
        path: `${baseOrgPath}/users`,
        actions: {
          ...commonDataActions,
          resendTempPassword: {
            httpMethod: HttpMethod.Put,
            path: '/:userId/password/resend',
          },
        },
      },
      [DataType.UserGroup]: {
        path: baseOrgPath,
        actions: {
          create: {
            httpMethod: HttpMethod.Post,
            path: '/resourcegroups/user',
          },
          delete: {
            httpMethod: HttpMethod.Delete,
            path: '/resourcegroups/user/:id',
          },
          list: {
            httpMethod: HttpMethod.Get,
            path: '/resourcegroups/user',
          },
          retrieve: {
            httpMethod: HttpMethod.Get,
            path: '/resourcegroups/user/:id',
          },
          update: {
            httpMethod: HttpMethod.Put,
            path: '/resourcegroups/user/:id',
          },
          listByUserId: {
            httpMethod: HttpMethod.Get,
            path: '/users/:id/usergroups',
          },
          addUserToUserGroup: {
            httpMethod: HttpMethod.Post,
            path: '/resourcegroups/user/:id/addresource',
          },
          removeUserFromUserGroup: {
            httpMethod: HttpMethod.Post,
            path: '/resourcegroups/user/:id/removeresource',
          },
        },
      },
      [DataType.Destination]: {
        path: `${baseOrgPath}/integrationdestinations/webhooks`,
        actions: commonDataActions,
      },
    },
    actions: {
      getPermissionResources: {
        httpMethod: HttpMethod.Get,
        path: '/resourcetypes',
      },
    },
  },
  [FeatureCluster.Usage]: {
    entityTypeConfig: {
      [DataType.Meter]: {
        path: `${baseOrgPath}/meters`,
        actions: commonDataActions,
      },
      [DataType.Aggregation]: {
        path: `${baseOrgPath}/aggregations`,
        actions: commonDataActions,
      },
      [DataType.CompoundAggregation]: {
        path: `${baseOrgPath}/compoundaggregations`,
        actions: commonDataActions,
      },
      [DataType.Counter]: {
        path: `${baseOrgPath}/counters`,
        actions: commonDataActions,
      },
    },
    actions: {
      submitMeasurements: {
        httpMethod: HttpMethod.Post,
        path: `${baseOrgPath}/measurements`,
      },
    },
  },
};

/**
 * The featureLookupMap is used to retrieve the parent feature of a dataType.
 * This makes looking up the parent feature cheaper as this won't get garbage collected.
 */

const featureLookupMap = Object.values(DataType).reduce((result, dataType) => {
  const parentFeatureCluster = Object.entries(featureConfigMap).find(
    ([_featureCluster, featureConfig]) => {
      if (!featureConfig.entityTypeConfig) return false;

      return Object.keys(featureConfig.entityTypeConfig).includes(dataType);
    }
  )?.[0];

  if (parentFeatureCluster) {
    // eslint-disable-next-line no-param-reassign
    result[dataType] = parentFeatureCluster as FeatureCluster;
  }

  return result;
}, {} as Record<DataType, FeatureCluster>);

const lookUpFeatureFromDataType = (
  dataType: DataType
): FeatureCluster | undefined => {
  return featureLookupMap[dataType];
};

// Removes any undefined / null values from params and stringifies arrays.
const cleanParams = (params: Params): Params =>
  Object.entries(params).reduce<Params>((acc, [key, value]) => {
    if (value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        acc[key] = value.join(',');
      } else {
        acc[key] = value;
      }
    }
    return acc;
  }, {});

const pathPlaceholderRegexp = /:(\w+)/g;

const buildPath = (
  placeholderPath: string,
  pathParams: Record<string, any>
): string =>
  placeholderPath.replace(pathPlaceholderRegexp, (_fullMatch, paramKey) => {
    if (paramKey in pathParams) {
      return String(pathParams[paramKey]);
    }
    throw new Error(`Path param "${paramKey}" not provided.`);
  });

/**
 * This function is used for when we hit an API where we always know the returned entity.
 */
export const performDataAction = (
  dataType: DataType,
  actionName: string,
  pathParams: PathParams = {},
  queryParams: QueryParams = {},
  data: Record<string, any> = {}
) => {
  const lookedUpFeature = lookUpFeatureFromDataType(dataType);

  const actionConfig =
    featureConfigMap[lookedUpFeature!].entityTypeConfig?.[dataType];

  if (!actionConfig) {
    throw new Error(
      `Config not found for '${dataType}' in '${lookedUpFeature}'.`
    );
  }

  const { actions } = actionConfig;

  const action = actions?.[actionName];

  if (!action)
    throw new Error(
      `Action ${actionName} not found for '${dataType}' in '${lookedUpFeature}'.`
    );

  const fullPath = buildPath(actionConfig.path + action.path, pathParams);

  // Do an API call with data and params here and return promise
  return apiMethodsByType[action.httpMethod]('api', fullPath, {
    queryStringParameters: cleanParams(queryParams),
    body: data,
  });
};

/**
 * This function is used when we hit an API that doesn't return us an entity.
 * Data Explorer for example returns you a different set of data depending on data sent,
 * so we can never know the response entity.
 */

export const performFeatureAction = (
  featureType: FeatureCluster,
  actionName: string,
  pathParams: PathParams = {},
  queryParams: QueryParams = {},
  data: Record<string, any> = {}
) => {
  const { actions } = featureConfigMap[featureType];

  const action = actions?.[actionName];
  if (!action)
    throw new Error(`Action ${actionName} not found in '${featureType}'.`);

  const fullPath = buildPath(action.path, pathParams);

  // Do an API call with data and params here and return promise
  return apiMethodsByType[action.httpMethod]('api', fullPath, {
    queryStringParameters: cleanParams(queryParams),
    body: data,
  });
};
