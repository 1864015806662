import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudRoute } from '@/components/common/crud/CrudRoute';

export const PricingRoute: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CrudRoute
      requiresProduct
      dataType={DataType.Pricing}
      title={t('common:pricing')}
    />
  );
};
