import React from 'react';

import { StatementDefinition } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { StatementDefinitionForm } from '@/components/features/statements/StatementDefinitionForm';

export const StatementDefinitionsEditRoute: React.FC = () => (
  <CrudEdit<StatementDefinition> form={StatementDefinitionForm} />
);
