import React from 'react';

import {
  Icon,
  IconButton,
  Text,
  Td,
  Tooltip,
  Tr,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import {
  FaCaretDown,
  FaCaretRight,
  FaRegArrowAltCircleRight,
} from 'react-icons/fa';

import { Bill, BillLineItem, BillLineItemType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { formatNumber } from '@m3ter-com/console-core/utils';

import { getLineItemUnits, getUnitPriceSummary } from '@/util/billing';
import { formatEntityUnit } from '@/util/data';
import useQueryString from '@/hooks/navigation/useQueryString';
import useCurrencies from '@/hooks/util/useCurrencies';
import useDateFormatter from '@/hooks/util/useDateFormatter';

import { BillLineItemBreakdown } from './BillLineItemBreakdown';
import { BillLineItemDescription } from './BillLineItemDescription';

export interface BillLineItemRowProps {
  bill: Bill;
  reference: string;
  lineItem: BillLineItem;
}

export interface HighlightQueryParams {
  highlightLineItem: string;
}

export const BillLineItemRow: React.FC<BillLineItemRowProps> = ({
  bill,
  lineItem,
  reference,
}) => {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();

  const { formatDateRangeWithExclusiveEndDate } = useDateFormatter();
  const { formatCurrency } = useCurrencies();
  const units = getLineItemUnits(lineItem);

  const { highlightLineItem } = useQueryString<HighlightQueryParams>();
  const highlight = highlightLineItem === lineItem.id;

  const lineItemCurrency = lineItem.currency ?? bill.currency;

  const hasDetails =
    !!lineItem.childAccountId ||
    !!lineItem.planId ||
    !!lineItem.planGroupId ||
    !!lineItem.aggregationId ||
    !!lineItem.compoundAggregationId ||
    !!lineItem.meterId ||
    !!lineItem.commitmentId ||
    !!lineItem.counterId;

  const isBreakdownLineItem =
    lineItem.lineItemType === BillLineItemType.Usage ||
    lineItem.lineItemType === BillLineItemType.CreditMemo ||
    lineItem.lineItemType === BillLineItemType.DebitMemo ||
    lineItem.lineItemType === BillLineItemType.CounterAdjustmentDebit ||
    lineItem.lineItemType === BillLineItemType.CounterAdjustmentCredit ||
    lineItem.lineItemType === BillLineItemType.CounterRunningTotalCharge;

  return (
    <React.Fragment>
      <Tr borderTopWidth={1} data-testid="line-item" verticalAlign="top">
        <Td paddingLeft={0} paddingRight={0} whiteSpace="nowrap">
          {highlight && (
            <Icon
              mr={2}
              verticalAlign="middle"
              as={FaRegArrowAltCircleRight}
              data-testid="highlight"
            />
          )}
          <IconButton
            // We only want the extra details section to be expandable if we have something to show
            isDisabled={!hasDetails && !isBreakdownLineItem}
            aria-label={
              isOpen
                ? t('features:billing.collapseExtraDetails')
                : t('features:billing.expandExtraDetails')
            }
            icon={isOpen ? <FaCaretDown /> : <FaCaretRight />}
            size="xs"
            onClick={onToggle}
          />
        </Td>
        <Td>{reference}</Td>
        <Td>
          <VStack alignItems="flex-start">
            <BillLineItemDescription
              lineItem={lineItem}
              accountId={bill.accountId}
            />
            <Text>
              {lineItem.servicePeriodStartDate &&
                lineItem.servicePeriodEndDate &&
                formatDateRangeWithExclusiveEndDate(
                  lineItem.servicePeriodStartDate,
                  lineItem.servicePeriodEndDate
                )}
            </Text>
          </VStack>
        </Td>
        <Td>{t(`features:billing.lineItemTypes.${lineItem.lineItemType}`)}</Td>
        <Td textAlign="right">
          <Tooltip label={formatNumber(lineItem.quantity)} placement="top">
            <span>{formatNumber(units)}</span>
          </Tooltip>
        </Td>
        {lineItem.unit ? <Td>{formatEntityUnit(lineItem.unit)}</Td> : <Td />}
        <Td textAlign="right">
          {lineItem.usagePerPricingBand
            ? getUnitPriceSummary(
                lineItem.usagePerPricingBand,
                lineItemCurrency,
                formatCurrency
              )
            : '-'}
        </Td>
        <Td textAlign="right" whiteSpace="nowrap">
          {formatCurrency(lineItem.convertedSubtotal, bill.currency)}
        </Td>
      </Tr>
      {isOpen && (
        <React.Fragment>
          <BillLineItemBreakdown bill={bill} lineItem={lineItem} />
          <Tr height={2} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
