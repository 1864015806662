import React, { useCallback } from 'react';

import {
  RenderRecordLinkContext,
  StructuredText,
  StructuredTextGraphQlResponse,
} from 'react-datocms';
import { Box, CSSObject } from '@chakra-ui/react';

import { RecordTypes } from '@/types/docs';

import { createBlogPostLink, createDocsLink } from '@/util/cms';

export interface HelpContentProps {
  data: StructuredTextGraphQlResponse;
}

const styles: CSSObject = {
  p: { mb: 2 },
  ul: { ml: 4 },
  ol: { ml: 4 },
  a: { ':hover': { textDecoration: 'underline' }, color: 'green-adaptive' },
};

export const HelpContent: React.FC<HelpContentProps> = ({ data }) => {
  const renderLinkToRecord = useCallback(
    ({ record, children, transformedMeta }: RenderRecordLinkContext<any>) => {
      let link;
      // eslint-disable-next-line no-underscore-dangle
      switch (record.__typename) {
        case RecordTypes.BlogPost:
          link = createBlogPostLink(record);
          break;
        case RecordTypes.DocumentationPage:
          link = createDocsLink(record);
          break;
        default:
        // Do nothing
      }

      return link ? (
        <a {...transformedMeta} href={link} target="_blank" rel="noreferrer">
          {children}
        </a>
      ) : (
        <span {...transformedMeta}>{children}</span>
      );
    },
    []
  );

  return (
    <Box __css={styles}>
      <StructuredText data={data} renderLinkToRecord={renderLinkToRecord} />
    </Box>
  );
};
