import React from 'react';

import { Link, To, useMatch, useResolvedPath } from 'react-router-dom';

import { TabLink, TabLinkProps } from '@m3ter-com/ui-components';

export interface NavTabLinkProps extends TabLinkProps {
  to: To;
  activeOnlyIfExact?: boolean;
}

export const NavTabLink: React.FC<NavTabLinkProps> = ({
  to,
  activeOnlyIfExact = false,
  ...props
}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: activeOnlyIfExact });

  return <TabLink as={Link} active={!!match} to={to} {...props} />;
};
