import {
  takeEvery,
  takeLatest,
  all,
  call,
  put,
  select,
  StrictEffect,
} from 'redux-saga/effects';
import { ActionCreator } from 'redux';

import {
  DataType,
  Entity,
  Id,
  Aggregation,
  CompoundAggregation,
  Counter,
  CounterPricing,
  Plan,
  PlanTemplate,
  Pricing,
  PricingCommon,
} from '@m3ter-com/m3ter-api';

import { PricingDataType } from '@/types/data';

import { getRelationship } from '@/services/api/entities';
import { uniq } from '@/util/array';
import { cloneEntity, ids } from '@/util/data';
import { extractError } from '@/util/error';
import {
  getPlanEditorState,
  setPlanEditorState,
  PlanEditorSaveState,
} from '@/util/localStorage';
import { canUsePlanEditor } from '@/util/planEditor';
import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';
import { selectSelectedProductId } from '@/store/products/products';
import { dataCreated, selectById, DataCreatedAction } from '@/store/data/data';
import { createData, hasAny, listData } from '@/store/data/data.saga';

import {
  loadAllPricingDataByPlans,
  loadAllPricingDataByPlanTemplates,
} from './pricingUtils.saga';
import {
  addAggregations,
  addAggregationsSuccess,
  addCompoundAggregations,
  addCompoundAggregationsSuccess,
  addPlans,
  addPlansSuccess,
  addPlanTemplates,
  addPlanTemplatesSuccess,
  addPricing,
  duplicatePlan,
  duplicatePlanSuccess,
  duplicatePlanTemplate,
  duplicatePlanTemplateSuccess,
  duplicationFailure,
  loadInitialData,
  loadInitialDataSuccess,
  loadInitialDataFailure,
  removeUsageEntities,
  removePricings,
  restoreSelections,
  restoreSelectionsSuccess,
  removeLinkedPlanTemplate,
  removePlan,
  removePlanTemplate,
  selectLinkedPlanTemplateIds,
  selectSelectedAggregationIds,
  selectSelectedCompoundAggregationIds,
  selectSelectedPlanIds,
  selectSelectedPlanTemplateIds,
  selectSelectedPlans,
  selectPricings,
  selectRequiredAggregations,
  selectRequiredCompoundAggregations,
  AddAggregationAction,
  AddCompoundAggregationAction,
  AddPlansAction,
  AddPlanTemplatesAction,
  DuplicatePlanAction,
  DuplicatePlanTemplateAction,
  LoadInitialDataAction,
  RemoveFromLocation,
  RemovePlanAction,
  RemovePlanTemplateAction,
  RestoreSelectionsAction,
  AddItemCounterAction,
  addItemCountersSuccess,
  selectSelectedItemCounterIds,
  addItemCounters,
  selectItemCounterPricings,
  selectRequiredItemCounters,
  addItemCounterPricing,
  AddItemCounterPricingAction,
  AddPricingAction,
} from './planEditor';

type DuplicationAction = DuplicatePlanAction | DuplicatePlanTemplateAction;
type RemovalAction = RemovePlanAction | RemovePlanTemplateAction;

function isDuplicatePlanAction(
  action: DuplicationAction
): action is DuplicatePlanAction {
  return action.type === duplicatePlan.type;
}

function isDuplicatePlanTemplateAction(
  action: DuplicationAction
): action is DuplicatePlanTemplateAction {
  return action.type === duplicatePlanTemplate.type;
}

function* loadAggregationsData(
  aggregationIds: Array<string>
): Generator<StrictEffect, Array<string>, any> {
  if (aggregationIds.length > 0) {
    const aggregationsResponse = yield call(listData, DataType.Aggregation, {
      ids: aggregationIds,
    });
    const aggregations: Array<Aggregation> = aggregationsResponse.data;

    return ids(aggregations);
  }

  return [];
}

function* loadCompoundAggregationsData(
  compoundAggregationIds: Array<string>
): Generator<StrictEffect, Array<string>, any> {
  if (compoundAggregationIds.length > 0) {
    const compoundAggregationsResponse = yield call(
      listData,
      DataType.CompoundAggregation,
      {
        ids: compoundAggregationIds,
      }
    );
    const compoundAggregations: Array<CompoundAggregation> =
      compoundAggregationsResponse.data;

    return ids(compoundAggregations);
  }

  return [];
}

function* loadItemCountersData(
  itemCounterIds?: Array<string>
): Generator<StrictEffect, Array<string>, any> {
  if (itemCounterIds && itemCounterIds.length > 0) {
    const itemCountersResponse = yield call(listData, DataType.Counter, {
      ids: itemCounterIds,
    });
    const itemCounters: Array<Counter> = itemCountersResponse.data;

    return ids(itemCounters);
  }

  return [];
}

function* checkMissingDataTypes(
  productId: string
): Generator<StrictEffect, Array<DataType>, any> {
  const missingDataTypes = new Array<DataType>();

  // Meters / (compound) aggregations / item counters can be global as well product-specific. Parameter order matters.
  const usageParams = { productId: ['', productId] };

  // Plans / plan templates have to be for the product.
  const planParams = { productId };

  const [
    hasMeters,
    hasAggregations,
    hasCompoundAggregations,
    hasItemCounters,
    hasPlanTemplates,
    hasPlans,
  ] = yield all([
    call(hasAny, DataType.Meter, usageParams),
    call(hasAny, DataType.Aggregation, usageParams),
    call(hasAny, DataType.CompoundAggregation, usageParams),
    call(hasAny, DataType.Counter, usageParams),
    call(hasAny, DataType.PlanTemplate, planParams),
    call(hasAny, DataType.Plan, planParams),
  ]);

  if (!hasMeters) {
    missingDataTypes.push(DataType.Meter);
  }

  if (!hasAggregations) {
    missingDataTypes.push(DataType.Aggregation);
  }

  if (!hasCompoundAggregations) {
    missingDataTypes.push(DataType.CompoundAggregation);
  }

  if (!hasItemCounters) {
    missingDataTypes.push(DataType.Counter);
  }

  if (!hasPlanTemplates) {
    missingDataTypes.push(DataType.PlanTemplate);
  }

  if (!hasPlans) {
    missingDataTypes.push(DataType.Plan);
  }

  return missingDataTypes;
}

export function* loadInitialDataSaga(
  action: LoadInitialDataAction
): Generator<StrictEffect, void, any> {
  const { productId, addPlanId, addPlanTemplateId } = action.payload;

  try {
    const missingDataTypes: Array<DataType> = yield call(
      checkMissingDataTypes,
      productId
    );

    // If we have certain missing data types, we know the pricing grid cannot be rendered so escape early.
    if (!canUsePlanEditor(missingDataTypes)) {
      yield put(loadInitialDataSuccess(missingDataTypes));
      return;
    }

    // If we want to show newly created plan / plan templates, add them to the saved plan editor state.
    // This ensures they are present if the user leaves the page before doing anything else in the editor
    // that would cause these new entities to be persisted as selections and lets the restoreSelectionsSaga
    // handle loading them.
    if (addPlanId || addPlanTemplateId) {
      const orgId = yield select(selectCurrentOrgId);
      const planEditorStateKey = `${orgId}:${productId}`;
      const planEditorState: PlanEditorSaveState | null = yield call(
        getPlanEditorState,
        planEditorStateKey
      );

      const newPlanEditorState = planEditorState || {
        selectedAggregationIds: [],
        selectedCompoundAggregationIds: [],
        selectedItemCounterIds: [],
        selectedPlanIds: [],
        selectedPlanTemplateIds: [],
      };

      if (addPlanId) {
        newPlanEditorState.selectedPlanIds.push(addPlanId);
      }
      if (addPlanTemplateId) {
        newPlanEditorState.selectedPlanTemplateIds.push(addPlanTemplateId);
      }
      yield call(setPlanEditorState, newPlanEditorState, planEditorStateKey);
    }

    yield put(loadInitialDataSuccess(missingDataTypes));
    yield put(restoreSelections(action.payload.productId));
  } catch (error) {
    yield put(loadInitialDataFailure(extractError(error)));
  }
}

export function* addPlansSaga(
  action: AddPlansAction
): Generator<StrictEffect, void, any> {
  const {
    aggregationIds,
    compoundAggregationIds,
    itemCounterIds,
    planIds,
    linkedPlanTemplateIds,
    pricingIds,
    itemCounterPricingIds,
  } = yield call(loadAllPricingDataByPlans, action.payload.planIds);

  yield put(
    addPlansSuccess(
      aggregationIds,
      compoundAggregationIds,
      itemCounterIds,
      linkedPlanTemplateIds,
      planIds,
      pricingIds,
      itemCounterPricingIds
    )
  );
}

export function* addPlanTemplatesSaga(
  action: AddPlanTemplatesAction
): Generator<StrictEffect, void, any> {
  const {
    aggregationIds,
    compoundAggregationIds,
    itemCounterIds,
    planTemplateIds,
    pricingIds,
    itemCounterPricingIds,
  } = yield call(
    loadAllPricingDataByPlanTemplates,
    action.payload.planTemplateIds
  );

  yield put(
    addPlanTemplatesSuccess(
      aggregationIds,
      compoundAggregationIds,
      itemCounterIds,
      planTemplateIds,
      pricingIds,
      itemCounterPricingIds
    )
  );
}

export function* addAggregationsSaga(
  action: AddAggregationAction
): Generator<StrictEffect, void, any> {
  const aggregationIds = yield call(
    loadAggregationsData,
    action.payload.aggregationIds
  );
  yield put(addAggregationsSuccess(aggregationIds));
}

export function* addCompoundAggregationsSaga(
  action: AddCompoundAggregationAction
): Generator<StrictEffect, void, any> {
  const compoundAggregationIds = yield call(
    loadCompoundAggregationsData,
    action.payload.compoundAggregationIds
  );
  yield put(addCompoundAggregationsSuccess(compoundAggregationIds));
}

export function* addItemCountersSaga(
  action: AddItemCounterAction
): Generator<StrictEffect, void, any> {
  const itemCounterIds = yield call(
    loadItemCountersData,
    action.payload.itemCounterIds
  );
  yield put(addItemCountersSuccess(itemCounterIds));
}

export function* persistSelectionsSaga(): Generator<StrictEffect, void, any> {
  // Get the current values from state so we can re-use this saga for multiple actions.
  const selectedAggregationIds: Array<Id> = yield select(
    selectSelectedAggregationIds
  );
  const selectedCompoundAggregationIds: Array<Id> = yield select(
    selectSelectedCompoundAggregationIds
  );
  const selectedItemCounterIds: Array<Id> = yield select(
    selectSelectedItemCounterIds
  );
  const selectedPlanIds: Array<Id> = yield select(selectSelectedPlanIds);
  const selectedPlanTemplateIds: Array<Id> = yield select(
    selectSelectedPlanTemplateIds
  );

  const organizationId: Id = yield select(selectCurrentOrgId);
  const productId: Id | undefined = yield select(selectSelectedProductId);

  yield call(
    setPlanEditorState,
    {
      selectedAggregationIds,
      selectedCompoundAggregationIds,
      selectedItemCounterIds,
      selectedPlanIds,
      selectedPlanTemplateIds,
    },
    `${organizationId}:${productId}`
  );
}

export function* restoreSelectionsSaga(
  action: RestoreSelectionsAction
): Generator<StrictEffect, void, any> {
  const { productId } = action.payload;
  const organizationId = yield select(selectCurrentOrgId);
  const data: PlanEditorSaveState | null = yield call(
    getPlanEditorState,
    `${organizationId}:${productId}`
  );

  if (data) {
    // Load all the required data.
    const {
      aggregationIds: planAggregationIds,
      compoundAggregationIds: planCompoundAggregationIds,
      itemCounterIds: planItemCounterIds,
      linkedPlanTemplateIds,
      planIds,
      pricingIds: planPricingIds,
      itemCounterPricingIds: planItemCounterPricingIds,
    } = yield call(loadAllPricingDataByPlans, data.selectedPlanIds);

    const {
      aggregationIds: planTemplateAggregationIds,
      compoundAggregationIds: planTemplateCompoundAggregationIds,
      itemCounterIds: planTemplateItemCounterIds,
      planTemplateIds,
      pricingIds: planTemplatePricingIds,
      itemCounterPricingIds: planTemplateItemCounterPricingIds,
    } = yield call(
      loadAllPricingDataByPlanTemplates,
      data.selectedPlanTemplateIds
    );

    const selectedAggregationIds = yield call(
      loadAggregationsData,
      data.selectedAggregationIds
    );

    const selectedCompoundAggregationIds = yield call(
      loadCompoundAggregationsData,
      data.selectedCompoundAggregationIds
    );

    const selectedItemCounterIds = yield call(
      loadItemCountersData,
      data.selectedItemCounterIds
    );

    // Merge the aggregation and pricing IDs, avoiding duplicates.
    const requiredAggregationIds = uniq([
      ...planAggregationIds,
      ...planTemplateAggregationIds,
    ]);
    const requiredCompoundAggregationIds = uniq([
      ...planCompoundAggregationIds,
      ...planTemplateCompoundAggregationIds,
    ]);
    const requiredItemCounterIds = uniq([
      ...planItemCounterIds,
      ...planTemplateItemCounterIds,
    ]);
    const allPricingIds = uniq([...planPricingIds, ...planTemplatePricingIds]);
    const allItemCounterPricingIds = uniq([
      ...planItemCounterPricingIds,
      ...planTemplateItemCounterPricingIds,
    ]);

    yield put(
      restoreSelectionsSuccess(
        requiredAggregationIds,
        selectedAggregationIds,
        requiredCompoundAggregationIds,
        selectedCompoundAggregationIds,
        requiredItemCounterIds,
        selectedItemCounterIds,
        linkedPlanTemplateIds,
        planIds,
        planTemplateIds,
        allPricingIds,
        allItemCounterPricingIds
      )
    );
  } else {
    // Nothing to restore, so put an empty success action.
    yield put(restoreSelectionsSuccess());
  }
}

export function* createPricingSaga<P extends PricingCommon>(
  pricing: P,
  actionCreator: ActionCreator<AddItemCounterPricingAction | AddPricingAction>
): Generator<StrictEffect, void, any> {
  // Check the pricing relates to a plan/plan template that is in the grid.
  const selectedPlanIds: Array<string> = yield select(selectSelectedPlanIds);
  const selectedPlanTemplateIds: Array<string> = yield select(
    selectSelectedPlanTemplateIds
  );
  const linkedPlanTemplateIds: Array<string> = yield select(
    selectLinkedPlanTemplateIds
  );

  if (
    (pricing.planId && selectedPlanIds.includes(pricing.planId)) ||
    (pricing.planTemplateId &&
      (selectedPlanTemplateIds.includes(pricing.planTemplateId) ||
        linkedPlanTemplateIds.includes(pricing.planTemplateId)))
  ) {
    yield put(actionCreator(pricing.id));
  }
}

export function* dataCreatedSaga(
  action: DataCreatedAction
): Generator<StrictEffect, void, any> {
  const { data, dataType } = action.payload;

  if (dataType === DataType.Pricing) {
    yield call(createPricingSaga, data[0] as Pricing, addPricing);
  }

  if (dataType === DataType.CounterPricing) {
    yield call(
      createPricingSaga,
      data[0] as CounterPricing,
      addItemCounterPricing
    );
  }
}

export function* createPricingsSaga<P extends PricingCommon>(
  pricings: Array<P>,
  foreignKey: string,
  originalEntityId: Id,
  newEntityId: Id,
  dataType: PricingDataType
): Generator<StrictEffect, Array<P>, any> {
  const relatedPricings = pricings.filter(
    (pricing) => pricing[foreignKey as keyof P] === originalEntityId
  );
  const newPricingOverrides = { [foreignKey]: newEntityId } as Partial<P>;
  const createPricingCalls = relatedPricings.map((pricing) =>
    call(createData, dataType, cloneEntity(pricing, newPricingOverrides))
  );
  const responses = yield all(createPricingCalls);
  return responses;
}

export function* duplicateSaga(
  action: DuplicationAction
): Generator<StrictEffect, void, any> {
  let originalEntity: Plan | PlanTemplate | undefined;
  let newEntityOverrides: Partial<Plan> | Partial<PlanTemplate> | undefined;
  let dataType: DataType.Plan | DataType.PlanTemplate | undefined;
  let pricingForeignKey: string | undefined;
  let itemCounterPricingForeignKey: string | undefined;

  try {
    // Select the plan / plan template from the state. We don't want to reload
    // it in case that means duplicating a plan that has since changed.
    if (isDuplicatePlanAction(action)) {
      const { planId, newName, newCode } = action.payload;
      const selector = yield call(selectById, DataType.Plan, planId);
      originalEntity = yield select(selector);
      newEntityOverrides = { code: newCode, name: newName };
      dataType = DataType.Plan;
      pricingForeignKey = getRelationship(DataType.Pricing, 'plan').foreignKey;
      itemCounterPricingForeignKey = getRelationship(
        DataType.CounterPricing,
        'plan'
      ).foreignKey;
    } else if (isDuplicatePlanTemplateAction(action)) {
      const { planTemplateId, newName, newCode } = action.payload;
      const selector = yield call(
        selectById,
        DataType.PlanTemplate,
        planTemplateId
      );
      originalEntity = yield select(selector);
      newEntityOverrides = { name: newName, code: newCode };
      dataType = DataType.PlanTemplate;
      pricingForeignKey = getRelationship(
        DataType.Pricing,
        'planTemplate'
      ).foreignKey;
      itemCounterPricingForeignKey = getRelationship(
        DataType.CounterPricing,
        'planTemplate'
      ).foreignKey;
    }

    const canContinue =
      originalEntity &&
      newEntityOverrides &&
      dataType &&
      (pricingForeignKey || itemCounterPricingForeignKey);

    if (canContinue) {
      const newEntityData = cloneEntity(originalEntity!, newEntityOverrides);
      const newEntity: Plan | PlanTemplate = yield call(
        createData,
        dataType!,
        newEntityData
      );

      let pricingResponses: Array<Pricing> = [];
      let itemCounterPricingResponses: Array<CounterPricing> = [];

      if (pricingForeignKey) {
        const pricings: Array<Pricing> = yield select(selectPricings);
        pricingResponses = yield call(
          createPricingsSaga,
          pricings,
          pricingForeignKey,
          originalEntity!.id,
          newEntity.id,
          DataType.Pricing
        );
      }

      if (itemCounterPricingForeignKey) {
        const itemCounterPricings: Array<CounterPricing> = yield select(
          selectItemCounterPricings
        );
        itemCounterPricingResponses = yield call(
          createPricingsSaga,
          itemCounterPricings,
          itemCounterPricingForeignKey,
          originalEntity!.id,
          newEntity.id,
          DataType.CounterPricing
        );
      }

      if (dataType === DataType.Plan) {
        yield put(
          duplicatePlanSuccess(
            newEntity.id,
            ids(pricingResponses),
            ids(itemCounterPricingResponses)
          )
        );
      } else if (dataType === DataType.PlanTemplate) {
        yield put(
          duplicatePlanTemplateSuccess(
            newEntity.id,
            ids(pricingResponses),
            ids(itemCounterPricingResponses)
          )
        );
      }
    }
  } catch (error) {
    yield put(duplicationFailure(extractError(error)));
  }
}

export function* getRequiredPricingsSaga<P extends PricingCommon>(
  pricings: Array<P>
): Generator<StrictEffect, Array<P>, any> {
  const selectedPlanIds: Array<Id> = yield select(selectSelectedPlanIds);
  const selectedPlanTemplateIds: Array<Id> = yield select(
    selectSelectedPlanTemplateIds
  );
  const linkedPlanTemplateIds: Array<Id> = yield select(
    selectLinkedPlanTemplateIds
  );

  // Include pricing that links to any plans, plan templates or linked plan templates.
  return pricings.filter(
    (pricing) =>
      (pricing.planId && selectedPlanIds.includes(pricing.planId)) ||
      (pricing.planTemplateId &&
        (selectedPlanTemplateIds.includes(pricing.planTemplateId) ||
          linkedPlanTemplateIds.includes(pricing.planTemplateId)))
  );
}

const isRemovePlanAction = (
  action: RemovalAction
): action is RemovePlanAction => {
  return action.type === removePlan.type;
};

const filterPricingsToRemove = <P extends PricingCommon>(
  currentItems: Array<P>,
  requiredItems: Array<P>
): Array<P> => currentItems.filter((item) => !requiredItems.includes(item));

const filterItemsToRemove = <E extends Entity, P extends PricingCommon>(
  currentItems: Array<E>,
  requiredPricings: Array<P>,
  referenceKey: keyof P
): Array<E> =>
  currentItems.filter(
    (item) =>
      !requiredPricings.some(
        (requiredItem) => requiredItem[referenceKey] === item.id
      )
  );

export function* removeRelatedSaga(
  action: RemovalAction
): Generator<StrictEffect, void, any> {
  // When removing a plan we first need to check if we can remove its
  // plan template from linked plan templates.
  if (isRemovePlanAction(action)) {
    const { planId } = action.payload;

    const planSelector = yield call(selectById, DataType.Plan, planId);
    const plan: Plan = yield select(planSelector);

    // If the plan's template is not used by other selected plans we can remove
    // it from linked plan templates.
    if (plan) {
      const selectedPlans: Array<Plan> = yield select(selectSelectedPlans);
      if (
        !selectedPlans.some(
          (selectedPlan) => selectedPlan.planTemplateId === plan.planTemplateId
        )
      ) {
        yield put(removeLinkedPlanTemplate(plan.planTemplateId));
      }
    }
  }

  const currentPricings: Array<Pricing> = yield select(selectPricings);
  const requiredPricings: Array<Pricing> = yield call(
    getRequiredPricingsSaga,
    currentPricings
  );
  const currentItemCounterPricings: Array<CounterPricing> = yield select(
    selectItemCounterPricings
  );
  const requiredItemCounterPricings: Array<CounterPricing> = yield call(
    getRequiredPricingsSaga,
    currentItemCounterPricings
  );

  const pricingsToRemove = filterPricingsToRemove(
    currentPricings,
    requiredPricings
  );
  const itemCounterPricingsToRemove = filterPricingsToRemove(
    currentItemCounterPricings,
    requiredItemCounterPricings
  );

  if (pricingsToRemove.length > 0 || itemCounterPricingsToRemove.length > 0) {
    yield put(
      removePricings(ids(pricingsToRemove), ids(itemCounterPricingsToRemove))
    );
  }

  const currentAggregations: Array<Aggregation> = yield select(
    selectRequiredAggregations
  );
  const aggregationsToRemove = filterItemsToRemove(
    currentAggregations,
    requiredPricings,
    'aggregationId'
  );

  const currentCompoundAggregations: Array<CompoundAggregation> = yield select(
    selectRequiredCompoundAggregations
  );
  const compoundAggregationsToRemove = filterItemsToRemove(
    currentCompoundAggregations,
    requiredPricings,
    'compoundAggregationId'
  );

  const currentItemCounters: Array<Counter> = yield select(
    selectRequiredItemCounters
  );
  const itemCountersToRemove = filterItemsToRemove(
    currentItemCounters,
    requiredItemCounterPricings,
    'counterId'
  );

  if (
    aggregationsToRemove.length > 0 ||
    compoundAggregationsToRemove.length > 0 ||
    itemCountersToRemove.length > 0
  ) {
    yield put(
      removeUsageEntities(
        ids(aggregationsToRemove),
        ids(compoundAggregationsToRemove),
        ids(itemCountersToRemove),
        RemoveFromLocation.Required
      )
    );
  }
}

export default function* planEditorSaga() {
  yield takeLatest(loadInitialData.type, loadInitialDataSaga);
  yield takeEvery(addAggregations.type, addAggregationsSaga);
  yield takeEvery(addCompoundAggregations.type, addCompoundAggregationsSaga);
  yield takeEvery(addItemCounters.type, addItemCountersSaga);
  yield takeEvery(addPlans.type, addPlansSaga);
  yield takeEvery(addPlanTemplates.type, addPlanTemplatesSaga);
  yield takeEvery(dataCreated.type, dataCreatedSaga);

  const duplicationActions = [duplicatePlan, duplicatePlanTemplate];
  yield takeEvery(duplicationActions, duplicateSaga);

  // Each time we remove a plan / plan template, we need to clean up
  // the related pricings, aggregations and compound aggregations.
  const removalActions = [removePlan.type, removePlanTemplate.type];
  yield takeEvery(removalActions, removeRelatedSaga);

  // Each time we successfully add/remove a plan / template / aggregation, persist the selections.
  const persistableActions = [
    addPricing.type,
    addPlansSuccess.type,
    addPlanTemplatesSuccess.type,
    addAggregationsSuccess.type,
    addCompoundAggregationsSuccess.type,
    addItemCountersSuccess.type,
    removePlan.type,
    removePlanTemplate.type,
    removeUsageEntities.type,
    duplicatePlanSuccess.type,
  ];
  yield takeLatest(persistableActions, persistSelectionsSaga);

  yield takeEvery(restoreSelections.type, restoreSelectionsSaga);
}
