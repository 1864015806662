import {
  all,
  call,
  put,
  select,
  StrictEffect,
  takeEvery,
} from 'redux-saga/effects';

import {
  DataType,
  ServiceUser,
  updateEntityPermissionPolicy,
} from '@m3ter-com/m3ter-api';

import { extractError } from '@/util/error';
import { createData } from '@/store/data/data.saga';
import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';
import { createServiceUserCredential } from '@/store/features/access/service-user/serviceUserCredentials';

import {
  createServiceUser,
  CreateServiceUserAction,
  createServiceUserFailure,
  createServiceUserSuccess,
} from './serviceUserCreate';

export function* createServiceUserSaga(
  action: CreateServiceUserAction
): Generator<StrictEffect, void, any> {
  const { formValues } = action.payload;
  const { onSuccess, onFailure } = action.meta;
  const { generateAccessKey, permissionPolicyIds, ...serviceUserValues } =
    formValues;

  try {
    const serviceUser: ServiceUser = yield call(
      createData,
      DataType.ServiceUser,
      serviceUserValues
    );

    const organizationId = yield select(selectCurrentOrgId);

    if (generateAccessKey) {
      yield put(createServiceUserCredential(serviceUser.id));
    }

    if (permissionPolicyIds.length > 0) {
      yield all(
        permissionPolicyIds.map((permissionPolicyId) =>
          call(updateEntityPermissionPolicy, {
            actionName: 'addtoserviceuser',
            organizationId,
            permissionPolicyId,
            data: { principalId: serviceUser.id },
          })
        )
      );
    }

    yield put(createServiceUserSuccess(serviceUser, onSuccess));
  } catch (error) {
    yield put(createServiceUserFailure(extractError(error), onFailure));
  }
}

export default function* serviceUserCreateSaga() {
  yield takeEvery(createServiceUser.type, createServiceUserSaga);
}
