import i18next from 'i18next';

import { CreditType } from '@m3ter-com/m3ter-api';

export const getCreditOrderDescription = (
  order: Array<CreditType>
): string | null => {
  if (Array.isArray(order)) {
    if (order[0] === CreditType.Prepayment) {
      return order[1] === CreditType.Balance
        ? i18next.t('forms:labels.prepaymentThenBalance')
        : i18next.t('forms:labels.prepaymentOnly');
    }
    if (order[0] === CreditType.Balance) {
      return order[1] === CreditType.Prepayment
        ? i18next.t('forms:labels.balanceThenPrepayment')
        : i18next.t('forms:labels.balanceOnly');
    }
  }
  return null;
};
