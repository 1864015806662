import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { CompleteNewPasswordFormValues } from '@m3ter-com/console-core/types';

const suite = create(
  'completeNewPassword',
  (data: Partial<CompleteNewPasswordFormValues>) => {
    test(
      'newPassword',
      i18next.t('forms:validations.users.passwordRequired'),
      () => {
        enforce(data.newPassword).isNotEmpty();
      }
    );

    test(
      'confirmPassword',
      i18next.t('forms:validations.users.confirmNewPassword'),
      () => {
        enforce(data.confirmPassword).isNotEmpty();
      }
    );

    test(
      'confirmPassword',
      i18next.t('forms:validations.users.passwordsMustMatch'),
      () => {
        enforce(data.newPassword).equals(data.confirmPassword);
      }
    );

    test('name', i18next.t('forms:validations.common.nameRequired'), () => {
      enforce(data.name).isNotEmpty();
    });
  }
);

export default suite;
