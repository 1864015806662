import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import { v4 } from 'uuid';

import { DateTimeISOString, IngestRequestBody } from '@m3ter-com/m3ter-api';

interface SubmitMeasurementsPayload {
  body: IngestRequestBody;
}
export type SubmitMeasurementsAction = PayloadAction<SubmitMeasurementsPayload>;

interface SubmitMeasurementsFailurePayload {
  response: string;
}
export type SubmitMeasurementsFailureAction =
  PayloadAction<SubmitMeasurementsFailurePayload>;

interface SubmitMeasurementsSuccessPayload {
  response: string;
}
export type SubmitMeasurementsSuccessAction =
  PayloadAction<SubmitMeasurementsSuccessPayload>;

export interface Submission {
  id: string;
  body: IngestRequestBody;
  requestTimestamp: DateTimeISOString;
  response?: string;
  responseType?: 'success' | 'error';
  responseTimestamp?: DateTimeISOString;
}

export interface IngestState {
  isSubmitting: boolean;
  submissions: Array<Submission>;
}

const name = 'features/usage/ingest';
export const initialState: IngestState = {
  isSubmitting: false,
  submissions: [],
};

const ingestSlice = createSlice({
  name,
  initialState,
  reducers: {
    submitMeasurements: {
      reducer: (state: IngestState, action: SubmitMeasurementsAction) => {
        const { body } = action.payload;
        state.isSubmitting = true;
        // Clone to avoid referencing the same objects/arrays as the form that submitted them.
        state.submissions.unshift({
          id: v4(),
          body: cloneDeep(body),
          requestTimestamp: new Date().toISOString(),
        });
      },
      prepare: (body: IngestRequestBody) => ({
        payload: { body },
      }),
    },
    submitMeasurementsFailure: {
      reducer: (
        state: IngestState,
        action: SubmitMeasurementsFailureAction
      ) => {
        if (state.submissions.length > 0) {
          state.submissions[0].responseType = 'error';
          state.submissions[0].response = action.payload.response;
          state.submissions[0].responseTimestamp = new Date().toISOString();
          state.isSubmitting = false;
        }
      },
      prepare: (response: string) => ({ payload: { response } }),
    },
    submitMeasurementsSuccess: {
      reducer: (
        state: IngestState,
        action: SubmitMeasurementsFailureAction
      ) => {
        if (state.submissions.length > 0) {
          state.submissions[0].responseType = 'success';
          state.submissions[0].response = action.payload.response;
          state.submissions[0].responseTimestamp = new Date().toISOString();
          state.isSubmitting = false;
        }
      },
      prepare: (response: string) => ({ payload: { response } }),
    },
    reset: () => initialState,
  },
});

export default ingestSlice.reducer;

export const {
  submitMeasurements,
  submitMeasurementsFailure,
  submitMeasurementsSuccess,
  reset,
} = ingestSlice.actions;

const selectIngestState = (state: {
  features: { usage: { ingest: IngestState } };
}): IngestState => state.features.usage.ingest;

export const selectIsSubmittingMeasurements = createSelector(
  selectIngestState,
  (state) => state.isSubmitting
);

export const selectSubmissions = createSelector(
  selectIngestState,
  (state) => state.submissions
);
