import React from 'react';

import { UserGroup } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormInput,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import userGroupSchema from '@/validation/userGroup';

const defaultInitialValues = {};

export interface UserGroupEditFormProps extends BaseFormProps<UserGroup> {}

export const UserGroupEditForm: React.FC<UserGroupEditFormProps> = ({
  onSave,
  onCancel,
  isSaving = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={userGroupSchema}
    >
      <FormStack mb={4}>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: t('common:userGroup'),
          })}
        >
          <FormField
            isRequired
            name="name"
            label={t('forms:labels.name')}
            control={FormInput}
          />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={t('forms:buttons.updateEntity', {
            entityName: t('common:userGroup'),
          })}
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
