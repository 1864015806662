import React from 'react';

import { Box, IconButton } from '@chakra-ui/react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FaTimes } from 'react-icons/fa';

import { useTranslation, useFormError } from '@m3ter-com/console-core/hooks';
import { Alert, SelectOption } from '@m3ter-com/ui-components';
import {
  FormAddRowButton,
  FormField,
  FormInput,
  FormMultiRowGridWrapper,
  FormMultiRowWrapper,
  FormSection,
  FormSelect,
  FormTextarea,
  NameCodeFields,
} from '@m3ter-com/console-core/components';

import { isCategoryNumeric } from '@/util/meter';

const MAX_DERIVED_FIELDS = 15;

export interface DerivedFieldsFieldProps {
  name: string;
  categoryOptions: Array<SelectOption>;
}

export const DerivedFieldsField: React.FC<DerivedFieldsFieldProps> = ({
  name,
  categoryOptions,
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const { isInvalid, message } = useFormError(name);
  const values = useWatch({ control, name });
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <FormSection
      isOptional
      heading={`${t('forms:labels.derivedFields')} ${t(
        'forms:labels.maximumWithNumber',
        {
          max: MAX_DERIVED_FIELDS,
        }
      )}`}
    >
      {isInvalid && message && <Alert status="error">{message}</Alert>}
      <FormMultiRowWrapper
        hasFields={fields.length > 0}
        emptyContentMessage={t('features:meters.noDerivedFields')}
      >
        {fields.map((field, index) => {
          const namePrefix = `${name}.${index}.`;
          const isUnitFieldDisabled =
            !values[index] || !isCategoryNumeric(values[index].category);
          return (
            <FormMultiRowGridWrapper key={field.id} columnCount={4}>
              <NameCodeFields
                nameFieldPrefix={namePrefix}
                codeFieldPrefix={namePrefix}
              />
              <FormField
                label={t('forms:labels.category')}
                name={`${namePrefix}category`}
                control={FormSelect}
                isRequired={false}
                options={categoryOptions}
              />
              <FormField
                label={t('forms:labels.unit')}
                name={`${namePrefix}unit`}
                control={FormInput}
                isDisabled={isUnitFieldDisabled}
                isRequired={false}
              />
              <Box gridColumn="1/5">
                <FormField
                  label={t('forms:labels.calculation')}
                  name={`${namePrefix}calculation`}
                  control={FormTextarea}
                  isRequired={false}
                  w="100%"
                />
              </Box>
              <IconButton
                aria-label={t('common:remove')}
                icon={<FaTimes />}
                onClick={() => remove(index)}
                alignSelf="start"
                gridRow="1"
                gridColumn="5"
                mt={8}
              />
            </FormMultiRowGridWrapper>
          );
        })}
      </FormMultiRowWrapper>
      <FormAddRowButton
        maxRows={MAX_DERIVED_FIELDS}
        isDisabled={fields.length >= MAX_DERIVED_FIELDS}
        onAdd={() => {
          append({});
        }}
      />
    </FormSection>
  );
};
