import React, { useMemo } from 'react';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Trans } from 'react-i18next';

import {
  DataType,
  BillJobFrequency,
  CurrencyCode,
  DateTimeISOString,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Alert,
  AlertLink,
  FormStack,
  SelectOption,
} from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormSelect,
} from '@m3ter-com/console-core/components';

import type { TFunction } from 'i18next';

import schema from '@/validation/runBillingModal';
import { FormDatePicker } from '@/components/forms/FormDatePicker';
import { FormPicklistSelect } from '@/components/features/accounts/FormPicklistSelect';
import { NamedLink } from '@/components/common/navigation/NamedLink';

export interface RunBillingFormValues {
  externalInvoiceDate: DateTimeISOString;
  frequency?: BillJobFrequency;
  targetCurrency?: CurrencyCode;
}

export interface RunBillingModalProps {
  includeFrequency?: boolean;
  initialValues?: Partial<RunBillingFormValues>;
  isOpen?: boolean;
  onClose: () => void;
  onSubmit: (data: RunBillingFormValues) => void;
}

const defaultInitialValues: Partial<RunBillingFormValues> = {};

export const RunBillingModal: React.FC<RunBillingModalProps> = ({
  includeFrequency = true,
  initialValues = defaultInitialValues,
  isOpen = false,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const frequencyOptions = useMemo<Array<SelectOption<BillJobFrequency>>>(
    () =>
      Object.values(BillJobFrequency).map((frequencyValue) => ({
        value: frequencyValue,
        label: t(`common:frequency.${frequencyValue}`),
      })),
    [t]
  );

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={schema}
        >
          <ModalHeader>{t('features:billing.runBilling')}</ModalHeader>
          <ModalBody>
            <FormStack>
              <Alert
                data-testid="child-account-bill-calculation-alert"
                status="info"
              >
                {t(
                  'features:billing.runBillingChildAccountBillCalculationWarning'
                )}
              </Alert>
              <FormField
                isRequired
                name="externalInvoiceDate"
                label={t('features:billing.invoiceDate')}
                control={FormDatePicker}
              />
              <FormField
                isClearable
                name="frequency"
                label={t('features:billing.billFrequency')}
                control={FormSelect}
                options={frequencyOptions}
                showField={includeFrequency}
                placeholder={t('common:all')}
                alert={
                  !includeFrequency && (
                    <Alert data-testid="bill-frequency-alert" status="info">
                      <Trans
                        t={t as TFunction}
                        i18nKey="features:billing.billFrequencyWarning"
                        components={{
                          orgConfigEditLink: (
                            <AlertLink
                              as={NamedLink}
                              name="settings.org.configuration.edit"
                            />
                          ),
                        }}
                      />
                    </Alert>
                  )
                }
              />
              <FormField
                name="targetCurrency"
                label={t('forms:labels.targetCurrency')}
                helpText={t('forms:helpText.targetCurrency')}
                control={FormPicklistSelect}
                useCodeAsValue
                dataType={DataType.Currency}
              />
            </FormStack>
          </ModalBody>
          <ModalFooter>
            <FormActions
              submitText={t('features:billing.runBilling')}
              cancelText={t('common:cancel')}
              onCancel={onClose}
            />
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
};
