import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Id } from '@m3ter-com/m3ter-api';

import {
  addPlansToPlanGroup,
  loadAccountPlanPricingData,
  loadAndAddExtraUsageEntities,
  loadPlanPricingData,
  reset,
  selectExtraUsageEntitiesError,
  selectExtraPricingAggregations,
  selectExtraPricingCompoundAggregations,
  selectIsLoadingExtraUsageEntities,
  selectIsLoadingPricingData,
  selectPlanDetailsError,
  selectPricingDataEntities,
  selectExtraPricingItemCounters,
} from '@/store/features/pricing/planDetails';
import useQueryString from '@/hooks/navigation/useQueryString';

interface UsageEntityParams {
  aggregationId?: Id;
  compoundAggregationId?: Id;
  counterId?: Id;
  aggregationIds?: string;
  compoundAggregationIds?: string;
  itemCounterIds?: string;
}

const usePlanDetails = (
  planTemplateId?: Id,
  planId?: Id,
  accountPlanId?: Id
) => {
  const dispatch = useDispatch();
  const {
    aggregationId: addAggregationId,
    compoundAggregationId: addCompoundAggregationId,
    counterId: addItemCounterId,
    aggregationIds: extraAggregationIds,
    compoundAggregationIds: extraCompoundAggregationIds,
    itemCounterIds: extraItemCounterIds,
  } = useQueryString<UsageEntityParams>();
  useEffect(() => {
    if (planTemplateId) {
      dispatch(
        loadPlanPricingData(
          planTemplateId,
          planId,
          addAggregationId,
          addCompoundAggregationId,
          addItemCounterId
        )
      );
    } else if (accountPlanId) {
      dispatch(
        loadAccountPlanPricingData(
          accountPlanId,
          addAggregationId,
          addCompoundAggregationId,
          addItemCounterId
        )
      );
    }

    return () => {
      dispatch(reset());
    };
  }, [
    dispatch,
    planTemplateId,
    planId,
    accountPlanId,
    addAggregationId,
    addCompoundAggregationId,
    addItemCounterId,
  ]);

  useEffect(() => {
    if (
      extraAggregationIds ||
      extraCompoundAggregationIds ||
      extraItemCounterIds
    ) {
      const aggregationIds = extraAggregationIds
        ? extraAggregationIds.split(',')
        : [];
      const compoundAggregationIds = extraCompoundAggregationIds
        ? extraCompoundAggregationIds.split(',')
        : [];
      const itemCounterIds = extraItemCounterIds
        ? extraItemCounterIds.split(',')
        : [];

      dispatch(
        loadAndAddExtraUsageEntities(
          aggregationIds,
          compoundAggregationIds,
          itemCounterIds
        )
      );
    }
    /**
     * The useEffect needs to trigger whenever any of the search parameters change,
     * including 'extra' usage entity IDs and 'add' usage entity ID changes.
     * This ensures that the extra usage entities are reloaded when any parameter changes.
     * Without this, removing added usage entities from the search parameters would prevent
     * the IDs from being reloaded when the search parameters are replced.
     */
  }, [
    dispatch,
    addAggregationId,
    addCompoundAggregationId,
    addItemCounterId,
    extraAggregationIds,
    extraCompoundAggregationIds,
    extraItemCounterIds,
  ]);

  const error = useSelector(selectPlanDetailsError);
  const extraUsageEntitiesError = useSelector(selectExtraUsageEntitiesError);
  const extraPricingAggregations = useSelector(selectExtraPricingAggregations);
  const extraPricingCompoundAggregations = useSelector(
    selectExtraPricingCompoundAggregations
  );
  const extraPricingItemCounters = useSelector(selectExtraPricingItemCounters);
  const isLoading = useSelector(selectIsLoadingPricingData);
  const isLoadingExtraUsageEntities = useSelector(
    selectIsLoadingExtraUsageEntities
  );
  const pricingData = useSelector(selectPricingDataEntities);

  const onAddPlansToPlanGroup = useCallback(
    (planIds: Array<Id>) => {
      if (!pricingData?.planGroup) {
        return;
      }

      dispatch(addPlansToPlanGroup(pricingData.planGroup.id, planIds));
    },
    [pricingData, dispatch]
  );

  return {
    error: error ?? extraUsageEntitiesError,
    extraPricingAggregations,
    extraPricingCompoundAggregations,
    extraPricingItemCounters,
    isLoading,
    isLoadingExtraUsageEntities,
    pricingData,
    onAddPlansToPlanGroup,
  };
};

export default usePlanDetails;
