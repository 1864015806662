import React from 'react';

import { ServiceUser } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { ServiceUserEditForm } from '@/components/features/access/service-user/ServiceUserEditForm';

export const ServiceUsersEditRoute: React.FC = () => (
  <CrudEdit<ServiceUser> form={ServiceUserEditForm} />
);
