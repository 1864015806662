import React from 'react';

import { ButtonGroup } from '@chakra-ui/react';

import {
  getIntegrationCredentialRedirectURL,
  IntegrationConfigDestinationSchema,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert, Button, FormStack } from '@m3ter-com/ui-components';
import { FormSection } from '@m3ter-com/console-core/components';

import useAppQuery from '@/hooks/data/useAppQuery';
import useOrg from '@/hooks/data/crud/useOrg';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch';

export interface IntegrationCredentialConfigureExternalContentProps {
  destinationSchema: IntegrationConfigDestinationSchema;
  name: string;
  onBack: () => void;
  onCancel: () => void;
}

// For some integration destinations, we need to redirect the user to the 3rd party's website. This component handles
// loading the redirect URL and rendering a link to it. The backend then handles creating the credential and sending
// them back to the console.
export const IntegrationCredentialConfigureExternalContent: React.FC<
  IntegrationCredentialConfigureExternalContentProps
> = ({ destinationSchema, name, onBack, onCancel }) => {
  const { t } = useTranslation();

  const { currentOrgId: organizationId } = useOrg();
  const {
    data: redirectUrlResponse,
    error: redirectUrlQueryError,
    isPending: isLoadingRedirectUrl,
  } = useAppQuery({
    queryKey: [
      'integration-credential-redirect-url',
      organizationId,
      destinationSchema.destination,
      name,
    ],
    queryFn: () =>
      getIntegrationCredentialRedirectURL({
        destination: destinationSchema.destination,
        name,
        organizationId,
      }),
  });

  return (
    <FormStack>
      <FormSection
        heading={`${destinationSchema.displayName} ${t(
          'features:integrations.connection'
        )}`}
      >
        <LoadingErrorContentSwitch
          error={redirectUrlQueryError}
          isLoading={isLoadingRedirectUrl}
        >
          {redirectUrlResponse && (
            <React.Fragment>
              <Alert status="info">
                {t(
                  'features:integrations.integrationCredentialsExternalLinkInfo',
                  {
                    destination: destinationSchema.displayName,
                  }
                )}
              </Alert>
              <Button intent="primary" as="a" href={redirectUrlResponse.uri}>
                {t('features:integrations.goToDestination', {
                  destination: destinationSchema.displayName,
                })}
              </Button>
            </React.Fragment>
          )}
        </LoadingErrorContentSwitch>
      </FormSection>
      <ButtonGroup>
        <Button variant="ghost" onClick={onBack}>
          {t('common:back')}
        </Button>
        <Button variant="ghost" onClick={onCancel}>
          {t('common:cancel')}
        </Button>
      </ButtonGroup>
    </FormStack>
  );
};
