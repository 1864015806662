import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Id } from '@m3ter-com/m3ter-api';

import {
  loadAccountContracts,
  reset,
  selectAccountContractsError,
  selectActiveAndPendingContracts,
  selectIsAccountContractsLoading,
  selectPreviousContracts,
} from '@/store/features/accounts/accountContracts';

const useAccountContracts = (accountId: Id) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAccountContracts(accountId));

    return () => {
      dispatch(reset());
    };
  }, [accountId, dispatch]);

  const isLoading = useSelector(selectIsAccountContractsLoading);
  const error = useSelector(selectAccountContractsError);
  const activeAndPendingContracts = useSelector(
    selectActiveAndPendingContracts
  );
  const previousContracts = useSelector(selectPreviousContracts);

  return { isLoading, error, activeAndPendingContracts, previousContracts };
};

export default useAccountContracts;
