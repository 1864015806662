import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { IntegrationConfig, UnsavedEntity } from '@m3ter-com/m3ter-api';

const suite = create(
  'integrationConfig',
  (
    data: Omit<
      UnsavedEntity<IntegrationConfig>,
      'authorized' | 'entityType' | 'destination'
    >
  ) => {
    test('name', i18next.t('forms:validations.common.nameRequired'), () => {
      enforce(data.name).isNotEmpty();
    });
  }
);

export default suite;
