import React, { PropsWithChildren } from 'react';

import { CardBody, StyleProps } from '@chakra-ui/react';

export const WidgetBody: React.FC<PropsWithChildren<StyleProps>> = ({
  children,
  ...styles
}) => (
  <CardBody overflowY="scroll" {...styles}>
    {children}
  </CardBody>
);
