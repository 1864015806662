import React, { ComponentProps } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { DatePicker, SelectOption } from '@m3ter-com/ui-components';

import useDateFormatter from '@/hooks/util/useDateFormatter';

import { CrudListFilterComparatorSelect } from './CrudListFilterComparatorSelect';

interface CrudListDateFilterProps
  extends Omit<ComponentProps<typeof DatePicker>, 'value' | 'onChange'> {
  name: string;
  comparatorOptions: Array<SelectOption>;
}

export const CrudListDateFilter: React.FC<CrudListDateFilterProps> = ({
  name,
  comparatorOptions,
  ...props
}) => {
  const { control } = useFormContext();
  const { timeZone, toShortDate } = useDateFormatter();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: {
          value = { comparator: comparatorOptions[0].value, value: '' },
          onChange,
        },
      }) => (
        <CrudListFilterComparatorSelect
          value={value}
          onChange={onChange}
          comparatorOptions={comparatorOptions}
        >
          <DatePicker
            value={value.value}
            onChange={(date: string | null) => {
              if (date) {
                // Search service will match dates without the time part so we can strip it here.
                onChange({ ...value, value: toShortDate(date) });
              } else {
                onChange({ ...value, value: '' });
              }
            }}
            showTimeSelect={false}
            timeZone={timeZone}
            {...props}
          />
        </CrudListFilterComparatorSelect>
      )}
    />
  );
};
