import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const stepper: ComponentMultiStyleConfig = {
  parts: [
    'stepper',
    'step',
    'title',
    'description',
    'indicator',
    'separator',
    'icon',
    'number',
  ],
  baseStyle: {
    stepper: {
      justifyContent: 'start',
    },
    step: {
      maxWidth: '250px',
      flexWrap: 'wrap',
    },
    title: {
      flexBasis: '100%', // Force the title to wrap below the indicator / separator.
    },
  },
};

export default stepper;
