import React, { useMemo } from 'react';

import {
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
} from '@chakra-ui/react';

import {
  DataType,
  BillingEntity,
  DateTimeISOString,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Form,
  FormField,
  FormSwitch,
  FormMultiSelect,
} from '@m3ter-com/console-core/components';
import { Button, FormStack, SelectOption } from '@m3ter-com/ui-components';

import schema from '@/validation/endDateBillingEntities';
import { FormDatePicker } from '@/components/forms/FormDatePicker';

export interface EndBillingEntitiesFormValues {
  endDate: DateTimeISOString;
  billingEntities: Array<BillingEntity>;
  applyToChildren?: boolean;
}

export interface EndBillingEntitiesModalProps {
  dataType: DataType.Account | DataType.Contract;
  showApplyToChildren?: boolean;
  initialValues?: Partial<EndBillingEntitiesFormValues>;
  isOpen?: boolean;
  onClose: () => void;
  onSubmit: (data: EndBillingEntitiesFormValues) => void;
}

const defaultInitialValues: Partial<EndBillingEntitiesFormValues> = {};

export const EndBillingEntitiesModal: React.FC<
  EndBillingEntitiesModalProps
> = ({
  dataType,
  showApplyToChildren = false,
  initialValues = defaultInitialValues,
  isOpen = false,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const billingEntityOptions = useMemo<Array<SelectOption<BillingEntity>>>(
    () =>
      Object.values(BillingEntity).map((entity) => ({
        value: entity,
        label: t(`common:billingEntities.${entity}`),
      })),
    [t]
  );

  const entityName = t(`common:${dataType}`);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={schema}
        >
          <ModalHeader>
            {t('common:endBillingEntities', { entityName })}
          </ModalHeader>
          <ModalBody>
            <FormStack>
              <FormField
                isRequired
                name="billingEntities"
                label={t('forms:labels.billingEntities')}
                control={FormMultiSelect}
                options={billingEntityOptions}
                helpText={t('forms:helpText.billingEntities', { entityName })}
                isClearable
              />
              {showApplyToChildren && (
                <FormField
                  name="applyToChildren"
                  label={t('forms:labels.applyToChildren')}
                  control={FormSwitch}
                  helpText={
                    dataType === DataType.Account
                      ? t('forms:helpText.applyToChildrenAccounts')
                      : t('forms:helpText.applyToChildrenContracts')
                  }
                />
              )}
              <FormField
                isRequired
                name="endDate"
                label={t('forms:labels.endDate')}
                control={FormDatePicker}
                helpText={t('forms:helpText.endDateBillingEntities')}
              />
            </FormStack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button type="submit" intent="primary">
                {t('common:endBillingEntities', { entityName })}
              </Button>
              <Button type="button" variant="ghost" onClick={onClose}>
                {t('common:cancel')}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
};
