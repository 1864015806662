import React from 'react';

import { Commitment } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { CommitmentForm } from '@/components/features/accounts/CommitmentForm';

export const CommitmentsEditRoute: React.FC = () => {
  return <CrudEdit<Commitment> form={CommitmentForm} />;
};
