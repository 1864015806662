import {
  call,
  put,
  StrictEffect,
  takeLatest,
  select,
} from 'redux-saga/effects';

import {
  DataType,
  ListResponse,
  Id,
  IntegrationConfigLink,
  NotificationEvent,
} from '@m3ter-com/m3ter-api';
import {} from '@m3ter-com/console-core/types';

import { ConnectedEvents } from '@/types/data';

import { extractError } from '@/util/error';
import { performDataAction } from '@/services/api';
import { listAllData, listData } from '@/store/data/data.saga';
import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';

import {
  loadNotificationDestinations,
  loadNotificationDestinationsFailure,
  loadNotificationDestinationsSuccess,
  loadNotificationEventsData,
  loadNotificationEventsDataFailure,
  loadNotificationEventsDataSuccess,
  LoadNotificationsDataAction,
} from './notifications';

export function* loadNotificationDestinationsSaga(
  action: LoadNotificationsDataAction
): Generator<StrictEffect, void, any> {
  try {
    const organizationId = yield select(selectCurrentOrgId);
    const { notificationId } = action.payload;

    const configLinks: ListResponse<IntegrationConfigLink> = yield call(
      performDataAction,
      DataType.Integration,
      'fetchLinkedIntegrations',
      { organizationId, entityType: 'Notification' },
      { entityId: notificationId }
    );

    const destinationIds: Array<Id> = configLinks.data.map(
      ({ destinationId }) => destinationId
    );

    yield call(listAllData, DataType.Destination);
    yield put(loadNotificationDestinationsSuccess(destinationIds));
  } catch (error) {
    yield put(loadNotificationDestinationsFailure(extractError(error)));
  }
}

export function* loadNotificationEventsDataSaga(): Generator<
  StrictEffect,
  void,
  any
> {
  try {
    const notificationEvents: ListResponse<NotificationEvent> = yield call(
      listData,
      DataType.M3terEvent,
      {
        eventType: 'Notification',
        pageSize: 100,
      }
    );

    const countConnectedEventNames = (
      notificationEventData: Array<NotificationEvent>
    ): Array<ConnectedEvents> => {
      const eventTypeMap: { [key: string]: typeof notificationEventData } = {};
      notificationEventData.forEach((notificationEvent) => {
        if (!eventTypeMap[notificationEvent.eventName]) {
          eventTypeMap[notificationEvent.eventName] = [];
        }
        eventTypeMap[notificationEvent.eventName].push(notificationEvent);
      });

      const connectedEvents: Array<ConnectedEvents> = [];
      Object.entries(eventTypeMap).forEach(([eventType, events]) => {
        const connectedRules = events.length;
        const mostRecentEventDate = events.reduce((a, b) =>
          new Date(a.eventTime) > new Date(b.eventTime) ? a : b
        ).eventTime;

        connectedEvents.push({
          eventName: eventType,
          triggeredEventsCount: connectedRules,
          mostRecentEventDate,
        });
      });

      return connectedEvents;
    };

    yield put(
      loadNotificationEventsDataSuccess(
        countConnectedEventNames(notificationEvents.data)
      )
    );
  } catch (error) {
    yield put(loadNotificationEventsDataFailure(extractError(error)));
  }
}

export default function* notificationsSaga() {
  yield takeLatest(
    loadNotificationDestinations.type,
    loadNotificationDestinationsSaga
  );
  yield takeLatest(
    loadNotificationEventsData.type,
    loadNotificationEventsDataSaga
  );
}
