import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useSteps } from '@chakra-ui/react';

import { DataType, ExternalMappingConfig } from '@m3ter-com/m3ter-api';
import { DeepPartial } from '@m3ter-com/console-core/types';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Stepper, Step } from '@m3ter-com/ui-components';

import { reset as resetExternalMappingData } from '@/store/features/integrations/external-mappings/externalMappingsData';
import useSingleton from '@/hooks/data/useSingleton';
import useExternalMappingM3terEntityOptions from '@/hooks/features/externalMappings/useExternalMappingM3terEntityOptions';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch';

import {
  ExternalMappingCreateFormStepOneValues,
  ExternalMappingCreateFormStepTwoValues,
  ExternalMappingCreateFormValues,
  ExternalMappingCreateMode,
} from './types';
import { ExternalMappingCreateFormStepOne } from './ExternalMappingCreateFormStepOne';
import { ExternalMappingCreateFormStepTwo } from './ExternalMappingCreateFormStepTwo';

export interface ExternalMappingCreateFormProps {
  isSaving: boolean;
  mode: ExternalMappingCreateMode;
  initialValues?: DeepPartial<ExternalMappingCreateFormValues>;
  onSave: (data: ExternalMappingCreateFormValues) => void;
  onCancel?: () => void;
}

export const ExternalMappingCreateForm: React.FC<
  ExternalMappingCreateFormProps
> = ({ initialValues, isSaving, mode, onCancel, onSave }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetExternalMappingData());
    };
  }, [dispatch]);

  const {
    data: externalMappingConfig,
    error: externalMappingConfigError,
    isLoading: isLoadingExternalMappingConfig,
  } = useSingleton<ExternalMappingConfig>(DataType.ExternalMappingConfig);

  const { activeStep, goToNext, goToPrevious } = useSteps({ count: 2 });

  const [stepOneChoices, setStepOneChoices] = useState<
    Partial<ExternalMappingCreateFormStepOneValues>
  >({
    ...initialValues,
  });
  const isStepOneComplete =
    !!stepOneChoices.externalSystem &&
    !!stepOneChoices.externalTable &&
    !!stepOneChoices.m3terEntity;

  const { loadUnmappedEntities } = useExternalMappingM3terEntityOptions(
    stepOneChoices?.m3terEntity,
    stepOneChoices?.externalSystem,
    stepOneChoices?.externalTable
  );
  useEffect(() => {
    loadUnmappedEntities();
  }, [loadUnmappedEntities]);

  const onStepOneSubmit = useCallback(
    (newStepOneChoices: ExternalMappingCreateFormStepOneValues) => {
      setStepOneChoices(newStepOneChoices);
      goToNext();
    },
    [goToNext]
  );

  const stepTwoInitialValues = useMemo<
    DeepPartial<ExternalMappingCreateFormStepTwoValues>
  >(
    () => ({
      mappings: initialValues?.mappings,
    }),
    [initialValues]
  );
  const onStepTwoSubmit = useCallback(
    (stepTwoChoices: ExternalMappingCreateFormStepTwoValues) => {
      if (!isStepOneComplete) {
        return;
      }
      onSave({
        ...(stepOneChoices as ExternalMappingCreateFormStepOneValues),
        ...stepTwoChoices,
      });
    },
    [isStepOneComplete, stepOneChoices, onSave]
  );

  return (
    <LoadingErrorContentSwitch
      isLoading={isLoadingExternalMappingConfig}
      error={externalMappingConfigError}
    >
      {!!externalMappingConfig && (
        <React.Fragment>
          <Stepper index={activeStep} mb={6}>
            <Step title={t('features:externalMappings.selectMappingType')} />
            <Step title={t('features:externalMappings.addMappingIds')} />
          </Stepper>
          {activeStep === 0 && (
            <ExternalMappingCreateFormStepOne
              externalMappingConfig={externalMappingConfig}
              initialValues={stepOneChoices}
              isSaving={isSaving}
              onCancel={onCancel}
              onSave={onStepOneSubmit}
            />
          )}
          {activeStep === 1 && (
            <ExternalMappingCreateFormStepTwo
              initialValues={stepTwoInitialValues}
              isSaving={isSaving}
              mode={mode}
              onBack={goToPrevious}
              onCancel={onCancel}
              onSave={onStepTwoSubmit}
              stepOneChoices={
                isStepOneComplete
                  ? (stepOneChoices as ExternalMappingCreateFormStepOneValues)
                  : undefined
              }
            />
          )}
        </React.Fragment>
      )}
    </LoadingErrorContentSwitch>
  );
};
