import React, { PropsWithChildren } from 'react';

import {
  Card,
  CardBody,
  CardHeader,
  CardProps,
  Collapse,
  HStack,
  Heading,
  IconButton,
  StackDivider,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import type { MotionStyle } from 'framer-motion';

interface FormSectionBaseProps extends CardProps {
  heading: string;
  helpText?: string;
  isOptional?: boolean;
  useDivider?: boolean;
}

interface FormCardSectionProps extends FormSectionBaseProps {
  isAccordion?: never;
  defaultIsOpen?: never;
  toggleButtonLabel?: never;
}

interface FormAccordionCardSectionProps extends FormSectionBaseProps {
  isAccordion: true;
  defaultIsOpen?: boolean;
  toggleButtonLabel?: string;
}

export type FormSectionProps =
  | FormCardSectionProps
  | FormAccordionCardSectionProps;

interface FormSectionContentProps {
  useDivider: boolean;
}

const FormSectionContent: React.FC<
  PropsWithChildren<FormSectionContentProps>
> = ({ useDivider, children }) => {
  return (
    <CardBody>
      <VStack
        alignItems="flex-start"
        gap={useDivider ? 2 : 6}
        justifyContent="flex-start"
        divider={
          useDivider ? (
            <StackDivider data-testid="form-section-stack-divider" />
          ) : undefined
        }
      >
        {children}
      </VStack>
    </CardBody>
  );
};

const collapseStyles: MotionStyle = {
  overflow: 'unset',
};

export const FormSection: React.FC<PropsWithChildren<FormSectionProps>> = ({
  heading,
  helpText,
  isOptional = false,
  useDivider = false,
  isAccordion = false,
  defaultIsOpen = true,
  toggleButtonLabel = 'Toggle card collapse',
  children,
  ...cardProps
}) => {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen });

  return (
    <Card w="100%" {...cardProps}>
      <CardHeader borderBottomWidth={isOpen ? 1 : 0}>
        <HStack alignItems="center" justifyContent="space-between">
          <VStack alignItems="flex-start">
            <Heading as="h4" size="md">
              {heading}
              {isOptional && (
                <Text
                  as="span"
                  fontSize="xs"
                  fontWeight="semibold"
                  marginLeft={2}
                  variant="annotation"
                >
                  {t('forms:labels.optional')}
                </Text>
              )}
            </Heading>
            {helpText && (
              <Text
                data-testid="form-section-help-text"
                as="span"
                fontSize="sm"
              >
                {helpText}
              </Text>
            )}
          </VStack>
          {isAccordion && (
            <IconButton
              data-testid="form-section-accordion-toggle-button"
              aria-label={toggleButtonLabel}
              onClick={onToggle}
              icon={isOpen ? <FaCaretUp /> : <FaCaretDown />}
            />
          )}
        </HStack>
      </CardHeader>
      {isAccordion ? (
        <Collapse
          data-testid="form-section-accordion"
          animateOpacity
          in={isOpen}
          style={collapseStyles}
        >
          <FormSectionContent useDivider={useDivider}>
            {children}
          </FormSectionContent>
        </Collapse>
      ) : (
        <FormSectionContent useDivider={useDivider}>
          {children}
        </FormSectionContent>
      )}
    </Card>
  );
};
