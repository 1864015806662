import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DataType,
  DataTypeToEntity,
  ExternalMappingConfig,
} from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';
import { ExternalMappingEntityTypeToDataType } from '@/types/data';

import { createSelectByIds, selectSingleton } from '@/store/data/data';

export interface ExternalMappingsDataState {
  isLoadingUnmappedEntities: boolean;
  unmappedEntities: Partial<
    Record<
      string,
      {
        entityIds: Array<string>;
        error?: AppError;
      }
    >
  >;
}

interface LoadUnmappedEntitiesPayload {
  dataType: DataType;
  externalSystem: string;
  m3terEntityType: keyof ExternalMappingEntityTypeToDataType;
  externalTable: string;
}
export type LoadUnmappedEntitiesAction =
  PayloadAction<LoadUnmappedEntitiesPayload>;

interface LoadUnmappedEntitiesFailurePayload {
  externalSystem: string;
  m3terEntityType: keyof ExternalMappingEntityTypeToDataType;
  externalTable: string;
}
type LoadUnmappedEntitiesFailureAction = PayloadAction<
  LoadUnmappedEntitiesFailurePayload,
  string,
  never,
  AppError
>;

interface LoadUnmappedEntitieSuccessPayload {
  externalSystem: string;
  m3terEntityType: keyof ExternalMappingEntityTypeToDataType;
  externalTable: string;
  unmappedEntityIds: Array<string>;
}
export type LoadUnmappedEntitieSuccessAction =
  PayloadAction<LoadUnmappedEntitieSuccessPayload>;

const createUnmappedEntityStateKey = (
  m3terEntityType: keyof ExternalMappingEntityTypeToDataType,
  externalSystem: string,
  externalTable: string
) => `${m3terEntityType}_${externalSystem}_${externalTable}`;

export const initialState: ExternalMappingsDataState = {
  isLoadingUnmappedEntities: false,
  unmappedEntities: {},
};
const name = 'features/integrations/externalMappings/data';
const externalMappingsDataState = createSlice({
  name,
  initialState,
  reducers: {
    loadUnmappedEntities: {
      reducer: (
        state: ExternalMappingsDataState,
        action: LoadUnmappedEntitiesAction
      ) => {
        const stateKey = createUnmappedEntityStateKey(
          action.payload.m3terEntityType,
          action.payload.externalSystem,
          action.payload.externalTable
        );
        state.isLoadingUnmappedEntities = true;
        state.unmappedEntities[stateKey] = {
          entityIds: [],
        };
      },
      prepare: <E extends keyof ExternalMappingEntityTypeToDataType>(
        m3terEntityType: E,
        dataType: DataType,
        externalSystem: string,
        externalTable: string
      ) => ({
        payload: { dataType, externalSystem, m3terEntityType, externalTable },
      }),
    },
    loadUnmappedEntitiesFailure: {
      reducer: (
        state: ExternalMappingsDataState,
        action: LoadUnmappedEntitiesFailureAction
      ) => {
        const stateKey = createUnmappedEntityStateKey(
          action.payload.m3terEntityType,
          action.payload.externalSystem,
          action.payload.externalTable
        );
        state.unmappedEntities[stateKey] = {
          entityIds: [],
          error: action.error,
        };
        state.isLoadingUnmappedEntities = false;
      },
      prepare: <E extends keyof ExternalMappingEntityTypeToDataType>(
        m3terEntityType: E,
        externalSystem: string,
        externalTable: string,
        error: AppError
      ) => ({
        error,
        payload: { externalSystem, m3terEntityType, externalTable },
      }),
    },
    loadUnmappedEntitiesSuccess: {
      reducer: (
        state: ExternalMappingsDataState,
        action: LoadUnmappedEntitieSuccessAction
      ) => {
        const stateKey = createUnmappedEntityStateKey(
          action.payload.m3terEntityType,
          action.payload.externalSystem,
          action.payload.externalTable
        );
        state.unmappedEntities[stateKey] = {
          entityIds: action.payload.unmappedEntityIds,
        };
        state.isLoadingUnmappedEntities = false;
      },
      prepare: <E extends keyof ExternalMappingEntityTypeToDataType>(
        m3terEntityType: E,
        externalSystem: string,
        externalTable: string,
        unmappedEntityIds: Array<string>
      ) => ({
        payload: {
          externalSystem,
          m3terEntityType,
          externalTable,
          unmappedEntityIds,
        },
      }),
    },
    reset: () => initialState,
  },
});

// Actions
export const {
  loadUnmappedEntitiesFailure,
  loadUnmappedEntitiesSuccess,
  reset,
} = externalMappingsDataState.actions;
interface LoadUnmappedEntitiesActionCreator {
  <E extends keyof ExternalMappingEntityTypeToDataType>(
    m3terEntityType: E,
    dataType: ExternalMappingEntityTypeToDataType[E],
    externalSystem: string,
    externalTable: string
  ): LoadUnmappedEntitiesAction;
  type: string;
}
export const loadUnmappedEntities = externalMappingsDataState.actions
  .loadUnmappedEntities as LoadUnmappedEntitiesActionCreator;

// Selectors
const selectExternalMappingsDataState = (state: {
  features: {
    integrations: { externalMappings: { data: ExternalMappingsDataState } };
  };
}): ExternalMappingsDataState =>
  state.features.integrations.externalMappings.data;

export const selectExternalMappingConfig =
  selectSingleton<ExternalMappingConfig>(DataType.ExternalMappingConfig);

export const selectIsLoadingUnmappedEntities = createSelector(
  selectExternalMappingsDataState,
  (state) => state.isLoadingUnmappedEntities
);

const selectUnmappedEntityIds = (
  m3terEntityType: keyof ExternalMappingEntityTypeToDataType,
  externalSystem: string,
  externalTable: string
) =>
  createSelector(selectExternalMappingsDataState, (state) => {
    const stateKey = createUnmappedEntityStateKey(
      m3terEntityType,
      externalSystem,
      externalTable
    );
    return state.unmappedEntities[stateKey]?.entityIds || [];
  });

export const selectUnmappedEntities = <
  E extends keyof ExternalMappingEntityTypeToDataType
>(
  m3terEntityType: E,
  dataType: ExternalMappingEntityTypeToDataType[E],
  externalSystem: string,
  externalTable: string
) =>
  createSelector(
    selectUnmappedEntityIds(m3terEntityType, externalSystem, externalTable),
    createSelectByIds<DataTypeToEntity[ExternalMappingEntityTypeToDataType[E]]>(
      dataType
    ),
    (unmappedEntityIds, selectByIds) => selectByIds(unmappedEntityIds)
  );

export const selectUnmappedEntitiesError = <
  E extends keyof ExternalMappingEntityTypeToDataType
>(
  m3terEntityType: E,
  externalSystem: string,
  externalTable: string
) =>
  createSelector(selectExternalMappingsDataState, (state) => {
    const stateKey = createUnmappedEntityStateKey(
      m3terEntityType,
      externalSystem,
      externalTable
    );
    return state.unmappedEntities[stateKey]?.error;
  });

export default externalMappingsDataState.reducer;
