import React, { ReactElement, useMemo } from 'react';

import { Box, ButtonGroup, Link, VStack } from '@chakra-ui/react';

import { DataType, DataTypeToEntity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Alert,
  Button,
  Confirmation,
  KeyValueList,
  KeyValueListItem,
} from '@m3ter-com/ui-components';
import { isPricing, isTransactionPricing } from '@m3ter-com/console-core/utils';

import { PricingDataType } from '@/types/data';

import useCurrencies from '@/hooks/util/useCurrencies';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import { PricingSummary } from '@/components/features/pricing/PricingSummary';
import { CrudEditLink } from '@/components/common/navigation/CrudEditLink';
import { usePricingGridContext } from '@/components/features/pricing/grid/PricingGridContext';
import { usePricingGridCellContext } from '@/components/features/pricing/grid/PricingGridCellContext';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import { PricingBandsTable } from '@/components/features/pricing/PricingBandsTable';

export interface PricingGridPricingProps<DT extends PricingDataType> {
  dataType: DT;
  isTemplatePricingInPlanColumn: boolean;
  canOverridePricing: boolean;
  showActions: boolean;
  pricing: DataTypeToEntity[DT];
}

export function PricingGridPricing<DT extends PricingDataType>({
  dataType,
  isTemplatePricingInPlanColumn,
  canOverridePricing,
  pricing,
  showActions,
}: PricingGridPricingProps<DT>): ReactElement<any, any> | null {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();
  const { toLongDateTime } = useDateFormatter();

  const { deletePricing, deleteItemCounterPricing } = usePricingGridContext();
  const {
    currencyCode = '',
    pricingLinkQueryParams,
    planTemplate,
  } = usePricingGridCellContext();

  const overrideLinkQueryParams = useMemo(
    () => ({
      ...pricingLinkQueryParams,
      duplicate: pricing.id,
    }),
    [pricingLinkQueryParams, pricing]
  );

  const isStandardPricing = isPricing(pricing);

  return (
    <VStack
      height="100%"
      alignItems="flex-start"
      justifyContent="flex-start"
      spacing={4}
    >
      {isTemplatePricingInPlanColumn && planTemplate && (
        <Alert status="info" p={2}>
          {/* Extra element needed so text and link don't flex together. */}
          <Box maxW="300px" wordBreak="break-word">
            {t('features:pricing.usingPricingFromTemplate')}{' '}
            {planTemplate && (
              <Link
                as={CrudDetailsLink}
                dataType={DataType.PlanTemplate}
                id={planTemplate.id}
              >
                {planTemplate.name}
              </Link>
            )}
          </Box>
        </Alert>
      )}
      <PricingSummary currencyCode={currencyCode} pricing={pricing} size="sm" />
      <React.Fragment>
        {isStandardPricing && !!pricing.overagePricingBands?.length && (
          <PricingBandsTable
            size="sm"
            pricingBands={pricing.overagePricingBands}
            currencyCode={currencyCode}
            heading={t('features:pricing.prepaymentAndOveragePricing')}
            isTransactional={isTransactionPricing(pricing)}
          />
        )}
        <KeyValueList fontSize="sm">
          {isStandardPricing && (
            <KeyValueListItem
              label={t('forms:labels.minimumSpend')}
              value={formatCurrency(pricing.minimumSpend ?? 0, currencyCode)}
            />
          )}
          <KeyValueListItem
            label={t('forms:labels.start')}
            value={toLongDateTime(pricing.startDate)}
          />
          {pricing.endDate && (
            <KeyValueListItem
              label={t('forms:labels.end')}
              value={toLongDateTime(pricing.endDate)}
            />
          )}
        </KeyValueList>
      </React.Fragment>
      {showActions && (
        <ButtonGroup spacing={2} size="sm">
          {canOverridePricing && (
            <Button
              data-testid="override-pricing"
              as={CrudCreateLink}
              addReturnPath
              dataType={dataType}
              queryParams={overrideLinkQueryParams}
            >
              {t('features:pricing.overrideTemplatePricing')}
            </Button>
          )}
          {!isTemplatePricingInPlanColumn && (
            <React.Fragment>
              <Button
                data-testid="edit-pricing"
                as={CrudEditLink}
                addReturnPath
                dataType={dataType}
                id={pricing.id}
              >
                {t('common:edit')}
              </Button>
              <Confirmation
                trigger={
                  <Button data-testid="delete-pricing">
                    {t('common:delete')}
                  </Button>
                }
                message={t('features:pricing.deleteConfirmation')}
                onConfirm={() => {
                  if (isStandardPricing) {
                    deletePricing(pricing);
                  } else {
                    deleteItemCounterPricing(pricing);
                  }
                }}
              />
              <Button as={CrudDetailsLink} dataType={dataType} id={pricing.id}>
                {t('common:details')}
              </Button>
            </React.Fragment>
          )}
        </ButtonGroup>
      )}
    </VStack>
  );
}
