import en from './en/currencies.json';

export interface Currency {
  code: string;
  name: string;
  symbol: string;
}

export const getAllCurrencies = (): Record<string, Currency> => en;

export const getCurrencyByCode = (code: string): Currency | undefined =>
  (en as Record<string, Currency>)[code];
