import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { InvitationsRoute } from '@/components/routes/settings/invitations/InvitationsRoute';
import { InvitationsListRoute } from '@/components/routes/settings/invitations/InvitationsListRoute';
import { InvitationsDetailsRoute } from '@/components/routes/settings/invitations/InvitationsDetailsRoute';
import { InvitationsCreateRoute } from '@/components/routes/settings/invitations/InvitationsCreateRoute';

export default () =>
  createCrudRoute({
    path: 'invitations',
    dataType: DataType.Invitation,
    element: <InvitationsRoute />,
    list: <InvitationsListRoute />,
    details: <InvitationsDetailsRoute />,
    create: <InvitationsCreateRoute />,
  });
