import React from 'react';

import { StatementDefinition } from '@m3ter-com/m3ter-api';
import { DeepPartial } from '@m3ter-com/console-core/types';

import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { StatementDefinitionForm } from '@/components/features/statements/StatementDefinitionForm';

const initialValues: DeepPartial<StatementDefinition> = {
  measures: [{}],
  dimensions: [],
};

export const StatementDefinitionsCreateRoute: React.FC = () => (
  <CrudCreate<StatementDefinition>
    form={StatementDefinitionForm}
    initialValues={initialValues}
  />
);
