import { lazy, ComponentType } from 'react';

const chunkLoadRecoveryAttemptedKey = 'CHUNK_LOAD_FAILURE_RECOVERY_ATTEMPTED';

// A wrapper util for React.lazy that will attempt to recover if an error is
// encountered when lazy-loading a component chunk.
export const lazyWithRetry = <T extends ComponentType<any>>(
  componentImport: () => Promise<{ default: T }>
) =>
  lazy(async () => {
    const chunkLoadRecoveryAttempted = JSON.parse(
      localStorage.getItem(chunkLoadRecoveryAttemptedKey) || 'false'
    );

    try {
      const component = await componentImport();

      // Chunk has been loaded successfully, reset localStorage and return the
      // component.
      localStorage.setItem(chunkLoadRecoveryAttemptedKey, 'false');
      return component;
    } catch (error) {
      // Chunk could not be loaded.

      if (!chunkLoadRecoveryAttempted) {
        // We have not attempted to recover from this error before, set this
        // in localStorage and do a hard refresh so we can try to load the
        // latest version of the chunk.
        localStorage.setItem(chunkLoadRecoveryAttemptedKey, 'true');
        window.location.reload();
      } else {
        // We have already attempted to recover from this error, no point in
        // retrying endlessly, let the error bubble up.
        throw error;
      }

      // No-op
      // This return statement should never actually be reached and is used purely
      // to satisfy the types for React.lazy
      return {
        default: (() => null) as unknown as T,
      };
    }
  });
