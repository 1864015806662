import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';

import {
  DataType,
  AccountPlan,
  Plan,
  UnsavedEntity,
} from '@m3ter-com/m3ter-api';

import { extractError } from '@/util/error';
import { createData, deleteData } from '@/store/data/data.saga';
import { AccountCustomPlanFormValues } from '@/components/features/accounts/AccountCustomPlanForm';

import {
  createCustomPlan,
  createCustomPlanSuccess,
  createCustomPlanFailure,
  CreateCustomPlanAction,
} from './customPlanForm';

export function* createPlanAndAccountPlan(
  formValues: AccountCustomPlanFormValues
): Generator<StrictEffect, AccountPlan, any> {
  const plan: Plan = yield call(createData, DataType.Plan, formValues);

  try {
    // Create the account plan referencing the newly-created plan.
    const data: UnsavedEntity<AccountPlan> = {
      planId: plan.id,
      accountId: formValues.accountId,
      startDate: formValues.startDate,
      endDate: formValues.endDate,
      contractId: formValues.contractId,
      childBillingMode: formValues.childBillingMode,
      billEpoch: formValues.billEpoch,
    };

    const accountPlan = yield call(createData, DataType.AccountPlan, data);
    return accountPlan;
  } catch (error) {
    // Delete the plan so it's not left hanging.
    yield call(deleteData, DataType.Plan, plan.id);

    throw error;
  }
}

export function* createCustomPlanSaga(
  action: CreateCustomPlanAction
): Generator<StrictEffect, void, any> {
  const { formValues } = action.payload;
  const { onSuccess, onFailure } = action.meta;

  try {
    const accountPlan: AccountPlan = yield call(
      createPlanAndAccountPlan,
      formValues
    );
    yield put(createCustomPlanSuccess(accountPlan, onSuccess));
  } catch (error) {
    yield put(createCustomPlanFailure(extractError(error), onFailure));
  }
}

export default function* customPlanFormSaga() {
  yield takeEvery(createCustomPlan.type, createCustomPlanSaga);
}
