import { useCallback, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  DataType,
  Entity,
  Id,
  UnsavedEntity,
  PathParams,
} from '@m3ter-com/m3ter-api';

import {
  selectCreateState,
  createItem as createItemAction,
  resetCreate,
} from '@/store/crud';
import { dataTypeRetrieveQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useAppQuery from '@/hooks/data/useAppQuery';

const useEntityCreate = <E extends Entity = Entity>(
  dataType: DataType,
  returnPath?: string,
  pathParams?: PathParams,
  duplicateId?: Id
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetCreate(dataType));
    };
  }, [dataType, dispatch]);

  const orgPathParams = useOrgPathParams();

  const { isLoading: isDuplicateLoading, data: duplicate } = useAppQuery(
    dataTypeRetrieveQuery(
      {
        dataType,
        id: duplicateId,
        pathParams: orgPathParams,
      },
      { enabled: !!duplicateId }
    )
  );

  // Memoize the selector otherwise it will be new function per render.
  const createStateSelector = useMemo(
    () => selectCreateState(dataType),
    [dataType]
  );
  const createState = useSelector(createStateSelector);

  const createItem = useCallback(
    (item: UnsavedEntity<E>) => {
      dispatch(
        createItemAction(
          dataType,
          item,
          undefined,
          undefined,
          returnPath,
          pathParams
        )
      );
    },
    [dispatch, dataType, returnPath, pathParams]
  );

  return {
    createItem,
    duplicate: duplicate as E,
    error: createState?.error,
    isLoading: duplicateId ? isDuplicateLoading : false,
    isSaving: !!createState?.isSaving,
  };
};

export default useEntityCreate;
