import React from 'react';

import { IntegrationConfig } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Form, FormActions } from '@m3ter-com/console-core/components';
import { FormStack } from '@m3ter-com/ui-components';

import { BaseFormProps } from '@/types/forms';

import validationSchema from '@/validation/integrationConfig';

import { IntegrationConfigFormConfigureFields } from './IntegrationConfigFormConfigureFields';

interface IntegrationConfigEditFormProps
  extends BaseFormProps<IntegrationConfig> {
  initialValues: IntegrationConfig;
}

export const IntegrationConfigEditForm: React.FC<
  IntegrationConfigEditFormProps
> = ({ isSaving, initialValues, onCancel, onSave }) => {
  const { t } = useTranslation();

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <FormStack>
        <IntegrationConfigFormConfigureFields
          destination={initialValues.destination}
          entityType={initialValues.entityType}
        />
        <FormActions
          cancelText={t('common:cancel')}
          submitText={t('forms:buttons.updateEntity', {
            entityName: t('common:integration'),
          })}
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
