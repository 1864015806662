import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { OrganizationAdminPermissionsModalFormValues } from '@/components/features/admin/organizations/OrganizationAdminPermissionsModal';

const suite = create(
  'organizationAdminPermissions',
  (data: Partial<OrganizationAdminPermissionsModalFormValues>) => {
    test('userId', i18next.t('forms:validations.admin.userRequired'), () => {
      enforce(data.userId).isNotEmpty();
    });

    test(
      'permissionPolicyIds',
      i18next.t('forms:validations.admin.permissionPolicyRequired'),
      () => {
        enforce(data.permissionPolicyIds).isNotEmpty();
      }
    );
  }
);

export default suite;
