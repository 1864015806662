import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const useQueryString = <
  Params extends { [K in keyof Params]?: string } = {}
>() => {
  const location = useLocation();

  return queryString.parse(location.search) as Params;
};

export default useQueryString;
