import React from 'react';

import { Navigate } from 'react-router-dom';

import { NamedRoute } from '@/types/routes';

import { AccessSettingsRoute } from '@/components/routes/settings/AccessSettingsRoute';

import setupInvitationsRoute from './invitations';
import setupPermissionPoliciesRoute from './permission-policies';
import setupServiceUsersRoute from './service-users';
import setupSupportAccessRoute from './support-access';
import setupUserGroupsRoute from './user-groups';
import setupUsersRoute from './users';

export default (): NamedRoute => ({
  path: 'access',
  name: 'settings.access',
  element: <AccessSettingsRoute />,
  children: [
    { index: true, element: <Navigate replace to="users" /> },
    setupUsersRoute(),
    setupInvitationsRoute(),
    setupServiceUsersRoute(),
    setupSupportAccessRoute(),
    setupPermissionPoliciesRoute(),
    setupUserGroupsRoute(),
  ],
});
