import { Entity, UnsavedEntity, PathParams } from '@m3ter-com/m3ter-api';

import { CrudQueryParams } from '@/types/routes';

import { useCrudContext } from '@/components/common/crud/CrudContext';
import useQueryString from '@/hooks/navigation/useQueryString';
import useEntityCreate from '@/hooks/data/crud/useEntityCreate';

export type DuplicateEntityMerger<E extends Entity> = (
  entity: UnsavedEntity<E>
) => Partial<E>;

const useCrudCreate = <E extends Entity = Entity>(pathParams?: PathParams) => {
  const { basePath, dataType, detailsRouteName, onCancel } =
    useCrudContext<E>();
  const { duplicate: duplicateId, returnPath: queryParamsReturnPath } =
    useQueryString<CrudQueryParams>();

  const returnPath =
    queryParamsReturnPath || (detailsRouteName ? `${basePath}/:id` : basePath);

  const { createItem, duplicate, error, isLoading, isSaving } =
    useEntityCreate<E>(dataType, returnPath, pathParams, duplicateId);

  return {
    createItem,
    dataType,
    duplicate,
    error,
    isLoading,
    isSaving,
    onCancel,
  };
};

export default useCrudCreate;
