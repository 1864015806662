import React, { useCallback, useMemo } from 'react';

import {
  Badge,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';

import { DataType, Alert, AlertStatus } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormSelect,
} from '@m3ter-com/console-core/components';

import useEntityUpdate from '@/hooks/data/crud/useEntityUpdate';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import { SeverityBadge } from '@/components/common/data/SeverityBadge';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';

interface AlertDetailsModalProps {
  alert: Alert;
  isOpen: boolean;
  onClose: () => void;
}

export const AlertDetailsModal: React.FC<AlertDetailsModalProps> = ({
  alert,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { toLongDateTime } = useDateFormatter();

  const { isSaving, loadingError, savingError, updateItem } =
    useEntityUpdate<Alert>(DataType.Alert, alert.id, undefined);

  // The API only expects the status property as it is the only property of an alert that can be updated.
  const onSubmit = useCallback(
    (values: Partial<Alert>) => {
      const { status } = values;
      updateItem({ status });
    },
    [updateItem]
  );

  const statusOptions = useMemo(
    () =>
      Object.values(AlertStatus).map((value) => ({
        label: t(`features:alerts.statuses.${value}`),
        value,
      })),
    [t]
  );

  const statusTranslation = t('forms:labels.status');

  const entityName = t('common:alerts');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <Form onSubmit={onSubmit} initialValues={alert}>
          <ModalHeader>{t('features:alerts.alertDetails')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack alignItems="stretch" spacing={4} mb={8}>
              {!!(loadingError || savingError) && (
                <ErrorAlert
                  title={t(
                    `errors:generic.${
                      loadingError ? 'problemLoadingData' : 'problemSavingData'
                    }`,
                    { entityName }
                  )}
                  error={(loadingError || savingError)!}
                />
              )}
              <SimpleGrid columns={2} spacing={4}>
                <KeyValue
                  label={t('forms:labels.status')}
                  value={
                    <Badge
                      whiteSpace="nowrap"
                      colorScheme={
                        alert.status === AlertStatus.New ? 'green' : undefined
                      }
                    >
                      {t(`features:alerts.statuses.${alert.status}`)}
                    </Badge>
                  }
                />
                <KeyValue
                  label={t('common:createdDate')}
                  value={toLongDateTime(alert.createdDate)}
                />
                <KeyValue
                  label={t('forms:labels.severity')}
                  value={<SeverityBadge severity={alert.severity} />}
                />
                <KeyValue
                  label={t('common:lastUpdatedDate')}
                  value={toLongDateTime(alert.lastUpdatedDate)}
                />
                <KeyValue
                  label={t('forms:labels.type')}
                  value={<Badge>{alert.type}</Badge>}
                />
              </SimpleGrid>
              <KeyValue
                label={t('forms:labels.description')}
                value={alert.description}
              />
              <KeyValue
                label={t('forms:labels.details')}
                value={alert.details.join(', ')}
              />
            </VStack>
            <FormField
              isRequired
              name="status"
              label={statusTranslation}
              control={FormSelect}
              options={statusOptions}
            />
          </ModalBody>
          <ModalFooter>
            <FormActions
              cancelText={t('common:close')}
              submitText={t('forms:buttons.updateEntity', {
                entityName: statusTranslation,
              })}
              isSaving={isSaving}
              onCancel={onClose}
            />
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
};
