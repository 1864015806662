import React, { useCallback, useMemo } from 'react';

import { FormLabel, SimpleGrid, Switch, VStack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import { AutoApprovePeriodUnit } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormInput, FormSelect } from '@m3ter-com/console-core/components';

export interface AutoApproveBillsFieldsProps {
  name: string;
}

export const AutoApproveBillsFields: React.FC<AutoApproveBillsFieldsProps> = ({
  name,
}) => {
  const { t } = useTranslation();

  const unitFieldName = `${name}Unit`;

  const unitOptions = useMemo(
    () =>
      Object.values(AutoApprovePeriodUnit).map((unit) => ({
        value: unit,
        label: t(`features:billing.autoApprovePeriodUnits.${unit}`),
      })),
    [t]
  );

  const autoApproveBillsGracePeriod = useWatch({ name });

  const { setValue } = useFormContext();

  const isEnabled =
    autoApproveBillsGracePeriod !== undefined &&
    autoApproveBillsGracePeriod !== null;

  const oneAutoApprovalChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setValue(name, 24);
        setValue(unitFieldName, AutoApprovePeriodUnit.Hours);
      } else {
        setValue(name, null, { shouldValidate: true });
        setValue(unitFieldName, null, { shouldValidate: true });
      }
    },
    [setValue, name, unitFieldName]
  );

  return (
    <VStack width="100%" alignItems="stretch">
      <Switch
        isChecked={isEnabled}
        onChange={oneAutoApprovalChange}
        alignSelf="start"
      />
      <FormLabel>{t('forms:labels.billAutoApprovalGradePeriod')}</FormLabel>
      <SimpleGrid columns={2} spacing={2}>
        <FormInput name={name} type="number" isDisabled={!isEnabled} />
        <FormSelect
          name={unitFieldName}
          options={unitOptions}
          isDisabled={!isEnabled}
        />
      </SimpleGrid>
    </VStack>
  );
};
