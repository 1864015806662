import {
  Aggregation,
  CompoundAggregation,
  SegmentedAggregation,
  SegmentedCompoundAggregation,
} from '@m3ter-com/m3ter-api';

export const isAggregationSegmented = (
  aggregation: Aggregation
): aggregation is SegmentedAggregation =>
  !!aggregation.segmentedFields?.length && !!aggregation.segments?.length;

export const isCompoundAggregationSegmented = (
  compoundAggregation: CompoundAggregation
): compoundAggregation is SegmentedCompoundAggregation =>
  !!compoundAggregation.segments?.length;
