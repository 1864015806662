import React, { useCallback } from 'react';

import { HStack } from '@chakra-ui/react';

import { MeasurementsDeletion } from '@m3ter-com/m3ter-api';
import { FormStack } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Form,
  FormActions,
  FormField,
  FormInput,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import { FormDatePicker } from '@/components/forms/FormDatePicker';
import measurementsDeletionSchema from '@/validation/measurementsDeletion';

export interface MeasurementsDeletionFormProps
  extends BaseFormProps<MeasurementsDeletion> {}

const defaultInitialValues: Partial<MeasurementsDeletion> = {};

export const MeasurementsDeletionForm: React.FC<
  MeasurementsDeletionFormProps
> = ({
  onSave,
  onCancel,
  isSaving = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();
  const entityName = t('common:measurementsDeletion');

  const onSubmit = useCallback(
    (data: MeasurementsDeletion) => {
      onSave({
        ...data,
        // ID fields are optional but shouldn't be sent as empty strings.
        accountId:
          data.accountId && data.accountId !== '' ? data.accountId : undefined,
        meterId: data.meterId && data.meterId !== '' ? data.meterId : undefined,
      });
    },
    [onSave]
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={measurementsDeletionSchema}
    >
      <FormStack>
        <FormSection heading={t('common:entityDetails', { entityName })}>
          <FormField
            isRequired
            name="orgName"
            label={t('features:organizations.name')}
            control={FormInput}
          />
          <HStack spacing={4}>
            <FormField
              isRequired
              name="tsStart"
              label={t('forms:labels.startDate')}
              control={FormDatePicker}
              showTimeSelect
            />
            <FormField
              isRequired
              name="tsEnd"
              label={t('forms:labels.endDate')}
              control={FormDatePicker}
              showTimeSelect
            />
          </HStack>
          <FormField
            name="accountId"
            label={`${t('common:account')} (${t('common:id')})`}
            control={FormInput}
          />
          <FormField
            name="meterId"
            label={`${t('common:meter')} (${t('common:id')})`}
            control={FormInput}
          />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={t('forms:buttons.createEntity', { entityName })}
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
