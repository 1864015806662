import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
  PrepareAction,
} from '@reduxjs/toolkit';

import { DataType, Id, StatementJob } from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

import { createSelectByIds } from '@/store/data/data';

export interface StatementJobsState {
  runningStatementJobIds: Array<Id>;
  generateStatementsError?: AppError;
}

interface SetRunningStatementJobsPayload {
  ids: Array<Id>;
}
export type SetRunningStatementJobsAction =
  PayloadAction<SetRunningStatementJobsPayload>;

interface GenerateStatementsPayload {
  billId: Id;
  generateCsvStatement: boolean;
}
export type GenerateStatementsAction = PayloadAction<GenerateStatementsPayload>;

interface StatementJobPayload {
  statementJob: StatementJob;
}

export type GenerateStatementsSuccessAction =
  PayloadAction<StatementJobPayload>;

export type GenerateStatementsFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

export type StatementJobCompleteAction = PayloadAction<StatementJobPayload>;

const name = `features/billing/statementJobs`;

export const initialState: StatementJobsState = {
  runningStatementJobIds: [],
};

const statementJobPrepare = (statementJob: StatementJob) => ({
  payload: { statementJob },
});

const statementJobsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setRunningStatementJobs: {
      reducer: (
        state: StatementJobsState,
        action: SetRunningStatementJobsAction
      ) => {
        state.runningStatementJobIds = action.payload.ids;
      },
      prepare: (ids: Array<Id>) => ({
        payload: { ids },
      }),
    },
    generateStatements: {
      reducer: (
        state: StatementJobsState,
        _action: GenerateStatementsAction
      ) => {
        state.generateStatementsError = undefined;
      },
      prepare: (billId: Id, generateCsvStatement: boolean) => ({
        payload: { billId, generateCsvStatement },
      }),
    },
    generateStatementsSuccess: {
      reducer: (
        state: StatementJobsState,
        action: GenerateStatementsSuccessAction
      ) => {
        state.runningStatementJobIds.push(action.payload.statementJob.id);
      },
      prepare: statementJobPrepare,
    },
    generateStatementsFailure: {
      reducer: (
        state: StatementJobsState,
        action: GenerateStatementsFailureAction
      ) => {
        state.generateStatementsError = action.error;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
  },
});

// Actions

export const {
  setRunningStatementJobs,
  generateStatements,
  generateStatementsSuccess,
  generateStatementsFailure,
} = statementJobsSlice.actions;

export const statementJobComplete = createAction<
  PrepareAction<StatementJobPayload>
>(`${name}/statementJobComplete`, statementJobPrepare);

// Selectors

const selectStatementJobsState = (state: {
  features: { billing: { statementJobs: StatementJobsState } };
}) => state.features.billing.statementJobs;

export const selectRunningStatementJobIds = createSelector(
  selectStatementJobsState,
  (state) => state.runningStatementJobIds
);

export const selectRunningStatementJobs = createSelector(
  selectRunningStatementJobIds,
  createSelectByIds<StatementJob>(DataType.StatementJob),
  (statementJobIds, selectByIds) => selectByIds(statementJobIds)
);

export const selectGenerateStatementsError = createSelector(
  selectStatementJobsState,
  (state) => state.generateStatementsError
);

export default statementJobsSlice.reducer;
