import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { BalancesRoute } from '@/components/routes/accounts/BalancesRoute';
import { BalancesListRoute } from '@/components/routes/accounts/BalancesListRoute';
import { BalancesCreateRoute } from '@/components/routes/accounts/BalancesCreateRoute';
import { BalancesEditRoute } from '@/components/routes/accounts/BalancesEditRoute';
import { BalancesDetailsRoute } from '@/components/routes/accounts/BalancesDetailsRoute';

import setupBalanceTransactionsRoute from './balance-transactions';

export default () =>
  createCrudRoute({
    path: 'balances',
    dataType: DataType.Balance,
    element: <BalancesRoute />,
    list: <BalancesListRoute />,
    create: <BalancesCreateRoute />,
    edit: <BalancesEditRoute />,
    details: <BalancesDetailsRoute />,
    entityChildRoutes: [setupBalanceTransactionsRoute()],
  });
