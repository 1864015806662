import {
  Accessor,
  ColumnOption,
  ColumnOptionWithVisibility,
  ColumnDisplay,
} from './types';

export const getAccessorValue = <D, ReturnType = any>(
  data: D,
  accessor: Accessor<D, ReturnType>
): ReturnType => {
  return typeof accessor === 'function'
    ? accessor(data)
    : (data[accessor] as ReturnType);
};

export const getSortedColumnOptionsWithVisibility = (
  columnOptions: Array<ColumnOption>,
  columnDisplay: Array<ColumnDisplay>
): Array<ColumnOptionWithVisibility> => {
  return columnDisplay
    .map(({ id, visible }) => {
      const column = columnOptions.find((option) => option.id === id);
      return column ? { ...column, visible } : undefined;
    })
    .filter(Boolean) as Array<ColumnOptionWithVisibility>;
};
