import {
  createAction,
  createSelector,
  createSlice,
  isAnyOf,
  PayloadAction,
  PrepareAction,
} from '@reduxjs/toolkit';
import memoize from 'memoize-one';

import { DataType, Entity, Id, PathParams } from '@m3ter-com/m3ter-api';

import dataAdapter, { DataAdapterState } from './data.adapter';

export interface DataState {
  dataTypes: Partial<Record<DataType, DataAdapterState>>;
  singletons: Partial<Record<DataType, Entity>>;
}

const name = 'data';

const initialState: DataState = {
  dataTypes: {},
  singletons: {},
};

interface DataActionPayload {
  dataType: DataType;
  data: Array<Entity>;
}

interface DataActionMeta {
  pathParams?: PathParams;
}

interface SingletonActionPayload {
  dataType: DataType;
  data: Entity;
}

export type DataLoadedAction = PayloadAction<
  DataActionPayload,
  string,
  DataActionMeta
>;
export type DataCreatedAction = PayloadAction<
  DataActionPayload,
  string,
  DataActionMeta
>;
export type DataUpdatedAction = PayloadAction<
  DataActionPayload,
  string,
  DataActionMeta
>;

export type SingletonLoadedAction = PayloadAction<SingletonActionPayload>;
export type SingletonUpdatedAction = PayloadAction<SingletonActionPayload>;

interface CancelListAllPayload {
  cancelId: string;
}
export type CancelListAllAction = PayloadAction<CancelListAllPayload>;

interface DataDeletedPayload {
  dataType: DataType;
  ids: Array<string>;
}

export type DataDeletedAction = PayloadAction<
  DataDeletedPayload,
  string,
  DataActionMeta
>;

interface ResetExcludingPayload {
  excludedDataTypes: Array<DataType>;
}

export type ResetExcludingAction = PayloadAction<ResetExcludingPayload>;

const getStateForDataType = (
  state: DataState,
  dataType: DataType
): DataAdapterState => {
  if (!state.dataTypes[dataType]) {
    state.dataTypes[dataType] = dataAdapter.getInitialState();
  }
  return state.dataTypes[dataType] as DataAdapterState;
};

// There is a bug in RTK/Immer that means we can't create the initial state
// and use it in entity adapter methods in the same reducer, without setting
// the return value again. These functions encapsulate that get/set.
// See https://github.com/reduxjs/redux-toolkit/issues/878

const upsertManyForDataType = (
  state: DataState,
  dataType: DataType,
  data: Array<Entity>
) => {
  const dataTypeState = getStateForDataType(state, dataType);
  state.dataTypes[dataType] = dataAdapter.setMany(dataTypeState, data);
};

const removeManyForDataType = (
  state: DataState,
  dataType: DataType,
  ids: Array<string>
) => {
  const dataTypeState = getStateForDataType(state, dataType);
  state.dataTypes[dataType] = dataAdapter.removeMany(dataTypeState, ids);
};

const dataActionPrepare = (
  dataType: DataType,
  data: Array<Entity>,
  pathParams?: PathParams
) => ({
  payload: { dataType, data },
  meta: { pathParams },
});

export const dataLoaded = createAction(`${name}/dataLoaded`, dataActionPrepare);
export const dataCreated = createAction(
  `${name}/dataCreated`,
  dataActionPrepare
);
export const dataUpdated = createAction(
  `${name}/dataUpdated`,
  dataActionPrepare
);

const singletonActionPrepare = (dataType: DataType, data: Entity) => ({
  payload: { dataType, data },
});

export const singletonLoaded = createAction<
  PrepareAction<SingletonActionPayload>
>(`${name}/singletonLoaded`, singletonActionPrepare);
export const singletonUpdated = createAction<
  PrepareAction<SingletonActionPayload>
>(`${name}/singletonUpdated`, singletonActionPrepare);

const dataSlice = createSlice({
  name,
  initialState,
  reducers: {
    dataDeleted: {
      reducer: (state: DataState, action: DataDeletedAction) => {
        const { dataType, ids } = action.payload;
        removeManyForDataType(state, dataType, ids);
      },
      prepare: (
        dataType: DataType,
        ids: Array<string>,
        pathParams?: PathParams
      ) => ({
        payload: { dataType, ids },
        meta: { pathParams },
      }),
    },
    resetExcluding: {
      reducer: (state: DataState, action: ResetExcludingAction) => {
        const { excludedDataTypes } = action.payload;
        Object.keys(state.dataTypes).forEach((dataType) => {
          if (!excludedDataTypes.includes(dataType as DataType))
            delete state.dataTypes[dataType as DataType];
        });
        Object.keys(state.singletons).forEach((dataType) => {
          if (!excludedDataTypes.includes(dataType as DataType)) {
            delete state.singletons[dataType as DataType];
          }
        });
      },
      prepare: (excludedDataTypes: Array<DataType> = []) => ({
        payload: { excludedDataTypes },
      }),
    },
  },
  extraReducers: (builder) => {
    // The loaded, created and updated actions are all handled the same way to upsert
    // the data into the state for the data type. They are separate actions so that
    // consumers know which CRUD action occurred.
    builder.addMatcher(
      isAnyOf(dataLoaded, dataCreated, dataUpdated),
      (state: DataState, action: PayloadAction<DataActionPayload>) => {
        const { dataType, data } = action.payload;
        upsertManyForDataType(state, dataType, data);
      }
    );
    // Singleton actions are handled the same way to set the current value of the
    // singleton. They are separate actions so consumers know which action occured.
    builder.addMatcher(
      isAnyOf(singletonLoaded, singletonUpdated),
      (state: DataState, action: PayloadAction<SingletonActionPayload>) => {
        const { dataType, data } = action.payload;
        state.singletons[dataType] = data;
      }
    );
  },
});

// Export actions.
export const { dataDeleted, resetExcluding } = dataSlice.actions;

// Selectors

const selectDataState = (state: { [name]: DataState }): DataState =>
  state[name];

const entityAdapterSelectors = dataAdapter.getSelectors();

const selectDataByDataType = (dataType: DataType) =>
  createSelector(selectDataState, (dataState) => dataState.dataTypes[dataType]);

export const selectAllByDataType = <T extends Entity = Entity>(
  dataType: DataType
) =>
  createSelector(
    selectDataByDataType(dataType),
    (dataTypeState) =>
      (dataTypeState
        ? entityAdapterSelectors.selectAll(dataTypeState)
        : []) as Array<T>
  );

// Create selectors that return memoized functions to get entities of the specified
// data type by ID(s). These can be used in other selectors to return data based
// on specific stored ID(s).
// See https://github.com/reduxjs/reselect#q-how-do-i-create-a-selector-that-takes-an-argument

export const createSelectByDataTypeAndIds = createSelector(
  selectDataState,
  (dataState) =>
    memoize((dataType: DataType, ids: Array<Id> | undefined): Array<Entity> => {
      const dataTypeState = dataState.dataTypes[dataType];
      return dataTypeState && ids
        ? (ids
            .map((id) => entityAdapterSelectors.selectById(dataTypeState, id))
            .filter(Boolean) as Array<Entity>)
        : [];
    })
);

export const createSelectByDataTypeAndId = createSelector(
  selectDataState,
  (dataState) =>
    memoize((dataType: DataType, id: Id | undefined): Entity | undefined => {
      const dataTypeState = dataState.dataTypes[dataType];
      return dataTypeState && id
        ? entityAdapterSelectors.selectById(dataTypeState, id)
        : undefined;
    })
);

export const createSelectByIds = <T extends Entity = Entity>(
  dataType: DataType
) =>
  createSelector(selectDataByDataType(dataType), (dataTypeState) =>
    memoize((ids: Array<string> | undefined) => {
      return dataTypeState && ids
        ? ids
            .map(
              (id) => entityAdapterSelectors.selectById(dataTypeState, id) as T
            )
            .filter(Boolean)
        : [];
    })
  );

export const createSelectById = <T extends Entity = Entity>(
  dataType: DataType
) =>
  createSelector(selectDataByDataType(dataType), (dataTypeState) =>
    memoize((id: string | undefined) => {
      return dataTypeState && id
        ? (entityAdapterSelectors.selectById(dataTypeState, id) as T)
        : undefined;
    })
  );

export const selectById = <T extends Entity = Entity>(
  dataType: DataType,
  id: string | undefined
) =>
  createSelector(selectDataByDataType(dataType), (dataTypeState) =>
    dataTypeState && id
      ? (entityAdapterSelectors.selectById(dataTypeState, id) as T)
      : undefined
  );

export const selectByIds = <T extends Entity = Entity>(
  dataType: DataType,
  ids: Array<string>
) =>
  createSelector(createSelectByIds<T>(dataType), (selector) => selector(ids));

export const selectSingleton = <T extends Entity = Entity>(
  dataType: DataType
) =>
  createSelector(
    selectDataState,
    (dataState) => dataState.singletons[dataType] as T | undefined
  );

// Default export is the reducer itself.
export default dataSlice.reducer;
