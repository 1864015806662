import { combineReducers } from '@reduxjs/toolkit';

import accountContractsReducer from './accountContracts';
import accountPlansReducer from './accountPlans';
import customPlanFormReducer from './customPlanForm';
import customPlanGroupFormReducer from './customPlanGroupForm';
import endBillingEntitiesReducer from './endBillingEntities';

const accountsReducer = combineReducers({
  accountContracts: accountContractsReducer,
  accountPlans: accountPlansReducer,
  customPlanForm: customPlanFormReducer,
  customPlanGroupForm: customPlanGroupFormReducer,
  endBillingEntities: endBillingEntitiesReducer,
});

export default accountsReducer;
