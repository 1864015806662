import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const parts = ['container', 'value'];

const baseStyles = {
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '5px',
};

const changeIndicator: ComponentMultiStyleConfig = {
  parts,
  baseStyle: {
    container: {
      ...baseStyles,
    },
    value: {
      whiteSpace: 'nowrap',
    },
  },
  variants: {
    neutral: {
      container: {
        ...baseStyles,
      },
    },
    good: {
      container: {
        ...baseStyles,
        color: 'green-adaptive',
      },
    },
    bad: {
      container: {
        ...baseStyles,
        color: 'red-adaptive',
      },
    },
  },
  defaultProps: {
    variant: 'neutral',
  },
};

export default changeIndicator;
