import React, { useCallback, useMemo } from 'react';

import {
  Badge,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  Heading,
  Spinner,
  Text,
} from '@chakra-ui/react';

import { ServiceUser, ServiceUserCredential } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Button,
  DataTable,
  DataTableColumnDefinition,
} from '@m3ter-com/ui-components';

import useServiceUserCredentials from '@/hooks/features/access/useServiceUserCredentials';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';

import { ServiceUserCredentialModal } from './ServiceUserCredentialsModal';

interface ServiceUserCredentialsProps {
  data: ServiceUser;
}

export const ServiceUserCredentialList: React.FC<
  ServiceUserCredentialsProps
> = ({ data }) => {
  const { t } = useTranslation();
  const {
    createCredential,
    clearCredential,
    onRemoveServiceUserCredential,
    onMakeServiceUserCredentialInactive,
    onMakeServiceUserCredentialActive,
    reloadServiceUserCredentials,
    newCredential,
    serviceUserCredentials,
    serviceUserCredentialsLoading,
    serviceUserCredentialsError,
  } = useServiceUserCredentials(data.id);

  const onActionStatusButtonClick = useCallback(
    (row: ServiceUserCredential) =>
      row.inactive
        ? onMakeServiceUserCredentialActive(row.apiKey)
        : onMakeServiceUserCredentialInactive(row.apiKey),
    [onMakeServiceUserCredentialActive, onMakeServiceUserCredentialInactive]
  );

  const columns = useMemo<
    Array<DataTableColumnDefinition<ServiceUserCredential>>
  >(
    () => [
      {
        id: 'accessKeyId',
        header: t('features:users.serviceUsers.accessKeyId'),
        accessor: (row: ServiceUserCredential) => row.apiKey,
      },
      {
        id: 'status',
        header: t('features:users.serviceUsers.status'),
        accessor: (row: ServiceUserCredential) =>
          row.inactive ? (
            <Badge variant="outline">{t('common:inactive')}</Badge>
          ) : (
            <Badge colorScheme="green">{t('common:active')}</Badge>
          ),
      },
      {
        id: 'actions',
        header: '',
        accessor: (row: ServiceUserCredential) => (
          <ButtonGroup>
            <Button
              size="sm"
              onClick={() => onActionStatusButtonClick(row)}
              isLoading={serviceUserCredentialsLoading}
              spinnerPlacement="end"
            >
              {row.inactive
                ? t('features:users.serviceUsers.activate')
                : t('features:users.serviceUsers.deactivate')}
            </Button>
            <Button
              size="sm"
              onClick={() => onRemoveServiceUserCredential(row.apiKey)}
              isLoading={serviceUserCredentialsLoading}
              spinnerPlacement="end"
              isDisabled={row.inactive === false}
            >
              {t('common:remove')}
            </Button>
          </ButtonGroup>
        ),
        align: 'right',
      },
    ],
    [
      t,
      onActionStatusButtonClick,
      onRemoveServiceUserCredential,
      serviceUserCredentialsLoading,
    ]
  );

  const onGenerateAccessKeyClick = useCallback(() => {
    createCredential();
  }, [createCredential]);

  const onGenerateAccessKeyClose = useCallback(() => {
    reloadServiceUserCredentials();
    clearCredential();
  }, [reloadServiceUserCredentials, clearCredential]);

  return (
    <Card>
      <CardHeader>
        <Flex justify="space-between" alignItems="center">
          <Heading size="md">
            {t('features:users.serviceUsers.accessKeys')}
          </Heading>
          <Button size="sm" onClick={onGenerateAccessKeyClick}>
            {t('features:users.serviceUsers.generateAccessKey')}
          </Button>
        </Flex>
      </CardHeader>
      <CardBody>
        {serviceUserCredentialsLoading ? (
          <Spinner />
        ) : (
          <React.Fragment>
            {serviceUserCredentialsError && (
              <ErrorAlert error={serviceUserCredentialsError} />
            )}
            {newCredential && (
              <ServiceUserCredentialModal
                isOpen
                credential={newCredential}
                onClose={onGenerateAccessKeyClose}
              />
            )}
            {!!serviceUserCredentials && (
              <DataTable<ServiceUserCredential>
                items={serviceUserCredentials}
                columnDefinitions={columns}
                idAccessor="apiKey"
                isLoading={!data}
                emptyContent={
                  <HStack justifyContent="center" alignItems="center">
                    <Text>
                      {t('errors:generic.noDataToDisplay', {
                        entityName: t('features:users.serviceUsers.accessKeys'),
                      })}
                    </Text>
                  </HStack>
                }
              />
            )}
          </React.Fragment>
        )}
      </CardBody>
    </Card>
  );
};
