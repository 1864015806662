import React from 'react';

import { Customer } from '@m3ter-com/m3ter-api';
import { FormStack } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Form,
  FormActions,
  FormField,
  FormInput,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import customerSchema from '@/validation/customer';

export interface CustomerFormProps extends BaseFormProps<Customer> {}

const defaultInitialValues: Partial<Customer> = {};

export const CustomerForm: React.FC<CustomerFormProps> = ({
  onSave,
  onCancel,
  isEdit = false,
  isSaving = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();
  const entityName = t('common:customer');

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={customerSchema}
    >
      <FormStack>
        <FormSection
          heading={t('common:entityDetails', { entityName })}
          helpText={t('forms:helpText.organizationAdminCustomer')}
        >
          <FormField
            isRequired
            name="name"
            label={t('forms:labels.name')}
            control={FormInput}
          />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={
            !isEdit
              ? t('forms:buttons.createEntity', { entityName })
              : t('forms:buttons.updateEntity', { entityName })
          }
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
