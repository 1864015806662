import React, {
  createContext,
  useContext,
  useMemo,
  Context,
  PropsWithChildren,
} from 'react';

import { DataType, CounterPricing, Pricing } from '@m3ter-com/m3ter-api';

import useEntityDelete from '@/hooks/data/crud/useEntityDelete';

export interface PricingGridContextValues {
  canEditPricing: boolean;
  canEditTemplatePricing: boolean;
  deletePricing: (pricing: Pricing) => void;
  deleteItemCounterPricing: (itemCounterPricing: CounterPricing) => void;
}

const PricingGridContext = createContext<PricingGridContextValues | null>(null);

export type PricingGridContextProviderProps = Omit<
  PricingGridContextValues,
  'deletePricing' | 'deleteItemCounterPricing'
>;

export const PricingGridContextProvider: React.FC<
  PropsWithChildren<PricingGridContextProviderProps>
> = ({ children, ...props }) => {
  const { deleteItem: deletePricing } = useEntityDelete(DataType.Pricing);
  const { deleteItem: deleteItemCounterPricing } = useEntityDelete(
    DataType.CounterPricing
  );

  const value = useMemo<PricingGridContextValues>(
    () => ({
      ...props,
      deletePricing,
      deleteItemCounterPricing,
    }),
    [props, deletePricing, deleteItemCounterPricing]
  );

  return (
    <PricingGridContext.Provider value={value}>
      {children}
    </PricingGridContext.Provider>
  );
};

export const usePricingGridContext = () =>
  useContext(PricingGridContext as Context<PricingGridContextValues>);
