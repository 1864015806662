import React, { useMemo, useState } from 'react';

import { useWatch } from 'react-hook-form';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';

import { DataType, Aggregation } from '@m3ter-com/m3ter-api';
import { useTranslation, useFormError } from '@m3ter-com/console-core/hooks';
import { FormTextarea } from '@m3ter-com/console-core/components';
import { RadioTabs } from '@m3ter-com/ui-components';

import { dataTypeListAllQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useAppQuery from '@/hooks/data/useAppQuery';

import { CompoundAggregationFormSimpleCalculationField } from './CompoundAggregationFormSimpleCalculationField';

enum CalculationFieldType {
  Advanced = 'advanced',
  Simple = 'simple',
}

const emptyArray = new Array<Aggregation>();
const name = 'calculation';
const simpleAggregationSumRegex = /^(aggregation.\w+\s*\+*\s*)*$/;

export const CompoundAggregationFormCalculationField: React.FC = () => {
  const { t } = useTranslation();
  const { isInvalid, message } = useFormError(name);
  const selectedProductId = useWatch({ name: 'productId' });
  const calculationValue: string | undefined = useWatch({ name });

  const canShowSimpleField =
    !calculationValue || simpleAggregationSumRegex.test(calculationValue);
  const [fieldType, setFieldType] = useState<CalculationFieldType>(
    canShowSimpleField
      ? CalculationFieldType.Simple
      : CalculationFieldType.Advanced
  );

  const pathParams = useOrgPathParams();
  const { data: aggregations = emptyArray, isLoading: isLoadingAggregations } =
    useAppQuery(
      dataTypeListAllQuery({
        dataType: DataType.Aggregation,
        pathParams,
        queryParams: selectedProductId
          ? { productId: ['', selectedProductId] } // Matches the product ID or global. Order seems to matter.
          : {}, // Matches all aggregations.
      })
    );

  const fieldOptions = useMemo(
    () => [
      {
        value: CalculationFieldType.Simple,
        label: t('forms:labels.addAggregations'),
        content: (
          <CompoundAggregationFormSimpleCalculationField
            aggregations={aggregations}
            name={name}
            isLoading={isLoadingAggregations}
          />
        ),
      },
      {
        value: CalculationFieldType.Advanced,
        label: t('common:advanced'),
        content: (
          <FormTextarea
            data-testid="compound-aggregation-calculation-text-area"
            name={name}
          />
        ),
      },
    ],
    [aggregations, isLoadingAggregations, t]
  );

  return (
    <FormControl isRequired isInvalid={isInvalid}>
      <FormLabel>{t('forms:labels.calculation')}</FormLabel>
      {isInvalid && <FormErrorMessage>{message}</FormErrorMessage>}
      <RadioTabs
        alignItems="stretch"
        isDisabled={!canShowSimpleField}
        options={fieldOptions}
        value={fieldType}
        onChange={setFieldType}
      />
    </FormControl>
  );
};
