import { combineReducers } from 'redux';

import authReducer from './auth/auth';
import bootstrapReducer from './bootstrap/bootstrap';

const appReducer = combineReducers({
  auth: authReducer,
  bootstrap: bootstrapReducer,
});

export type AppState = ReturnType<typeof appReducer>;

export default appReducer;
