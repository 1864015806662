import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataType, Product } from '@m3ter-com/m3ter-api';

import { createSelectById, selectAllByDataType } from '@/store/data/data';

export interface ProductsState {
  selectedProductId?: string;
  selectedGlobal?: boolean;
}

interface SetSelectedProductPayload {
  productId: string | null;
}
export type SetSelectedProductAction = PayloadAction<SetSelectedProductPayload>;

interface RestoreSelectionsPayload {
  productId: string;
  global: boolean;
}
export type RestoreSelectionsAction = PayloadAction<RestoreSelectionsPayload>;

const name = 'products';

export const initialState: ProductsState = {};

const productsSlice = createSlice({
  name,
  initialState,
  reducers: {
    restoreSelections: {
      reducer: (state: ProductsState, action: RestoreSelectionsAction) => {
        state.selectedProductId = action.payload.productId;
        state.selectedGlobal = action.payload.global;
      },
      prepare: (productId: string, global: boolean) => ({
        payload: { productId, global },
      }),
    },
    setSelectedProduct: {
      reducer: (state: ProductsState, action: SetSelectedProductAction) => {
        const { productId } = action.payload;
        if (productId) {
          state.selectedProductId = productId;
          state.selectedGlobal = false;
        } else {
          state.selectedGlobal = true;
        }
      },
      prepare: (productId: string | null) => ({ payload: { productId } }),
    },
    reset: () => initialState,
  },
});

// Export actions.

export const { restoreSelections, setSelectedProduct, reset } =
  productsSlice.actions;

// Selectors

const selectProductsState = (state: { [name]: ProductsState }): ProductsState =>
  state[name];

export const selectSelectedProductId = createSelector(
  selectProductsState,
  (productsState) => productsState.selectedProductId
);

export const selectSelectedProductIdIfNotGlobal = createSelector(
  selectProductsState,
  (productsState) =>
    productsState.selectedGlobal ? null : productsState.selectedProductId
);

export const selectSelectedProduct = createSelector(
  selectSelectedProductId,
  createSelectById(DataType.Product),
  (selectedProductId, selectById) => selectById(selectedProductId)
);

export const selectSelectedGlobal = createSelector(
  selectProductsState,
  (productsState) => !!productsState.selectedGlobal
);

export const selectProducts = selectAllByDataType<Product>(DataType.Product);

export default productsSlice.reducer;
