import React, { useMemo } from 'react';

import { Grid } from '@chakra-ui/react';
import orderBy from 'lodash/orderBy';

import {
  Aggregation,
  CompoundAggregation,
  Counter,
  CounterPricing,
  Plan,
  PlanTemplate,
  Pricing,
} from '@m3ter-com/m3ter-api';
import { HorizontalScrollBox } from '@m3ter-com/ui-components';
import { getGroupedPricings } from '@m3ter-com/console-core/utils';

import { PricingGridContextProvider } from '@/components/features/pricing/grid/PricingGridContext';
import { PricingGridCell } from '@/components/features/pricing/grid/PricingGridCell';

import {
  PricingGridColumnHeader,
  PricingGridColumnHeaderCallback,
} from './PricingGridColumnHeader';
import { PricingGridRowHeaderCallback } from './PricingGridRowHeader';
import { PricingGridRow } from './PricingGridRow';
import { NoUsageEntitiesWarning } from './NoUsageEntitiesWarning';

export interface PricingGridProps {
  linkedPlanTemplates: Array<PlanTemplate>;
  pricings: Array<Pricing>;
  itemCounterPricings: Array<CounterPricing>;
  selectedAggregations: Array<Aggregation>;
  selectedCompoundAggregations: Array<CompoundAggregation>;
  selectedPlans: Array<Plan>;
  selectedPlanTemplates: Array<PlanTemplate>;
  selectedItemCounters: Array<Counter>;
  canEditPlanOrTemplate?: boolean;
  canEditPricing?: boolean;
  canEditTemplatePricing?: boolean;
  onAddPlanOrTemplate?: PricingGridColumnHeaderCallback;
  onDuplicatePlanOrTemplate?: PricingGridColumnHeaderCallback;
  onRemovePricingUsageEntity: PricingGridRowHeaderCallback;
  onRemovePlanOrTemplate?: PricingGridColumnHeaderCallback;
}

export const PricingGrid: React.FC<PricingGridProps> = ({
  linkedPlanTemplates,
  pricings,
  itemCounterPricings,
  selectedAggregations,
  selectedCompoundAggregations,
  selectedPlans,
  selectedPlanTemplates,
  selectedItemCounters,
  onAddPlanOrTemplate,
  onDuplicatePlanOrTemplate,
  onRemovePricingUsageEntity,
  onRemovePlanOrTemplate,
  canEditPlanOrTemplate = true,
  canEditPricing = true,
  canEditTemplatePricing = true,
}) => {
  const orderedSelectedPlans = useMemo(
    () => orderBy(selectedPlans, 'name'),
    [selectedPlans]
  );

  const orderedSelectedPlanTemplates = useMemo(
    () => orderBy(selectedPlanTemplates, 'name'),
    [selectedPlanTemplates]
  );

  const orderedSelectedAggregations = useMemo(
    () => orderBy(selectedAggregations, 'name'),
    [selectedAggregations]
  );

  const orderedSelectedCompoundAggregations = useMemo(
    () => orderBy(selectedCompoundAggregations, 'name'),
    [selectedCompoundAggregations]
  );

  const orderedSelectedItemCounters = useMemo(
    () => orderBy(selectedItemCounters, 'name'),
    [selectedItemCounters]
  );

  const linkedPlanTemplatesByPlanId: Record<string, PlanTemplate | undefined> =
    useMemo(
      () =>
        Object.fromEntries(
          orderedSelectedPlans.map((plan) => {
            const { id: planId, planTemplateId } = plan;
            const planTemplate = linkedPlanTemplates.find(
              (template) => template.id === planTemplateId
            );

            return [planId, planTemplate];
          })
        ),
      [linkedPlanTemplates, orderedSelectedPlans]
    );

  const groupedPricings = useMemo(
    () => getGroupedPricings(pricings),
    [pricings]
  );

  const groupedItemCounterPricings = useMemo(
    () => getGroupedPricings(itemCounterPricings),
    [itemCounterPricings]
  );

  // Extra row and column is for the column (plan) and row (aggregation) headers.
  const numColumns =
    orderedSelectedPlans.length + orderedSelectedPlanTemplates.length + 1;
  const numRows =
    orderedSelectedAggregations.length +
    orderedSelectedCompoundAggregations.length +
    orderedSelectedItemCounters.length +
    1;

  return (
    <HorizontalScrollBox my={8}>
      <PricingGridContextProvider
        canEditPricing={canEditPricing}
        canEditTemplatePricing={canEditTemplatePricing}
      >
        <Grid
          templateColumns={`repeat(${numColumns}, max-content)`}
          templateRows={`repeat(${numRows}, max-content)`}
        >
          <PricingGridCell />
          {orderedSelectedPlans.map((plan, index) => (
            <PricingGridCell
              key={`column-${index + 1}`}
              data-testid={`plan-column-${index + 1}`}
              borderTopWidth={2}
            >
              <PricingGridColumnHeader
                plan={plan}
                planTemplate={linkedPlanTemplatesByPlanId[plan.id]}
                onAddPlanOrTemplate={onAddPlanOrTemplate}
                onDuplicate={onDuplicatePlanOrTemplate}
                onRemove={onRemovePlanOrTemplate}
                showEdit={canEditPlanOrTemplate}
              />
            </PricingGridCell>
          ))}
          {orderedSelectedPlanTemplates.map((planTemplate, index) => (
            <PricingGridCell
              key={`column-${index + 1}`}
              data-testid={`plan-template-column-${index + 1}`}
              borderTopWidth={2}
            >
              <PricingGridColumnHeader
                planTemplate={planTemplate}
                onDuplicate={onDuplicatePlanOrTemplate}
                onRemove={onRemovePlanOrTemplate}
                showEdit={canEditPlanOrTemplate}
              />
            </PricingGridCell>
          ))}
          {orderedSelectedAggregations.length === 0 &&
            orderedSelectedCompoundAggregations.length === 0 &&
            orderedSelectedItemCounters.length === 0 && (
              <React.Fragment>
                <PricingGridCell borderLeftWidth={2}>
                  <NoUsageEntitiesWarning />
                </PricingGridCell>
                {/* Add an empty cell for each remaining column */}
                {Array.from({ length: numColumns - 1 }, (_, i) => (
                  <PricingGridCell key={i} />
                ))}
              </React.Fragment>
            )}
          {orderedSelectedAggregations.map((aggregation, index) => (
            <PricingGridRow
              key={`pricing-grid-aggregation-row-${aggregation.id}`}
              data-testid={`pricing-grid-aggregation-row-${index}`}
              pricingsByPlanOrTemplate={groupedPricings[aggregation.id]}
              aggregation={aggregation}
              linkedPlanTemplatesByPlanId={linkedPlanTemplatesByPlanId}
              selectedPlans={orderedSelectedPlans}
              selectedPlanTemplates={orderedSelectedPlanTemplates}
              onRemovePricingUsageEntity={onRemovePricingUsageEntity}
            />
          ))}
          {selectedCompoundAggregations.map((compoundAggregation, index) => (
            <PricingGridRow
              key={`pricing-grid-compound-row-${compoundAggregation.id}`}
              data-testid={`pricing-grid-compound-row-${index}`}
              pricingsByPlanOrTemplate={groupedPricings[compoundAggregation.id]}
              compoundAggregation={compoundAggregation}
              linkedPlanTemplatesByPlanId={linkedPlanTemplatesByPlanId}
              selectedPlans={orderedSelectedPlans}
              selectedPlanTemplates={orderedSelectedPlanTemplates}
              onRemovePricingUsageEntity={onRemovePricingUsageEntity}
            />
          ))}
          {selectedItemCounters.map((itemCounter, index) => (
            <PricingGridRow
              key={`pricing-grid-counter-row-${itemCounter.id}`}
              data-testid={`pricing-grid-counter-row-${index}`}
              pricingsByPlanOrTemplate={
                groupedItemCounterPricings[itemCounter.id]
              }
              itemCounter={itemCounter}
              linkedPlanTemplatesByPlanId={linkedPlanTemplatesByPlanId}
              selectedPlans={orderedSelectedPlans}
              selectedPlanTemplates={orderedSelectedPlanTemplates}
              onRemovePricingUsageEntity={onRemovePricingUsageEntity}
            />
          ))}
        </Grid>
      </PricingGridContextProvider>
    </HorizontalScrollBox>
  );
};

// Re-export callback types
export type { PricingGridColumnHeaderCallback, PricingGridRowHeaderCallback };
