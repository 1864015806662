import { useMemo } from 'react';

import { useFormContext, get } from 'react-hook-form';

export const useFormError = (name: string) => {
  const { formState } = useFormContext();

  return useMemo(() => {
    const error = get(formState?.errors, name);
    return {
      isInvalid: !!error,
      message: !error ? undefined : error.message,
    };
  }, [formState, name]);
};
