import React from 'react';

import { DataType, Pricing } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { PricingRoute } from '@/components/routes/pricing/PricingRoute';

import setupPricingSegmentsEditorRoute from './segments-editor';

const LazyPricingCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "PricingCreate" */ '@/components/routes/pricing/editor/PricingCreateRoute'
  ).then((module) => ({ default: module.PricingCreateRoute }))
);

const LazyPricingEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "PricingEdit" */ '@/components/routes/pricing/editor/PricingEditRoute'
  ).then((module) => ({ default: module.PricingEditRoute }))
);

const LazyPricingDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "PricingDetails" */ '@/components/routes/pricing/editor/PricingDetailsRoute'
  ).then((module) => ({ default: module.PricingDetailsRoute }))
);

export default () =>
  createCrudRoute<Pricing>({
    path: 'pricing',
    dataType: DataType.Pricing,
    element: <PricingRoute />,
    create: <LazyPricingCreateRoute />,
    edit: <LazyPricingEditRoute />,
    details: <LazyPricingDetailsRoute />,
    directChildRoutes: [setupPricingSegmentsEditorRoute()],
  });
