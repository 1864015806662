import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const radioTabs: ComponentMultiStyleConfig = {
  parts: ['tab', 'tabPanel'],
  baseStyle: {
    tab: {
      padding: 0,
      mr: 4,
    },
    tabPanel: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
};

export default radioTabs;
