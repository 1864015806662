import React from 'react';

import { Product } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { ProductForm } from '@/components/features/products/ProductForm';

export const ProductsEditRoute: React.FC = () => {
  return <CrudEdit<Product> form={ProductForm} />;
};
