import React, { useMemo } from 'react';

import { skipToken } from '@tanstack/react-query';

import {
  ExternalMappingEntityType,
  getIntegrationsExternalEntities,
} from '@m3ter-com/m3ter-api';
import { ComboboxOption } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormCombobox, FormField } from '@m3ter-com/console-core/components';

import useOrg from '@/hooks/data/crud/useOrg';
import useAppQuery from '@/hooks/data/useAppQuery';

export interface ExternalMappingExternalIdFieldProps {
  name: string;
  externalSystem?: string;
  m3terEntityType?: ExternalMappingEntityType;
  hideLabel?: boolean;
}

export const ExternalMappingExternalIdField: React.FC<
  ExternalMappingExternalIdFieldProps
> = ({ externalSystem, m3terEntityType, name, hideLabel = false }) => {
  const { t } = useTranslation();
  const { currentOrgId: organizationId } = useOrg();

  const { isLoading: isLoadingExternalEntities, data: externalEntities } =
    useAppQuery({
      queryFn:
        !!externalSystem && !!m3terEntityType
          ? () =>
              getIntegrationsExternalEntities({
                organizationId,
                externalSystem,
                m3terEntityType,
              })
          : skipToken,
      queryKey: [
        'externalEntities',
        organizationId,
        externalSystem,
        m3terEntityType,
      ],
    });

  const externalIdOptions = useMemo<Array<ComboboxOption>>(
    () =>
      (externalEntities || []).map((entity) => ({
        value: entity.externalId,
        label: entity.externalName ? entity.externalName : entity.externalId,
        secondaryLabel: entity.externalName ? entity.externalId : undefined,
      })),
    [externalEntities]
  );

  return (
    <FormField
      isRequired
      name={name}
      label={t('forms:labels.externalEntityId')}
      isDisabled={!externalSystem || !m3terEntityType}
      control={FormCombobox}
      options={externalIdOptions}
      isLoadingOptions={isLoadingExternalEntities}
      hideLabel={hideLabel}
    />
  );
};
