import React, { useMemo } from 'react';

import { Link } from '@chakra-ui/react';

import {
  DataType,
  ExternalMapping,
  ExternalMappingEntityType,
} from '@m3ter-com/m3ter-api';

import { ReferenceLabel } from '@/components/common/data/ReferenceLabel';
import { ReferenceLink } from '@/components/common/data/ReferenceLink';
import { CrudEditLink } from '@/components/common/navigation/CrudEditLink';
import useDateFormatter from '@/hooks/util/useDateFormatter';

export interface ExternalMappingM3terIdAccessorProps {
  externalMapping: ExternalMapping;
}

export const ExternalMappingM3terIdAccessor: React.FC<
  ExternalMappingM3terIdAccessorProps
> = ({ externalMapping }) => {
  const { m3terEntity: entityType, m3terId: entityId } = externalMapping;
  const { toLongDate } = useDateFormatter();

  // Definitely not ideal to use this switch statement here but the alternative
  // is building a map of ExternalMappingEntityType -> the component and props
  // needed to render appropriate reference links / labels and I wish anyone that
  // tries to get the typings for that to work a jolly good time.
  const referenceComponent = useMemo(() => {
    switch (entityType) {
      case ExternalMappingEntityType.Account:
        return (
          <ReferenceLink
            id={entityId}
            dataType={DataType.Account}
            accessor="name"
          />
        );
      case ExternalMappingEntityType.Aggregation:
        return (
          <ReferenceLink
            id={entityId}
            dataType={DataType.Aggregation}
            accessor="name"
          />
        );
      case ExternalMappingEntityType.Bill:
        return (
          <ReferenceLink
            id={entityId}
            dataType={DataType.Bill}
            accessor={(bill) => toLongDate(bill.externalInvoiceDate)}
          />
        );
      case ExternalMappingEntityType.CompoundAggregation:
        return (
          <ReferenceLink
            id={entityId}
            dataType={DataType.CompoundAggregation}
            accessor="name"
          />
        );
      case ExternalMappingEntityType.Meter:
        return (
          <ReferenceLink
            id={entityId}
            dataType={DataType.Meter}
            accessor="name"
          />
        );
      case ExternalMappingEntityType.Organization:
        return (
          <ReferenceLabel
            id={entityId}
            dataType={DataType.Organization}
            accessor="organizationName"
          />
        );
      case ExternalMappingEntityType.Plan:
        return (
          <ReferenceLink
            id={entityId}
            dataType={DataType.Plan}
            accessor="name"
          />
        );
      case ExternalMappingEntityType.Pricing:
        return (
          <Link
            as={CrudEditLink}
            addReturnPath
            dataType={DataType.Pricing}
            id={entityId}
          >
            {entityId}
          </Link>
        );
      case ExternalMappingEntityType.Product:
        return (
          <Link
            as={CrudEditLink}
            addReturnPath
            dataType={DataType.Product}
            id={entityId}
          >
            <ReferenceLabel
              id={entityId}
              dataType={DataType.Product}
              accessor="name"
            />
          </Link>
        );
      default:
        return entityId;
    }
  }, [entityType, entityId, toLongDate]);

  // Without the Fragment the return type doesn't match `React.FC`
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <React.Fragment>{referenceComponent}</React.Fragment>;
};
