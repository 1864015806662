import React from 'react';

import { DataType, PlanGroup } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormSection,
  NameCodeFields,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import planGroupSchema from '@/validation/planGroup';
import { FormCustomFieldsEditor } from '@/components/forms/FormCustomFieldsEditor';
import { PlanGroupFormFields } from '@/components/features/pricing/PlanGroupFormFields';
import { FormPicklistSelect } from '@/components/features/accounts/FormPicklistSelect';

export interface PlanGroupFormProps extends BaseFormProps<Partial<PlanGroup>> {}

export const PlanGroupForm: React.FC<PlanGroupFormProps> = ({
  onSave,
  onCancel,
  isSaving = false,
  isEdit = false,
  initialValues = {},
}) => {
  const { t } = useTranslation();
  const entityName = t('common:planGroup');

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={planGroupSchema}
    >
      <FormStack mb={4}>
        <FormSection heading={t('common:entityDetails', { entityName })}>
          <NameCodeFields />
          <FormField
            isRequired
            name="currency"
            label={t('forms:labels.currency')}
            control={FormPicklistSelect}
            useCodeAsValue
            dataType={DataType.Currency}
          />
        </FormSection>
        <PlanGroupFormFields />
        <FormCustomFieldsEditor />
      </FormStack>
      <FormActions
        cancelText={t('common:cancel')}
        submitText={
          isEdit
            ? t('forms:buttons.updateEntity', { entityName })
            : t('forms:buttons.createEntity', { entityName })
        }
        isSaving={isSaving}
        onCancel={onCancel}
      />
    </Form>
  );
};
