import React from 'react';

import { ButtonGroup } from '@chakra-ui/react';

import { Button } from '@m3ter-com/ui-components';

export interface FormActionsProps {
  submitText: string;
  backText?: string;
  cancelText?: string;
  isSaving?: boolean;
  isSubmitDisabled?: boolean;
  isBackDisabled?: boolean;
  isCancelDisabled?: boolean;
  onBack?: () => void;
  onCancel?: () => void;
}

export const FormActions: React.FC<FormActionsProps> = ({
  submitText,
  backText,
  cancelText,
  isSaving = false,
  isSubmitDisabled = false,
  isBackDisabled = false,
  isCancelDisabled = false,
  onBack,
  onCancel,
}) => (
  <ButtonGroup>
    <Button
      data-testid="form-actions-submit-button"
      intent="primary"
      type="submit"
      isLoading={isSaving}
      isDisabled={isSubmitDisabled}
    >
      {submitText}
    </Button>
    {onBack && (
      <Button
        data-testid="form-actions-back-button"
        type="button"
        variant="ghost"
        isDisabled={isBackDisabled}
        onClick={onBack}
      >
        {backText}
      </Button>
    )}
    {onCancel && (
      <Button
        data-testid="form-actions-cancel-button"
        type="button"
        variant="ghost"
        isDisabled={isCancelDisabled}
        onClick={onCancel}
      >
        {cancelText}
      </Button>
    )}
  </ButtonGroup>
);
