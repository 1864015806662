import React, { useCallback, useMemo } from 'react';

import ReactSelect, {
  ActionMeta,
  createFilter,
  Props as ReactSelectProps,
  SingleValue,
} from 'react-select';
import { useFormControl } from '@chakra-ui/react';

import useReactSelectStyles from '../../../../theme/hooks/useReactSelectStyles';
import { NumericOption, NumericSelectBaseProps } from '../types';
import useSelectKeyDown from '../../../../hooks/useSelectKeyDown';

export interface NumericSelectProps
  extends NumericSelectBaseProps,
    Pick<ReactSelectProps, 'inputId' | 'menuPlacement' | 'placeholder'> {}

export const NumericSelect: React.FC<NumericSelectProps> = ({
  options,
  value,
  onChange,
  inputId,
  isClearable,
  isDisabled = false,
  isLoading = false,
  isSearchable = false,
  menuPlacement,
  placeholder,
  searchMatchFrom = 'start',
}) => {
  const { onMenuOpen, onMenuClose, onSelectKeyDown } = useSelectKeyDown();
  const { styles, theme } = useReactSelectStyles<NumericOption>();

  // Get the ID from the Chakra `FormControl` if there is one, so that
  // the label and form field are connected.
  const { id } = useFormControl({});

  const filterOption = useMemo(
    () =>
      createFilter({
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: searchMatchFrom,
        stringify: (option) => option.label,
        trim: true,
      }),
    [searchMatchFrom]
  );

  const selectedOption = useMemo<NumericOption | undefined>(
    () => options.find((opt) => opt.value === value),
    [options, value]
  );

  const handleChange = useCallback(
    (
      newOption: SingleValue<NumericOption>,
      actionMeta: ActionMeta<NumericOption>
    ) => {
      if (!newOption || actionMeta.action === 'clear') {
        onChange(null);
      } else {
        onChange(newOption.value);
      }
    },
    [onChange]
  );

  return (
    <ReactSelect<NumericOption, false>
      filterOption={filterOption}
      inputId={inputId ?? id}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isMulti={false}
      isSearchable={isSearchable}
      menuPlacement={menuPlacement}
      onKeyDown={onSelectKeyDown}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      options={options}
      placeholder={placeholder}
      styles={styles}
      theme={theme}
      value={selectedOption}
      onChange={handleChange}
    />
  );
};
