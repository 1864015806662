import { useMemo, useCallback } from 'react';

import { uniq } from 'lodash';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Id } from '@m3ter-com/m3ter-api';

interface AddExtraAggregationsQueryParams {
  aggregationIds?: Array<Id>;
  compoundAggregationIds?: Array<Id>;
  itemCounterIds?: Array<Id>;
}

const usePlanPricingQueryParams = () => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const planPricingQueryString = useMemo(
    () =>
      Object.entries(Object.fromEntries(searchParams))
        .map(([key, value]) => `${key}=${value}`)
        .join('&'),
    [searchParams]
  );

  const generateUsageEntitiesLinkQueryParams = useCallback(
    (id: Id) => ({
      returnPath: `${pathname}?${id}=:id${
        planPricingQueryString ? `&${planPricingQueryString}` : ''
      }`,
    }),
    [pathname, planPricingQueryString]
  );

  const addExtraIdsToQueryParams = useCallback(
    (paramKey: string, ids: Array<Id>) => {
      const extraIds = searchParams.get(paramKey)?.split(',') ?? [];
      const mergedIds = uniq([...ids, ...extraIds]).join(',');

      if (mergedIds) {
        searchParams.set(paramKey, mergedIds);
      }
    },
    [searchParams]
  );

  const addExtraUsageEntitiesToQueryParams = useCallback(
    ({
      aggregationIds = [],
      compoundAggregationIds = [],
      itemCounterIds = [],
    }: AddExtraAggregationsQueryParams) => {
      addExtraIdsToQueryParams('aggregationIds', aggregationIds);
      addExtraIdsToQueryParams(
        'compoundAggregationIds',
        compoundAggregationIds
      );
      addExtraIdsToQueryParams('itemCounterIds', itemCounterIds);
      setSearchParams(searchParams, { replace: true });
    },
    [addExtraIdsToQueryParams, searchParams, setSearchParams]
  );

  const getFilteredIds = useCallback(
    (param: string, filteredId: Id): string => {
      const ids = searchParams.get(param)?.split(',') ?? [];
      return ids.filter((id) => id !== filteredId).join(',');
    },
    [searchParams]
  );

  const removeIdsFromQueryParams = useCallback(
    (paramKey: string, ids: string): void => {
      if (ids) {
        searchParams.set(paramKey, ids);
      } else {
        searchParams.delete(paramKey);
      }
    },
    [searchParams]
  );

  const getQueryParamsToUpdate = useCallback(
    (isCompound?: boolean) =>
      // eslint-disable-next-line no-nested-ternary
      isCompound === false
        ? ['aggregationIds', 'aggregationId']
        : isCompound
        ? ['compoundAggregationIds', 'compoundAggregationId']
        : ['itemCounterIds', 'counterId'],
    []
  );

  const removeUsageEntitiesFromQueryParams = useCallback(
    (id: Id, isCompound?: boolean) => {
      const queryParamsToUpdate = getQueryParamsToUpdate(isCompound);
      queryParamsToUpdate.forEach((paramKey) => {
        removeIdsFromQueryParams(paramKey, getFilteredIds(paramKey, id));
      });
      setSearchParams(searchParams, { replace: true });
    },
    [
      getQueryParamsToUpdate,
      setSearchParams,
      searchParams,
      removeIdsFromQueryParams,
      getFilteredIds,
    ]
  );

  return {
    addExtraUsageEntitiesToQueryParams,
    removeUsageEntitiesFromQueryParams,
    generateUsageEntitiesLinkQueryParams,
  };
};

export default usePlanPricingQueryParams;
