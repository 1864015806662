import type { NamedRoute } from '@/types/routes';

import setupAggregationsRoute from './aggregations';
import setupCompoundAggregationsRoute from './compound-aggregations';
import setupIngestEventsRoute from './ingest-events';
import setupItemCountersRoute from './item-counters';
import setupMetersRoute from './meters';

export default (): NamedRoute => ({
  path: 'usage',
  children: [
    setupAggregationsRoute(),
    setupCompoundAggregationsRoute(),
    setupIngestEventsRoute(),
    setupItemCountersRoute(),
    setupMetersRoute(),
  ],
});
