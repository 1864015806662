import React, { useCallback, useMemo } from 'react';

import { Box, Checkbox, Flex } from '@chakra-ui/react';
import isEqual from 'lodash/isEqual';

import { uniq } from '@/util/array';

export interface Option {
  children?: Array<Option>;
  value: string;
  label: string;
}

export type { Option as NestedCheckboxOption };

// Helper function to get all the values of all the descendants of an option.
const getDescendantValues = (option: Option): Array<string> => {
  const values: Array<string> = [];

  const traverse = (optionToTraverse: Option) => {
    if (!optionToTraverse.children || optionToTraverse.children.length === 0) {
      values.push(optionToTraverse.value);
    } else {
      optionToTraverse.children.forEach(traverse);
    }
  };

  traverse(option);
  return values;
};

interface NestedCheckboxInnerProps {
  selectAllValue: string;
  values: Array<string>;
  options: Array<Option>;
  onValueChange: (
    value: string,
    isChecked: boolean,
    ancestors: Array<string>
  ) => void;
  ancestors?: Array<string>;
  index?: number;
}

const NestedCheckboxInner: React.FC<NestedCheckboxInnerProps> = ({
  selectAllValue,
  values,
  options,
  onValueChange,
  ancestors = [],
  index = 0,
}) => {
  const onChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      optionAncestors: Array<string>
    ) => {
      onValueChange(event.target.value, event.target.checked, optionAncestors);
    },
    [onValueChange]
  );

  return (
    <React.Fragment>
      {options.map((option) => {
        const descendants = getDescendantValues(option);

        const isChecked =
          values.includes(option.value) ||
          values.includes(selectAllValue) ||
          descendants.every((descendant) => values.includes(descendant));

        const hasChildren = !!option.children && option.children.length > 0;
        const hasCheckedChild = descendants.some((descendant) =>
          values.includes(descendant)
        );

        const isIndeterminate = hasChildren && !isChecked && hasCheckedChild;

        let optionChildren: JSX.Element | null = null;
        if (hasChildren) {
          optionChildren = (
            <Flex
              flexDirection={index === 0 ? 'row' : 'column'}
              borderLeft="1px solid"
              borderColor="chakra-border-color"
            >
              <NestedCheckboxInner
                selectAllValue={selectAllValue}
                values={values}
                onValueChange={onValueChange}
                options={option.children!} // 'hasChildren' already checks for the options children
                ancestors={[...ancestors, option.value]}
                index={index + 1}
              />
            </Flex>
          );
        }

        return (
          <Box pl={index === 0 ? 0 : 4} key={option.value}>
            <Checkbox
              value={option.value}
              isChecked={isChecked}
              onChange={(event) => onChange(event, ancestors)}
              isIndeterminate={isIndeterminate}
            >
              {option.label}
            </Checkbox>
            {optionChildren}
          </Box>
        );
      })}
    </React.Fragment>
  );
};

export interface NestedCheckboxProps {
  selectAllValue?: string;
  selectAllLabel?: string;
  value: Array<string>;
  options: Array<Option>;
  onChange: (value: Array<string>) => void;
}

export const NestedCheckbox: React.FC<NestedCheckboxProps> = ({
  value: values,
  options,
  onChange,
  selectAllValue = '*',
  selectAllLabel = 'Select all',
}) => {
  const transformedOptions = useMemo<Array<Option>>(
    () => [
      {
        value: selectAllValue,
        label: selectAllLabel,
        children: options,
      },
    ],
    [options, selectAllLabel, selectAllValue]
  );

  const allDescendants = useMemo<Array<string>>(
    () => getDescendantValues(transformedOptions[0]),
    [transformedOptions]
  );

  const findOption = useCallback(
    (optionValue: string, ancestors: Array<string>) => {
      let foundOption: Option | undefined;

      if (ancestors.length === 0) {
        return transformedOptions.find(
          (option) => option.value === optionValue
        );
      }

      ancestors.forEach((ancestor) => {
        const candidates = foundOption
          ? foundOption.children
          : transformedOptions;
        foundOption = candidates?.find(
          (candidate) => candidate.value === ancestor
        );
      });

      return foundOption?.children?.find(
        (child) => child.value === optionValue
      );
    },
    [transformedOptions]
  );

  const onValueChange = useCallback(
    (value: string, isChecked: boolean, ancestors: Array<string>) => {
      const option = findOption(value, ancestors);

      if (!option) {
        return;
      }

      let updateValues = [...values];
      const optionDescendants = getDescendantValues(option);

      if (option.value === selectAllValue) {
        updateValues = isChecked ? [selectAllValue] : [];
      } else {
        if (updateValues[0] === selectAllValue) {
          updateValues = allDescendants;
        }

        updateValues = isChecked
          ? uniq([...updateValues, ...optionDescendants])
          : updateValues.filter(
              (selectedValue) => !optionDescendants.includes(selectedValue)
            );

        updateValues = isEqual(allDescendants.sort(), updateValues.sort())
          ? [selectAllValue]
          : updateValues;
      }

      onChange(updateValues);
    },
    [allDescendants, findOption, onChange, selectAllValue, values]
  );

  return (
    <NestedCheckboxInner
      selectAllValue={selectAllValue}
      values={values}
      onValueChange={onValueChange}
      options={transformedOptions}
    />
  );
};
