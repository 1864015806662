import React from 'react';

import { VStack, StackProps } from '@chakra-ui/react';

export interface FormStackProps extends StackProps {}

export const FormStack: React.FC<FormStackProps> = ({ children, ...props }) => (
  <VStack spacing={6} maxWidth="40em" alignItems="start" {...props}>
    {children}
  </VStack>
);
