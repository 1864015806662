import React from 'react';

import { Icon, VStack } from '@chakra-ui/react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button } from '@m3ter-com/ui-components';

import {
  SegmentedPricingSortingState,
  SortingDirection,
} from '@/hooks/features/pricing/usePricingSegmentsEditor';

export interface SegmentedPricingGridSortButtonProps {
  fieldName: string;
  sortingState: SegmentedPricingSortingState;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const SegmentedPricingGridSortButton: React.FC<
  SegmentedPricingGridSortButtonProps
> = ({ fieldName, sortingState, onClick }) => {
  const { t } = useTranslation();
  const isFieldSorted = sortingState.fieldName === fieldName;
  const ascendingButtonColor =
    isFieldSorted && sortingState.direction === SortingDirection.Ascending
      ? 'green-adaptive'
      : 'chakra-subtle-text';
  const descendingButtonColor =
    isFieldSorted && sortingState.direction === SortingDirection.Descending
      ? 'green-adaptive'
      : 'chakra-subtle-text';

  return (
    <Button
      data-field-name={fieldName}
      aria-label={t('common:sortByX', { x: fieldName })}
      onClick={onClick}
    >
      <VStack alignItems="center" justifyContent="center" spacing={0}>
        <Icon as={FaCaretUp} boxSize="1.5em" color={ascendingButtonColor} />
        <Icon as={FaCaretDown} boxSize="1.5em" color={descendingButtonColor} />
      </VStack>
    </Button>
  );
};
