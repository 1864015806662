import React, { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { Balance } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { RequiresCurrenciesWrapper } from '@/components/features/organization/RequiresCurrenciesWrapper';
import { BalanceForm } from '@/components/features/accounts/BalanceForm';

export const BalancesCreateRoute: React.FC = () => {
  const { t } = useTranslation();
  const { accountId } = useParams();

  const initialValues = useMemo<Partial<Balance>>(
    () => ({
      accountId,
    }),
    [accountId]
  );

  return (
    <RequiresCurrenciesWrapper
      message={t('features:balances.noCurrenciesMessage')}
    >
      <CrudCreate<Balance> form={BalanceForm} initialValues={initialValues} />
    </RequiresCurrenciesWrapper>
  );
};
