import { createAction, PrepareAction } from '@reduxjs/toolkit';

export interface RedirectPayload {
  to: string;
}

export const redirect = createAction<PrepareAction<RedirectPayload>>(
  'redirection/redirect',
  (to: string) => ({
    payload: { to },
  })
);
