import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { Contract } from '@m3ter-com/m3ter-api';

const suite = create('contract', (data: Partial<Contract>) => {
  test('name', i18next.t('forms:validations.common.nameRequired'), () => {
    enforce(data.name).isNotEmpty();
  });

  test('name', i18next.t('forms:validations.common.nameMaxLength'), () => {
    enforce(data.name).shorterThanOrEquals(200);
  });

  test('code', i18next.t('forms:validations.common.codeRequired'), () => {
    enforce(data.code).isNotEmpty();
  });

  test(
    'startDate',
    i18next.t('forms:validations.common.startDateRequired'),
    () => {
      enforce(data.startDate).isNotEmpty();
    }
  );

  test('endDate', i18next.t('forms:validations.common.endDateRequired'), () => {
    enforce(data.endDate).isNotEmpty();
  });
});

export default suite;
