import React, { useMemo } from 'react';

import { AccountPlan, ChildBillingMode } from '@m3ter-com/m3ter-api';

import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { AccountPlanForm } from '@/components/features/accounts/AccountPlanForm';
import { useCrudRetrieveContext } from '@/components/common/crud/CrudRetrieveContext';

export const AccountPlansCreateRoute: React.FC = () => {
  const { account } = useCrudRetrieveContext();

  const initialValues = useMemo(
    () => ({
      accountId: account?.id,
      childBillingMode: ChildBillingMode.ParentBreakdown,
    }),
    [account]
  );

  return (
    <CrudCreate<AccountPlan>
      form={AccountPlanForm}
      initialValues={initialValues}
    />
  );
};
