import React, { ReactElement } from 'react';

import { Entity } from '@m3ter-com/m3ter-api';

import useCrudDetails from '@/hooks/data/crud/useCrudDetails';

export interface CrudDetailsProps {
  component: React.ElementType;
}

export function CrudDetails<E extends Entity>({
  component: Component,
}: CrudDetailsProps): ReactElement<any, any> | null {
  const { itemData } = useCrudDetails<E>();

  return itemData ? <Component data={itemData} /> : null;
}
