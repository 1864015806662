import { PayloadAction } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { takeEvery, call, put } from 'redux-saga/effects';

import { Context } from '@/store/rootSaga';
import { redirect, RedirectPayload } from '@/store/redirection/redirection';

export function* handleRedirectSaga(action: AnyAction) {
  // Resolve any data in the redirectTo.
  const { redirectTo }: { redirectTo: string } = action.meta;
  const id: string | undefined = action.payload?.item?.id;
  const redirectPath =
    id && redirectTo.includes(':id')
      ? redirectTo.replace(':id', action.payload.item.id)
      : redirectTo;

  yield put(redirect(redirectPath));
}

export function* redirectSaga(
  { router }: Context,
  action: PayloadAction<RedirectPayload>
) {
  // We only allow absolute paths within the console. This avoids
  // being able to redirect to a 3rd-party site, `javascript:` URLs etc.
  if (action.payload.to.startsWith('/')) {
    yield call(router.navigate, action.payload.to);
  }
}

export default function* redirectionSaga(context: Context) {
  // Take any action with a redirect in the meta.
  yield takeEvery(
    (action: AnyAction) =>
      !!action.meta && action.meta.redirectTo !== undefined,
    handleRedirectSaga
  );

  yield takeEvery(redirect.type, redirectSaga, context);
}
