import React from 'react';

import { DataType, UserAdmin } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { UserAdminRoute } from '@/components/routes/admin/users/UserAdminRoute';
import { UserAdminListRoute } from '@/components/routes/admin/users/UserAdminListRoute';
import { UserAdminCreateRoute } from '@/components/routes/admin/users/UserAdminCreateRoute';

export default () =>
  createCrudRoute<UserAdmin>({
    path: 'users',
    dataType: DataType.UserAdmin,
    element: <UserAdminRoute />,
    list: <UserAdminListRoute />,
    create: <UserAdminCreateRoute />,
  });
