import React, { useCallback, useMemo, useState } from 'react';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useSteps,
} from '@chakra-ui/react';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Stepper, Step } from '@m3ter-com/ui-components';

import { dataTypeRetrieveQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useAppQuery from '@/hooks/data/useAppQuery';

import {
  AccountIngestModalStepOne,
  AccountIngestModalStepOneFormValues,
} from './AccountIngestModalStepOne';
import { AccountIngestModalStepTwo } from './AccountIngestModalStepTwo';

export interface AccountIngestModalProps {
  accountCode: string;
  isOpen: boolean;
  onClose: () => void;
}

type AccountIngestModalState = AccountIngestModalStepOneFormValues;

const initialModalState: AccountIngestModalState = {
  meterId: '',
};

export const AccountIngestModal: React.FC<AccountIngestModalProps> = ({
  accountCode,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  const [modalState, setModalState] =
    useState<AccountIngestModalState>(initialModalState);

  const { activeStep, goToNext, goToPrevious, setActiveStep } = useSteps({
    count: 2,
  });

  const { meterId } = modalState;

  const pathParams = useOrgPathParams();
  const { data: selectedMeter } = useAppQuery(
    dataTypeRetrieveQuery(
      { dataType: DataType.Meter, id: meterId, pathParams },
      { enabled: !!meterId }
    )
  );

  const stepOneInitialValues = useMemo<AccountIngestModalStepOneFormValues>(
    () => ({
      meterId: modalState.meterId,
    }),
    [modalState]
  );

  const onStepOneSubmit = useCallback(
    (formValues: AccountIngestModalStepOneFormValues) => {
      setModalState(formValues);
      goToNext();
    },
    [goToNext]
  );

  const handleOnClose = useCallback(() => {
    onClose();
    setModalState(initialModalState);
    setActiveStep(0);
  }, [onClose, setActiveStep]);

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <ModalOverlay>
        <ModalContent minW="75vw" maxH="fit-content" p={4}>
          <ModalHeader>{t('features:ingest.submitUsageData')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stepper index={activeStep} mb={6}>
              <Step title={t('features:ingest.selectAMeter')} />
              <Step title={t('features:ingest.addMeasurementsAndSend')} />
            </Stepper>
            {activeStep === 0 && (
              <AccountIngestModalStepOne
                initialValues={stepOneInitialValues}
                onCancel={handleOnClose}
                onSubmit={onStepOneSubmit}
              />
            )}
            {activeStep === 1 && (
              <AccountIngestModalStepTwo
                accountCode={accountCode}
                selectedMeter={selectedMeter}
                onBack={goToPrevious}
                onCancel={handleOnClose}
              />
            )}
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
