import { ComponentMultiStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const parts = ['root', 'tab', 'tablist', 'tabpanel'];

const card: ComponentMultiStyleConfig = {
  parts,
  variants: {
    'soft-rounded': (props) => ({
      tab: {
        color: mode('gray.600', 'gray.200')(props),
      },
    }),
  },
};

export default card;
