import React, { PropsWithChildren, useCallback, useMemo } from 'react';

import {
  Text,
  Tr,
  Td,
  Tooltip,
  VStack,
  ButtonGroup,
  TableRowProps,
} from '@chakra-ui/react';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';

import {
  DataType,
  Bill,
  BillLineItem,
  BillLineItemType,
  BillStatus,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Confirmation, IconButton } from '@m3ter-com/ui-components';
import { formatNumber } from '@m3ter-com/console-core/utils';

import type { HighlightQueryParams } from './BillLineItemRow';

import { formatEntityUnit } from '@/util/data';
import useEntityDelete from '@/hooks/data/crud/useEntityDelete';
import useCurrencies from '@/hooks/util/useCurrencies';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import { CrudEditLink } from '@/components/common/navigation/CrudEditLink';
import { ReferenceLabel } from '@/components/common/data/ReferenceLabel';
import { ReferenceLink } from '@/components/common/data/ReferenceLink';

import { BillLineItemDetails } from './BillLineItemDetails';

export interface BillLineItemBreakdownProps {
  lineItem: BillLineItem;
  bill: Bill;
}

interface MemoLineItemBreakdownProps<DT extends DataType>
  extends BillLineItemBreakdownProps {
  dataType: DT;
  billLineItemType: BillLineItemType.CreditMemo | BillLineItemType.DebitMemo;
}

interface UsageLineItemBreakdownProps extends BillLineItemBreakdownProps {}

interface BillLineItemDetailsRowProps extends TableRowProps {}

const BillLineItemDetailsRow: React.FC<
  PropsWithChildren<BillLineItemDetailsRowProps>
> = ({ children, ...props }) => {
  return (
    <Tr backgroundColor="chakra-body-bg" {...props}>
      {children}
    </Tr>
  );
};

const UsageLineItemBreakdown: React.FC<UsageLineItemBreakdownProps> = ({
  bill,
  lineItem,
}) => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();

  const lineItemUsagePerPricingBand = useMemo(
    () => lineItem.usagePerPricingBand ?? [],
    [lineItem]
  );

  const lineItemCurrency = lineItem.currency ?? bill.currency;

  return lineItemUsagePerPricingBand.length > 0 ? (
    <React.Fragment>
      {lineItemUsagePerPricingBand.map((usage, index) => {
        const canShowUnitPrice = !usage.fixedPrice && !!usage.unitPrice;
        const usageBandDetailsBorderBottom =
          index < lineItemUsagePerPricingBand.length - 1
            ? '1px solid'
            : undefined;

        return (
          <BillLineItemDetailsRow
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            data-testid="usage-row"
            fontSize="sm"
            verticalAlign="top"
          >
            {index === 0 && (
              <BillLineItemDetails
                accountId={bill.accountId}
                lineItem={lineItem}
                rowSpan={lineItemUsagePerPricingBand.length}
              />
            )}
            <Td borderBottom={usageBandDetailsBorderBottom} textAlign="right">
              <Tooltip label={formatNumber(usage.bandQuantity)} placement="top">
                <span>{formatNumber(usage.bandUnits)}</span>
              </Tooltip>
            </Td>
            <Td borderBottom={usageBandDetailsBorderBottom}>
              {lineItem.unit ? formatEntityUnit(lineItem.unit) : ''}
            </Td>
            <Td borderBottom={usageBandDetailsBorderBottom} textAlign="right">
              {canShowUnitPrice &&
                formatCurrency(usage.unitPrice, lineItemCurrency, true)}
            </Td>
            <Td borderBottom={usageBandDetailsBorderBottom} textAlign="right">
              {formatCurrency(
                usage.bandSubtotal * lineItem.conversionRate,
                bill.currency
              )}
            </Td>
          </BillLineItemDetailsRow>
        );
      })}
    </React.Fragment>
  ) : (
    <BillLineItemDetailsRow data-testid="no-usage-row">
      <BillLineItemDetails accountId={bill.accountId} lineItem={lineItem} />
      <Td colSpan={5} fontSize="sm">
        <Text w="100%" textAlign="center">
          {t('features:billing.noUsageBreakdown')}
        </Text>
      </Td>
    </BillLineItemDetailsRow>
  );
};

function MemoLineItemBreakdown<DT extends DataType>({
  bill,
  billLineItemType,
  dataType,
  lineItem,
}: MemoLineItemBreakdownProps<DT>) {
  const { t } = useTranslation();
  const { toLongDate } = useDateFormatter();

  const isBillLocked = bill.locked;
  const isBillApproved = bill.status === BillStatus.Approved;
  const isCreditLineItem = dataType === DataType.CreditLineItem;

  const billLinkParams = useMemo<HighlightQueryParams | undefined>(
    () =>
      lineItem.lineItemType === billLineItemType &&
      lineItem.referencedLineItemId
        ? {
            highlightLineItem: lineItem.referencedLineItemId,
          }
        : undefined,
    [billLineItemType, lineItem]
  );

  const deletePathParams = useMemo(
    () => ({
      billId: bill.id,
    }),
    [bill]
  );

  const reasonDataType = useMemo(
    () => (isCreditLineItem ? DataType.CreditReason : DataType.DebitReason),
    [isCreditLineItem]
  );

  const reasonDescription = useMemo(
    () =>
      isCreditLineItem ? t('common:creditReason') : t('common:debitReason'),
    [isCreditLineItem, t]
  );

  const { deleteItem } = useEntityDelete(dataType, undefined, deletePathParams);

  const onDelete = useCallback(() => {
    deleteItem(lineItem);
  }, [lineItem, deleteItem]);

  return (
    <BillLineItemDetailsRow>
      <Td colSpan={2} />
      <Td colSpan={1}>
        <VStack alignItems="flex-start">
          {lineItem.reasonId && (
            <Text as="span">
              {reasonDescription}:{' '}
              <ReferenceLabel
                dataType={reasonDataType}
                id={lineItem.reasonId}
                accessor="name"
              />
            </Text>
          )}
          {lineItem.referencedBillId && (
            <Text as="span">
              {t('common:bill')}:{' '}
              <ReferenceLink
                dataType={DataType.Bill}
                id={lineItem.referencedBillId}
                accessor={(b) => toLongDate(b.externalInvoiceDate)}
                queryParams={billLinkParams}
              />
            </Text>
          )}
        </VStack>
      </Td>
      <Td>
        <ButtonGroup spacing={2}>
          <IconButton
            isDisabled={isBillLocked}
            aria-label={t('common:edit')}
            size="sm"
            icon={<FaPencilAlt />}
            as={CrudEditLink}
            addReturnPath
            dataType={dataType}
            id={lineItem.id}
          />
          <Confirmation
            trigger={
              <IconButton
                isDisabled={isBillApproved || isBillLocked}
                aria-label={t('common:delete')}
                size="sm"
                icon={<FaTrash />}
              />
            }
            onConfirm={onDelete}
          />
        </ButtonGroup>
      </Td>
      <Td colSpan={4} />
    </BillLineItemDetailsRow>
  );
}

export const BillLineItemBreakdown: React.FC<BillLineItemBreakdownProps> = ({
  lineItem,
  bill,
}) => {
  switch (lineItem.lineItemType) {
    case BillLineItemType.Usage:
    case BillLineItemType.OverageUsage:
    case BillLineItemType.CounterAdjustmentCredit:
    case BillLineItemType.CounterAdjustmentDebit:
    case BillLineItemType.CounterRunningTotalCharge:
      return <UsageLineItemBreakdown bill={bill} lineItem={lineItem} />;
    case BillLineItemType.CreditMemo:
      return (
        <MemoLineItemBreakdown
          bill={bill}
          billLineItemType={BillLineItemType.CreditMemo}
          dataType={DataType.CreditLineItem}
          lineItem={lineItem}
        />
      );
    case BillLineItemType.DebitMemo:
      return (
        <MemoLineItemBreakdown
          bill={bill}
          billLineItemType={BillLineItemType.DebitMemo}
          dataType={DataType.DebitLineItem}
          lineItem={lineItem}
        />
      );
    default:
      return (
        <BillLineItemDetailsRow>
          <BillLineItemDetails accountId={bill.accountId} lineItem={lineItem} />
          <Td colSpan={5} />
        </BillLineItemDetailsRow>
      );
  }
};
