import { useCallback, useMemo, useState } from 'react';

export interface PasswordValidationErrors {
  isLengthValid?: boolean;
  hasUpperCase?: boolean;
  hasLowerCase?: boolean;
  hasNumber?: boolean;
  hasNoLeadingOrTrailingSpace?: boolean;
  hasSpecialCharacterOrSpace?: boolean;
}

const usePasswordValidator = () => {
  const [passwordValidationErrors, setPasswordValidationErrors] =
    useState<PasswordValidationErrors>({});

  const onValidatePassword = useCallback((password: string) => {
    const isLengthValid = password.length >= 8;

    // Password complexity check
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasNoLeadingOrTrailingSpace = !/^\s|\s$/.test(password);
    const hasSpecialCharacterOrSpace =
      /[!@#$%^&*()_+\-={}[\]|;:,.?/~"'\s]/.test(password);

    setPasswordValidationErrors({
      isLengthValid,
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      hasNoLeadingOrTrailingSpace,
      hasSpecialCharacterOrSpace,
    });
  }, []);

  const isPasswordValid = useMemo(
    () => Object.values(passwordValidationErrors).every(Boolean),
    [passwordValidationErrors]
  );

  return {
    isPasswordValid,
    onValidatePassword,
    passwordValidationErrors,
  };
};

export default usePasswordValidator;
