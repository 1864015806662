import React, {
  useCallback,
  useMemo,
  PropsWithChildren,
  ReactElement,
} from 'react';

import ReactSelect, {
  createFilter,
  ActionMeta,
  MultiValue,
} from 'react-select';
import { useFormControl } from '@chakra-ui/react';

import useReactSelectStyles from '../../../../theme/hooks/useReactSelectStyles';
import { MultiSelectBaseProps, Option, PassthroughProps } from '../types';
import { getStringValue, isOptionNotOptionGroup } from '../utils';
import { OptionWithSecondaryLabel } from '../OptionWithSecondaryLabel';
import useSelectKeyDown from '../../../../hooks/useSelectKeyDown';

export interface MultiSelectProps<T extends string = string>
  extends MultiSelectBaseProps<T>,
    PassthroughProps {}

const customComponents = {
  Option: OptionWithSecondaryLabel,
};

export function MultiSelect<T extends string = string>({
  options,
  value,
  onChange,
  inputId,
  isSearchable = false,
  searchMatchFrom = 'start',
  ...selectProps
}: PropsWithChildren<MultiSelectProps<T>>): ReactElement<any, any> {
  const { onMenuOpen, onMenuClose, onSelectKeyDown } = useSelectKeyDown();

  const { styles, theme } = useReactSelectStyles<Option<T>>();

  // Get the ID from the Chakra `FormControl` if there is one, so that
  // the label and form field are connected.
  const { id } = useFormControl({});

  const filterOption = useMemo(
    () =>
      createFilter<Option<T>>({
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: searchMatchFrom,
        stringify: ({ data }) => getStringValue(data),
        trim: true,
      }),
    [searchMatchFrom]
  );

  const selectedOptions = useMemo(() => {
    if (!value?.length) {
      return [];
    }
    return options.filter(
      (option) => isOptionNotOptionGroup(option) && value.includes(option.value)
    ) as Array<Option<T>>;
  }, [options, value]);

  const handleChange = useCallback(
    (newOptions: MultiValue<Option<T>>, actionMeta: ActionMeta<Option<T>>) => {
      if (!newOptions?.length || actionMeta.action === 'clear') {
        onChange([]);
      } else {
        onChange(newOptions.map((o) => o.value));
      }
    },
    [onChange]
  );

  return (
    <ReactSelect<Option<T>, true>
      isMulti
      filterOption={filterOption}
      inputId={inputId ?? id}
      isSearchable={isSearchable}
      options={options}
      styles={styles}
      theme={theme}
      value={selectedOptions}
      onChange={handleChange}
      onKeyDown={onSelectKeyDown}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      components={customComponents}
      {...selectProps}
    />
  );
}
