import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  BillJob,
  BillJobFrequency,
  BillJobType,
  CurrencyCode,
  DateTimeISOString,
  Id,
} from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

import { uniq } from '@/util/array';

export interface BillJobsState {
  recalculatingBillIds: Array<Id>;
  runningBillJobsIds: Array<Id>;
  userInitiatedBillJobIds: Array<Id>;
  error?: AppError;
}

interface SetRunningBillJobsPayload {
  ids: Array<Id>;
}
export type SetRunningBillJobsAction = PayloadAction<SetRunningBillJobsPayload>;

type FailureAction = PayloadAction<undefined, string, never, AppError>;

interface GenerateBillsPayload {
  externalInvoiceDate: DateTimeISOString;
  billingFrequency?: BillJobFrequency;
  accountIds?: Array<Id>;
  targetCurrency?: CurrencyCode;
}
export type GenerateBillsAction = PayloadAction<GenerateBillsPayload>;

interface BillJobPayload {
  billJob: BillJob;
}
export type GenerateBillsSuccessAction = PayloadAction<BillJobPayload>;

interface RecalculateBillsPayload {
  billIds: Array<string>;
}
export type RecalculateBillsAction = PayloadAction<RecalculateBillsPayload>;

export type RecalculateBillsSuccessAction = PayloadAction<BillJobPayload>;

export type RecalculateBillsFailureAction = PayloadAction<
  RecalculateBillsPayload,
  string,
  never,
  AppError
>;

export type BillJobCompleteAction = PayloadAction<BillJobPayload>;

const name = `features/billing/billJobs`;

export const initialState: BillJobsState = {
  recalculatingBillIds: [],
  runningBillJobsIds: [],
  userInitiatedBillJobIds: [],
};

const addRecalculatingBillIds = (
  state: BillJobsState,
  billIds: Array<string>
) => uniq([...state.recalculatingBillIds, ...billIds]);

const removeRecalculatingBillIds = (
  state: BillJobsState,
  billIds: Array<string>
) => state.recalculatingBillIds.filter((billId) => !billIds.includes(billId));

const addBillJob = (state: BillJobsState, billJobId: Id) => {
  state.runningBillJobsIds.push(billJobId);
  state.userInitiatedBillJobIds.push(billJobId);
};

const billJobPrepare = (billJob: BillJob) => ({ payload: { billJob } });

const billJobsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setRunningBillJobs: {
      reducer: (state: BillJobsState, action: SetRunningBillJobsAction) => {
        state.runningBillJobsIds = action.payload.ids;
      },
      prepare: (ids: Array<Id>) => ({
        payload: { ids },
      }),
    },
    generateBills: {
      reducer: (state: BillJobsState, _action: GenerateBillsAction) => {
        state.error = undefined;
      },
      prepare: (
        externalInvoiceDate: DateTimeISOString,
        billingFrequency?: BillJobFrequency,
        accountIds?: Array<Id>,
        targetCurrency?: CurrencyCode
      ) => ({
        payload: {
          externalInvoiceDate,
          billingFrequency,
          accountIds,
          targetCurrency,
        },
      }),
    },
    generateBillsSuccess: {
      reducer: (state: BillJobsState, action: GenerateBillsSuccessAction) => {
        addBillJob(state, action.payload.billJob.id);
      },
      prepare: billJobPrepare,
    },
    generateBillsFailure: {
      reducer: (state: BillJobsState, action: FailureAction) => {
        state.error = action.error;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    recalculateBills: {
      reducer: (state: BillJobsState, action: RecalculateBillsAction) => {
        state.recalculatingBillIds = addRecalculatingBillIds(
          state,
          action.payload.billIds
        );
        state.error = undefined;
      },
      prepare: (billIds: Array<string>) => ({
        payload: { billIds },
      }),
    },
    recalculateBillsSuccess: {
      reducer: (
        state: BillJobsState,
        action: RecalculateBillsSuccessAction
      ) => {
        addBillJob(state, action.payload.billJob.id);
      },
      prepare: billJobPrepare,
    },
    recalculateBillsFailure: {
      reducer: (
        state: BillJobsState,
        action: RecalculateBillsFailureAction
      ) => {
        state.error = action.error;
        state.recalculatingBillIds = removeRecalculatingBillIds(
          state,
          action.payload.billIds
        );
      },
      prepare: (error: AppError, billIds: Array<Id>) => ({
        payload: { billIds },
        error,
      }),
    },
    billJobComplete: {
      reducer: (state: BillJobsState, action: BillJobCompleteAction) => {
        const { billJob } = action.payload;
        state.userInitiatedBillJobIds = state.userInitiatedBillJobIds.filter(
          (id) => id !== billJob.id
        );
        // Remove bills from `recalculatingBillIds` when relevant bill jobs complete.
        if (billJob.type === BillJobType.Recalculate && !!billJob.billIds) {
          state.recalculatingBillIds = removeRecalculatingBillIds(
            state,
            billJob.billIds!
          );
        }
      },
      prepare: billJobPrepare,
    },
  },
});

// Export actions.
export const {
  setRunningBillJobs,
  generateBills,
  generateBillsFailure,
  generateBillsSuccess,
  recalculateBills,
  recalculateBillsFailure,
  recalculateBillsSuccess,
  billJobComplete,
} = billJobsSlice.actions;

// Selectors.

const selectBillJobsState = (state: {
  features: { billing: { billJobs: BillJobsState } };
}) => state.features.billing.billJobs;

export const selectRunningBillJobIds = createSelector(
  selectBillJobsState,
  (state) => state.runningBillJobsIds
);

export const selectRecalculatingBillIds = createSelector(
  selectBillJobsState,
  (state) => state.recalculatingBillIds
);

export const selectIsBillBeingRecalculated = (billId: Id) =>
  createSelector(selectRecalculatingBillIds, (recalculatingBillIds) => {
    return recalculatingBillIds.includes(billId);
  });

export const selectBillJobsError = createSelector(
  selectBillJobsState,
  (state) => state.error
);

export const selectUserInitiatedBillJobIds = createSelector(
  selectBillJobsState,
  (state) => state.userInitiatedBillJobIds
);

export default billJobsSlice.reducer;
