import { ColorMode } from '@chakra-ui/react';
import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
  PrepareAction,
} from '@reduxjs/toolkit';

import { DataType, Id } from '@m3ter-com/m3ter-api';

import { createSelectByIds } from '@/store/data/data';

const VIEWED_DATA_TYPE_PAGE_LIMIT = 10;

interface BreadcrumbItem {
  to?: string;
  title: string;
  hideInTrail: boolean;
}

export type RecentEntities = Partial<Record<DataType, Array<Id>>>;

export interface PageState {
  breadcrumbs: Array<BreadcrumbItem>;
  recentEntities: RecentEntities;
  colorMode?: ColorMode;
}

export type AddBreadcrumbAction = PayloadAction<BreadcrumbItem>;
export type RemoveBreadcrumbAction = PayloadAction<BreadcrumbItem>;

interface AddToRecentEntitiesPayload {
  dataType: DataType;
  id: Id;
}
export type AddToRecentEntitiesAction =
  PayloadAction<AddToRecentEntitiesPayload>;

interface RestoreRecentEntitiesPayload {
  organizationId: string;
}
export type RestoreRecentEntitiesAction =
  PayloadAction<RestoreRecentEntitiesPayload>;

interface SetRecentEntitiesPayload {
  recentEntities: RecentEntities;
}
export type SetRecentEntitiesAction = PayloadAction<SetRecentEntitiesPayload>;

export type SetColorModeAction = PayloadAction<ColorMode>;

const name = 'page';

const initialState: PageState = {
  breadcrumbs: [],
  recentEntities: {},
};

const payloadPrepare = (title: string, hideInTrail: boolean, to?: string) => ({
  payload: { to, title, hideInTrail },
});

const pageSlice = createSlice({
  name,
  initialState,
  reducers: {
    addBreadcrumb: {
      reducer: (state: PageState, action: AddBreadcrumbAction) => {
        const { to, title, hideInTrail } = action.payload;
        state.breadcrumbs = [...state.breadcrumbs, { to, title, hideInTrail }];
      },
      prepare: payloadPrepare,
    },
    removeBreadcrumb: {
      reducer: (state: PageState, action: RemoveBreadcrumbAction) => {
        const { to, title } = action.payload;
        state.breadcrumbs = state.breadcrumbs.filter(
          (item) => item.to !== to || item.title !== title
        );
      },
      prepare: payloadPrepare,
    },
    addToRecentEntities: {
      reducer: (state: PageState, action: AddToRecentEntitiesAction) => {
        const { dataType, id } = action.payload;
        // Make sure we have a list for this dataType and remove the provided ID
        // from that list if it's already in there
        state.recentEntities[dataType] = (
          state.recentEntities[dataType] || []
        ).filter((storedId) => storedId !== id);
        // Put the new ID to the start of the list
        state.recentEntities[dataType]!.unshift(id);
        // Limit the new list to the max length
        state.recentEntities[dataType]!.length = Math.min(
          state.recentEntities[dataType]!.length,
          VIEWED_DATA_TYPE_PAGE_LIMIT
        );
      },
      prepare: (dataType: DataType, id: Id) => ({
        payload: { dataType, id },
      }),
    },
    setRecentEntities: {
      reducer: (state: PageState, action: SetRecentEntitiesAction) => {
        state.recentEntities = action.payload.recentEntities;
      },
      prepare: (recentEntities: RecentEntities) => ({
        payload: { recentEntities },
      }),
    },
    setColorMode: (state: PageState, action: SetColorModeAction) => {
      state.colorMode = action.payload;
    },
  },
});

// Export actions.
export const restoreRecentEntities = createAction<
  PrepareAction<RestoreRecentEntitiesPayload>
>('page/retoreRecentEntities', (organizationId) => ({
  payload: { organizationId },
}));
export const {
  addBreadcrumb,
  removeBreadcrumb,
  addToRecentEntities,
  setRecentEntities,
  setColorMode,
} = pageSlice.actions;

// Selectors

const selectPageState = (state: { [name]: PageState }): PageState =>
  state[name];

export const selectAllBreadcrumbs = createSelector(
  selectPageState,
  (state) => state.breadcrumbs
);

const selectShownBreadcrumbs = createSelector(
  selectAllBreadcrumbs,
  (breadcrumbs) => breadcrumbs.filter(({ hideInTrail }) => !hideInTrail)
);

export const selectBreadcrumbs = createSelector(
  selectShownBreadcrumbs,
  (breadcrumbs) => breadcrumbs.slice(0, -1)
);

export const selectTitle = createSelector(
  selectShownBreadcrumbs,
  (breadcrumbs) =>
    breadcrumbs.length > 0
      ? breadcrumbs[breadcrumbs.length - 1].title
      : undefined
);

export const selectAllRecentEntityIds = createSelector(
  selectPageState,
  (pageState) => pageState.recentEntities
);

export const selectRecentEntityIdsByDataType = (dataType: DataType) =>
  createSelector(
    selectAllRecentEntityIds,
    (recentEntities) => recentEntities[dataType] || []
  );

export const selectRecentEntitiesByDataType = (dataType: DataType) =>
  createSelector(
    selectRecentEntityIdsByDataType(dataType),
    createSelectByIds(dataType),
    (recentEntityIds, selectByIds) => selectByIds(recentEntityIds)
  );

export const selectColorMode = createSelector(
  selectPageState,
  (state) => state.colorMode
);

// Default export is the reducer itself.
export default pageSlice.reducer;
