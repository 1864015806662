import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { AccountPlansRoute } from '@/components/routes/accounts/AccountPlansRoute';
import { AccountPlansListRoute } from '@/components/routes/accounts/AccountPlansListRoute';
import { AccountPlanDetailsRoute } from '@/components/routes/accounts/AccountPlanDetailsRoute';
import { AccountPlansCreateRoute } from '@/components/routes/accounts/AccountPlansCreateRoute';
import { AccountPlansEditRoute } from '@/components/routes/accounts/AccountPlansEditRoute';
import { CreateCustomPlanRoute } from '@/components/routes/accounts/CreateCustomPlanRoute';
import { CreateCustomPlanGroupRoute } from '@/components/routes/accounts/CreateCustomPlanGroupRoute';

export default () =>
  createCrudRoute({
    path: 'plans',
    dataType: DataType.AccountPlan,
    element: <AccountPlansRoute />,
    list: <AccountPlansListRoute />,
    details: <AccountPlanDetailsRoute />,
    create: <AccountPlansCreateRoute />,
    edit: <AccountPlansEditRoute />,
    directChildRoutes: [
      {
        name: 'create-custom-plan',
        path: 'create-custom-plan',
        element: <CreateCustomPlanRoute />,
      },
      {
        name: 'create-custom-plan-group',
        path: 'create-custom-plan-group',
        element: <CreateCustomPlanGroupRoute />,
      },
    ],
  });
