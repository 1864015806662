import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  selectProducts,
  selectSelectedProduct,
  selectSelectedProductId,
  selectSelectedProductIdIfNotGlobal,
  setSelectedProduct,
} from '@/store/products/products';

const useProducts = () => {
  const dispatch = useDispatch();

  const products = useSelector(selectProducts);
  const selectedProductId = useSelector(selectSelectedProductId);
  const selectedProductIdIfNotGlobal = useSelector(
    selectSelectedProductIdIfNotGlobal
  );
  const selectedProduct = useSelector(selectSelectedProduct);

  const selectProduct = useCallback(
    (id: string | null) => {
      dispatch(setSelectedProduct(id));
    },
    [dispatch]
  );

  return {
    products,
    selectedProductId,
    selectedProductIdIfNotGlobal,
    selectedProduct,
    selectProduct,
  };
};

export default useProducts;
