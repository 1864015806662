import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AggregationSegment, Id } from '@m3ter-com/m3ter-api';

import {
  loadPricingSchedule,
  reset,
  selectAggregation,
  selectCompoundAggregation,
  selectError,
  selectIsLoading,
  selectItemCounter,
  selectItemCounterPricings,
  selectPlan,
  selectPlanTemplate,
  selectPricings,
} from '@/store/features/pricing/pricingSchedule';

export interface PricingScheduleProps {
  planId?: Id;
  planTemplateId?: Id;
  aggregationId?: Id;
  compoundAggregationId?: Id;
  counterId?: Id;
  segment?: AggregationSegment;
}

const usePricingSchedule = ({
  planId,
  planTemplateId,
  aggregationId,
  compoundAggregationId,
  counterId,
  segment,
}: PricingScheduleProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      loadPricingSchedule(
        planId,
        planTemplateId,
        aggregationId,
        compoundAggregationId,
        counterId,
        segment
      )
    );

    return () => {
      dispatch(reset());
    };
  }, [
    dispatch,
    planId,
    planTemplateId,
    aggregationId,
    compoundAggregationId,
    counterId,
    segment,
  ]);

  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);

  const pricings = useSelector(selectPricings);
  const itemCounterPricings = useSelector(selectItemCounterPricings);
  const plan = useSelector(selectPlan);
  const planTemplate = useSelector(selectPlanTemplate);
  const aggregation = useSelector(selectAggregation);
  const compoundAggregation = useSelector(selectCompoundAggregation);
  const itemCounter = useSelector(selectItemCounter);

  return {
    isLoading,
    error,
    pricings,
    itemCounterPricings,
    plan,
    planTemplate,
    aggregation,
    compoundAggregation,
    itemCounter,
  };
};

export default usePricingSchedule;
