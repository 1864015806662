import React, { ReactElement } from 'react';

import { TableCellProps, Td } from '@chakra-ui/react';

import { ColumnDefinition } from './types';
import { getAccessorValue } from './utils';

export interface DataTableCellProps<D> extends TableCellProps {
  item: D;
  columnDefinition: ColumnDefinition<D>;
}

export function DataTableCell<D>({
  item,
  columnDefinition,
  ...tdProps
}: DataTableCellProps<D>): ReactElement<any, any> | null {
  return (
    <Td {...tdProps} textAlign={columnDefinition.align}>
      {getAccessorValue(item, columnDefinition.accessor)}
    </Td>
  );
}
