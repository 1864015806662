import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ExternalMapping } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudQueryParams } from '@/types/routes';

import {
  createExternalMappings,
  selectExternalMappingsCreateError,
  selectIsSavingExternalMappings,
} from '@/store/features/integrations/external-mappings/externalMappingsCreateForm';
import { useCrudContext } from '@/components/common/crud/CrudContext';
import useQueryString from '@/hooks/navigation/useQueryString';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import {
  ExternalMappingCreateForm,
  ExternalMappingCreateFormValues,
  ExternalMappingCreateMode,
} from '@/components/features/external-mappings/ExternalMappingForm';

export const ExternalMappingsBulkCreateRoute: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const error = useSelector(selectExternalMappingsCreateError);
  const isSaving = useSelector(selectIsSavingExternalMappings);
  const { basePath, onCancel } = useCrudContext<ExternalMapping>();
  const { returnPath = basePath } = useQueryString<CrudQueryParams>();

  const onSave = useCallback(
    (data: ExternalMappingCreateFormValues) => {
      dispatch(createExternalMappings(data, returnPath));
    },
    [dispatch, returnPath]
  );

  return (
    <React.Fragment>
      <BreadcrumbItem title={t('common:createInBulk')} />
      {error && (
        <ErrorAlert
          title={t('errors:generic.problemSavingData', {
            entityName: t('common:externalMapping'),
          })}
          error={error}
          mb={4}
        />
      )}
      <ExternalMappingCreateForm
        isSaving={isSaving}
        mode={ExternalMappingCreateMode.Multiple}
        onCancel={onCancel}
        onSave={onSave}
      />
    </React.Fragment>
  );
};
