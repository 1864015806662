import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AnalyticsJobTypeToResponseData } from '@m3ter-com/m3ter-api';

import { ReportType, ReportTypeToFilterState } from '@/types/data';

import {
  loadReportData as loadReportDataAction,
  resetReportState as resetReportStateAction,
  selectReportState,
  ReportsStateDatum,
} from '@/store/features/analytics/reports/reports';

type UseReportStateReturn<RT extends ReportType> = ReportsStateDatum<RT> & {
  loadReportData: (
    filterState: ReportTypeToFilterState[RT],
    loadExportUrl?: boolean
  ) => void;
  resetReportState: () => void;
};

const useReportData = <RT extends ReportType>(
  reportType: RT
): UseReportStateReturn<RT> => {
  const dispatch = useDispatch();

  const reportStateSelector = useMemo(
    () => selectReportState(reportType),
    [reportType]
  );
  const reportState = useSelector(reportStateSelector);

  const loadReportData = useCallback(
    (filterState: ReportTypeToFilterState[RT], loadExportUrl = true) => {
      dispatch(loadReportDataAction(reportType, filterState, loadExportUrl));
    },
    [reportType, dispatch]
  );

  const resetReportState = useCallback(() => {
    dispatch(resetReportStateAction(reportType));
  }, [reportType, dispatch]);

  return {
    error: reportState?.error,
    isExporting: reportState?.isExporting ?? false,
    isLoading: reportState?.isLoading ?? false,
    response: reportState?.response as
      | AnalyticsJobTypeToResponseData[RT]
      | undefined,
    exportUrl: reportState?.exportUrl,
    loadReportData,
    resetReportState,
  };
};

export default useReportData;
