import React, { useMemo } from 'react';

import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  ColorMode,
  forwardRef,
  useColorMode,
} from '@chakra-ui/react';

export type Intent = 'default' | 'primary' | 'warning' | 'danger';

export interface ButtonProps extends ChakraButtonProps {
  intent?: Intent;
}

const darkColourSchemes: Record<Intent, string | undefined> = {
  default: undefined,
  primary: 'green',
  warning: 'orange',
  danger: 'red',
};

const lightColourSchemes: Record<Intent, string | undefined> = {
  default: undefined,
  primary: 'green',
  warning: 'orange',
  danger: 'red',
};

export const getColorScheme = (
  intent: Intent,
  colorMode: ColorMode
): string | undefined => {
  return (colorMode === 'dark' ? darkColourSchemes : lightColourSchemes)[
    intent
  ];
};

export const Button = forwardRef<ButtonProps, 'button'>(
  ({ intent = 'default', ...props }, ref) => {
    const { colorMode } = useColorMode();
    const colorScheme = useMemo(
      () => getColorScheme(intent, colorMode),
      [intent, colorMode]
    );

    return <ChakraButton ref={ref} colorScheme={colorScheme} {...props} />;
  }
);
