import { DataType, Id } from '@m3ter-com/m3ter-api';

import { dataTypeListAllQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useAppQuery from '@/hooks/data/useAppQuery';

const useAccountChildren = (
  accountId: Id | undefined,
  pageSize: number = 1
) => {
  const pathParams = useOrgPathParams({ accountId });

  const {
    isLoading: isLoadingAccountChildren,
    data: accountChildrenResponse,
    error: accountChildrenError,
  } = useAppQuery(
    dataTypeListAllQuery(
      {
        dataType: DataType.Account,
        actionName: 'listChildren',
        pathParams,
        queryParams: { pageSize },
      },
      { enabled: !!accountId }
    )
  );

  return {
    accountChildren: accountChildrenResponse ?? [],
    isLoadingAccountChildren,
    accountChildrenError,
  };
};

export default useAccountChildren;
