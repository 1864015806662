import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { DateTimeISOString } from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

export enum BillListStatus {
  All = 'ALL',
  Locked = 'LOCKED',
  Unlocked = 'UNLOCKED',
}

export interface BillsListState {
  isApprovingAllBills: boolean;
  selectedStartDate: DateTimeISOString | null;
  selectedEndDate: DateTimeISOString | null;
  selectedStatus: BillListStatus;
  error?: AppError;
}

export interface ApproveAllBillsPayload {
  externalInvoiceDateStart: DateTimeISOString;
  externalInvoiceDateEnd: DateTimeISOString;
}

export type ApproveAllBillsAction = PayloadAction<ApproveAllBillsPayload>;

export type ApproveAllBillsFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

export type GenerateBillsFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

interface UpdateSelectedDatesPayload {
  start: string | null;
  end: string | null;
}

export type UpdateSelectedDatesAction =
  PayloadAction<UpdateSelectedDatesPayload>;

interface UpdateSelectedStatusPayload {
  status: BillListStatus;
}

export type UpdateSelectedStatusAction =
  PayloadAction<UpdateSelectedStatusPayload>;

const name = 'features/billing/billsList';

export const initialState: BillsListState = {
  isApprovingAllBills: false,
  selectedStartDate: null,
  selectedEndDate: null,
  selectedStatus: BillListStatus.All,
};

const billsListState = createSlice({
  name,
  initialState,
  reducers: {
    approveAllBills: {
      reducer: (state: BillsListState, _action: ApproveAllBillsAction) => {
        state.isApprovingAllBills = true;
      },
      prepare: (
        externalInvoiceDateStart: DateTimeISOString,
        externalInvoiceDateEnd: DateTimeISOString
      ) => ({
        payload: {
          externalInvoiceDateStart,
          externalInvoiceDateEnd,
        },
      }),
    },
    approveAllBillsSuccess: (state: BillsListState) => {
      state.error = undefined;
      state.isApprovingAllBills = false;
    },
    approveAllBillsFailure: {
      reducer: (
        state: BillsListState,
        action: ApproveAllBillsFailureAction
      ) => {
        state.isApprovingAllBills = false;
        state.error = action.error;
      },
      prepare: (error: AppError) => ({
        payload: undefined,
        error,
      }),
    },
    setSelectedDates: {
      reducer: (state: BillsListState, action: UpdateSelectedDatesAction) => {
        state.selectedStartDate = action.payload.start;
        state.selectedEndDate = action.payload.end;
      },
      prepare: (
        start: DateTimeISOString | null,
        end: DateTimeISOString | null
      ) => ({ payload: { start, end } }),
    },
    setSelectedStatus: {
      reducer: (state: BillsListState, action: UpdateSelectedStatusAction) => {
        state.selectedStatus = action.payload.status;
      },
      prepare: (status: BillListStatus) => ({ payload: { status } }),
    },
  },
});

// Actions
export const loadInitialData = createAction(`${name}/loadInitialData`);

export const {
  approveAllBills,
  approveAllBillsFailure,
  approveAllBillsSuccess,
  setSelectedDates,
  setSelectedStatus,
} = billsListState.actions;

// Selectors
const selectBillsListState = (state: {
  features: { billing: { billsList: BillsListState } };
}) => state.features.billing.billsList;

export const selectSelectedStartDate = createSelector(
  selectBillsListState,
  (state) => state.selectedStartDate
);

export const selectSelectedEndDate = createSelector(
  selectBillsListState,
  (state) => state.selectedEndDate
);

export const selectSelectedStatus = createSelector(
  selectBillsListState,
  (state) => state.selectedStatus
);

export const selectIsApprovingAll = createSelector(
  selectBillsListState,
  (state) => state.isApprovingAllBills
);

// Export the slice reducer
export default billsListState.reducer;
