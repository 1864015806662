import React, { ComponentProps } from 'react';

import { Checkbox } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';

import { SelectOption } from '@m3ter-com/ui-components';

interface CrudListCheckboxFilterProps
  extends Omit<ComponentProps<typeof Checkbox>, 'value' | 'onChange'> {
  name: string;
  option: SelectOption;
  comparatorOptions: Array<SelectOption>;
}

export const CrudListCheckboxFilter: React.FC<CrudListCheckboxFilterProps> = ({
  name,
  option,
  comparatorOptions,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: {
          value = { comparator: comparatorOptions[0].value, value: '' },
          onChange,
        },
      }) => (
        <Checkbox
          value={option.value}
          isChecked={!!value.value}
          onChange={(event) =>
            onChange({ ...value, value: event.target.checked || '' })
          }
          {...props}
        >
          {option.label}
        </Checkbox>
      )}
    />
  );
};
