import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { ForgotPasswordFormValues } from '@m3ter-com/console-core/types';

const suite = create(
  'forgotPasswordForm',
  (data: Partial<ForgotPasswordFormValues>) => {
    test(
      'email',
      i18next.t('forms:validations.users.emailAddressRequired'),
      () => {
        enforce(data.email).isNotEmpty();
      }
    );
  }
);

export default suite;
