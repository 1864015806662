import React, { PropsWithChildren, ReactNode } from 'react';

import { Box, useMultiStyleConfig } from '@chakra-ui/react';

export interface AppLayoutProps {
  header: ReactNode;
  actions?: ReactNode;
  navigation?: ReactNode;
  navigationBottomPanel?: ReactNode;
}

export const AppLayout: React.FC<PropsWithChildren<AppLayoutProps>> = ({
  navigation,
  header,
  actions,
  navigationBottomPanel,
  children,
}) => {
  const styles = useMultiStyleConfig('AppLayout', {});

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.navigation}>
        {navigation}
        <Box sx={styles.navigationBottomPanel}>{navigationBottomPanel}</Box>
      </Box>
      <Box as="main" sx={styles.page}>
        <Box sx={styles.header}>{header}</Box>
        {actions && <Box sx={styles.actions}>{actions}</Box>}
        <Box sx={styles.content}>{children}</Box>
      </Box>
    </Box>
  );
};
