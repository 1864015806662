import React, { PropsWithChildren, ReactNode } from 'react';

import { CardHeader, CardHeaderProps, Flex } from '@chakra-ui/react';

export interface CardActionsHeaderProps extends CardHeaderProps {
  actions: ReactNode;
}

export const CardActionsHeader: React.FC<
  PropsWithChildren<CardActionsHeaderProps>
> = ({ actions, children, ...cardHeaderProps }) => {
  return (
    <CardHeader py={3} {...cardHeaderProps}>
      <Flex alignItems="center" gap={4} justifyContent="space-between">
        {children}
        {actions}
      </Flex>
    </CardHeader>
  );
};
