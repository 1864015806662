import React, { PropsWithChildren } from 'react';

import { Badge } from '@chakra-ui/react';

export const ButtonBadge: React.FC<PropsWithChildren> = ({ children }) => (
  <Badge
    position="absolute"
    bottom="-4px"
    right="-4px"
    zIndex="2"
    colorScheme="green"
    borderRadius={1000}
    px={2}
    pointerEvents="none"
  >
    {children}
  </Badge>
);
