import React, { useMemo } from 'react';

import { Link } from '@chakra-ui/react';

import { Bill, IntegrationRunLog } from '@m3ter-com/m3ter-api';

import { ExternalIcon } from '@/components/common/navigation/ExternalIcon';

export interface ExternalInvoiceLinkProps {
  bill: Bill;
  latestIntegrationRunData?: IntegrationRunLog;
}

export const ExternalInvoiceLink: React.FC<ExternalInvoiceLinkProps> = ({
  bill,
  latestIntegrationRunData,
}) => {
  // If we have an external invoice reference we can use that as the text.
  // Fall back to the name of the integration, the external ID or finally to `-`.
  const text = useMemo(
    () =>
      bill.externalInvoiceReference ??
      latestIntegrationRunData?.destination ??
      latestIntegrationRunData?.externalId ??
      '-',
    [bill, latestIntegrationRunData]
  );

  // Add an external link if there is a URL in the integration data.
  return latestIntegrationRunData?.url ? (
    <Link
      href={latestIntegrationRunData.url}
      isExternal
      title={latestIntegrationRunData?.destination}
    >
      {text}
      <ExternalIcon />
    </Link>
  ) : (
    // Without the Fragment the return type doesn't match `React.FC`
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment>{text}</React.Fragment>
  );
};
