import { ComponentSingleStyleConfig } from '@chakra-ui/react';

const notification: ComponentSingleStyleConfig = {
  baseStyle: {
    alignItems: 'start',
    width: 'auto',
    boxShadow: 'md',
    borderRadius: 'md',
    textAlign: 'left',
    pointerEvents: 'auto',
  },
};

export default notification;
