import React, { useMemo, ReactNode } from 'react';

import { Text } from '@chakra-ui/react';

import { Address } from '@m3ter-com/m3ter-api';

export interface AddressDetailsProps {
  data: Address;
}

const orderedAddressKeys: Array<keyof Address> = [
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'addressLine4',
  'locality',
  'region',
  'postCode',
  'country',
];

export const AddressDetails: React.FC<AddressDetailsProps> = ({ data }) => {
  const addressRows = useMemo(
    () =>
      orderedAddressKeys.reduce((result, key) => {
        const value = data[key];
        if (value) {
          result.push(<Text key={`address-row-${key}`}>{value}</Text>);
        }

        return result;
      }, new Array<ReactNode>()),
    [data]
  );

  // Without the Fragment the return type doesn't match `React.FC`
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <React.Fragment>{addressRows}</React.Fragment>;
};
