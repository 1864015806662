import React, { useMemo } from 'react';

import { ExternalMappingEntityType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  FormField,
  FormInput,
  FormSelect,
} from '@m3ter-com/console-core/components';
import { SelectOption } from '@m3ter-com/ui-components';

import useExternalMappingM3terEntityOptions from '@/hooks/features/externalMappings/useExternalMappingM3terEntityOptions';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch';

export interface ExternalMappingM3terIdFieldProps {
  name: string;
  externalSystem?: string;
  externalTable?: string;
  initialM3terId?: string;
  m3terEntityType?: ExternalMappingEntityType;
  optionsFilter?: (option: SelectOption) => boolean;
  hideLabel?: boolean;
}

export const ExternalMappingM3terIdField: React.FC<
  ExternalMappingM3terIdFieldProps
> = ({
  externalSystem,
  externalTable,
  initialM3terId,
  m3terEntityType,
  name,
  optionsFilter,
  hideLabel = false,
}) => {
  const { t } = useTranslation();
  const { entityOptions, error, isLoading } =
    useExternalMappingM3terEntityOptions(
      m3terEntityType,
      externalSystem,
      externalTable,
      initialM3terId
    );
  const availableEntityOptions = useMemo<Array<SelectOption>>(
    () => (optionsFilter ? entityOptions.filter(optionsFilter) : entityOptions),
    [entityOptions, optionsFilter]
  );

  return (
    <LoadingErrorContentSwitch isLoading={isLoading} error={error}>
      {availableEntityOptions.length > 0 ? (
        <FormField
          isRequired
          name={name}
          label={t('forms:labels.m3terEntity')}
          control={FormSelect}
          isClearable
          isSearchable
          options={availableEntityOptions}
          searchMatchFrom="any"
          hideLabel={hideLabel}
        />
      ) : (
        <FormField
          isRequired
          name={name}
          label={t('forms:labels.m3terEntity')}
          control={FormInput}
          hideLabel={hideLabel}
        />
      )}
    </LoadingErrorContentSwitch>
  );
};
