import {
  DefaultError,
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

import { AppError } from '@/types/errors';

import { extractError } from '@/util/error';

// Wrapper around RQ's `useMutation` to extract errors into `AppError`s.

interface UseAppMutationResult<
  TData = unknown,
  TError = DefaultError,
  TVariables = unknown,
  TContext = unknown
> extends Omit<
    UseMutationResult<TData, TError, TVariables, TContext>,
    'error'
  > {
  error: AppError | undefined;
}

const useAppMutation = <
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown
>(
  options: UseMutationOptions<TData, TError, TVariables, TContext>
): UseAppMutationResult<TData, TError, TVariables, TContext> => {
  const result = useMutation(options);

  return {
    ...result,
    error: result.error ? extractError(result.error) : undefined,
  };
};

export default useAppMutation;
