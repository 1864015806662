import { StructuredTextGraphQlResponse } from 'react-datocms';

import { DateString } from '@m3ter-com/m3ter-api';

export enum RecordTypes {
  DocumentationPage = 'DocumentationPageRecord',
  BlogPost = 'BlogPostRecord',
}

export interface DocsPage {
  id: string;
  slug: string;
  title: string;
  children?: Array<DocsPage>;
  parent?: DocsPage;
}

export interface BlogPost {
  id: string;
  slug: string;
  title: string;
}

export interface WhatsNew {
  id: string;
  date: DateString;
  content: StructuredTextGraphQlResponse;
  title: string;
}
