import React, { useMemo } from 'react';

import { Link, List, ListItem, SimpleGrid } from '@chakra-ui/react';

import { DataType, Customer } from '@m3ter-com/m3ter-api';
import { KeyValue } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { DetailsCard } from '@/components/common/data/DetailsCard';
import useAppQuery from '@/hooks/data/useAppQuery';
import { dataTypeListAllQuery } from '@/queries/crud';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';

export interface CustomerDetailsProps {
  data: Customer;
}

export const CustomerDetails: React.FC<CustomerDetailsProps> = ({
  data: customer,
}) => {
  const { t } = useTranslation();
  const { data: organizations = [] } = useAppQuery(
    dataTypeListAllQuery({ dataType: DataType.OrganizationAdmin })
  );

  const customerOrganizations = useMemo(
    () => organizations.filter(({ customerId }) => customerId === customer.id),
    [customer, organizations]
  );

  return (
    <DetailsCard
      showEditButton
      data={customer}
      dataType={DataType.Customer}
      details={
        <SimpleGrid columns={2} gap={6} w="100%">
          <KeyValue label={t('forms:labels.name')} value={customer.name} />
          <KeyValue
            label={t('common:organizations')}
            value={
              customerOrganizations.length > 0 ? (
                <List>
                  {customerOrganizations.map((organization) => (
                    <ListItem key={organization.id}>
                      <Link
                        as={CrudDetailsLink}
                        dataType={DataType.OrganizationAdmin}
                        id={organization.id}
                      >
                        {organization.organizationName}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              ) : (
                '-'
              )
            }
          />
        </SimpleGrid>
      }
    />
  );
};
