import React from 'react';

import { HStack, Switch, SwitchProps, Text } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

export interface FormSwitchProps extends SwitchProps {
  name: string;
  switchLabel?: string;
}

export const FormSwitch: React.FC<FormSwitchProps> = ({
  name,
  switchLabel,
  ...props
}) => {
  const { register } = useFormContext();
  return (
    <HStack justifyContent="flex-start" alignItems="flex-start" spacing={4}>
      {switchLabel && <Text fontSize="sm">{switchLabel}</Text>}
      <Switch {...register(name, { disabled: props.isDisabled })} {...props} />
    </HStack>
  );
};
