import React from 'react';

import { Image, ImageProps, useColorModeValue } from '@chakra-ui/react';

export interface LogoProps extends ImageProps {}

export const Logo: React.FC<LogoProps> = (imageProps) => {
  const logoSrc = useColorModeValue(
    '/m3ter-logo-blue.svg',
    '/m3ter-logo-white.svg'
  );

  return <Image src={logoSrc} alt="m3ter" width="120px" {...imageProps} />;
};
