import { all, call, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import i18next from 'i18next';

import {
  DataType,
  Aggregation,
  CompoundAggregation,
  Id,
  Counter,
  Meter,
  Plan,
  PlanTemplate,
} from '@m3ter-com/m3ter-api';

import { extractError } from '@/util/error';
import { retrieveData } from '@/store/data/data.saga';

import {
  loadPricingRelatedDataSuccess,
  loadPricingRelatedDataFailure,
  LoadPricingRelatedDataAction,
  loadPricingRelatedData,
} from './pricingData';

export function* loadPricingRelatedDataSaga(
  action: LoadPricingRelatedDataAction
): Generator<StrictEffect, void, any> {
  const {
    aggregationId,
    compoundAggregationId,
    counterId,
    planId,
    planTemplateId,
  } = action.payload;

  try {
    if (!planId && !planTemplateId) {
      const missingPlanErrorMessage = i18next.t(
        'features:pricing.missingPlanErrorMessage'
      );
      throw new Error(missingPlanErrorMessage);
    }

    if (counterId) {
      yield call(
        loadItemCounterPricingRelatedDataSaga,
        counterId,
        planId,
        planTemplateId
      );
      return;
    }

    // 'counterId' will also be undefined here due to the previous statement so no need to check again, i.e.
    // all pricing usage entities will be undefined at this point if there is no 'aggregationId' or 'compoundAggregationId'.
    if (!aggregationId && !compoundAggregationId) {
      const missingUsageEntityErrorMessage = i18next.t(
        'features:pricing.missingUsageEntityErrorMessage'
      );
      throw new Error(missingUsageEntityErrorMessage);
    }

    let plan: Plan | undefined;
    if (planId) plan = yield call(retrieveData, DataType.Plan, planId);
    // If no plan was loaded and we haven't thrown an error, planTemplateId must be defined.
    const templateId = plan ? plan.planTemplateId : planTemplateId!;
    const planTemplate: PlanTemplate = yield call(
      retrieveData,
      DataType.PlanTemplate,
      templateId
    );

    const aggregationPromises = [];
    aggregationPromises.push(
      aggregationId
        ? call(retrieveData, DataType.Aggregation, aggregationId)
        : call(() => undefined)
    );
    if (compoundAggregationId)
      aggregationPromises.push(
        call(retrieveData, DataType.CompoundAggregation, compoundAggregationId)
      );
    const [aggregation, compoundAggregation]: [
      Aggregation | undefined,
      CompoundAggregation | undefined
    ] = yield all(aggregationPromises);

    // Load the meter for the aggregation if it exists
    let meter: Meter | undefined;
    if (aggregation)
      meter = yield call(retrieveData, DataType.Meter, aggregation.meterId);

    yield put(
      loadPricingRelatedDataSuccess(
        aggregation?.id,
        compoundAggregation?.id,
        meter?.id,
        undefined,
        plan?.id,
        planTemplate?.id
      )
    );
  } catch (error) {
    yield put(loadPricingRelatedDataFailure(extractError(error)));
  }
}

export function* loadItemCounterPricingRelatedDataSaga(
  counterId: Id,
  planId?: Id,
  planTemplateId?: Id
): Generator<StrictEffect, void, any> {
  try {
    let plan: Plan | undefined;
    if (planId) {
      plan = yield call(retrieveData, DataType.Plan, planId);
    }

    // If no plan was loaded and we haven't thrown an error, planTemplateId must be defined.
    const templateId = plan ? plan.planTemplateId : planTemplateId!;
    const planTemplate: PlanTemplate = yield call(
      retrieveData,
      DataType.PlanTemplate,
      templateId
    );

    const itemCounter: Counter = yield call(
      retrieveData,
      DataType.Counter,
      counterId
    );

    yield put(
      loadPricingRelatedDataSuccess(
        undefined,
        undefined,
        undefined,
        itemCounter.id,
        plan?.id,
        planTemplate?.id
      )
    );
  } catch (error) {
    yield put(loadPricingRelatedDataFailure(extractError(error)));
  }
}

export default function* pricingDataSaga() {
  yield takeEvery(loadPricingRelatedData.type, loadPricingRelatedDataSaga);
}
