import React, { useCallback, useMemo } from 'react';

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Button, HStack, Text, IconButton } from '@chakra-ui/react';

export interface PaginationProps {
  currentPage: number;
  pageCount: number;
  pageRange?: number;
  hasMore?: boolean;
  disabled?: boolean;
  backLabel?: string;
  nextLabel?: string;
  onChange: (page: number) => void;
  onNext?: () => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  hasMore = false,
  pageCount,
  pageRange = 2,
  disabled = false,
  backLabel = 'Back',
  nextLabel = 'Next',
  onChange,
  onNext,
}) => {
  const pages = useMemo(() => {
    const minPage = Math.max(currentPage - pageRange, 1);
    const maxPage = Math.min(currentPage + pageRange, pageCount);

    return Array.from({ length: maxPage - minPage + 1 }).map(
      (_, i) => i + minPage
    );
  }, [currentPage, pageCount, pageRange]);

  const onPreviousClick = useCallback(() => {
    if (currentPage > 1) {
      onChange(currentPage - 1);
    }
  }, [currentPage, onChange]);

  const onNextClick = useCallback(() => {
    if (currentPage < pageCount) {
      onChange(currentPage + 1);
    } else if (hasMore && onNext) {
      onNext();
    }
  }, [currentPage, pageCount, hasMore, onChange, onNext]);

  return (
    <HStack>
      <IconButton
        isDisabled={currentPage === 1 || disabled}
        aria-label={backLabel}
        icon={<FaChevronLeft />}
        onClick={onPreviousClick}
        data-testid="previous"
      />
      {pages[0] > 1 && <Text>…</Text>}
      {pages.map((page) => (
        <Button
          key={page}
          onClick={() => {
            onChange(page);
          }}
          isActive={page === currentPage}
          isDisabled={disabled}
          data-testid="page-button"
        >
          {page}
        </Button>
      ))}
      {hasMore && <Text>…</Text>}
      <IconButton
        isDisabled={disabled || (!hasMore && currentPage === pageCount)}
        aria-label={nextLabel}
        icon={<FaChevronRight />}
        onClick={onNextClick}
        data-testid="next"
      />
    </HStack>
  );
};
