import React from 'react';

import { NamedRoute } from '@/types/routes';

import { OrganizationOverviewRoute } from '@/components/routes/settings/organization/OrganizationOverviewRoute';
import { OrganizationConfigEditRoute } from '@/components/routes/settings/organization/OrganizationConfigEditRoute';

export default (): NamedRoute => ({
  name: 'settings.org.configuration',
  path: 'configuration',
  children: [
    { index: true, element: <OrganizationOverviewRoute /> },
    {
      path: 'edit',
      name: 'settings.org.configuration.edit',
      element: <OrganizationConfigEditRoute />,
    },
  ],
});
