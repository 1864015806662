import { API, Auth } from 'aws-amplify';

import { EnvironmentConfig } from '@/config';
import { federatedSignInHelper } from '@/util/auth';

const setupAmplify = (config: EnvironmentConfig) => {
  Auth.configure({
    region: config.cognito.region,
    userPoolId: config.cognito.userPoolId,
    userPoolWebClientId: config.cognito.userPoolWebClientId,
    oauth: {
      domain: config.cognito.domain,
      redirectSignIn: `${window.location.protocol}//${window.location.host}`,
      redirectSignOut: `${window.location.protocol}//${window.location.host}`,
      responseType: 'code',
      urlOpener: federatedSignInHelper,
    },
  });

  API.configure({
    endpoints: [
      {
        name: 'api',
        endpoint: config.apiEndpoint,
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        }),
      },
      {
        name: 'ingest',
        endpoint: config.ingestEndpoint,
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        }),
      },
      {
        name: 'raw',
        endpoint: '',
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        }),
      },
    ],
  });
};

export default setupAmplify;
