import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Entity } from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

import { CompletionMeta, RequestMeta } from '@/store/store';

export interface FormState {
  isSaving: boolean;
  error?: AppError;
}

interface CreatePayload<FormValues> {
  formValues: FormValues;
}

export type CreateAction<FormValues> = PayloadAction<
  CreatePayload<FormValues>,
  string,
  RequestMeta
>;

interface CreateSuccessPayload<EntityType> {
  item: EntityType;
}

export type CreateSuccessAction<EntityType> = PayloadAction<
  CreateSuccessPayload<EntityType>,
  string,
  CompletionMeta
>;

export type CreateFailureAction = PayloadAction<
  void,
  string,
  CompletionMeta,
  AppError
>;

const initialState: FormState = {
  isSaving: false,
};

export const createFormSlice = <
  EntityType extends Entity,
  FormValues = Record<string, any>
>(
  name: string
) =>
  createSlice({
    name,
    initialState,
    reducers: {
      create: {
        reducer: (state: FormState, _action: CreateAction<FormValues>) => {
          state.isSaving = true;
          state.error = undefined;
        },
        prepare: (formValues: FormValues, redirectTo: string) => ({
          payload: { formValues },
          meta: { onSuccess: { redirectTo } },
        }),
      },
      createSuccess: {
        reducer: (
          state: FormState,
          _action: CreateSuccessAction<EntityType>
        ) => {
          state.isSaving = false;
        },
        prepare: (item: EntityType, meta?: any) => ({
          payload: { item },
          meta,
        }),
      },
      createFailure: {
        reducer: (state: FormState, action: CreateFailureAction) => {
          state.isSaving = false;
          state.error = action.error;
        },
        prepare: (error: AppError, meta?: any) => ({
          payload: undefined,
          meta,
          error,
        }),
      },
    },
  });

export default createFormSlice;
