import React, { useMemo } from 'react';

import { DataType, Bill, CreditLineItem } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { useCrudRetrieveData } from '@/components/common/crud/CrudRetrieveContext';
import { CreditLineItemForm } from '@/components/features/billing/CreditLineItemForm/CreditLineItemForm';

export const CreditLineItemsEditRoute: React.FC = () => {
  // These routes have to be under the bill retrieve route
  // so we can apply the credit to that bill.
  const bill = useCrudRetrieveData<Bill>(DataType.Bill);

  const pathParams = useMemo(
    () => ({
      billId: bill.id,
    }),
    [bill]
  );

  return (
    <CrudEdit<CreditLineItem>
      form={CreditLineItemForm}
      pathParams={pathParams}
    />
  );
};
