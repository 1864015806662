import React from 'react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { SearchBar } from '@m3ter-com/ui-components';

import useCrudListSearch from '@/hooks/data/crud/useCrudListSearch';

export const CrudListSearchBar: React.FC = () => {
  const { t } = useTranslation();
  const { clearSearch, entityNamings, search, searchFields, searchQuery } =
    useCrudListSearch();

  return searchFields?.length ? (
    <SearchBar
      onSearch={search}
      onClear={clearSearch}
      placeholder={t('common:searchEntity', {
        entity: entityNamings.plural,
      })}
      initialValue={searchQuery}
    />
  ) : null;
};
