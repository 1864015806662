import { create, enforce, group, omitWhen, test } from 'vest';
import i18next from 'i18next';

import { CreditLineItem } from '@m3ter-com/m3ter-api';

const suite = create('creditLineItem', (data: Partial<CreditLineItem>) => {
  omitWhen(!!data.productId, () => {
    test(
      'referencedBillId',
      i18next.t('forms:validations.memoLineItem.billRequired'),
      () => {
        enforce(data.referencedBillId).isNotEmpty();
      }
    );

    test(
      'referencedLineItemId',
      i18next.t('forms:validations.memoLineItem.lineItemRequired'),
      () => {
        enforce(data.referencedLineItemId).isNotEmpty();
      }
    );
  });

  omitWhen(!!data.referencedBillId || !!data.referencedLineItemId, () => {
    test(
      'productId',
      i18next.t('forms:validations.common.productRequired'),
      () => {
        enforce(data.productId).isNotEmpty();
      }
    );
  });

  test(
    'description',
    i18next.t('forms:validations.common.descriptionRequired'),
    () => {
      enforce(data.description).isNotEmpty();
    }
  );

  group('amountGroup', () => {
    test('amount', i18next.t('forms:validations.common.amountRequired'), () => {
      enforce(data.amount).isNotEmpty();
    });

    test(
      'amount',
      i18next.t('forms:validations.memoLineItem.amountPositive'),
      () => {
        enforce(data.amount).isPositive();
      }
    );
  });

  test(
    'creditReasonId',
    i18next.t('forms:validations.billing.reasonRequired'),
    () => {
      enforce(data.creditReasonId).isNotEmpty();
    }
  );
});

export default suite;
