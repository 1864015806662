import { EventTypes, Id } from '../types';

import { getOrganizationPath } from '../util/path';
import { get } from '../client';

export const getEventTypes = (organizationId: Id) =>
  get({
    path: getOrganizationPath('/events/types'),
    pathParams: { organizationId },
  }) as Promise<EventTypes>;
