import { takeLatest, call, put, StrictEffect } from 'redux-saga/effects';

import {
  retrieveSingletonData,
  updateSingletonData,
} from '@/store/data/data.saga';
import { extractError } from '@/util/error';

import {
  loadSingleton,
  loadSingletonSuccess,
  loadSingletonFailure,
  updateSingleton,
  updateSingletonSuccess,
  updateSingletonFailure,
  LoadSingletonAction,
  UpdateSingletonAction,
} from './singletons';

export function* loadSingletonSaga(
  action: LoadSingletonAction
): Generator<StrictEffect, void, any> {
  const { dataType } = action.payload;
  try {
    const response = yield call(retrieveSingletonData, dataType);
    yield put(loadSingletonSuccess(dataType, response));
  } catch (error) {
    yield put(loadSingletonFailure(dataType, extractError(error)));
  }
}

export function* updateSingletonSaga(
  action: UpdateSingletonAction
): Generator<StrictEffect, void, any> {
  const { dataType, data } = action.payload;
  const { onSuccess, onFailure } = action.meta;
  try {
    const response = yield call(updateSingletonData, dataType, data);
    yield put(updateSingletonSuccess(dataType, response, onSuccess));
  } catch (error) {
    yield put(updateSingletonFailure(dataType, extractError(error), onFailure));
  }
}

export default function* singletonsSaga() {
  yield takeLatest(loadSingleton.type, loadSingletonSaga);
  yield takeLatest(updateSingleton.type, updateSingletonSaga);
}
