import React, { useMemo } from 'react';

import { UserAdmin } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds } from '@/types/lists';

import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';

const searchFields = ['firstName', 'lastName', 'email', 'id'];

export const UserAdminListRoute: React.FC = () => {
  const { t } = useTranslation();

  const columns = useMemo<Array<ColumnDefinition<UserAdmin>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (item: UserAdmin) => `${item.firstName} ${item.lastName}`,
      },
      {
        id: 'firstName',
        header: t('forms:labels.firstName'),
        accessor: 'firstName',
        defaultHidden: true,
      },
      {
        id: 'lastName',
        header: t('forms:labels.lastName'),
        accessor: 'lastName',
        defaultHidden: true,
      },
      {
        id: 'email',
        header: t('forms:labels.emailAddress'),
        accessor: 'email',
      },
      {
        id: 'contactNumber',
        header: t('forms:labels.contactNumber'),
        accessor: 'contactNumber',
      },
      {
        id: 'id',
        header: t('forms:labels.userId'),
        accessor: 'id',
      },
    ],
    [t]
  );

  return (
    <CrudList<UserAdmin>
      listId={EntityRouteListIds.UserAdmin}
      searchFields={searchFields}
      columns={columns}
    />
  );
};
