import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
} from 'react';

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';

import { BadgeButton, BadgeIconButton } from '../../controls/Button';

export interface ButtonPanelProps {
  icon: ReactElement;
  title: string;
  addLabel?: boolean;
  badge?: ReactNode;
  onOpen?: () => void;
}

export const ButtonPanel: React.FC<PropsWithChildren<ButtonPanelProps>> = ({
  icon,
  title,
  addLabel = false,
  badge,
  onOpen,
  children,
}) => {
  const { isOpen, onOpen: openDrawer, onClose: closeDrawer } = useDisclosure();

  const onButtonClick = useCallback(() => {
    openDrawer();
    if (onOpen) {
      onOpen();
    }
  }, [onOpen, openDrawer]);

  return (
    <React.Fragment>
      {addLabel ? (
        <BadgeButton badge={badge} leftIcon={icon} onClick={onButtonClick}>
          {title}
        </BadgeButton>
      ) : (
        <BadgeIconButton
          aria-label={title}
          badge={badge}
          icon={icon}
          onClick={onButtonClick}
        />
      )}
      <Drawer isOpen={isOpen} placement="right" onClose={closeDrawer} size="sm">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            alignItems="center"
            display="flex"
            flexFlow="row nowrap"
            gap={2}
            justifyContent="flex-start"
          >
            {icon}
            <Heading as="h4" fontSize="xl">
              {title}
            </Heading>
          </DrawerHeader>
          <DrawerBody>{children}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </React.Fragment>
  );
};
