import React, { useCallback } from 'react';

import { Box, ButtonGroup, ButtonGroupProps } from '@chakra-ui/react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { IconButton } from '../../controls/Button';

import { useCalendarContext } from './CalendarContext';

export interface CalendarNavigationProps extends ButtonGroupProps {}

export const CalendarNavigation: React.FC<CalendarNavigationProps> = ({
  children,
  ...buttonGroupProps
}) => {
  const { goToNextMonth, goToNextYear, goToPreviousMonth, goToPreviousYear } =
    useCalendarContext();

  const onNextYearClick = useCallback(() => {
    goToNextYear(1);
  }, [goToNextYear]);

  const onPreviousYearClick = useCallback(() => {
    goToPreviousYear(1);
  }, [goToPreviousYear]);

  return (
    <ButtonGroup width="100%" alignItems="center" {...buttonGroupProps}>
      <IconButton
        data-testid="date-picker-calendar-prev-year-button"
        aria-label="Go to previous year"
        icon={
          <React.Fragment>
            <FaChevronLeft />
            <FaChevronLeft />
          </React.Fragment>
        }
        variant="ghost"
        onClick={onPreviousYearClick}
      />
      <IconButton
        data-testid="date-picker-calendar-prev-month-button"
        aria-label="Go to previous month"
        icon={<FaChevronLeft />}
        variant="ghost"
        onClick={goToPreviousMonth}
      />
      <Box flex={1}>{children}</Box>
      <IconButton
        data-testid="date-picker-calendar-next-month-button"
        aria-label="Go to next month"
        icon={<FaChevronRight />}
        variant="ghost"
        onClick={goToNextMonth}
      />
      <IconButton
        data-testid="date-picker-calendar-next-year-button"
        aria-label="Go to next year"
        icon={
          <React.Fragment>
            <FaChevronRight />
            <FaChevronRight />
          </React.Fragment>
        }
        variant="ghost"
        onClick={onNextYearClick}
      />
    </ButtonGroup>
  );
};
