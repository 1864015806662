import { useMemo, useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

import { DataType, Entity, PathParams } from '@m3ter-com/m3ter-api';

import {
  loadUpdateItem,
  updateItem as updateItemAction,
  selectUpdateEntity,
  selectUpdateState,
  resetUpdate,
} from '@/store/crud';

const useEntityUpdate = <E extends Entity = Entity>(
  dataType: DataType,
  id: string,
  returnPath?: string,
  pathParams?: PathParams
) => {
  const dispatch = useDispatch();

  // We need the query client to invalidate queries until we switch updates
  // to be a React Query mutation.
  const queryClient = useQueryClient();

  useEffect(() => {
    dispatch(loadUpdateItem(dataType, id, pathParams));

    return () => {
      dispatch(resetUpdate(dataType));
    };
  }, [dispatch, dataType, id, pathParams]);

  const itemDataSelector = useMemo(
    () => selectUpdateEntity(dataType),
    [dataType]
  );
  const itemData = useSelector(itemDataSelector) as E | undefined;

  // Memoize the selector otherwise it will be a new function per render.
  const updateStateSelector = useMemo(
    () => selectUpdateState(dataType),
    [dataType]
  );
  const updateState = useSelector(updateStateSelector);

  const updateItem = useCallback(
    (changes: Partial<E>) => {
      // Merge changes with the record to keep any unchanged fields, including the version.
      // TODO: Consider moving to saga, where we can select the canonical record.
      dispatch(
        updateItemAction(
          dataType,
          id,
          { ...itemData, ...changes } as Entity,
          undefined,
          undefined,
          returnPath,
          pathParams
        )
      );
      queryClient.invalidateQueries({ queryKey: [dataType] });
    },
    [dispatch, dataType, itemData, id, returnPath, pathParams, queryClient]
  );

  return {
    isLoading: !!updateState?.isLoading,
    isSaving: !!updateState?.isSaving,
    itemData,
    loadingError: updateState?.loadingError,
    savingError: updateState?.savingError,
    updateItem,
    updateState: updateState?.savingError,
  };
};

export default useEntityUpdate;
