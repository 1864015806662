import React from 'react';

import { DataType, Account } from '@m3ter-com/m3ter-api';

import { useCrudRetrieveData } from '@/components/common/crud/CrudRetrieveContext';
import { AccountPlans } from '@/components/features/accounts/AccountPlans';

export const AccountPlansListRoute: React.FC = () => {
  const account = useCrudRetrieveData<Account>(DataType.Account);

  return <AccountPlans account={account} />;
};
