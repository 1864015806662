import React, { PropsWithChildren } from 'react';

import { ChakraProvider } from '@chakra-ui/react';

import theme from '../../../theme';

/**
 * Theme provider to consistently configure and style the consoles.
 */
export const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <ChakraProvider theme={theme}>{children}</ChakraProvider>
);
