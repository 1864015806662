import type { ComponentMultiStyleConfig } from '@chakra-ui/react';

const parts = ['wrapper', 'navigation', 'page', 'header', 'actions', 'content'];

const appLayout: ComponentMultiStyleConfig = {
  parts,
  baseStyle: {
    wrapper: {
      width: '100vw',
      height: '100vh',
      display: 'grid',
      gridTemplateAreas: '"navigation page"',
      gridTemplateColumns: '300px auto',
      gridTemplateRows: '100vh',
      overflow: 'hidden',
    },
    navigation: {
      gridArea: 'navigation',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      paddingTop: 4,
      paddingRight: 0,
      paddingBottom: 4,
      paddingLeft: 4,
      bg: 'nav-bg',
      zIndex: 1,
      overflowY: 'scroll',
    },
    navigationBottomPanel: {
      width: '100%',
      paddingRight: 4,
    },
    page: {
      gridArea: 'page',
      position: 'relative',
      width: '100%',
      height: '100vh',
      display: 'grid',
      gridTemplateColumns: '60% 40%',
      gridTemplateRows: 'max-content auto',
      padding: 4,
      overflowX: 'hidden',
      overflowY: 'scroll',
    },
    header: {
      gridColumn: '1',
      gridRow: '1',
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    actions: {
      gridColumn: '2',
      gridRow: '1',
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
    content: {
      gridColumn: '1 / 3',
      gridRow: '2',
      marginTop: 8,
    },
  },
};

export default appLayout;
