import { queryOptions } from '@tanstack/react-query';

import {
  DataType,
  list,
  listAll,
  ListOptions,
  retrieve,
  RetrieveOptions,
} from '@m3ter-com/m3ter-api';

interface ExtraOptions {
  enabled?: boolean;
  refetchInterval?: number;
}

// Generic query creator to list all of the specified data type.
export const dataTypeListAllQuery = <DT extends DataType>(
  { dataType, actionName, pathParams, queryParams }: ListOptions<DT>,
  extraOptions?: ExtraOptions
) =>
  queryOptions({
    queryKey: [dataType, 'listAll', actionName, pathParams, queryParams],
    queryFn: () => listAll({ dataType, actionName, pathParams, queryParams }),
    select: (response) => response.data,
    ...extraOptions,
  });

export const dataTypeListQuery = <DT extends DataType>(
  { dataType, actionName, pathParams, queryParams }: ListOptions<DT>,
  extraOptions?: ExtraOptions
) =>
  queryOptions({
    queryKey: [dataType, 'list', actionName, pathParams, queryParams],
    queryFn: () => list({ dataType, actionName, pathParams, queryParams }),
    select: (response) => response.data,
    ...extraOptions,
  });

export const dataTypeRetrieveQuery = <DT extends DataType>(
  { dataType, id, actionName, pathParams, queryParams }: RetrieveOptions<DT>,
  extraOptions?: ExtraOptions
) =>
  queryOptions({
    queryKey: [dataType, 'retrieve', id, actionName, pathParams, queryParams],
    queryFn: () =>
      retrieve({ dataType, id, actionName, pathParams, queryParams }),
    ...extraOptions,
  });
