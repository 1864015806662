import React, { useMemo } from 'react';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { DropdownButton, DropdownButtonAction } from '@m3ter-com/ui-components';

import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink';
import { NamedLink } from '@/components/common/navigation/NamedLink';

export const AccountPlansListActions: React.FC = () => {
  const { t } = useTranslation();

  const actions = useMemo<Array<DropdownButtonAction>>(
    () => [
      {
        key: 'attach',
        label: t('forms:buttons.createEntity', {
          entityName: t('common:planAttachment'),
        }),
        as: CrudCreateLink,
        addReturnPath: true,
        dataType: DataType.AccountPlan,
      },
      {
        key: 'custom-plan',
        label: t('forms:buttons.createEntity', {
          entityName: t('common:customPlan'),
        }),
        as: NamedLink,
        name: 'create-custom-plan',
      },
      {
        key: 'custom-plan-group',
        label: t('forms:buttons.createEntity', {
          entityName: t('common:customPlanGroup'),
        }),
        as: NamedLink,
        name: 'create-custom-plan-group',
      },
    ],
    [t]
  );

  return <DropdownButton actions={actions} maxButtons={1} size="sm" />;
};
