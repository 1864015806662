import React, { useCallback, useEffect } from 'react';

import { IconButton, useColorMode, useColorModeValue } from '@chakra-ui/react';

import { FaMoon, FaSun } from 'react-icons/fa';

const colorModeLocalStorageKey = 'M3TER_COLOR_MODE';

export const ColorModeSwitcher: React.FC = () => {
  const { colorMode, setColorMode } = useColorMode();

  useEffect(() => {
    const colorModePreference = localStorage.getItem(colorModeLocalStorageKey);
    if (!colorModePreference) return;

    setColorMode(colorModePreference);
  }, [setColorMode]);

  const handleClick = useCallback(() => {
    const newColorMode = colorMode === 'light' ? 'dark' : 'light';
    localStorage.setItem(colorModeLocalStorageKey, newColorMode);
    setColorMode(newColorMode);
  }, [colorMode, setColorMode]);

  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  return (
    <IconButton
      aria-label="Toggle"
      icon={<SwitchIcon />}
      onClick={handleClick}
    />
  );
};
