import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Bill } from '@m3ter-com/m3ter-api';

import { isBillLocked as isBillLockedUtil } from '@/util/billing';
import {
  approveBill as approveBillAction,
  lockBills,
  selectBillingError,
  selectIsBillBeingUpdated,
} from '@/store/features/billing/billing';
import {
  recalculateBills,
  selectIsBillBeingRecalculated,
} from '@/store/features/billing/billJobs';
import { selectBillConfig } from '@/store/app/bootstrap/bootstrap';

const useBillView = (bill: Bill) => {
  const dispatch = useDispatch();

  const billId = bill.id;

  const billConfig = useSelector(selectBillConfig);
  const isUpdatingBillSelector = useMemo(
    () => selectIsBillBeingUpdated(billId),
    [billId]
  );
  const isUpdatingBill = useSelector(isUpdatingBillSelector);
  const isRecalculatingBillSelector = useMemo(
    () => selectIsBillBeingRecalculated(billId),
    [billId]
  );
  const isRecalculatingBill = useSelector(isRecalculatingBillSelector);
  const error = useSelector(selectBillingError);

  const isBillLocked = useMemo(
    () => isBillLockedUtil(bill, billConfig),
    [bill, billConfig]
  );

  const approveBill = useCallback(() => {
    dispatch(approveBillAction(billId));
  }, [dispatch, billId]);

  const lockBill = useCallback(() => {
    dispatch(lockBills([billId]));
  }, [dispatch, billId]);

  const recalculateBill = useCallback(() => {
    dispatch(recalculateBills([billId]));
  }, [dispatch, billId]);

  return {
    isBillLocked,
    isUpdatingBill,
    isRecalculatingBill,
    error,
    approveBill,
    lockBill,
    recalculateBill,
  };
};

export default useBillView;
