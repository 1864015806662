import React, { useMemo } from 'react';

import { MeasurementsDeletion } from '@m3ter-com/m3ter-api';

import useQueryString from '@/hooks/navigation/useQueryString';
import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { MeasurementsDeletionForm } from '@/components/features/admin/measurements-deletions/MeasurementsDeletionForm';

export interface MeasurementsDeletionsCreateRouteProps {}

interface CreateQuery {
  organizationId: string;
}

export const MeasurementsDeletionsCreateRoute: React.FC<
  MeasurementsDeletionsCreateRouteProps
> = () => {
  const { organizationId } = useQueryString<CreateQuery>();

  const pathParams = useMemo(
    () => ({
      organizationId,
    }),
    [organizationId]
  );

  return (
    <CrudCreate<MeasurementsDeletion>
      form={MeasurementsDeletionForm}
      pathParams={pathParams}
    />
  );
};
