import React from 'react';

import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react';

export interface WellProps extends BoxProps {}

export const Well: React.FC<WellProps> = ({ children, ...props }) => {
  const styles = useStyleConfig('Well');

  return (
    <Box __css={styles} {...props}>
      {children}
    </Box>
  );
};
