import { AnyAction } from 'redux';
import {
  call,
  put,
  StrictEffect,
  takeLatest,
  takeEvery,
  select,
} from 'redux-saga/effects';

import { DataType, Product } from '@m3ter-com/m3ter-api';

import {
  isBootstrapSuccessActionWithOrgId,
  selectCurrentOrgId,
} from '@/store/app/bootstrap/bootstrap';
import {
  selectSelectedProductId,
  setSelectedProduct,
  selectSelectedGlobal,
  restoreSelections,
  selectProducts,
} from '@/store/products/products';
import { dataCreated, DataCreatedAction } from '@/store/data/data';
import {
  getLastSelectedProductId,
  getSelectedGlobal,
  setLastSelectedProductId,
  setSelectedGlobal,
} from '@/util/localStorage';

export function* loadProductsSaga(): Generator<StrictEffect, void, any> {
  const products: Array<Product> = yield select(selectProducts);

  // Restore the selected product if it's valid.
  const organizationId = yield select(selectCurrentOrgId);
  const lastSelectedProductId = yield call(
    getLastSelectedProductId,
    organizationId
  );
  const selectedGlobal = yield call(getSelectedGlobal, organizationId);

  let newSelectedProductId;
  if (products.some(({ id }) => id === lastSelectedProductId)) {
    newSelectedProductId = lastSelectedProductId;
  } else if (products.length > 0) {
    newSelectedProductId = products[0].id;
  }

  if (newSelectedProductId) {
    yield put(restoreSelections(newSelectedProductId, selectedGlobal));
  }
}

export function* persistSelectedProductSaga(): Generator<
  StrictEffect,
  void,
  any
> {
  const selectedProductId = yield select(selectSelectedProductId);
  const selectedGlobal = yield select(selectSelectedGlobal);
  const organizationId = yield select(selectCurrentOrgId);
  yield call(setLastSelectedProductId, selectedProductId, organizationId);
  yield call(setSelectedGlobal, selectedGlobal, organizationId);
}

export function* dataCreatedSaga(
  action: DataCreatedAction
): Generator<StrictEffect, void, any> {
  const productId = action.payload.data[0].id;
  const selectedProductId = yield select(selectSelectedProductId);
  if (!selectedProductId) {
    yield put(setSelectedProduct(productId));
  }
}

export default function* productsSaga(): Generator<StrictEffect, void, any> {
  // When the org changes / bootstrapping is complete we need to update the selected product.
  yield takeLatest(isBootstrapSuccessActionWithOrgId, loadProductsSaga);
  yield takeLatest(setSelectedProduct.type, persistSelectedProductSaga);

  // If a new product is created we need to add it to the list.
  yield takeEvery(
    (action: AnyAction) =>
      action.type === dataCreated.type &&
      action.payload.dataType === DataType.Product,
    dataCreatedSaga
  );
}
