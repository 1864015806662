import {
  call,
  put,
  select,
  takeLatest,
  StrictEffect,
} from 'redux-saga/effects';

import { DataType, Bill, ListResponse, listAll } from '@m3ter-com/m3ter-api';

import { extractError } from '@/util/error';
import { ids } from '@/util/data';
import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';
import { dataLoaded } from '@/store/data/data';
import { listAllData } from '@/store/data/data.saga';

import {
  loadAccounts,
  loadAccountsFailure,
  loadAccountsSuccess,
  updateSelectedAccountId,
  UpdateSelectedAccountIdAction,
  updateSelectedAccountIdFailure,
  updateSelectedAccountIdSuccess,
} from './billHistory';

export function* loadAccountsSaga(): Generator<StrictEffect, void, any> {
  try {
    const allAccounts = yield call(listAllData, DataType.Account);
    const accountIds = ids(allAccounts);

    yield put(loadAccountsSuccess(accountIds));
  } catch (error) {
    yield put(loadAccountsFailure(extractError(error)));
  }
}

export function* updateSelectedAccountIdSaga(
  action: UpdateSelectedAccountIdAction
): Generator<StrictEffect, void, any> {
  const { accountId } = action.payload;

  try {
    const organizationId = yield select(selectCurrentOrgId);
    const accountBillsResponse: ListResponse<Bill> = yield call(listAll, {
      dataType: DataType.Bill,
      actionName: 'getAllBillsForAccount',
      pathParams: { organizationId, accountId },
    });
    const accountBills = accountBillsResponse.data;
    yield put(dataLoaded(DataType.Bill, accountBills));

    yield put(updateSelectedAccountIdSuccess(ids(accountBills)));
  } catch (error) {
    yield put(updateSelectedAccountIdFailure(extractError(error)));
  }
}

export default function* billHistorySaga() {
  yield takeLatest(loadAccounts.type, loadAccountsSaga);
  yield takeLatest(updateSelectedAccountId.type, updateSelectedAccountIdSaga);
}
