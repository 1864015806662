import { PayloadAction } from '@reduxjs/toolkit';
import {
  all,
  call,
  put,
  spawn,
  takeEvery,
  StrictEffect,
} from 'redux-saga/effects';

import { extractError } from '@/util/error';
import {
  createItem,
  CreateItemAction,
  createItemFailure,
  createItemSuccess,
  deleteItem,
  DeleteItemAction,
  deleteItemFailure,
  deleteItems,
  DeleteItemsAction,
  deleteItemsFailure,
  deleteItemsSuccess,
  deleteItemSuccess,
  loadItem,
  loadItemFailure,
  LoadItemPayload,
  loadItemSuccess,
  loadUpdateItem,
  LoadUpdateItemAction,
  loadUpdateItemFailure,
  loadUpdateItemSuccess,
  updateItem,
  UpdateItemAction,
  updateItemFailure,
  updateItemSuccess,
} from '@/store/crud';
import {
  createData,
  deleteData,
  retrieveData,
  updateData,
} from '@/store/data/data.saga';

import { loadRelatedData } from './crudUtils.saga';
import listSaga from './list.saga';

export function* loadItemSaga(
  action: PayloadAction<LoadItemPayload>
): Generator<StrictEffect, void, any> {
  const { dataType, id, pathParams, relationships } = action.payload;
  try {
    const response = yield call(retrieveData, dataType, id, pathParams);

    const relatedData = yield call(
      loadRelatedData,
      dataType,
      [response],
      relationships
    );

    yield put(loadItemSuccess(dataType, id, response, relatedData));
  } catch (error) {
    yield put(loadItemFailure(dataType, extractError(error)));
  }
}

export function* deleteItemSaga(
  action: DeleteItemAction
): Generator<StrictEffect, void, any> {
  const { dataType, id, pathParams } = action.payload;
  const { onSuccess, onFailure } = action.meta;

  try {
    yield call(deleteData, dataType, id, pathParams);
    yield put(deleteItemSuccess(dataType, id, onSuccess));
  } catch (error) {
    yield put(deleteItemFailure(dataType, id, extractError(error), onFailure));
  }
}

export function* deleteItemsSaga(
  action: DeleteItemsAction
): Generator<any, void, any> {
  const { dataType, ids: idsToDelete } = action.payload;
  const { onSuccess, onFailure } = action.meta;

  try {
    yield all(idsToDelete.map((id) => call(deleteData, dataType, id)));
    yield put(deleteItemsSuccess(dataType, idsToDelete, onSuccess));
  } catch (error) {
    yield put(
      deleteItemsFailure(dataType, idsToDelete, extractError(error), onFailure)
    );
  }
}

export function* createItemSaga(
  action: CreateItemAction
): Generator<StrictEffect, void, any> {
  const { dataType, item, pathParams } = action.payload;
  const { onSuccess, onFailure } = action.meta;

  try {
    const response = yield call(createData, dataType, item, pathParams);
    yield put(createItemSuccess(dataType, response, onSuccess));
  } catch (error) {
    yield put(createItemFailure(dataType, extractError(error), onFailure));
  }
}

export function* loadUpdateItemSaga(
  action: LoadUpdateItemAction
): Generator<StrictEffect, void, any> {
  const { dataType, id, pathParams } = action.payload;
  try {
    const response = yield call(retrieveData, dataType, id, pathParams);
    yield put(loadUpdateItemSuccess(dataType, id, response));
  } catch (error) {
    yield put(loadUpdateItemFailure(dataType, extractError(error)));
  }
}

export function* updateItemSaga(
  action: UpdateItemAction
): Generator<StrictEffect, void, any> {
  const { dataType, id, item, pathParams } = action.payload;
  const { onSuccess, onFailure } = action.meta;

  try {
    const response = yield call(updateData, dataType, id, item, pathParams);
    yield put(updateItemSuccess(dataType, id, response, onSuccess));
  } catch (error) {
    yield put(updateItemFailure(dataType, extractError(error), onFailure));
  }
}

export default function* crudSaga() {
  yield spawn(listSaga);

  yield takeEvery(createItem.type, createItemSaga);
  yield takeEvery(deleteItem.type, deleteItemSaga);
  yield takeEvery(deleteItems.type, deleteItemsSaga);
  yield takeEvery(loadItem.type, loadItemSaga);
  yield takeEvery(loadUpdateItem.type, loadUpdateItemSaga);
  yield takeEvery(updateItem.type, updateItemSaga);
}
