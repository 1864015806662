import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';

export interface BillInAdvanceFieldProps {
  name: string;
  defaultLabel?: string;
}

enum BillInAdvance {
  InArrears = 'arrears',
  InAdvance = 'advance',
}

export const BillInAdvanceField: React.FC<BillInAdvanceFieldProps> = ({
  name,
  defaultLabel,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => {
        let radioValue: string;
        if (value === true) {
          radioValue = BillInAdvance.InAdvance;
        } else if (value === false) {
          radioValue = BillInAdvance.InArrears;
        } else {
          radioValue = '';
        }

        return (
          <RadioGroup
            value={radioValue}
            onChange={(newValue) => {
              if (newValue === '') {
                onChange(undefined);
              } else {
                onChange(newValue === BillInAdvance.InAdvance);
              }
            }}
          >
            <Stack alignItems="start">
              {!!defaultLabel && <Radio value="">{defaultLabel}</Radio>}
              <Radio value={BillInAdvance.InArrears}>
                {t('forms:labels.billInArrears')}
              </Radio>
              <Radio value={BillInAdvance.InAdvance}>
                {t('forms:labels.billInAdvance')}
              </Radio>
            </Stack>
          </RadioGroup>
        );
      }}
    />
  );
};
