import React, { useMemo } from 'react';

import { Card, CardHeader, CardBody, Flex, Heading } from '@chakra-ui/react';

import { DataType, Id, NotificationEvent } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { OtherListIds } from '@/types/lists';

import useDateFormatter from '@/hooks/util/useDateFormatter';
import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';

interface NotificationRuleEventsProps {
  notificationRuleId: Id;
}

export const NotificationRuleEvents: React.FC<NotificationRuleEventsProps> = ({
  notificationRuleId,
}) => {
  const { t } = useTranslation();
  const { toLongDateTime } = useDateFormatter();

  const columns = useMemo<Array<ColumnDefinition<NotificationEvent>>>(
    () => [
      {
        id: 'name',
        header: t('features:notifications.notificationRule'),
        accessor: (item) => item.m3terEvent.eventData.name,
      },
      {
        id: 'eventName',
        header: t('forms:labels.event'),
        accessor: 'eventName',
      },
      {
        id: 'eventTime',
        header: t('forms:labels.date'),
        accessor: (item) => toLongDateTime(item.eventTime),
      },
    ],
    [toLongDateTime, t]
  );

  const params = useMemo(
    () => ({ notificationId: notificationRuleId }),
    [notificationRuleId]
  );

  return (
    <Card>
      <CardHeader>
        <Flex justify="space-between" alignItems="center">
          <Heading size="md">
            {t('features:notifications.latestEvents')}
          </Heading>
        </Flex>
      </CardHeader>
      <CardBody>
        <CrudList<NotificationEvent>
          columns={columns}
          listId={OtherListIds.NotificationRuleEvents}
          dataType={DataType.M3terEvent}
          params={params}
        />
      </CardBody>
    </Card>
  );
};
