import React, { PropsWithChildren } from 'react';

import { Flex } from '@chakra-ui/react';

import { CrudListSizeSelect } from './CrudListSizeSelect';
import { CrudListPagination } from './CrudListPagination';

export interface CrudListFooterProps {
  hidePagination?: boolean;
}

export const CrudListFooter: React.FC<
  PropsWithChildren<CrudListFooterProps>
> = ({ children, hidePagination }) => {
  return (
    <Flex
      alignItems="center"
      flexFlow="row nowrap"
      gap={4}
      justifyContent="flex-start"
    >
      {children}
      {!hidePagination && (
        <Flex
          alignItems="center"
          flexFlow="row nowrap"
          gap={4}
          justifyContent="flex-start"
          marginLeft="auto"
        >
          <CrudListSizeSelect />
          <CrudListPagination />
        </Flex>
      )}
    </Flex>
  );
};
