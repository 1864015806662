import React, { ElementType } from 'react';

import { PickListEntity } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';

export interface PickListEditRouteProps {
  form: ElementType;
}

export const PickListEditRoute: React.FC<PickListEditRouteProps> = ({
  form,
}) => <CrudEdit<PickListEntity> form={form} />;
