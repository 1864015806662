import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const formControl: ComponentMultiStyleConfig = {
  parts: ['container', 'helperText', 'requiredIndicator'],
  baseStyle: {
    helperText: {
      color: 'chakra-subtle-text',
    },
  },
};

export default formControl;
