import React, { ReactElement, ReactNode } from 'react';

import { Text } from '@chakra-ui/react';

import {
  OrganizationConfig,
  Plan,
  PlanTemplate,
  UnsavedEntity,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useOrg from '@/hooks/data/crud/useOrg';

type OrgConfigAndPlanTemplateAndPlanCommonKey =
  keyof UnsavedEntity<OrganizationConfig> &
    keyof UnsavedEntity<PlanTemplate> &
    keyof UnsavedEntity<Plan>;

export interface PlanOrTemplateOrOrgConfigValueProps<
  K extends OrgConfigAndPlanTemplateAndPlanCommonKey
> {
  fieldName: K;
  plan: Plan;
  planTemplate: PlanTemplate;
  format?: (
    value: OrganizationConfig[K] | PlanTemplate[K] | Plan[K]
  ) => ReactNode;
}

const defaultFormat = (value: any): string => `${value}`;

export const PlanOrTemplateOrOrgConfigValue = <
  K extends OrgConfigAndPlanTemplateAndPlanCommonKey
>({
  fieldName,
  format = defaultFormat,
  plan,
  planTemplate,
}: PlanOrTemplateOrOrgConfigValueProps<K>): ReactElement<any, any> | null => {
  const { t } = useTranslation();
  const { orgConfig } = useOrg();

  const planValue = plan[fieldName];
  const planTemplateValue = planTemplate[fieldName];
  const orgConfigValue = orgConfig[fieldName];

  if (planValue !== undefined) {
    return (
      <React.Fragment>
        <Text>{format(planValue)}</Text>
        {planTemplateValue !== undefined && (
          <Text variant="annotation">
            {t('features:plans.planTemplateDefault')}:{' '}
            <s>{format(planTemplateValue)}</s>
          </Text>
        )}
        <Text variant="annotation">
          {t('features:plans.orgConfigDefault')}:{' '}
          <s>{format(orgConfigValue)}</s>
        </Text>
      </React.Fragment>
    );
  }

  if (planTemplateValue !== undefined) {
    return (
      <React.Fragment>
        <Text>{format(planTemplateValue)}</Text>
        <Text variant="annotation">{t('features:plans.fromPlanTemplate')}</Text>
        <Text variant="annotation">
          {t('features:plans.orgConfigDefault')}:{' '}
          <s>{format(orgConfigValue)}</s>
        </Text>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Text>{format(orgConfigValue)}</Text>
      <Text variant="annotation">{t('features:plans.fromOrgConfig')}</Text>
    </React.Fragment>
  );
};
