import React, { useCallback } from 'react';

import { Flex, Heading } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormField,
  FormInput,
  FormPasswordInput,
} from '@m3ter-com/console-core/components';
import { CompleteNewPasswordFormValues } from '@m3ter-com/console-core/types';

import useAuth from '@/hooks/auth/useAuth';
import usePasswordValidator from '@/hooks/auth/usePasswordValidator';
import completeNewPasswordFormSchema from '@/validation/completeNewPasswordForm';
import { PasswordValidationList } from '@//components/features/auth/PasswordValidationList';
import { AuthSubmitButton } from '@/components/features/auth/AuthSubmitButton';

const emptyObject = {};

export const CompleteNewPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const { onCompleteNewPassword, isLoading } = useAuth(true);

  const { isPasswordValid, onValidatePassword, passwordValidationErrors } =
    usePasswordValidator();

  const onSubmit = useCallback(
    (data: CompleteNewPasswordFormValues) => {
      if (isPasswordValid) {
        onCompleteNewPassword(data.newPassword, data.name);
      }
    },
    [isPasswordValid, onCompleteNewPassword]
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={emptyObject}
      validationSchema={completeNewPasswordFormSchema}
    >
      <FormStack spacing={4}>
        <Flex w="100%" align="center" justify="center">
          <Heading size="md" textAlign="center">
            {t('forms:labels.changePassword')}
          </Heading>
        </Flex>
        <FormField
          isRequired
          name="name"
          label={t('forms:labels.name')}
          control={FormInput}
        />
        <FormField
          autoFocus
          isRequired
          name="newPassword"
          label={t('forms:labels.newPassword')}
          control={FormPasswordInput}
          placeholder={t('forms:labels.password')}
          onPasswordChange={onValidatePassword}
        />
        <FormField
          isRequired
          name="confirmPassword"
          label={t('forms:labels.confirmPassword')}
          control={FormPasswordInput}
          placeholder={t('forms:labels.confirmPassword')}
        />
        <PasswordValidationList
          passwordValidationErrors={passwordValidationErrors}
        />
        <AuthSubmitButton
          isLoading={isLoading}
          buttonText={t('forms:labels.changePassword')}
        />
      </FormStack>
    </Form>
  );
};
