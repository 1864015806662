export const IDP_IDENTIFIER_PREFIX = 'identifier:';

/**
 * Function to assist in the federated sign-in process.
 *
 * Due to a bug in the AWS Amplify library, the federatedSignIn method of the Auth
 * class does not accept an idp_identifier parameter, even though it can be used in
 * the authorize call to redirect the user to the correct provider sign-in.
 * See: https://github.com/aws-amplify/amplify-js/issues/10226
 *
 * Therefore, this function modifies the URL used for navigation to add the
 * `idp_identifier` manually when the identity provider starts with the defined prefix.
 *
 * This function can be removed after the bug is fixed.
 * See: https://github.com/aws-amplify/amplify-js/pull/10505
 */
export const federatedSignInHelper = (authoriseUrl: string) => {
  const identityProvider = 'identity_provider';
  const idpIdentifier = 'idp_identifier';

  const url = new URL(authoriseUrl);
  const provider = url.searchParams.get(identityProvider);

  if (provider?.startsWith(IDP_IDENTIFIER_PREFIX)) {
    url.searchParams.delete(identityProvider);
    url.searchParams.append(
      idpIdentifier,
      provider.replace(IDP_IDENTIFIER_PREFIX, '')
    );
  }

  const win = window.open(url.href, '_self');
  return Promise.resolve(win);
};
