import { useLocation, useResolvedPath } from 'react-router-dom';

const useNestedSubRoute = () => {
  const location = useLocation();
  const resolved = useResolvedPath('');
  const subRoutePath = location.pathname.replace(resolved.pathname, '');

  return {
    isNestedSubRoute: (subRoutePath.match(/\//g) || []).length > 1,
  };
};

export default useNestedSubRoute;
