import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { NotificationsList } from '@m3ter-com/ui-components';

import {
  removeNotification,
  selectNotifications,
} from '@/store/notifications/notifications';

export interface NotificationsListProps {}

export const Notifications: React.FC<NotificationsListProps> = () => {
  const notifications = useSelector(selectNotifications);

  const dispatch = useDispatch();

  const onClose = useCallback(
    (id: string) => {
      dispatch(removeNotification(id));
    },
    [dispatch]
  );

  return <NotificationsList notifications={notifications} onClose={onClose} />;
};
