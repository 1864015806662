import React, { useCallback, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { SimpleGrid } from '@chakra-ui/react';
import { v4 } from 'uuid';

import { DataType, Meter, MeterFieldCategory } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack, KeyValue, RadioTabs } from '@m3ter-com/ui-components';
import {
  FormActions,
  FormField,
  FormFieldset,
  FormInput,
  FormJsonEditor,
  FormValidationContext,
  vestResolver,
} from '@m3ter-com/console-core/components';

import ingestFormSchema, {
  IngestFormExtraValidationData,
  IngestFormValues,
} from '@/validation/ingest';
import {
  DataTypeFormEntitySelect,
  FormEntitySelect,
} from '@/components/forms/FormEntitySelect';
import { FormDatePicker } from '@/components/forms/FormDatePicker';

export interface IngestFormProps {
  meter: Meter;
  isSubmitting: boolean;
  onSubmit: (data: IngestFormValues) => void;
  onBack?: () => void;
  onCancel?: () => void;
  accountCode?: string;
  canEditAccount?: boolean;
}

enum IngestFormType {
  Simple = 'simple',
  Advanced = 'advanced',
}

export const IngestForm: React.FC<IngestFormProps> = ({
  meter,
  onBack,
  onCancel,
  onSubmit,
  isSubmitting = false,
  accountCode = '',
  canEditAccount = false,
}) => {
  const { t } = useTranslation();

  const [formType, setFormType] = useState<IngestFormType>(
    IngestFormType.Simple
  );

  const initialValues = useMemo<IngestFormValues>(
    () => ({
      requestBody: {
        measurements: [
          {
            uid: v4(),
            meter: meter.code,
            account: accountCode,
            ts: new Date().toISOString(),
          },
        ],
      },
    }),
    [accountCode, meter]
  );

  const validationContext = useMemo<
    FormValidationContext<IngestFormValues, IngestFormExtraValidationData>
  >(() => ({ initialValues, extraData: { meter } }), [initialValues, meter]);

  const form = useForm({
    context: validationContext,
    defaultValues: initialValues,
    resolver: vestResolver(ingestFormSchema),
  });
  const { setValue, handleSubmit } = form;

  const onFormSubmit = useCallback(
    (data: IngestFormValues) => {
      onSubmit(data);
      // Reset UID and date on all measurements and create a new object
      // so that the advanced editor updates.
      const requestBody = {
        measurements: (data?.requestBody?.measurements ?? []).map(
          (measurement) => ({
            ...measurement,
            uid: v4(),
          })
        ),
      };
      setValue('requestBody', requestBody);
      setValue(
        'requestBody.measurements.0.uid',
        requestBody.measurements[0].uid
      );
    },
    [onSubmit, setValue]
  );

  const formOptions = useMemo(
    () => [
      {
        value: IngestFormType.Simple,
        label: t('common:simple'),
        content: (
          <React.Fragment>
            <FormField
              isReadOnly
              name="requestBody.measurements.0.uid"
              label={t('forms:labels.uid')}
              control={FormInput}
            />
            <FormField
              showTimeSelect
              name="requestBody.measurements.0.ts"
              label={t('forms:labels.timestamp')}
              control={FormDatePicker}
            />
            <FormField
              isReadOnly
              name="requestBody.measurements.0.meter"
              label={t('common:meter')}
              control={FormInput}
            />
            {canEditAccount ? (
              <FormField
                name="requestBody.measurements.0.account"
                label={t('common:account')}
                control={
                  FormEntitySelect as DataTypeFormEntitySelect<DataType.Account>
                }
                dataType={DataType.Account}
                accessor="name"
                detailAccessor="code"
                optionValueAccessor="code"
              />
            ) : (
              <FormField
                isReadOnly
                name="requestBody.measurements.0.account"
                label={t('common:account')}
                control={FormInput}
              />
            )}
            <SimpleGrid
              as={FormFieldset}
              legend={t('forms:labels.dataFields')}
              alignItems="center"
              columns={3}
              spacing={2}
              width="100%"
            >
              {meter.dataFields.map((dataField) => (
                <React.Fragment key={dataField.code}>
                  <KeyValue
                    label={t('forms:labels.category')}
                    value={dataField.category}
                  />
                  <KeyValue
                    label={t('forms:labels.name')}
                    value={dataField.name}
                  />
                  <FormField
                    name={`requestBody.measurements.0.${dataField.category.toLowerCase()}.${
                      dataField.code
                    }`}
                    label={t('forms:labels.value')}
                    control={FormInput}
                    type={
                      dataField.category === MeterFieldCategory.MEASURE
                        ? 'number'
                        : 'string'
                    }
                    step={
                      dataField.category === MeterFieldCategory.MEASURE
                        ? 'any'
                        : undefined
                    }
                  />
                </React.Fragment>
              ))}
            </SimpleGrid>
          </React.Fragment>
        ),
      },
      {
        value: IngestFormType.Advanced,
        label: t('common:advanced'),
        content: (
          <FormJsonEditor
            w="100%"
            name="requestBody"
            label={t('forms:labels.ingestRequestBody')}
          />
        ),
      },
    ],
    [canEditAccount, meter, t]
  );

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <FormProvider {...form}>
        <FormStack alignItems="stretch">
          <RadioTabs
            options={formOptions}
            value={formType}
            onChange={setFormType}
          />
          <FormActions
            backText={onBack && t('common:back')}
            cancelText={onCancel && t('common:cancel')}
            submitText={t('features:ingest.submitUsage')}
            isSaving={isSubmitting}
            onBack={onBack}
            onCancel={onCancel}
          />
        </FormStack>
      </FormProvider>
    </form>
  );
};
