import { call, delay, select, StrictEffect } from 'redux-saga/effects';

import {
  DataType,
  AnalyticsJob,
  AnalyticsJobStatus,
  AnalyticsJobType,
  AnalyticsJobTypeToRequestBody,
  retrieve,
  create,
} from '@m3ter-com/m3ter-api';
import { DeepPartial } from '@m3ter-com/console-core/types';

import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';
import { AnalyticsJobError } from '@/util/error';

const INITIAL_ANALYTICS_JOB_POLLING_DELAY = 1000;
const MAX_ANALYTICS_JOB_POLLING_DELAY = 16000;

export function* pollAnalyticsJobUntilComplete<T extends AnalyticsJobType>(
  jobId: string
): Generator<StrictEffect, AnalyticsJob<T>, any> {
  const organizationId = yield select(selectCurrentOrgId);
  let analyticsJob: AnalyticsJob<T> = yield call(retrieve, {
    dataType: DataType.AnalyticsJob,
    id: jobId,
    pathParams: { organizationId },
  });

  let continuePolling = analyticsJob.status === AnalyticsJobStatus.Pending;
  // Start with a small delay to try and catch jobs that complete quickly
  let pollingDelay = INITIAL_ANALYTICS_JOB_POLLING_DELAY;
  while (continuePolling) {
    yield delay(pollingDelay);
    analyticsJob = yield call(retrieve, {
      dataType: DataType.AnalyticsJob,
      id: jobId,
      pathParams: { organizationId },
    });
    continuePolling = analyticsJob.status === AnalyticsJobStatus.Pending;
    // Double the delay with each loop until a maximum
    pollingDelay = Math.min(pollingDelay * 2, MAX_ANALYTICS_JOB_POLLING_DELAY);
  }

  return analyticsJob;
}

// Creates a new AnalyticsJob and polls it until it completes
// or fails. Then, returns that AnalyticsJob.
// This saga also catches errors and wraps them in our own AnalyticsJobError
// object so that we can track the ID of the created AnalyticsJob if polling fails
// for some reason.
// Would be nice to be able to type the error that can be thrown by this at some point,
// we should keep an eye on this TS feature:
// https://github.com/microsoft/TypeScript/issues/13219
export function* getAnalyticsResult<T extends AnalyticsJobType>(
  jobType: T,
  jobParameters: AnalyticsJobTypeToRequestBody[T],
  jobName?: string
): Generator<StrictEffect, AnalyticsJob<T>, any> {
  let analyticsJobId: string | undefined;

  try {
    const analyticsJobData: DeepPartial<AnalyticsJob<AnalyticsJobType>> = {
      name: jobName,
      parameters: jobParameters,
      type: jobType,
    };
    const organizationId = yield select(selectCurrentOrgId);
    let analyticsJob: AnalyticsJob<T> = yield call(create, {
      dataType: DataType.AnalyticsJob,
      pathParams: { organizationId },
      data: analyticsJobData,
    });
    analyticsJobId = analyticsJob.id;
    analyticsJob = yield call(pollAnalyticsJobUntilComplete, analyticsJob.id);

    return analyticsJob;
  } catch (error) {
    const analyticsJobError = new AnalyticsJobError(error, analyticsJobId);
    throw analyticsJobError;
  }
}
