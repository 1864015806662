import { PathParams } from '@m3ter-com/m3ter-api';

import useOrg from '@/hooks/data/crud/useOrg';

const useOrgPathParams = (extraParams?: PathParams): PathParams => {
  const { currentOrgId } = useOrg();
  return {
    organizationId: currentOrgId,
    ...extraParams,
  };
};

export default useOrgPathParams;
