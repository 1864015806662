import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { UserAdmin } from '@m3ter-com/m3ter-api';

const suite = create('userAdmin', (data: Partial<UserAdmin>) => {
  test(
    'firstName',
    i18next.t('forms:validations.users.firstNameRequired'),
    () => {
      enforce(data.firstName).isNotEmpty();
    }
  );

  test(
    'lastName',
    i18next.t('forms:validations.users.lastNameRequired'),
    () => {
      enforce(data.lastName).isNotEmpty();
    }
  );

  test(
    'email',
    i18next.t('forms:validations.users.emailAddressRequired'),
    () => {
      enforce(data.email).isNotEmpty();
    }
  );

  test(
    'tempPassword',
    i18next.t('forms:validations.users.passwordRequired'),
    () => {
      enforce(data.tempPassword).isNotEmpty();
    }
  );
});

export default suite;
