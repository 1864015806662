import React, {
  useCallback,
  useMemo,
  PropsWithChildren,
  ReactElement,
} from 'react';

import { Link } from '@chakra-ui/react';

import { Entity } from '@m3ter-com/m3ter-api';
import {
  DataTable,
  DataTableColumnDefinition,
  DataTableProps,
} from '@m3ter-com/ui-components';

import { getAccessorValue } from '@/util/data';
import { getIdParamName } from '@/routes/crud';
import { NamedLink } from '@/components/common/navigation/NamedLink';
import { EntityCrudActions } from '@/components/common/data/EntityCrudActions';

import { useCrudListContext } from './CrudListContext';
import { CrudListEmptyTableContent } from './CrudListEmptyTableContent';

// Re-export to avoid needing to import from UI components.
export type ColumnDefinition<E extends Entity = Entity> =
  DataTableColumnDefinition<E>;

export type CrudListTableProps<E extends Entity = Entity> = Omit<
  DataTableProps<E>,
  | 'columnDefinitions'
  | 'emptyContent'
  | 'idAccessor'
  | 'isLoading'
  | 'items'
  | 'onSelectedItemsChange'
> & {
  addActions?: boolean;
  columns: Array<ColumnDefinition<E>>;
  onSelectedItemsChange?: (
    newSelectedEntityIds: Array<string>,
    newSelectedEntities: Array<E>
  ) => void;
};

export function CrudListTable<E extends Entity>({
  addActions = true,
  columns,
  onSelectedItemsChange,
  ...tableProps
}: PropsWithChildren<CrudListTableProps<E>>): ReactElement<any, any> | null {
  const {
    allEntities,
    currentPageEntities,
    dataType,
    detailsRouteName,
    editRouteName,
    error,
    isItemDeletable,
    isItemEditable,
    isLoading,
    onDelete,
    sortCriteria,
    sortList,
  } = useCrudListContext<E>();

  const columnsWithActions = useMemo<Array<ColumnDefinition<E>>>(() => {
    if (columns.length === 0) {
      return columns;
    }

    const newColumns = [...columns];
    if (detailsRouteName) {
      const originalFirstColumn = {
        ...newColumns[0],
      };
      newColumns[0] = {
        ...originalFirstColumn,
        accessor: (item) => {
          const firstColumValue = getAccessorValue(
            item,
            originalFirstColumn.accessor
          );
          const linkParams = {
            [getIdParamName(dataType)]: item.id,
          };

          return (
            <Link as={NamedLink} name={detailsRouteName} params={linkParams}>
              {firstColumValue as React.ReactNode}
            </Link>
          );
        },
      };
    }

    const canAddActions = editRouteName || onDelete;
    if (addActions && canAddActions) {
      newColumns.push({
        id: 'list-actions-column',
        header: '',
        accessor: (item) => (
          <EntityCrudActions<E>
            dataType={dataType!}
            item={item}
            editRouteName={editRouteName}
            onDelete={onDelete}
            canDelete={isItemDeletable}
            canEdit={isItemEditable}
          />
        ),
      });
    }

    return newColumns;
  }, [
    addActions,
    columns,
    dataType,
    detailsRouteName,
    editRouteName,
    isItemDeletable,
    isItemEditable,
    onDelete,
  ]);

  const handleSelectedItemsChange = useCallback(
    (newSelectedEntityIds: Array<string>) => {
      if (onSelectedItemsChange) {
        const newSelectedEntities = allEntities.filter((entity) =>
          newSelectedEntityIds.includes(entity.id)
        );
        onSelectedItemsChange(newSelectedEntityIds, newSelectedEntities);
      }
    },
    [allEntities, onSelectedItemsChange]
  );

  const handleSort = useCallback(
    (newSortBy: string, descending: boolean) => {
      sortList({
        sortBy: newSortBy,
        sortOrder: descending ? 'DESC' : 'ASC',
      });
    },
    [sortList]
  );

  return (
    <DataTable<E>
      columnDefinitions={columnsWithActions}
      idAccessor="id"
      isLoading={isLoading && !error}
      items={currentPageEntities}
      onSelectedItemsChange={handleSelectedItemsChange}
      onSortChange={handleSort}
      sortColumn={sortCriteria?.sortBy}
      sortDescending={sortCriteria?.sortOrder === 'DESC'}
      emptyContent={<CrudListEmptyTableContent />}
      {...tableProps}
    />
  );
}
