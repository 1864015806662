import React, { useCallback, useMemo, useState } from 'react';

import sortBy from 'lodash/sortBy';
import { ButtonGroup, useSteps } from '@chakra-ui/react';

import { IntegrationConfig, UnsavedEntity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Button,
  FormStack,
  Select,
  SelectOption,
  Stepper,
  Step,
} from '@m3ter-com/ui-components';
import { Form, FormActions } from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import { isLocalOrAlpha } from '@/config';
import validationSchema from '@/validation/integrationConfig';
import { useIntegrationConfigSchemaContext } from '@/components/features/integrations/IntegrationConfigSchemaContext';

import { IntegrationConfigFormConfigureFields } from './IntegrationConfigFormConfigureFields';

export type IntegrationConfigCreateFormValues = Omit<
  UnsavedEntity<IntegrationConfig>,
  'authorized' | 'entityType' | 'destination'
>;

export interface IntegrationConfigCreateFormProps
  extends BaseFormProps<UnsavedEntity<IntegrationConfig>> {}

const initialValues: IntegrationConfigCreateFormValues = {
  configData: {},
};

// A 3-step form that allows the user to:
// 1. Select an entityType for their integration
// 2. Select the destination for the integration
// 3. Configure all of the options that apply when defining an integration for their
// chosen entityType and destination
//
// Some sections / fields of the form depend on the selections made in the first two steps.
// Keeping these as seperate steps instead of having them all in the same form means the user
// doesn't see whole chunks of the form being replaced when they change these selections.
export const IntegrationConfigCreateForm: React.FC<
  IntegrationConfigCreateFormProps
> = ({ isSaving, onCancel, onSave }) => {
  const { t } = useTranslation();

  const { configSchema } = useIntegrationConfigSchemaContext();

  const { activeStep, goToNext, goToPrevious } = useSteps({ count: 3 });

  const [selectedEntityType, setSelectedEntityType] = useState<string | null>(
    null
  );
  const [selectedDestination, setSelectedDestination] = useState<string | null>(
    null
  );

  const entityTypeOptions = useMemo<Array<SelectOption>>(() => {
    // Integrations for some entities are a WIP and are makred as such with a 'released' field.
    // We only show those as options when running the console locally or in alpha.
    const showUnreleasedEntityTypes = isLocalOrAlpha();
    const availableEntityTypes = Object.entries(configSchema.supportedEntities)
      .filter(
        ([_, entitySchema]) =>
          showUnreleasedEntityTypes || entitySchema!.released
      )
      .map(([entityType, _]) => entityType);

    return sortBy(
      availableEntityTypes.map((entityType) => ({
        label: entityType,
        value: entityType,
      })),
      'label'
    );
  }, [configSchema]);

  const destinationOptions = useMemo<Array<SelectOption>>(() => {
    if (!selectedEntityType) {
      return [];
    }

    // Integrations for some destinations are a WIP and are makred as such with a 'released' field.
    // We only show those as options when running the console locally or in alpha.
    const showUnreleasedDestinations = isLocalOrAlpha();
    const entityDestinationSchemas =
      configSchema.supportedEntities[selectedEntityType]?.destinations || [];
    const availableDestinationSchemas = entityDestinationSchemas.filter(
      (destinationSchema) =>
        showUnreleasedDestinations || destinationSchema.released
    );

    return sortBy(
      availableDestinationSchemas.map((destinationSchema) => ({
        label: destinationSchema.displayName,
        value: destinationSchema.destination,
      })),
      'label'
    );
  }, [configSchema, selectedEntityType]);

  const goToStepTwo = useCallback(() => {
    setSelectedDestination('');
    goToNext();
  }, [goToNext]);

  const onSubmit = useCallback(
    (formValues: IntegrationConfigCreateFormValues) => {
      if (selectedEntityType && selectedDestination) {
        onSave({
          authorized: false,
          destination: selectedDestination,
          entityType: selectedEntityType,
          ...formValues,
        });
      }
    },
    [onSave, selectedDestination, selectedEntityType]
  );

  return (
    <React.Fragment>
      <Stepper index={activeStep} mb={6}>
        <Step title={t('features:integrations.selectEntityTypeStepTitle')} />
        <Step title={t('features:integrations.selectDestinationStepTitle')} />
        <Step title={t('features:integrations.configureStepTitle')} />
      </Stepper>
      {activeStep === 0 && (
        <FormStack alignItems="stretch">
          <Select
            onChange={setSelectedEntityType}
            options={entityTypeOptions}
            value={selectedEntityType}
          />
          <ButtonGroup>
            <Button
              intent="primary"
              isDisabled={!selectedEntityType}
              onClick={goToStepTwo}
            >
              {t('common:next')}
            </Button>
            <Button variant="ghost" onClick={onCancel}>
              {t('common:cancel')}
            </Button>
          </ButtonGroup>
        </FormStack>
      )}
      {activeStep === 1 && (
        <FormStack alignItems="stretch">
          <Select
            onChange={setSelectedDestination}
            options={destinationOptions}
            value={selectedDestination}
          />
          <ButtonGroup>
            <Button
              intent="primary"
              isDisabled={!selectedEntityType || !selectedDestination}
              onClick={goToNext}
            >
              {t('common:next')}
            </Button>
            <Button variant="ghost" onClick={goToPrevious}>
              {t('common:back')}
            </Button>
            <Button variant="ghost" onClick={onCancel}>
              {t('common:cancel')}
            </Button>
          </ButtonGroup>
        </FormStack>
      )}
      {activeStep === 2 && !!selectedEntityType && !!selectedDestination && (
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <FormStack>
            <IntegrationConfigFormConfigureFields
              destination={selectedDestination}
              entityType={selectedEntityType}
            />
            <FormActions
              backText={t('common:back')}
              cancelText={t('common:cancel')}
              isSaving={isSaving}
              onBack={goToPrevious}
              onCancel={onCancel}
              submitText={t('forms:buttons.createEntity', {
                entityName: t('common:integration'),
              })}
            />
          </FormStack>
        </Form>
      )}
    </React.Fragment>
  );
};
