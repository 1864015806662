import React, { useEffect, useRef } from 'react';

import { AlertProps } from '@chakra-ui/react';

import { Alert } from '@m3ter-com/ui-components';

import { AppError } from '@/types/errors';

export interface ErrorAlertProps extends AlertProps {
  error: AppError;
  title?: string;
}

export const ErrorAlert: React.FC<ErrorAlertProps> = ({
  error,
  title,
  ...rest
}) => {
  const errorRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (errorRef?.current) {
      errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  return (
    <Alert
      ref={errorRef}
      status="error"
      alertTitle={title}
      p={2}
      {...rest}
      mt={2}
      mb={2}
    >
      {error.message}
    </Alert>
  );
};
