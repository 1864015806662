import React, { ReactNode } from 'react';

import { Spinner, Td } from '@chakra-ui/react';

export interface DataTableNoItemsCellProps {
  columnCount: number;
  isLoading: boolean;
  emptyContent?: ReactNode;
}

export const DataTableNoItemsCell: React.FC<DataTableNoItemsCellProps> = ({
  isLoading,
  columnCount,
  emptyContent,
}) => {
  return (
    <Td colSpan={columnCount} textAlign="center" paddingY={8}>
      {isLoading ? <Spinner /> : emptyContent}
    </Td>
  );
};
