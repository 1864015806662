import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';

import { DataType, ExternalMapping, UnsavedEntity } from '@m3ter-com/m3ter-api';

import { extractError } from '@/util/error';
import { createData } from '@/store/data/data.saga';

import {
  createExternalMappings,
  createExternalMappingsFailure,
  createExternalMappingsSuccess,
  CreateExternalMappingsAction,
} from './externalMappingsCreateForm';

export function* createExternalMappingsSaga(
  action: CreateExternalMappingsAction
): Generator<StrictEffect, void, any> {
  const { onSuccess, onFailure } = action.meta;
  const { formValues } = action.payload;

  try {
    const { externalSystem, externalTable, m3terEntity } = formValues;
    const externalMappings = new Array<ExternalMapping>();
    for (let i = 0; i < formValues.mappings.length; i += 1) {
      const unsavedMapping: UnsavedEntity<ExternalMapping> = {
        ...formValues.mappings[i],
        externalSystem,
        externalTable,
        m3terEntity,
      };
      const externalMapping = yield call(
        createData,
        DataType.ExternalMapping,
        unsavedMapping
      );
      externalMappings.push(externalMapping);
    }
    yield put(createExternalMappingsSuccess(externalMappings[0], onSuccess));
  } catch (error) {
    yield put(createExternalMappingsFailure(extractError(error), onFailure));
  }
}

export default function* externalMappingsCreateFormSaga() {
  yield takeEvery(createExternalMappings.type, createExternalMappingsSaga);
}
