import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const parts = [
  'root', // Accordion
  'container', // AccordionItem
  'button', // AccordionButton
  'panel', // AccordionPanel
];

const accordion: ComponentMultiStyleConfig = {
  parts,
  baseStyle: {
    container: {
      borderTopWidth: 0,
      '&:last-of-type': {
        borderBottomWidth: 0,
      },
    },
    panel: {
      padding: 0,
    },
  },
};

export default accordion;
