import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { BalanceTransaction } from '@m3ter-com/m3ter-api';

const suite = create(
  'balanceTransaction',
  (data: Partial<BalanceTransaction>) => {
    test(
      'transactionTypeId',
      i18next.t('forms:validations.balanceTransaction.transactionTypeRequired'),
      () => {
        enforce(data.transactionTypeId).isNotEmpty();
      }
    );

    test('amount', i18next.t('forms:validations.common.amountRequired'), () => {
      enforce(data.amount).isNotEmpty();
    });
  }
);

export default suite;
