import { ExternalMappingEntityType } from '@m3ter-com/m3ter-api';

export enum ExternalMappingCreateMode {
  Multiple = 'MULTIPLE',
  Single = 'SINGLE',
}

export interface ExternalMappingCreateFormStepOneValues {
  m3terEntity: ExternalMappingEntityType;
  externalSystem: string;
  externalTable: string;
}

export interface ExternalMappingCreateFormStepTwoValues {
  mappings: Array<{
    externalId: string;
    m3terId: string;
    integrationConfigId?: string;
  }>;
}

export type ExternalMappingCreateFormValues =
  ExternalMappingCreateFormStepOneValues &
    ExternalMappingCreateFormStepTwoValues;
