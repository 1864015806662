import React from 'react';

import { SimpleGrid } from '@chakra-ui/react';

import { DataType, UserGroup } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import { DetailsCard } from '@/components/common/data/DetailsCard';
import { PermissionPolicyList } from '@/components/features/access/permission-policy/PermissionPolicyList';

export interface UserGroupDetailsProps {
  data: UserGroup;
}

export const UserGroupDetails: React.FC<UserGroupDetailsProps> = ({
  data: userGroup,
}) => {
  const { t } = useTranslation();

  return (
    <DetailsCard
      data={userGroup}
      dataType={DataType.UserGroup}
      showEditButton
      details={
        <SimpleGrid columns={1} gap={6} w="100%">
          <KeyValue label={t('forms:labels.name')} value={userGroup.name} />
        </SimpleGrid>
      }
    >
      <PermissionPolicyList dataType={DataType.UserGroup} id={userGroup.id} />
    </DetailsCard>
  );
};
