import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  IntegrationConfig,
  IntegrationTaskAverageTimeData,
} from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';
import { SerialisedIntegrationRunDetailsData } from '@/types/data';

export interface IntegrationRunsState {
  averageTaskTime: Array<IntegrationTaskAverageTimeData>;
  integrationRunData: Array<SerialisedIntegrationRunDetailsData>;
  isLoadingRunData: boolean;
  error?: AppError;
  integrationRunConfig?: IntegrationConfig;
}

type IntegrationRunsFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

interface LoadIntegrationRunPayload {
  entityId?: string;
  entityType?: string;
}
export type LoadIntegrationRunAction = PayloadAction<LoadIntegrationRunPayload>;

interface LoadIntegrationRunSuccessPayload {
  runData: Array<SerialisedIntegrationRunDetailsData>;
  averageTaskTime: Array<IntegrationTaskAverageTimeData>;
  config?: IntegrationConfig;
}
type LoadIntegrationRunSuccessAction =
  PayloadAction<LoadIntegrationRunSuccessPayload>;

export const initialState: IntegrationRunsState = {
  averageTaskTime: [],
  integrationRunData: [],
  isLoadingRunData: false,
};
const name = 'features/integrations/integrationRuns';
const integrationRunsState = createSlice({
  name,
  initialState,
  reducers: {
    loadIntegrationRun: {
      reducer: (
        state: IntegrationRunsState,
        _action: LoadIntegrationRunAction
      ) => {
        state.isLoadingRunData = true;
        state.averageTaskTime = [];
        state.integrationRunConfig = undefined;
        state.integrationRunData = [];
        state.error = undefined;
      },
      prepare: (entityId: string, entityType: string) => ({
        payload: { entityId, entityType },
      }),
    },
    loadIntegrationRunFailure: {
      reducer: (
        state: IntegrationRunsState,
        action: IntegrationRunsFailureAction
      ) => {
        state.error = action.error;
        state.isLoadingRunData = false;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    loadIntegrationRunSuccess: {
      reducer: (
        state: IntegrationRunsState,
        action: LoadIntegrationRunSuccessAction
      ) => {
        state.averageTaskTime = action.payload.averageTaskTime;
        state.integrationRunConfig = action.payload.config;
        state.integrationRunData = action.payload.runData;
        state.isLoadingRunData = false;
      },
      prepare: (
        serialisedRunData: Array<SerialisedIntegrationRunDetailsData>,
        averageTaskTime: Array<IntegrationTaskAverageTimeData>,
        config?: IntegrationConfig
      ) => ({
        payload: { runData: serialisedRunData, averageTaskTime, config },
      }),
    },
    reset: () => initialState,
  },
});

// Actions
export const {
  loadIntegrationRun,
  loadIntegrationRunFailure,
  loadIntegrationRunSuccess,
  reset,
} = integrationRunsState.actions;

// Selectors
const selectIntegrationRunsState = (state: {
  features: { integrations: { integrationRuns: IntegrationRunsState } };
}): IntegrationRunsState => state.features.integrations.integrationRuns;

export const selectIsLoadingIntegrationRunData = createSelector(
  selectIntegrationRunsState,
  (state) => state.isLoadingRunData
);

export const selectIntegrationRunsError = createSelector(
  selectIntegrationRunsState,
  (state) => state.error
);

export const selectIntegrationRunAverageTaskTime = createSelector(
  selectIntegrationRunsState,
  (state) => state.averageTaskTime
);

export const selectIntegrationRunConfig = createSelector(
  selectIntegrationRunsState,
  (state) => state.integrationRunConfig
);

export const selectIntegrationRunData = createSelector(
  selectIntegrationRunsState,
  (state) => state.integrationRunData
);

export default integrationRunsState.reducer;
