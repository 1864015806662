import {
  call,
  delay,
  put,
  race,
  select,
  StrictEffect,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import i18next from 'i18next';
import difference from 'lodash/difference';

import { DataType, Id, StatementJob } from '@m3ter-com/m3ter-api';

import { Task } from '@/types/tasks';

import { ids } from '@/util/data';
import { extractError } from '@/util/error';
import { isBootstrapSuccessActionWithOrgId } from '@/store/app/bootstrap/bootstrap';
import { dataLoaded, selectById } from '@/store/data/data';
import {
  createData,
  listData,
  performItemAction,
  retrieveData,
} from '@/store/data/data.saga';
import {
  cancelTask,
  CancelTaskAction,
  removeTask,
  upsertTasks,
} from '@/store/tasks/tasks';

import {
  generateStatements,
  GenerateStatementsAction,
  generateStatementsFailure,
  generateStatementsSuccess,
  selectRunningStatementJobIds,
  setRunningStatementJobs,
  statementJobComplete,
  StatementJobCompleteAction,
} from './statementJobs';

const MAX_STATEMENT_JOBS = 10;
const STATEMENT_JOB_ACTIVE_POLLING_DELAY = 5000;
const STATEMENT_JOB_INACTIVE_POLLING_DELAY = 30000;
const TASK_ID_PREFIX = 'statementJob-';

const getTaskId = (id: Id) => `${TASK_ID_PREFIX}${id}`;

const createTask = (statementJob: StatementJob): Task => {
  return {
    id: getTaskId(statementJob.id),
    title: i18next.t('common:statementJob'),
    cancellable: true,
  };
};

export function* pollStatementJobsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const statementJobs = yield call(updateStatementJobsSaga);

    // Wait for either a delay or a new statement job being created.
    yield race([
      delay(
        // Poll more frequently if there are running statement jobs.
        statementJobs.length > 0
          ? STATEMENT_JOB_ACTIVE_POLLING_DELAY
          : STATEMENT_JOB_INACTIVE_POLLING_DELAY
      ),
      take(generateStatementsSuccess.type),
    ]);
  }
}

export function* updateStatementJobsSaga(): Generator<
  StrictEffect,
  Array<StatementJob>,
  any
> {
  try {
    const response = yield call(listData, DataType.StatementJob, {
      active: true,
      pageSize: MAX_STATEMENT_JOBS,
    });
    const statementJobs: Array<StatementJob> = response.data;
    const statementJobIds = ids(statementJobs);

    const runningStatementJobIds = yield select(selectRunningStatementJobIds);

    // Check for any removed running statement jobs that we can consider complete.
    const removedStatementJobIds = difference(
      runningStatementJobIds,
      statementJobIds
    );

    for (let i = 0; i < removedStatementJobIds.length; i += 1) {
      const statementJobSelector = yield call(
        selectById,
        DataType.StatementJob,
        removedStatementJobIds[i]
      );
      const statementJob = yield select(statementJobSelector);
      yield put(statementJobComplete(statementJob));
      yield put(removeTask(getTaskId(removedStatementJobIds[i])));
    }

    // Update / add all running bill jobs.
    yield put(setRunningStatementJobs(statementJobIds));

    if (statementJobIds.length > 0) {
      yield put(upsertTasks(statementJobs.map(createTask)));
    }

    return statementJobs;
  } catch {
    // Error likely to be a network issue or auth session timeout.
    // We can skip the update and the polling will retry the call.
    return [];
  }
}

export function* generateStatementsSaga(
  action: GenerateStatementsAction
): Generator<StrictEffect, void, any> {
  const { billId, generateCsvStatement } = action.payload;

  try {
    const statementJob = yield call(createData, DataType.StatementJob, {
      billId,
      includeCsvFormat: generateCsvStatement,
    });

    // Manually add the statement job to the data slice.
    yield put(dataLoaded(DataType.StatementJob, [statementJob]));

    yield put(generateStatementsSuccess(statementJob));
  } catch (error) {
    yield put(generateStatementsFailure(extractError(error)));
  }
}

export function* statementJobCompleteSaga(
  action: StatementJobCompleteAction
): Generator<StrictEffect, void, any> {
  // Reload the bill that the statement was generated for.
  const { statementJob } = action.payload;
  try {
    yield call(retrieveData, DataType.Bill, statementJob.billId);
  } catch (error) {
    // There's nothing we can do if the bill reloading fails.
  }
}

export function* cancelTaskSaga(
  action: CancelTaskAction
): Generator<StrictEffect, void, any> {
  const { id } = action.payload;
  if (id.startsWith(TASK_ID_PREFIX)) {
    const statementJobId = id.replace(TASK_ID_PREFIX, '');
    try {
      yield call(
        performItemAction,
        DataType.StatementJob,
        statementJobId,
        'cancelStatementJob'
      );
    } catch (error) {
      // Statement job likely to already be complete.
    }
    yield put(removeTask(id));
  }
}

export default function* statementJobsSaga() {
  yield takeEvery(generateStatements.type, generateStatementsSaga);

  // Even though we manually add the running job and task after
  // this action, leaving this reload will mark any very fast
  // completed statement jobs quicker than waiting for the next poll.
  yield takeEvery(generateStatementsSuccess.type, updateStatementJobsSaga);

  yield takeEvery(statementJobComplete.type, statementJobCompleteSaga);

  // `takeLatest` cancels previous tasks if they are still running so
  // each time the org changes the old polling will be stopped.
  // https://redux-saga.js.org/docs/api#takelatestpattern-saga-args
  yield takeLatest(isBootstrapSuccessActionWithOrgId, pollStatementJobsSaga);

  yield takeEvery(cancelTask.type, cancelTaskSaga);
}
