import React from 'react';

import { OrganizationAdmin } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { OrganizationAdminForm } from '@/components/features/admin/organizations/OrganizationAdminForm';

export const OrganizationAdminEditRoute: React.FC = () => (
  <CrudEdit<OrganizationAdmin> form={OrganizationAdminForm} />
);
