import { useParams } from 'react-router-dom';

import { DataType } from '@m3ter-com/m3ter-api';

import { getIdParamName } from '@/routes/crud';

const useCrudId = (dataType: DataType) => {
  const params = useParams();
  const id = params[getIdParamName(dataType)];

  if (!id) {
    throw new Error(
      `Attempting to find an id for ${dataType} when one is not present in the router params.`
    );
  }

  return id;
};

export default useCrudId;
