import { toNestError } from '@hookform/resolvers';
import { FieldError, ResolverResult } from 'react-hook-form';
import vest from 'vest';

export type VestSchema = ReturnType<typeof vest.create>;

export type FormValidationContext<
  FormValues = Record<string, any>,
  ExtraData = never
> = {
  initialValues: FormValues;
} & ([ExtraData] extends [never] ? {} : { extraData: ExtraData });

/**
 * react-hook-form's current vest resolver implementation is below.
 * https://github.com/react-hook-form/resolvers/blob/4309d1c071792e4efec51d1509f41a8709965b0e/vest/src/vest.ts
 * This doesn't make use of the "context" parameter that can be passed when using the useForm hook
 * meaning we cannot get any data besides the current form values into our vest schemas.
 *
 * The below function is a custom implementation of the standard vest resolver that allows
 * us to leverage this idea of context so that we can pass extra data into our schemas.
 */
export const vestResolver =
  (schema: VestSchema) =>
  async (
    values: Record<string, any>,
    context?: Record<string, any>
  ): Promise<ResolverResult> => {
    const result = schema(values, context);
    const hasErrors = result.hasErrors();
    if (!hasErrors)
      return {
        values,
        errors: {},
      };

    const vestErrors = result.getErrors();
    const errors: Record<string, FieldError> = {};
    Object.keys(vestErrors).forEach((path) => {
      if (!errors[path]) {
        errors[path] = { message: vestErrors[path][0], type: '' };
      }
    });

    return {
      values: {},
      errors: toNestError(errors, {
        fields: {},
        shouldUseNativeValidation: false,
      }),
    };
  };
