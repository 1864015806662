import React from 'react';

import type { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { CustomerInsightsRootRoute } from '@/components/routes/analytics/customer-insights/CustomerInsightsRootRoute';
import { DataExplorerRootRoute } from '@/components/routes/analytics/data-explorer/DataExplorerRootRoute';

const LazyUsageExplorerRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "UsageExplorer" */ '@/components/routes/analytics/data-explorer/UsageExplorerRoute'
  ).then((module) => ({ default: module.UsageExplorerRoute }))
);
const LazyBillingExplorerRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BillingExplorer" */ '@/components/routes/analytics/data-explorer/BillingExplorerRoute'
  ).then((module) => ({ default: module.BillingExplorerRoute }))
);
const LazyCommitmentsExplorerRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "CommitmentsExplorer" */ '@/components/routes/analytics/data-explorer/CommitmentsExplorerRoute'
  ).then((module) => ({ default: module.CommitmentsExplorerRoute }))
);

const LazyCustomerInsightsOverviewRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "CustomerInsightsOverview" */ '@/components/routes/analytics/customer-insights/CustomerInsightsOverviewRoute'
  ).then((module) => ({ default: module.CustomerInsightsOverviewRoute }))
);
const LazyCustomerInsightsAccountDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "CustomerInsightsAccountDetails" */ '@/components/routes/analytics/customer-insights/CustomerInsightsAccountDetailsRoute'
  ).then((module) => ({ default: module.CustomerInsightsAccountDetailsRoute }))
);

export default (): NamedRoute => ({
  path: 'analytics',
  children: [
    {
      path: 'customer-insights',
      name: 'analytics.customerInsights',
      element: <CustomerInsightsRootRoute />,
      children: [
        {
          index: true,
          element: <LazyCustomerInsightsOverviewRoute />,
        },
        {
          path: 'account-details',
          element: <LazyCustomerInsightsAccountDetailsRoute />,
        },
      ],
    },
    {
      path: 'data-explorer',
      name: 'analytics.dataExplorer',
      element: <DataExplorerRootRoute />,
      children: [
        {
          index: true,
          element: <LazyUsageExplorerRoute />,
        },
        {
          path: 'billing',
          element: <LazyBillingExplorerRoute />,
        },
        {
          path: 'commitments',
          element: <LazyCommitmentsExplorerRoute />,
        },
      ],
    },
  ],
});
