import React, { useCallback } from 'react';

import {
  Input as ChakraInput,
  forwardRef,
  useStyleConfig,
} from '@chakra-ui/react';

export interface FileInputProps
  extends Omit<
    React.ComponentProps<typeof ChakraInput>,
    'onChange' | 'type' | 'value'
  > {
  onChange: (file: File | null) => void;
}

export const FileInput = forwardRef<FileInputProps, 'input'>(
  ({ onChange, ...inputProps }, ref) => {
    const styles = useStyleConfig('FileInput');

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLInputElement>) => {
        const inputElement = event.target as HTMLInputElement;
        inputElement.value = '';
        onChange(null);
      },
      [onChange]
    );

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length === 1) {
          onChange(event.target.files[0]);
        }
      },
      [onChange]
    );

    return (
      <ChakraInput
        ref={ref}
        {...inputProps}
        onChange={handleChange}
        onClick={handleClick}
        sx={styles}
        type="file"
      />
    );
  }
);

// Make Chakra treat this like its own Input.
// https://github.com/chakra-ui/chakra-ui/issues/2269
FileInput.id = 'Input';
