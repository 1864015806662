import React from 'react';

import { NotificationRule } from '@m3ter-com/m3ter-api';
import { Form } from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import notificationRuleSchema from '@/validation/notificationRule';

import { NotificationRuleFormInner } from './NotificationRuleFormInner';

const defaultInitialValues = {};

interface NotificationRuleFormProps extends BaseFormProps<NotificationRule> {}

export const NotificationRuleForm: React.FC<NotificationRuleFormProps> = ({
  onSave,
  onCancel,
  isSaving = false,
  isEdit = false,
  initialValues = defaultInitialValues,
}) => {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSave}
      validationSchema={notificationRuleSchema}
    >
      <NotificationRuleFormInner
        isEdit={isEdit}
        isSaving={isSaving}
        onCancel={onCancel}
      />
    </Form>
  );
};
