import { createSelector } from '@reduxjs/toolkit';

import { AccountPlan } from '@m3ter-com/m3ter-api';

import { AccountCustomPlanGroupFormValues } from '@/components/features/accounts/AccountCustomPlanGroupForm';
import createFormSlice, {
  FormState,
  CreateAction,
} from '@/store/forms/createFormSlice';

export type CreateCustomPlanGroupAction =
  CreateAction<AccountCustomPlanGroupFormValues>;

const customPlanGroupFormSlice = createFormSlice<
  AccountPlan,
  AccountCustomPlanGroupFormValues
>('features/accounts/customPlanGroupForm');

export const {
  create: createCustomPlanGroup,
  createSuccess: createCustomPlanGroupSuccess,
  createFailure: createCustomPlanGroupFailure,
} = customPlanGroupFormSlice.actions;

// Selectors

const selectCustomPlanGroupFormState = (state: {
  features: { accounts: { customPlanGroupForm: FormState } };
}): FormState => state.features.accounts.customPlanGroupForm;

export const selectIsSaving = createSelector(
  selectCustomPlanGroupFormState,
  (planFormState) => planFormState.isSaving
);

export const selectError = createSelector(
  selectCustomPlanGroupFormState,
  (planFormState) => planFormState.error
);

export default customPlanGroupFormSlice.reducer;
