import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ButtonGroup, Flex, Heading, VStack } from '@chakra-ui/react';
import { FaCaretLeft, FaCaretRight, FaRegCalendarAlt } from 'react-icons/fa';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert, Button, IconButton } from '@m3ter-com/ui-components';
import {
  collatePricing,
  combineAndSortPricing,
  isPlanPricing,
  isPlanTemplatePricing,
  PricingActivityStatus,
} from '@m3ter-com/console-core/utils';

import { NamedLink } from '@/components/common/navigation/NamedLink';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink';
import { PricingGridPricing } from '@/components/features/pricing/grid/PricingGridPricing';
import { usePricingGridContext } from '@/components/features/pricing/grid/PricingGridContext';
import { usePricingGridCellContext } from '@/components/features/pricing/grid/PricingGridCellContext';
import useEntityNamings from '@/hooks/util/useEntityNamings';

export const PricingGridPricingNavigator: React.FC = () => {
  const { t } = useTranslation();

  const { canEditPricing, canEditTemplatePricing } = usePricingGridContext();

  const {
    dataType,
    isTemplateColumn,
    pricingLinkQueryParams,
    planPricings = [],
    planTemplatePricings = [],
  } = usePricingGridCellContext();

  const { singular: entityName } = useEntityNamings(dataType);

  const collatedPricing = useMemo(
    () =>
      collatePricing(combineAndSortPricing(planPricings, planTemplatePricings)),
    [planPricings, planTemplatePricings]
  );

  // Set initial index of the active pricing to show, defaulting to plan over plan template.
  const [showIndex, setShowIndex] = useState<number>(() => {
    let startIndex = 0;
    for (let i = 0; i < collatedPricing.length; i += 1) {
      if (collatedPricing[i].status === PricingActivityStatus.Active) {
        const pricing = collatedPricing[i].pricing;
        if (pricing && isPlanPricing(pricing)) {
          // If we have active plan pricing use it.
          return i;
        }
        startIndex = i;
      }
    }
    return startIndex;
  });

  const canGoBack = showIndex > 0;
  const canGoForward = showIndex < collatedPricing.length - 1;

  const onBackClick = useCallback(() => {
    setShowIndex((i) => i - 1);
  }, []);

  const onForwardClick = useCallback(() => {
    setShowIndex((i) => i + 1);
  }, []);

  useEffect(() => {
    // When the collated pricing changes, which could be because pricing is deleted,
    // ensure the `showIndex` is still valid.
    if (showIndex >= collatedPricing.length) {
      setShowIndex(collatedPricing.length - 1);
    }
  }, [showIndex, collatedPricing]);

  // If the index is out of range, select the last item.
  const { status, pricing } =
    collatedPricing[Math.min(showIndex, collatedPricing.length - 1)];

  const isTemplatePricingInPlanColumn =
    !isTemplateColumn && !!pricing && isPlanTemplatePricing(pricing);

  // Only show Override button for active plan template pricing when not on a template column.
  const canOverridePricing =
    isTemplatePricingInPlanColumn && status === PricingActivityStatus.Active;

  // We only want to show the actions if pricing can be edited and if we're not in
  // a plan template column or editing template pricing is enabled.
  const showActions =
    canEditPricing && (!isTemplateColumn || canEditTemplatePricing);

  return (
    <VStack spacing={2} alignItems="stretch">
      <Flex justifyContent="space-between" alignItems="center">
        <Heading size="xs">
          {t(`features:pricing.activityStatusHeadings.${status}`, {
            entityName,
          })}
        </Heading>
        <ButtonGroup size="xs">
          <IconButton
            aria-label={t('common:back')}
            icon={<FaCaretLeft />}
            onClick={onBackClick}
            isDisabled={!canGoBack}
          />
          {canEditPricing && (
            <IconButton
              aria-label={t('features:pricing.viewPricingSchedule', {
                entityName,
              })}
              icon={<FaRegCalendarAlt />}
              as={NamedLink}
              name="pricing.schedule"
              queryParams={pricingLinkQueryParams}
            />
          )}
          <IconButton
            aria-label={t('common:forward')}
            icon={<FaCaretRight />}
            onClick={onForwardClick}
            isDisabled={!canGoForward}
          />
        </ButtonGroup>
      </Flex>
      {pricing ? (
        <PricingGridPricing
          dataType={dataType}
          pricing={pricing}
          isTemplatePricingInPlanColumn={isTemplatePricingInPlanColumn}
          canOverridePricing={canOverridePricing}
          showActions={showActions}
        />
      ) : (
        <VStack alignItems="center" spacing={4}>
          <Alert status={isTemplateColumn ? 'info' : 'warning'} padding={2}>
            {t('features:pricing.noActivePricingConfigured')}
          </Alert>
          {showActions && (
            <Button
              data-testid="create-pricing"
              size="sm"
              as={CrudCreateLink}
              addReturnPath
              dataType={dataType}
              queryParams={pricingLinkQueryParams}
            >
              {isTemplateColumn
                ? t('forms:buttons.createEntity', {
                    entityName: t('common:planTemplatePricing'),
                  })
                : t('forms:buttons.createEntity', {
                    entityName: t('common:planPricing'),
                  })}
            </Button>
          )}
        </VStack>
      )}
    </VStack>
  );
};
