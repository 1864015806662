import { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { startOfToday } from 'date-fns';

import { copySystemDateInTimeZone } from '@m3ter-com/console-core/utils';

import { selectOrgTimezone } from '@/store/app/bootstrap/bootstrap';

export const useOrgDates = () => {
  const orgTimeZone = useSelector(selectOrgTimezone);

  const getStartOfToday = useCallback(
    () => copySystemDateInTimeZone(startOfToday(), orgTimeZone),
    [orgTimeZone]
  );

  return {
    orgTimeZone,
    getStartOfToday,
  };
};
