import { DataType } from '@m3ter-com/m3ter-api';

import { useAppRoutesContext } from '@/routes/context';
import { CrudRouteType, getCrudRouteName } from '@/routes/crud';

const useCrudRouteNames = (dataType: DataType) => {
  const { routeExists } = useAppRoutesContext();

  const listName = getCrudRouteName(dataType, CrudRouteType.List);
  const detailsName = getCrudRouteName(dataType, CrudRouteType.Details);
  const createName = getCrudRouteName(dataType, CrudRouteType.Create);
  const editName = getCrudRouteName(dataType, CrudRouteType.Edit);

  return {
    listRouteName: routeExists(listName) ? listName : undefined,
    detailsRouteName: routeExists(detailsName) ? detailsName : undefined,
    createRouteName: routeExists(createName) ? createName : undefined,
    editRouteName: routeExists(editName) ? editName : undefined,
  };
};

export default useCrudRouteNames;
