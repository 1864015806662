import { AnyAction } from '@reduxjs/toolkit';
import {
  call,
  put,
  select,
  StrictEffect,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  DataType,
  AccountPlan,
  Commitment,
  Contract,
} from '@m3ter-com/m3ter-api';
import {
  getCleanDateInstance,
  getDatesSortOrder,
} from '@m3ter-com/console-core/utils';

import { OtherListIds } from '@/types/lists';

import { ids } from '@/util/data';
import { extractError } from '@/util/error';
import { listAllData, retrieveData } from '@/store/data/data.saga';
import { selectOrgTimezone } from '@/store/app/bootstrap/bootstrap';
import { refreshList, updateItem } from '@/store/crud';
import { updateItemSaga } from '@/store/crud/crud.saga';
import {
  addEntityLink,
  addEntityLinkFailure,
  AddEntityLinkFailureAction,
  addEntityLinkSuccess,
  AddEntityLinkSuccessAction,
} from '@/store/utils/linkEntity';

import {
  addAccountPlansToContract,
  addAccountPlansToContractComplete,
  addCommitmentsToContract,
  addCommitmentsToContractComplete,
  loadAccountContracts,
  loadAccountContractsFailure,
  loadAccountContractsSuccess,
  AddAccountPlansToContractAction,
  AddCommitmentsToContractAction,
  LoadAccountContractsAction,
  RemoveAccountPlanFromContractAction,
  RemoveCommitmentFromContractAction,
  removeAccountPlanFromContract,
  removeCommitmentFromContract,
} from './accountContracts';

export function* addAccountPlansToContractSaga(
  action: AddAccountPlansToContractAction
): Generator<StrictEffect, void, any> {
  const { contractId, accountPlanIds } = action.payload;
  const successNotification = action.meta.onSuccess.notification;

  yield put(
    addEntityLink(
      DataType.Contract,
      contractId,
      DataType.AccountPlan,
      accountPlanIds,
      successNotification
    )
  );

  // Wait for the failure / success action from linking the account plans and contract.
  const addEntityLinkResultAction:
    | AddEntityLinkFailureAction
    | AddEntityLinkSuccessAction = yield take(
    (awaitedAction: AnyAction) =>
      (awaitedAction.type === addEntityLinkFailure.type ||
        awaitedAction.type === addEntityLinkSuccess.type) &&
      awaitedAction.payload.parentDataType === DataType.Contract &&
      awaitedAction.payload.childDataType === DataType.AccountPlan
  );

  // If we succesfully linked the account plans to the contract, refresh the contract plans list
  if (addEntityLinkResultAction.type === addEntityLinkSuccess.type) {
    yield put(
      refreshList(DataType.AccountPlan, OtherListIds.ContractAccountPlans)
    );
  }

  yield put(addAccountPlansToContractComplete());
}

export function* addCommitmentsToContractSaga(
  action: AddCommitmentsToContractAction
): Generator<StrictEffect, void, any> {
  const { contractId, commitmentIds } = action.payload;
  const successNotification = action.meta.onSuccess.notification;
  yield put(
    addEntityLink(
      DataType.Contract,
      contractId,
      DataType.Commitment,
      commitmentIds,
      successNotification
    )
  );

  // Wait for the failure / success action from linking the commitments and contract.
  const addEntityLinkResultAction:
    | AddEntityLinkFailureAction
    | AddEntityLinkSuccessAction = yield take(
    (awaitedAction: AnyAction) =>
      (awaitedAction.type === addEntityLinkFailure.type ||
        awaitedAction.type === addEntityLinkSuccess.type) &&
      awaitedAction.payload.parentDataType === DataType.Contract &&
      awaitedAction.payload.childDataType === DataType.Commitment
  );

  // If we succesfully linked the commitments to the contract, refresh the contract commitments list.
  if (addEntityLinkResultAction.type === addEntityLinkSuccess.type) {
    yield put(
      refreshList(DataType.Commitment, OtherListIds.ContractCommitments)
    );
  }

  yield put(addCommitmentsToContractComplete());
}

export function* loadAccountContractsSaga(
  action: LoadAccountContractsAction
): Generator<StrictEffect, void, any> {
  const { accountId } = action.payload;

  try {
    const allAccountContracts: Array<Contract> = yield call(
      listAllData,
      DataType.Contract,
      { accountId }
    );

    allAccountContracts.sort((contractA, contractB) =>
      getDatesSortOrder(contractA.startDate, contractB.startDate)
    );

    const orgTimeZone = yield select(selectOrgTimezone);
    const activeAndPendingContracts = new Array<Contract>();
    const previousContracts = new Array<Contract>();
    const now = new Date();
    allAccountContracts.forEach((contract) => {
      const contractEndDate = getCleanDateInstance(
        contract.endDate,
        orgTimeZone
      );
      if (contractEndDate > now) {
        activeAndPendingContracts.push(contract);
      } else {
        previousContracts.push(contract);
      }
    });

    yield put(
      loadAccountContractsSuccess(
        ids(activeAndPendingContracts),
        ids(previousContracts)
      )
    );
  } catch (error) {
    yield put(loadAccountContractsFailure(extractError(error)));
  }
}

export function* removeAccountPlanFromContractSaga(
  action: RemoveAccountPlanFromContractAction
): Generator<StrictEffect, void, any> {
  const { accountPlanId } = action.payload;
  const successNotification = action.meta.onSuccess.notification;
  const failureNotification = action.meta.onFailure.notification;

  try {
    const accountPlan: AccountPlan = yield call(
      retrieveData,
      DataType.AccountPlan,
      accountPlanId
    );
    // Call the updateItemSaga directly so that this saga is blocked until the update is complete
    yield call(
      updateItemSaga,
      updateItem(
        DataType.AccountPlan,
        accountPlanId,
        {
          ...accountPlan,
          contractId: null,
        },
        successNotification,
        failureNotification
      )
    );

    yield put(
      refreshList(DataType.AccountPlan, OtherListIds.ContractAccountPlans)
    );
  } catch {
    // No-op
  }
}

export function* removeCommitmentFromContractSaga(
  action: RemoveCommitmentFromContractAction
): Generator<StrictEffect, void, any> {
  const { commitmentId } = action.payload;
  const successNotification = action.meta.onSuccess.notification;
  const failureNotification = action.meta.onFailure.notification;

  try {
    const commitment: Commitment = yield call(
      retrieveData,
      DataType.Commitment,
      commitmentId
    );
    // Call the updateItemSaga directly so that this saga is blocked until the update is complete
    yield call(
      updateItemSaga,
      updateItem(
        DataType.Commitment,
        commitmentId,
        {
          ...commitment,
          contractId: null,
        },
        successNotification,
        failureNotification
      )
    );

    yield put(
      refreshList(DataType.Commitment, OtherListIds.ContractCommitments)
    );
  } catch {
    // No-op
  }
}

export default function* accountContractsSaga() {
  yield takeEvery(
    addAccountPlansToContract.type,
    addAccountPlansToContractSaga
  );
  yield takeEvery(addCommitmentsToContract.type, addCommitmentsToContractSaga);
  yield takeLatest(loadAccountContracts.type, loadAccountContractsSaga);
  yield takeEvery(
    removeAccountPlanFromContract.type,
    removeAccountPlanFromContractSaga
  );
  yield takeEvery(
    removeCommitmentFromContract.type,
    removeCommitmentFromContractSaga
  );
}
