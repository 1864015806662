import {
  createSelector,
  createSlice,
  AnyAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  DataType,
  BillConfig,
  Organization,
  OrganizationConfig,
  SupportAccess,
} from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

import { selectAllByDataType, selectSingleton } from '@/store/data/data';

export enum BootstrapFailureReason {
  FatalError = 'FATAL_ERROR',
  NoOrgsAvailable = 'NO_ORGS_AVAILABLE',
  OrgDataLoadingFailure = 'ORG_DATA_LOADING_FAILURE',
  InvalidOrgId = 'INVALID_ORG',
}

export interface BootstrapState {
  currentOrgId: string;
  isBootstrapping: boolean;
  minorErrors: Array<AppError>;
  bootstrapFailureError?: AppError;
  bootstrapFailureReason?: BootstrapFailureReason;
}

interface BootstrapFailurePayload {
  reason: BootstrapFailureReason;
  error?: AppError;
  orgId?: string;
}
export type BootstrapFailureAction = PayloadAction<BootstrapFailurePayload>;

interface BootstrapSuccessPayload {
  orgId: string;
  errors: Array<AppError>;
}
export type BootstrapSuccessAction = PayloadAction<BootstrapSuccessPayload>;

interface ChangeOrgPayload {
  orgId: string;
}
export type ChangeOrgAction = PayloadAction<ChangeOrgPayload>;

const name = 'app/bootstrap';

export const initialState: BootstrapState = {
  currentOrgId: '',
  isBootstrapping: true,
  minorErrors: [],
};

const appSlice = createSlice({
  name,
  initialState,
  reducers: {
    bootstrapApp: (state: BootstrapState) => {
      return {
        ...initialState,
        currentOrgId: state.currentOrgId,
        isBootstrapping: true,
      };
    },
    bootstrapFailure: {
      reducer: (state: BootstrapState, action: BootstrapFailureAction) => {
        state.bootstrapFailureError = action.payload.error;
        state.bootstrapFailureReason = action.payload.reason;
        state.currentOrgId = action.payload.orgId ?? '';
        state.isBootstrapping = false;
      },
      prepare: (
        reason: BootstrapFailureReason,
        error?: AppError,
        orgId?: string
      ) => ({
        payload: { error, orgId, reason },
      }),
    },
    bootstrapSuccess: {
      reducer: (state: BootstrapState, action: BootstrapSuccessAction) => {
        state.minorErrors = action.payload.errors;
        state.currentOrgId = action.payload.orgId;
        state.isBootstrapping = false;
      },
      prepare: (orgId: string, errors: Array<AppError>) => ({
        payload: { errors, orgId },
      }),
    },
    changeOrg: {
      reducer: (_state: BootstrapState, _action: ChangeOrgAction) =>
        initialState,
      prepare: (orgId: string) => ({ payload: { orgId } }),
    },
  },
});

export const { bootstrapApp, bootstrapFailure, bootstrapSuccess, changeOrg } =
  appSlice.actions;

// When sagas want to listen for the bootstrapSuccess action, they likely only
// want to run when the org ID is a non-empty string.
export const isBootstrapSuccessActionWithOrgId = (action: AnyAction) =>
  action.type === bootstrapSuccess.type &&
  !!(action as BootstrapSuccessAction).payload.orgId;

// Selectors
const selectBootstrapState = (state: {
  app: { bootstrap: BootstrapState };
}): BootstrapState => state.app.bootstrap;

export const selectBootstrapFailureError = createSelector(
  selectBootstrapState,
  (state) => state.bootstrapFailureError
);

export const selectBootstrapFailureReason = createSelector(
  selectBootstrapState,
  (state) => state.bootstrapFailureReason
);

export const selectMinorBootstrapErrors = createSelector(
  selectBootstrapState,
  (state) => state.minorErrors
);

export const selectIsBootstrapping = createSelector(
  selectBootstrapState,
  (state) => state.isBootstrapping
);

export const selectCurrentOrgId = createSelector(
  selectBootstrapState,
  (state) => state.currentOrgId
);

// The below selectors are used to grab org-specific bits of data that
// we load before rendering anything in the console.
// As long as we use them in routes that are rendered after the root / org route,
// the non-null assertions should be safe.
export const selectStandardOrgs = selectAllByDataType<Organization>(
  DataType.Organization
);

export const selectSupportOrgs = selectAllByDataType<Organization>(
  DataType.SupportOrganization
);

export const selectAllOrgs = createSelector(
  selectStandardOrgs,
  selectSupportOrgs,
  (standardOrgs, supportOrgs) => [...standardOrgs, ...supportOrgs]
);

export const selectCurrentOrg = createSelector(
  selectCurrentOrgId,
  selectAllOrgs,
  (currentOrgId, allOrgs) => allOrgs.find((org) => org.id === currentOrgId)!
);

export const selectOrgConfig = (state: any) =>
  selectSingleton<OrganizationConfig>(DataType.OrganizationConfig)(state)!;

export const selectOrgTimezone = createSelector(
  selectOrgConfig,
  (orgConfig) => orgConfig.timezone
);

export const selectSupportAccess = (state: any) =>
  selectSingleton<SupportAccess>(DataType.SupportAccess)(state)!;

export const selectBillConfig = (state: any) =>
  selectSingleton<BillConfig>(DataType.BillConfig)(state)!;

export default appSlice.reducer;
