import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { DataType, Entity } from '@m3ter-com/m3ter-api';

import {
  loadSingleton,
  selectIsLoading,
  selectIsSaving,
  selectError,
  updateSingleton,
} from '@/store/singletons/singletons';
import { selectSingleton } from '@/store/data/data';
import { NotificationDefinition } from '@/store/store';

const useSingleton = <T extends Entity = Entity>(dataType: DataType) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSingleton(dataType));
  }, [dispatch, dataType]);

  const isLoading = useSelector(selectIsLoading(dataType));
  const isSaving = useSelector(selectIsSaving(dataType));
  const data = useSelector(selectSingleton<T>(dataType));
  const error = useSelector(selectError(dataType));

  const update = useCallback(
    (
      updatedData: T,
      successNotification?: NotificationDefinition,
      failureNotification?: NotificationDefinition,
      redirectTo?: string
    ) => {
      dispatch(
        updateSingleton(
          dataType,
          updatedData,
          successNotification,
          failureNotification,
          redirectTo
        )
      );
    },
    [dispatch, dataType]
  );

  return {
    isLoading,
    isSaving,
    error,
    data,
    update,
  };
};

export default useSingleton;
