import i18next from 'i18next';

import { DataType, TransactionType } from '@m3ter-com/m3ter-api';

import { EntityRouteListIds } from '@/types/lists';

import { createPickListRoute } from '@/routes/picklist';

export default () =>
  createPickListRoute<TransactionType>({
    path: 'transaction-types',
    dataType: DataType.TransactionType,
    title: i18next.t('common:transactionTypes'),
    listId: EntityRouteListIds.TransactionType,
  });
