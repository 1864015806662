import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';

import { DataType, Account, Id } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import {
  addEntityLink,
  selectIsLinkingEntity,
  removeEntityLink,
  selectIsUnlinkingEntity,
} from '@/store/utils/linkEntity';
import { NotificationDefinition } from '@/store/store';

const useAccountUpdate = (accountId: Id) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isOpen: isChildrenModalOpen,
    onOpen: onOpenChildrenModal,
    onClose: onCloseChildrenModal,
  } = useDisclosure();

  // Filter out the current account and any accounts that alread have a parent.
  const isAccountSelectionDisabled = useCallback(
    (item: Account) => !!item.parentAccountId || item.id === accountId,
    [accountId]
  );

  const childrenAddedSuccessNotification = useMemo<NotificationDefinition>(
    () => ({
      type: 'success',
      message: t('notifications:accountChildrenAddedSuccess'),
      removeAfter: 5000,
    }),
    [t]
  );
  const addChildrenToParentAccount = useCallback(
    (ids: Array<Id>) => {
      onCloseChildrenModal();
      if (ids.length > 0) {
        dispatch(
          addEntityLink(
            DataType.Account,
            accountId,
            DataType.Account,
            ids,
            childrenAddedSuccessNotification
          )
        );
      }
    },
    [
      onCloseChildrenModal,
      dispatch,
      accountId,
      childrenAddedSuccessNotification,
    ]
  );

  const removeAccountSuccessNotification = useMemo<NotificationDefinition>(
    () => ({
      type: 'success',
      message: t('notifications:childAccountRemovedSuccess'),
      removeAfter: 5000,
    }),
    [t]
  );
  const removeChildFromParentAccount = useCallback(
    (childAccountId: Id) => {
      dispatch(
        removeEntityLink(
          DataType.Account,
          null, // parentId is null to remove child from parent account.
          DataType.Account,
          childAccountId,
          removeAccountSuccessNotification
        )
      );
    },
    [dispatch, removeAccountSuccessNotification]
  );

  const isAddingChildrenSelector = useMemo(
    () => selectIsLinkingEntity(DataType.Account, DataType.Account),
    []
  );
  const isAddingChildren = useSelector(isAddingChildrenSelector);

  const isRemovingChildSelector = useMemo(
    () => selectIsUnlinkingEntity(DataType.Account, DataType.Account),
    []
  );
  const isRemovingChild = useSelector(isRemovingChildSelector);

  return {
    addChildrenToParentAccount,
    removeChildFromParentAccount,
    isAddingChildren,
    isRemovingChild,
    isAccountSelectionDisabled,
    isChildrenModalOpen,
    onOpenChildrenModal,
    onCloseChildrenModal,
  };
};

export default useAccountUpdate;
