import { ComponentSingleStyleConfig } from '@chakra-ui/react';

const underlineSize = '2px';

export const tabLink: ComponentSingleStyleConfig = {
  baseStyle: {
    position: 'relative',
    flex: '0 0 auto',
    marginBottom: `-${underlineSize}`,
    padding: '8px 16px',
    textAlign: 'center',
    color: 'inherit',
    borderWidth: `0 0 ${underlineSize} 0`,
    _hover: {
      textDecoration: 'none',
    },
  },
  variants: {
    active: {
      color: 'misc-highlight',
      borderColor: 'misc-highlight',
    },
  },
  defaultProps: {
    variant: 'default',
  },
};

const tabbedLinks: ComponentSingleStyleConfig = {
  baseStyle: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    width: '100%',
  },
};

export default tabbedLinks;
