import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Id } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import {
  addAccountPlansToContract,
  addCommitmentsToContract,
  removeAccountPlanFromContract,
  removeCommitmentFromContract,
  selectIsAddingAccountPlans,
  selectIsAddingCommitments,
} from '@/store/features/accounts/accountContracts';
import { NotificationDefinition } from '@/store/store';

const useContractUpdate = (contractId: Id) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const addAccountPlansSuccessNotification = useMemo<NotificationDefinition>(
    () => ({
      type: 'success',
      message: t('notifications:accountPlansContractLinkSuccess'),
      removeAfter: 5000,
    }),
    [t]
  );
  const addAccountPlans = useCallback(
    (accountPlanIds: Array<Id>) => {
      dispatch(
        addAccountPlansToContract(
          contractId,
          accountPlanIds,
          addAccountPlansSuccessNotification
        )
      );
    },
    [addAccountPlansSuccessNotification, contractId, dispatch]
  );

  const addCommitmentsSuccessNotification = useMemo<NotificationDefinition>(
    () => ({
      type: 'success',
      message: t('notifications:commitmentContractLinkSuccess'),
      removeAfter: 5000,
    }),
    [t]
  );
  const addCommitments = useCallback(
    (commitmentIds: Array<Id>) => {
      dispatch(
        addCommitmentsToContract(
          contractId,
          commitmentIds,
          addCommitmentsSuccessNotification
        )
      );
    },
    [addCommitmentsSuccessNotification, contractId, dispatch]
  );

  const removeAccountPlanSuccessNotification = useMemo<NotificationDefinition>(
    () => ({
      type: 'success',
      message: t('notifications:accountPlanContractUnlinkSuccess'),
      removeAfter: 5000,
    }),
    [t]
  );
  const removeAccountPlanFailureNotification = useMemo<NotificationDefinition>(
    () => ({
      type: 'error',
      message: t('notifications:accountPlanContractUnlinkFailure'),
      removeAfter: 5000,
    }),
    [t]
  );
  const removeAccountPlan = useCallback(
    (accountPlanId: Id) => {
      dispatch(
        removeAccountPlanFromContract(
          accountPlanId,
          removeAccountPlanSuccessNotification,
          removeAccountPlanFailureNotification
        )
      );
    },
    [
      dispatch,
      removeAccountPlanFailureNotification,
      removeAccountPlanSuccessNotification,
    ]
  );

  const removeCommitmentSuccessNotification = useMemo<NotificationDefinition>(
    () => ({
      type: 'success',
      message: t('notifications:commitmentContractUnlinkSuccess'),
      removeAfter: 5000,
    }),
    [t]
  );
  const removeCommitmentFailureNotification = useMemo<NotificationDefinition>(
    () => ({
      type: 'error',
      message: t('notifications:commitmentContractUnlinkFailure'),
      removeAfter: 5000,
    }),
    [t]
  );
  const removeCommitment = useCallback(
    (commitmentId: Id) => {
      dispatch(
        removeCommitmentFromContract(
          commitmentId,
          removeCommitmentSuccessNotification,
          removeCommitmentFailureNotification
        )
      );
    },
    [
      dispatch,
      removeCommitmentFailureNotification,
      removeCommitmentSuccessNotification,
    ]
  );

  const isAddingAccountPlans = useSelector(selectIsAddingAccountPlans);
  const isAddingCommitments = useSelector(selectIsAddingCommitments);

  return {
    isAddingAccountPlans,
    isAddingCommitments,
    addAccountPlans,
    addCommitments,
    removeAccountPlan,
    removeCommitment,
  };
};

export default useContractUpdate;
