import { QueryClient } from '@tanstack/react-query';

const setupQueryClient = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 10,
        retry: false,
      },
    },
  });

  return queryClient;
};

export default setupQueryClient;
