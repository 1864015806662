import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { RadioGroup, Stack, Radio } from '@chakra-ui/react';

export interface RadioGroupOption {
  value: string;
  label: string;
}

export interface FormRadioGroupProps {
  name: string;
  options: Array<RadioGroupOption>;
  stacked?: boolean;
}

export const FormRadioGroup: React.FC<FormRadioGroupProps> = ({
  name,
  options,
  stacked = false,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <RadioGroup value={value} onChange={onChange}>
          <Stack direction={stacked ? 'column' : 'row'} alignItems="start">
            {options.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      )}
    />
  );
};
