import { spawn } from 'redux-saga/effects';

import serviceUserCreateSaga from './service-user/serviceUserCreate.saga';
import serviceUserCredentialsSaga from './service-user/serviceUserCredentials.saga';
import supportAccessUpdateSaga from './support-access/supportAccessUpdate.saga';
import userActivationSaga from './users/userActivation.saga';
import userGroupCreateSaga from './user-groups/userGroupCreate.saga';

export default function* accessSaga() {
  yield spawn(serviceUserCreateSaga);
  yield spawn(serviceUserCredentialsSaga);
  yield spawn(supportAccessUpdateSaga);
  yield spawn(userActivationSaga);
  yield spawn(userGroupCreateSaga);
}
