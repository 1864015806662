import React from 'react';

import { DataType, Account } from '@m3ter-com/m3ter-api';

import { useCrudRetrieveData } from '@/components/common/crud/CrudRetrieveContext';
import { AccountContracts } from '@/components/features/accounts/AccountContracts';

export const ContractsListRoute: React.FC = () => {
  const account = useCrudRetrieveData<Account>(DataType.Account);

  return <AccountContracts account={account} />;
};
