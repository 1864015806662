import React from 'react';

import { Box, Heading, VStack } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert } from '@m3ter-com/ui-components';

import { isLocal } from '@/config';

export interface GenericErrorMessageProps {
  error: unknown;
  componentStack: string | null;
}

export const GenericErrorMessage: React.FC<GenericErrorMessageProps> = ({
  error,
  componentStack,
}) => {
  const { t } = useTranslation();

  return (
    <VStack spacing={4} alignItems="stretch">
      <Alert status="error" alertTitle={t('errors:generic.genericErrorTitle')}>
        {t('errors:generic.genericErrorDescription')}
      </Alert>
      {isLocal() && error instanceof Error && error.stack && (
        <Box data-testid="stack">
          <Heading size="sm">Stack trace</Heading>
          <pre>{error.stack}</pre>
        </Box>
      )}
      {isLocal() && componentStack && (
        <Box data-testid="component-stack">
          <Heading size="sm">Component stack</Heading>
          <pre>{componentStack}</pre>
        </Box>
      )}
    </VStack>
  );
};
