import React, { useMemo } from 'react';

import { Badge } from '@chakra-ui/react';

import { PlanGroup } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds } from '@/types/lists';

import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';

const searchFields = ['name', 'code'];

export const PlanGroupsListRoute: React.FC = () => {
  const { t } = useTranslation();

  const columns = useMemo<Array<ColumnDefinition<PlanGroup>>>(
    () => [
      { id: 'name', header: t('forms:labels.name'), accessor: 'name' },
      { id: 'code', header: t('forms:labels.code'), accessor: 'code' },
      {
        id: 'plan-type',
        header: t('features:plans.planGroupType'),
        accessor: (item: PlanGroup) =>
          item.accountId && (
            <Badge colorScheme="green">{t('common:custom')}</Badge>
          ),
      },
    ],
    [t]
  );

  return (
    <CrudList
      listId={EntityRouteListIds.PlanGroup}
      columns={columns}
      searchFields={searchFields}
    />
  );
};
