import React from 'react';

import { VStack } from '@chakra-ui/react';

import { PlanGroup } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { PlanGroupDetails } from '@/components/features/pricing/PlanGroupDetails';
import { PlanGroupLinksList } from '@/components/features/pricing/PlanGroupLinksList';

const PlansGroupsDetailsRouteComponent: React.FC<{ data: PlanGroup }> = ({
  data,
}) => (
  <VStack spacing={4} flex={2} alignItems="stretch">
    <PlanGroupDetails showEditButton showIsCustomDetails planGroup={data} />
    <PlanGroupLinksList planGroup={data} />
  </VStack>
);

export const PlanGroupsDetailsRoute: React.FC = () => (
  <CrudDetails<PlanGroup> component={PlansGroupsDetailsRouteComponent} />
);
