import React, { Suspense, useEffect } from 'react';

import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, HStack, Spinner } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { AppLayout, LoadingScreen } from '@m3ter-com/ui-components';

import {
  removeAuthRedirectPath,
  setAuthRedirectPath,
} from '@/util/localStorage';
import {
  selectBootstrapFailureError,
  selectBootstrapFailureReason,
  selectOrgTimezone,
  BootstrapFailureReason,
  changeOrg,
  selectIsBootstrapping,
} from '@/store/app/bootstrap/bootstrap';
import useAuth from '@/hooks/auth/useAuth';
import useOrg from '@/hooks/data/crud/useOrg';
import { TimezoneContextProvider } from '@/hooks/util/useDateFormatter';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import { Header } from '@/components/common/navigation/Header';
import { RecentsButton } from '@/components/common/navigation/RecentsButton';
import { WhatsNewButton } from '@/components/common/docs/WhatsNewButton';
import { TasksButton } from '@/components/common/tasks/TasksButton';
import { UserActions } from '@/components/common/user/UserActions';
import { Logo } from '@/components/common/brand/Logo';
import { PrimaryNavigation } from '@/components/common/navigation/PrimaryNavigation';
import { OrgSwitcher } from '@/components/common/navigation/OrgSwitcher';

const BootstrapFailureContent: React.FC = () => {
  const { t } = useTranslation();
  const appBootstrapFailureError = useSelector(selectBootstrapFailureError);
  const appBootstrapFailureReason = useSelector(selectBootstrapFailureReason)!;
  const { onSignOut, isM3terAdmin } = useAuth();

  return (
    <AppLayout
      header={null}
      actions={
        <HStack>
          <UserActions
            onLogOutClick={onSignOut}
            isM3terAdminEnabled={isM3terAdmin}
          />
        </HStack>
      }
      navigation={
        <Box width="100%">
          <Link to="/">
            <Logo marginRight="auto" marginLeft="auto" marginBottom={6} />
          </Link>
        </Box>
      }
      navigationBottomPanel={<OrgSwitcher />}
    >
      {appBootstrapFailureReason === BootstrapFailureReason.FatalError &&
        !!appBootstrapFailureError && (
          <ErrorAlert error={appBootstrapFailureError} />
        )}
      {appBootstrapFailureReason === BootstrapFailureReason.InvalidOrgId && (
        <ErrorAlert
          error={{ message: t('errors:organizations.invalidOrgId') }}
        />
      )}
      {appBootstrapFailureReason === BootstrapFailureReason.NoOrgsAvailable && (
        <ErrorAlert
          error={{ message: t('errors:organizations.noAvailableOrgs') }}
        />
      )}
      {appBootstrapFailureReason ===
        BootstrapFailureReason.OrgDataLoadingFailure && (
        <ErrorAlert
          title={t('errors:organizations.noUserPermissions')}
          error={{
            message: t('errors:organizations.orgDataLoadingFailure', {
              error: appBootstrapFailureError?.message,
            }),
          }}
        />
      )}
    </AppLayout>
  );
};

const BoostrapSuccessContent: React.FC = () => {
  const { onSignOut, isM3terAdmin } = useAuth();
  const orgTimezone = useSelector(selectOrgTimezone);

  return (
    <TimezoneContextProvider timeZone={orgTimezone}>
      <AppLayout
        header={<Header />}
        actions={
          <HStack>
            <RecentsButton />
            <WhatsNewButton />
            <TasksButton />
            <UserActions
              onLogOutClick={onSignOut}
              isM3terAdminEnabled={isM3terAdmin}
            />
            {/* Userflow resource centre placeholder */}
            <Box w="50px" data-testid="userflow" />
          </HStack>
        }
        navigation={
          <Box width="100%">
            <Link to="/">
              <Logo marginRight="auto" marginLeft="auto" marginBottom={6} />
            </Link>
            <PrimaryNavigation />
          </Box>
        }
        navigationBottomPanel={<OrgSwitcher />}
      >
        <Suspense fallback={<Spinner />}>
          <Outlet />
        </Suspense>
      </AppLayout>
    </TimezoneContextProvider>
  );
};

export const OrganizationRoute: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const { orgId: urlOrgId } = useParams();
  const { currentOrgId: stateOrgId } = useOrg();
  const isAppBootstrapping = useSelector(selectIsBootstrapping);
  const appBootstrapFailureReason = useSelector(selectBootstrapFailureReason);

  useEffect(() => {
    if (!isAppBootstrapping && urlOrgId && urlOrgId !== stateOrgId) {
      dispatch(changeOrg(urlOrgId));
    }
  }, [dispatch, isAppBootstrapping, stateOrgId, urlOrgId]);

  if (isAppBootstrapping) {
    setAuthRedirectPath(pathname + search);
    return <LoadingScreen />;
  }
  removeAuthRedirectPath();

  if (!stateOrgId) {
    return null;
  }

  return appBootstrapFailureReason ? (
    <BootstrapFailureContent />
  ) : (
    <BoostrapSuccessContent />
  );
};
