import { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { DataType, Entity, PathParams } from '@m3ter-com/m3ter-api';
import { EntityWithRelationships } from '@m3ter-com/console-core/types';

import {
  loadItem,
  clearItem,
  selectIsViewLoading,
  selectViewEntity,
  selectViewError,
  selectViewRelatedData,
} from '@/store/crud';

const useEntityRetrieve = <E extends Entity = Entity>(
  dataType: DataType,
  id: string,
  relationships?: Array<string>,
  pathParams?: PathParams
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadItem(dataType, id, relationships, pathParams));

    return () => {
      dispatch(clearItem(dataType));
    };
  }, [dispatch, dataType, id, pathParams, relationships]);

  // Memoize selectors otherwise they will be new functions per render.
  const isLoadingSelector = useMemo(
    () => selectIsViewLoading(dataType),
    [dataType]
  );
  const isLoading = useSelector(isLoadingSelector);

  const errorSelector = useMemo(() => selectViewError(dataType), [dataType]);
  const error = useSelector(errorSelector);

  const itemDataSelector = useMemo(
    () => selectViewEntity(dataType),
    [dataType]
  );
  const itemData = useSelector(itemDataSelector) as E | undefined;

  const relatedDataSelector = useMemo(
    () => selectViewRelatedData(dataType),
    [dataType]
  );
  const relatedData = useSelector(relatedDataSelector) as Record<string, any>;

  const itemWithAnyRelatedData = useMemo(() => {
    if (!relationships?.length || !relatedData) {
      return itemData;
    }

    return { ...itemData, ...relatedData } as EntityWithRelationships<E>;
  }, [itemData, relatedData, relationships]);

  return {
    error,
    isLoading,
    itemData: itemWithAnyRelatedData,
  };
};

export default useEntityRetrieve;
