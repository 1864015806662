import React, { useMemo } from 'react';

import { addDays } from 'date-fns';

import { Invitation } from '@m3ter-com/m3ter-api';
import { copySystemDateInTimeZone } from '@m3ter-com/console-core/utils';

import useDateFormatter from '@/hooks/util/useDateFormatter';
import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { UserInviteForm } from '@/components/features/access/invitation/UserInviteForm';

export const InvitationsCreateRoute: React.FC = () => {
  const { timeZone } = useDateFormatter();
  const initialValues = useMemo(() => {
    // Default the invite expiry end date to 30 days from now.
    const initialSystemExpiryDate = addDays(new Date(), 30);
    const initialExpiryDate = copySystemDateInTimeZone(
      initialSystemExpiryDate,
      timeZone
    );

    return {
      dtExpiry: initialExpiryDate.toISOString(),
      permissionPolicyIds: [],
    };
  }, [timeZone]);

  return (
    <CrudCreate<Invitation>
      form={UserInviteForm}
      initialValues={initialValues}
    />
  );
};
