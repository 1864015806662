import { useMemo } from 'react';

import { Params, useParams } from 'react-router-dom';

import { useAppRoutesContext } from '@/routes/context';

const useNamedLink = (
  name: string,
  params: Params = {},
  queryParams: Record<string, any> = {}
) => {
  const routerParams = useParams();
  const { getLink } = useAppRoutesContext();
  const combinedParams = useMemo(
    () => ({
      ...routerParams,
      ...params,
    }),
    [routerParams, params]
  );

  return getLink(name, combinedParams, queryParams);
};

export default useNamedLink;
