import React, { useCallback, useMemo } from 'react';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { DataType, AccountPlan, Contract } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { EntityWithRelationships } from '@m3ter-com/console-core/types';
import { Button } from '@m3ter-com/ui-components';

import { OtherListIds } from '@/types/lists';

import useDateFormatter from '@/hooks/util/useDateFormatter';
import useContractUpdate from '@/hooks/features/contracts/useContractUpdate';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';
import { EntityMultiSelectModal } from '@/components/common/data/EntitySelectModal';

export interface ContractPlansProps {
  contract: Contract;
}

const accountPlansListRelationships = ['plan', 'planGroup'];
const emptyArray = new Array<any>();

export const ContractPlans: React.FC<ContractPlansProps> = ({ contract }) => {
  const { accountId, id: contractId } = contract;
  const { t } = useTranslation();
  const { toLongDate } = useDateFormatter();
  const { isAddingAccountPlans, addAccountPlans, removeAccountPlan } =
    useContractUpdate(contractId);

  const contractPlansListQueryParams = useMemo(
    () => ({ account: accountId, contract: contractId, includeall: true }),
    [accountId, contractId]
  );
  const contractPlansListColumns = useMemo<
    Array<ColumnDefinition<AccountPlan>>
  >(
    () => [
      {
        id: 'plan-or-plan-group',
        accessor: (accountPlan: EntityWithRelationships<AccountPlan>) => (
          <HStack spacing={4}>
            <Link
              as={CrudDetailsLink}
              dataType={DataType.AccountPlan}
              id={accountPlan.id}
            >
              {accountPlan.planId
                ? accountPlan.plan.name
                : accountPlan.planGroup.name}
            </Link>
            <Badge>
              {accountPlan.planId ? t('common:plan') : t('common:planGroup')}
            </Badge>
          </HStack>
        ),
        header: t('features:contracts.plansListHeader'),
      },
      {
        id: 'start-date',
        accessor: (accountPlan) => toLongDate(accountPlan.startDate),
        header: t('forms:labels.startDate'),
      },
      {
        id: 'end-date',
        accessor: (accountPlan) =>
          accountPlan.endDate
            ? toLongDate(accountPlan.endDate)
            : `(${t('common:none')})`,
        header: t('forms:labels.endDate'),
      },
    ],
    [t, toLongDate]
  );
  const onRemoveAccountPlan = useCallback(
    (accountPlan: AccountPlan) => {
      removeAccountPlan(accountPlan.id);
    },
    [removeAccountPlan]
  );

  const {
    isOpen: isAccountPlansModalOpen,
    onOpen: onOpenAccountPlansModal,
    onClose: onCloseAccountPlansModal,
  } = useDisclosure();
  const addAccountPlansModalQueryParams = useMemo(
    () => ({ account: accountId, contract: '', includeall: true }),
    [accountId]
  );
  const addAccountPlansModalColumns = useMemo<
    Array<ColumnDefinition<AccountPlan>>
  >(
    () => [
      {
        id: 'plan-or-plan-group',
        accessor: (accountPlan: EntityWithRelationships<AccountPlan>) => (
          <HStack spacing={4}>
            <Text>
              {accountPlan.planId
                ? accountPlan.plan.name
                : accountPlan.planGroup.name}
            </Text>
            <Badge>
              {accountPlan.planId ? t('common:plan') : t('common:planGroup')}
            </Badge>
          </HStack>
        ),
        header: t('features:contracts.plansListHeader'),
      },
      {
        id: 'start-date',
        accessor: (accountPlan) => toLongDate(accountPlan.startDate),
        header: t('forms:labels.startDate'),
      },
      {
        id: 'end-date',
        accessor: (accountPlan) =>
          accountPlan.endDate
            ? toLongDate(accountPlan.endDate)
            : `(${t('common:none')})`,
        header: t('forms:labels.endDate'),
      },
    ],
    [t, toLongDate]
  );

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <HStack justifyContent="space-between">
            <Heading size="md">{t('features:contracts.plans')}</Heading>
            <Button
              size="sm"
              onClick={onOpenAccountPlansModal}
              isLoading={isAddingAccountPlans}
            >
              {t('features:contracts.addAccountPlans')}
            </Button>
          </HStack>
        </CardHeader>
        <CardBody>
          <CrudList<AccountPlan>
            listId={OtherListIds.ContractAccountPlans}
            columns={contractPlansListColumns}
            dataType={DataType.AccountPlan}
            params={contractPlansListQueryParams}
            relationships={accountPlansListRelationships}
            onDelete={onRemoveAccountPlan}
          />
        </CardBody>
      </Card>
      <EntityMultiSelectModal<AccountPlan>
        columns={addAccountPlansModalColumns}
        dataType={DataType.AccountPlan}
        isOpen={isAccountPlansModalOpen}
        onClose={onCloseAccountPlansModal}
        onConfirm={addAccountPlans}
        params={addAccountPlansModalQueryParams}
        relationships={accountPlansListRelationships}
        selected={emptyArray}
      />
    </React.Fragment>
  );
};
