import React, { PropsWithChildren, useCallback } from 'react';

import {
  Box,
  CloseButton,
  Flex,
  Icon,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaBook } from 'react-icons/fa';

import { Button, IconButton } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import usePreference from '@/hooks/data/usePreference';

export interface InlineDocsProps {
  id: string;
  docsLink?: string;
}

export const InlineDocs: React.FC<PropsWithChildren<InlineDocsProps>> = ({
  id,
  docsLink,
  children,
}) => {
  const { t } = useTranslation();

  const [dismissed, setDismissed] = usePreference(
    `docs-dismissed-${id}`,
    false
  );

  const onDismiss = useCallback(() => {
    setDismissed(true);
  }, [setDismissed]);

  const onShow = useCallback(() => {
    setDismissed(false);
  }, [setDismissed]);

  const bgColor = useColorModeValue('purple.100', 'purple.800');
  const iconColor = useColorModeValue('purple.800', 'purple.100');

  return !dismissed ? (
    <Flex gap={4} px={4} py={3} bg={bgColor} borderRadius="md">
      <Icon as={FaBook} boxSize={6} color={iconColor} />
      <Stack flex={1} alignItems="start">
        <Box>{children}</Box>
        {docsLink && (
          <Button
            size="sm"
            colorScheme="purple"
            as="a"
            href={docsLink}
            target="_blank"
          >
            {t('common:readDocs')}
          </Button>
        )}
      </Stack>
      <CloseButton alignSelf="start" justifySelf="end" onClick={onDismiss} />
    </Flex>
  ) : (
    <Box>
      <IconButton
        isRound
        size="sm"
        icon={<FaBook />}
        aria-label={t('common:readDocs')}
        onClick={onShow}
        colorScheme="purple"
      />
    </Box>
  );
};
