import React from 'react';

import { useWatch } from 'react-hook-form';
import { Text } from '@chakra-ui/react';

import { PlanTemplate } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

export interface PlanTemplateValueProps {
  fieldName: Exclude<keyof PlanTemplate, 'customFields'>;
  planTemplate?: PlanTemplate;
}

export const PlanTemplateValue: React.FC<PlanTemplateValueProps> = ({
  planTemplate,
  fieldName,
}) => {
  const { t } = useTranslation();
  const planValue = useWatch({ name: fieldName });

  return planTemplate ? (
    <Text>
      {t('features:pricing.valueFromTemplate')}:{' '}
      {planValue !== undefined && !Number.isNaN(planValue) ? (
        <s>{planTemplate[fieldName]}</s>
      ) : (
        planTemplate[fieldName]
      )}
    </Text>
  ) : null;
};
