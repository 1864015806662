import { useState, useCallback } from 'react';

import { onKeyDown } from '../components/forms/selects/utils';

const useSelectKeyDown = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onMenuOpen = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const onMenuClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const onSelectKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      onKeyDown(event, isMenuOpen);
    },
    [isMenuOpen]
  );

  return {
    onMenuOpen,
    onMenuClose,
    onSelectKeyDown,
  };
};

export default useSelectKeyDown;
