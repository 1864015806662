import React, { ReactNode } from 'react';

import { Box, forwardRef } from '@chakra-ui/react';

import { ButtonBadge } from './ButtonBadge';
import { Button, ButtonProps } from './Button';

export interface BadgeButtonProps extends ButtonProps {
  badge?: ReactNode;
}

export const BadgeButton = forwardRef<BadgeButtonProps, 'button'>(
  ({ badge, ...buttonProps }, ref) => (
    <Box position="relative" display="inline-block">
      <Button ref={ref} {...buttonProps} />
      {badge && <ButtonBadge>{badge}</ButtonBadge>}
    </Box>
  )
);
