import React, { ReactNode, PropsWithChildren, ReactElement } from 'react';

import { Params } from 'react-router-dom';
import { Link } from '@chakra-ui/react';

import { DataType, DataTypeToEntity } from '@m3ter-com/m3ter-api';

import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import { ReferenceLabel } from '@/components/common/data/ReferenceLabel';
import { Accessor } from '@/util/data';

export interface ReferenceLinkProps<DT extends DataType> {
  dataType: DT;
  id: string;
  accessor: Accessor<DataTypeToEntity[DT], ReactNode>;
  queryParams?: Record<string, any>;
  params?: Params;
}

export function ReferenceLink<DT extends DataType>({
  dataType,
  id,
  accessor,
  queryParams,
  params,
}: PropsWithChildren<ReferenceLinkProps<DT>>): ReactElement<any, any> {
  return (
    <Link
      as={CrudDetailsLink}
      dataType={dataType}
      id={id}
      queryParams={queryParams}
      params={params}
    >
      <ReferenceLabel dataType={dataType} id={id} accessor={accessor} />
    </Link>
  );
}
