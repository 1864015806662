import React from 'react';

import { Heading, useMultiStyleConfig } from '@chakra-ui/react';
import { format } from 'date-fns';

import { useCalendarContext } from './CalendarContext';
import { CalendarGrid } from './CalendarGrid';
import { CalendarNavigation } from './CalendarNavigation';

const CURRENT_MONTH_YEAR_FORMAT_KEY = 'MMMM yyyy';

export const Calendar: React.FC = () => {
  const {
    activeMonths: [currentMonth],
  } = useCalendarContext();

  const styles = useMultiStyleConfig('DatePicker', {});

  return (
    <React.Fragment>
      <CalendarNavigation sx={styles.calendarControlsWrapper}>
        <Heading
          data-testid="date-picker-calendar-title"
          as="h2"
          sx={styles.calendarHeader}
        >
          {format(currentMonth.date, CURRENT_MONTH_YEAR_FORMAT_KEY)}
        </Heading>
      </CalendarNavigation>
      <CalendarGrid month={currentMonth} />
    </React.Fragment>
  );
};
