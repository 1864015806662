import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { CreditLineItemsRoute } from '@/components/routes/billing/CreditLineItemsRoute';
import { CreditLineItemsCreateRoute } from '@/components/routes/billing/CreditLineItemsCreateRoute';
import { CreditLineItemsEditRoute } from '@/components/routes/billing/CreditLineItemsEditRoute';

export default () =>
  createCrudRoute({
    path: 'credit-line-items',
    dataType: DataType.CreditLineItem,
    element: <CreditLineItemsRoute />,
    create: <CreditLineItemsCreateRoute />,
    edit: <CreditLineItemsEditRoute />,
  });
