import React from 'react';

import { Aggregation } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { AggregationDetails } from '@/components/features/aggregations/AggregationDetails';

const AggregationsDetailsRouteComponent: React.FC<{ data: Aggregation }> = ({
  data,
}) => (
  <AggregationDetails
    showEditButton
    showSegments
    showCustomFields
    aggregation={data}
  />
);

export const AggregationsDetailsRoute: React.FC = () => (
  <CrudDetails<Aggregation> component={AggregationsDetailsRouteComponent} />
);
