import React from 'react';

import { DataType, Account } from '@m3ter-com/m3ter-api';

import { useCrudRetrieveData } from '@/components/common/crud/CrudRetrieveContext';
import { AccountCommitments } from '@/components/features/accounts/AccountCommitments';

export interface CommitmentsListRouteProps {}

export const CommitmentsListRoute: React.FC<CommitmentsListRouteProps> = () => {
  const account = useCrudRetrieveData<Account>(DataType.Account);

  return <AccountCommitments account={account} />;
};
