import React, { PropsWithChildren, ReactNode, useMemo } from 'react';

import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  Heading,
  Stack,
  StackDivider,
} from '@chakra-ui/react';

import { DataType, Entity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button } from '@m3ter-com/ui-components';

import { isAuditedEntity, isEntityWithCustomFields } from '@/util/data';
import { AuditData } from '@/components/common/data/AuditData';
import { CrudEditLink } from '@/components/common/navigation/CrudEditLink';
import { CustomFieldsDetails } from '@/components/common/data/CustomFieldsDetails';
import useEntityNamings from '@/hooks/util/useEntityNamings';

export interface DetailsCardProps {
  details: ReactNode;
  extraDetails?: ReactNode;
  header?: ReactNode;
  dataType: DataType;
  data: Entity;
  entityNameOverride?: string;
  maxCustomfields?: number;
  showAuditData?: boolean;
  showCustomFields?: boolean;
  showEditButton?: boolean;
}

export const DetailsCard: React.FC<PropsWithChildren<DetailsCardProps>> = ({
  children,
  details,
  extraDetails,
  header,
  dataType,
  data,
  entityNameOverride,
  maxCustomfields,
  showAuditData = true,
  showCustomFields = false,
  showEditButton = false,
}) => {
  const { t } = useTranslation();

  const entityNamings = useEntityNamings(dataType);
  const entityName = useMemo(
    () => entityNameOverride ?? entityNamings.singular,
    [entityNamings, entityNameOverride]
  );

  const canDisplayAuditData = isAuditedEntity(data) && showAuditData;
  const canDisplayCustomFields =
    isEntityWithCustomFields(data) &&
    Object.keys(data?.customFields ?? {}).length > 0;

  return (
    <Stack spacing={4}>
      <Card data-testid="details-card">
        <CardHeader>
          <Flex justify="space-between" alignItems="center">
            <Heading size="md" whiteSpace="nowrap">
              {t('common:entityDetails', { entityName })}
            </Heading>
            {header}
            {showEditButton && (
              <Button
                intent="primary"
                size="sm"
                as={CrudEditLink}
                addReturnPath
                dataType={dataType}
                id={data.id}
              >
                {t('common:edit')}
              </Button>
            )}
          </Flex>
        </CardHeader>
        <CardBody>
          <Stack w="100%" spacing={4} divider={<StackDivider />}>
            <Grid
              templateColumns={showCustomFields ? '3fr 1fr' : '1fr'}
              gap={4}
            >
              <Box h="max-content">{details}</Box>
              {showCustomFields && (
                <GridItem
                  p={2}
                  h="max-content"
                  borderRadius={4}
                  backgroundColor="chakra-body-bg"
                >
                  <Heading size="xs" mb={2} as="h4">
                    {t('common:customFields')}
                  </Heading>
                  {canDisplayCustomFields ? (
                    <CustomFieldsDetails
                      customFields={data.customFields!} // hasCustomFields validates entity custom fields
                      initialListItemCount={maxCustomfields}
                    />
                  ) : (
                    t('common:none')
                  )}
                </GridItem>
              )}
            </Grid>
            {/* extraDetails is for below main details section (renders below custom fields) */}
            {extraDetails && <Box>{extraDetails}</Box>}
            {canDisplayAuditData && (
              <AuditData data={data} variant="horizontal" />
            )}
          </Stack>
        </CardBody>
      </Card>
      {children}
    </Stack>
  );
};
