import React from 'react';

import { DataType, NotificationRule } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { NotificationRuleDetailsRoute } from '@/components/routes/events/notification-rules/NotificationRuleDetailsRoute';
import { NotificationRuleEditRoute } from '@/components/routes/events/notification-rules/NotificationRuleEditRoute';
import { NotificationRulesCreateRoute } from '@/components/routes/events/notification-rules/NotificationRulesCreateRoute';
import { ConnectedEventsListRoute } from '@/components/routes/events/notification-rules/ConnectedEventsListRoute';
import { NotificationRulesRoute } from '@/components/routes/events/notification-rules/NotificationRulesRoute';
import { NotificationRulesListRoute } from '@/components/routes/events/notification-rules/NotificationRulesListRoute';
import { NotificationRulesRetrieveRoute } from '@/components/routes/events/notification-rules/NotificationsRoute';
import { CrudRetrieve } from '@/components/common/crud/CrudRetrieve';

export default () =>
  createCrudRoute<NotificationRule>({
    path: 'notification-rules',
    dataType: DataType.NotificationRule,
    element: <NotificationRulesRoute />,
    list: <ConnectedEventsListRoute />,
    create: <NotificationRulesCreateRoute />,
    edit: <NotificationRuleEditRoute />,
    details: <NotificationRuleDetailsRoute />,
    retrieve: (
      <CrudRetrieve<NotificationRule>
        element={<NotificationRulesRetrieveRoute />}
        titleAccessor="name"
      />
    ),
    directChildRoutes: [
      {
        name: 'notification-rule-by-event-name',
        path: 'by-event/:eventName',
        element: <NotificationRulesListRoute />,
      },
      {
        name: 'unfiltered-notification-rules',
        path: 'view-all',
        element: <NotificationRulesListRoute />,
      },
    ],
  });
