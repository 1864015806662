import React from 'react';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';

const LazyPrepaymentsStatusReportRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "PrepaymentsStatusReportRoute" */ '@/components/routes/reports/PrepaymentsStatusReportRoute'
  ).then((module) => ({
    default: module.PrepaymentsStatusReportRoute,
  }))
);

export const name = 'reports.prepaymentsStatus';

export default (): NamedRoute => ({
  name,
  path: 'prepayments-status',
  element: <LazyPrepaymentsStatusReportRoute />,
});
