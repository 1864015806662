import { useCallback, useState } from 'react';

import { DataType, PathParams, QueryParams } from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

import { performDataAction } from '@/services/api';
import { extractError } from '@/util/error';

// Allows an onClick on an element to trigger a download after
// getting the download URL from an API data action.
const useDownloadUrl = (
  dataType: DataType,
  actionName: string,
  pathParams: PathParams = {},
  queryParams: QueryParams = {},
  data: Record<string, any> = {},
  downloadKey: string = 'downloadUrl'
) => {
  const [isDownloadingUrl, setIsDownloadingUrl] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState<undefined | AppError>();

  const download = useCallback(() => {
    setIsDownloadingUrl(true);
    setDownloadError(undefined);

    performDataAction(dataType, actionName, pathParams, queryParams, data)
      .then((response) => {
        setIsDownloadingUrl(false);
        window.open(response[downloadKey]);
      })
      .catch((error) => {
        setIsDownloadingUrl(false);
        setDownloadError(extractError(error));
      });
  }, [actionName, data, dataType, downloadKey, pathParams, queryParams]);

  return { download, isDownloadingUrl, downloadError };
};

export default useDownloadUrl;
