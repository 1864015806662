import React from 'react';

import { Link } from 'react-router-dom';
import { Heading, SimpleGrid, Text, VStack } from '@chakra-ui/react';

import {
  DataType,
  CustomFieldDefaults,
  CustomFields,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, KeyValue } from '@m3ter-com/ui-components';

import useSingleton from '@/hooks/data/useSingleton';
import { DetailsCard } from '@/components/common/data/DetailsCard';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch';

export interface OrganizationCustomFieldsDetailsProps {
  editUrl: string;
}

export interface CustomFieldsDisplayProps {
  customFields?: CustomFields;
}

const CustomFieldsDisplay: React.FC<CustomFieldsDisplayProps> = ({
  customFields = {},
}) => {
  const { t } = useTranslation();

  const keys = Object.keys(customFields);

  return keys.length > 0 ? (
    <SimpleGrid columns={4}>
      {keys.map((key) => (
        <KeyValue key={key} label={key} value={customFields[key]} />
      ))}
    </SimpleGrid>
  ) : (
    <Text>{t('features:customFields.noCustomFieldsDefined')}</Text>
  );
};

export const OrganizationCustomFieldsDetails: React.FC<
  OrganizationCustomFieldsDetailsProps
> = ({ editUrl }) => {
  const { t } = useTranslation();
  const {
    isLoading,
    error,
    data: customFields,
  } = useSingleton<CustomFieldDefaults>(DataType.OrganizationCustomFields);

  return (
    <LoadingErrorContentSwitch isLoading={isLoading} error={error}>
      {!!customFields && (
        <DetailsCard
          data={customFields}
          dataType={DataType.OrganizationCustomFields}
          header={
            <Button intent="primary" as={Link} to={editUrl} size="sm">
              {t('common:edit')}
            </Button>
          }
          details={
            <VStack alignItems="stretch" spacing={4}>
              <Heading size="sm">{t('common:organization')}</Heading>
              <CustomFieldsDisplay customFields={customFields?.organization} />
              <Heading size="sm">{t('common:product')}</Heading>
              <CustomFieldsDisplay customFields={customFields?.product} />
              <Heading size="sm">{t('common:meter')}</Heading>
              <CustomFieldsDisplay customFields={customFields?.meter} />
              <Heading size="sm">{t('common:plan')}</Heading>
              <CustomFieldsDisplay customFields={customFields?.plan} />
              <Heading size="sm">{t('common:planTemplate')}</Heading>
              <CustomFieldsDisplay customFields={customFields?.planTemplate} />
              <Heading size="sm">{t('common:account')}</Heading>
              <CustomFieldsDisplay customFields={customFields?.account} />
              <Heading size="sm">{t('common:accountPlan')}</Heading>
              <CustomFieldsDisplay customFields={customFields?.accountPlan} />
              <Heading size="sm">{t('common:aggregation')}</Heading>
              <CustomFieldsDisplay customFields={customFields?.aggregation} />
              <Heading size="sm">{t('common:compoundAggregation')}</Heading>
              <CustomFieldsDisplay
                customFields={customFields?.compoundAggregation}
              />
            </VStack>
          }
        />
      )}
    </LoadingErrorContentSwitch>
  );
};
