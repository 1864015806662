import wretch from 'wretch';

import { Id, IngestMeasurementDatum } from '../types';

import { getOrganizationPath } from '../util/path';
import { ingestPost, post } from '../client';

interface GenerateUploadUrlResponse {
  headers: Record<string, string>;
  jobId: Id;
  url: string;
}

export interface UploadMeasurementsResponse {
  jobId: Id;
}

export interface IngestRequestBody {
  measurements: Array<IngestMeasurementDatum>;
}

export const uploadMeasurements = async (
  organizationId: string,
  file: File
) => {
  // Generate the upload URL.
  const uploadUrlResponse = (await post({
    path: getOrganizationPath('/fileuploads/measurements/generateUploadUrl'),
    pathParams: { organizationId },
    body: {
      fileName: file.name,
      contentType: 'text/json',
      contentLength: file.size,
    },
  })) as GenerateUploadUrlResponse;

  // The request to S3 shouldn't include our auth headers so we use a fresh client.
  await wretch(uploadUrlResponse.url)
    .headers(uploadUrlResponse.headers)
    .body(file)
    .put()
    .res();

  return { jobId: uploadUrlResponse.jobId } as UploadMeasurementsResponse;
};

export const submitMeasurements = (
  organizationId: string,
  data: IngestRequestBody
) =>
  ingestPost({
    path: getOrganizationPath('/measurements'),
    pathParams: { organizationId },
    body: data,
  });
