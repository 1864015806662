import React from 'react';

import { NamedRoute } from '@/types/routes';

import { AccountExternalMappingsRoute } from '@/components/routes/accounts/AccountExternalMappingsRoute';

export default (): NamedRoute => ({
  name: 'account.external-mappings',
  path: 'external-mappings',
  element: <AccountExternalMappingsRoute />,
});
