import React from 'react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert } from '@m3ter-com/ui-components';

export enum AggregationType {
  Aggregations = 'AGGREGATIONS',
  CompoundAggregations = 'COMPOUND_AGGREGATIONS',
}

export interface AggregationPricingListAlertProps {
  aggregationType: AggregationType;
  isPricingEditorAlert?: boolean;
}

export const AggregationPricingListAlert: React.FC<
  AggregationPricingListAlertProps
> = ({ aggregationType, isPricingEditorAlert = false }) => {
  const { t } = useTranslation();

  return (
    <Alert mb={4} w="100%" status="info">
      {t(
        `features:pricing.${
          isPricingEditorAlert
            ? 'aggregationsEditorListSelectInfo'
            : 'aggregationsListSelectInfo'
        }`,
        {
          entityName:
            aggregationType === AggregationType.Aggregations
              ? t('common:aggregations')
              : t('common:compoundAggregations'),
        }
      )}
    </Alert>
  );
};
