import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { selectAllBreadcrumbs } from '@/store/page/page';
import useOrg from '@/hooks/data/crud/useOrg';

export interface DocumentTitleProps {
  siteTitle: string;
}

export const DocumentTitle: React.FC<DocumentTitleProps> = ({ siteTitle }) => {
  const breadcrumbs = useSelector(selectAllBreadcrumbs);
  const { currentOrg } = useOrg();

  useEffect(() => {
    const parts = [siteTitle];
    // 'currentOrg' will be undefined before the app is bootstrapped so we need this check here.
    if (currentOrg?.organizationName) {
      parts.unshift(currentOrg.organizationName);
    }
    breadcrumbs.forEach((breadcrumb) => {
      parts.unshift(breadcrumb.title);
    });

    document.title = parts.join(' - ');
  }, [siteTitle, breadcrumbs, currentOrg]);

  return null;
};
