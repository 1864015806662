import React from 'react';

import {
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
} from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormSwitch,
} from '@m3ter-com/console-core/components';

export interface GenerateStatementsFormValues {
  generateCsvStatement: boolean;
}

export interface GenerateStatementsModalProps {
  initialValues?: Partial<GenerateStatementsFormValues>;
  isOpen?: boolean;
  onClose: () => void;
  onSubmit: (data: GenerateStatementsFormValues) => void;
}

const defaultInitialValues: Partial<GenerateStatementsFormValues> = {};

export const GenerateStatementsModal: React.FC<
  GenerateStatementsModalProps
> = ({
  initialValues = defaultInitialValues,
  isOpen = false,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          <ModalHeader>
            {t('features:statements.generateStatements')}
          </ModalHeader>
          <ModalBody>
            <FormStack>
              <FormControl display="flex" alignItems="center">
                <Switch isDisabled isChecked mr={2} />
                <FormLabel mb="0">
                  {t('features:statements.generateJsonStatement')}
                </FormLabel>
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <FormSwitch name="generateCsvStatement" mr={2} />
                <FormLabel mb="0">
                  {t('features:statements.generateCsvStatement')}
                </FormLabel>
              </FormControl>
            </FormStack>
          </ModalBody>
          <ModalFooter>
            <FormActions
              submitText={t('features:statements.generate')}
              cancelText={t('common:cancel')}
              onCancel={onClose}
            />
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
};
