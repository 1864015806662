import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { ServiceUsersRoute } from '@/components/routes/settings/service-users/ServiceUsersRoute';
import { ServiceUsersListRoute } from '@/components/routes/settings/service-users/ServiceUsersListRoute';
import { ServiceUsersDetailsRoute } from '@/components/routes/settings/service-users/ServiceUsersDetailsRoute';
import { ServiceUsersCreateRoute } from '@/components/routes/settings/service-users/ServiceUsersCreateRoute';
import { ServiceUsersEditRoute } from '@/components/routes/settings/service-users/ServiceUsersEditRoute';

export default () =>
  createCrudRoute({
    path: 'service-users',
    dataType: DataType.ServiceUser,
    element: <ServiceUsersRoute />,
    list: <ServiceUsersListRoute />,
    details: <ServiceUsersDetailsRoute />,
    create: <ServiceUsersCreateRoute />,
    edit: <ServiceUsersEditRoute />,
  });
