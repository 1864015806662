import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { UserInvite } from '@m3ter-com/m3ter-api';

const suite = create('userInvite', (data: Partial<UserInvite>) => {
  test(
    'firstName',
    i18next.t('forms:validations.users.firstNameRequired'),
    () => {
      enforce(data.firstName).isNotEmpty();
    }
  );

  test(
    'lastName',
    i18next.t('forms:validations.users.lastNameRequired'),
    () => {
      enforce(data.lastName).isNotEmpty();
    }
  );

  test(
    'email',
    i18next.t('forms:validations.users.emailAddressRequired'),
    () => {
      enforce(data.email).isNotEmpty();
    }
  );

  test(
    'dtExpiry',
    i18next.t('forms:validations.users.inviteExpiryDateRequired'),
    () => {
      enforce(data.dtExpiry).isNotEmpty();
    }
  );
});

export default suite;
