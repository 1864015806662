import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataType, Id, PlanTemplate } from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

import { createSelectByIds } from '@/store/data/data';

export interface PlanTemplatesState {
  isLoading: boolean;
  isLoaded: boolean;
  planTemplateIds?: Array<Id>;
  error?: AppError;
}

export type LoadPlanTemplatesAction = PayloadAction<undefined>;
interface LoadPlanTemplatesSuccessPayload {
  planTemplateIds: Array<Id>;
}
export type LoadPlanTemplatesSuccessAction =
  PayloadAction<LoadPlanTemplatesSuccessPayload>;
export type LoadPlanTemplatesFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

const name = 'features/pricing/planTemplates';

const initialState: PlanTemplatesState = {
  isLoading: false,
  isLoaded: false,
};

const planTemplatesSlice = createSlice({
  name,
  initialState,
  reducers: {
    loadPlanTemplates: (
      state: PlanTemplatesState,
      _action: LoadPlanTemplatesAction
    ) => {
      state.isLoading = true;
      state.isLoaded = false;
      state.error = undefined;
    },
    loadPlanTemplatesSuccess: {
      reducer: (
        state: PlanTemplatesState,
        action: LoadPlanTemplatesSuccessAction
      ) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.planTemplateIds = action.payload.planTemplateIds;
      },
      prepare: (planTemplateIds: Array<string>) => ({
        payload: { planTemplateIds },
      }),
    },
    loadPlanTemplatesFailure: {
      reducer: (
        state: PlanTemplatesState,
        action: LoadPlanTemplatesFailureAction
      ) => {
        state.isLoading = false;
        state.error = action.error;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    reset: () => initialState,
  },
});

export const {
  loadPlanTemplates,
  loadPlanTemplatesFailure,
  loadPlanTemplatesSuccess,
  reset,
} = planTemplatesSlice.actions;

const selectPlanTemplatesState = (state: {
  features: { pricing: { planTemplates: PlanTemplatesState } };
}): PlanTemplatesState => state.features.pricing.planTemplates;

export const selectIsLoading = createSelector(
  selectPlanTemplatesState,
  (planTemplatesState) => planTemplatesState.isLoading
);
export const selectIsLoaded = createSelector(
  selectPlanTemplatesState,
  (planTemplatesState) => planTemplatesState.isLoaded
);

export const selectError = createSelector(
  selectPlanTemplatesState,
  (planTemplatesState) => planTemplatesState.error
);

export const selectPlanTemplateIds = createSelector(
  selectPlanTemplatesState,
  (planTemplatesState) => planTemplatesState.planTemplateIds
);

export const selectPlanTemplates = createSelector(
  selectPlanTemplateIds,
  createSelectByIds<PlanTemplate>(DataType.PlanTemplate),
  (planTemplateIds, selectByIds) => selectByIds(planTemplateIds)
);

export default planTemplatesSlice.reducer;
