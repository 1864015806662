import React from 'react';

import {
  Badge,
  Box,
  Heading,
  List,
  ListItem,
  SimpleGrid,
  StackDivider,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';

import { DataType, PermissionPolicy } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import { DetailsCard } from '@/components/common/data/DetailsCard';

export interface PermissionPolicyDetailsProps {
  data: PermissionPolicy;
}

export const PermissionPolicyDetails: React.FC<
  PermissionPolicyDetailsProps
> = ({ data: permissionPolicy }) => {
  const { t } = useTranslation();

  return (
    <DetailsCard
      data={permissionPolicy}
      dataType={DataType.PermissionPolicy}
      showEditButton={!permissionPolicy.managedPolicy}
      details={
        <VStack spacing={6} alignItems="stretch" divider={<StackDivider />}>
          <SimpleGrid columns={2} gap={6} w="100%">
            <KeyValue
              label={t('forms:labels.name')}
              value={permissionPolicy.name}
            />
            <KeyValue
              label={t('features:access.permissionPolicyType')}
              value={
                <Badge>
                  {permissionPolicy.managedPolicy
                    ? t('features:access.managed')
                    : t('common:custom')}
                </Badge>
              }
            />
          </SimpleGrid>
          <Box>
            <Heading size="sm" mb={4}>
              {t('features:access.permissionPolicyStatements')}
            </Heading>
            <Table data-testid="statements">
              <Thead>
                <Tr>
                  <Th w="30%">{t('forms:labels.effect')}</Th>
                  <Th w="30%">{t('forms:labels.action')}</Th>
                  <Th w="40%">{t('forms:labels.resource')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {permissionPolicy.permissionPolicy.map((statement, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Tr verticalAlign="top" key={index}>
                    <Td>{t(`features:access.effect.${statement.effect}`)}</Td>
                    <Td>
                      <List>
                        {statement.action.map((actionItem) => (
                          <ListItem key={actionItem}>{actionItem}</ListItem>
                        ))}
                      </List>
                    </Td>
                    <Td>
                      <List>
                        {statement.resource.map((resourceItem) => (
                          <ListItem key={resourceItem}>{resourceItem}</ListItem>
                        ))}
                      </List>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </VStack>
      }
    />
  );
};
