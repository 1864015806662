import React from 'react';

import { DataType, OrganizationAdmin } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { OrganizationAdminRoute } from '@/components/routes/admin/Organizations/OrganizationAdminRoute';
import { OrganizationAdminListRoute } from '@/components/routes/admin/Organizations/OrganizationAdminListRoute';
import { OrganizationAdminDetailsRoute } from '@/components/routes/admin/Organizations/OrganizationAdminDetailsRoute';
import { OrganizationAdminCreateRoute } from '@/components/routes/admin/Organizations/OrganizationAdminCreateRoute';
import { OrganizationAdminEditRoute } from '@/components/routes/admin/Organizations/OrganizationAdminEditRoute';

export default () =>
  createCrudRoute<OrganizationAdmin>({
    path: 'organizations',
    dataType: DataType.OrganizationAdmin,
    element: <OrganizationAdminRoute />,
    list: <OrganizationAdminListRoute />,
    details: <OrganizationAdminDetailsRoute />,
    create: <OrganizationAdminCreateRoute />,
    edit: <OrganizationAdminEditRoute />,
  });
