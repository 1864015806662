import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const parts = ['list', 'label', 'value'];

const keyValueList: ComponentMultiStyleConfig = {
  parts,
  baseStyle: {
    list: {
      display: 'grid',
      gridTemplateColumns: 'minmax(0, max-content) minmax(0, max-content)',
      gridRowGap: 1,
      gridColumnGap: 4,
      alignItems: 'baseline',
    },
    label: {
      color: 'chakra-subtle-text',
      fontSize: 'sm',
    },
    value: {
      color: 'chakra-body-text',
      gridColumn: 2,
    },
  },
};

export default keyValueList;
