import React, {
  useContext,
  useMemo,
  PropsWithChildren,
  ReactElement,
} from 'react';

import { DataType, DataTypeToEntity, Entity } from '@m3ter-com/m3ter-api';

export interface CrudRetrieveContextProviderProps<E extends Entity> {
  data: E;
  dataType: DataType;
}

type CrudRetrieveContextValue = {
  [DT in keyof DataTypeToEntity]?: DataTypeToEntity[DT];
};

const defaultValue = {};

const CrudRetrieveContext =
  React.createContext<CrudRetrieveContextValue>(defaultValue);

CrudRetrieveContext.displayName = 'CrudRetrieveContext';

export const useCrudRetrieveContext = () =>
  useContext(CrudRetrieveContext) as CrudRetrieveContextValue;

export function CrudRetrieveContextProvider<E extends Entity>({
  children,
  data,
  dataType,
}: PropsWithChildren<CrudRetrieveContextProviderProps<E>>): ReactElement<
  any,
  any
> | null {
  const parentContext = useCrudRetrieveContext() ?? defaultValue;

  const value = useMemo(
    () => ({
      ...parentContext,
      [dataType]: data,
    }),
    [parentContext, dataType, data]
  );

  return (
    <CrudRetrieveContext.Provider value={value}>
      {children}
    </CrudRetrieveContext.Provider>
  );
}

export const useCrudRetrieveData = <E extends Entity = Entity>(
  dataType: DataType
) => {
  const value = useCrudRetrieveContext();
  return value[dataType] as E;
};

export default CrudRetrieveContext;
