import React from 'react';

import { Customer } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { CustomerDetails } from '@/components/features/admin/customers/CustomerDetails';

export const CustomersAdminDetailsRoute: React.FC = () => (
  <CrudDetails<Customer> component={CustomerDetails} />
);
