import React from 'react';

import { HStack, List, ListItem, Text } from '@chakra-ui/react';
import { FaCheck, FaMinus, FaTimes } from 'react-icons/fa';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import { PasswordValidationErrors } from '@/hooks/auth/usePasswordValidator';

interface PasswordValidationListProps {
  passwordValidationErrors: PasswordValidationErrors;
}

interface PasswordValidationErrorProps {
  validator?: boolean;
  helpText: string;
}

const PasswordValidationError: React.FC<PasswordValidationErrorProps> = ({
  validator,
  helpText,
}) => {
  return (
    <ListItem>
      <HStack w="100%" alignItems="center" spacing={2}>
        {validator === undefined && <FaMinus />}
        {validator !== undefined &&
          (validator ? (
            <FaCheck data-testid="valid-password-requirement" color="green" />
          ) : (
            <FaTimes data-testid="invalid-password-requirement" color="red" />
          ))}
        <Text>{helpText}</Text>
      </HStack>
    </ListItem>
  );
};

export const PasswordValidationList: React.FC<PasswordValidationListProps> = ({
  passwordValidationErrors,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Text>{t('features:auth.passwordRequirements')}</Text>
      <List>
        <PasswordValidationError
          validator={passwordValidationErrors.isLengthValid}
          helpText={t('features:auth.passwordValidation.validLength')}
        />
        <PasswordValidationError
          validator={passwordValidationErrors.hasUpperCase}
          helpText={t('features:auth.passwordValidation.validUppercase')}
        />
        <PasswordValidationError
          validator={passwordValidationErrors.hasLowerCase}
          helpText={t('features:auth.passwordValidation.validLowercase')}
        />
        <PasswordValidationError
          validator={passwordValidationErrors.hasNumber}
          helpText={t('features:auth.passwordValidation.validNumber')}
        />
        <PasswordValidationError
          validator={passwordValidationErrors.hasSpecialCharacterOrSpace}
          helpText={t('features:auth.passwordValidation.validSpecialCharacter')}
        />
        <PasswordValidationError
          validator={passwordValidationErrors.hasNoLeadingOrTrailingSpace}
          helpText={t('features:auth.passwordValidation.validWhiteSpace')}
        />
      </List>
    </React.Fragment>
  );
};
