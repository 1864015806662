import React from 'react';

import { Destination } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { DestinationForm } from '@/components/features/destinations/DestinationForm';

export const DestinationEditRoute: React.FC = () => (
  <CrudEdit<Destination> form={DestinationForm} />
);
