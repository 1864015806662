import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  UseDatepickerProps,
  START_DATE,
  useDatepicker,
  FocusedInput,
  END_DATE,
} from '@datepicker-react/hooks';
import {
  Heading,
  HStack,
  InputGroup,
  InputRightAddon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  StackDivider,
  useDisclosure,
  useMultiStyleConfig,
  VStack,
  Box,
} from '@chakra-ui/react';
import { FaCalendar, FaLongArrowAltRight, FaTimes } from 'react-icons/fa';
import { format } from 'date-fns';

import { Button, IconButton } from '../../controls/Button';
import { CalendarContextProvider, CalendarTranslations } from '../Calendar';
import { CalendarGrid } from '../Calendar/CalendarGrid';
import { CalendarNavigation } from '../Calendar/CalendarNavigation';
import { DateInput } from '../../forms/DateInput';

export interface DateRange {
  start: Date;
  end: Date;
}

export interface PredefinedRange extends DateRange {
  label: string;
}

export interface SystemDateRangePickerProps {
  value: DateRange | null;
  translations: CalendarTranslations;
  isClearable: boolean;
  isDisabled: boolean;
  onChange: (value: DateRange | null) => void;
  ranges?: Array<PredefinedRange>;
}

const CURRENT_MONTH_YEAR_FORMAT_KEY = 'MMMM yyyy';

const defaultRanges: Array<PredefinedRange> = [];

export const SystemDateRangePicker: React.FC<SystemDateRangePickerProps> = ({
  value,
  translations,
  onChange,
  isClearable,
  isDisabled,
  ranges = defaultRanges,
}) => {
  const [selectingDate, setSelectingDate] = useState<FocusedInput>(START_DATE);
  const [startDate, setStartDate] = useState(value ? value.start : null);
  const [endDate, setEndDate] = useState(value ? value.end : null);
  const [isStartInvalid, setIsStartInvalid] = useState(false);
  const [isEndInvalid, setIsEndInvalid] = useState(false);

  const checkDateRange = useCallback(
    (newStart: Date | null, newEnd: Date | null) => {
      // Trigger a change when both start and end date are set.
      if (newStart && newEnd) {
        onChange({ start: newStart, end: newEnd });
      }
    },
    [onChange]
  );

  useEffect(() => {
    // If the value changes we need to keep the internal state updated.
    if (value) {
      setStartDate(value.start);
      setEndDate(value.end);
    }
  }, [value]);

  const calendarState = useMemo<UseDatepickerProps>(
    () => ({
      focusedInput: selectingDate,
      startDate,
      endDate,
      onDatesChange: (data) => {
        // Reset state.
        setIsStartInvalid(false);
        setIsEndInvalid(false);

        // Update dates
        if (data.focusedInput === END_DATE) {
          // When selecting the 1st date, the focusedInput changes to END_DATE
          setStartDate(data.startDate);
        } else {
          checkDateRange(startDate, data.endDate);
        }
        setEndDate(data.endDate);

        // Update selected field.
        if (!data.focusedInput) {
          setSelectingDate(START_DATE);
        } else {
          setSelectingDate(data.focusedInput);
        }
      },
      numberOfMonths: 2,
      changeActiveMonthOnSelect: false,
    }),
    [startDate, endDate, selectingDate, checkDateRange]
  );

  const {
    isOpen: isCalendarOpen,
    onClose: onCloseCalendar,
    onToggle: onToggleCalendar,
  } = useDisclosure();

  // Share styles with the DatePicker for consistency.
  const styles = useMultiStyleConfig('DatePicker', {
    isCalendarOpen,
    isClearable,
  });

  const {
    activeMonths,
    firstDayOfWeek,
    focusedDate,
    isDateBlocked,
    isDateFocused,
    isDateHovered,
    isDateSelected,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateHover,
    onDateSelect,
    goToNextMonthsByOneMonth,
    goToNextYear,
    goToPreviousMonthsByOneMonth,
    goToPreviousYear,
  } = useDatepicker(calendarState);

  // react-datepicker/hooks defaults to showing either the current month
  // or the last month the user viewed.
  // If this is the first time the user is opening the calendar and a start value
  // has been set on the input, we want to show the month of the selected date
  // and if the user has typed a date in (which doesn't use the hooks to set the
  // selection), we want to show the typed date when they open the calendar.
  // To achieve both of these, we can use the `onDateFocus` callback to update the
  // calendar view each time the date pickers value is updated.
  // We don't do this if the calendar is open to prevent it jumping to different months.
  // We can't have `onDateFocus` as a dependency of this useEffect though since it
  // changes reference each time the user interacts with the calendar ¯\_(ツ)_/¯
  useEffect(() => {
    if (value && !isCalendarOpen) {
      onDateFocus(value.start);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onClear = useCallback(() => {
    setStartDate(null);
    setEndDate(null);
    onChange(null);
  }, [onChange]);

  const onStartInputChange = useCallback(
    (newStartDate: Date | null) => {
      // If there is already an end date the start date needs to be before it (or null).
      if (!endDate || !newStartDate || newStartDate < endDate) {
        setIsStartInvalid(false);
        setStartDate(newStartDate);
        checkDateRange(newStartDate, endDate);
      } else {
        setIsStartInvalid(true);
      }
    },
    [endDate, checkDateRange]
  );

  const onEndInputChange = useCallback(
    (newEndDate: Date | null) => {
      // If there is already a start date the end date needs to be after it (or null).
      if (!startDate || !newEndDate || startDate < newEndDate) {
        setIsEndInvalid(false);
        setEndDate(newEndDate);
        checkDateRange(startDate, newEndDate);
      } else {
        setIsEndInvalid(true);
      }
    },
    [startDate, checkDateRange]
  );

  const onStartInputFocus = useCallback(() => {
    setSelectingDate(START_DATE);
  }, []);

  const onEndInputFocus = useCallback(() => {
    setSelectingDate(END_DATE);
  }, []);

  const onRangeClick = useCallback(
    (range: PredefinedRange) => {
      onChange(range);
      onCloseCalendar();
    },
    [onChange, onCloseCalendar]
  );

  return (
    <CalendarContextProvider
      showHoverRange
      activeMonths={activeMonths}
      firstDayOfWeek={firstDayOfWeek}
      focusedDate={focusedDate}
      translations={translations}
      isDateBlocked={isDateBlocked}
      isDateFocused={isDateFocused}
      isDateHovered={isDateHovered}
      isDateSelected={isDateSelected}
      isFirstOrLastSelectedDate={isFirstOrLastSelectedDate}
      onDateFocus={onDateFocus}
      onDateHover={onDateHover}
      onDateSelect={onDateSelect}
      goToNextMonth={goToNextMonthsByOneMonth}
      goToNextYear={goToNextYear}
      goToPreviousMonth={goToPreviousMonthsByOneMonth}
      goToPreviousYear={goToPreviousYear}
    >
      <InputGroup isolation="auto">
        <DateInput
          value={startDate}
          onChange={onStartInputChange}
          onFocus={onStartInputFocus}
          isDisabled={isDisabled}
          isFocussed={isCalendarOpen && selectingDate === START_DATE}
          isInvalid={isStartInvalid}
          data-testid="date-range-picker-start-input"
        />
        <Box sx={styles.rangeIndicator}>
          <FaLongArrowAltRight />
        </Box>
        <DateInput
          value={endDate}
          onChange={onEndInputChange}
          onFocus={onEndInputFocus}
          isDisabled={isDisabled || !startDate}
          isFocussed={isCalendarOpen && selectingDate === END_DATE}
          isInvalid={isEndInvalid}
          borderLeftRadius={0}
          data-testid="date-range-picker-end-input"
        />
        <InputRightAddon sx={styles.calendarOpenButtonWrapper}>
          <Popover
            isOpen={isCalendarOpen}
            onClose={onCloseCalendar}
            placement="bottom"
          >
            <PopoverTrigger>
              <IconButton
                data-testid="date-range-picker-calendar-button"
                aria-label="Open date picker calendar"
                icon={<FaCalendar />}
                isDisabled={isDisabled}
                onClick={onToggleCalendar}
              />
            </PopoverTrigger>
            <PopoverContent width="100%">
              <PopoverArrow />
              <PopoverBody data-testid="date-range-picker-calendars">
                <HStack spacing={4} divider={<StackDivider />}>
                  <Box>
                    <CalendarNavigation />
                    <HStack spacing={4} divider={<StackDivider />}>
                      {activeMonths.map((month) => (
                        <VStack key={`${month.year}-${month.date}`}>
                          <Heading size="md" mb={2}>
                            {format(month.date, CURRENT_MONTH_YEAR_FORMAT_KEY)}
                          </Heading>
                          <CalendarGrid month={month} />
                        </VStack>
                      ))}
                    </HStack>
                  </Box>
                  {ranges.length > 0 && (
                    <Box>
                      <VStack alignItems="stretch">
                        {ranges.map((range) => (
                          <Button
                            key={range.label}
                            onClick={() => {
                              onRangeClick(range);
                            }}
                          >
                            {range.label}
                          </Button>
                        ))}
                      </VStack>
                    </Box>
                  )}
                </HStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </InputRightAddon>
        {isClearable && (
          <InputRightAddon sx={styles.clearButtonWrapper}>
            <IconButton
              data-testid="date-range-picker-clear-button"
              aria-label="Clear date"
              icon={<FaTimes />}
              isDisabled={isDisabled}
              onClick={onClear}
              borderRadius="inherit"
            />
          </InputRightAddon>
        )}
      </InputGroup>
    </CalendarContextProvider>
  );
};
