import { WidgetType } from '@/types/widgets';

import { InjectableStore } from '@/store';
import WidgetBank from '@/data/widgets/WidgetBank';

export const registerWidgets = (store: InjectableStore) => {
  WidgetBank.registerWidget({
    initialConfigData: {},
    configFieldSchemas: [],
    contentComponentLoader: () =>
      import(
        /* webpackChunkName: "AlertsSummaryWidget" */ './alertsSummaryWidget'
      ).then((module) => {
        store.injectReducer(
          'alertsSummaryWidget',
          module.alertsSummaryWidgetReducer
        );
        store.injectSaga('alertsSummaryWidget', module.alertsSummaryWidgetSaga);
        return {
          default: module.AlertsSummaryWidget,
        };
      }),
    type: WidgetType.AlertsSummary,
  });

  WidgetBank.registerWidget({
    initialConfigData: {},
    configFieldSchemas: [],
    contentComponentLoader: () =>
      import(
        /* webpackChunkName: "RecentAccountsWidget" */ '@/components/widgets/RecentAccountsWidget'
      ).then((module) => ({
        default: module.RecentAccountsWidget,
      })),
    type: WidgetType.RecentAccounts,
  });
};
