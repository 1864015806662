import React, { PropsWithChildren, ReactNode } from 'react';

import {
  Alert as ChakraAlert,
  AlertDescription,
  AlertIcon,
  AlertProps as ChakraAlertProps,
  AlertTitle,
  Box,
} from '@chakra-ui/react';

export interface AlertProps extends ChakraAlertProps {
  alertTitle?: string;
  alertIcon?: ReactNode;
}

export const Alert = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<AlertProps>
>(({ alertTitle, alertIcon, children, ...alertProps }, ref) => {
  return (
    <ChakraAlert ref={ref} alignItems="center" {...alertProps}>
      {alertIcon || <AlertIcon data-testid="default-alert-icon" />}
      <Box w="100%">
        {!!alertTitle && (
          <AlertTitle data-testid="alert-title">{alertTitle}</AlertTitle>
        )}
        <AlertDescription whiteSpace="pre-line">{children}</AlertDescription>
      </Box>
    </ChakraAlert>
  );
});
