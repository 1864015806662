import React, { PropsWithChildren } from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import {
  NamedLink,
  NamedLinkProps,
} from '@/components/common/navigation/NamedLink';
import { CrudRouteType, getCrudRouteName } from '@/routes/crud';

export interface CrudListLinkProps extends Omit<NamedLinkProps, 'name'> {
  dataType: DataType;
}

export const CrudListLink = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<CrudListLinkProps>
>(({ dataType, children, ...props }, ref) => (
  <NamedLink
    ref={ref}
    name={getCrudRouteName(dataType, CrudRouteType.List)}
    {...props}
  >
    {children}
  </NamedLink>
));
