import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { PlanGroupsRoute } from '@/components/routes/plan-groups/PlanGroupsRoute';
import { PlanGroupsListRoute } from '@/components/routes/plan-groups/PlanGroupsListRoute';
import { PlanGroupsDetailsRoute } from '@/components/routes/plan-groups/PlanGroupsDetailsRoute';
import { PlanGroupsCreateRoute } from '@/components/routes/plan-groups/PlanGroupsCreateRoute';
import { PlanGroupsEditRoute } from '@/components/routes/plan-groups/PlanGroupsEditRoute';

export default () =>
  createCrudRoute({
    path: 'plan-groups',
    dataType: DataType.PlanGroup,
    element: <PlanGroupsRoute />,
    list: <PlanGroupsListRoute />,
    details: <PlanGroupsDetailsRoute />,
    create: <PlanGroupsCreateRoute />,
    edit: <PlanGroupsEditRoute />,
  });
