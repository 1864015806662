import React from 'react';

import { Flex, Icon, Text } from '@chakra-ui/react';
import { FaQuestionCircle } from 'react-icons/fa';

export interface NotFoundScreenProps {
  message: string;
}

export const NotFoundScreen: React.FC<NotFoundScreenProps> = ({ message }) => (
  <Flex
    alignItems="center"
    flexFlow="column nowrap"
    gap={4}
    height="100vh"
    justifyContent="center"
    width="100vw"
  >
    <Icon as={FaQuestionCircle} boxSize={12} />
    {!!message && <Text fontSize="xl">{message}</Text>}
  </Flex>
);
