import React, { PropsWithChildren } from 'react';

import { Flex } from '@chakra-ui/react';

import { Entity } from '@m3ter-com/m3ter-api';

import { CrudListError } from './CrudListError';
import { CrudListSearchBar } from './CrudListSearchBar';
import { CrudListFilterMenu } from './CrudListFilterMenu';
import { CrudListRefreshButton } from './CrudListRefreshButton';
import { CrudListCreateLink } from './CrudListCreateLink';
import { CrudListActiveFilters } from './CrudListActiveFilters';

export interface CrudListHeaderProps {
  hideCreateLink?: boolean;
  hideError?: boolean;
  hideFilters?: boolean;
  hideRefresh?: boolean;
  hideSearchBar?: boolean;
  isCreateDisabled?: boolean;
}

export function CrudListHeader<E extends Entity>({
  children,
  hideCreateLink,
  hideError,
  hideFilters,
  hideRefresh,
  hideSearchBar,
  isCreateDisabled = false,
}: PropsWithChildren<CrudListHeaderProps>) {
  return (
    <React.Fragment>
      {!hideError && <CrudListError />}
      <Flex
        alignItems="center"
        flexFlow="row nowrap"
        gap={4}
        justifyContent="flex-start"
      >
        {!hideSearchBar && <CrudListSearchBar />}
        {!hideFilters && <CrudListFilterMenu<E> />}
        {children}
        {(!hideRefresh || !hideCreateLink) && (
          <Flex
            alignItems="center"
            flexFlow="row nowrap"
            gap={4}
            justifyContent="flex-start"
            marginLeft="auto"
          >
            {!hideRefresh && <CrudListRefreshButton flex="0 0 max-content" />}
            {!hideCreateLink && (
              <CrudListCreateLink
                flex="0 0 max-content"
                isDisabled={isCreateDisabled}
              />
            )}
          </Flex>
        )}
      </Flex>
      {!hideFilters && <CrudListActiveFilters<E> />}
    </React.Fragment>
  );
}
