import { useMemo, useCallback } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import cloneDeep from 'lodash/cloneDeep';

import { Entity } from '@m3ter-com/m3ter-api';
import { DeepPartial } from '@m3ter-com/console-core/types';

import { ListFilterDefinition, ListSearchCriteria } from '@/types/lists';

import { EntityNamings } from '@/hooks/util/useEntityNamings';
import { useCrudListContext } from '@/components/common/crud/CrudList';

export interface UseCrudListFiltersReturn<E extends Entity> {
  applyFilters: (filterCriteria: DeepPartial<ListSearchCriteria>) => void;
  currentFilters?: ListSearchCriteria;
  entityNamings: EntityNamings;
  filterDefinitions?: Array<ListFilterDefinition<E>>;
  filtersCount: number;
  isFilterModalOpen: boolean;
  onCloseFilterModal: () => void;
  onToggleFilterModal: () => void;
  removeAllFilters: () => void;
  searchFields?: Array<string>;
}

// Field components tend to set values to null / undefined when the input is cleared.
// null / undefined values in a filter criterion are useless and signify that the filter
// should actually just be removed. This util does just that.
const cleanFilterCriteria = (
  criteria: DeepPartial<ListSearchCriteria>
): ListSearchCriteria => {
  const criteriaCopy = cloneDeep(criteria);
  Object.entries(criteriaCopy).forEach(([fieldName, fieldCriteria]) => {
    if (!fieldName) {
      delete criteriaCopy[fieldName];
      return;
    }

    if (Array.isArray(fieldCriteria)) {
      const validCriteria = [...fieldCriteria].filter(
        (fieldCriterion) =>
          !!fieldCriterion?.comparator &&
          fieldCriterion.value !== undefined &&
          fieldCriterion.value !== null
      );
      if (validCriteria.length > 0) {
        criteriaCopy[fieldName] = validCriteria;
      } else {
        delete criteriaCopy[fieldName];
      }
      return;
    }

    if (
      !fieldCriteria?.comparator ||
      fieldCriteria.value === undefined ||
      fieldCriteria.value === null
    ) {
      delete criteriaCopy[fieldName];
    }
  });

  return criteriaCopy as ListSearchCriteria;
};

const useCrudListFilters = <
  E extends Entity
>(): UseCrudListFiltersReturn<E> => {
  const {
    entityNamings,
    filterCriteria,
    filterDefinitions,
    filterList,
    searchFields,
  } = useCrudListContext<E>();

  const {
    isOpen: isFilterModalOpen,
    onClose: onCloseFilterModal,
    onToggle: onToggleFilterModal,
  } = useDisclosure();

  const filtersCount = useMemo(() => {
    return Object.entries(filterCriteria || {}).reduce(
      (count, [_fieldName, fieldCriteria]) => {
        if (Array.isArray(fieldCriteria)) {
          return count + fieldCriteria.length;
        }

        return count + 1;
      },
      0
    );
  }, [filterCriteria]);

  const applyFilters = useCallback(
    (newFilterCriteria: DeepPartial<ListSearchCriteria>) => {
      filterList(cleanFilterCriteria(newFilterCriteria));
      onCloseFilterModal();
    },
    [filterList, onCloseFilterModal]
  );

  const removeAllFilters = useCallback(() => {
    filterList(undefined);
  }, [filterList]);

  return {
    applyFilters,
    currentFilters: filterCriteria,
    entityNamings,
    filterDefinitions,
    filtersCount,
    isFilterModalOpen,
    onCloseFilterModal,
    onToggleFilterModal,
    removeAllFilters,
    searchFields,
  };
};

export default useCrudListFilters;
