import React from 'react';

import { DataType, IntegrationCredential } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { createCrudRoute } from '@/routes/crud';
import { IntegrationCredentialsRoute } from '@/components/routes/integrations/credentials/IntegrationCredentialsRoute';
import { IntegrationCredentialsListRoute } from '@/components/routes/integrations/credentials/IntegrationCredentialsListRoute';
import { IntegrationCredentialCreateRoute } from '@/components/routes/integrations/credentials/IntegrationCredentialCreateRoute';

export default (): NamedRoute =>
  createCrudRoute<IntegrationCredential>({
    path: 'credentials',
    dataType: DataType.IntegrationCredential,
    element: <IntegrationCredentialsRoute />,
    list: <IntegrationCredentialsListRoute />,
    create: <IntegrationCredentialCreateRoute />,
  });
