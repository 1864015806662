import React, { useCallback, useMemo } from 'react';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { DataType, User } from '@m3ter-com/m3ter-api';
import { FormStack } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Form,
  FormActions,
  FormField,
  FormInput,
} from '@m3ter-com/console-core/components';

import useSingleton from '@/hooks/data/useSingleton';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch';
import { NotificationDefinition } from '@/store/store';

export interface ProfileEditModalProps {
  isOpen?: boolean;
  onClose: () => void;
}

export const ProfileEditModal: React.FC<ProfileEditModalProps> = ({
  isOpen = false,
  onClose,
}) => {
  const { t } = useTranslation();

  const { data, isLoading, update } = useSingleton<User>(DataType.CurrentUser);

  const profileUpdateSuccessNotification = useMemo<NotificationDefinition>(
    () => ({
      type: 'success',
      message: t('notifications:userProfileUpdateSuccess'),
      removeAfter: 5000,
    }),
    [t]
  );
  const profileUpdateFailureNotification = useMemo<NotificationDefinition>(
    () => ({
      type: 'error',
      message: t('notifications:userProfileUpdateFailure'),
      removeAfter: 5000,
    }),
    [t]
  );

  const onSubmit = useCallback(
    (updatedData: User) => {
      update(
        updatedData,
        profileUpdateSuccessNotification,
        profileUpdateFailureNotification
      );
      onClose();
    },
    [
      onClose,
      profileUpdateFailureNotification,
      profileUpdateSuccessNotification,
      update,
    ]
  );

  return data ? (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Form onSubmit={onSubmit} initialValues={data}>
          <ModalHeader>{t('common:editProfile')}</ModalHeader>
          <ModalBody>
            <LoadingErrorContentSwitch isLoading={isLoading}>
              <FormStack>
                <FormField
                  name="firstName"
                  label={t('forms:labels.firstName')}
                  control={FormInput}
                />
                <FormField
                  name="lastName"
                  label={t('forms:labels.lastName')}
                  control={FormInput}
                />
              </FormStack>
            </LoadingErrorContentSwitch>
          </ModalBody>
          <ModalFooter>
            <FormActions
              submitText={t('common:update')}
              cancelText={t('common:cancel')}
              onCancel={onClose}
            />
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  ) : null;
};
