import React from 'react';

import {
  FaBell,
  FaBox,
  FaChartLine,
  FaExclamationCircle,
  FaHeartbeat,
  FaHome,
  FaLifeRing,
  FaMoneyCheckAlt,
  FaReceipt,
  FaRegFileAlt,
  FaRegObjectUngroup,
  FaTable,
  FaTachometerAlt,
  FaUser,
  FaWrench,
} from 'react-icons/fa';
import { Link } from '@chakra-ui/react';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Navigation,
  NavigationDivider,
  NavigationGroup,
  NavigationItem,
} from '@m3ter-com/ui-components';

import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import useFeatureFlags, { Feature } from '@/hooks/util/useFeatureFlags';
import { NamedNavLink } from '@/components/common/navigation/NamedNavLink';
import { ExternalIcon } from '@/components/common/navigation/ExternalIcon';

export const PrimaryNavigation: React.FC = () => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeatureFlags();

  return (
    <Navigation>
      <NamedNavLink name="dashboard" activeOnlyIfExact icon={<FaHome />}>
        {t('common:dashboard')}
      </NamedNavLink>
      <NavigationGroup title={t('common:billing')} icon={<FaReceipt />}>
        <NamedNavLink
          name={getCrudRouteName(DataType.Bill, CrudRouteType.List)}
        >
          {t('features:billing.billManagement')}
        </NamedNavLink>
        <NamedNavLink name="billing.history">
          {t('features:billing.billHistory')}
        </NamedNavLink>
        {isFeatureEnabled(Feature.Statements) && (
          <NamedNavLink
            name={getCrudRouteName(
              DataType.StatementDefintion,
              CrudRouteType.List
            )}
          >
            {t('common:statementDefinitions')}
          </NamedNavLink>
        )}
      </NavigationGroup>
      <NamedNavLink
        name={getCrudRouteName(DataType.Account, CrudRouteType.List)}
        icon={<FaUser />}
      >
        {t('common:accounts')}
      </NamedNavLink>
      <NamedNavLink
        name={getCrudRouteName(DataType.Product, CrudRouteType.List)}
        icon={<FaBox />}
      >
        {t('common:products')}
      </NamedNavLink>
      <NavigationGroup title={t('common:usage')} icon={<FaTachometerAlt />}>
        <NamedNavLink
          name={getCrudRouteName(DataType.Meter, CrudRouteType.List)}
        >
          {t('common:meters')}
        </NamedNavLink>
        <NamedNavLink
          name={getCrudRouteName(DataType.Aggregation, CrudRouteType.List)}
        >
          {t('common:aggregations')}
        </NamedNavLink>
        <NamedNavLink
          name={getCrudRouteName(
            DataType.CompoundAggregation,
            CrudRouteType.List
          )}
        >
          {t('common:compoundAggregations')}
        </NamedNavLink>
        {isFeatureEnabled(Feature.Counters) && (
          <NamedNavLink
            name={getCrudRouteName(DataType.Counter, CrudRouteType.List)}
          >
            {t('common:counters')}
          </NamedNavLink>
        )}
        <NamedNavLink name="ingest.events">
          {t('features:ingest.ingestEvents')}
        </NamedNavLink>
      </NavigationGroup>
      <NavigationGroup title={t('common:pricing')} icon={<FaMoneyCheckAlt />}>
        <NamedNavLink
          name={getCrudRouteName(DataType.PlanTemplate, CrudRouteType.List)}
        >
          {t('common:planTemplates')}
        </NamedNavLink>
        <NamedNavLink
          name={getCrudRouteName(DataType.Plan, CrudRouteType.List)}
        >
          {t('common:plans')}
        </NamedNavLink>
        <NamedNavLink
          name={getCrudRouteName(DataType.PlanGroup, CrudRouteType.List)}
        >
          {t('common:planGroups')}
        </NamedNavLink>
        <NamedNavLink name="pricing.editor">
          {t('features:pricing.pricingEditor')}
        </NamedNavLink>
      </NavigationGroup>
      <NamedNavLink
        name="analytics.dataExplorer"
        activeOnlyIfExact
        icon={<FaChartLine />}
      >
        {t('features:dataExplorer.title')}
      </NamedNavLink>
      {isFeatureEnabled(Feature.Reports) && (
        <NamedNavLink name="reports" icon={<FaTable />}>
          {t('features:reports.title')}
        </NamedNavLink>
      )}
      <NavigationGroup
        title={t('common:integrations')}
        icon={<FaRegObjectUngroup />}
      >
        <NamedNavLink
          name={getCrudRouteName(DataType.Destination, CrudRouteType.List)}
        >
          {t('features:integrations.destinations')}
        </NamedNavLink>
        <NamedNavLink
          name={getCrudRouteName(DataType.Integration, CrudRouteType.List)}
        >
          {t('features:integrations.configurations')}
        </NamedNavLink>
        <NamedNavLink
          name={getCrudRouteName(
            DataType.IntegrationCredential,
            CrudRouteType.List
          )}
        >
          {t('features:integrations.credentials')}
        </NamedNavLink>
        <NamedNavLink name="integrations.events">
          {t('features:integrations.integrationEvents')}
        </NamedNavLink>
        <NamedNavLink
          name={getCrudRouteName(DataType.ExternalMapping, CrudRouteType.List)}
        >
          {t('common:externalMappings')}
        </NamedNavLink>
      </NavigationGroup>
      <NavigationGroup title={t('common:settings')} icon={<FaWrench />}>
        <NamedNavLink name="settings.access">{t('common:access')}</NamedNavLink>
        <NamedNavLink name="settings.organization">
          {t('common:organization')}
        </NamedNavLink>
      </NavigationGroup>
      {/* TODO: Move this to top navigation when we re-style the console */}
      {/* TODO: Alerts will be removed and replaced with notifications when completed */}
      <NamedNavLink
        name={getCrudRouteName(DataType.Alert, CrudRouteType.List)}
        icon={<FaExclamationCircle />}
      >
        {t('common:alerts')}
      </NamedNavLink>
      {isFeatureEnabled(Feature.Notifications) && (
        <NamedNavLink name="notification-rules" icon={<FaBell />}>
          {t('common:notifications')}
        </NamedNavLink>
      )}
      <NavigationDivider />
      <NavigationItem
        as={Link}
        isExternal
        icon={<FaRegFileAlt />}
        href="https://www.m3ter.com/docs"
      >
        {t('common:documentation')}
        <ExternalIcon />
      </NavigationItem>
      <NavigationItem
        as={Link}
        isExternal
        icon={<FaLifeRing />}
        href="https://m3ter.myfreshworks.com/login/auth/1662629775049?client_id=451980218021503405&amp;redirect_uri=https%3A%2F%2Fm3ter.freshdesk.com%2Ffreshid%2Fcustomer_authorize_callback%3Fhd%3Dm3ter.freshdesk.com"
      >
        {t('common:support')}
        <ExternalIcon />
      </NavigationItem>
      <NavigationItem
        as={Link}
        isExternal
        icon={<FaHeartbeat />}
        href="http://status.m3ter.com"
      >
        {t('common:serviceStatus')}
        <ExternalIcon />
      </NavigationItem>
    </Navigation>
  );
};
