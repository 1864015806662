import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { DebitLineItemsRoute } from '@/components/routes/billing/DebitLineItemsRoute';
import { DebitLineItemsCreateRoute } from '@/components/routes/billing/DebitLineItemsCreateRoute';
import { DebitLineItemsEditRoute } from '@/components/routes/billing/DebitLineItemsEditRoute';

export default () =>
  createCrudRoute({
    path: 'debit-line-items',
    dataType: DataType.DebitLineItem,
    element: <DebitLineItemsRoute />,
    create: <DebitLineItemsCreateRoute />,
    edit: <DebitLineItemsEditRoute />,
  });
