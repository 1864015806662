import React, { ReactElement, ReactNode } from 'react';

import { Text } from '@chakra-ui/react';

import {
  Entity,
  OrganizationConfig,
  UnsavedEntity,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useOrg from '@/hooks/data/crud/useOrg';

type OrgConfigAndEntityCommonKey<E extends Entity> =
  keyof UnsavedEntity<OrganizationConfig> & keyof UnsavedEntity<E>;

export interface EntityOrOrgConfigValueProps<
  E extends Entity,
  K extends OrgConfigAndEntityCommonKey<E>
> {
  fieldName: K;
  entity: E;
  format?: (value: OrganizationConfig[K] | E[K]) => ReactNode;
}
const defaultFormat = (value: any): string => `${value}`;

export const EntityOrOrgConfigValue = <
  E extends Entity,
  K extends OrgConfigAndEntityCommonKey<E>
>({
  entity,
  fieldName,
  format = defaultFormat,
}: EntityOrOrgConfigValueProps<E, K>): ReactElement<any, any> | null => {
  const { t } = useTranslation();
  const { orgConfig } = useOrg();

  const entityValue = entity[fieldName];
  const orgConfigValue = orgConfig[fieldName];

  if (entityValue !== undefined) {
    return (
      <React.Fragment>
        <Text>{format(entityValue)}</Text>
        <Text variant="annotation">
          {t('features:plans.orgConfigDefault')}:{' '}
          <s>{format(orgConfigValue)}</s>
        </Text>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Text>{format(orgConfigValue)}</Text>
      <Text variant="annotation">{t('features:plans.fromOrgConfig')}</Text>
    </React.Fragment>
  );
};
