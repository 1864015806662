import React, { ElementType } from 'react';

import { DataType, PickListEntity } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { Accessor } from '@/util/data';
import { createCrudRoute } from '@/routes/crud';
import { ColumnDefinition } from '@/components/common/crud/CrudList';
import { PickListRoute } from '@/components/common/picklist/PickListRoute';
import { PickListListRoute } from '@/components/common/picklist/PickListListRoute';
import { PickListCreateRoute } from '@/components/common/picklist/PickListCreateRoute';
import { PickListEditRoute } from '@/components/common/picklist/PickListEditRoute';
import { PickListForm } from '@/components/features/organization/PickListForm';

interface PickListRouteConfig<T extends PickListEntity> {
  dataType: DataType;
  path: string;
  title: string;
  listId: string;
  form?: ElementType;
  listExtraColumns?: Array<ColumnDefinition<T>>;
  titleAccessor?: Accessor<T>;
}

export const createPickListRoute = <
  EntityType extends PickListEntity = PickListEntity
>(
  config: PickListRouteConfig<EntityType>
): NamedRoute => {
  const {
    dataType,
    title,
    listId,
    listExtraColumns,
    form = PickListForm,
  } = config;

  return createCrudRoute({
    ...config,
    element: <PickListRoute dataType={dataType} title={title} />,
    list: (
      <PickListListRoute<EntityType>
        listId={listId}
        extraColumns={listExtraColumns}
      />
    ),
    create: <PickListCreateRoute form={form} />,
    edit: <PickListEditRoute form={form} />,
  });
};
