import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { User } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import {
  selectError,
  selectIsLoading,
  updateUserActivation,
} from '@/store/features/access/users/userActivation';
import { NotificationDefinition } from '@/store/store';

const useUserActivation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userDeactivatedSuccessNotification: NotificationDefinition = useMemo(
    () => ({
      type: 'success',
      message: t('notifications:userDeactivatedSuccess'),
      removeAfter: 5000,
    }),
    [t]
  );

  const userDeactivatedFailureNotification: NotificationDefinition = useMemo(
    () => ({
      type: 'error',
      message: t('notifications:userDeactivatedFailure'),
      removeAfter: 5000,
    }),
    [t]
  );

  const userActivatedSuccessNotification: NotificationDefinition = useMemo(
    () => ({
      type: 'success',
      message: t('notifications:userActivatedSuccess'),
      removeAfter: 5000,
    }),
    [t]
  );

  const userActivatedFailureNotification: NotificationDefinition = useMemo(
    () => ({
      type: 'error',
      message: t('notifications:userActivatedFailure'),
      removeAfter: 5000,
    }),
    [t]
  );

  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);

  const onDeactivateUser = useCallback(
    (user: User) => {
      const now = new Date(Date.now()).toISOString();
      dispatch(
        updateUserActivation(
          user,
          now,
          userDeactivatedSuccessNotification,
          userDeactivatedFailureNotification
        )
      );
    },
    [
      dispatch,
      userDeactivatedFailureNotification,
      userDeactivatedSuccessNotification,
    ]
  );

  const onActivateUser = useCallback(
    (user: User) => {
      dispatch(
        updateUserActivation(
          user,
          null,
          userActivatedSuccessNotification,
          userActivatedFailureNotification
        )
      );
    },
    [
      dispatch,
      userActivatedFailureNotification,
      userActivatedSuccessNotification,
    ]
  );

  return {
    error,
    isLoading,
    onActivateUser,
    onDeactivateUser,
  };
};

export default useUserActivation;
