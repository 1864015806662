import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { OrganizationConfig } from '@m3ter-com/m3ter-api';

const suite = create('meter', (data: Partial<OrganizationConfig>) => {
  test(
    'autoApproveBillsGracePeriod',
    i18next.t(
      'forms:validations.organizationConfig.autoApproveBillsGradePeriodNumber'
    ),
    () => {
      enforce(data.autoApproveBillsGracePeriod).isNotNaN();
    }
  );
});

export default suite;
