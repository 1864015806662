import React, { useCallback, useMemo, useState } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { HStack } from '@chakra-ui/react';

import { DataType, Entity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { DeepPartial } from '@m3ter-com/console-core/types';
import { FormStack, RadioTabs } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormAdvancedNumberInput,
  FormField,
  FormInput,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import creditLineItemSchema from '@/validation/creditLineItem';
import debitLineItemSchema from '@/validation/debitLineItem';
import {
  DataTypeFormEntitySelect,
  FormEntitySelect,
} from '@/components/forms/FormEntitySelect';
import {
  FormPicklistSelect,
  PickListDataType,
} from '@/components/features/accounts/FormPicklistSelect';
import { FormDatePicker } from '@/components/forms/FormDatePicker';

import { BillLineItemSelect } from './BillLineItemSelect';

enum Type {
  LineItem = 'lineItem',
  Product = 'product',
}

interface MemoLineItemFormProps<
  DT extends PickListDataType = PickListDataType,
  T extends Entity = Entity
> extends BaseFormProps<T> {
  dataType: DT;
}

interface LineItemTypeSelectProps {
  entityName: string;
  dataType: PickListDataType;
  isCreditReason: boolean;
  isEdit: boolean;
}

interface MemoLineItemFormInnerProps {
  dataType: PickListDataType;
  isCreditReason: boolean;
}

const defaultInitialValues: Partial<MemoLineItemFormProps> = {};

const MemoLineItemFormInner: React.FC<MemoLineItemFormInnerProps> = ({
  dataType,
  isCreditReason,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FormField
        isRequired
        name={isCreditReason ? 'creditReasonId' : 'debitReasonId'}
        label={
          isCreditReason ? t('common:creditReason') : t('common:debitReason')
        }
        control={FormPicklistSelect}
        dataType={dataType}
      />
      <FormField
        isRequired
        name="description"
        label={t('forms:labels.description')}
        control={FormInput}
      />
      <FormField
        isRequired
        name="amount"
        label={t('forms:labels.amount')}
        control={FormAdvancedNumberInput}
      />
    </React.Fragment>
  );
};

const LineItemTypeSelect: React.FC<LineItemTypeSelectProps> = ({
  entityName,
  dataType,
  isCreditReason,
  isEdit,
}) => {
  const { t } = useTranslation();

  const { setValue } = useFormContext();
  const referencedBillId = useWatch({ name: 'referencedBillId' });
  const productId = useWatch({ name: 'productId' });

  const [type, setType] = useState<Type>(
    referencedBillId || productId === undefined ? Type.LineItem : Type.Product
  );

  const onTypeChange = useCallback(
    (newType: Type) => {
      // When switching type, we need to clear the other selection.
      if (newType === Type.LineItem) {
        setValue('productId', null);
      } else {
        setValue('referencedBillId', null);
        setValue('referencedLineItemId', null);
      }
      setType(newType);
    },
    [setValue]
  );

  const lineItemTypeLabel = useMemo(
    () =>
      isCreditReason
        ? t('features:billing.creditBillLineItem')
        : t('features:billing.debitBillLineItem'),
    [isCreditReason, t]
  );

  const productTypeLabel = useMemo(
    () =>
      isCreditReason
        ? t('features:billing.applyManualCredit')
        : t('features:billing.applyManualDebit'),
    [isCreditReason, t]
  );

  const options = useMemo(
    () => [
      {
        value: Type.LineItem,
        label: lineItemTypeLabel,
        content: (
          <FormSection heading={t('common:entityDetails', { entityName })}>
            <BillLineItemSelect updateAmount={!isEdit} />
            <MemoLineItemFormInner
              dataType={dataType}
              isCreditReason={isCreditReason}
            />
          </FormSection>
        ),
      },
      {
        value: Type.Product,
        label: productTypeLabel,
        content: (
          <FormSection heading={t('common:entityDetails', { entityName })}>
            <FormField
              isRequired
              name="productId"
              label={t('common:product')}
              control={
                FormEntitySelect as DataTypeFormEntitySelect<DataType.Product>
              }
              dataType={DataType.Product}
              accessor="name"
              detailAccessor="code"
            />
            <MemoLineItemFormInner
              dataType={dataType}
              isCreditReason={isCreditReason}
            />
          </FormSection>
        ),
      },
    ],
    [
      dataType,
      entityName,
      isCreditReason,
      isEdit,
      lineItemTypeLabel,
      productTypeLabel,
      t,
    ]
  );

  return (
    <RadioTabs
      options={options}
      value={type}
      onChange={onTypeChange}
      width="100%"
    />
  );
};

export function MemoLineItemForm<
  DT extends PickListDataType = DataType.CreditReason | DataType.DebitReason,
  T extends Entity = Entity
>({
  initialValues = defaultInitialValues as DeepPartial<T>,
  isEdit = false,
  isSaving,
  onCancel,
  onSave,
  dataType,
}: MemoLineItemFormProps<DT, T>) {
  const { t } = useTranslation();

  const isCreditReason = dataType === DataType.CreditReason;
  const entityName = isCreditReason
    ? t('common:creditLineItem')
    : t('common:debitLineItem');

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSave}
      validationSchema={
        isCreditReason ? creditLineItemSchema : debitLineItemSchema
      }
    >
      <FormStack>
        <LineItemTypeSelect
          dataType={dataType}
          entityName={entityName}
          isCreditReason={isCreditReason}
          isEdit={isEdit}
        />
        <FormSection
          isOptional
          heading={t('forms:labels.servicePeriodSettings')}
        >
          <HStack spacing={4}>
            <FormField
              name="servicePeriodStartDate"
              label={t('forms:labels.servicePeriodStart')}
              control={FormDatePicker}
            />
            <FormField
              name="servicePeriodEndDate"
              label={t('forms:labels.servicePeriodEnd')}
              control={FormDatePicker}
            />
          </HStack>
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={
            isEdit
              ? t('forms:buttons.updateEntity', { entityName })
              : t('forms:buttons.createEntity', { entityName })
          }
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
}
