import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';

import {
  IntegrationConfig,
  IntegrationRunEntry,
  IntegrationTaskDefinition,
} from '@m3ter-com/m3ter-api';

import { SerialisedIntegrationRunEntry } from '@/types/data';

export const integrationAccessor = (integration: IntegrationConfig) =>
  integration.name || '-';

export const integrationDetailsAccessor = (integration: IntegrationConfig) =>
  `${integration.entityType} - ${integration.destination}`;

export const parseJsonString = (json: string) => {
  try {
    return JSON.parse(json);
  } catch {
    return json;
  }
};

export const serialiseTaskEntries = (
  taskEntries: Array<IntegrationRunEntry>,
  taskId: string
) => {
  let aggregatedTime = 0;

  const serialisedTaskEntries = taskEntries
    .filter((entry) => entry.taskId === taskId)
    .map((entry) => {
      const millisecondDifference = entry.httpRequestCompleteTime
        ? differenceInMilliseconds(
            new Date(entry.httpRequestCompleteTime),
            new Date(entry.timestamp)
          )
        : undefined;

      aggregatedTime += millisecondDifference || 0;

      const parsedResponseBody = entry.response?.responseBody
        ? parseJsonString(entry.response.responseBody)
        : null;

      const parsedRequestBody = entry.request?.requestBody
        ? parseJsonString(entry.request.requestBody)
        : null;

      return {
        ...entry,
        response: entry.response
          ? { ...entry.response, responseBody: parsedResponseBody }
          : undefined,
        request: entry.request
          ? { ...entry.request, requestBody: parsedRequestBody }
          : undefined,
        timeDifferenceToPreviousEntry: millisecondDifference,
      };
    })
    .filter(Boolean) as SerialisedIntegrationRunEntry[];

  return { serialisedTaskEntries, aggregatedTime };
};

export const groupTaskDefinitions = (
  taskDefinitions: Array<IntegrationTaskDefinition>
) => {
  const parentTaskDefinitions = new Array<IntegrationTaskDefinition>();
  const childTaskDefinitions = new Array<IntegrationTaskDefinition>();

  // With task definitions we need to sort them based on what they actioned during the integration, note a parent
  // taskDefinition also refers to an entity with null as its parentId and no children

  taskDefinitions.forEach((taskDefinition) => {
    if (taskDefinition.parentTaskId) {
      childTaskDefinitions.push(taskDefinition);
    } else {
      parentTaskDefinitions.push(taskDefinition);
    }
  });

  return parentTaskDefinitions.map((parentTaskDefinition) => {
    const children = childTaskDefinitions.filter(
      (childTaskDefinition) =>
        childTaskDefinition.parentTaskId === parentTaskDefinition.taskId
    );

    return [parentTaskDefinition, ...children];
  });
};

export const formatMsToRelevantTime = (milliseconds: number) => {
  if (milliseconds < 1000) {
    return `${milliseconds}ms`;
  }

  const ms = milliseconds % 1000;
  const seconds = Math.floor((milliseconds / 1000) % 60);

  if (milliseconds < 60000) {
    return `${seconds}.${ms}s`;
  }

  const minutes = Math.floor((milliseconds / (60 * 1000)) % 60);
  return `${minutes}m:${seconds}.${ms}s`;
};
