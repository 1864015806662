import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import {
  CurrencySelect,
  CurrencySelectProps,
} from '@/components/forms/CurrencySelect';

export interface FormCurrencyInputProps
  extends Omit<CurrencySelectProps, 'value' | 'onChange'> {
  name: string;
}

export const FormCurrencySelect: React.FC<FormCurrencyInputProps> = ({
  name,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <CurrencySelect value={value} onChange={onChange} {...props} />
      )}
    />
  );
};
