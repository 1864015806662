import React from 'react';

import { Navigate } from 'react-router-dom';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { PricingRootRoute } from '@/components/routes/pricing/PricingRootRoute';

import setupPricingRoute from './editor/pricing';
import setupItemCounterPricingRoute from './editor/item-counter-pricing';
import setupPricingScheduleRoute from './editor/schedule';
import setupPlansRoute from './plans';
import setupPlanGroupsRoute from './plan-groups';
import setupPlanTemplatesRoute from './plan-templates';

const LazyPricingEditorRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "PricingEditor" */ '@/components/routes/pricing/editor/PricingEditorRoute'
  ).then((module) => ({ default: module.PricingEditorRoute }))
);

export default (): NamedRoute => ({
  path: 'pricing',
  children: [
    { index: true, element: <Navigate replace to="editor" /> },
    {
      path: 'editor',
      name: 'pricing.editor',
      element: <PricingRootRoute />,
      children: [
        {
          index: true,
          element: <LazyPricingEditorRoute />,
        },
        setupPricingRoute(),
        setupItemCounterPricingRoute(),
        setupPricingScheduleRoute(),
      ],
    },
    setupPlansRoute(),
    setupPlanGroupsRoute(),
    setupPlanTemplatesRoute(),
  ],
});
