import React from 'react';

import { HStack } from '@chakra-ui/react';

import { Contract, UnsavedEntity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormInput,
  FormSection,
  NameCodeFields,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import contractSchema from '@/validation/contract';
import { FormDatePicker } from '@/components/forms/FormDatePicker';
import { FormCustomFieldsEditor } from '@/components/forms/FormCustomFieldsEditor';

export interface ContractFormProps
  extends BaseFormProps<UnsavedEntity<Contract>> {}

const defaultInitialValues: Partial<Contract> = {};

export const ContractForm: React.FC<ContractFormProps> = ({
  initialValues = defaultInitialValues,
  isEdit = false,
  isSaving,
  onSave,
  onCancel,
}) => {
  const { t } = useTranslation();
  const entityName = t('common:contract');

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSave}
      validationSchema={contractSchema}
    >
      <FormStack>
        <FormSection heading={t('common:entityDetails', { entityName })}>
          <NameCodeFields />
          <FormField
            name="description"
            label={t('forms:labels.description')}
            control={FormInput}
          />
          <FormField
            name="purchaseOrderNumber"
            label={t('forms:labels.purchaseOrderNumber')}
            control={FormInput}
          />
          <HStack spacing={4}>
            <FormField
              isRequired
              name="startDate"
              label={t('forms:labels.startDateInclusive')}
              control={FormDatePicker}
            />
            <FormField
              isRequired
              name="endDate"
              label={t('forms:labels.endDateExclusive')}
              control={FormDatePicker}
            />
          </HStack>
        </FormSection>
        <FormCustomFieldsEditor />
        <FormActions
          cancelText={t('common:cancel')}
          submitText={
            isEdit
              ? t('forms:buttons.updateEntity', { entityName })
              : t('forms:buttons.createEntity', { entityName })
          }
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
