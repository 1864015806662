import React, { ReactElement, ReactNode } from 'react';

import { Text } from '@chakra-ui/react';

import {
  OrganizationConfig,
  PlanTemplate,
  UnsavedEntity,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useOrg from '@/hooks/data/crud/useOrg';

type PlanTemplateAndOrgConfigCommonKey =
  keyof UnsavedEntity<OrganizationConfig> & keyof UnsavedEntity<PlanTemplate>;
export interface PlanTemplateOrOrgConfigValueProps<
  K extends PlanTemplateAndOrgConfigCommonKey
> {
  fieldName: K;
  format: (value: OrganizationConfig[K] | PlanTemplate[K]) => ReactNode;
  planTemplate: PlanTemplate;
}

export const PlanTemplateOrOrgConfigValue = <
  K extends PlanTemplateAndOrgConfigCommonKey
>({
  fieldName,
  format,
  planTemplate,
}: PlanTemplateOrOrgConfigValueProps<K>): ReactElement<any, any> | null => {
  const { t } = useTranslation();
  const { orgConfig } = useOrg();

  const planTemplateValue = planTemplate[fieldName];
  const orgConfigValue = orgConfig[fieldName];

  if (planTemplateValue !== undefined) {
    return (
      <React.Fragment>
        <Text>{format(planTemplateValue)}</Text>
        <Text variant="annotation">
          {t('features:plans.orgConfigDefault')}:{' '}
          <s>{format(orgConfigValue)}</s>
        </Text>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Text>{format(orgConfigValue)}</Text>
      <Text variant="annotation">{t('features:plans.fromOrgConfig')}</Text>
    </React.Fragment>
  );
};
