import React from 'react';

import { Badge, SpaceProps } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';

export interface ActiveStatusBadgeProps {
  active: boolean;
  activeLabel?: string;
  inactiveLabel?: string;
  ml?: SpaceProps['ml'];
}

export const ActiveStatusBadge: React.FC<ActiveStatusBadgeProps> = ({
  active,
  activeLabel,
  inactiveLabel,
  ml = 2,
}) => {
  const { t } = useTranslation();

  return active ? (
    <Badge data-testid="active-badge" ml={ml} colorScheme="green">
      {activeLabel || t('common:active')}
    </Badge>
  ) : (
    <Badge data-testid="inactive-badge" ml={ml} variant="outline">
      {inactiveLabel || t('common:inactive')}
    </Badge>
  );
};
