import React, { useCallback, useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { FaGift } from 'react-icons/fa';
import { Box, Heading, Spinner, StackDivider, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';

import { shortDateFormatKey } from '@m3ter-com/console-core/utils';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { ButtonPanel } from '@m3ter-com/ui-components';

import { getWhatsNew } from '@/services/docs';
import { HelpContent } from '@/components/common/docs/HelpContent';
import {
  getWhatsNewLastOpenedDate,
  setWhatsNewLastOpenedDate,
} from '@/util/localStorage';

export interface WhatsNewButtonProps {}

export const WhatsNewButton: React.FC<WhatsNewButtonProps> = () => {
  const { t } = useTranslation();

  const [lastOpened, setLastOpened] = useState(getWhatsNewLastOpenedDate());

  const { data } = useQuery({
    queryKey: ['whats-new'],
    queryFn: getWhatsNew,
  });

  const count = useMemo<number>(() => {
    if (!data) {
      return 0;
    }

    if (!lastOpened) {
      return data.length;
    }

    // Return the count of news that's newer than the last opened date.
    return data.reduce((acc, news) => {
      return news.date > lastOpened ? acc + 1 : acc;
    }, 0);
  }, [data, lastOpened]);

  const onOpen = useCallback(() => {
    const newLastOpened = format(new Date(), shortDateFormatKey);
    setLastOpened(newLastOpened);
    setWhatsNewLastOpenedDate(newLastOpened);
  }, []);

  return (
    <ButtonPanel
      icon={<FaGift />}
      title={t('common:whatsNew')}
      badge={count > 0 ? count : undefined}
      onOpen={onOpen}
    >
      {data ? (
        <VStack divider={<StackDivider />} spacing={4} alignItems="stretch">
          {data.map((news) => (
            <Box key={news.id}>
              <Heading size="sm" mb={2}>
                {news.title}
              </Heading>
              <HelpContent data={news.content} />
            </Box>
          ))}
        </VStack>
      ) : (
        <Spinner />
      )}
    </ButtonPanel>
  );
};
