import React, { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';
import { chakra } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Select,
  SelectOption,
  SelectOptionGroup,
} from '@m3ter-com/ui-components';

import useNamedNavigate from '@/hooks/navigation/useNamedNavigate';
import useOrg from '@/hooks/data/crud/useOrg';
import {
  selectStandardOrgs,
  selectSupportOrgs,
} from '@/store/app/bootstrap/bootstrap';

export const OrgSwitcher: React.FC = () => {
  const { t } = useTranslation();
  const { currentOrgId } = useOrg();
  const standardOrgs = useSelector(selectStandardOrgs);
  const supportOrgs = useSelector(selectSupportOrgs);
  const options = useMemo<Array<SelectOption | SelectOptionGroup>>(() => {
    const standardOrgOptions = sortBy(
      standardOrgs.map((org) => ({
        value: org.id,
        label: org.organizationName,
      })),
      'label'
    );
    if (supportOrgs.length === 0) {
      return standardOrgOptions;
    }

    return [
      {
        label: t('features:organizations.standardOrganizations'),
        options: standardOrgOptions,
      },
      {
        label: t('features:organizations.support.supportAccess'),
        options: sortBy(
          supportOrgs.map((org) => ({
            value: org.id,
            label: org.organizationName,
          })),
          'label'
        ),
      },
    ];
  }, [standardOrgs, supportOrgs, t]);

  const navigate = useNamedNavigate();

  const onChange = useCallback(
    (newOrgId: string | null) => {
      // We can safely cast here since we aren't making the <Select> clearable
      navigate('organization', { orgId: newOrgId as string });
    },
    [navigate]
  );

  return (
    <chakra.div data-testid="org-switcher-select">
      <Select
        isSearchable
        options={options}
        value={currentOrgId}
        onChange={onChange}
        menuPlacement="top"
      />
    </chakra.div>
  );
};
