import { combineReducers } from '@reduxjs/toolkit';

import serviceUserCreateReducer from './service-user/serviceUserCreate';
import serviceUserCredentialsReducer from './service-user/serviceUserCredentials';
import supportAccessUpdateReducer from './support-access/supportAccessUpdate';
import userActivationReducer from './users/userActivation';
import userGroupCreateReducer from './user-groups/userGroupCreate';

const accessReducer = combineReducers({
  serviceUserCreate: serviceUserCreateReducer,
  serviceUserCredentials: serviceUserCredentialsReducer,
  supportAccessUpdate: supportAccessUpdateReducer,
  userActivation: userActivationReducer,
  userGroupCreate: userGroupCreateReducer,
});

export default accessReducer;
