import { ComponentSingleStyleConfig } from '@chakra-ui/react';

const text: ComponentSingleStyleConfig = {
  variants: {
    annotation: {
      color: 'chakra-subtle-text',
      fontSize: 'sm',
    },
  },
};

export default text;
