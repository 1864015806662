import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { ItemCountersRoute } from '@/components/routes/item-counters/ItemCountersRoute';
import { ItemCountersListRoute } from '@/components/routes/item-counters/ItemCountersListRoute';
import { ItemCountersDetailsRoute } from '@/components/routes/item-counters/ItemCountersDetailsRoute';

const LazyItemCountersCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "ItemCountersCreateRoute" */ '@/components/routes/item-counters/ItemCountersCreateRoute'
  ).then((module) => ({ default: module.ItemCountersCreateRoute }))
);

const LazyItemCountersEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "ItemCountersEditRoute" */ '@/components/routes/item-counters/ItemCountersEditRoute'
  ).then((module) => ({ default: module.ItemCountersEditRoute }))
);
export default () =>
  createCrudRoute({
    path: 'counters',
    dataType: DataType.Counter,
    element: <ItemCountersRoute />,
    list: <ItemCountersListRoute />,
    details: <ItemCountersDetailsRoute />,
    create: <LazyItemCountersCreateRoute />,
    edit: <LazyItemCountersEditRoute />,
  });
