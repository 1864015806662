import React from 'react';

import { Box } from '@chakra-ui/react';

import { PageTitle } from '@/components/common/breadcrumbs/PageTitle';
import { Breadcrumbs } from '@/components/common/breadcrumbs/Breadcrumbs';

export interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => (
  <Box>
    <Breadcrumbs />
    <PageTitle />
  </Box>
);
