import React from 'react';

import { useWatch } from 'react-hook-form';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  FormAdvancedNumberInput,
  FormField,
  FormInput,
  FormSection,
} from '@m3ter-com/console-core/components';

import {
  DataTypeFormEntitySelect,
  FormEntitySelect,
} from '@/components/forms/FormEntitySelect';
import { BillInAdvanceField } from '@/components/features/pricing/BillInAdvanceField';
import useOrg from '@/hooks/data/crud/useOrg';
import { getBillInAdvanceDescription } from '@/util/billing';

export const PlanGroupFormFields: React.FC = () => {
  const { t } = useTranslation();
  const { orgConfig } = useOrg();

  const currency: string | undefined = useWatch({ name: 'currency' });
  const isPlanGroupDetailsMissing = !currency;

  return (
    <React.Fragment>
      <FormSection isOptional heading={t('forms:labels.standingChargeHeading')}>
        <FormField
          name="standingCharge"
          label={t('forms:labels.standingCharge')}
          control={FormAdvancedNumberInput}
          isDisabled={isPlanGroupDetailsMissing}
        />
        <FormField
          isRequired
          name="standingChargeBillInAdvance"
          helpText={t('forms:helpText.standingChargeBilling')}
          label={t('forms:labels.standingChargeBilling')}
          control={BillInAdvanceField}
          defaultLabel={t('forms:labels.useConfigFromOrgConfig', {
            default: getBillInAdvanceDescription(
              orgConfig.standingChargeBillInAdvance
            ),
          })}
          isDisabled={isPlanGroupDetailsMissing}
        />
        <FormField
          isClearable
          name="standingChargeAccountingProductId"
          label={t('forms:labels.standingChargeAccountingProduct')}
          control={
            FormEntitySelect as DataTypeFormEntitySelect<DataType.Product>
          }
          dataType={DataType.Product}
          accessor="name"
          detailAccessor="code"
          isDisabled={isPlanGroupDetailsMissing}
        />
        <FormField
          name="standingChargeDescription"
          helpText={t('features:pricing.displayedBillLineItem')}
          label={t('forms:labels.standingChargeDescription')}
          control={FormInput}
          isDisabled={isPlanGroupDetailsMissing}
        />
      </FormSection>
      <FormSection isOptional heading={t('forms:labels.minimumSpendHeading')}>
        <FormField
          name="minimumSpend"
          label={t('forms:labels.minimumSpend')}
          control={FormAdvancedNumberInput}
          isDisabled={isPlanGroupDetailsMissing}
        />
        <FormField
          isRequired
          name="minimumSpendBillInAdvance"
          helpText={t('forms:helpText.minimumSpendBilling')}
          label={t('forms:labels.minimumSpendBilling')}
          control={BillInAdvanceField}
          defaultLabel={t('forms:labels.useConfigFromOrgConfig', {
            default: getBillInAdvanceDescription(
              orgConfig.minimumSpendBillInAdvance
            ),
          })}
          isDisabled={isPlanGroupDetailsMissing}
        />
        <FormField
          isClearable
          name="minimumSpendAccountingProductId"
          label={t('forms:labels.minimumSpendAccountingProduct')}
          control={
            FormEntitySelect as DataTypeFormEntitySelect<DataType.Product>
          }
          dataType={DataType.Product}
          accessor="name"
          detailAccessor="code"
          isDisabled={isPlanGroupDetailsMissing}
        />
        <FormField
          name="minimumSpendDescription"
          helpText={t('features:pricing.displayedBillLineItem')}
          label={t('forms:labels.minimumSpendDescription')}
          control={FormInput}
          isDisabled={isPlanGroupDetailsMissing}
        />
      </FormSection>
    </React.Fragment>
  );
};
