import React from 'react';

import {
  forwardRef,
  Link as ChakraLink,
  LinkProps,
  useAlertStyles,
} from '@chakra-ui/react';

export interface AlertLinkProps extends LinkProps {}

export const AlertLink = forwardRef<AlertLinkProps, 'a'>((props, ref) => {
  // Chakra doesn't export `useAlertStatus` but we can match the colour
  // of the link to that of an `AlertIcon`.
  const styles = useAlertStyles();
  const linkStyles = {
    color: styles?.icon?.color ?? 'inherit',
  };

  return <ChakraLink ref={ref} sx={linkStyles} {...props} />;
});
