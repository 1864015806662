import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { CommitmentsRoute } from '@/components/routes/accounts/CommitmentsRoute';
import { CommitmentsListRoute } from '@/components/routes/accounts/CommitmentsListRoute';
import { CommitmentsCreateRoute } from '@/components/routes/accounts/CommitmentsCreateRoute';
import { CommitmentsEditRoute } from '@/components/routes/accounts/CommitmentsEditRoute';
import { CommitmentsRetrieveRoute } from '@/components/routes/accounts/CommitmentsRetrieveRoute';

const LazyCommitmentsDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "CommitmentsDetails" */ '@/components/routes/accounts/CommitmentsDetailsRoute'
  ).then((module) => ({ default: module.CommitmentsDetailsRoute }))
);

export default () =>
  createCrudRoute({
    path: 'commitments',
    dataType: DataType.Commitment,
    element: <CommitmentsRoute />,
    list: <CommitmentsListRoute />,
    create: <CommitmentsCreateRoute />,
    edit: <CommitmentsEditRoute />,
    details: <LazyCommitmentsDetailsRoute />,
    retrieve: <CommitmentsRetrieveRoute />,
  });
