import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const radio: ComponentMultiStyleConfig = {
  parts: ['label'],
  baseStyle: {
    label: {
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    },
  },
};

export default radio;
