import React, { useMemo } from 'react';

import { Entity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FCWithControl } from '@m3ter-com/console-core/types';
import { FormField, FormFieldProps } from '@m3ter-com/console-core/components';
import { SelectOption } from '@m3ter-com/ui-components';

import {
  ListFilterDefinition,
  ListFilterFieldType,
  ListSearchComparator,
} from '@/types/lists';

import { CrudListCheckboxFilter } from './CrudListCheckboxFilter';
import { CrudListDateFilter } from './CrudListDateFilter';
import { CrudListDateRangeFilter } from './CrudListDateRangeFilter';
import { CrudListInputFilter } from './CrudListInputFilter';
import { CrudListNumberFilter } from './CrudListNumberFilter';
import { CrudListSelectFilter } from './CrudListSelectFilter';
import { CrudListSearchableEntitySelectFilter } from './CrudListSearchableEntitySelectFilter';

interface FilterInputProps extends FormFieldProps {
  comparatorOptions: Array<SelectOption>;
}

const FilterInput: FCWithControl<FilterInputProps> = ({
  name,
  label,
  helpText,
  comparatorOptions,
  control: Control,
  ...controlProps
}) => (
  <FormField
    name={name}
    label={label}
    helpText={helpText}
    control={Control}
    comparatorOptions={comparatorOptions}
    {...controlProps}
  />
);

interface CrudListFilterFieldProps<E extends Entity = Entity> {
  filterDefinition: ListFilterDefinition<E>;
}

const checkboxComparators = [ListSearchComparator.Equal];
const selectComparators = [ListSearchComparator.Equal];
const inputComparators = [
  ListSearchComparator.Contains,
  ListSearchComparator.Equal,
];
const rangeComparators = [
  ListSearchComparator.LessThanOrEqual,
  ListSearchComparator.GreaterThanOrEqual,
];
const dateOrNumberComparators = [
  ListSearchComparator.Equal,
  ListSearchComparator.NotEqual,
  ListSearchComparator.LessThan,
  ListSearchComparator.LessThanOrEqual,
  ListSearchComparator.GreaterThan,
  ListSearchComparator.GreaterThanOrEqual,
];

export function CrudListFilterField<EntityType extends Entity = Entity>({
  filterDefinition,
}: CrudListFilterFieldProps<EntityType>) {
  const { t } = useTranslation();
  const {
    valueTranslator: _,
    filterFieldType,
    ...controlProps
  } = filterDefinition;

  const checkboxComparatorOptions = useMemo<Array<SelectOption>>(
    () =>
      checkboxComparators.map((comparator) => ({
        value: comparator,
        label: t(`common:searchComparator.${comparator}`),
      })),
    [t]
  );

  const dateOrNumberComparatorOptions = useMemo(
    () =>
      dateOrNumberComparators.map((comparator) => ({
        value: comparator,
        label: t(`common:searchComparator.${comparator}`),
      })),
    [t]
  );

  const dateRangeComparatorOptions = useMemo<Array<SelectOption>>(
    () =>
      rangeComparators.map((comparator) => ({
        value: comparator,
        label: t(`common:searchComparator.${comparator}`),
      })),
    [t]
  );

  const inputComparatorOptions = useMemo<Array<SelectOption>>(
    () =>
      inputComparators.map((comparator) => ({
        value: comparator,
        label: t(`common:searchComparator.${comparator}`),
      })),
    [t]
  );

  const selectComparatorOptions = useMemo<Array<SelectOption>>(
    () =>
      selectComparators.map((comparator) => ({
        value: comparator,
        label: t(`common:searchComparator.${comparator}`),
      })),
    [t]
  );

  const comparatorMap = useMemo(
    () => ({
      [ListFilterFieldType.Checkbox]: checkboxComparatorOptions,
      [ListFilterFieldType.Input]: inputComparatorOptions,
      [ListFilterFieldType.Select]: selectComparatorOptions,
      [ListFilterFieldType.SearchableEntitySelect]: selectComparatorOptions,
      [ListFilterFieldType.Date]: dateOrNumberComparatorOptions,
      [ListFilterFieldType.DateRange]: dateRangeComparatorOptions,
      [ListFilterFieldType.Number]: dateOrNumberComparatorOptions,
    }),
    [
      checkboxComparatorOptions,
      dateRangeComparatorOptions,
      dateOrNumberComparatorOptions,
      selectComparatorOptions,
      inputComparatorOptions,
    ]
  );

  const controlMap = useMemo(
    () => ({
      [ListFilterFieldType.Date]: CrudListDateFilter,
      [ListFilterFieldType.DateRange]: CrudListDateRangeFilter,
      [ListFilterFieldType.Checkbox]: CrudListCheckboxFilter,
      [ListFilterFieldType.Input]: CrudListInputFilter,
      [ListFilterFieldType.Number]: CrudListNumberFilter,
      [ListFilterFieldType.Select]: CrudListSelectFilter,
      [ListFilterFieldType.SearchableEntitySelect]:
        CrudListSearchableEntitySelectFilter,
    }),
    []
  );

  return (
    <FilterInput
      control={controlMap[filterFieldType]}
      comparatorOptions={comparatorMap[filterFieldType]}
      {...controlProps}
    />
  );
}
