import { DataType } from '@m3ter-com/m3ter-api';

export enum RelationshipType {
  OneToMany = 'ONE_TO_MANY',
  OneToOne = 'ONE_TO_ONE',
}

interface RelationshipConfig {
  foreignKey: string;
  dataType: DataType;
  type: RelationshipType;
}

interface EntityConfig {
  relationships: Partial<Record<string, RelationshipConfig>>;
}

const entities: Record<DataType, EntityConfig> = {
  [DataType.Account]: {
    relationships: {},
  },
  [DataType.AccountPlan]: {
    relationships: {
      account: {
        foreignKey: 'accountId',
        dataType: DataType.Account,
        type: RelationshipType.OneToOne,
      },
      plan: {
        foreignKey: 'planId',
        dataType: DataType.Plan,
        type: RelationshipType.OneToOne,
      },
      planGroup: {
        foreignKey: 'planGroupId',
        dataType: DataType.PlanGroup,
        type: RelationshipType.OneToOne,
      },
    },
  },
  [DataType.Aggregation]: {
    relationships: {
      meter: {
        foreignKey: 'meterId',
        dataType: DataType.Meter,
        type: RelationshipType.OneToOne,
      },
    },
  },
  [DataType.Alert]: {
    relationships: {},
  },
  [DataType.AnalyticsJob]: {
    relationships: {},
  },
  [DataType.Balance]: {
    relationships: {},
  },
  [DataType.BalanceTransaction]: {
    relationships: {},
  },
  [DataType.Bill]: {
    relationships: {
      account: {
        foreignKey: 'accountId',
        dataType: DataType.Account,
        type: RelationshipType.OneToOne,
      },
    },
  },
  [DataType.BillConfig]: {
    relationships: {},
  },
  [DataType.BillJob]: {
    relationships: {},
  },
  [DataType.BillLineItem]: {
    relationships: {},
  },
  [DataType.Commitment]: {
    relationships: {
      account: {
        foreignKey: 'accountId',
        dataType: DataType.Account,
        type: RelationshipType.OneToOne,
      },
      billingPlan: {
        foreignKey: 'billingPlanId',
        dataType: DataType.Plan,
        type: RelationshipType.OneToOne,
      },
      products: {
        foreignKey: 'productIds',
        dataType: DataType.Product,
        type: RelationshipType.OneToMany,
      },
      contract: {
        foreignKey: 'contractId',
        dataType: DataType.Contract,
        type: RelationshipType.OneToOne,
      },
    },
  },
  [DataType.CompoundAggregation]: {
    relationships: {},
  },
  [DataType.Contract]: {
    relationships: {
      account: {
        foreignKey: 'accountId',
        dataType: DataType.Account,
        type: RelationshipType.OneToOne,
      },
    },
  },
  [DataType.Counter]: {
    relationships: {
      product: {
        foreignKey: 'productId',
        dataType: DataType.Product,
        type: RelationshipType.OneToOne,
      },
    },
  },
  [DataType.CounterAdjustment]: {
    relationships: {
      account: {
        foreignKey: 'accountId',
        dataType: DataType.Account,
        type: RelationshipType.OneToOne,
      },
      itemCounter: {
        foreignKey: 'counterId',
        dataType: DataType.Counter,
        type: RelationshipType.OneToOne,
      },
    },
  },
  [DataType.CounterPricing]: {
    relationships: {
      itemCounter: {
        foreignKey: 'counterId',
        dataType: DataType.Counter,
        type: RelationshipType.OneToOne,
      },
      plan: {
        foreignKey: 'planId',
        dataType: DataType.Plan,
        type: RelationshipType.OneToOne,
      },
      planTemplate: {
        foreignKey: 'planTemplateId',
        dataType: DataType.PlanTemplate,
        type: RelationshipType.OneToOne,
      },
    },
  },
  [DataType.CreditLineItem]: {
    relationships: {},
  },
  [DataType.CreditReason]: {
    relationships: {},
  },
  [DataType.Currency]: {
    relationships: {},
  },
  [DataType.CurrentUser]: {
    relationships: {},
  },
  [DataType.Customer]: {
    relationships: {},
  },
  [DataType.DataExplorerSavedQuery]: {
    relationships: {},
  },
  [DataType.DebitLineItem]: {
    relationships: {},
  },
  [DataType.DebitReason]: {
    relationships: {},
  },
  [DataType.ExternalMapping]: {
    relationships: {},
  },
  [DataType.ExternalMappingConfig]: {
    relationships: {},
  },
  [DataType.Integration]: {
    relationships: {},
  },
  [DataType.IntegrationCredential]: {
    relationships: {},
  },
  [DataType.Invitation]: {
    relationships: {},
  },
  [DataType.MeasurmentsDeletion]: {
    relationships: {},
  },
  [DataType.Meter]: {
    relationships: {
      product: {
        foreignKey: 'productId',
        dataType: DataType.Product,
        type: RelationshipType.OneToOne,
      },
    },
  },
  [DataType.M3terEvent]: {
    relationships: {
      account: {
        foreignKey: 'm3terEvent.eventData.accountId',
        dataType: DataType.Account,
        type: RelationshipType.OneToOne,
      },
    },
  },
  [DataType.NotificationRule]: {
    relationships: {},
  },
  [DataType.Organization]: {
    relationships: {},
  },
  [DataType.OrganizationAdmin]: {
    relationships: {
      customer: {
        foreignKey: 'customerId',
        dataType: DataType.Customer,
        type: RelationshipType.OneToOne,
      },
    },
  },
  [DataType.OrganizationConfig]: {
    relationships: {},
  },
  [DataType.OrganizationCustomFields]: {
    relationships: {},
  },
  [DataType.PermissionPolicy]: {
    relationships: {},
  },
  [DataType.Plan]: {
    relationships: {
      account: {
        foreignKey: 'accountId',
        dataType: DataType.Account,
        type: RelationshipType.OneToOne,
      },
      planTemplate: {
        foreignKey: 'planTemplateId',
        dataType: DataType.PlanTemplate,
        type: RelationshipType.OneToOne,
      },
    },
  },
  [DataType.PlanGroup]: {
    relationships: {},
  },
  [DataType.PlanGroupLink]: {
    relationships: {
      plan: {
        foreignKey: 'planId',
        dataType: DataType.Plan,
        type: RelationshipType.OneToOne,
      },
      planGroup: {
        foreignKey: 'planGroupId',
        dataType: DataType.PlanGroup,
        type: RelationshipType.OneToOne,
      },
    },
  },
  [DataType.PlanTemplate]: {
    relationships: {
      product: {
        foreignKey: 'productId',
        dataType: DataType.Product,
        type: RelationshipType.OneToOne,
      },
    },
  },
  [DataType.Pricing]: {
    relationships: {
      plan: {
        foreignKey: 'planId',
        dataType: DataType.Plan,
        type: RelationshipType.OneToOne,
      },
      planTemplate: {
        foreignKey: 'planTemplateId',
        dataType: DataType.PlanTemplate,
        type: RelationshipType.OneToOne,
      },
      aggregation: {
        foreignKey: 'aggregationId',
        dataType: DataType.Aggregation,
        type: RelationshipType.OneToOne,
      },
      compoundAggregation: {
        foreignKey: 'compoundAggregationId',
        dataType: DataType.CompoundAggregation,
        type: RelationshipType.OneToOne,
      },
    },
  },
  [DataType.Product]: {
    relationships: {},
  },
  [DataType.ServiceUser]: {
    relationships: {},
  },
  [DataType.StatementDefintion]: {
    relationships: {},
  },
  [DataType.StatementJob]: {
    relationships: {},
  },
  [DataType.SupportAccess]: {
    relationships: {},
  },
  [DataType.SupportOrganization]: {
    relationships: {},
  },
  [DataType.TransactionType]: {
    relationships: {},
  },
  [DataType.UploadJob]: {
    relationships: {},
  },
  [DataType.UserGroup]: {
    relationships: {},
  },
  [DataType.User]: {
    relationships: {},
  },
  [DataType.UserAdmin]: {
    relationships: {},
  },
  [DataType.Destination]: {
    relationships: {},
  },
};

export const getRelationship = (
  dataType: DataType,
  name: string
): RelationshipConfig => {
  const relationship = entities[dataType].relationships?.[name];

  if (!relationship) {
    throw new Error(`Unable to find relationship ${name} on ${dataType}`);
  }

  return relationship;
};

export default entities;
