import React, { useMemo } from 'react';

import sortBy from 'lodash/sortBy';
import { SimpleGrid } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue, Well } from '@m3ter-com/ui-components';
import {
  FormField,
  FormInput,
  FormSection,
} from '@m3ter-com/console-core/components';

import { useIntegrationConfigSchemaContext } from '@/components/features/integrations/IntegrationConfigSchemaContext';
import { IntegrationConfigParameterField } from '@/components/features/integrations/IntegrationConfigParameterField';

export interface IntegrationConfigFormConfigureFieldsProps {
  destination: string;
  entityType: string;
}

export const IntegrationConfigFormConfigureFields: React.FC<
  IntegrationConfigFormConfigureFieldsProps
> = ({ destination, entityType }) => {
  const { t } = useTranslation();

  const { configSchema } = useIntegrationConfigSchemaContext();

  const destinationName = useMemo(() => {
    const entityDestinations =
      configSchema.supportedEntities[entityType]?.destinations;
    if (entityDestinations) {
      const destinationSchema = entityDestinations.find(
        (d) => d.destination === destination
      );
      if (destinationSchema) {
        return destinationSchema.displayName;
      }
    }

    return '-';
  }, [configSchema, destination, entityType]);

  const globalParameterSchemas = useMemo(
    () =>
      sortBy(configSchema.globalConfigurationOptions, (parameterSchema) =>
        parameterSchema.name.toLowerCase()
      ),
    [configSchema]
  );

  const entitySpecificParameterSchemas = useMemo(() => {
    const entitySchema = configSchema.supportedEntities[entityType];
    if (!entitySchema) {
      return [];
    }

    return sortBy(entitySchema.entityConfigurationOptions, (parameterSchema) =>
      parameterSchema.name.toLowerCase()
    );
  }, [configSchema, entityType]);

  const destinationSpecificParameterSchemas = useMemo(() => {
    const entitySchema = configSchema.supportedEntities[entityType];
    if (!entitySchema) {
      return [];
    }

    const destinationSchema = entitySchema.destinations.find(
      (d) => d.destination === destination
    );
    if (!destinationSchema) {
      return [];
    }

    return sortBy(
      destinationSchema.destinationConfigOptions || [],
      (parameterSchema) => parameterSchema.name.toLowerCase()
    );
  }, [configSchema, destination, entityType]);

  return (
    <React.Fragment>
      <Well width="100%">
        <SimpleGrid columns={2}>
          <KeyValue
            label={t('features:integrations.entityType')}
            value={entityType}
          />
          <KeyValue
            label={t('features:integrations.integrationDestination')}
            value={destinationName}
          />
        </SimpleGrid>
      </Well>
      <FormSection
        isOptional
        useDivider
        heading={t('features:integrations.globalConfig')}
      >
        <FormField
          isRequired
          name="name"
          label={t('forms:labels.name')}
          control={FormInput}
        />
        {globalParameterSchemas.map((parameterSchema) => (
          <IntegrationConfigParameterField
            key={parameterSchema.name}
            name={`configData.${parameterSchema.name}`}
            schema={parameterSchema}
          />
        ))}
      </FormSection>
      {entitySpecificParameterSchemas.length > 0 && (
        <FormSection
          isOptional
          useDivider
          heading={t('features:integrations.entityConfig')}
        >
          {entitySpecificParameterSchemas.map((parameterSchema) => (
            <IntegrationConfigParameterField
              key={parameterSchema.name}
              name={`configData.${parameterSchema.name}`}
              schema={parameterSchema}
            />
          ))}
        </FormSection>
      )}
      {destinationSpecificParameterSchemas.length > 0 && (
        <FormSection
          isOptional
          useDivider
          heading={t('features:integrations.destinationConfig')}
        >
          {destinationSpecificParameterSchemas.map((parameterSchema) => (
            <IntegrationConfigParameterField
              key={parameterSchema.name}
              name={`configData.${parameterSchema.name}`}
              schema={parameterSchema}
            />
          ))}
        </FormSection>
      )}
    </React.Fragment>
  );
};
