import React from 'react';

import { DataType, MeasurementsDeletion } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { MeasurementsDeletionsRoute } from '@/components/routes/admin/measurements-deletions/MeasurementsDeletionsRoute';
import { MeasurementsDeletionsListRoute } from '@/components/routes/admin/measurements-deletions/MeasurementsDeletionsListRoute';
import { MeasurementsDeletionsCreateRoute } from '@/components/routes/admin/measurements-deletions/MeasurementsDeletionsCreateRoute';

export default () =>
  createCrudRoute<MeasurementsDeletion>({
    path: 'measurements-deletions',
    dataType: DataType.MeasurmentsDeletion,
    element: <MeasurementsDeletionsRoute />,
    list: <MeasurementsDeletionsListRoute />,
    create: <MeasurementsDeletionsCreateRoute />,
  });
