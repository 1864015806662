import React from 'react';

import { AccountPlan } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { AccountPlanForm } from '@/components/features/accounts/AccountPlanForm';

export const AccountPlansEditRoute: React.FC = () => (
  <CrudEdit<AccountPlan> form={AccountPlanForm} />
);
