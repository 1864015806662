import React from 'react';

import { Bill } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { BillDetails } from '@/components/features/billing/BillDetails';

export const BillDetailsRoute: React.FC = () => (
  <CrudDetails<Bill> component={BillDetails} />
);
