import React from 'react';

import { DataType, Plan, PlanTemplate } from '@m3ter-com/m3ter-api';

import { DetailsCard } from '@/components/common/data/DetailsCard';

import { PlanDetailsContent } from './PlanDetailsContent';

interface PlanDetailsProps {
  plan: Plan;
  planTemplate: PlanTemplate;
  showEditButton?: boolean;
  showIsCustomDetails?: boolean;
}

export const PlanDetails: React.FC<PlanDetailsProps> = ({
  plan,
  planTemplate,
  showEditButton = false,
  showIsCustomDetails,
}) => {
  return (
    <DetailsCard
      data={plan}
      dataType={DataType.Plan}
      showCustomFields
      showEditButton={showEditButton}
      details={
        <PlanDetailsContent
          plan={plan}
          planTemplate={planTemplate}
          showIsCustomDetails={showIsCustomDetails}
        />
      }
    />
  );
};
