import React, { createContext, useContext, PropsWithChildren } from 'react';

import { FirstDayOfWeek, MonthType } from '@datepicker-react/hooks';

export interface CalendarTranslations {
  timeDropdownPlaceHolder: string;
  mondayInitial: string;
  tuesdayInitial: string;
  wednesdayInitial: string;
  thursdayInitial: string;
  fridayInitial: string;
  saturdayInitial: string;
  sundayInitial: string;
}

export interface CalendarContextValues {
  activeMonths: Array<MonthType>;
  firstDayOfWeek: FirstDayOfWeek;
  focusedDate: Date | null;
  translations: CalendarTranslations;
  showHoverRange: boolean;
  isDateBlocked: (date: Date) => boolean;
  isDateFocused: (date: Date) => boolean;
  isDateHovered: (date: Date) => boolean;
  isDateSelected: (date: Date) => boolean;
  isFirstOrLastSelectedDate: (date: Date) => boolean;
  onDateFocus: (date: Date) => void;
  onDateHover: (date: Date) => void;
  onDateSelect: (date: Date) => void;
  goToNextMonth: () => void;
  goToNextYear: (numberOfYears?: number) => void;
  goToPreviousMonth: () => void;
  goToPreviousYear: (numberOfYears?: number) => void;
}

const calendarContext = createContext<CalendarContextValues | null>(null);

export const CalendarContextProvider: React.FC<
  PropsWithChildren<CalendarContextValues>
> = ({ children, ...contextProps }) => {
  return (
    <calendarContext.Provider value={contextProps}>
      {children}
    </calendarContext.Provider>
  );
};

export const useCalendarContext = () =>
  useContext(calendarContext) as CalendarContextValues;

export const defaultTranslations: CalendarTranslations = {
  timeDropdownPlaceHolder: 'Select a time',
  mondayInitial: 'M',
  tuesdayInitial: 'T',
  wednesdayInitial: 'W',
  thursdayInitial: 'T',
  fridayInitial: 'F',
  saturdayInitial: 'S',
  sundayInitial: 'S',
};
