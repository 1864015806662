import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
} from 'react';

import { useFormControl } from '@chakra-ui/react';
import { ActionMeta, createFilter, GroupBase, MultiValue } from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';

import useReactSelectStyles from '../../../../theme/hooks/useReactSelectStyles';
import { MultiSelectBaseProps, Option } from '../types';
import { isOptionNotOptionGroup } from '../utils';
import useSelectKeyDown from '../../../../hooks/useSelectKeyDown';

export interface CreatableMultiSelectProps<T extends string = string>
  extends MultiSelectBaseProps<T>,
    Pick<
      CreatableProps<Option<T>, true, GroupBase<Option<T>>>,
      'inputId' | 'menuPlacement' | 'placeholder'
    > {}

const EmptyComponent: React.FC = () => null;

export function CreatableMultiSelect<T extends string = string>({
  options,
  value,
  onChange,
  isDisabled,
  isLoading,
  isSearchable,
  inputId,
  menuPlacement,
  placeholder,
  searchMatchFrom,
}: PropsWithChildren<CreatableMultiSelectProps<T>>): ReactElement<any, any> {
  const { onMenuOpen, onMenuClose, onSelectKeyDown } = useSelectKeyDown();
  const { styles, theme } = useReactSelectStyles<Option<T>>();

  // Get the ID from the Chakra `FormControl` if there is one, so that
  // the label and form field are connected.
  const { id } = useFormControl({});

  const handleChange = useCallback(
    (newOptions: MultiValue<Option<T>>, actionMeta: ActionMeta<Option<T>>) => {
      if (!newOptions?.length || actionMeta.action === 'clear') {
        onChange([]);
      } else {
        onChange(newOptions.map((o) => o.value));
      }
    },
    [onChange]
  );

  const selectedOptions = useMemo(() => {
    // We need an option for every item in the value or we can't create new ones.
    return (value ?? []).map((v) => {
      // Try to use a genuine option first.
      const option = options.find(
        (o) => isOptionNotOptionGroup(o) && o.value === v
      );
      return option ?? { value: v, label: v };
    }) as Array<Option<T>>;
  }, [options, value]);

  const hasOptions = options.length > 0;

  const components = useMemo(
    () =>
      hasOptions
        ? {}
        : {
            DropdownIndicator: EmptyComponent,
            IndicatorSeparator: EmptyComponent,
          },
    [hasOptions]
  );

  const filterOption = useMemo(
    () =>
      createFilter({
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: searchMatchFrom,
        stringify: (option) => option.label,
        trim: true,
      }),
    [searchMatchFrom]
  );

  return (
    <CreatableSelect<Option<T>, true>
      isMulti
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      onKeyDown={onSelectKeyDown}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      components={components}
      formatCreateLabel={(input: string) => `Add '${input}'`}
      noOptionsMessage={() => null}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isSearchable={isSearchable}
      inputId={inputId ?? id}
      placeholder={placeholder}
      menuPlacement={menuPlacement}
      styles={styles}
      theme={theme}
      filterOption={filterOption}
    />
  );
}
