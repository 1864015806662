import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { Frequency, Id, PlanTemplate } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { selectSelectedProductId } from '@/store/products/products';
import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { PlanTemplateForm } from '@/components/features/pricing/PlanTemplateForm';
import useQueryString from '@/hooks/navigation/useQueryString';
import { RequiresCurrenciesWrapper } from '@/components/features/organization/RequiresCurrenciesWrapper';

interface PlanTemplateQueryParams {
  productId?: Id;
}

export const PlanTemplatesCreateRoute: React.FC = () => {
  const { t } = useTranslation();
  const selectedProductId = useSelector(selectSelectedProductId)!;
  const { productId = selectedProductId } =
    useQueryString<PlanTemplateQueryParams>();

  const initialValues = useMemo<Partial<PlanTemplate>>(
    () => ({
      productId,
      ordinal: 1,
      billFrequency: Frequency.MONTHLY,
      billFrequencyInterval: 1,
      standingCharge: 0,
      standingChargeInterval: 1,
      standingChargeOffset: 0,
    }),
    [productId]
  );

  return (
    <RequiresCurrenciesWrapper
      message={t('features:plans.noCurrenciesMessage')}
    >
      <CrudCreate<PlanTemplate>
        form={PlanTemplateForm}
        initialValues={initialValues}
      />
    </RequiresCurrenciesWrapper>
  );
};
