import React from 'react';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { ServiceUserCredential } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button } from '@m3ter-com/ui-components';

import useOrg from '@/hooks/data/crud/useOrg';
import { ServiceUserCredentialDetails } from '@/components/features/access/service-user/ServiceUserCredentialDetails';

export interface ServiceUserCredentialModalProps {
  credential: ServiceUserCredential;
  isOpen: boolean;
  onClose: () => void;
}

export const ServiceUserCredentialModal: React.FC<
  ServiceUserCredentialModalProps
> = ({ credential, isOpen, onClose }) => {
  const { t } = useTranslation();
  const { currentOrgId } = useOrg();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="100%" width="max-content">
        <ModalHeader>
          {t('features:users.serviceUsers.generateAccessKey')}
        </ModalHeader>
        <ModalBody w="max-content">
          <ServiceUserCredentialDetails
            credential={credential}
            organizationId={currentOrgId}
          />
        </ModalBody>
        <ModalFooter>
          <Button type="button" mr={3} onClick={onClose}>
            {t('common:close')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
