import React from 'react';

import { NotificationRule } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { NotificationRuleDetails } from '@/components/features/notification-rules/NotificationRuleDetails';

export const NotificationRuleDetailsRoute: React.FC = () => (
  <CrudDetails<NotificationRule> component={NotificationRuleDetails} />
);
