import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const parts = ['headerContainer', 'headerButton'];

const accordionCard: ComponentMultiStyleConfig = {
  parts,
  baseStyle: {
    headerContainer: {
      w: '100%',
      h: 'max-content',
      padding: 4,
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottomWidth: 1,
    },
    headerContentContainer: {
      w: '100%',
      h: 'max-content',
    },
    headerButtonContainer: {
      w: 'max-content',
      h: 'max-content',
      alignSelf: 'flex-start',
      marginLeft: 4,
    },
  },
};

export default accordionCard;
