import { useCallback, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';

import { DataType, Id } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { AppError } from '@/types/errors';

import useOrg from '@/hooks/data/crud/useOrg';
import { performDataAction } from '@/services/api';
import { addNotification } from '@/store/notifications/notifications';
import { extractError } from '@/util/error';

const useResendTempPassword = (userId: Id) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentOrgId: organizationId } = useOrg();

  const [isResendingPassword, setIsResendingPassword] =
    useState<boolean>(false);
  const [resendingPasswordError, setResendingPasswordError] = useState<
    undefined | AppError
  >();

  const pathParams = useMemo(
    () => ({ organizationId, userId }),
    [organizationId, userId]
  );

  const resendTempPassword = useCallback(() => {
    setIsResendingPassword(true);
    setResendingPasswordError(undefined);

    performDataAction(DataType.User, 'resendTempPassword', pathParams)
      .then(() => {
        setIsResendingPassword(false);
        dispatch(
          addNotification(
            t('notifications:tempPasswordResentSuccess'),
            'success',
            5000
          )
        );
      })
      .catch((error) => {
        setIsResendingPassword(false);

        const appError = extractError(error);
        setResendingPasswordError(appError);
        dispatch(
          addNotification(
            t('notifications:tempPasswordResentFailure').replace(
              '{error}',
              appError.message
            ),
            'error',
            5000
          )
        );
      });
  }, [dispatch, pathParams, t]);

  return { resendTempPassword, isResendingPassword, resendingPasswordError };
};

export default useResendTempPassword;
