import React, { useCallback } from 'react';

import {
  VStack,
  SimpleGrid,
  FormControl,
  FormLabel,
  Switch,
} from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormInput } from '@m3ter-com/console-core/components';

export interface BillNumberingFieldsProps {
  name: string;
  prefixFieldName: string;
  sequenceStartFieldName: string;
}

export const BillNumberingFields: React.FC<BillNumberingFieldsProps> = ({
  name,
  prefixFieldName,
  sequenceStartFieldName,
}) => {
  const { t } = useTranslation();

  const { setValue } = useFormContext();
  const billPrefix = useWatch({ name: prefixFieldName });

  const isEnabled = billPrefix !== undefined && billPrefix !== null;

  const onBillNumberingChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setValue(prefixFieldName, '');
        setValue(sequenceStartFieldName, '1');
      } else {
        setValue(prefixFieldName, null, { shouldValidate: true });
        setValue(sequenceStartFieldName, null, { shouldValidate: true });
      }
    },
    [setValue, prefixFieldName, sequenceStartFieldName]
  );

  return (
    <VStack width="100%" alignItems="stretch">
      <Switch
        isChecked={isEnabled}
        name={name}
        onChange={onBillNumberingChange}
        alignSelf="start"
      />
      <SimpleGrid columns={2} spacing={2}>
        <FormControl isDisabled={!isEnabled}>
          <FormLabel>{t('forms:labels.billPrefix')}</FormLabel>
          <FormInput name={prefixFieldName} />
        </FormControl>
        <FormControl isDisabled={!isEnabled}>
          <FormLabel>{t('forms:labels.sequenceStartNumber')}</FormLabel>
          <FormInput type="number" name={sequenceStartFieldName} />
        </FormControl>
      </SimpleGrid>
    </VStack>
  );
};
