import React from 'react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { NotFoundScreen } from '@m3ter-com/ui-components';

export const NotFoundRoute: React.FC = () => {
  const { t } = useTranslation();

  return <NotFoundScreen message={t('common:pageNotFound')} />;
};
