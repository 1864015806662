import React from 'react';

import { User } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { UserDetails } from '@/components/features/access/user/UserDetails';

export const UsersDetailsRoute: React.FC = () => (
  <CrudDetails<User> component={UserDetails} />
);
