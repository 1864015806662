import React from 'react';

import { NamedRoute } from '@/types/routes';

import { OrganizationCustomFieldsRoute } from '@/components/routes/settings/organization/OrganizationCustomFieldsRoute';
import { OrganizationCustomFieldsDetailsRoute } from '@/components/routes/settings/organization/OrganizationCustomFieldsDetailsRoute';
import { OrganizationCustomFieldsEditRoute } from '@/components/routes/settings/organization/OrganizationCustomFieldsEditRoute';

export default (): NamedRoute => ({
  name: 'settings.org.customFields',
  path: 'custom-fields',
  element: <OrganizationCustomFieldsRoute />,
  children: [
    { index: true, element: <OrganizationCustomFieldsDetailsRoute /> },
    { path: 'edit', element: <OrganizationCustomFieldsEditRoute /> },
  ],
});
