import { call, select, StrictEffect } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import Big from 'big.js';

import {
  list,
  DataType,
  Plan,
  Pricing,
  PricingBand,
  UnsavedEntity,
  QueryParams,
} from '@m3ter-com/m3ter-api';

import {
  createData,
  deleteData,
  listAllData,
  retrieveData,
  updateData,
} from '@/store/data/data.saga';
import { isFeatureEnabled } from '@/store/features/flags/flags.saga';
import { Feature } from '@/hooks/util/useFeatureFlags';
import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';

const fieldsToCopy: Array<keyof Pricing> = [
  'cumulative',
  'endDate',
  'segment',
  'startDate',
  'tiersSpanPlan',
  'type',
];

const combinePricing = (
  percentPricing: Pricing,
  fixedPricing: Pricing
): Pricing => ({
  ...percentPricing,
  relatedPricingId: fixedPricing.id,
  pricingBands: percentPricing.pricingBands?.map((band, index) => {
    const relatedBand = fixedPricing.pricingBands?.[index];
    return {
      lowerLimit: band.lowerLimit,
      percentagePrice: new Big(band.unitPrice).mul(100).toNumber(),
      unitPrice: 0,
      fixedPrice: relatedBand ? relatedBand.unitPrice : 0,
    };
  }),
});

const combinePricings = (
  percentPricings: Array<Pricing>,
  fixedPricings: Array<Pricing>
): Array<Pricing> => {
  return percentPricings.map((percentPricing) => {
    // Find the related pricing with the same start date and segment.
    const relatedFixedPricing = fixedPricings.find(
      ({ startDate, segment }) =>
        startDate === percentPricing.startDate &&
        (!segment || isEqual(segment, percentPricing.segment))
    );
    return relatedFixedPricing
      ? combinePricing(percentPricing, relatedFixedPricing)
      : percentPricing;
  });
};

const getFixedBands = (
  pricing: Pick<Pricing, 'pricingBands'>
): Array<PricingBand> | undefined =>
  pricing.pricingBands?.map((band) => ({
    lowerLimit: band.lowerLimit,
    unitPrice: band.fixedPrice,
    fixedPrice: 0,
  }));

const getPercentBands = (
  pricing: Pick<Pricing, 'pricingBands'>
): Array<PricingBand> | undefined =>
  pricing.pricingBands?.map((band) => ({
    lowerLimit: band.lowerLimit,
    unitPrice: band.percentagePrice
      ? new Big(band.percentagePrice).div(100).toNumber()
      : 0,
    fixedPrice: 0,
  }));

export function* afterDeletePricing(
  pricing: Pricing
): Generator<StrictEffect, void, any> {
  if (yield call(isFeatureEnabled, Feature.TransactionPricing)) {
    // If the pricing was for the percentage part of transaction pricing the fixed part needs deleting.
    if (pricing.planId) {
      const plan = yield call(retrieveData, DataType.Plan, pricing.planId);
      if (
        plan.transactionPercentAggregationId &&
        plan.transactionFixedAggregationId &&
        pricing.aggregationId === plan.transactionPercentAggregationId
      ) {
        // We can't just use `listAllData` like in `afterRetrievePricing` because
        // the percentage pricing has just been deleted. We need to load all
        // plan pricing but not have it go through the list interceptor.
        const organizationId = yield select(selectCurrentOrgId);
        const response = yield call(list, {
          dataType: DataType.Pricing,
          pathParams: { organizationId },
          queryParams: { planId: pricing.planId },
        });
        // Find the pricing for the fixed aggregation with the same start date as the deleted pricing.
        const fixedPricing = response.data.find(
          ({ aggregationId, startDate }: Pricing) =>
            aggregationId === plan.transactionFixedAggregationId &&
            startDate === pricing.startDate
        );
        if (fixedPricing) {
          yield call(deleteData, DataType.Pricing, fixedPricing.id);
        }
      }
    }
  }
}

export function* afterRetrievePricing(
  pricing: Pricing
): Generator<StrictEffect, Pricing, any> {
  if (yield call(isFeatureEnabled, Feature.TransactionPricing)) {
    if (pricing.planId) {
      // We need the plan to work out if transaction pricing is enabled and
      // which pricings are percent / plan.
      const plan = yield call(retrieveData, DataType.Plan, pricing.planId);
      if (
        plan.transactionPercentAggregationId &&
        plan.transactionFixedAggregationId
      ) {
        // We need to load all the other plan pricing but since this will also
        // go through the `afterListPricings` interceptor it will combine the
        // fixed/percent pricing already and we can just find it by ID.
        const pricings: Array<Pricing> = yield call(
          listAllData,
          DataType.Pricing,
          undefined,
          {
            planId: pricing.planId,
          }
        );
        return pricings.find(({ id }) => id === pricing.id) ?? pricing;
      }
    }
  }
  return pricing;
}

export function* afterListPricings(
  pricings: Array<Pricing>,
  queryParams: QueryParams
): Generator<StrictEffect, Array<Pricing>, any> {
  if (yield call(isFeatureEnabled, Feature.TransactionPricing)) {
    const { planId } = queryParams;

    if (planId) {
      // We need the plan to work out if transaction pricing is enabled and
      // which pricings are percent / plan.
      const plan = yield call(retrieveData, DataType.Plan, planId as string);
      if (
        plan.transactionPercentAggregationId &&
        plan.transactionFixedAggregationId
      ) {
        const percentPricings = pricings.filter(
          ({ aggregationId }) =>
            aggregationId === plan.transactionPercentAggregationId
        );
        const fixedPricings = pricings.filter(
          ({ aggregationId }) =>
            aggregationId === plan.transactionFixedAggregationId
        );
        const otherPricings = pricings.filter(
          ({ aggregationId }) =>
            aggregationId !== plan.transactionPercentAggregationId &&
            aggregationId !== plan.transactionFixedAggregationId
        );

        return [
          ...combinePricings(percentPricings, fixedPricings),
          ...otherPricings,
        ];
      }
    }
  }
  return pricings;
}

export function* beforeCreatePricing(
  pricing: UnsavedEntity<Pricing>
): Generator<StrictEffect, UnsavedEntity<Pricing>, any> {
  if (yield call(isFeatureEnabled, Feature.TransactionPricing)) {
    // We need the plan to get the aggregation IDs and check it has transaction pricing.
    if (pricing.planId) {
      const plan: Plan | undefined = yield call(
        retrieveData,
        DataType.Plan,
        pricing.planId
      );
      if (
        plan &&
        plan.transactionPercentAggregationId &&
        plan.transactionFixedAggregationId
      ) {
        // Check the pricing is for the percentage aggregation.
        if (pricing.aggregationId === plan.transactionPercentAggregationId) {
          const fixedPricing: UnsavedEntity<Pricing> = {
            ...pricing,
            aggregationId: plan.transactionFixedAggregationId,
            pricingBands: getFixedBands(pricing),
          };
          yield call(createData, DataType.Pricing, fixedPricing);

          return {
            ...pricing,
            pricingBands: getPercentBands(pricing),
          };
        }
      }
    }
  }
  return pricing;
}

export function* beforeUpdatePricing(
  pricing: Pricing
): Generator<StrictEffect, Pricing, any> {
  if (yield call(isFeatureEnabled, Feature.TransactionPricing)) {
    if (pricing.relatedPricingId) {
      // Load the related pricing
      const relatedFixedPricing: Pricing | undefined = yield call(
        retrieveData,
        DataType.Pricing,
        pricing.relatedPricingId
      );

      // Update and save the relative pricing.
      if (relatedFixedPricing) {
        const updatedFixedPricing: Pricing = {
          ...relatedFixedPricing,
          ...pick(pricing, fieldsToCopy),
          pricingBands: getFixedBands(pricing),
        };

        yield call(
          updateData,
          DataType.Pricing,
          relatedFixedPricing.id,
          updatedFixedPricing
        );
      }

      const updatedPercentPricing: Pricing = {
        ...omit(pricing, 'relatedPricingId'),
        pricingBands: getPercentBands(pricing),
      };

      return updatedPercentPricing;
    }
  }

  return pricing;
}
