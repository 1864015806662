import React from 'react';

import { Flex, SimpleGrid, VStack } from '@chakra-ui/react';

import { DataType, Contract } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, KeyValue } from '@m3ter-com/ui-components';

import useAccountChildren from '@/hooks/features/accounts/useAccountChildren';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useEndBillingEntities from '@/hooks/features/accounts/useEndBillingEntities';
import { DetailsCard } from '@/components/common/data/DetailsCard';
import { ContractPlans } from '@/components/features/accounts/ContractPlans';
import { ContractCommitments } from '@/components/features/accounts/ContractCommitments';
import { EndBillingEntitiesModal } from '@/components/features/accounts/EndBillingEntitiesModal';

export interface ContractDetailsProps {
  data: Contract;
}

export const ContractDetails: React.FC<ContractDetailsProps> = ({
  data: contract,
}) => {
  const { t } = useTranslation();
  const { toLongDate } = useDateFormatter();

  const { accountChildren } = useAccountChildren(contract.accountId);
  const isContractAccountParent = accountChildren.length > 0;

  const {
    onEndBillingEntities,
    isEndBillingEntitiesModalOpen,
    onOpenEndBillingEntitiesModal,
    onCloseEndBillingEntitiesModal,
  } = useEndBillingEntities(contract.id, DataType.Contract);

  return (
    <DetailsCard
      data={contract}
      dataType={DataType.Contract}
      showCustomFields
      showEditButton
      header={
        <Flex w="100%" mr={2} justifyContent="flex-end">
          <Button size="sm" onClick={onOpenEndBillingEntitiesModal}>
            {t('common:endBillingEntities', {
              entityName: t('common:contract'),
            })}
          </Button>
        </Flex>
      }
      details={
        <SimpleGrid columns={4} gap={6} w="100%">
          <KeyValue label={t('forms:labels.name')} value={contract.name} />
          <KeyValue
            label={t('forms:labels.code')}
            value={contract.code ?? '-'}
          />
          <KeyValue
            label={t('common:start')}
            value={toLongDate(contract.startDate)}
          />
          <KeyValue
            label={t('common:end')}
            value={toLongDate(contract.endDate)}
          />
          <KeyValue
            label={t('forms:labels.purchaseOrderNumber')}
            value={contract.purchaseOrderNumber || '-'}
          />
          <KeyValue
            label={t('forms:labels.description')}
            value={contract.description || '-'}
          />
        </SimpleGrid>
      }
    >
      <VStack w="100%" spacing={4} alignItems="stretch">
        <ContractPlans contract={contract} />
        <ContractCommitments contract={contract} />
      </VStack>
      <EndBillingEntitiesModal
        dataType={DataType.Contract}
        showApplyToChildren={isContractAccountParent}
        isOpen={isEndBillingEntitiesModalOpen}
        onClose={onCloseEndBillingEntitiesModal}
        onSubmit={onEndBillingEntities}
      />
    </DetailsCard>
  );
};
