import {
  call,
  put,
  select,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';

import { isAlpha, isGamma, isLocal } from '@/config';
import {
  isBootstrapSuccessActionWithOrgId,
  selectCurrentOrgId,
} from '@/store/app/bootstrap/bootstrap';

import { getFeatures } from './data';
import { Feature, selectFeaturesEnabled, setFeaturesEnabled } from './flags';

enum ReleaseEnvironments {
  Local = 'local',
  Alpha = 'alpha',
  Gamma = 'gamma',
  Prod = 'prod',
}

// Feature release status is our (console team) way of restricting how
// far features are released. On top of this, features are enabled / disabled
// based on the organization. For a feature to be visible to an organization,
// we have to have released the UI to prod and the feature either has to be
// enabled for all orgs, or specifically for their org.

const releaseStatuses: Record<Feature, ReleaseEnvironments> = {
  [Feature.CompoundAggregations]: ReleaseEnvironments.Prod,
  [Feature.IntegrationDestinations]: ReleaseEnvironments.Prod,
  [Feature.Notifications]: ReleaseEnvironments.Prod,
  [Feature.Reports]: ReleaseEnvironments.Prod,
  [Feature.Statements]: ReleaseEnvironments.Prod,
  [Feature.TransactionPricing]: ReleaseEnvironments.Prod,
  [Feature.Counters]: ReleaseEnvironments.Prod,
};

export function* loadFeaturesSaga(): Generator<StrictEffect, void, any> {
  // This list of features will eventually come from the API and be enabled per organization.
  // In a separate function for now to make testing simpler.
  const orgId = yield select(selectCurrentOrgId);
  const featuresForOrg: Array<Feature> = yield call(getFeatures, orgId);

  const isGammaEnv: boolean = yield call(isGamma);
  const isAlphaEnv: boolean = yield call(isAlpha);
  const isLocalEnv: boolean = yield call(isLocal);

  // Only include features that have been released to this environment.
  const features = featuresForOrg.filter((feature) => {
    const releaseEnvironment = releaseStatuses[feature];
    switch (releaseEnvironment) {
      case ReleaseEnvironments.Prod:
        // Released everywhere.
        return true;
      case ReleaseEnvironments.Gamma:
        return isLocalEnv || isAlphaEnv || isGammaEnv;
      case ReleaseEnvironments.Alpha:
        return isLocalEnv || isAlphaEnv;
      case ReleaseEnvironments.Local:
        return isLocalEnv;
      default:
        return false;
    }
  });

  yield put(setFeaturesEnabled(features));
}

// Helper function for use in other sagas.
export function* isFeatureEnabled(
  feature: Feature
): Generator<StrictEffect, boolean, any> {
  const featuresEnabled = yield select(selectFeaturesEnabled);
  return featuresEnabled.includes(feature);
}

export default function* flagsSaga() {
  // When the org changes / bootstrapping is complete we need to (re)load the enabled features.
  yield takeLatest(isBootstrapSuccessActionWithOrgId, loadFeaturesSaga);
}
