import React from 'react';

import { NamedRoute } from '@/types/routes';

import { AccountBillsRoute } from '@/components/routes/accounts/AccountBillsRoute';

export default (): NamedRoute => ({
  name: 'account.bills',
  path: 'bills',
  element: <AccountBillsRoute />,
});
