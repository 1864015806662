import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const parts = ['headerContainer', 'headerButton'];

const tabbedCard: ComponentMultiStyleConfig = {
  parts,
  baseStyle: {
    headerContainer: {
      w: '100%',
      h: 'max-content',
      borderBottomWidth: 1,
    },
    headerButton: {
      w: 'max-content',
      h: 'max-content',
      padding: 4,
      borderRadius: 0,
    },
  },
};

export default tabbedCard;
