import React, { useMemo, useState } from 'react';

import { StackDivider, VStack } from '@chakra-ui/react';

import { PermissionPolicyStatement } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { RadioTabs } from '@m3ter-com/ui-components';

import { PermissionPolicyEditorProps } from '@/components/forms/PermissionPolicyEditor';

import { PermissionStatementJsonFieldRow } from './PermissionStatementJsonFieldRow';
import { PermissionStatementFieldRow } from './PermissionStatementFieldRow';

interface PermissionStatementFormFieldsProps
  extends Omit<PermissionPolicyEditorProps, 'onChange'> {
  onRowChange: (rowValue: PermissionPolicyStatement, index: number) => void;
  onRowRemove: (index: number) => void;
}

enum EditorType {
  Simple = 'simple',
  Advanced = 'advanced',
}

export const PermissionStatementFormFields: React.FC<
  PermissionStatementFormFieldsProps
> = ({ value, options, onRowChange, onRowRemove }) => {
  const { t } = useTranslation();

  const [editorType, setEditorType] = useState<EditorType>(EditorType.Simple);

  const editorOptions = useMemo(
    () => [
      {
        value: EditorType.Simple,
        label: t('common:simple'),
        content: (
          <VStack spacing={6} alignItems="stretch" divider={<StackDivider />}>
            {value.map((row, index) => (
              <PermissionStatementFieldRow
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                value={row}
                hideLabels={index > 0}
                options={options}
                onChange={(newValue) => {
                  onRowChange(newValue, index);
                }}
                onRemove={() => {
                  onRowRemove(index);
                }}
                canRemove={index > 0}
              />
            ))}
          </VStack>
        ),
      },
      {
        value: EditorType.Advanced,
        label: t('common:advanced'),
        content: (
          <VStack
            w="38em"
            spacing={6}
            alignItems="start"
            divider={<StackDivider />}
          >
            {value.map((row, index) => (
              <PermissionStatementJsonFieldRow
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                value={row}
                onChange={(newValue) => {
                  onRowChange(newValue, index);
                }}
                onRemove={() => {
                  onRowRemove(index);
                }}
                canRemove={index > 0}
              />
            ))}
          </VStack>
        ),
      },
    ],
    [onRowChange, onRowRemove, options, t, value]
  );

  return (
    <RadioTabs
      options={editorOptions}
      value={editorType}
      onChange={setEditorType}
    />
  );
};
