import { useMemo } from 'react';

import { AggregationSegment } from '@m3ter-com/m3ter-api';

const useParsedSegment = (stringifiedSegment: string | undefined) =>
  useMemo<AggregationSegment | undefined>(() => {
    if (!stringifiedSegment) {
      return undefined;
    }

    try {
      const parsedSegment = JSON.parse(stringifiedSegment);
      return parsedSegment;
    } catch {
      return undefined;
    }
  }, [stringifiedSegment]);

export default useParsedSegment;
