import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudRoute } from '@/components/common/crud/CrudRoute';
import { IntegrationConfigSchemaContextProvider } from '@/components/features/integrations/IntegrationConfigSchemaContext';

export const IntegrationCredentialsRoute: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IntegrationConfigSchemaContextProvider>
      <CrudRoute
        dataType={DataType.IntegrationCredential}
        title={t('features:integrations.credentials')}
      />
    </IntegrationConfigSchemaContextProvider>
  );
};
