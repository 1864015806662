import React, { ReactElement } from 'react';

import {
  Heading,
  UnorderedList,
  ListItem,
  Link,
  VStack,
  StackDivider,
  Box,
  Text,
} from '@chakra-ui/react';
import { FaStar } from 'react-icons/fa';

import { DataType, DataTypeToEntity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { ButtonPanel } from '@m3ter-com/ui-components';

import { Accessor, getAccessorValue } from '@/util/data';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import useRecentlyViewed from '@/hooks/navigation/useRecentlyViewed';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';

interface RecentEntitiesListProps<DT extends DataType> {
  accessor: Accessor<DataTypeToEntity[DT], string>;
  dataType: DT;
}

function RecentEntitiesList<DT extends DataType>({
  accessor,
  dataType,
}: RecentEntitiesListProps<DT>): ReactElement<any, any> | null {
  const { t } = useTranslation();
  const { plural: title } = useEntityNamings(dataType);
  const {
    isLoadingRecentlyViewed: isLoadingRecentAccounts,
    loadingRecentlyViewedError: loadingRecentAccountsError,
    recentlyViewed: recentAccounts,
  } = useRecentlyViewed(dataType, true);

  return (
    <Box>
      <Heading as="h5" fontSize="lg" mb={2}>
        {title}
      </Heading>
      <LoadingErrorContentSwitch
        error={loadingRecentAccountsError}
        isLoading={isLoadingRecentAccounts}
      >
        {recentAccounts.length > 0 ? (
          <UnorderedList>
            {recentAccounts.map((entity) => (
              <ListItem key={entity.id}>
                <Link as={CrudDetailsLink} dataType={dataType} id={entity.id}>
                  {getAccessorValue(entity, accessor)}
                </Link>
              </ListItem>
            ))}
          </UnorderedList>
        ) : (
          <Text>{t('common:noRecentX', { x: title })}</Text>
        )}
      </LoadingErrorContentSwitch>
    </Box>
  );
}

export const RecentsButton: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ButtonPanel addLabel icon={<FaStar />} title={t('common:recents')}>
      <VStack
        alignItems="stretch"
        divider={<StackDivider />}
        flexFlow="column nowrap"
        gap={4}
        justifyContent="flex-start"
      >
        <RecentEntitiesList accessor="name" dataType={DataType.Account} />
      </VStack>
    </ButtonPanel>
  );
};
