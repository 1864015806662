import type { FC, LazyExoticComponent } from 'react';

export interface Dashboard {
  widgets: Array<DashboardWidget>;
}

export interface DashboardWidget {
  configData: WidgetConfig;
  position: WidgetPosition;
  type: WidgetType;
}

export type LazyWidgetContentComponent = LazyExoticComponent<FC>;

export type LazyWidgetContentComponentLoader = () => Promise<{
  default: FC;
}>;

export interface RenderableWidgetDefinition extends WidgetDefinitionBase {
  contentComponent: LazyWidgetContentComponent;
}

export interface StoredWidgetDefinition extends WidgetDefinitionBase {
  contentComponentLoader: LazyWidgetContentComponentLoader;
}

export type WidgetConfig = Record<string, any>;

export enum WidgetConfigFieldType {}

export interface WidgetConfigFieldSchema {
  key: string;
  label: string;
  props: Record<string, any>;
  type: WidgetConfigFieldType;
}

export interface WidgetDefinitionBase {
  configFieldSchemas: Array<WidgetConfigFieldSchema>;
  initialConfigData: WidgetConfig;
  type: WidgetType;
}

export interface WidgetPosition {
  x: number;
  y: number;
  h: number;
  w: number;
}

export enum WidgetType {
  LazyLoadFailure = 'LAZY_LOAD_FAILURE',
  AlertsSummary = 'ALERTS_SUMMARY',
  RecentAccounts = 'RECENT_ACCOUNTS',
}
