import {
  all,
  call,
  put,
  select,
  StrictEffect,
  takeEvery,
} from 'redux-saga/effects';

import {
  DataType,
  updateEntityPermissionPolicy,
  UserGroup,
} from '@m3ter-com/m3ter-api';

import { extractError } from '@/util/error';
import { createData } from '@/store/data/data.saga';
import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';

import {
  createUserGroup,
  CreateUserGroupAction,
  createUserGroupFailure,
  createUserGroupSuccess,
} from './userGroupCreate';

export function* createUserGroupSaga(
  action: CreateUserGroupAction
): Generator<StrictEffect, void, any> {
  const { formValues } = action.payload;
  const { onSuccess, onFailure } = action.meta;
  const { permissionPolicyIds, ...userGroupValues } = formValues;

  try {
    const userGroup: UserGroup = yield call(
      createData,
      DataType.UserGroup,
      userGroupValues
    );

    const organizationId = yield select(selectCurrentOrgId);

    if (permissionPolicyIds.length > 0) {
      yield all(
        permissionPolicyIds.map((permissionPolicyId) =>
          call(updateEntityPermissionPolicy, {
            actionName: 'addtousergroup',
            organizationId,
            permissionPolicyId,
            data: { principalId: userGroup.id },
          })
        )
      );
    }

    yield put(createUserGroupSuccess(userGroup, onSuccess));
  } catch (error) {
    yield put(createUserGroupFailure(extractError(error), onFailure));
  }
}

export default function* userGroupCreateSaga() {
  yield takeEvery(createUserGroup.type, createUserGroupSaga);
}
