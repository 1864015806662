import React from 'react';

import { NamedRoute } from '@/types/routes';

import { PricingScheduleRoute } from '@/components/routes/pricing/PricingScheduleRoute';

export default (): NamedRoute => ({
  path: 'schedule',
  name: 'pricing.schedule',
  element: <PricingScheduleRoute />,
});
