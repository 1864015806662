import React, { useMemo } from 'react';

import { Stack, StackDivider, StyleProps } from '@chakra-ui/react';

import { DataType, AuditedEntity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { CopyToClipboard, KeyValue } from '@m3ter-com/ui-components';

import useDateFormatter from '@/hooks/util/useDateFormatter';
import { ReferenceLink } from '@/components/common/data/ReferenceLink';

export interface AuditDataProps {
  data: AuditedEntity;
  variant?: 'horizontal' | 'vertical';
}

interface AuditDataEditorProps {
  typeAndId: string;
}

const AuditDataEditor: React.FC<AuditDataEditorProps> = ({ typeAndId }) => {
  const [type, id] = typeAndId.split('_');

  switch (type) {
    case 'USER':
      return (
        <ReferenceLink
          dataType={DataType.User}
          id={id}
          accessor={({ firstName, lastName }) => `${firstName} ${lastName}`}
        />
      );
    case 'SERVICEUSER':
      return (
        <ReferenceLink
          dataType={DataType.ServiceUser}
          id={id}
          accessor="name"
        />
      );
    case 'm3ter':
    case 'M3ter':
      return <span>m3ter</span>;
    default:
      return null;
  }
};

export const AuditData: React.FC<AuditDataProps> = ({
  data,
  variant = 'vertical',
}) => {
  const { t } = useTranslation();
  const { toLongDateTime } = useDateFormatter();

  const keyValueStyles = useMemo<StyleProps>(() => {
    if (variant === 'vertical') {
      return {};
    }
    return {
      display: 'flex',
      flexFlow: 'row nowrap',
      gap: 1,
    };
  }, [variant]);
  const keySuffix = variant === 'horizontal' ? ':' : '';

  return (
    <Stack
      direction={variant === 'horizontal' ? 'row' : 'column'}
      divider={variant === 'horizontal' ? <StackDivider /> : undefined}
      flexWrap={variant === 'horizontal' ? 'wrap' : 'nowrap'}
      gap={variant === 'horizontal' ? 2 : 0}
    >
      <KeyValue
        fontSize="sm"
        label={`${t('common:id')}${keySuffix}`}
        value={<CopyToClipboard value={data.id}>{data.id}</CopyToClipboard>}
        {...keyValueStyles}
      />
      <KeyValue
        fontSize="sm"
        label={`${t('common:created')}${keySuffix}`}
        value={
          <span>
            {toLongDateTime(data.dtCreated)}
            {data.createdBy && (
              <React.Fragment>
                {' '}
                {t('common:by')} <AuditDataEditor typeAndId={data.createdBy} />
              </React.Fragment>
            )}
          </span>
        }
        {...keyValueStyles}
      />
      <KeyValue
        fontSize="sm"
        label={`${t('common:lastModified')}${keySuffix}`}
        value={
          <span>
            {toLongDateTime(data.dtLastModified)}
            {data.lastModifiedBy && (
              <React.Fragment>
                {' '}
                {t('common:by')}{' '}
                <AuditDataEditor typeAndId={data.lastModifiedBy} />
              </React.Fragment>
            )}
          </span>
        }
        {...keyValueStyles}
      />
    </Stack>
  );
};
