import React, { ComponentProps } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { Select } from '@m3ter-com/ui-components';

export interface FormSelectProps
  extends Omit<ComponentProps<typeof Select>, 'value' | 'onChange'> {
  name: string;
}

export const FormSelect: React.FC<FormSelectProps> = ({ name, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Select value={value} onChange={onChange} {...props} />
      )}
    />
  );
};
