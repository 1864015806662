import React, { useMemo } from 'react';

import { Rounding } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  FormField,
  FormRadioGroup,
  RadioGroupOption,
} from '@m3ter-com/console-core/components';

export const RoundingSelect: React.FC<{}> = () => {
  const { t } = useTranslation();

  const roundingOptions = useMemo<Array<RadioGroupOption>>(
    () =>
      Object.values(Rounding).map((roundingValue) => ({
        value: roundingValue,
        label: t(`common:rounding.${roundingValue}`),
      })),
    [t]
  );

  return (
    <FormField
      isRequired
      name="rounding"
      label={t('forms:labels.rounding')}
      control={FormRadioGroup}
      options={roundingOptions}
      stacked
    />
  );
};
