import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { MetersCreateRoute } from '@/components/routes/meters/MetersCreateRoute';
import { MetersEditRoute } from '@/components/routes/meters/MetersEditRoute';
import { MetersRoute } from '@/components/routes/meters/MetersRoute';

const LazyMetersListRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "MetersList" */ '@/components/routes/meters/MetersListRoute'
  ).then((module) => ({ default: module.MetersListRoute }))
);

const LazyMetersDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "MetersDetails" */ '@/components/routes/meters/MetersDetailsRoute'
  ).then((module) => ({ default: module.MetersDetailsRoute }))
);

export default () =>
  createCrudRoute({
    path: 'meters',
    dataType: DataType.Meter,
    element: <MetersRoute />,
    list: <LazyMetersListRoute />,
    details: <LazyMetersDetailsRoute />,
    create: <MetersCreateRoute />,
    edit: <MetersEditRoute />,
  });
