import React from 'react';

import { NamedRoute } from '@/types/routes';

import { ReportsListRoute } from '@/components/routes/reports/ReportsListRoute';

export default (): NamedRoute => ({
  index: true,
  element: <ReportsListRoute />,
});
