import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Box, Link } from '@chakra-ui/react';

import { CustomFields } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CustomFieldsList } from '@/components/common/data/CustomFieldsList';

interface CustomFieldsDetailsProps {
  customFields: CustomFields;
  initialListItemCount?: number;
}

export const CustomFieldsDetails: React.FC<CustomFieldsDetailsProps> = ({
  customFields,
  initialListItemCount = 5,
}) => {
  const { t } = useTranslation();

  const [listItemCount, setListItemCount] = useState(initialListItemCount);
  const [listHeight, setListHeight] = useState('max-content');

  const contentRef = useRef<HTMLDivElement>(null);

  const customFieldsCount = useMemo(
    () => Object.keys(customFields).length,
    [customFields]
  );

  const limitedCustomFields = useMemo<CustomFields>(() => {
    const limitedFieldEntries = Object.entries(customFields)
      .sort()
      .slice(0, listItemCount);

    return Object.fromEntries(limitedFieldEntries);
  }, [customFields, listItemCount]);

  useEffect(() => {
    if (contentRef.current) {
      const { height } = contentRef.current.getBoundingClientRect();
      setListHeight(`${height}px`);
    }
  }, [limitedCustomFields]);

  const shouldShowMoreLessButton = useMemo(
    () => customFieldsCount > initialListItemCount,
    [customFieldsCount, initialListItemCount]
  );

  const isListPartiallyVisible = useMemo(
    () => listItemCount <= initialListItemCount,
    [initialListItemCount, listItemCount]
  );

  const toggleListVisibility = useCallback(() => {
    setListItemCount((prevCount) =>
      prevCount === customFieldsCount ? initialListItemCount : customFieldsCount
    );
  }, [customFieldsCount, initialListItemCount]);

  return (
    <Box w="100%" h={listHeight} transition="0.1s">
      <Box ref={contentRef} w="fit-content" h="max-content">
        <CustomFieldsList
          customFields={limitedCustomFields}
          truncateValues={shouldShowMoreLessButton && isListPartiallyVisible}
        />
        {shouldShowMoreLessButton && (
          <Link as="button" size="sm" onClick={toggleListVisibility}>
            {isListPartiallyVisible
              ? t('common:showMore')
              : t('common:showLess')}
          </Link>
        )}
      </Box>
    </Box>
  );
};
