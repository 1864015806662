import React from 'react';

import { Heading, HStack, Icon, Link, Text, VStack } from '@chakra-ui/react';

import type { IconType } from 'react-icons/lib';
import { ReportType } from '@/types/data';

import { getReportRouteName } from '@/routes/reports';
import { NamedLink } from '@/components/common/navigation/NamedLink';

export interface ReportCardProps {
  description: string;
  icon: IconType;
  reportType: ReportType;
  title: string;
}

export const ReportCard: React.FC<ReportCardProps> = ({
  description,
  icon,
  reportType,
  title,
}) => (
  <HStack justifyContent="flex-start" alignItems="flex-start" spacing={2}>
    <Icon as={icon} w={8} h={8} color="green-adaptive" />
    <VStack
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={4}
      p={2}
    >
      <Link as={NamedLink} name={getReportRouteName(reportType)}>
        <Heading size="sm" as="h6">
          {title}
        </Heading>
      </Link>
      <Text fontSize="sm">{description}</Text>
    </VStack>
  </HStack>
);
