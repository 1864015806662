import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { CompoundAggregation, Rounding } from '@m3ter-com/m3ter-api';

import { selectSelectedProductIdIfNotGlobal } from '@/store/products/products';
import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { CompoundAggregationForm } from '@/components/features/aggregations/CompoundAggregationForm';

export const CompoundAggregationsCreateRoute: React.FC = () => {
  const productId = useSelector(selectSelectedProductIdIfNotGlobal);
  const initialValues = useMemo(() => {
    const values: Partial<CompoundAggregation> = {
      rounding: Rounding.None,
      quantityPerUnit: 1,
    };

    if (productId) {
      values.productId = productId;
    }

    return values;
  }, [productId]);

  return (
    <CrudCreate<CompoundAggregation>
      form={CompoundAggregationForm}
      initialValues={initialValues}
    />
  );
};
