import { create, each, enforce, omitWhen, test } from 'vest';
import i18next from 'i18next';

import {
  IngestMeasurementDimension,
  IngestRequestBody,
  Meter,
} from '@m3ter-com/m3ter-api';
import { FormValidationContext } from '@m3ter-com/console-core/components';

export interface IngestFormValues {
  requestBody: IngestRequestBody;
}

export interface IngestFormExtraValidationData {
  meter: Meter;
}

const suite = create(
  'ingest',
  (
    data: IngestFormValues,
    context: FormValidationContext<
      IngestFormValues,
      IngestFormExtraValidationData
    >
  ) => {
    const isRequestBodyAnObject =
      typeof data.requestBody === 'object' &&
      data.requestBody.constructor === Object;
    const isMeasurementsPropertyPresent =
      isRequestBodyAnObject &&
      Object.keys(data.requestBody).length === 1 &&
      Object.keys(data.requestBody)[0] === 'measurements';
    const isMeasurementsPropertyAValidArray =
      isMeasurementsPropertyPresent &&
      Array.isArray(data.requestBody.measurements) &&
      data.requestBody.measurements.length > 0;

    const { meter } = context.extraData;

    omitWhen(!isMeasurementsPropertyAValidArray, () => {
      each(
        Array.isArray(data.requestBody?.measurements)
          ? data.requestBody.measurements
          : [],
        (measurement, measurementIndex) => {
          test(
            `requestBody.measurements.${measurementIndex}.uid`,
            i18next.t('forms:validations.ingest.uidRequired'),
            () => {
              enforce(measurement.uid).isNotEmpty();
            }
          );

          test(
            `requestBody.measurements.${measurementIndex}.meter`,
            i18next.t('forms:validations.ingest.matchingMeterRequired'),
            () => {
              enforce(measurement.meter).isNotEmpty();
              enforce(measurement.meter).equals(meter.code);
            }
          );

          test(
            `requestBody.measurements.${measurementIndex}.account`,
            i18next.t('forms:validations.ingest.accountRequired'),
            () => {
              enforce(measurement.account).isNotEmpty();
            }
          );

          test(
            `requestBody.measurements.${measurementIndex}.ts`,
            i18next.t('forms:validations.ingest.timestampRequired'),
            () => {
              enforce(measurement.ts).isNotEmpty();
            }
          );

          each(meter.dataFields, (dataField) => {
            const dataFieldCategoryKey =
              dataField.category.toLowerCase() as IngestMeasurementDimension;
            const dataFieldKey = dataField.code;
            test(
              `requestBody.measurements.${measurementIndex}.${dataFieldCategoryKey}.${dataFieldKey}`,
              i18next.t('forms:validations.ingest.dataFieldRequired', {
                fieldKey: dataFieldKey,
              }),
              () => {
                const fieldValue =
                  measurement[dataFieldCategoryKey]![dataFieldKey];
                return (
                  fieldValue !== undefined &&
                  fieldValue !== null &&
                  !Number.isNaN(fieldValue) &&
                  fieldValue !== ''
                );
              }
            );
          });
        }
      );
    });

    test(
      'requestBody',
      i18next.t('forms:validations.ingest.requestBodyMustBeObject'),
      () => {
        return isRequestBodyAnObject;
      }
    );
    test(
      'requestBody',
      i18next.t('forms:validations.ingest.measurementsPropertyRequired'),
      () => isMeasurementsPropertyPresent
    );
    test(
      'requestBody',
      i18next.t('forms:validations.ingest.measurementsInvalid'),
      () => isMeasurementsPropertyAValidArray
    );
  }
);

export default suite;
