import React, { useCallback, useMemo } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { HStack, Link, VStack } from '@chakra-ui/react';

import {
  DataType,
  ExternalInvoiceDate,
  OrganizationConfig,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert, Button, FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormField,
  FormFieldset,
  FormInput,
  FormNumericSelect,
  FormRadioGroup,
  FormSelect,
  FormSwitch,
} from '@m3ter-com/console-core/components';

import type { TFunction } from 'i18next';

import timeZones, { toLabel } from '@/data/timeZones';
import organizationConfigSchema from '@/validation/organizationConfig';
import { BillStatementConfigFields } from '@/components/forms/BillStatementConfigFields';
import { FormDatePicker } from '@/components/forms/FormDatePicker';
import { MultiRowField } from '@/components/forms/MultiRowField';
import { CreditOrderField } from '@/components/features/pricing/CreditOrderField';
import { FormPicklistSelect } from '@/components/features/accounts/FormPicklistSelect';
import { BillInAdvanceField } from '@/components/features/pricing/BillInAdvanceField';

import { AutoApproveBillsFields } from './AutoApproveBillsFields';
import { BillNumberingFields } from './BillNumberingFields';

const timeZoneOptions = timeZones.map((timeZone) => ({
  value: timeZone,
  label: toLabel(timeZone),
}));

export interface OrganizationConfigFormProps {
  initialValues: OrganizationConfig;
  isSaving: boolean;
  returnUrl: string;
  onSave(config: OrganizationConfig, returnUrl: string): void;
}

export const OrganizationConfigForm: React.FC<OrganizationConfigFormProps> = ({
  initialValues,
  isSaving,
  returnUrl,
  onSave,
}) => {
  const onSubmit = useCallback(
    (formValues: OrganizationConfig) => {
      onSave(formValues, returnUrl);
    },
    [onSave, returnUrl]
  );

  const { t } = useTranslation();
  const entityName = t('features:organizations.configuration');

  const currencyConversionRowFields = useMemo(
    () => [
      {
        name: 'from',
        label: t('forms:labels.from'),
        control: FormPicklistSelect,
        controlProps: {
          dataType: DataType.Currency,
          isRequired: true,
          useCodeAsValue: true,
        },
      },
      {
        name: 'to',
        label: t('forms:labels.to'),
        control: FormPicklistSelect,
        controlProps: {
          dataType: DataType.Currency,
          isRequired: true,
          useCodeAsValue: true,
        },
      },
      {
        name: 'multiplier',
        label: t('features:billing.conversionRate'),
        control: FormInput,
        controlProps: {
          type: 'number',
        },
      },
    ],
    [t]
  );

  const scheduledBillIntervalOptions = useMemo(
    () =>
      [0, 0.25, 0.5, 1, 2, 3, 4, 6, 8, 12, 24].map((value) => ({
        value,
        label: t(
          `features:organizations.scheduledBillIntervalOptions.${value
            .toString()
            .replace('.', '_')}` // Can't use dots in translation keys.
        ),
      })),
    [t]
  );

  const externalInvoiceDateOptions = useMemo(
    () =>
      Object.values(ExternalInvoiceDate).map((value) => ({
        value,
        label: t(`features:organizations.externalInvoiceDateOptions.${value}`),
      })),
    [t]
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={organizationConfigSchema}
    >
      <FormStack mb={4}>
        <FormField
          isRequired
          name="currency"
          label={t('forms:labels.currency')}
          control={FormPicklistSelect}
          useCodeAsValue
          dataType={DataType.Currency}
        />
        <FormField
          isRequired
          name="standingChargeBillInAdvance"
          helpText={t('forms:helpText.standingChargeBilling')}
          label={t('forms:labels.standingChargeBilling')}
          control={BillInAdvanceField}
        />
        <FormField
          isRequired
          name="minimumSpendBillInAdvance"
          helpText={t('forms:helpText.minimumSpendBilling')}
          label={t('forms:labels.minimumSpendBilling')}
          control={BillInAdvanceField}
        />
        <FormField
          isRequired
          name="commitmentFeeBillInAdvance"
          helpText={t('forms:helpText.commitmentFeeBilling')}
          label={t('forms:labels.commitmentFeeBilling')}
          control={BillInAdvanceField}
        />
        <VStack
          as={FormFieldset}
          alignSelf="stretch"
          legend={t('features:billing.billAutoApproval')}
          gap={4}
        >
          <FormField
            name="autoApproveBillsGracePeriod"
            label={t('forms:labels.autoApproveBills')}
            helpText={t('forms:helpText.autoApproveBills')}
            control={AutoApproveBillsFields}
            alert={
              <Alert status="warning" mt={2}>
                <Trans
                  t={t as TFunction}
                  i18nKey="features:organizations.billAutoApprovalWarning"
                  components={{
                    billAutoApprovalLink: (
                      <Link
                        href="https://www.m3ter.com/docs/guides/billing-and-usage-data/running-viewing-and-managing-bills/approving-and-locking-bills-managing-bill-lifecycle#auto-approving-bills"
                        isExternal
                      />
                    ),
                  }}
                />
              </Alert>
            }
          />
        </VStack>
        <FormField
          name="scheduledBillInterval"
          label={t('forms:labels.scheduledBillInterval')}
          control={FormNumericSelect}
          options={scheduledBillIntervalOptions}
        />
        <FormField
          isRequired
          name="timezone"
          label={t('forms:labels.timezone')}
          control={FormSelect}
          isSearchable
          searchMatchFrom="any"
          options={timeZoneOptions}
        />
        <FormField
          isRequired
          name="yearEpoch"
          label={t('features:organizations.yearEpoch')}
          control={FormDatePicker}
          stripTime
        />
        <FormField
          isRequired
          name="monthEpoch"
          label={t('features:organizations.monthEpoch')}
          control={FormDatePicker}
          stripTime
        />
        <FormField
          isRequired
          name="weekEpoch"
          label={t('features:organizations.weekEpoch')}
          control={FormDatePicker}
          stripTime
        />
        <FormField
          isRequired
          name="dayEpoch"
          label={t('features:organizations.dayEpoch')}
          control={FormDatePicker}
          stripTime
        />
        <FormField
          isRequired
          type="number"
          name="daysBeforeBillDue"
          label={t('forms:labels.daysBeforeBillDue')}
          control={FormInput}
        />
        <FormField
          name="externalInvoiceDate"
          label={t('features:organizations.externalInvoiceDate')}
          control={FormRadioGroup}
          options={externalInvoiceDateOptions}
          stacked
        />
        <VStack
          as={FormFieldset}
          alignSelf="stretch"
          legend={t('forms:labels.billStatements')}
          gap={4}
        >
          <BillStatementConfigFields
            definitionIdFieldName="defaultStatementDefinitionId"
            generateModeHelpText={t('forms:helpText.autoGenerateStatements')}
          />
        </VStack>
        <FormField
          name="billNumbering"
          label={t('features:billing.billNumbering')}
          control={BillNumberingFields}
          prefixFieldName="billPrefix"
          sequenceStartFieldName="sequenceStartNumber"
          helpText={t('forms:helpText.billNumbering')}
        />
        <FormField
          name="suppressedEmptyBills"
          label={t('features:organizations.suppressEmptyBills')}
          helpText={t('forms:helpText.suppressEmptyBills')}
          control={FormSwitch}
        />
        <FormField
          name="consolidateBills"
          label={t('features:organizations.consolidateBills')}
          helpText={t('forms:helpText.consolidateBills')}
          control={FormSwitch}
          alert={
            <Alert status="warning">
              {t('features:organizations.consolidateBillsWarning')}
            </Alert>
          }
        />
        <FormField
          name="creditApplicationOrder"
          label={t('forms:labels.creditApplicationOrder')}
          control={CreditOrderField}
        />
        <MultiRowField
          allowRemoveFirstRow
          name="currencyConversions"
          label={t('features:organizations.currencyConversions')}
          rowFields={currencyConversionRowFields}
          emptyContentMessage={t(
            'features:organizations.noCurrencyConversions'
          )}
        />
      </FormStack>
      <HStack>
        <Button intent="primary" type="submit" isLoading={isSaving}>
          {t('forms:buttons.updateEntity', { entityName })}
        </Button>
        <Button variant="ghost" as={RouterLink} to={returnUrl}>
          {t('common:cancel')}
        </Button>
      </HStack>
    </Form>
  );
};
