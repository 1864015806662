import { useCallback, useState } from 'react';

import { PreferenceName } from '@/types/preferences';

import { getPreference, setPreference } from '@/util/localStorage';

type SetPreferenceAction<T> = T | ((prevState: T) => T);

type UsePreferenceReturn<T> = [T, (value: SetPreferenceAction<T>) => void];

const usePreference = <T = any>(
  name: PreferenceName | string,
  defaultValue: T
): UsePreferenceReturn<T> => {
  const [value, setValue] = useState<T>(
    (getPreference(name) as T) ?? defaultValue
  );

  const setPreferenceValue = useCallback(
    (newValue: SetPreferenceAction<T>) => {
      const valueToStore =
        newValue instanceof Function ? newValue(value) : newValue;
      setValue(valueToStore);
      setPreference(name, valueToStore);
    },
    [name, value]
  );

  return [value, setPreferenceValue];
};

export default usePreference;
