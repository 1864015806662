import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { PermissionsRoute } from '@/components/routes/settings/permissions/PermissionsRoute';
import { PermissionsListRoute } from '@/components/routes/settings/permissions/PermissionsListRoute';
import { PermissionsDetailsRoute } from '@/components/routes/settings/permissions/PermissionsDetailsRoute';
import { PermissionsCreateRoute } from '@/components/routes/settings/permissions/PermissionsCreateRoute';
import { PermissionsEditRoute } from '@/components/routes/settings/permissions/PermissionsEditRoute';

export default () =>
  createCrudRoute({
    path: 'permission-policies',
    dataType: DataType.PermissionPolicy,
    element: <PermissionsRoute />,
    list: <PermissionsListRoute />,
    details: <PermissionsDetailsRoute />,
    create: <PermissionsCreateRoute />,
    edit: <PermissionsEditRoute />,
  });
