import {
  createAction,
  createSlice,
  PayloadAction,
  PrepareAction,
} from '@reduxjs/toolkit';

import { Task } from '@/types/tasks';

export type TasksState = Array<Task>;

export type AddTaskAction = PayloadAction<Task>;
export type AddTasksAction = PayloadAction<Array<Task>>;
export type UpsertTasksAction = PayloadAction<Array<Task>>;
export type UpdateTaskAction = PayloadAction<Task>;
export type CancelTaskAction = PayloadAction<Task>;

interface RemoveTaskPayload {
  id: string;
}
export type RemoveTaskAction = PayloadAction<RemoveTaskPayload>;

const name = 'tasks';

const initialState: TasksState = [];

const taskPrepare = (task: Task) => ({
  payload: task,
});
const tasksPrepare = (tasks: Array<Task>) => ({
  payload: tasks,
});

const updateTaskProperties = (task: Task, newTask: Task) => {
  task.title = newTask.title;
  task.details = newTask.details;
  task.progress = newTask.progress;
};

const tasksSlice = createSlice({
  name,
  initialState,
  reducers: {
    addTask: {
      reducer: (state: TasksState, action: AddTaskAction) => {
        return [action.payload, ...state];
      },
      prepare: taskPrepare,
    },
    addTasks: {
      reducer: (state: TasksState, action: AddTasksAction) => {
        return [...action.payload, ...state];
      },
      prepare: (tasks: Array<Task>) => ({
        payload: tasks,
      }),
    },
    upsertTasks: {
      reducer: (state: TasksState, action: UpsertTasksAction) => {
        action.payload.forEach((newTask) => {
          const task = state.find((t) => t.id === newTask.id);
          if (task) {
            updateTaskProperties(task, newTask);
          } else {
            state.unshift(newTask);
          }
        });
      },
      prepare: tasksPrepare,
    },
    updateTask: {
      reducer: (state: TasksState, action: UpdateTaskAction) => {
        const task = state.find((t) => t.id === action.payload.id);
        if (task) {
          updateTaskProperties(task, action.payload);
        }
      },
      prepare: taskPrepare,
    },
    removeTask: {
      reducer: (state: TasksState, action: RemoveTaskAction) => {
        return state.filter(({ id }) => id !== action.payload.id);
      },
      prepare: (id: string) => ({
        payload: { id },
      }),
    },
    reset: () => initialState,
  },
});

// Export actions.
export const { addTask, addTasks, upsertTasks, updateTask, removeTask, reset } =
  tasksSlice.actions;

export const cancelTask = createAction<PrepareAction<Task>>(
  `${name}/cancelTask`,
  (task: Task) => ({
    payload: task,
  })
);

// Selectors.
export const selectTasks = (state: { tasks: TasksState }) => state.tasks;

export default tasksSlice.reducer;
