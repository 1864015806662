import React, { PropsWithChildren, ReactElement } from 'react';

import {
  HStack,
  Radio,
  RadioGroup,
  VStack,
  StackProps,
} from '@chakra-ui/react';

export interface RadioTabOption<ValueKey extends string = string> {
  value: ValueKey;
  label: string;
  content: React.ReactNode;
}

export interface RadioTabsProps<ValueKey extends string = string>
  extends Omit<StackProps, 'onChange'> {
  options: Array<RadioTabOption<ValueKey>>;
  value: ValueKey;
  isDisabled?: boolean;
  onChange: (value: ValueKey) => void;
}

export function RadioTabs<ValueKey extends string = string>({
  options,
  value,
  isDisabled,
  onChange,
  ...stackProps
}: PropsWithChildren<RadioTabsProps<ValueKey>>): ReactElement<any, any> {
  const selectedOption: RadioTabOption | undefined = options.find(
    (option) => option.value === value
  );

  return (
    <VStack spacing={4} alignItems="flex-start" {...stackProps}>
      <RadioGroup value={value} onChange={onChange} isDisabled={isDisabled}>
        <HStack>
          {options.map((option) => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </HStack>
      </RadioGroup>
      {selectedOption && selectedOption.content}
    </VStack>
  );
}
