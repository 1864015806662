import React, { useMemo } from 'react';

import { ButtonGroup } from '@chakra-ui/react';

import { DataType, OrganizationAdmin, UserAdmin } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button } from '@m3ter-com/ui-components';

import useOrgUserAdmin from '@/hooks/features/admin/useOrgUserAdmin';
import { OrganizationAdminPermissionsModal } from '@/components/features/admin/organizations/OrganizationAdminPermissionsModal';
import { OrganizationDetails } from '@/components/features/organization/OrganizationDetails';
import { CrudEditLink } from '@/components/common/navigation/CrudEditLink';
import {
  ColumnDefinition,
  EntityMultiSelectModal,
} from '@/components/common/data/EntitySelectModal';

interface OrganizationAdminDetailsProps {
  data: OrganizationAdmin;
}

const addUsersListSearchFields = ['firstName', 'lastName'];

const emptyArray = new Array<any>();

export const OrganizationAdminDetails: React.FC<
  OrganizationAdminDetailsProps
> = ({ data: org }) => {
  const { t } = useTranslation();
  const {
    isOrgUserModalOpen,
    isUserPermissionsModalOpen,
    onAddPermissionToUser,
    onAddUserToOrg,
    onCloseOrgUserModal,
    onCloseUserPermissionsModal,
    onOpenOrgUserModal,
    onOpenUserPermissionsModal,
  } = useOrgUserAdmin(org);

  const addUsersModalColumns = useMemo<Array<ColumnDefinition<UserAdmin>>>(
    () => [
      {
        id: 'name',
        accessor: (item: UserAdmin) => `${item.firstName} ${item.lastName}`,
        header: t('forms:labels.name'),
      },
      {
        id: 'firstName',
        accessor: 'firstName',
        header: t('forms:labels.lastName'),
        defaultHidden: true,
      },
      {
        id: 'lastName',
        accessor: 'lastName',
        header: t('forms:labels.lastName'),
        defaultHidden: true,
      },
    ],
    [t]
  );

  return (
    <React.Fragment>
      <OrganizationDetails
        showCustomer
        org={org}
        adminActions={
          <ButtonGroup>
            <Button size="sm" onClick={onOpenOrgUserModal}>
              {t('features:admin.addUser')}
            </Button>
            <Button size="sm" onClick={onOpenUserPermissionsModal}>
              {t('features:admin.addUserPermissionPolicy')}
            </Button>
            <Button
              intent="primary"
              size="sm"
              as={CrudEditLink}
              addReturnPath
              dataType={DataType.OrganizationAdmin}
              id={org.id}
            >
              {t('common:edit')}
            </Button>
          </ButtonGroup>
        }
      />
      <OrganizationAdminPermissionsModal
        isOpen={isUserPermissionsModalOpen}
        onClose={onCloseUserPermissionsModal}
        onSubmit={onAddPermissionToUser}
      />
      <EntityMultiSelectModal<UserAdmin>
        columns={addUsersModalColumns}
        dataType={DataType.UserAdmin}
        isOpen={isOrgUserModalOpen}
        onClose={onCloseOrgUserModal}
        onConfirm={onAddUserToOrg}
        searchFields={addUsersListSearchFields}
        selected={emptyArray}
      />
    </React.Fragment>
  );
};
