import React from 'react';

import { Link, Text } from '@chakra-ui/react';

import { DataType, PlanTemplate } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert } from '@m3ter-com/ui-components';

import { CrudEditLink } from '@/components/common/navigation/CrudEditLink';

export interface PlanTemplateLinkProps {
  planTemplate?: PlanTemplate;
}

export const PlanTemplateLink: React.FC<PlanTemplateLinkProps> = ({
  planTemplate,
}) => {
  const { t } = useTranslation();

  return planTemplate ? (
    <Alert status="info">
      <Text>
        {t('features:pricing.planTemplateBeingUsed')}:{' '}
        <Link
          as={CrudEditLink}
          addReturnPath
          dataType={DataType.PlanTemplate}
          id={planTemplate.id}
        >
          {planTemplate.name}
        </Link>
      </Text>
    </Alert>
  ) : null;
};
