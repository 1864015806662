import React, { useMemo } from 'react';

import { useLocation } from 'react-router-dom';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  MenuGroup,
} from '@chakra-ui/react';
import { FaEllipsisH } from 'react-icons/fa';

import {
  DataType,
  ExternalMappingEntityType,
  Id,
  QueryParams,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { IconButton } from '@m3ter-com/ui-components';

import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink';
import { NamedLink } from '@/components/common/navigation/NamedLink';
import useFeatureFlags, { Feature } from '@/hooks/util/useFeatureFlags';

interface AccountOverviewActionsProps {
  accountId: Id;
  onOpenIngestModal: () => void;
  onOpenEndBillingEntitiesModal: () => void;
}

export const AccountOverviewActions: React.FC<AccountOverviewActionsProps> = ({
  accountId,
  onOpenIngestModal,
  onOpenEndBillingEntitiesModal,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { isFeatureEnabled } = useFeatureFlags();

  const createRouteQueryParams = useMemo<QueryParams>(
    () => ({
      returnPath: location.pathname,
      m3terEntity: ExternalMappingEntityType.Account,
      m3terId: accountId,
    }),
    [accountId, location]
  );

  return (
    <Menu>
      <MenuButton
        alignSelf="flex-end"
        aria-label={t('features:account.actions')}
        as={IconButton}
        icon={<FaEllipsisH />}
      />
      <MenuList>
        <MenuGroup title={t('common:account')}>
          <MenuItem onClick={onOpenIngestModal}>
            {t('features:ingest.submitUsage')}
          </MenuItem>
          <MenuItem onClick={onOpenEndBillingEntitiesModal}>
            {t('common:endBillingEntities', {
              entityName: t('common:account'),
            })}
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title={t('common:commitments')}>
          <MenuItem as={CrudCreateLink} dataType={DataType.Commitment}>
            {t('forms:buttons.createEntity', {
              entityName: t('common:commitment'),
            })}
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title={t('common:balances')}>
          <MenuItem as={CrudCreateLink} dataType={DataType.Balance}>
            {t('forms:buttons.createEntity', {
              entityName: t('common:balance'),
            })}
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title={t('common:plans')}>
          <MenuItem
            addReturnPath
            as={CrudCreateLink}
            dataType={DataType.AccountPlan}
          >
            {t('forms:buttons.createEntity', {
              entityName: t('common:planAttachment'),
            })}
          </MenuItem>
          <MenuItem as={NamedLink} name="create-custom-plan">
            {t('forms:buttons.createEntity', {
              entityName: t('common:customPlan'),
            })}
          </MenuItem>
          <MenuItem as={NamedLink} name="create-custom-plan-group">
            {t('forms:buttons.createEntity', {
              entityName: t('common:customPlanGroup'),
            })}
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title={t('common:contracts')}>
          <MenuItem as={CrudCreateLink} dataType={DataType.Contract}>
            {t('forms:buttons.createEntity', {
              entityName: t('common:contract'),
            })}
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title={t('common:externalMappings')}>
          <MenuItem
            as={CrudCreateLink}
            dataType={DataType.ExternalMapping}
            queryParams={createRouteQueryParams}
          >
            {t('forms:buttons.createEntity', {
              entityName: t('common:externalMappings'),
            })}
          </MenuItem>
        </MenuGroup>
        {isFeatureEnabled(Feature.Counters) && (
          <React.Fragment>
            <MenuDivider />
            <MenuGroup title={t('common:counterAdjustments')}>
              <MenuItem
                as={CrudCreateLink}
                dataType={DataType.CounterAdjustment}
              >
                {t('forms:buttons.createEntity', {
                  entityName: t('common:counterAdjustment'),
                })}
              </MenuItem>
            </MenuGroup>
          </React.Fragment>
        )}
      </MenuList>
    </Menu>
  );
};
