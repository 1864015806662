import React, { useMemo } from 'react';

import { DataType, Bill, CreditLineItem } from '@m3ter-com/m3ter-api';

import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { useCrudRetrieveData } from '@/components/common/crud/CrudRetrieveContext';
import { CreditLineItemForm } from '@/components/features/billing/CreditLineItemForm/CreditLineItemForm';

const initialValues: Partial<CreditLineItem> = {};

export const CreditLineItemsCreateRoute: React.FC = () => {
  // These routes have to be under the bill retrieve route
  // so we can apply the credit to that bill.
  const bill = useCrudRetrieveData<Bill>(DataType.Bill);

  const pathParams = useMemo(
    () => ({
      billId: bill.id,
    }),
    [bill]
  );

  return (
    <CrudCreate<CreditLineItem>
      form={CreditLineItemForm}
      initialValues={initialValues}
      pathParams={pathParams}
    />
  );
};
