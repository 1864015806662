import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';

import { StatementAutoGenerateMode } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

export interface StatementGenerateModeFieldProps {
  name: string;
  defaultLabel?: string;
  includeOrgConfig?: boolean;
}

export const StatementGenerateModeField: React.FC<
  StatementGenerateModeFieldProps
> = ({ name, includeOrgConfig = false, defaultLabel }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => {
        return (
          <RadioGroup
            value={value ?? ''}
            onChange={(newValue) => {
              onChange(newValue !== '' ? newValue : null);
            }}
          >
            <Stack alignItems="start">
              {includeOrgConfig && !!defaultLabel && (
                <Radio value="">{defaultLabel}</Radio>
              )}
              {Object.values(StatementAutoGenerateMode).map((mode) => (
                <Radio key={mode} value={mode}>
                  {t(`features:statements.autoGenerateMode.${mode}`)}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        );
      }}
    />
  );
};
