import React, {
  BaseSyntheticEvent,
  PropsWithChildren,
  useCallback,
  useMemo,
} from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { vestResolver, VestSchema } from './vestResolver';

export interface FormProps {
  onSubmit: (data: any) => void;
  initialValues: Record<string, any>;
  validationSchema?: VestSchema;
  extraValidationData?: Record<string, any>;
}

export const Form: React.FC<PropsWithChildren<FormProps>> = ({
  onSubmit,
  initialValues,
  validationSchema,
  extraValidationData,
  children,
}) => {
  const formContext = useMemo(
    () => ({
      initialValues,
      extraData: extraValidationData,
    }),
    [initialValues, extraValidationData]
  );

  // Remove the event so onSubmit is only called with the data.
  const onSubmitWrapper = useCallback(
    (data: any, _event?: BaseSyntheticEvent) => onSubmit(data),
    [onSubmit]
  );

  const form = useForm({
    defaultValues: initialValues,
    resolver: validationSchema ? vestResolver(validationSchema) : undefined,
    context: formContext,
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmitWrapper)} noValidate>
        {children}
      </form>
    </FormProvider>
  );
};
