import React, { Suspense } from 'react';

import { Outlet } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';

export const PricingRootRoute: React.FC = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Outlet />
    </Suspense>
  );
};
