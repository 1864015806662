import React, { useMemo } from 'react';

import { ExternalMappingConfig } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormField, FormSelect } from '@m3ter-com/console-core/components';
import { SelectOption } from '@m3ter-com/ui-components';

export interface ExternalMappingExternalSystemFieldProps {
  externalMappingConfig: ExternalMappingConfig;
  name: string;
}

export const ExternalMappingExternalSystemField: React.FC<
  ExternalMappingExternalSystemFieldProps
> = ({ externalMappingConfig, name }) => {
  const { t } = useTranslation();

  const externalSystemOptions = useMemo<Array<SelectOption>>(
    () =>
      externalMappingConfig.externalSystems.map((system) => ({
        value: system.name,
        label: system.name,
      })),
    [externalMappingConfig]
  );

  return (
    <FormField
      isRequired
      name={name}
      label={t('forms:labels.externalSystem')}
      control={FormSelect}
      options={externalSystemOptions}
    />
  );
};
