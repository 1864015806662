import React from 'react';

import { Navigate } from 'react-router-dom';

import { NamedRoute } from '@/types/routes';

import { OrganizationSettingsRoute } from '@/components/routes/settings/organization/OrganizationSettingsRoute';

import setupConfigurationRoute from './configuration';
import setupCreditReasonsRoute from './credit-reasons';
import setupCurrenciesRoute from './currencies';
import setupCustomFieldsRoute from './custom-fields';
import setupDebitReasonsRoute from './debit-reasons';
import setupTransactionTypesRoute from './transaction-types';

export default (): NamedRoute => ({
  path: 'organization',
  name: 'settings.organization',
  element: <OrganizationSettingsRoute />,
  children: [
    { index: true, element: <Navigate replace to="configuration" /> },
    setupConfigurationRoute(),
    setupCreditReasonsRoute(),
    setupCurrenciesRoute(),
    setupCustomFieldsRoute(),
    setupDebitReasonsRoute(),
    setupTransactionTypesRoute(),
  ],
});
