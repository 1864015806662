import React, { ReactNode } from 'react';

import { Box, BoxProps, useMultiStyleConfig } from '@chakra-ui/react';

export interface KeyValueProps extends BoxProps {
  label: ReactNode;
  value: ReactNode;
}

export const KeyValue: React.FC<KeyValueProps> = ({
  label,
  value,
  ...boxProps
}) => {
  const styles = useMultiStyleConfig('KeyValue', {});

  return (
    <Box {...boxProps}>
      <Box sx={styles.label}>{label}</Box>
      <Box sx={styles.value}>{value}</Box>
    </Box>
  );
};
