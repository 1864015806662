import React, { useEffect } from 'react';

import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary } from '@sentry/react';
import { Box, useColorMode } from '@chakra-ui/react';

import { LoadingScreen } from '@m3ter-com/ui-components';

import { setColorMode } from '@/store/page/page';
import {
  bootstrapApp,
  selectMinorBootstrapErrors,
} from '@/store/app/bootstrap/bootstrap';
import useAuth from '@/hooks/auth/useAuth';
import { GenericErrorMessage } from '@/components/common/errors/GenericErrorMessage';
import { ErrorAlerts } from '@/components/common/errors/ErrorAlerts';
import { Notifications } from '@/components/common/notifications/Notifications';

export const RootRoute: React.FC = () => {
  const dispatch = useDispatch();

  const { isSigningOut } = useAuth();
  const minorBootstrapErrors = useSelector(selectMinorBootstrapErrors);

  useEffect(() => {
    dispatch(bootstrapApp());
  }, [dispatch]);

  // Dispatch an action when Chakra's color mode is set / changed
  // so we can track it in Redux state.
  const { colorMode } = useColorMode();
  useEffect(() => {
    dispatch(setColorMode(colorMode));
  }, [dispatch, colorMode]);

  return (
    <ErrorBoundary
      fallback={({ error, componentStack }) => {
        return (
          <Box p={8} h="100vh" overflowY="scroll">
            <GenericErrorMessage
              error={error}
              componentStack={componentStack}
            />
          </Box>
        );
      }}
    >
      {isSigningOut ? (
        <LoadingScreen />
      ) : (
        <React.Fragment>
          <ErrorAlerts errors={minorBootstrapErrors} />
          <Notifications />
          <Outlet />
        </React.Fragment>
      )}
    </ErrorBoundary>
  );
};
