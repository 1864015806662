import React from 'react';

import { Text, VStack } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button } from '@m3ter-com/ui-components';

import { NamedLink } from '@/components/common/navigation/NamedLink';

import { useCrudListContext } from './CrudListContext';

export const CrudListEmptyTableContent: React.FC = () => {
  const { t } = useTranslation();

  const {
    createRouteLabel,
    createRouteName,
    createRouteParams,
    entityNamings,
  } = useCrudListContext();

  return (
    <VStack spacing={4}>
      <Text>
        {t('errors:generic.noDataToDisplay', {
          entityName: entityNamings.plural,
        })}
      </Text>
      {createRouteName && (
        <Button
          as={NamedLink}
          name={createRouteName}
          queryParams={createRouteParams}
        >
          {createRouteLabel}
        </Button>
      )}
    </VStack>
  );
};
