import React, { useMemo } from 'react';

import { UserGroup } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds } from '@/types/lists';

import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';

const searchFields = ['name'];

export const UserGroupsListRoute: React.FC = () => {
  const { t } = useTranslation();

  const columns = useMemo<Array<ColumnDefinition<UserGroup>>>(
    () => [{ id: 'name', header: t('forms:labels.name'), accessor: 'name' }],
    [t]
  );

  return (
    <CrudList<UserGroup>
      listId={EntityRouteListIds.UserGroup}
      columns={columns}
      searchFields={searchFields}
    />
  );
};
