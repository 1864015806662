import React, { ComponentProps } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import {
  DateRangePicker,
  DateStringRange,
  SelectOption,
} from '@m3ter-com/ui-components';

import useDateFormatter from '@/hooks/util/useDateFormatter';

interface CrudListDateRangeFilterProps
  extends Omit<ComponentProps<typeof DateRangePicker>, 'value' | 'onChange'> {
  name: string;
  comparatorOptions: Array<SelectOption>;
}

export const CrudListDateRangeFilter: React.FC<
  CrudListDateRangeFilterProps
> = ({ name, comparatorOptions, ...props }) => {
  const { control } = useFormContext();
  const { timeZone } = useDateFormatter();
  const [{ value: startComparator }, { value: endComparator }] =
    comparatorOptions;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value = [], onChange } }) => {
        const onDateRangeChange = (range: DateStringRange | null) => {
          onChange(
            range
              ? [
                  { comparator: startComparator, value: range.start },
                  { comparator: endComparator, value: range.end },
                ]
              : null
          );
        };

        let dateRangeValue = null;
        if (value[0] && value[1]) {
          dateRangeValue = { start: value[0].value, end: value[1].value };
        }

        return (
          <DateRangePicker
            onChange={onDateRangeChange}
            value={dateRangeValue}
            timeZone={timeZone}
            isClearable
            {...props}
          />
        );
      }}
    />
  );
};
