import React from 'react';

import { OrganizationAdmin } from '@m3ter-com/m3ter-api';

import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { OrganizationAdminForm } from '@/components/features/admin/organizations/OrganizationAdminForm';

export const OrganizationAdminCreateRoute: React.FC = () => (
  <CrudCreate<OrganizationAdmin> form={OrganizationAdminForm} />
);
