import React from 'react';

import { HStack, VStack } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormField } from '@m3ter-com/console-core/components';

import { FormDatePicker } from '@/components/forms/FormDatePicker';

export const AccountPlanGroupDateFields: React.FC = () => {
  const { t } = useTranslation();

  return (
    <VStack w="100%" spacing={4} alignItems="stretch">
      <HStack spacing={4}>
        <FormField
          isRequired
          name="startDate"
          label={t('forms:labels.startDateInclusive')}
          control={FormDatePicker}
        />
        <FormField
          name="endDate"
          label={t('forms:labels.endDateExclusive')}
          control={FormDatePicker}
        />
      </HStack>
    </VStack>
  );
};
