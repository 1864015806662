import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { PlansRoute } from '@/components/routes/plans/PlansRoute';
import { PlansCreateRoute } from '@/components/routes/plans/PlansCreateRoute';
import { PlansEditRoute } from '@/components/routes/plans/PlansEditRoute';
import { PlansListRoute } from '@/components/routes/plans/PlansListRoute';
import { PlansDetailsRoute } from '@/components/routes/plans/PlansDetailsRoute';

export default () =>
  createCrudRoute({
    path: 'plans',
    dataType: DataType.Plan,
    element: <PlansRoute />,
    list: <PlansListRoute />,
    details: <PlansDetailsRoute />,
    create: <PlansCreateRoute />,
    edit: <PlansEditRoute />,
  });
