import { BlogPost, DocsPage } from '@/types/docs';

export const createDocsLink = (page: DocsPage): string => {
  const parts = [];

  // Traverse up the tree, adding the slug to the start of the parts.
  let current: DocsPage | undefined = page;
  do {
    parts.unshift(current.slug);
    current = current.parent;
  } while (current);

  return `https://www.m3ter.com/docs/guides/${parts.join('/')}`;
};

export const createBlogPostLink = (post: BlogPost): string =>
  `https://www.m3ter.com/blog/${post.slug}`;
