import React, { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import snakeCase from 'lodash/snakeCase';

import { AccountPlan, ChildBillingMode } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudQueryParams } from '@/types/routes';

import {
  selectError,
  selectIsSaving,
  createCustomPlanGroup,
} from '@/store/features/accounts/customPlanGroupForm';
import { useOrgDates } from '@/hooks/util/useOrgDates';
import useQueryString from '@/hooks/navigation/useQueryString';
import {
  AccountCustomPlanGroupForm,
  AccountCustomPlanGroupFormValues,
} from '@/components/features/accounts/AccountCustomPlanGroupForm';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';
import { useCrudRetrieveContext } from '@/components/common/crud/CrudRetrieveContext';
import { useCrudContext } from '@/components/common/crud/CrudContext';

export const CreateCustomPlanGroupRoute: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isSaving = useSelector(selectIsSaving);
  const error = useSelector(selectError);

  const { account } = useCrudRetrieveContext();

  const { getStartOfToday } = useOrgDates();

  const initialValues = useMemo(() => {
    const name = `${account?.name} ${t('common:planGroup')}`;

    return {
      accountId: account?.id,
      startDate: getStartOfToday().toISOString(),
      name,
      code: snakeCase(name),
      childBillingMode: ChildBillingMode.ParentBreakdown,
    };
  }, [account, getStartOfToday, t]);

  const { basePath, onCancel } = useCrudContext<AccountPlan>();
  const { returnPath = `${basePath}/:id` } = useQueryString<CrudQueryParams>();

  const onSave = useCallback(
    (data: AccountCustomPlanGroupFormValues) => {
      dispatch(createCustomPlanGroup(data, returnPath));
    },
    [dispatch, returnPath]
  );

  return (
    <React.Fragment>
      {error && (
        <ErrorAlert
          title={t('errors:generic.problemSavingData', {
            entityName: t('common:planGroup'),
          })}
          error={error}
          mb={4}
        />
      )}
      <BreadcrumbItem title={t('common:create')} />
      <AccountCustomPlanGroupForm
        isSaving={isSaving}
        initialValues={initialValues}
        onSave={onSave}
        onCancel={onCancel}
      />
    </React.Fragment>
  );
};
