import { DataType } from '@m3ter-com/m3ter-api';

import { OtherListIds } from '@/types/lists';

export interface PermissionActionConfig {
  addAction: string;
  removeAction: string;
  listAction: string;
  listId: OtherListIds;
}

export type PermissionDataType =
  | DataType.SupportAccess
  | DataType.ServiceUser
  | DataType.User
  | DataType.UserGroup;

export const permissionDataTypeConfigMap: Record<
  PermissionDataType,
  PermissionActionConfig
> = {
  [DataType.User]: {
    addAction: 'addtouser',
    removeAction: 'removefromuser',
    listAction: 'listUserPermissions',
    listId: OtherListIds.UsersPermissionPolicies,
  },
  [DataType.ServiceUser]: {
    addAction: 'addtoserviceuser',
    removeAction: 'removefromserviceuser',
    listAction: 'listServiceUserPermissions',
    listId: OtherListIds.ServiceUsersPermissionPolicies,
  },
  [DataType.UserGroup]: {
    addAction: 'addtousergroup',
    removeAction: 'removefromusergroup',
    listAction: 'listUserGroupPermissions',
    listId: OtherListIds.UserGroupsPermissionPolicies,
  },
  [DataType.SupportAccess]: {
    addAction: 'addtosupportusers',
    removeAction: 'removefromsupportusers',
    listAction: 'listSupportAccessPermissions',
    listId: OtherListIds.SupportAccessPermissionPolicies,
  },
};
