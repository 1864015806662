import React, { useContext, ReactElement, PropsWithChildren } from 'react';

import { DataType, Entity } from '@m3ter-com/m3ter-api';

import { CrudActionProps } from '@/types/crud';

export interface CrudContextValues<E extends Entity = Entity>
  extends CrudActionProps<E> {
  basePath: string;
  dataType: DataType;
  onCancel?: () => void;
}

const CrudContext = React.createContext<CrudContextValues<any> | null>(null);

CrudContext.displayName = 'CrudContext';

export function CrudContextProvider<E extends Entity>({
  children,
  ...props
}: PropsWithChildren<CrudContextValues<E>>): ReactElement<any, any> | null {
  return <CrudContext.Provider value={props}>{children}</CrudContext.Provider>;
}

export const useCrudContext = <E extends Entity>() =>
  useContext(CrudContext) as CrudContextValues<E>;

export default CrudContext;
