import { StrictEffect } from 'redux-saga/effects';

import { PathParams, QueryParams } from '@m3ter-com/m3ter-api';

import {
  DataExplorerDataType,
  DataExplorerDataTypeToRequestBody,
} from '@/types/data';

import {
  billingAccountsLoader,
  billingDataLoader,
  billingProductsLoader,
} from './dataExplorerBilling.saga';
import {
  commitmentsAccountsLoader,
  commitmentsDataLoader,
  lineItemsDataLoader,
  obligationsDataLoader,
} from './dataExplorerCommitments.saga';
import {
  meterDimensionsLoader,
  metersLoader,
  usageDataLoader,
} from './dataExplorerUsage.saga';

type DataExplorerDataLoader<
  DT extends DataExplorerDataType = DataExplorerDataType
> = (
  organizationId: string,
  body: DataExplorerDataTypeToRequestBody[DT],
  pathParams?: PathParams,
  queryParams?: QueryParams,
  loadExportUrl?: boolean
) => Generator<StrictEffect, void, any>;
type DataExplorerDataLoaderMap = {
  [DT in DataExplorerDataType]: DataExplorerDataLoader<DT>;
};

export const dataExplorerDataTypeToLoader: DataExplorerDataLoaderMap = {
  [DataExplorerDataType.BillingAccounts]: billingAccountsLoader,
  [DataExplorerDataType.BillingData]: billingDataLoader,
  [DataExplorerDataType.BillingProducts]: billingProductsLoader,
  [DataExplorerDataType.CommitmentsAccounts]: commitmentsAccountsLoader,
  [DataExplorerDataType.CommitmentsData]: commitmentsDataLoader,
  [DataExplorerDataType.CommitmentsLineItemsData]: lineItemsDataLoader,
  [DataExplorerDataType.CommitmentsObligationsData]: obligationsDataLoader,
  [DataExplorerDataType.UsageData]: usageDataLoader,
  [DataExplorerDataType.UsageMeterDimensions]: meterDimensionsLoader,
  [DataExplorerDataType.UsageMeters]: metersLoader,
};
