import { useId, useMemo, useRef } from 'react';

import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';

import { DataType, DataTypeToEntity } from '@m3ter-com/m3ter-api';

import { ListSearchOperator } from '@/types/lists';
import { EntityOptionsBaseProps } from '@/types/entity-options';

import { buildSelectOptions } from '@/util/data';
import { createSearchCriteria } from '@/util/crud';
import { dataTypeRetrieveQuery } from '@/queries/crud';
import useEntityList, {
  UseEntityListOptions,
} from '@/hooks/data/crud/useEntityList';
import useAppQuery from '@/hooks/data/useAppQuery';

export interface UseSearchableEntityOptionsProps<DT extends DataType>
  extends EntityOptionsBaseProps<DT> {
  searchFields: Array<string>;
  value: string | null;
}

const entityListOptions: UseEntityListOptions = {
  searchOperator: ListSearchOperator.Or,
};

const useSearchableEntityOptions = <DT extends DataType>({
  accessor,
  dataType,
  detailAccessor,
  optionValueAccessor = 'id',
  searchFields,
  value,
}: UseSearchableEntityOptionsProps<DT>) => {
  // We want a unique list ID because we could have different selects with the
  // same data type, params etc. on the same view.
  const listId = useId();
  const { allEntities, isLoading, error, searchList } = useEntityList<
    DataTypeToEntity[DT]
  >(dataType, listId, entityListOptions);

  // A blur of the input within `Select` always fires the `onInputChange`.
  // Store the last query to avoid extra network requests if the search hasn't changed.
  const lastQuery = useRef('');

  // We always need to load any selected entity and include it in the options.
  const { data: selectedEntity } = useAppQuery(
    dataTypeRetrieveQuery(
      { dataType, id: value ?? undefined },
      { enabled: !!value }
    )
  );

  const combinedData = useMemo(() => {
    const sortedEntities = sortBy(allEntities, accessor);
    return selectedEntity && !allEntities.find(({ id }) => id === value)
      ? [selectedEntity, ...sortedEntities]
      : sortedEntities;
  }, [selectedEntity, allEntities, accessor, value]);

  const options = useMemo(
    () =>
      buildSelectOptions(
        combinedData,
        optionValueAccessor,
        accessor,
        detailAccessor
      ),
    [combinedData, optionValueAccessor, accessor, detailAccessor]
  );

  const debouncedSearch = useMemo(
    () =>
      debounce((query: string) => {
        if (lastQuery.current !== query) {
          searchList(
            query !== '' ? createSearchCriteria(query, searchFields) : undefined
          );
          lastQuery.current = query;
        }
      }, 250),
    [searchList, searchFields]
  );

  return {
    isLoading,
    error,
    options,
    search: debouncedSearch,
  };
};

export default useSearchableEntityOptions;
