import { AnyAction } from 'redux';
import { StrictEffect, call, takeEvery, select } from 'redux-saga/effects';

import { DataType, Id } from '@m3ter-com/m3ter-api';

import { dataCreated, DataCreatedAction, selectById } from '@/store/data/data';
import { retrieveData } from '@/store/data/data.saga';

export function* balanceTransactionChangeSaga(
  action: DataCreatedAction
): Generator<StrictEffect, void, any> {
  const balanceId = action.meta.pathParams?.balanceId;
  if (balanceId) {
    // Check the balance has been loaded.
    const balanceSelector = yield call(
      selectById,
      DataType.Balance,
      balanceId as Id
    );
    const balance = yield select(balanceSelector);

    if (balance) {
      yield call(retrieveData, DataType.Balance, balanceId as Id);
    }
  }
}

export default function* accountBalancesSaga() {
  // When balance transaction are created we need to reload the relevant balance.
  // There is no way to edit/delete so we can ignore those types.
  yield takeEvery(
    (action: AnyAction) =>
      action.type === dataCreated.type &&
      action.payload.dataType === DataType.BalanceTransaction,
    balanceTransactionChangeSaga
  );
}
