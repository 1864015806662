import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AnalyticsJobTypeToResponseData } from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';
import { ReportType, ReportTypeToFilterState } from '@/types/data';

export interface BaseReportsPayload<RT extends ReportType> {
  reportType: RT;
}

export interface LoadReportDataPayload<RT extends ReportType>
  extends BaseReportsPayload<RT> {
  filterState: ReportTypeToFilterState[RT];
  loadExportUrl: boolean;
}
export type LoadReportDataAction<RT extends ReportType> = PayloadAction<
  LoadReportDataPayload<RT>
>;
type LoadReportDataActionCreator = {
  type: string;
  <RT extends ReportType>(
    reportType: RT,
    filterState: ReportTypeToFilterState[RT],
    loadExportUrl: boolean
  ): LoadReportDataAction<RT>;
};

interface LoadReportDataFailurePayload<RT extends ReportType>
  extends BaseReportsPayload<RT> {
  exportUrl?: string;
}
export type LoadReportDataFailureAction<RT extends ReportType> = PayloadAction<
  LoadReportDataFailurePayload<RT>,
  string,
  never,
  AppError
>;
type LoadReportDataFailureActionCreator = {
  type: string;
  <RT extends ReportType>(
    reportType: RT,
    error: AppError,
    exportUrl?: string
  ): LoadReportDataFailureAction<RT>;
};

interface LoadReportDataSuccessPayload<RT extends ReportType>
  extends BaseReportsPayload<RT> {
  response: AnalyticsJobTypeToResponseData[RT];
  exportUrl?: string;
}
export type LoadReportDataSuccessAction<RT extends ReportType> = PayloadAction<
  LoadReportDataSuccessPayload<RT>
>;
type LoadReportDataSuccessActionCreator = {
  type: string;
  <RT extends ReportType>(
    reportType: RT,
    response: AnalyticsJobTypeToResponseData[RT],
    exportUrl?: string
  ): LoadReportDataSuccessAction<RT>;
};

interface ResetReportStatePayload<RT extends ReportType>
  extends BaseReportsPayload<RT> {}
export type ResetReportStateAction<RT extends ReportType> = PayloadAction<
  ResetReportStatePayload<RT>
>;
type ResetReportStateActionCreator = {
  type: string;
  <RT extends ReportType>(reportType: RT): ResetReportStateAction<RT>;
};

export type ReportsStateDatum<RT extends ReportType> = {
  isExporting: boolean;
  isLoading: boolean;
  error?: AppError;
  exportUrl?: string;
  response?: AnalyticsJobTypeToResponseData[RT];
};

export type ReportsState = Partial<{
  [RT in ReportType]?: ReportsStateDatum<RT>;
}>;

const getReportState = (
  state: ReportsState,
  reportType: ReportType
): ReportsStateDatum<ReportType> => {
  if (!state[reportType]) {
    state[reportType] = {
      isExporting: false,
      isLoading: false,
    };
  }
  return state[reportType]!;
};

export const initialState: ReportsState = {};
export const name = 'features/analytics/reports';
const reportsSlice = createSlice({
  name,
  initialState,
  reducers: {
    loadReportData: {
      reducer: <RT extends ReportType>(
        state: ReportsState,
        action: LoadReportDataAction<RT>
      ) => {
        const reportState = getReportState(state, action.payload.reportType);
        reportState.isLoading = true;
        reportState.error = undefined;
        reportState.exportUrl = undefined;
        reportState.response = undefined;
      },
      prepare: <RT extends ReportType>(
        reportType: RT,
        filterState: ReportTypeToFilterState[RT],
        loadExportUrl: boolean
      ) => ({
        payload: { reportType, filterState, loadExportUrl },
      }),
    },
    loadReportDataFailure: {
      reducer: <RT extends ReportType>(
        state: ReportsState,
        action: LoadReportDataFailureAction<RT>
      ) => {
        const reportState = getReportState(state, action.payload.reportType);
        reportState.error = action.error;
        if (action.payload.exportUrl) {
          reportState.exportUrl = action.payload.exportUrl;
        }
        reportState.isLoading = false;
      },
      prepare: <RT extends ReportType>(
        reportType: RT,
        error: AppError,
        exportUrl?: string
      ) => ({
        payload: { reportType, exportUrl },
        error,
      }),
    },
    loadReportDataSuccess: {
      reducer: <RT extends ReportType>(
        state: ReportsState,
        action: LoadReportDataSuccessAction<RT>
      ) => {
        const reportState = getReportState(state, action.payload.reportType);
        reportState.response = action.payload.response;
        reportState.exportUrl = action.payload.exportUrl;
        reportState.isLoading = false;
      },
      prepare: <RT extends ReportType>(
        reportType: RT,
        response: AnalyticsJobTypeToResponseData[RT],
        exportUrl?: string
      ) => ({
        payload: { exportUrl, reportType, response },
      }),
    },
    resetReportState: {
      reducer: <RT extends ReportType>(
        state: ReportsState,
        action: ResetReportStateAction<RT>
      ) => {
        state[action.payload.reportType] = undefined;
      },
      prepare: <RT extends ReportType>(reportType: RT) => ({
        payload: { reportType },
      }),
    },
  },
});

// Action exports
// We need to do these individual exports and cast here because destructuring these
// actions means we lose type inference due to the action creators being generic.

export const loadReportData = reportsSlice.actions
  .loadReportData as LoadReportDataActionCreator;
export const loadReportDataFailure = reportsSlice.actions
  .loadReportDataFailure as LoadReportDataFailureActionCreator;
export const loadReportDataSuccess = reportsSlice.actions
  .loadReportDataSuccess as LoadReportDataSuccessActionCreator;
export const resetReportState = reportsSlice.actions
  .resetReportState as ResetReportStateActionCreator;

const selectReportsSlice = (state: {
  features: { analytics: { reports: ReportsState } };
}): ReportsState => state.features.analytics.reports;

export const selectReportState = <RT extends ReportType>(reportType: RT) =>
  createSelector(selectReportsSlice, (slice) => slice[reportType]);

export const selectIsReportExporting = <RT extends ReportType>(
  reportType: RT
) =>
  createSelector(
    selectReportState(reportType),
    (reportState) => reportState?.isExporting ?? false
  );

export const selectIsReportLoading = <RT extends ReportType>(reportType: RT) =>
  createSelector(
    selectReportState(reportType),
    (reportState) => reportState?.isLoading ?? false
  );

export const selectReportResponse = <RT extends ReportType>(reportType: RT) =>
  createSelector(
    selectReportState(reportType),
    (reportState) => reportState?.response
  );

export const selectReportExportLink = <RT extends ReportType>(reportType: RT) =>
  createSelector(
    selectReportState(reportType),
    (reportState) => reportState?.exportUrl
  );

export const selectReportError = <RT extends ReportType>(reportType: RT) =>
  createSelector(
    selectReportState(reportType),
    (reportState) => reportState?.error
  );

export default reportsSlice.reducer;
