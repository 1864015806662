import React from 'react';

import { NamedRoute } from '@/types/routes';

import { SupportAccessRoute } from '@/components/routes/settings/support-access/SupportAccessRoute';
import { SupportAccessDetailsRoute } from '@/components/routes/settings/support-access/SupportAccessDetailsRoute';
import { SupportAccessEditRoute } from '@/components/routes/settings/support-access/SupportAccessEditRoute';

export default (): NamedRoute => ({
  path: 'support-access',
  name: 'settings.supportAccess',
  element: <SupportAccessRoute />,
  children: [
    { index: true, element: <SupportAccessDetailsRoute /> },
    { path: 'edit', element: <SupportAccessEditRoute /> },
  ],
});
