import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';

import { chakra, StyleProps } from '@chakra-ui/react';

export interface FormFieldsetProps extends StyleProps {
  isDisabled?: boolean;
  legend?: string;
  className?: string;
}

export interface FormFieldsetContext {
  isDisabled?: boolean;
}

const FieldsetContext = createContext<FormFieldsetContext | undefined>(
  undefined
);

export const useFieldsetContext = () => useContext(FieldsetContext);

export const FormFieldset: React.FC<PropsWithChildren<FormFieldsetProps>> = ({
  isDisabled,
  legend,
  className,
  children,
  ...styleProps
}) => {
  const context = useMemo<FormFieldsetContext>(
    () => ({
      isDisabled,
    }),
    [isDisabled]
  );

  return (
    <FieldsetContext.Provider value={context}>
      <chakra.fieldset
        className={className}
        borderColor="chakra-border-color"
        borderWidth={1}
        padding={4}
        disabled={isDisabled}
        {...styleProps}
      >
        {legend && <legend>{legend}</legend>}
        {children}
      </chakra.fieldset>
    </FieldsetContext.Provider>
  );
};
