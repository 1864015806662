import React, { useMemo } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  Flex,
  Link,
} from '@chakra-ui/react';

import { DataType, Id, UserGroup } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, Confirmation } from '@m3ter-com/ui-components';

import { OtherListIds } from '@/types/lists';

import useOrg from '@/hooks/data/crud/useOrg';
import useUserGroupControl from '@/hooks/features/access/useUserGroupControl';
import {
  ColumnDefinition,
  CrudList,
  CrudListFooter,
  CrudListHeader,
  CrudListTable,
} from '@/components/common/crud/CrudList';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import { EntityMultiSelectModal } from '@/components/common/data/EntitySelectModal';

interface UserGroupsListProps {
  userId: Id;
}

const userGroupSearchFields = ['name'];

const emptyArray = new Array<any>();

export const UserGroupsList: React.FC<UserGroupsListProps> = ({ userId }) => {
  const { t } = useTranslation();
  const { currentOrgId: organizationId } = useOrg();

  const {
    isAddingUserToUserGroup,
    isUserGroupDisabled,
    isUserGroupModalOpen,
    isRemovingUserFromUserGroup,
    onAddUserToUserGroups,
    onCloseUserGroupModal,
    onOpenUserGroupModal,
    onRemoveUserFromUserGroups,
    selectedUserGroupIds,
    setSelectedUserGroupIds,
  } = useUserGroupControl(userId);

  const pathParams = useMemo(
    () => ({ organizationId, id: userId }),
    [organizationId, userId]
  );

  const columns = useMemo<Array<ColumnDefinition<UserGroup>>>(
    () => [
      {
        id: 'name',
        header: t('common:name'),
        accessor: (userGroup) => (
          <Link
            as={CrudDetailsLink}
            dataType={DataType.UserGroup}
            id={userGroup.id}
          >
            {userGroup.name}
          </Link>
        ),
      },
    ],
    [t]
  );

  const entityModalColumns = useMemo<Array<ColumnDefinition<UserGroup>>>(
    () => [{ id: 'name', header: t('forms:labels.name'), accessor: 'name' }],
    [t]
  );

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <Flex justifyContent="space-between" alignItems="center">
            <Heading as="h3" size="md">
              {t('common:userGroups')}
            </Heading>
            <Button
              size="sm"
              onClick={onOpenUserGroupModal}
              isLoading={isAddingUserToUserGroup}
            >
              {t('features:access.addToUserGroup')}
            </Button>
          </Flex>
        </CardHeader>
        <CardBody>
          <CrudList<UserGroup>
            dataType={DataType.UserGroup}
            listId={OtherListIds.UsersUserGroups}
            actionName="listByUserId"
            pathParams={pathParams}
          >
            <CrudListHeader hideCreateLink />
            <CrudListTable
              columns={columns}
              selectionType="multi"
              selectedItems={selectedUserGroupIds}
              onSelectedItemsChange={setSelectedUserGroupIds}
            />
            <CrudListFooter>
              <Confirmation
                trigger={
                  <Button
                    isDisabled={selectedUserGroupIds.length === 0}
                    isLoading={isRemovingUserFromUserGroup}
                  >
                    {t('features:access.removeFromSelected')}
                  </Button>
                }
                message={t('features:access.removeFromUserGroupsConfirmation')}
                onConfirm={onRemoveUserFromUserGroups}
              />
            </CrudListFooter>
          </CrudList>
        </CardBody>
      </Card>
      <EntityMultiSelectModal<UserGroup>
        columns={entityModalColumns}
        dataType={DataType.UserGroup}
        isItemDisabled={isUserGroupDisabled}
        isOpen={isUserGroupModalOpen}
        onClose={onCloseUserGroupModal}
        onConfirm={onAddUserToUserGroups}
        searchFields={userGroupSearchFields}
        selected={emptyArray}
      />
    </React.Fragment>
  );
};
