import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataType, Entity, PathParams } from '@m3ter-com/m3ter-api';
import { UnknownEntity } from '@m3ter-com/console-core/types';

import { AppError } from '@/types/errors';
import type { BaseItemPayload, BasePayload, CrudBaseState } from '.';

import {
  CompletionMeta,
  NotificationDefinition,
  RequestMeta,
} from '@/store/store';

export interface UpdateState extends CrudBaseState {
  isLoading: boolean;
  isSaving: boolean;
  loadingError?: AppError;
  savingError?: AppError;
}

export interface LoadUpdateItemPayload extends BaseItemPayload {
  pathParams?: PathParams;
}
export type LoadUpdateItemAction = PayloadAction<LoadUpdateItemPayload>;

export type LoadUpdateItemFailureAction = PayloadAction<
  BasePayload,
  string,
  CompletionMeta,
  AppError
>;

interface LoadUpdateItemSuccessPayload extends BaseItemPayload {
  response: any;
}
export type LoadUpdateItemSuccessAction =
  PayloadAction<LoadUpdateItemSuccessPayload>;

export interface ResetUpdatePayload extends BasePayload {}
export type ResetUpdateAction = PayloadAction<ResetUpdatePayload>;

export interface UpdateItemPayload extends BaseItemPayload {
  item: Entity;
  pathParams?: PathParams;
}

export type UpdateItemAction = PayloadAction<
  UpdateItemPayload,
  string,
  RequestMeta
>;

export type UpdateItemFailureAction = PayloadAction<
  BasePayload,
  string,
  CompletionMeta,
  AppError
>;

const initialState: UpdateState = {
  isLoading: false,
  isSaving: false,
};

const slice = createSlice({
  name: 'crud/update',
  initialState,
  reducers: {
    loadUpdateItem: {
      reducer: (state: UpdateState, _action: LoadUpdateItemAction) => {
        state.isLoading = true;
        state.loadingError = undefined;
      },
      prepare: (dataType: DataType, id: string, pathParams?: PathParams) => ({
        payload: { dataType, id, pathParams },
      }),
    },
    loadUpdateItemSuccess: {
      reducer: (state: UpdateState, action: LoadUpdateItemSuccessAction) => {
        state.id = action.payload.id;
        state.isLoading = false;
      },
      prepare: (dataType: DataType, id: string, response: any) => ({
        payload: { dataType, id, response },
      }),
    },
    loadUpdateItemFailure: {
      reducer: (state: UpdateState, action: LoadUpdateItemFailureAction) => {
        state.isLoading = false;
        state.loadingError = action.error;
      },
      prepare: (dataType: DataType, error: AppError, meta?: any) => ({
        payload: { dataType },
        error,
        meta,
      }),
    },
    updateItem: {
      reducer: (state: UpdateState) => {
        state.isSaving = true;
        state.savingError = undefined;
      },
      prepare: (
        dataType: DataType,
        id: string,
        item: UnknownEntity,
        successNotification?: NotificationDefinition,
        failureNotification?: NotificationDefinition,
        redirectTo?: string,
        pathParams?: PathParams
      ) => ({
        payload: { dataType, id, item, pathParams },
        meta: {
          onSuccess: { redirectTo, notification: successNotification },
          onFailure: { notification: failureNotification },
        },
      }),
    },
    updateItemSuccess: {
      reducer: (state: UpdateState) => {
        state.isSaving = false;
      },
      prepare: (dataType: DataType, id: string, item: Entity, meta?: any) => ({
        payload: { dataType, id, item },
        meta,
      }),
    },
    updateItemFailure: {
      reducer: (state: UpdateState, action: UpdateItemFailureAction) => {
        state.isSaving = false;
        state.savingError = action.error;
      },
      prepare: (dataType: DataType, error: AppError, meta?: any) => ({
        payload: { dataType },
        error,
        meta,
      }),
    },
    resetUpdate: {
      reducer: (_state: UpdateState, _action: ResetUpdateAction) => {
        return initialState;
      },
      prepare: (dataType: DataType) => ({
        payload: { dataType },
      }),
    },
  },
});

export const {
  loadUpdateItem,
  loadUpdateItemSuccess,
  loadUpdateItemFailure,
  updateItem,
  updateItemSuccess,
  updateItemFailure,
  resetUpdate,
} = slice.actions;

export default slice.reducer;
