import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Id } from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

export interface EventsListState {
  isUpdatingEvents: boolean;
  error?: AppError;
}

type FailureAction = PayloadAction<undefined, string, never, AppError>;

interface MarkEventsAsActionedPayload {
  eventIds: Array<Id>;
  listId?: string;
}
export type MarkEventsAsActionedAction =
  PayloadAction<MarkEventsAsActionedPayload>;

const name = 'features/eventsNotifications/eventsList';

const initialState: EventsListState = {
  isUpdatingEvents: false,
};

const eventsListSlice = createSlice({
  name,
  initialState,
  reducers: {
    markEventsAsActioned: {
      reducer: (
        state: EventsListState,
        _action: MarkEventsAsActionedAction
      ) => {
        state.isUpdatingEvents = true;
        state.error = undefined;
      },
      prepare: (eventIds: Array<Id>, listId?: string) => ({
        payload: { eventIds, listId },
      }),
    },
    markEventsAsActionedFailure: {
      reducer: (state: EventsListState, action: FailureAction) => {
        state.error = action.error;
        state.isUpdatingEvents = false;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    markEventsAsActionedSuccess: (state: EventsListState) => {
      state.isUpdatingEvents = false;
    },
    reset: () => initialState,
  },
});

// // Export action creators.
export const {
  markEventsAsActioned,
  markEventsAsActionedFailure,
  markEventsAsActionedSuccess,
  reset,
} = eventsListSlice.actions;

// Selectors.
const selectEventsListState = (state: {
  features: { eventsNotifications: { eventsList: EventsListState } };
}): EventsListState => state.features.eventsNotifications.eventsList;

export const selectIsUpdatingEvents = createSelector(
  selectEventsListState,
  (eventsListState) => eventsListState.isUpdatingEvents
);

export const selectEventsListError = createSelector(
  selectEventsListState,
  (eventsListState) => eventsListState.error
);

export default eventsListSlice.reducer;
