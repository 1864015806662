import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { PlanTemplate } from '@m3ter-com/m3ter-api';

const suite = create('planTemplate', (data: Partial<PlanTemplate>) => {
  test(
    'productId',
    i18next.t('forms:validations.planTemplate.productRequired'),
    () => {
      enforce(data.productId).isNotEmpty();
    }
  );

  test('name', i18next.t('forms:validations.common.nameRequired'), () => {
    enforce(data.name).isNotEmpty();
  });

  test(
    'currency',
    i18next.t('forms:validations.common.currencyRequired'),
    () => {
      enforce(data.currency).isNotEmpty();
    }
  );

  test('currency', i18next.t('forms:validations.common.currencyLength'), () => {
    enforce(data.currency).lengthEquals(3);
  });

  test(
    'billFrequency',
    i18next.t('forms:validations.planTemplate.billFrequency'),
    () => {
      enforce(data.billFrequency).isNotEmpty();
    }
  );

  test(
    'billFrequencyInterval',
    i18next.t('forms:validations.planTemplate.billFrequencyInterval'),
    () => {
      enforce(data.billFrequencyInterval).isNotEmpty();
    }
  );

  test(
    'standingCharge',
    i18next.t('forms:validations.plan.standingChargeRequired'),
    () => {
      enforce(data.standingCharge).greaterThanOrEquals(0);
    }
  );

  test(
    'standingChargeInterval',
    i18next.t('forms:validations.planTemplate.standingChargeIntervalRequired'),
    () => {
      enforce(data.standingChargeInterval).isNotEmpty();
    }
  );

  test(
    'standingChargeOffset',
    i18next.t('forms:validations.planTemplate.standingChargeOffsetRequired'),
    () => {
      enforce(data.standingChargeOffset).greaterThanOrEquals(0);
    }
  );
});

export default suite;
