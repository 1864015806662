import React, { useMemo } from 'react';

import { SystemStyleObject, Text } from '@chakra-ui/react';

import { CustomFields } from '@m3ter-com/m3ter-api';
import { KeyValueList, KeyValueListItem } from '@m3ter-com/ui-components';

export interface CustomFieldsListProps {
  customFields: CustomFields;
  truncateValues?: boolean;
}

export const CustomFieldsList: React.FC<CustomFieldsListProps> = ({
  customFields,
  truncateValues = false,
}) => {
  const styles = useMemo<SystemStyleObject>(
    () =>
      truncateValues
        ? {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }
        : {},
    [truncateValues]
  );

  return (
    <KeyValueList>
      {Object.keys(customFields)
        .sort()
        .map((key) => (
          <KeyValueListItem
            key={key}
            label={key}
            value={<Text sx={styles}>{customFields[key]}</Text>}
          />
        ))}
    </KeyValueList>
  );
};
