import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { ItemCounterAdjustmentsRoute } from '@/components/routes/accounts/ItemCounterAdjustmentsRoute';
import { ItemCounterAdjustmentsDetailsRoute } from '@/components/routes/accounts/ItemCounterAdjustmentsDetailsRoute';

const LazyItemCounterAdjustmentsCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "ItemCounterAdjustmentsCreateRoute" */ '@/components/routes/accounts/ItemCounterAdjustmentsCreateRoute'
  ).then((module) => ({ default: module.ItemCounterAdjustmentsCreateRoute }))
);

const LazyItemCounterAdjustmentsEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "ItemCounterAdjustmentsEditRoute" */ '@/components/routes/accounts/ItemCounterAdjustmentsEditRoute'
  ).then((module) => ({ default: module.ItemCounterAdjustmentsEditRoute }))
);

const LazyItemCounterAdjustmentsListRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "ItemCounterAdjustmentsListRoute" */ '@/components/routes/accounts/ItemCounterAdjustmentsListRoute'
  ).then((module) => ({ default: module.ItemCounterAdjustmentsListRoute }))
);

export default () =>
  createCrudRoute({
    path: 'counter-adjustments',
    dataType: DataType.CounterAdjustment,
    element: <ItemCounterAdjustmentsRoute />,
    list: <LazyItemCounterAdjustmentsListRoute />,
    details: <ItemCounterAdjustmentsDetailsRoute />,
    create: <LazyItemCounterAdjustmentsCreateRoute />,
    edit: <LazyItemCounterAdjustmentsEditRoute />,
  });
