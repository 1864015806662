import React, { useEffect, useCallback, useState, ReactElement } from 'react';

import { Entity, Id } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { ListSearchOperator, OtherListIds } from '@/types/lists';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import { CrudList } from '@/components/common/crud/CrudList';

import { EntitySelectModalBaseProps } from './types';
import { EntitySelectModalWrapper } from './EntitySelectModalWrapper';

export type EntitySelectModalProps<E extends Entity> =
  EntitySelectModalBaseProps<E> & {
    onConfirm: (entityId: Id | null) => void;
    selected: Id | null;
  };

export function EntitySelectModal<E extends Entity = Entity>({
  dataType,
  isOpen,
  onClose,
  onConfirm,
  selected,
  ...crudListProps
}: EntitySelectModalProps<E>): ReactElement<any, any> {
  const { t } = useTranslation();

  // We keep an internal state of the selected entities for a few reasons:
  // 1. It means the modal can be opened and selections can be made without
  // calling onSelectChange every time a row of the crud list is selected /
  // de-selected.
  // 2. It also means the user can cancel out of the modal after making changes,
  // without the external selected value being updated.
  const [internalSelected, setInternalSelected] = useState<Array<Id>>(
    selected ? [selected] : []
  );
  useEffect(() => {
    setInternalSelected(selected ? [selected] : []);
  }, [selected]);

  const handleConfirm = useCallback(() => {
    onConfirm(internalSelected[0] || null);
    onClose();
  }, [internalSelected, onConfirm, onClose]);
  const handleClose = useCallback(() => {
    onClose();
    setInternalSelected(selected ? [selected] : []);
  }, [onClose, selected]);

  const entityNamings = useEntityNamings(dataType);

  return (
    <EntitySelectModalWrapper
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={handleConfirm}
      title={t('forms:labels.selectOneX', { x: entityNamings.singular })}
    >
      <CrudList
        {...crudListProps}
        dataType={dataType}
        listId={OtherListIds.EntitySelectModal}
        onSelectedItemsChange={setInternalSelected}
        selectedItems={internalSelected}
        selectionType="single"
        searchOperator={ListSearchOperator.Or}
      />
    </EntitySelectModalWrapper>
  );
}
