import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DataType,
  Entity,
  UnsavedEntity,
  PathParams,
} from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';
import type { BasePayload } from '.';

import {
  RequestMeta,
  CompletionMeta,
  NotificationDefinition,
} from '@/store/store';

export interface CreateState {
  isSaving: boolean;
  error?: AppError;
}

export interface CreateItemPayload extends BasePayload {
  item: UnsavedEntity<Entity>;
  pathParams?: PathParams;
}

export type CreateItemAction = PayloadAction<
  CreateItemPayload,
  string,
  RequestMeta
>;

export interface CreateItemSuccessPayload extends BasePayload {
  item: Entity;
}
type CreateItemSuccessAction = PayloadAction<CreateItemSuccessPayload>;

export type CreateItemFailureAction = PayloadAction<
  BasePayload,
  string,
  CompletionMeta,
  AppError
>;

export type ResetCreateAction = PayloadAction<BasePayload>;

const initialState: CreateState = {
  isSaving: false,
};

const slice = createSlice({
  name: 'crud/create',
  initialState,
  reducers: {
    createItem: {
      reducer: (state: CreateState) => {
        state.isSaving = true;
        state.error = undefined;
      },
      prepare: (
        dataType: DataType,
        item: UnsavedEntity<Entity>,
        successNotification?: NotificationDefinition,
        failureNotification?: NotificationDefinition,
        redirectTo?: string,
        pathParams?: PathParams
      ) => ({
        payload: { dataType, item, pathParams },
        meta: {
          onSuccess: { redirectTo, notification: successNotification },
          onFailure: { notification: failureNotification },
        },
      }),
    },
    createItemSuccess: {
      reducer: (state: CreateState, _action: CreateItemSuccessAction) => {
        state.isSaving = false;
      },
      prepare: (dataType: DataType, item: Entity, meta?: any) => ({
        payload: { dataType, item },
        meta,
      }),
    },
    createItemFailure: {
      reducer: (state: CreateState, action: CreateItemFailureAction) => {
        state.isSaving = false;
        state.error = action.error;
      },
      prepare: (dataType: DataType, error: AppError, meta?: any) => ({
        payload: { dataType },
        error,
        meta,
      }),
    },
    resetCreate: {
      reducer: (_state: CreateState, _action: ResetCreateAction) => {
        return initialState;
      },
      prepare: (dataType: DataType) => ({
        payload: { dataType },
      }),
    },
  },
});

export const { createItem, createItemSuccess, createItemFailure, resetCreate } =
  slice.actions;

export default slice.reducer;
