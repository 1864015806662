import { useMemo } from 'react';

import { DataType, Id, UserGroup } from '@m3ter-com/m3ter-api';

import { OtherListIds } from '@/types/lists';

import useEntityList, {
  UseEntityListOptions,
} from '@/hooks/data/crud/useEntityList';
import useOrg from '@/hooks/data/crud/useOrg';

const useUserGroupList = (userId: Id) => {
  const { currentOrgId: organizationId } = useOrg();

  const pathParams = useMemo(
    () => ({ organizationId, id: userId }),
    [organizationId, userId]
  );

  const userGrouplistOptions = useMemo<UseEntityListOptions>(
    () => ({ actionName: 'listByUserId', pathParams }),
    [pathParams]
  );
  const {
    allEntities: userGroups,
    error: userGroupsError,
    isLoading: userGroupsLoading,
  } = useEntityList<UserGroup>(
    DataType.UserGroup,
    OtherListIds.UsersUserGroups,
    userGrouplistOptions
  );

  return {
    userGroups,
    userGroupsError,
    userGroupsLoading,
  };
};

export default useUserGroupList;
