import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { UserGroupsRoute } from '@/components/routes/settings/user-groups/UserGroupsRoute';
import { UserGroupsListRoute } from '@/components/routes/settings/user-groups/UserGroupsListRoute';
import { UserGroupsDetailsRoute } from '@/components/routes/settings/user-groups/UserGroupsDetailsRoute';
import { UserGroupsCreateRoute } from '@/components/routes/settings/user-groups/UserGroupsCreateRoute';
import { UserGroupsEditRoute } from '@/components/routes/settings/user-groups/UserGroupsEditRoute';

export default () =>
  createCrudRoute({
    path: 'user-groups',
    dataType: DataType.UserGroup,
    element: <UserGroupsRoute />,
    list: <UserGroupsListRoute />,
    details: <UserGroupsDetailsRoute />,
    create: <UserGroupsCreateRoute />,
    edit: <UserGroupsEditRoute />,
  });
