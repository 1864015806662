import React, { useCallback } from 'react';

import { VStack } from '@chakra-ui/react';

import {
  PermissionEffect,
  PermissionPolicyStatement,
} from '@m3ter-com/m3ter-api';
import { SelectOption } from '@m3ter-com/ui-components';
import {
  FormAddRowButton,
  FormMultiRowWrapper,
} from '@m3ter-com/console-core/components';

import { NestedCheckboxOption } from '@/components/forms/NestedCheckbox';
import { PermissionStatementFormFields } from '@/components/features/access/permission-policy/PermissionStatementFormFields';

export interface PermissionPolicyEditorOptions {
  effects: Array<SelectOption<PermissionEffect>>;
  actions: Array<NestedCheckboxOption>;
  resources: Array<SelectOption>;
}

export interface PermissionPolicyEditorProps {
  options: PermissionPolicyEditorOptions;
  value: Array<PermissionPolicyStatement>;
  onChange: (value: Array<PermissionPolicyStatement>) => void;
}

export const PermissionPolicyEditor: React.FC<PermissionPolicyEditorProps> = ({
  value,
  onChange,
  options,
}) => {
  const onRowChange = useCallback(
    (rowValue: PermissionPolicyStatement, index: number) => {
      onChange(value.map((row, i) => (i === index ? rowValue : row)));
    },
    [onChange, value]
  );

  const onRowRemove = useCallback(
    (index: number) => {
      onChange(value.filter((_row, i) => i !== index));
    },
    [onChange, value]
  );

  const onAdd = useCallback(() => {
    onChange([
      ...value,
      { effect: PermissionEffect.Allow, action: [], resource: ['*'] },
    ]);
  }, [onChange, value]);

  return (
    <VStack width="100%" alignItems="stretch" spacing={4}>
      <FormMultiRowWrapper hasFields={value.length > 0}>
        <PermissionStatementFormFields
          value={value}
          options={options}
          onRowChange={onRowChange}
          onRowRemove={onRowRemove}
        />
      </FormMultiRowWrapper>
      )
      <FormAddRowButton onAdd={onAdd} />
    </VStack>
  );
};
