import React from 'react';

import { Navigate } from 'react-router-dom';

import { NamedRoute } from '@/types/routes';

import { AuthContainer } from '@/components/features/auth/AuthContainer';
import { CompleteNewPasswordForm } from '@/components/features/auth/CompleteNewPasswordForm';
import { ForgotPasswordForm } from '@/components/features/auth/ForgotPasswordForm';
import { ForgotPasswordSubmitForm } from '@/components/features/auth/ForgotPasswordSubmitForm';
import { SignInForm } from '@/components/features/auth/SignInForm';
import { FederatedSignInForm } from '@/components/features/auth/FederatedSignInForm';

export const ROOT_AUTH_PATH = 'auth';

export default (): NamedRoute => ({
  path: ROOT_AUTH_PATH,
  name: 'auth',
  element: <AuthContainer />,
  children: [
    {
      index: true,
      element: <Navigate replace to="sign-in" />,
    },
    {
      path: 'sign-in',
      name: 'auth.sign-in',
      element: <SignInForm />,
    },
    {
      path: 'federated-sign-in',
      name: 'auth.federated-sign-in',
      element: <FederatedSignInForm />,
    },
    {
      path: 'forgot-password',
      name: 'auth.forgot-password',
      element: <ForgotPasswordForm />,
    },
    {
      path: 'forgot-password-submit',
      name: 'auth.forgot-password-submit',
      element: <ForgotPasswordSubmitForm />,
    },
    {
      path: 'complete-new-password',
      name: 'auth.complete-new-password',
      element: <CompleteNewPasswordForm />,
    },
  ],
});
