import React, { useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { Box, FormLabel, VStack } from '@chakra-ui/react';

import { DataType, Id, MeasurementsDeletion } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds } from '@/types/lists';

import useDateFormatter from '@/hooks/util/useDateFormatter';
import useQueryString from '@/hooks/navigation/useQueryString';
import { CrudList, ColumnDefinition } from '@/components/common/crud/CrudList';
import { EntitySelect } from '@/components/forms/EntitySelect';

export interface MeasurementsDeletionsListRouteProps {}

interface ListQuery {
  organizationId: string;
}

export const MeasurementsDeletionsListRoute: React.FC = () => {
  const { t } = useTranslation();
  const { toLongDateTime } = useDateFormatter();

  const { organizationId } = useQueryString<ListQuery>();
  const [selectedOrgId, setSelectedOrgId] = useState<Id | null>(organizationId);

  const columns = useMemo<Array<ColumnDefinition<MeasurementsDeletion>>>(
    () => [
      {
        id: 'id',
        header: t('common:id'),
        accessor: (deletion: MeasurementsDeletion) => deletion.id,
      },
      {
        id: 'deleteCount',
        header: t('features:admin.deleteCount'),
        accessor: (deletion: MeasurementsDeletion) => deletion.deleteCount,
      },
      {
        id: 'tsStart',
        header: t('common:start'),
        accessor: (deletion: MeasurementsDeletion) =>
          toLongDateTime(deletion.tsStart),
      },
      {
        id: 'tsEnd',
        header: t('common:end'),
        accessor: (deletion: MeasurementsDeletion) =>
          toLongDateTime(deletion.tsEnd),
      },
      {
        id: 'dtLastUpdated',
        header: t('common:lastUpdatedDate'),
        accessor: (deletion: MeasurementsDeletion) =>
          deletion.dtLastUpdated
            ? toLongDateTime(deletion.dtLastUpdated)
            : undefined,
      },
      {
        id: 'accountCode',
        header: t('common:account'),
        accessor: 'accountId',
      },
      {
        id: 'meterCode',
        header: t('common:meter'),
        accessor: 'meterId',
      },
      {
        id: 'status',
        header: t('forms:labels.status'),
        accessor: 'status',
      },
    ],
    [t, toLongDateTime]
  );

  const pathParams = useMemo(
    () => ({
      organizationId: selectedOrgId,
    }),
    [selectedOrgId]
  );

  const location = useLocation();

  const createQueryParams = useMemo(
    () => ({
      organizationId: selectedOrgId,
      returnPath: `${location.pathname}?organizationId=${selectedOrgId}`,
    }),
    [selectedOrgId, location]
  );

  return (
    <VStack alignItems="start">
      <Box width="50%">
        <FormLabel htmlFor="organization">{t('common:organization')}</FormLabel>
        <EntitySelect
          dataType={DataType.OrganizationAdmin}
          value={selectedOrgId}
          onChange={setSelectedOrgId}
          accessor={(org) => org.organizationName ?? org.shortName}
          inputId="organization"
        />
      </Box>
      {selectedOrgId && (
        <CrudList<MeasurementsDeletion>
          listId={EntityRouteListIds.MeasurementsDeletion}
          columns={columns}
          pathParams={pathParams}
          createRouteParams={createQueryParams}
        />
      )}
    </VStack>
  );
};
