import React, { useMemo } from 'react';

import { Icon, Text, Tooltip } from '@chakra-ui/react';
import { FaExclamationTriangle } from 'react-icons/fa';

import { IntegrationConfig } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds } from '@/types/lists';

import { ActiveStatusBadge } from '@/components/common/data/ActiveStatusBadge';
import { CrudList, ColumnDefinition } from '@/components/common/crud/CrudList';

const searchFields = ['entityType', 'entityId', 'destination'];

export const IntegrationConfigsListRoute: React.FC = () => {
  const { t } = useTranslation();

  const columns = useMemo<Array<ColumnDefinition<IntegrationConfig>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (config) => config.name || '-',
      },
      {
        id: 'entityType',
        header: t('features:integrations.entityType'),
        accessor: (config) => (
          <React.Fragment>
            <Text>{config.entityType}</Text>
            {config.entityId && (
              <Text variant="annotation">
                {t('common:id')}
                {': '}
                {config.entityId}
              </Text>
            )}
          </React.Fragment>
        ),
      },
      {
        id: 'destination',
        header: t('features:integrations.destination'),
        accessor: (config) => (
          <React.Fragment>
            <Text>{config.destination}</Text>
            {config.destinationId && (
              <Text variant="annotation">
                {t('common:id')}
                {': '}
                {config.destinationId}
              </Text>
            )}
          </React.Fragment>
        ),
      },
      {
        id: 'connection',
        header: t('features:integrations.connection'),
        accessor: (config) =>
          config.entityType !== 'Notification' && (
            <ActiveStatusBadge
              active={config.authorized}
              activeLabel={t('features:integrations.connected')}
              inactiveLabel={t('features:integrations.disconnected')}
              ml={0}
            />
          ),
      },
      {
        id: 'enabled',
        header: '',
        accessor: (config) =>
          config.enabled === false && (
            <Tooltip
              label={t('features:integrations.integrationDisabledError')}
              placement="top"
            >
              <span>
                <Icon as={FaExclamationTriangle} color="red" />
              </span>
            </Tooltip>
          ),
      },
    ],
    [t]
  );

  return (
    <CrudList
      listId={EntityRouteListIds.IntegrationConfig}
      columns={columns}
      searchFields={searchFields}
    />
  );
};
