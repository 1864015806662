import { create, each, enforce, omitWhen, test } from 'vest';
import i18next from 'i18next';

import { StatementDefinition } from '@m3ter-com/m3ter-api';
import { DeepPartial } from '@m3ter-com/console-core/types';

const suite = create('meter', (data: DeepPartial<StatementDefinition>) => {
  test('name', i18next.t('forms:validations.common.nameRequired'), () => {
    enforce(data.name).isNotEmpty();
  });

  test('name', i18next.t('forms:validations.common.nameMaxLength'), () => {
    enforce(data.name).shorterThanOrEquals(200);
  });

  test(
    'name',
    i18next.t(
      'forms:validations.statementDefinition.aggregationFrequencyRequired'
    ),
    () => {
      enforce(data.aggregationFrequency).isNotEmpty();
    }
  );

  test(
    'measures',
    i18next.t(
      'forms:validations.statementDefinition.atLeastOneMeasureRequired'
    ),
    () => {
      enforce(data.measures).lengthNotEquals(0);
    }
  );

  // Validate measures.
  omitWhen(!data.measures, () => {
    each(data.measures!, (measure, index) => {
      test(
        `measures.${index}.meterId`,
        i18next.t('forms:validations.statementDefinition.meterRequired'),
        () => {
          enforce(measure?.meterId).isNotEmpty();
        }
      );

      test(
        `measures.${index}.name`,
        i18next.t('forms:validations.statementDefinition.measureRequired'),
        () => {
          enforce(measure?.name).isNotEmpty();
        }
      );

      test(
        `measures.${index}.aggregations`,
        i18next.t('forms:validations.statementDefinition.aggregationRequired'),
        () => {
          enforce(measure?.aggregations).lengthNotEquals(0);
        }
      );
    });
  });

  // Validate dimensions.
  omitWhen(!data.dimensions, () => {
    each(data.dimensions!, (dimension, index) => {
      test(
        `dimensions.${index}.meterId`,
        i18next.t('forms:validations.statementDefinition.meterRequired'),
        () => {
          enforce(dimension?.meterId).isNotEmpty();
        }
      );

      test(
        `dimensions.${index}.name`,
        i18next.t('forms:validations.statementDefinition.dimensionRequired'),
        () => {
          enforce(dimension?.name).isNotEmpty();
        }
      );
    });
  });
});

export default suite;
