import React from 'react';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  ModalHeader,
  ButtonGroup,
  ModalFooter,
} from '@chakra-ui/react';

import { DataType, Id, UserAdmin } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, FormStack, SelectOption } from '@m3ter-com/ui-components';
import {
  Form,
  FormField,
  FormMultiSelect,
} from '@m3ter-com/console-core/components';

import {
  DataTypeFormEntitySelect,
  FormEntitySelect,
} from '@/components/forms/FormEntitySelect';
import userAdminPermissionPoliciesSchema from '@/validation/userAdminPermissionPolicies';

export interface OrganizationAdminPermissionsModalFormValues {
  userId: Id;
  permissionPolicyIds: Array<Id>;
}

export interface OrganizationAdminPermissionsModalProps {
  isOpen?: boolean;
  onClose: () => void;
  onSubmit: (data: OrganizationAdminPermissionsModalFormValues) => void;
}

const permissionOptions: Array<SelectOption> = [
  {
    value: 'administrator',
    label: 'Administrator',
  },
  {
    value: 'readonly',
    label: 'ReadOnly',
  },
];

const defaultInitialValues: Partial<OrganizationAdminPermissionsModalFormValues> =
  { permissionPolicyIds: [] };

export const OrganizationAdminPermissionsModal: React.FC<
  OrganizationAdminPermissionsModalProps
> = ({ isOpen = false, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const userAccessor = (item: UserAdmin) =>
    `${item.firstName} ${item.lastName}`;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Form
          onSubmit={onSubmit}
          initialValues={defaultInitialValues}
          validationSchema={userAdminPermissionPoliciesSchema}
        >
          <ModalHeader>
            {t('features:admin.addUserPermissionPolicy')}
          </ModalHeader>
          <ModalBody>
            <FormStack>
              <FormField
                isRequired
                name="userId"
                label={t('common:user')}
                dataType={DataType.UserAdmin}
                control={
                  FormEntitySelect as DataTypeFormEntitySelect<DataType.UserAdmin>
                }
                accessor={userAccessor}
              />
              <FormField
                isRequired
                name="permissionPolicyIds"
                label={t('common:permissionPolicy')}
                control={FormMultiSelect}
                options={permissionOptions}
              />
            </FormStack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button type="submit" intent="primary">
                {t('forms:buttons.addEntity', {
                  entityName: t('common:permissionPolicy'),
                })}
              </Button>
              <Button type="button" variant="ghost" onClick={onClose}>
                {t('common:cancel')}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
};
