import React, {
  createContext,
  useContext,
  useMemo,
  PropsWithChildren,
  ReactElement,
} from 'react';

import {
  DataType,
  DataTypeToEntity,
  AggregationSegment,
  Plan,
  PlanTemplate,
} from '@m3ter-com/m3ter-api';

import { PricingDataType } from '@/types/data';

import { usePricingGridRowContext } from '@/components/features/pricing/grid/PricingGridRowContext';

export interface PricingGridCellContextValues<DT extends PricingDataType> {
  dataType: DT;
  isTemplateColumn: boolean;
  planTemplatePricings?: Array<DataTypeToEntity[DT]>;
  pricingLinkQueryParams: Record<string, any>;
  currencyCode?: string;
  plan?: Plan;
  planPricings?: Array<DataTypeToEntity[DT]>;
  planTemplate?: PlanTemplate;
  segment?: AggregationSegment;
}

const PricingGridCellContext =
  createContext<PricingGridCellContextValues<PricingDataType> | null>(null);

export type PricingGridCellContextProviderProps<DT extends PricingDataType> =
  Omit<
    PricingGridCellContextValues<DT>,
    'pricingLinkQueryParams' | 'currencyCode' | 'dataType'
  >;

export const PricingGridCellContextProvider = <DT extends PricingDataType>({
  isTemplateColumn,
  plan,
  planPricings,
  planTemplate,
  planTemplatePricings,
  segment,
  children,
}: PropsWithChildren<PricingGridCellContextProviderProps<DT>>): ReactElement<
  any,
  any
> | null => {
  const { aggregation, compoundAggregation, itemCounter } =
    usePricingGridRowContext();

  const pricingLinkQueryParams = useMemo(
    () => ({
      planId: plan?.id,
      planTemplateId: planTemplate?.id,
      aggregationId: aggregation?.id,
      compoundAggregationId: compoundAggregation?.id,
      counterId: itemCounter?.id,
      segment: segment ? JSON.stringify(segment) : undefined,
    }),
    [plan, planTemplate, aggregation, compoundAggregation, itemCounter, segment]
  );

  const dataType = useMemo(
    () =>
      aggregation || compoundAggregation
        ? DataType.Pricing
        : DataType.CounterPricing,
    [aggregation, compoundAggregation]
  );

  const value = useMemo<PricingGridCellContextValues<PricingDataType>>(
    () => ({
      currencyCode: planTemplate?.currency,
      isTemplateColumn,
      plan,
      planTemplate,
      planPricings,
      planTemplatePricings,
      pricingLinkQueryParams,
      dataType,
    }),
    [
      dataType,
      isTemplateColumn,
      plan,
      planPricings,
      planTemplate,
      planTemplatePricings,
      pricingLinkQueryParams,
    ]
  );

  return (
    <PricingGridCellContext.Provider value={value}>
      {children}
    </PricingGridCellContext.Provider>
  );
};

export const usePricingGridCellContext = <DT extends PricingDataType>() => {
  const context = useContext(PricingGridCellContext);
  if (!context) {
    throw new Error(
      'usePricingGridCellContext must be used within a PricingGridCellContextProvider'
    );
  }
  return context as PricingGridCellContextValues<DT>;
};
