import { useCallback } from 'react';

import { useNavigate, useParams, Params } from 'react-router-dom';

import { useAppRoutesContext } from '@/routes/context';

const useNamedNavigate = () => {
  const navigate = useNavigate();
  const routerParams = useParams();
  const { getLink } = useAppRoutesContext();

  return useCallback(
    (
      routeName: string,
      params: Params = {},
      queryParams: Record<string, any> = {}
    ) => {
      const combinedParams = {
        ...routerParams,
        ...params,
      };
      navigate(getLink(routeName, combinedParams, queryParams));
    },
    [navigate, getLink, routerParams]
  );
};

export default useNamedNavigate;
