import React, { PropsWithChildren } from 'react';

import { Box, StyleProps } from '@chakra-ui/react';

export const PricingGridCell: React.FC<PropsWithChildren<StyleProps>> = ({
  children,
  ...styleProps
}) => {
  return (
    <Box width="100%" borderWidth="0 2px 2px 0" padding={2} {...styleProps}>
      {children}
    </Box>
  );
};
