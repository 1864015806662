import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { AggregationsListRoute } from '@/components/routes/aggregations/AggregationsListRoute';
import { AggregationsDetailsRoute } from '@/components/routes/aggregations/AggregationsDetailsRoute';
import { AggregationsRoute } from '@/components/routes/aggregations/AggregationsRoute';

const LazyAggregationsCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "AggregationsCreateRoute" */ '@/components/routes/aggregations/AggregationsCreateRoute'
  ).then((module) => ({ default: module.AggregationsCreateRoute }))
);

const LazyAggregationsEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "AggregationsEditRoute" */ '@/components/routes/aggregations/AggregationsEditRoute'
  ).then((module) => ({ default: module.AggregationsEditRoute }))
);

export default () =>
  createCrudRoute({
    path: 'aggregations',
    dataType: DataType.Aggregation,
    element: <AggregationsRoute />,
    list: <AggregationsListRoute />,
    details: <AggregationsDetailsRoute />,
    create: <LazyAggregationsCreateRoute />,
    edit: <LazyAggregationsEditRoute />,
  });
