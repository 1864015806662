import React, { useCallback, useMemo, useState } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { Aggregation } from '@m3ter-com/m3ter-api';
import { MultiSelect, MultiSelectOption } from '@m3ter-com/ui-components';

const aggregationCodeRegex = /aggregation.(\w+)/g;

export interface CompoundAggregationFormSimpleCalculationFieldProps {
  aggregations: Array<Aggregation>;
  name: string;
  isLoading: boolean;
}

export const CompoundAggregationFormSimpleCalculationField: React.FC<
  CompoundAggregationFormSimpleCalculationFieldProps
> = ({ aggregations, name, isLoading }) => {
  const calculationValue: string | undefined = useWatch({
    name,
  });
  const { setValue } = useFormContext();

  const [selectedAggregationCodes, setSelectedAggregationCodes] = useState<
    Array<string>
  >(() => {
    if (!calculationValue) {
      return [];
    }

    const aggregationCodeMatches =
      calculationValue.matchAll(aggregationCodeRegex);
    return Array.from(aggregationCodeMatches).map(
      ([_completeMatch, aggregationCodeGroup]) => aggregationCodeGroup
    );
  });
  const onSelectedAggregationsChange = useCallback(
    (newAggregationCodes: Array<string>) => {
      const newCalculationValue = newAggregationCodes
        .map((code) => `aggregation.${code}`)
        .join(' + ');
      setValue(name, newCalculationValue);
      setSelectedAggregationCodes(newAggregationCodes);
    },
    [name, setValue]
  );

  const aggregationOptions = useMemo<Array<MultiSelectOption>>(
    () =>
      aggregations
        .map<MultiSelectOption | undefined>((aggregation) =>
          aggregation.code
            ? {
                label: aggregation.name,
                value: aggregation.code,
                secondaryLabel: aggregation.code,
              }
            : undefined
        )
        .filter((option): option is MultiSelectOption => Boolean(option)),
    [aggregations]
  );

  return (
    <MultiSelect
      isSearchable
      isLoading={isLoading}
      onChange={onSelectedAggregationsChange}
      options={aggregationOptions}
      value={selectedAggregationCodes}
    />
  );
};
