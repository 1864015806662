import {
  all,
  call,
  put,
  select,
  takeLatest,
  StrictEffect,
} from 'redux-saga/effects';

import { DataType, AccountPlan, Id } from '@m3ter-com/m3ter-api';
import {
  getCleanDateInstance,
  getDatesSortOrder,
} from '@m3ter-com/console-core/utils';

import { extractError } from '@/util/error';
import { selectOrgTimezone } from '@/store/app/bootstrap/bootstrap';
import { selectById } from '@/store/data/data';
import { listAllData, updateData } from '@/store/data/data.saga';

import {
  loadAccountPlans,
  loadAccountPlansFailure,
  loadAccountPlansSuccess,
  LoadAccountPlansAction,
  PlanAccountPlanDataByProduct,
  PlanAccountPlanDatum,
  PlanGroupAccountPlanDatum,
  EndAccountPlanAction,
  endAccountPlan,
  endAccountPlanFailure,
  endAccountPlanSuccess,
} from './accountPlans';

export function* loadAccountPlanRelatedEntities(
  planIds: Array<Id>,
  productIds: Array<Id>,
  planGroupIds: Array<Id>
): Generator<StrictEffect, void, any> {
  const plansCall = call(listAllData, DataType.Plan, {
    ids: planIds,
  });
  const productsCall = call(listAllData, DataType.Product, {
    ids: productIds,
  });
  const planGroupsCall = call(listAllData, DataType.PlanGroup, {
    ids: planGroupIds,
  });

  yield all([plansCall, productsCall, planGroupsCall]);
}

export function* loadAccountPlansSaga(
  action: LoadAccountPlansAction
): Generator<StrictEffect, void, any> {
  const orgTimeZone = yield select(selectOrgTimezone);
  try {
    const { accountId } = action.payload;
    const accountPlans: Array<AccountPlan> = yield call(
      listAllData,
      DataType.AccountPlan,
      {
        account: accountId,
        includeall: true,
      }
    );

    accountPlans.sort((commitmentA, commitmentB) =>
      getDatesSortOrder(commitmentA.startDate, commitmentB.startDate)
    );

    const activeAndPendingPlansData: PlanAccountPlanDataByProduct = {};
    const previousPlansData: PlanAccountPlanDataByProduct = {};
    const activeAndPendingPlanGroupsData =
      new Array<PlanGroupAccountPlanDatum>();
    const previousPlanGroupsData = new Array<PlanGroupAccountPlanDatum>();
    const planIds = new Set<Id>();
    const productIds = new Set<Id>();
    const planGroupIds = new Set<Id>();
    const now = new Date();
    accountPlans.forEach((accountPlan) => {
      const accountPlanEndDate =
        accountPlan.endDate &&
        getCleanDateInstance(accountPlan.endDate, orgTimeZone);
      const isActiveOrPending = !accountPlanEndDate || accountPlanEndDate > now;

      if (accountPlan.planId && accountPlan.productId) {
        const { id: accountPlanId, planId, productId } = accountPlan;
        planIds.add(planId);
        productIds.add(productId);

        const destination = isActiveOrPending
          ? activeAndPendingPlansData
          : previousPlansData;
        if (!destination[productId]) {
          destination[productId] = new Array<PlanAccountPlanDatum>();
        }
        const data: PlanAccountPlanDatum = {
          accountPlanId,
          planId,
          productId,
        };

        destination[productId].push(data);
      } else if (accountPlan.planGroupId) {
        const { id: accountPlanId, planGroupId } = accountPlan;
        planGroupIds.add(accountPlan.planGroupId);
        const destination = isActiveOrPending
          ? activeAndPendingPlanGroupsData
          : previousPlanGroupsData;

        const data: PlanGroupAccountPlanDatum = { accountPlanId, planGroupId };
        destination.push(data);
      }
    });

    yield call(
      loadAccountPlanRelatedEntities,
      Array.from(planIds),
      Array.from(productIds),
      Array.from(planGroupIds)
    );

    yield put(
      loadAccountPlansSuccess(
        activeAndPendingPlanGroupsData,
        activeAndPendingPlansData,
        previousPlanGroupsData,
        previousPlansData
      )
    );
  } catch (error) {
    yield put(loadAccountPlansFailure(extractError(error)));
  }
}

export function* endAccountPlanSaga(
  action: EndAccountPlanAction
): Generator<StrictEffect, void, any> {
  const { accountPlanId, endDate } = action.payload;
  const { onSuccess, onFailure } = action.meta;

  const accountPlanSelector = yield call(
    selectById,
    DataType.AccountPlan,
    accountPlanId
  );
  const accountPlan = yield select(accountPlanSelector);

  if (accountPlan) {
    try {
      yield call(updateData, DataType.AccountPlan, accountPlanId, {
        ...accountPlan,
        endDate,
      });
      yield put(endAccountPlanSuccess(onSuccess));
    } catch (error) {
      yield put(endAccountPlanFailure(extractError(error), onFailure));
    }
  }
}

export default function* accountPlansSaga() {
  yield takeLatest(loadAccountPlans.type, loadAccountPlansSaga);
  yield takeLatest(endAccountPlan.type, endAccountPlanSaga);
}
