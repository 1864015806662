import React, { useMemo } from 'react';

import { DataType, Id } from '@m3ter-com/m3ter-api';

import { OtherListIds } from '@/types/lists';

import useDateFormatter from '@/hooks/util/useDateFormatter';
import { CrudList, CrudListFooter } from '@/components/common/crud/CrudList';

import {
  AccountCommitmentsTable,
  AccountCommitmentListType,
} from './AccountCommitmentsTable';

export { AccountCommitmentListType };
export interface AccountCommitmentsListProps {
  accountId: Id;
  listType: AccountCommitmentListType;
  hidePagination?: boolean;
}

const relationships = ['billingPlan', 'contract', 'products'];

export const AccountCommitmentsList: React.FC<AccountCommitmentsListProps> = ({
  accountId,
  listType,
  hidePagination = false,
}) => {
  const { toShortDate } = useDateFormatter();
  const params = useMemo(
    () => ({
      accountId,
      [listType === AccountCommitmentListType.ActiveAndPending
        ? 'endDateStart'
        : 'endDateEnd']: toShortDate(new Date(Date.now())),
    }),
    [accountId, listType, toShortDate]
  );

  return (
    <CrudList
      dataType={DataType.Commitment}
      listId={
        listType === AccountCommitmentListType.ActiveAndPending
          ? OtherListIds.ActiveAndPendingCommitments
          : OtherListIds.ExpiredCommitments
      }
      params={params}
      relationships={relationships}
    >
      <AccountCommitmentsTable listType={listType} />
      {!hidePagination && <CrudListFooter />}
    </CrudList>
  );
};
