import React from 'react';

import { UserAdmin } from '@m3ter-com/m3ter-api';

import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { UserAdminForm } from '@/components/features/admin/users/UserAdminForm';

export const UserAdminCreateRoute: React.FC = () => (
  <CrudCreate<UserAdmin> form={UserAdminForm} />
);
