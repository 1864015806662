import {
  ComponentSingleStyleConfig,
  ComponentMultiStyleConfig,
} from '@chakra-ui/react';

export const checklistItem: ComponentMultiStyleConfig = {
  parts: ['indicator', 'label'],
  baseStyle: {
    indicator: {
      color: 'white',
    },
  },
  variants: {
    incomplete: {
      indicator: {
        borderColor: 'green-bright',
        borderWidth: 2,
      },
    },
    complete: {
      indicator: {
        bg: 'green-bright',
      },
      label: {
        textDecoration: 'line-through',
      },
    },
    disabled: {
      indicator: {
        borderColor: 'chakra-border-color',
        borderWidth: 2,
      },
      label: {
        color: 'chakra-subtle-text',
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
};

const checklist: ComponentSingleStyleConfig = {
  baseStyle: {},
};

export default checklist;
