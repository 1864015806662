import React, { ComponentProps } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { Select, SelectOption } from '@m3ter-com/ui-components';

export interface CrudListSelectFilterProps
  extends Omit<ComponentProps<typeof Select>, 'value' | 'onChange'> {
  name: string;
  comparatorOptions: Array<SelectOption>;
}

export const CrudListSelectFilter: React.FC<CrudListSelectFilterProps> = ({
  name,
  comparatorOptions,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: {
          value = { comparator: comparatorOptions[0].value, value: '' },
          onChange,
        },
      }) => (
        <Select
          value={value.value}
          onChange={(option) => onChange({ ...value, value: option })}
          isClearable
          {...props}
        />
      )}
    />
  );
};
