import { create, each, enforce, omitWhen, test } from 'vest';
import i18next from 'i18next';

import { Meter, MeterFieldCategory } from '@m3ter-com/m3ter-api';

const suite = create('meter', (data: Partial<Meter>) => {
  test('code', i18next.t('forms:validations.common.codeRequired'), () => {
    enforce(data.code).isNotEmpty();
  });

  test('name', i18next.t('forms:validations.common.nameRequired'), () => {
    enforce(data.name).isNotEmpty();
  });

  test('name', i18next.t('forms:validations.common.nameMaxLength'), () => {
    enforce(data.name).shorterThanOrEquals(200);
  });

  test(
    'dataFields',
    i18next.t('forms:validations.meter.dataFieldRequired'),
    () => {
      enforce(data.dataFields).lengthNotEquals(0);
    }
  );

  // Validate data fields.
  omitWhen(!data.dataFields, () => {
    each(data.dataFields!, (dataField, index) => {
      test(
        `dataFields.${index}.code`,
        i18next.t('forms:validations.common.codeRequired'),
        () => {
          enforce(dataField.code).isNotEmpty();
        }
      );

      test(
        `dataFields.${index}.name`,
        i18next.t('forms:validations.common.nameRequired'),
        () => {
          enforce(dataField.name).isNotEmpty();
        }
      );

      omitWhen(dataField.category !== MeterFieldCategory.MEASURE, () => {
        test(
          `dataFields.${index}.unit`,
          i18next.t('forms:validations.meter.dataFieldUnitRequired'),
          () => {
            enforce(dataField.unit).isNotEmpty();
          }
        );
      });
    });
  });

  // Validate derived fields.
  omitWhen(!data.derivedFields, () => {
    each(data.derivedFields!, (derivedField, index) => {
      test(
        `derivedFields.${index}.code`,
        i18next.t('forms:validations.common.codeRequired'),
        () => {
          enforce(derivedField.code).isNotEmpty();
        }
      );

      test(
        `derivedFields.${index}.name`,
        i18next.t('forms:validations.common.nameRequired'),
        () => {
          enforce(derivedField.name).isNotEmpty();
        }
      );

      omitWhen(derivedField.category !== MeterFieldCategory.MEASURE, () => {
        test(
          `derivedFields.${index}.unit`,
          i18next.t('forms:validations.meter.derivedFieldUnitRequired'),
          () => {
            enforce(derivedField.unit).isNotEmpty();
          }
        );
      });
    });
  });
});

export default suite;
