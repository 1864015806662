import React, { ReactElement, ReactNode } from 'react';

import {
  Table,
  TableProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import type {
  Accessor,
  ColumnDefinition,
  ColumnDisplay,
  DataTableTranslations,
  SelectionProps,
  SortProps,
} from './types';

import { HorizontalScrollBox } from '../../layout/HorizontalScrollBox';

import { getAccessorValue } from './utils';
import useSelection from './useSelection';
import useColumnDisplay from './useColumnDisplay';
import { DataTableCell } from './DataTableCell';
import { DataTableNoItemsCell } from './DataTableNoItemsCell';
import { DataTableSelectionControl } from './DataTableSelectionControl';
import { DataTableColumnHeader } from './DataTableColumnHeader';
import { DataTableColumnSettings } from './DataTableColumnSettings';

const defaultTranslations: DataTableTranslations = {
  columnSettings: 'Column Settings',
  update: 'Update',
  cancel: 'Cancel',
};

export interface DataTableProps<D> extends SelectionProps<D>, SortProps {
  items: ReadonlyArray<D>;
  columnDefinitions: Array<ColumnDefinition<D>>;
  idAccessor: Accessor<D, string>;
  columnDisplay?: Array<ColumnDisplay>;
  emptyContent?: ReactNode;
  enableColumnOrdering?: boolean;
  enableColumnToggling?: boolean;
  isLoading?: boolean;
  tableProps?: TableProps;
  translations?: DataTableTranslations;
  onColumnDisplayChange?: (columnDisplay: Array<ColumnDisplay>) => void;
}

export { ColumnDefinition, ColumnDisplay };

export function DataTable<D>({
  items = [],
  columnDefinitions,
  emptyContent,
  idAccessor,
  isLoading = false,
  selectionType,
  selectedItems,
  sortColumn,
  sortDescending,
  enableColumnOrdering,
  enableColumnToggling,
  columnDisplay,
  translations = defaultTranslations,
  tableProps,
  onSelectedItemsChange,
  onSortChange,
  isItemDisabled,
  onColumnDisplayChange,
}: DataTableProps<D>): ReactElement<any, any> | null {
  const {
    isItemSelected,
    getSelectAllControlProps,
    getSelectItemControlProps,
  } = useSelection({
    items,
    idAccessor,
    selectionType,
    selectedItems,
    onSelectedItemsChange,
    isItemDisabled,
  });

  const showColumnSettings = enableColumnOrdering || enableColumnToggling;

  const { visibleColumns } = useColumnDisplay(columnDefinitions, columnDisplay);

  const columnCount =
    visibleColumns.length +
    (selectionType ? 1 : 0) +
    (showColumnSettings ? 1 : 0);

  const styles = useMultiStyleConfig('DataTable', {});

  return (
    <HorizontalScrollBox>
      <Table {...tableProps}>
        <Thead>
          <Tr>
            {!!selectionType && (
              <Th width={50}>
                {selectionType === 'multi' && (
                  <DataTableSelectionControl
                    selectionType={selectionType}
                    {...getSelectAllControlProps()}
                  />
                )}
              </Th>
            )}
            {visibleColumns.map((columnDefinition) => (
              <DataTableColumnHeader
                key={columnDefinition.id}
                columnDefinition={columnDefinition}
                sortColumn={sortColumn}
                sortDescending={sortDescending}
                onSortChange={onSortChange}
                sx={styles.header}
              />
            ))}
            {showColumnSettings && (
              <Th width={50}>
                <DataTableColumnSettings
                  columnOptions={columnDefinitions}
                  enableOrdering={enableColumnOrdering}
                  enableToggling={enableColumnToggling}
                  value={columnDisplay}
                  translations={translations}
                  onChange={onColumnDisplayChange}
                />
              </Th>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {!isLoading && items.length > 0 ? (
            items.map((item) => {
              const id = getAccessorValue(item, idAccessor);
              return (
                <Tr
                  key={id}
                  sx={styles.row}
                  bg={isItemSelected(item) ? 'chakra-subtle-bg' : undefined}
                  data-testid={`item-${id}`}
                >
                  {!!selectionType && (
                    <Td>
                      <DataTableSelectionControl
                        selectionType={selectionType}
                        {...getSelectItemControlProps(item)}
                      />
                    </Td>
                  )}
                  {visibleColumns.map((columnDefinition) => (
                    <DataTableCell
                      key={`${id}-${columnDefinition.id}`}
                      item={item}
                      columnDefinition={columnDefinition}
                      sx={styles.cell}
                    />
                  ))}
                  {showColumnSettings && <Td />}
                </Tr>
              );
            })
          ) : (
            <Tr>
              <DataTableNoItemsCell
                columnCount={columnCount}
                isLoading={isLoading}
                emptyContent={emptyContent}
              />
            </Tr>
          )}
        </Tbody>
      </Table>
    </HorizontalScrollBox>
  );
}
