import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Textarea, TextareaProps } from '@chakra-ui/react';
import ResizeTextarea from 'react-textarea-autosize';

export interface JsonEditorProps extends TextareaProps {
  value: any;
  onChange: (value: any) => void;
}

export const JsonEditor: React.FC<JsonEditorProps> = ({
  value,
  onChange,
  ...textAreaProps
}) => {
  const [stringValue, setStringValue] = useState(
    JSON.stringify(value, null, 2)
  );
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const ignoreValueChange = useRef(false);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const string = event.target.value;
      setStringValue(string);
      try {
        const json = JSON.parse(string);
        // When the field contains valid JSON we can call onChange but we don't
        // want to update the string value when the value prop changes.
        ignoreValueChange.current = true;
        onChange(json);
        setIsInvalid(false);
      } catch (e) {
        setIsInvalid(true);
      }
    },
    [onChange]
  );

  useEffect(() => {
    if (!ignoreValueChange.current) {
      setStringValue(JSON.stringify(value, null, 2));
      setIsInvalid(false);
    }
    ignoreValueChange.current = false;
  }, [value]);

  return (
    <Textarea
      as={ResizeTextarea}
      fontFamily="monospace"
      resize="none"
      value={stringValue}
      isInvalid={isInvalid}
      onChange={handleChange}
      spellCheck={false}
      {...textAreaProps}
    />
  );
};
