import { Styles } from '@chakra-ui/theme-tools';

const styles: Styles = {
  global: () => ({
    body: {
      overflow: 'hidden',
      fontFamily: 'DM Sans, sans-serif',
    },
  }),
};

export default styles;
