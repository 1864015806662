import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ServiceUser } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudQueryParams } from '@/types/routes';

import useQueryString from '@/hooks/navigation/useQueryString';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import {
  ServiceUserCreateForm,
  ServiceUserCreateFormValues,
} from '@/components/features/access/service-user/ServiceUserCreateForm';
import { useCrudContext } from '@/components/common/crud/CrudContext';
import {
  createServiceUser,
  selectError,
  selectIsSaving,
} from '@/store/features/access/service-user/serviceUserCreate';

export const ServiceUsersCreateRoute: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { basePath, onCancel } = useCrudContext<ServiceUser>();
  const { returnPath = `${basePath}/:id` } = useQueryString<CrudQueryParams>();

  const isSaving = useSelector(selectIsSaving);
  const error = useSelector(selectError);
  const onSave = useCallback(
    (data: ServiceUserCreateFormValues) => {
      dispatch(createServiceUser(data, returnPath));
    },
    [dispatch, returnPath]
  );

  return (
    <React.Fragment>
      <BreadcrumbItem title={t('common:create')} />
      {error && <ErrorAlert error={error} />}
      <ServiceUserCreateForm
        isSaving={isSaving}
        onSave={onSave}
        onCancel={onCancel}
      />
    </React.Fragment>
  );
};
