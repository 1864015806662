import React from 'react';

import { components, OptionProps } from 'react-select';
import { chakra } from '@chakra-ui/react';

import type { Option } from './types';

export interface OptionWithSecondaryLabelProps
  extends OptionProps<Option<any>> {}

// Custom Option component to render secondary label.
export const OptionWithSecondaryLabel = ({
  children,
  ...props
}: OptionProps<Option<any>>) => {
  const { data, isSelected } = props;

  return (
    <components.Option {...props}>
      {children}
      {data?.secondaryLabel && (
        <chakra.span
          ml={2}
          textColor={isSelected ? 'gray.100' : 'chakra-subtle-text'}
          fontSize="sm"
        >
          {data.secondaryLabel}
        </chakra.span>
      )}
    </components.Option>
  );
};
