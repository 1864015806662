import React from 'react';

import { NamedRoute } from '@/types/routes';

import { RootRoute } from '@/components/routes/RootRoute';
import { RootIndexRoute } from '@/components/routes/RootIndexRoute';
import { OrganizationRoute } from '@/components/routes/organization/OrganizationRoute';

import setupAuthRoutes from './auth';
import setupAdminRoute from './admin';
import setupNotFoundRoute from './not-found';
import setupOrganizationRoute from './organization';

const setupRoutes = (): Array<NamedRoute> => [
  {
    name: 'root',
    path: '/',
    element: <RootRoute />,
    children: [
      {
        index: true,
        element: <RootIndexRoute />,
      },
      {
        name: 'organization',
        element: <OrganizationRoute />,
        children: [setupOrganizationRoute()],
      },
      setupAdminRoute(),
      setupAuthRoutes(),
      setupNotFoundRoute(),
    ],
  },
];

export default setupRoutes;
