import {
  ExternalMappingEntityType,
  ExternalMappingExternalEntity,
  Id,
  IntegrationConfigSchema,
  IntegrationCredential,
  IntegrationCredentialRedirectURLResponse,
  IntegrationRunLog,
  IntegrationRunViewEntityTypes,
} from '../types';

import { getOrganizationPath } from '../util/path';
import { get, post } from '../client';

interface CreateIntegrationCredentialData {
  body: Record<string, any> & {
    name: string;
    type?: string;
  };
  destination: string;
  organizationId: string;
}

interface GetIntegrationCredentialRedirectURLData {
  destination: string;
  name: string;
  organizationId: string;
}

interface GetIntegrationsExternalEntitiesData {
  organizationId: string;
  externalSystem: string;
  m3terEntityType: ExternalMappingEntityType;
}

interface GetLatestIntegrationRunData {
  organizationId: string;
  entityType: IntegrationRunViewEntityTypes;
  id?: Id;
}

export const createIntegrationCredential = (
  data: CreateIntegrationCredentialData
): Promise<IntegrationCredential> => {
  let pathSuffix: string | undefined;
  switch (data.destination.toLowerCase()) {
    case 'chargebee':
      pathSuffix = '/chargebee/chargebeeauth';
      break;
    case 'netsuite':
      pathSuffix = '/netsuite/netsuiteauth';
      break;
    case 'paddle':
      pathSuffix = '/paddle/paddleauth';
      break;
    case 'paddlesandbox':
      pathSuffix = '/paddlesandbox/paddleauth';
      break;
    case 'salesforce':
      pathSuffix = '/salesforce/clientcredentials';
      break;
    default:
    // No-op
  }

  if (!pathSuffix) {
    throw new Error(`Cannot create ${data.destination} credential`);
  }

  return post({
    body: data.body,
    path: getOrganizationPath(`/integrationauth${pathSuffix}`),
    pathParams: { organizationId: data.organizationId },
  }) as Promise<IntegrationCredential>;
};

export const getIntegrationConfigSchema = (
  organizationId: string
): Promise<IntegrationConfigSchema> =>
  get({
    path: getOrganizationPath('/integrationconfigs/available'),
    pathParams: { organizationId },
  }) as Promise<IntegrationConfigSchema>;

export const getIntegrationCredentialRedirectURL = (
  data: GetIntegrationCredentialRedirectURLData
): Promise<IntegrationCredentialRedirectURLResponse> =>
  get({
    path: getOrganizationPath('/integrationauth/:destination/oauth/authorize'),
    pathParams: {
      organizationId: data.organizationId,
      destination: data.destination.toLowerCase(),
    },
    queryParams: { name: data.name },
  }) as Promise<IntegrationCredentialRedirectURLResponse>;

export const getIntegrationsExternalEntities = (
  data: GetIntegrationsExternalEntitiesData
) =>
  get({
    path: getOrganizationPath(
      '/externalmappingconfiguration/values/:externalSystem/:m3terEntityType'
    ),
    pathParams: {
      organizationId: data.organizationId,
      externalSystem: data.externalSystem,
      m3terEntityType: data.m3terEntityType,
    },
  }) as Promise<Array<ExternalMappingExternalEntity>>;

export const getLatestIntegrationRun = (data: GetLatestIntegrationRunData) =>
  get({
    path: getOrganizationPath('/integrationruns/:entityType/latest/:id'),
    pathParams: {
      organizationId: data.organizationId,
      entityType: data.entityType,
      id: data.id,
    },
  }) as Promise<IntegrationRunLog>;
