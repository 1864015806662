import React from 'react';

import { ServiceUser } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { ServiceUserDetails } from '@/components/features/access/service-user/ServiceUserDetails/ServiceUserDetails';

export const ServiceUsersDetailsRoute: React.FC = () => (
  <CrudDetails<ServiceUser> component={ServiceUserDetails} />
);
