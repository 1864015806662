import React from 'react';

import { Destination } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { DestinationDetails } from '@/components/features/destinations/DestinationDetails';

export const DestinationDetailsRoute: React.FC = () => (
  <CrudDetails<Destination> component={DestinationDetails} />
);
