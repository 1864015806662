import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DateTimeISOString, User } from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

import {
  CompletionMeta,
  NotificationDefinition,
  RequestMeta,
} from '@/store/store';

export interface UserActivationState {
  isLoading: boolean;
  error?: AppError;
}

export interface UpdateUserActivationPayload {
  user: User;
  accessEndDateTime: DateTimeISOString | null;
}

export type UpdateUserActivationAction = PayloadAction<
  UpdateUserActivationPayload,
  string,
  RequestMeta
>;

export type UpdateUserActivationFailureAction = PayloadAction<
  undefined,
  string,
  CompletionMeta,
  AppError
>;

const name = 'features/access/userActivation';

const initialState: UserActivationState = {
  isLoading: false,
};

const userActivationSlice = createSlice({
  name,
  initialState,
  reducers: {
    updateUserActivation: {
      reducer: (state: UserActivationState) => {
        state.isLoading = true;
        state.error = undefined;
      },
      prepare: (
        user: User,
        accessEndDateTime: DateTimeISOString | null,
        successNotification?: NotificationDefinition,
        failureNotification?: NotificationDefinition
      ) => ({
        payload: { user, accessEndDateTime },
        meta: {
          onSuccess: { notification: successNotification },
          onFailure: { notification: failureNotification },
        },
      }),
    },
    updateUserActivationSuccess: {
      reducer: (state: UserActivationState) => {
        state.isLoading = false;
      },
      prepare: (meta?: any) => ({
        payload: undefined,
        meta,
      }),
    },
    updateUserActivationFailure: {
      reducer: (
        state: UserActivationState,
        action: UpdateUserActivationFailureAction
      ) => {
        state.isLoading = false;
        state.error = action.error;
      },
      prepare: (error: AppError, meta?: any) => ({
        payload: undefined,
        error,
        meta,
      }),
    },
  },
});

export const {
  updateUserActivation,
  updateUserActivationFailure,
  updateUserActivationSuccess,
} = userActivationSlice.actions;

const selectUserActivationState = (state: {
  features: { access: { userActivation: UserActivationState } };
}): UserActivationState => state.features.access.userActivation;

export const selectIsLoading = createSelector(
  selectUserActivationState,
  (state) => state.isLoading
);

export const selectError = createSelector(
  selectUserActivationState,
  (state) => state?.error
);

export default userActivationSlice.reducer;
