import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import {
  PermissionPolicyEditor,
  PermissionPolicyEditorProps,
} from '@/components/forms/PermissionPolicyEditor';

export interface FormPermissionPolicyEditorProps
  extends Omit<PermissionPolicyEditorProps, 'value' | 'onChange'> {
  name: string;
}

export const FormPermissionPolicyEditor: React.FC<
  FormPermissionPolicyEditorProps
> = ({ name, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value = [], onChange } }) => (
        <PermissionPolicyEditor value={value} onChange={onChange} {...props} />
      )}
    />
  );
};
