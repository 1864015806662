import { useEffect, useMemo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  DataType,
  Entity,
  PathParams,
  QueryParams,
} from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';
import {
  ListSearchCriteria,
  ListSearchOperator,
  ListSortCriteria,
} from '@/types/lists';

import {
  createList,
  filterList as filterListAction,
  loadList as loadListAction,
  loadNextListPage as loadNextListPageAction,
  loadSpecificListPage as LoadSpecificListPageAction,
  refreshList as refreshListAction,
  removeList,
  selectCombinedListData,
  searchList as searchListAction,
  sortList as sortListAction,
} from '@/store/crud';

export interface UseEntityListOptions {
  actionName?: string;
  initialFilterCriteria?: ListSearchCriteria;
  initialSearchCriteria?: ListSearchCriteria;
  initialSortCriteria?: ListSortCriteria;
  loadOnMount?: boolean;
  pathParams?: PathParams;
  queryParams?: QueryParams;
  relationships?: Array<string>;
  searchOperator?: ListSearchOperator;
}

const defaultOptions: UseEntityListOptions = {};

export interface UseEntityListReturn<E extends Entity> {
  allEntities: Array<E>;
  currentPage: number;
  currentPageEntities: Array<E>;
  error?: AppError;
  filterCriteria?: ListSearchCriteria;
  filterList: (newFilterCriteria?: ListSearchCriteria) => void;
  loadList: () => void;
  loadNextListPage: () => void;
  loadSpecificListPage: (pageNumber: number) => void;
  hasMore: boolean;
  isLoading: boolean;
  knownPageCount: number;
  refreshList: () => void;
  searchCriteria?: ListSearchCriteria;
  searchList: (newSearchCriteria?: ListSearchCriteria) => void;
  sortCriteria?: ListSortCriteria;
  sortList: (newSortCriteria?: ListSortCriteria) => void;
}

const useEntityList = <E extends Entity = Entity>(
  dataType: DataType,
  listId: string,
  options: UseEntityListOptions = defaultOptions
): UseEntityListReturn<E> => {
  const dispatch = useDispatch();

  // Memoize the selector otherwise it will be new function per render.
  const combinedListDataSelector = useMemo(
    () => selectCombinedListData<E>(dataType, listId),
    [dataType, listId]
  );
  const listData = useSelector(combinedListDataSelector);

  const filterList = useCallback(
    (newFilterCriteria?: ListSearchCriteria) => {
      dispatch(filterListAction(dataType, listId, newFilterCriteria));
    },
    [dataType, dispatch, listId]
  );

  const loadList = useCallback(() => {
    dispatch(loadListAction(dataType, listId));
  }, [dataType, dispatch, listId]);

  const loadNextListPage = useCallback(() => {
    dispatch(loadNextListPageAction(dataType, listId));
  }, [dataType, dispatch, listId]);

  const loadSpecificListPage = useCallback(
    (pageNumber: number) => {
      dispatch(LoadSpecificListPageAction(dataType, listId, pageNumber - 1));
    },
    [dataType, dispatch, listId]
  );

  const refreshList = useCallback(() => {
    dispatch(refreshListAction(dataType, listId));
  }, [dataType, dispatch, listId]);

  const searchList = useCallback(
    (newSearchCriteria?: ListSearchCriteria) => {
      dispatch(searchListAction(dataType, listId, newSearchCriteria));
    },
    [dataType, dispatch, listId]
  );

  const sortList = useCallback(
    (newSortCriteria?: ListSortCriteria) => {
      dispatch(sortListAction(dataType, listId, newSortCriteria));
    },
    [dispatch, dataType, listId]
  );

  const {
    actionName,
    initialFilterCriteria,
    initialSearchCriteria,
    initialSortCriteria,
    loadOnMount = true,
    pathParams,
    queryParams,
    relationships,
    searchOperator,
  } = options;
  useEffect(() => {
    dispatch(
      createList(
        dataType,
        listId,
        actionName,
        pathParams,
        queryParams,
        relationships,
        initialSearchCriteria,
        initialFilterCriteria,
        searchOperator,
        initialSortCriteria
      )
    );
    if (loadOnMount) {
      loadList();
    }

    return () => {
      dispatch(removeList(dataType, listId));
    };
  }, [
    actionName,
    dataType,
    searchOperator,
    dispatch,
    initialFilterCriteria,
    initialSearchCriteria,
    initialSortCriteria,
    listId,
    loadList,
    loadOnMount,
    pathParams,
    queryParams,
    relationships,
  ]);

  return {
    allEntities: listData.allEntities,
    currentPage: listData.currentPageIndex + 1,
    currentPageEntities: listData.currentPageEntities,
    error: listData.error,
    filterCriteria: listData.filterCriteria,
    filterList,
    hasMore:
      // Open search uses different next tokens
      !!listData.pages[listData.pages.length - 1]?.nextToken &&
      listData.pages[listData.pages.length - 1]?.nextToken !== '0',
    isLoading: listData.isLoading,
    knownPageCount: listData.pages.length,
    loadList,
    loadNextListPage,
    loadSpecificListPage,
    refreshList,
    searchCriteria: listData.searchCriteria,
    searchList,
    sortCriteria: listData.sortCriteria,
    sortList,
  };
};

export default useEntityList;
