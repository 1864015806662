import { combineReducers } from '@reduxjs/toolkit';

import eventsListReducer from './eventsList';
import notificationsReducer from './notifications';

const pricingReducer = combineReducers({
  eventsList: eventsListReducer,
  notifications: notificationsReducer,
});

export default pricingReducer;
