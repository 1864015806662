import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { DataType, ServiceUserCredential } from '@m3ter-com/m3ter-api';

import { performDataAction } from '@/services/api';
import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';
import { extractError } from '@/util/error';
import {
  removeServiceUserCredential,
  RemoveServiceUserCredentialPayload,
  removeServiceUserCredentialSuccess,
  removeServiceUserCredentialFailure,
  ServiceUserCredentialStatusChangePayload,
  makeServiceUserCredentialInactiveSuccess,
  makeServiceUserCredentialInactiveFailure,
  makeServiceUserCredentialActiveFailure,
  makeServiceUserCredentialActiveSuccess,
  LoadServiceUserCredentialsAction,
  loadServiceUserCredentialsSuccess,
  loadServiceUserCredentialsFailure,
  loadServiceUserCredentials,
  makeServiceUserCredentialActive,
  makeServiceUserCredentialInactive,
  createServiceUserCredential,
  CreateServiceUserCredentialAction,
  createServiceUserCredentialSuccess,
  createServiceUserCredentialFailure,
} from '@/store/features/access/service-user/serviceUserCredentials';

export function* loadServiceUserCredentialsSaga(
  action: LoadServiceUserCredentialsAction
): Generator<StrictEffect, void, any> {
  try {
    const { serviceUserId } = action.payload;
    const organizationId = yield select(selectCurrentOrgId);

    if (serviceUserId) {
      const response = yield call(
        performDataAction,
        DataType.ServiceUser,
        'getCredentials',
        {
          organizationId,
          serviceUserId,
        }
      );
      yield put(loadServiceUserCredentialsSuccess(response.data));
    }
  } catch (error) {
    yield put(loadServiceUserCredentialsFailure(extractError(error)));
  }
}

export function* removeServiceUserCredentialSaga(
  action: PayloadAction<RemoveServiceUserCredentialPayload>
): Generator<StrictEffect, void, any> {
  try {
    const { serviceUserId, apiKey } = action.payload;
    const organizationId = yield select(selectCurrentOrgId);

    if (serviceUserId && apiKey) {
      const response = yield call(
        performDataAction,
        DataType.ServiceUser,
        'removeCredential',
        {
          organizationId,
          serviceUserId,
          credentialId: apiKey,
        }
      );
      yield put(removeServiceUserCredentialSuccess(response.apiKey));
    }
  } catch (error) {
    yield put(removeServiceUserCredentialFailure(extractError(error)));
  }
}

export function* makeServiceUserCredentialInactiveSaga(
  action: PayloadAction<ServiceUserCredentialStatusChangePayload>
): Generator<StrictEffect, void, any> {
  try {
    const { serviceUserId, apiKey } = action.payload;
    const organizationId = yield select(selectCurrentOrgId);

    if (serviceUserId && apiKey) {
      const updatedCredential = yield call(
        performDataAction,
        DataType.ServiceUser,
        'makeCredentialInactive',
        { organizationId, serviceUserId, credentialId: apiKey }
      );
      yield put(makeServiceUserCredentialInactiveSuccess(updatedCredential));
    }
  } catch (error) {
    yield put(makeServiceUserCredentialInactiveFailure(extractError(error)));
  }
}

export function* makeServiceUserCredentialActiveSaga(
  action: PayloadAction<ServiceUserCredentialStatusChangePayload>
): Generator<StrictEffect, void, any> {
  try {
    const { serviceUserId, apiKey } = action.payload;
    const organizationId = yield select(selectCurrentOrgId);

    if (serviceUserId && apiKey) {
      const updatedCredential = yield call(
        performDataAction,
        DataType.ServiceUser,
        'makeCredentialActive',
        { organizationId, serviceUserId, credentialId: apiKey }
      );
      yield put(makeServiceUserCredentialActiveSuccess(updatedCredential));
    }
  } catch (error) {
    yield put(makeServiceUserCredentialActiveFailure(extractError(error)));
  }
}

export function* createServiceUserCredentialSaga(
  action: CreateServiceUserCredentialAction
): Generator<StrictEffect, void, any> {
  const { serviceUserId } = action.payload;
  const organizationId = yield select(selectCurrentOrgId);

  try {
    const credential: ServiceUserCredential = yield call(
      performDataAction,
      DataType.ServiceUser,
      'createCredential',
      { serviceUserId, organizationId }
    );
    yield put(createServiceUserCredentialSuccess(credential));
  } catch (error) {
    yield put(createServiceUserCredentialFailure(extractError(error)));
  }
}

export default function* serviceUserCredentialsSaga() {
  yield takeEvery(
    removeServiceUserCredential.type,
    removeServiceUserCredentialSaga
  );
  yield takeEvery(
    makeServiceUserCredentialInactive.type,
    makeServiceUserCredentialInactiveSaga
  );
  yield takeEvery(
    makeServiceUserCredentialActive.type,
    makeServiceUserCredentialActiveSaga
  );
  yield takeEvery(
    loadServiceUserCredentials.type,
    loadServiceUserCredentialsSaga
  );
  yield takeEvery(
    createServiceUserCredential.type,
    createServiceUserCredentialSaga
  );
}
