import React, { useCallback } from 'react';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  chakra,
  CloseButton,
  useStyleConfig,
} from '@chakra-ui/react';

export interface NotificationProps {
  id: string;
  title?: string;
  message: string;
  type?: 'success' | 'info' | 'warning' | 'error';
  onClose?: (id: string) => void;
}

export const Notification: React.FC<NotificationProps> = ({
  id,
  title,
  message,
  type = 'info',
  onClose,
}) => {
  const onCloseClick = useCallback(() => {
    if (onClose) {
      onClose(id);
    }
  }, [onClose, id]);

  const styles = useStyleConfig('Notification');

  return (
    <Alert status={type} id={id} sx={styles}>
      <AlertIcon />
      <chakra.div flex="1">
        {title && <AlertTitle>{title}</AlertTitle>}
        <AlertDescription>{message}</AlertDescription>
      </chakra.div>
      {onClose && <CloseButton size="sm" onClick={onCloseClick} />}
    </Alert>
  );
};
