import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { DataType, ExternalMapping } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Form,
  FormActions,
  FormField,
  FormSection,
} from '@m3ter-com/console-core/components';
import { FormStack } from '@m3ter-com/ui-components';

import { BaseFormProps } from '@/types/forms';

import {
  integrationAccessor,
  integrationDetailsAccessor,
} from '@/util/integrations';
import { edit } from '@/validation/externalMapping';
import { reset as resetExternalMappingsData } from '@/store/features/integrations/external-mappings/externalMappingsData';
import {
  DataTypeFormEntitySelect,
  FormEntitySelect,
} from '@/components/forms/FormEntitySelect';
import useExternalMappingM3terEntityOptions from '@/hooks/features/externalMappings/useExternalMappingM3terEntityOptions';
import { ExternalMappingM3terIdField } from '@/components/features/external-mappings/ExternalMappingM3terIdField';
import { ExternalMappingExternalIdField } from '@/components/features/external-mappings/ExternalMappingExternalIdField';

import { ExternalMappingFormPrerequisiteFieldsDisplay } from './ExternalMappingFormPrerequisiteFieldsDisplay';

interface ExternalMappingEditFormProps
  extends Omit<BaseFormProps<ExternalMapping>, 'isEdit'> {
  initialValues: ExternalMapping;
}

export const ExternalMappingEditForm: React.FC<
  ExternalMappingEditFormProps
> = ({ isSaving, initialValues, onCancel, onSave }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { loadUnmappedEntities } = useExternalMappingM3terEntityOptions(
    initialValues.m3terEntity,
    initialValues.externalSystem,
    initialValues.externalTable,
    initialValues.m3terId
  );
  useEffect(() => {
    loadUnmappedEntities();

    return () => {
      dispatch(resetExternalMappingsData());
    };
  }, [dispatch, loadUnmappedEntities]);

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={edit}
    >
      <FormStack>
        <ExternalMappingFormPrerequisiteFieldsDisplay
          externalSystem={initialValues.externalSystem}
          externalTable={initialValues.externalTable}
          m3terEntity={initialValues.m3terEntity}
        />
        <FormSection
          heading={t('common:entityDetails', {
            entityName: t('common:externalMapping'),
          })}
        >
          <ExternalMappingM3terIdField
            externalSystem={initialValues.externalSystem}
            externalTable={initialValues.externalTable}
            initialM3terId={initialValues.m3terId}
            m3terEntityType={initialValues.m3terEntity}
            name="m3terId"
          />
          <ExternalMappingExternalIdField
            externalSystem={initialValues.externalSystem}
            m3terEntityType={initialValues.m3terEntity}
            name="externalId"
          />
          <FormField
            name="integrationConfigId"
            label={t('features:externalMappings.parentIntegration')}
            helpText={t('forms:helpText.externalMappingParentIntegration')}
            control={
              FormEntitySelect as DataTypeFormEntitySelect<DataType.Integration>
            }
            accessor={integrationAccessor}
            dataType={DataType.Integration}
            detailAccessor={integrationDetailsAccessor}
          />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={t('forms:buttons.updateEntity', {
            entityName: t('common:externalMapping'),
          })}
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
