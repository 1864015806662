import React, { PropsWithChildren } from 'react';

import { Spinner } from '@chakra-ui/react';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert, AlertLink } from '@m3ter-com/ui-components';

import useProducts from '@/hooks/features/products/useProducts';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink';

/**
 * Wrapper for routes that require at least one product is configured.
 *
 * If the products have loaded and there are none it displays an alert
 * with a link to create a product. If there are products the children
 * are rendered.
 */
export const RequiresProductsWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { t } = useTranslation();

  const { products, selectedProductId } = useProducts();
  const hasProducts = products && products.length > 0;

  if (!hasProducts) {
    return (
      <Alert status="warning">
        <Trans
          t={t as TFunction}
          i18nKey="features:products.productsRequired"
          components={{
            createProductLink: (
              <AlertLink
                as={CrudCreateLink}
                addReturnPath
                dataType={DataType.Product}
              />
            ),
          }}
        />
      </Alert>
    );
  }

  return !selectedProductId ? (
    <Spinner />
  ) : (
    // Without the Fragment the return type doesn't match `React.FC`
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment>{children}</React.Fragment>
  );
};
