import { ComponentMultiStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const parts = ['container', 'lineNumber', 'actions'];

const syntaxHighlighter: ComponentMultiStyleConfig = {
  parts,
  baseStyle: (props) => ({
    container: {
      position: 'relative',
      fontSize: 'sm',
      borderWidth: 1,
    },
    lineNumber: {
      px: 2,
      textAlign: 'right',
      userSelect: 'none',
      backgroundColor: mode('gray.100', 'gray.700')(props),
      color: mode('gray.800', 'gray.200')(props),
    },
    actions: {
      position: 'absolute',
      top: 1,
      right: 1,
    },
  }),
};

export default syntaxHighlighter;
