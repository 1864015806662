import React from 'react';

import {
  FaChartBar,
  FaDollarSign,
  FaFileAlt,
  FaFileContract,
  FaHeartbeat,
} from 'react-icons/fa';
import { Card, CardBody, SimpleGrid } from '@chakra-ui/react';

import { AnalyticsJobType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { ReportCard } from '@/components/features/reports/ReportCard';

export const ReportsListRoute: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <SimpleGrid columns={3} gridGap={4} as={CardBody}>
        <ReportCard
          description={t('features:reports.monthOnMonth.summary')}
          icon={FaChartBar}
          reportType={AnalyticsJobType.MonthOnMonthReport}
          title={t('features:reports.monthOnMonth.title')}
        />
        <ReportCard
          description={t('features:reports.recurringRevenue.standard.summary')}
          icon={FaFileContract}
          reportType={AnalyticsJobType.RecurringRevenueReport}
          title={t('features:reports.recurringRevenue.standard.title')}
        />
        <ReportCard
          description={t(
            'features:reports.recurringRevenue.billingBased.summary'
          )}
          icon={FaFileAlt}
          reportType={AnalyticsJobType.BillingBasedRecurringRevenueReport}
          title={t('features:reports.recurringRevenue.billingBased.title')}
        />
        <ReportCard
          description={t('features:reports.prepaymentsStatus.summary')}
          icon={FaHeartbeat}
          reportType={AnalyticsJobType.PrepaymentsStatusReport}
          title={t('features:reports.prepaymentsStatus.title')}
        />
        <ReportCard
          description={t('features:reports.totalContractValue.summary')}
          icon={FaDollarSign}
          reportType={AnalyticsJobType.TotalContractValueReport}
          title={t('features:reports.totalContractValue.title')}
        />
      </SimpleGrid>
    </Card>
  );
};
