import React from 'react';

import { useSelector } from 'react-redux';
import { Box, HStack } from '@chakra-ui/react';

import { LoadingScreen } from '@m3ter-com/ui-components';

import {
  removeAuthRedirectPath,
  setAuthRedirectPath,
} from '@/util/localStorage';
import { selectIsBootstrapping } from '@/store/app/bootstrap/bootstrap';
import useAuth from '@/hooks/auth/useAuth';
import { Logo } from '@/components/common/brand/Logo';
import { UserActions } from '@/components/common/user/UserActions';
import { OrgSelectionList } from '@/components/common/navigation/OrgSelectionList';

export const RootIndexRoute: React.FC = () => {
  const { onSignOut, isM3terAdmin } = useAuth();
  const isAppBootstrapping = useSelector(selectIsBootstrapping);

  if (isAppBootstrapping) {
    setAuthRedirectPath('/');
    return <LoadingScreen />;
  }
  removeAuthRedirectPath();

  return (
    <Box height="100vh" width="100vw">
      <HStack
        alignItems="center"
        justifyContent="space-between"
        marginBottom={8}
        padding={4}
        width="100%"
      >
        <Logo />
        <UserActions
          onLogOutClick={onSignOut}
          isM3terAdminEnabled={isM3terAdmin}
        />
      </HStack>
      <OrgSelectionList />
    </Box>
  );
};
