import { useCallback } from 'react';

import { format, isValid } from 'date-fns';
import { formatInTimeZone, toDate } from 'date-fns-tz';

const hasTimestampRegex = /\d\d:\d\d/;
const hasTimezoneRegex = /.+(z|(\+|-){1}\d\d:\d\d)/i;

const useDatePickerDate = (timeZone?: string, includeTime: boolean = false) => {
  const timeZoneLessFormatKey = includeTime
    ? "yyyy-MM-dd'T'HH:mm:ss"
    : "yyyy-MM-dd'T'00:00:00'";

  const toSystemDate = useCallback(
    (value: string | null) => {
      // If the provided date value is null or is an invalid date string, we cannot
      // convert it so return `null`.
      const isDateValueValid = !!value && isValid(new Date(value));
      if (!isDateValueValid) {
        return null;
      }

      if (timeZone) {
        // If a timeZone is supplied, it might differ from the system's timezone, so we need to do two things.

        // 1. We need to check if the supplied date-string (value prop) has a timestamp and timezone.
        // If it does, we can use it as is. If it doesn't, the JS Date constructor will assume
        // it is in the system's time-zone (at midnight if no time is given).
        // In reality, we should assume a timezone-less value is meant to be in the given timeZone
        // and a time-less value is meant to be midnight in the given timeZone.
        // So we check for both of these and convert the value string into
        // a valid date-time string in the given timeZone if need be.
        const dateValueHasTimestamp = hasTimestampRegex.test(value);
        const dateValueHasTimezone = hasTimezoneRegex.test(value);
        const safeDateString =
          dateValueHasTimestamp && dateValueHasTimezone
            ? value
            : toDate(value, { timeZone }).toISOString();

        // 2. We need to take the safe date-time string we have and use it to create
        // a Date instance that matches the date / time supplied via the value prop
        // but in the system's timeZone so our SystemDatePicker will display the correct
        // date / time.
        const timezoneLessDateString = formatInTimeZone(
          safeDateString,
          timeZone,
          timeZoneLessFormatKey
        );
        return new Date(timezoneLessDateString);
      }

      // If no timezone has been specified, assume we're using the system's
      return new Date(value);
    },
    [timeZone, timeZoneLessFormatKey]
  );

  const fromSystemDate = useCallback(
    (systemDate: Date | null) => {
      if (!systemDate) {
        // If we have being passed null as the new date, return null.
        return null;
      }

      if (!timeZone) {
        // If we have no timeZone prop, we just want to work in the system's timeZone so we can
        // pass the newSystemDate as is to the onChange callback
        return systemDate.toISOString();
      }

      // If we have a timeZone prop, we need to get a string that represents the time / date that
      // the user selected without any timezone information so that we can construct that exact
      // time / date in the specified timezone and then use date instance to generate a ISO string
      // that describes the correct timezoned time / date that was selected.
      const timeZoneLessDateString = format(systemDate, timeZoneLessFormatKey);
      const timeZoneDate = toDate(timeZoneLessDateString, { timeZone });
      return timeZoneDate.toISOString();
    },
    [timeZone, timeZoneLessFormatKey]
  );

  return {
    toSystemDate,
    fromSystemDate,
  };
};

export default useDatePickerDate;
