import React from 'react';

import { DataType, Customer } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { CustomersAdminRoute } from '@/components/routes/admin/customers/CustomersAdminRoute';
import { CustomersAdminListRoute } from '@/components/routes/admin/customers/CustomersAdminListRoute';
import { CustomersAdminDetailsRoute } from '@/components/routes/admin/customers/CustomersAdminDetailsRoute';
import { CustomersAdminCreateRoute } from '@/components/routes/admin/customers/CustomersAdminCreateRoute';
import { CustomersAdminEditRoute } from '@/components/routes/admin/customers/CustomersAdminEditRoute';

export default () =>
  createCrudRoute<Customer>({
    path: 'customers',
    dataType: DataType.Customer,
    element: <CustomersAdminRoute />,
    list: <CustomersAdminListRoute />,
    details: <CustomersAdminDetailsRoute />,
    create: <CustomersAdminCreateRoute />,
    edit: <CustomersAdminEditRoute />,
  });
