import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
} from '@m3ter-com/console-core/components';

import { accountIngestModalStepOneSchema } from '@/validation/accountIngestModal';
import {
  DataTypeFormEntitySelect,
  FormEntitySelect,
} from '@/components/forms/FormEntitySelect';

export interface AccountIngestModalStepOneFormValues {
  meterId: string;
}

interface AccountIngestModalStepOneFormFieldsProps {
  onCancel: () => void;
}

export interface AccountIngestModalStepOneProps {
  initialValues: AccountIngestModalStepOneFormValues;
  onCancel: () => void;
  onSubmit: (formValues: AccountIngestModalStepOneFormValues) => void;
}

const AccountIngestModalStepOneFormFields: React.FC<
  AccountIngestModalStepOneFormFieldsProps
> = ({ onCancel }) => {
  const { t } = useTranslation();

  return (
    <FormStack>
      <FormField
        isRequired
        name="meterId"
        label={t('common:meter')}
        control={FormEntitySelect as DataTypeFormEntitySelect<DataType.Meter>}
        dataType={DataType.Meter}
        accessor="name"
        detailAccessor="code"
      />
      <FormActions
        cancelText={t('common:cancel')}
        submitText={t('common:next')}
        onCancel={onCancel}
      />
    </FormStack>
  );
};

export const AccountIngestModalStepOne: React.FC<
  AccountIngestModalStepOneProps
> = ({ initialValues, onCancel, onSubmit }) => {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={accountIngestModalStepOneSchema}
    >
      <AccountIngestModalStepOneFormFields onCancel={onCancel} />
    </Form>
  );
};
