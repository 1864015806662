import {
  all,
  call,
  CallEffect,
  put,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';

import {
  DataType,
  Aggregation,
  CompoundAggregation,
  Plan,
  Pricing,
} from '@m3ter-com/m3ter-api';

import { ids } from '@/util/data';
import { extractError } from '@/util/error';
import {
  isAggregationSegmented,
  isCompoundAggregationSegmented,
} from '@/util/aggregation';
import { listAllData, retrieveData } from '@/store/data/data.saga';

import {
  loadPricingData,
  LoadPricingDataAction,
  loadPricingDataFailure,
  loadPricingDataSuccess,
} from './segmentsEditor';

export function* loadPricingDataSaga(
  action: LoadPricingDataAction
): Generator<StrictEffect, void, any> {
  const {
    aggregationOrCompoundAggregationId,
    isCompoundAggregation,
    planId,
    planTemplateId,
  } = action.payload;

  try {
    if (!planId && !planTemplateId) {
      throw new Error('Could not load the plan or plan template.');
    }

    const requests: Record<string, CallEffect> = {};

    if (isCompoundAggregation) {
      requests.compoundAggregation = call(
        retrieveData,
        DataType.CompoundAggregation,
        aggregationOrCompoundAggregationId
      );
    } else {
      requests.aggregation = call(
        retrieveData,
        DataType.Aggregation,
        aggregationOrCompoundAggregationId
      );
    }

    if (planId) {
      requests.plan = call(retrieveData, DataType.Plan, planId);
    }

    const {
      aggregation,
      compoundAggregation,
      plan,
    }: {
      aggregation: Aggregation | undefined;
      compoundAggregation: CompoundAggregation | undefined;
      plan: Plan | undefined;
    } = yield all(requests);

    if (
      (compoundAggregation &&
        !isCompoundAggregationSegmented(compoundAggregation)) ||
      (aggregation && !isAggregationSegmented(aggregation))
    ) {
      throw new Error('The selected (compound) aggregation is not segmented.');
    }

    const planTemplate = yield call(
      retrieveData,
      DataType.PlanTemplate,
      (plan?.planTemplateId || action.payload.planTemplateId)!
    );

    let planTemplatePricings: Array<Pricing> = yield call(
      listAllData,
      DataType.Pricing,
      {
        planTemplateId: planTemplate.id,
      }
    );

    let planPricings = new Array<Pricing>();
    if (planId) {
      planPricings = yield call(listAllData, DataType.Pricing, {
        planId,
      });
    }

    const pricingFilter = (pricing: Pricing): boolean =>
      (!!pricing.aggregationId &&
        pricing.aggregationId === aggregationOrCompoundAggregationId) ||
      (isCompoundAggregation &&
        !!pricing.compoundAggregationId &&
        pricing.compoundAggregationId === aggregationOrCompoundAggregationId);

    planTemplatePricings = planTemplatePricings.filter(pricingFilter);
    planPricings = planPricings.filter(pricingFilter);

    yield put(
      loadPricingDataSuccess(
        aggregationOrCompoundAggregationId,
        isCompoundAggregation,
        ids(planPricings),
        ids(planTemplatePricings),
        planTemplate.id,
        plan?.id
      )
    );
  } catch (error) {
    yield put(loadPricingDataFailure(extractError(error)));
  }
}

export default function* segmentsEditorSaga() {
  yield takeLatest(loadPricingData.type, loadPricingDataSaga);
}
