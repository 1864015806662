import React from 'react';

import { OrganizationAdmin } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { OrganizationAdminDetails } from '@/components/features/admin/organizations/OrganizationAdminDetails';

export const OrganizationAdminDetailsRoute: React.FC = () => (
  <CrudDetails<OrganizationAdmin> component={OrganizationAdminDetails} />
);
