import React, { PropsWithChildren } from 'react';

import { Grid } from '@chakra-ui/react';

import { Select, SelectOption } from '@m3ter-com/ui-components';

import { ListSearchCriterion } from '@/types/lists';

interface CrudListFilterComparatorSelectProps {
  onChange: (value: ListSearchCriterion) => void;
  comparatorOptions: Array<SelectOption>;
  value: ListSearchCriterion;
}

export const CrudListFilterComparatorSelect: React.FC<
  PropsWithChildren<CrudListFilterComparatorSelectProps>
> = ({ children, value, onChange, comparatorOptions }) => {
  return (
    <Grid width="100%" gridTemplateColumns="max-content 1fr" gap={2}>
      <Select
        value={value.comparator}
        onChange={(comparator) =>
          onChange({ ...value, comparator } as ListSearchCriterion)
        }
        options={comparatorOptions}
      />
      {children}
    </Grid>
  );
};
