import React from 'react';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';

const LazyTotalContractValueReportRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "TotalContractValueReportRoute" */ '@/components/routes/reports/TotalContractValueReportRoute'
  ).then((module) => ({
    default: module.TotalContractValueReportRoute,
  }))
);

export const name = 'reports.totalContractValue';

export default (): NamedRoute => ({
  name,
  path: 'total-contract-value',
  element: <LazyTotalContractValueReportRoute />,
});
