import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataType, PathParams, QueryParams } from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';
import {
  ListSearchCriteria,
  ListSearchOperator,
  ListSortCriteria,
} from '@/types/lists';
import type { BasePayload, RelatedData } from '.';

export interface ListPage {
  nextToken?: string;
  ids: Array<string>;
  relatedData: RelatedData;
}
export interface List {
  actionName: string;
  currentPageIndex: number;
  error?: AppError;
  filterCriteria?: ListSearchCriteria;
  isLoading: boolean;
  pages: Array<ListPage>;
  pathParams: PathParams;
  queryParams: QueryParams;
  relationships: Array<string>;
  searchCriteria?: ListSearchCriteria;
  searchOperator: ListSearchOperator;
  sortCriteria?: ListSortCriteria;
}
export interface ListState {
  lists: Record<string, List | undefined>;
}

export interface BaseListPayload extends BasePayload {
  listId: string;
}

interface CreateListPayload extends BaseListPayload {
  actionName: string;
  initialFilterCriteria?: ListSearchCriteria;
  initialSearchCriteria?: ListSearchCriteria;
  initialSortCriteria?: ListSortCriteria;
  pathParams: PathParams;
  queryParams: QueryParams;
  relationships: Array<string>;
  searchOperator: ListSearchOperator;
}
export type CreateListAction = PayloadAction<CreateListPayload>;

interface FilterListPayload extends BaseListPayload {
  filterCriteria?: ListSearchCriteria;
}
export type FilterListAction = PayloadAction<FilterListPayload>;

export type ListLoadFailureAction = PayloadAction<
  BaseListPayload,
  string,
  never,
  AppError
>;

export type ListLoadStartedAction = PayloadAction<BaseListPayload>;

interface ListLoadSuccessPayload extends BaseListPayload {
  ids: Array<string>;
  nextToken?: string;
  pageIndex: number;
  relatedData: RelatedData;
}
export type ListLoadSuccessAction = PayloadAction<ListLoadSuccessPayload>;

export type LoadListAction = PayloadAction<BaseListPayload>;

export type LoadNextListPageAction = PayloadAction<BaseListPayload>;

interface LoadSpecificListPagePayload extends BaseListPayload {
  pageIndex: number;
}
export type LoadSpecificListPageAction =
  PayloadAction<LoadSpecificListPagePayload>;

export type RefreshListAction = PayloadAction<BaseListPayload>;

export type RemoveListAction = PayloadAction<BaseListPayload>;

interface SearchListPayload extends BaseListPayload {
  searchCriteria?: ListSearchCriteria;
}
export type SearchListAction = PayloadAction<SearchListPayload>;

interface SortListPayload extends BaseListPayload {
  sortCriteria?: ListSortCriteria;
}
export type SortListAction = PayloadAction<SortListPayload>;

const initialState: ListState = {
  lists: {},
};

const listSlice = createSlice({
  name: 'crud/list',
  initialState,
  reducers: {
    createList: {
      reducer: (state: ListState, action: CreateListAction) => {
        state.lists[action.payload.listId] = {
          actionName: action.payload.actionName,
          currentPageIndex: 0,
          filterCriteria: action.payload.initialFilterCriteria,
          isLoading: false,
          pages: [],
          pathParams: action.payload.pathParams,
          queryParams: action.payload.queryParams,
          relationships: action.payload.relationships,
          searchCriteria: action.payload.initialSearchCriteria,
          searchOperator: action.payload.searchOperator,
          sortCriteria: action.payload.initialSortCriteria,
        };
      },
      prepare: (
        dataType: DataType,
        listId: string,
        actionName: string = 'list',
        pathParams: PathParams = {},
        queryParams: QueryParams = {},
        relationships: Array<string> = [],
        initialSearchCriteria?: ListSearchCriteria,
        initialFilterCriteria?: ListSearchCriteria,
        searchOperator: ListSearchOperator = ListSearchOperator.And,
        initialSortCriteria?: ListSortCriteria
      ) => ({
        payload: {
          actionName,
          searchOperator,
          initialFilterCriteria,
          initialSearchCriteria,
          initialSortCriteria,
          dataType,
          listId,
          pathParams,
          queryParams,
          relationships,
        },
      }),
    },
    filterList: {
      reducer: (state: ListState, action: FilterListAction) => {
        const listState = state.lists[action.payload.listId];
        if (listState) {
          listState.isLoading = true;
          listState.currentPageIndex = 0;
          listState.error = undefined;
          listState.filterCriteria = action.payload.filterCriteria;
          listState.pages = [];
        }
      },
      prepare: (
        dataType: DataType,
        listId: string,
        filterCriteria?: ListSearchCriteria
      ) => ({
        payload: { dataType, filterCriteria, listId },
      }),
    },
    listLoadFailure: {
      reducer: (state: ListState, action: ListLoadFailureAction) => {
        const listState = state.lists[action.payload.listId];
        if (listState) {
          listState.error = action.error;
          listState.isLoading = false;
        }
      },
      prepare: (dataType: DataType, listId: string, error: AppError) => ({
        payload: { dataType, listId },
        error,
      }),
    },
    listLoadSuccess: {
      reducer: (state: ListState, action: ListLoadSuccessAction) => {
        const listState = state.lists[action.payload.listId];
        if (listState) {
          const { ids, pageIndex, relatedData, nextToken } = action.payload;
          listState.currentPageIndex = pageIndex;
          listState.pages[pageIndex] = {
            ids,
            relatedData,
            nextToken,
          };
          listState.isLoading = false;
        }
      },
      prepare: (
        dataType: DataType,
        listId: string,
        ids: Array<string>,
        pageIndex: number,
        relatedData: RelatedData,
        nextToken?: string
      ) => ({
        payload: {
          dataType,
          listId,
          ids,
          nextToken,
          pageIndex,
          relatedData,
        },
      }),
    },
    loadList: {
      reducer: (state: ListState, action: LoadListAction) => {
        const listState = state.lists[action.payload.listId];
        if (listState) {
          listState.isLoading = true;
          listState.currentPageIndex = 0;
          listState.error = undefined;
          listState.pages = [];
        }
      },
      prepare: (dataType: DataType, listId: string) => ({
        payload: {
          dataType,
          listId,
        },
      }),
    },
    loadNextListPage: {
      reducer: (state: ListState, action: LoadNextListPageAction) => {
        const listState = state.lists[action.payload.listId];
        if (listState) {
          listState.isLoading = true;
          listState.currentPageIndex += 1;
          listState.error = undefined;
        }
      },
      prepare: (dataType: DataType, listId: string) => ({
        payload: { dataType, listId },
      }),
    },
    loadSpecificListPage: {
      reducer: (state: ListState, action: LoadSpecificListPageAction) => {
        const listState = state.lists[action.payload.listId];
        if (listState) {
          listState.isLoading = true;
          listState.currentPageIndex = action.payload.pageIndex;
          listState.error = undefined;
        }
      },
      prepare: (dataType: DataType, listId: string, pageIndex: number) => ({
        payload: { dataType, listId, pageIndex },
      }),
    },
    refreshList: {
      reducer: (state: ListState, action: RefreshListAction) => {
        const listState = state.lists[action.payload.listId];
        if (listState) {
          listState.isLoading = true;
          listState.error = undefined;
        }
      },
      prepare: (dataType: DataType, listId: string) => ({
        payload: { dataType, listId },
      }),
    },
    removeList: {
      reducer: (state: ListState, action: RemoveListAction) => {
        delete state.lists[action.payload.listId];
      },
      prepare: (dataType: DataType, listId: string) => ({
        payload: { dataType, listId },
      }),
    },
    searchList: {
      reducer: (state: ListState, action: SearchListAction) => {
        const listState = state.lists[action.payload.listId];
        if (listState) {
          listState.isLoading = true;
          listState.currentPageIndex = 0;
          listState.error = undefined;
          listState.pages = [];
          listState.searchCriteria = action.payload.searchCriteria;
        }
      },
      prepare: (
        dataType: DataType,
        listId: string,
        searchCriteria?: ListSearchCriteria
      ) => ({
        payload: {
          dataType,
          listId,
          searchCriteria,
        },
      }),
    },
    sortList: {
      reducer: (state: ListState, action: SortListAction) => {
        const listState = state.lists[action.payload.listId];
        if (listState) {
          listState.isLoading = true;
          listState.currentPageIndex = 0;
          listState.error = undefined;
          listState.pages = [];
          listState.sortCriteria = action.payload.sortCriteria;
        }
      },
      prepare: (
        dataType: DataType,
        listId: string,
        sortCriteria?: ListSortCriteria
      ) => ({
        payload: {
          dataType,
          listId,
          sortCriteria,
        },
      }),
    },
  },
});

export const {
  createList,
  filterList,
  listLoadFailure,
  listLoadSuccess,
  loadList,
  loadNextListPage,
  loadSpecificListPage,
  refreshList,
  removeList,
  searchList,
  sortList,
} = listSlice.actions;

// Selectors

export const selectList = (state: ListState, listId: string) => {
  return state.lists[listId];
};

export default listSlice.reducer;
