import { spawn } from 'redux-saga/effects';

import accountBalancesSaga from './accountBalances.saga';
import accountContractsSaga from './accountContracts.saga';
import accountPlansSaga from './accountPlans.saga';
import customPlanFormSaga from './customPlanForm.saga';
import customPlanGroupFormSaga from './customPlanGroupForm.saga';
import endBillingEntitiesSaga from './endBillingEntities.saga';

export default function* accountsSaga() {
  yield spawn(accountBalancesSaga);
  yield spawn(accountContractsSaga);
  yield spawn(accountPlansSaga);
  yield spawn(customPlanFormSaga);
  yield spawn(customPlanGroupFormSaga);
  yield spawn(endBillingEntitiesSaga);
}
