import React from 'react';

import { Badge, HStack, Heading, Link, VStack } from '@chakra-ui/react';

import { DataType, Aggregation } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValueList, KeyValueListItem } from '@m3ter-com/ui-components';
import { formatNumber } from '@m3ter-com/console-core/utils';

import { isAggregationSegmented } from '@/util/aggregation';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';

export interface PricingGridAggregationDetailsProps {
  aggregation: Aggregation;
}

export const PricingGridAggregationDetails: React.FC<
  PricingGridAggregationDetailsProps
> = ({ aggregation }) => {
  const { t } = useTranslation();

  return (
    <VStack alignItems="start">
      <Link
        as={CrudDetailsLink}
        dataType={DataType.Aggregation}
        id={aggregation.id}
      >
        <Heading as="h3" size="sm" maxW="300px" wordBreak="break-word">
          {aggregation.name}
        </Heading>
      </Link>
      <HStack>
        <Badge variant="outline" display="block" w="max-content">
          {t('common:aggregation')}
        </Badge>
        {isAggregationSegmented(aggregation) && (
          <Badge variant="outline">{t('features:aggregation.segmented')}</Badge>
        )}
      </HStack>
      <KeyValueList>
        <KeyValueListItem
          label={t('forms:labels.unit')}
          value={aggregation.unit}
        />
        <KeyValueListItem
          label={t('forms:labels.quantityPerUnit')}
          value={formatNumber(aggregation.quantityPerUnit)}
        />
        <KeyValueListItem
          label={t('forms:labels.rounding')}
          value={t(`common:rounding.${aggregation.rounding}`)}
        />
      </KeyValueList>
    </VStack>
  );
};
