import React from 'react';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';

const LazyRecurringRevenueReportRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "RecurringRevenueReportRoute" */ '@/components/routes/reports/RecurringRevenueReportRoute'
  ).then((module) => ({
    default: module.RecurringRevenueReportRoute,
  }))
);

export const name = 'reports.standardRecurringRevenue';

export default (): NamedRoute => ({
  name,
  path: 'recurring-revenue/standard',
  element: <LazyRecurringRevenueReportRoute />,
});
