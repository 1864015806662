import React from 'react';

import { useFormContext } from 'react-hook-form';

import { Input, InputProps } from '@m3ter-com/ui-components';

export interface FormInputProps extends InputProps {
  name: string;
}

// RHF has an issue where setting the value of a field with `valueAsNumber` to `null`
// causes the value to be set to 0. This changes in a later version to `NaN` but it
// still doesn't give us the ability to set a number field to `null`.
const valueAsNumber = (value: any): number | null | undefined => {
  if (value === null || value === undefined) {
    return value;
  }

  if (value === '') {
    return NaN;
  }

  return +value;
};

export const FormInput: React.FC<FormInputProps> = ({ name, ...props }) => {
  const { register } = useFormContext();
  const isNumber = props.type === 'number';

  return (
    <Input
      {...register(name, {
        setValueAs: isNumber ? valueAsNumber : undefined,
        disabled: props.isDisabled,
      })}
      {...props}
    />
  );
};
