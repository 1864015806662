import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Id } from '@m3ter-com/m3ter-api';

import {
  loadServiceUserCredentials,
  makeServiceUserCredentialActive,
  makeServiceUserCredentialInactive,
  removeServiceUserCredential,
  reset,
  selectServiceUserCredentials,
  selectIsLoadingCredentials,
  createServiceUserCredential,
  selectServiceUserCredentialsError,
  selectNewCredential,
  clearServiceUserCredential,
} from '@/store/features/access/service-user/serviceUserCredentials';

const useServiceUserCredentials = (serviceUserId: Id) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadServiceUserCredentials(serviceUserId));

    return () => {
      dispatch(reset());
    };
  }, [dispatch, serviceUserId]);

  // APIKeys
  const onMakeServiceUserCredentialActive = useCallback(
    (apiKey: string) => {
      dispatch(makeServiceUserCredentialActive(serviceUserId, apiKey));
    },
    [dispatch, serviceUserId]
  );

  const onMakeServiceUserCredentialInactive = useCallback(
    (apiKey: string) => {
      dispatch(makeServiceUserCredentialInactive(serviceUserId, apiKey));
    },
    [dispatch, serviceUserId]
  );

  const onRemoveServiceUserCredential = useCallback(
    (apiKey: string) => {
      dispatch(removeServiceUserCredential(serviceUserId, apiKey));
    },
    [dispatch, serviceUserId]
  );

  const reloadServiceUserCredentials = useCallback(() => {
    dispatch(loadServiceUserCredentials(serviceUserId));
  }, [dispatch, serviceUserId]);

  const createCredential = useCallback(() => {
    dispatch(createServiceUserCredential(serviceUserId));
  }, [dispatch, serviceUserId]);

  const clearCredential = useCallback(() => {
    dispatch(clearServiceUserCredential());
  }, [dispatch]);

  const serviceUserCredentials = useSelector(selectServiceUserCredentials);
  const serviceUserCredentialsError = useSelector(
    selectServiceUserCredentialsError
  );
  const serviceUserCredentialsLoading = useSelector(selectIsLoadingCredentials);
  const newCredential = useSelector(selectNewCredential);

  return {
    createCredential,
    clearCredential,
    onMakeServiceUserCredentialActive,
    onMakeServiceUserCredentialInactive,
    onRemoveServiceUserCredential,
    reloadServiceUserCredentials,
    newCredential,
    serviceUserCredentials,
    serviceUserCredentialsError,
    serviceUserCredentialsLoading,
  };
};

export default useServiceUserCredentials;
