import { create, enforce, omitWhen, test } from 'vest';
import i18next from 'i18next';

import { OrganizationAdmin } from '@m3ter-com/m3ter-api';
import { FormValidationContext } from '@m3ter-com/console-core/components';

import { OrganizationAdminFormExtraData } from '@/components/features/admin/organizations/OrganizationAdminForm';

const suite = create(
  'organizationAdmin',
  (
    data: Partial<OrganizationAdmin>,
    context: FormValidationContext<{}, OrganizationAdminFormExtraData>
  ) => {
    const { isEdit } = context.extraData;
    omitWhen(isEdit, () => {
      test(
        'ownerId',
        i18next.t('forms:validations.admin.organizationOwnerRequired'),
        () => {
          enforce(data.ownerId).isNotEmpty();
        }
      );
    });

    test(
      'organizationName',
      i18next.t('forms:validations.common.organizationNameRequired'),
      () => {
        enforce(data.organizationName).isNotEmpty();
      }
    );

    test(
      'shortName',
      i18next.t('forms:validations.admin.shortNameRequired'),
      () => {
        enforce(data.shortName).isNotEmpty();
      }
    );

    test(
      'addressLine1',
      i18next.t('forms:validations.common.addressLine1Required'),
      () => {
        enforce(data.addressLine1).isNotEmpty();
      }
    );

    test(
      'addressLine2',
      i18next.t('forms:validations.common.addressLine2Required'),
      () => {
        enforce(data.addressLine2).isNotEmpty();
      }
    );

    test(
      'postCode',
      i18next.t('forms:validations.common.postCodeRequired'),
      () => {
        enforce(data.postCode).isNotEmpty();
      }
    );

    test(
      'country',
      i18next.t('forms:validations.common.countryRequired'),
      () => {
        enforce(data.country).isNotEmpty();
      }
    );

    omitWhen(isEdit, () => {
      test(
        'customerId',
        i18next.t('forms:validations.admin.customerRequired'),
        () => {
          enforce(data.customerId).isNotEmpty();
        }
      );
    });
  }
);

export default suite;
