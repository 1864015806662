import i18next from 'i18next';

import { DataType, CreditReason } from '@m3ter-com/m3ter-api';

import { EntityRouteListIds } from '@/types/lists';

import { createPickListRoute } from '@/routes/picklist';

export default () =>
  createPickListRoute<CreditReason>({
    path: 'credit-reasons',
    dataType: DataType.CreditReason,
    title: i18next.t('common:creditReasons'),
    listId: EntityRouteListIds.CreditReason,
  });
