import React, { ReactNode } from 'react';

import { Params } from 'react-router-dom';

import { DataType, DataTypeToEntity, Id } from '@m3ter-com/m3ter-api';
import { KeyValue } from '@m3ter-com/ui-components';

import { ReferenceLink } from '@/components/common/data/ReferenceLink';
import { Accessor } from '@/util/data';

// Enforce a defualt value is passed as a prop when id can be undefined.
type KeyValueReferenceLinkConditionalProps =
  | { id?: Id; defaultValue: ReactNode }
  | { id: Id; defaultValue?: never };

interface KeyValueReferenceLinkRequiredProps<DT extends DataType> {
  label: string;
  dataType: DT;
  accessor: Accessor<DataTypeToEntity[DT], ReactNode>;
  params?: Params;
}

export type KeyValueReferenceLinkProps<DT extends DataType> =
  KeyValueReferenceLinkRequiredProps<DT> &
    KeyValueReferenceLinkConditionalProps;

export const KeyValueReferenceLink = <DT extends DataType>({
  label,
  id,
  dataType,
  accessor,
  params,
  defaultValue,
}: KeyValueReferenceLinkProps<DT>) => (
  <KeyValue
    label={label}
    value={
      id ? (
        <ReferenceLink
          id={id}
          dataType={dataType}
          params={params}
          accessor={accessor}
        />
      ) : (
        defaultValue
      )
    }
  />
);
