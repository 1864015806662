import React from 'react';

import { PickListEntity, UnsavedEntity } from '@m3ter-com/m3ter-api';
import { FormStack } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Form,
  FormActions,
  FormField,
  FormSection,
  FormSwitch,
  NameCodeFields,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

export interface PickListFormProps
  extends BaseFormProps<UnsavedEntity<PickListEntity>> {}

const defaultInitialValues: Partial<PickListEntity> = {};

export const PickListForm: React.FC<PickListFormProps> = ({
  onSave,
  onCancel,
  isSaving = false,
  isEdit = false,
  entityName,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();

  return (
    <Form onSubmit={onSave} initialValues={initialValues}>
      <FormStack>
        <FormSection heading={t('common:entityDetails', { entityName })}>
          <NameCodeFields isCodeFieldReadOnly={isEdit} />
          <FormField
            name="archived"
            label={t('forms:labels.archived')}
            control={FormSwitch}
            helpText={t('forms:helpText.archivePickListEntity', {
              entityName,
            })}
          />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={
            isEdit
              ? t('forms:buttons.updateEntity', { entityName })
              : t('forms:buttons.createEntity', { entityName })
          }
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
