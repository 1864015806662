import React from 'react';

import { Spinner, Text, VStack } from '@chakra-ui/react';

import { Meter } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { IngestSubmitter } from '@/components/features/meters/IngestSubmitter';
import { InlineDocs } from '@/components/common/docs/InlineDocs';

export interface AccountIngestModalStepTwoProps {
  accountCode: string;
  selectedMeter?: Meter | undefined;
  onBack: () => void;
  onCancel: () => void;
}

export const AccountIngestModalStepTwo: React.FC<
  AccountIngestModalStepTwoProps
> = ({ accountCode, selectedMeter, onBack, onCancel }) => {
  const { t } = useTranslation();

  return selectedMeter ? (
    <VStack alignItems="flex-start" spacing={4}>
      <InlineDocs id="account-submit-usage">
        <Text whiteSpace="pre-wrap">
          {t('features:account.usageSubmissionDescription')}
        </Text>
      </InlineDocs>
      <IngestSubmitter
        accountCode={accountCode}
        meter={selectedMeter}
        onCancel={onCancel}
        onBack={onBack}
      />
    </VStack>
  ) : (
    <Spinner />
  );
};
