import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const input: ComponentMultiStyleConfig = {
  parts: ['field', 'addon'],
  baseStyle: {},
  variants: {
    outline: {
      field: {
        color: 'text-light',
        bg: 'white',
        _placeholder: {
          color: 'text-subtle-light',
        },
      },
    },
  },
};

export default input;
