import React from 'react';

import { DataType, Alert } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { AlertsRoute } from '@/components/routes/alerts/AlertsRoute';
import { AlertsListRoute } from '@/components/routes/alerts/AlertsListRoute';

export default () =>
  createCrudRoute<Alert>({
    path: 'alerts',
    dataType: DataType.Alert,
    element: <AlertsRoute />,
    list: <AlertsListRoute />,
  });
