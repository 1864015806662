import {
  call,
  put,
  select,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';

import { getRecentEntities, setRecentEntities } from '@/util/localStorage';
import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';

import {
  addToRecentEntities,
  setRecentEntities as setRecentEntitiesAction,
  restoreRecentEntities,
  selectAllRecentEntityIds,
  RecentEntities,
  RestoreRecentEntitiesAction,
} from './page';

export function* addToRecentEntitiesSaga(): Generator<StrictEffect, void, any> {
  const organizationId = yield select(selectCurrentOrgId);
  const currentRecentEntities = yield select(selectAllRecentEntityIds);
  yield call(setRecentEntities, organizationId, currentRecentEntities);
}

export function* restoreRecentEntitiesSaga(
  action: RestoreRecentEntitiesAction
): Generator<StrictEffect, void, any> {
  const savedRecentEntities: RecentEntities | null = yield call(
    getRecentEntities,
    action.payload.organizationId
  );
  if (savedRecentEntities) {
    yield put(setRecentEntitiesAction(savedRecentEntities));
  }
}

export default function* pageSaga() {
  yield takeLatest(addToRecentEntities.type, addToRecentEntitiesSaga);
  yield takeLatest(restoreRecentEntities.type, restoreRecentEntitiesSaga);
}
