import {
  all,
  call,
  put,
  select,
  StrictEffect,
  take,
  takeLatest,
} from 'redux-saga/effects';

import {
  DataType,
  listAll,
  Organization,
  retrieve,
} from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';
import { PreferenceName } from '@/types/preferences';

import { ROOT_ORG_PATH } from '@/routes/organization';
import { extractError } from '@/util/error';
import { setPreference } from '@/util/localStorage';
import {
  signInSuccess,
  restoreSession,
  SignInSuccessAction,
} from '@/store/app/auth/auth';
import { dataLoaded, singletonLoaded } from '@/store/data/data';
import { listAllData } from '@/store/data/data.saga';
import { restoreRecentEntities } from '@/store/page/page';

import {
  bootstrapApp,
  bootstrapFailure,
  bootstrapSuccess,
  changeOrg,
  isBootstrapSuccessActionWithOrgId,
  selectAllOrgs,
  BootstrapFailureReason,
  BootstrapSuccessAction,
  ChangeOrgAction,
} from './bootstrap';

const URL_ORG_ID_REGEX = new RegExp(`/${ROOT_ORG_PATH}/([^/]*)`);

const isOrgIdValid = (orgId: string, availableOrgs: Array<Organization>) =>
  availableOrgs.some((org) => org.id === orgId);

// This saga should be used to load all data that is required before we can render
// the console. E.g. the OrganizationConfig is needed in order to render accurate dates
// / functional date pickers.
export function* loadRequiredOrgDataSaga(
  organizationId: string
): Generator<StrictEffect, void, any> {
  const [orgConfig, billConfig] = yield all([
    call(retrieve, {
      dataType: DataType.OrganizationConfig,
      pathParams: { organizationId },
    }),
    call(retrieve, {
      dataType: DataType.BillConfig,
      pathParams: { organizationId },
    }),
  ]);
  yield put(singletonLoaded(DataType.OrganizationConfig, orgConfig));
  yield put(singletonLoaded(DataType.BillConfig, billConfig));
  yield put(restoreRecentEntities(organizationId));
}

// This saga should be used to load all data that is often used within the console but
// is not essential. E.g. currencies are required to add plans but not to create meters.
export function* loadOptionalOrgDataSaga(
  organizationId: string
): Generator<StrictEffect, void, any> {
  const [currencies, products] = yield all([
    call(listAll, {
      dataType: DataType.Currency,
      pathParams: { organizationId },
    }),
    call(listAll, {
      dataType: DataType.Product,
      pathParams: { organizationId },
    }),
  ]);
  yield put(dataLoaded(DataType.Currency, currencies.data));
  yield put(dataLoaded(DataType.Product, products.data));
}

export function* bootstrapAppSaga(): Generator<StrictEffect, void, any> {
  // Kick off session restoration. If this fails, the auth sagas will handle redirecting to the
  // sign-in screen.
  yield put(restoreSession());
  // Wait for sign in to be completed. This also gives us access to the user so we can check support
  // user status.
  const signInSuccessAction: SignInSuccessAction = yield take(
    signInSuccess.type
  );

  // Attempt to load any standard orgs the user has accesss to.
  const minorErrors = new Array<AppError>();
  try {
    const standardOrgs = yield call(listAllData, DataType.Organization);
    if (standardOrgs.length === 0) {
      // If they don't have access to any orgs, dispatch an action
      // that tells us that.
      yield put(bootstrapFailure(BootstrapFailureReason.NoOrgsAvailable));
      return;
    }
  } catch (error) {
    // If this fails, they can't do anything in the console, dispatch a
    // fatal error action.
    yield put(
      bootstrapFailure(BootstrapFailureReason.FatalError, extractError(error))
    );
    return;
  }

  // Check if the user is a support user. If they are, attempt to load
  // any support orgs they have access to.
  // If this fails, they can still use the console so we track it as a
  // minor error.
  try {
    if (signInSuccessAction.payload.user.supportUser) {
      yield call(listAllData, DataType.SupportOrganization);
    }
  } catch (error) {
    minorErrors.push(extractError(error));
  }

  // Figure out what the initial org ID should be.
  let initialOrgId = '';
  const allOrgs = yield select(selectAllOrgs);
  // eslint-disable-next-line no-useless-escape
  const [_, urlOrgId] = URL_ORG_ID_REGEX.exec(window.location.pathname) || [];
  if (urlOrgId) {
    // If the user is trying to access a specific org, use that ID and check if it
    // they have access to that org.
    // If they do, all good. If not, we want to tell them that they can't access that
    // org or it doesn't exist.
    if (isOrgIdValid(urlOrgId, allOrgs)) {
      initialOrgId = urlOrgId;
    } else {
      yield put(
        bootstrapFailure(
          BootstrapFailureReason.InvalidOrgId,
          undefined,
          urlOrgId!
        )
      );
      return;
    }
  } else if (allOrgs.length === 1) {
    // If they're not trying to access a specific org, we want to direct them to the
    // org selection page so we leave the initialOrgId unset. Unless they only have
    // access to one org. If that's the case, we may as well just send them straight
    // into that org.
    initialOrgId = allOrgs[0].id;
  }

  if (initialOrgId) {
    // Load any data that the console needs to be initialised. If this fails, the console
    // can't be used so we dispatch an action that tells us that.
    try {
      yield call(loadRequiredOrgDataSaga, initialOrgId!);
    } catch (error) {
      yield put(
        bootstrapFailure(
          BootstrapFailureReason.OrgDataLoadingFailure,
          extractError(error),
          initialOrgId
        )
      );
      return;
    }

    // Load any data that is used repeatedly (e.g. products, currencies) but that isn't strictly
    // required for all areas of the console. If it fails, the user can still use the console.
    try {
      yield call(loadOptionalOrgDataSaga, initialOrgId!);
    } catch (error) {
      // No-op
    }
  }

  yield put(bootstrapSuccess(initialOrgId!, minorErrors));
}

export function* changeOrgSaga(
  action: ChangeOrgAction
): Generator<StrictEffect, void, any> {
  const newOrgId = action.payload.orgId;
  // Check if the new org ID is one that the user can access.
  const allOrgs: Array<Organization> = yield select(selectAllOrgs);
  const isOrgValid = allOrgs.some((org) => org.id === newOrgId);
  if (!isOrgValid) {
    yield put(
      bootstrapFailure(BootstrapFailureReason.InvalidOrgId, undefined, newOrgId)
    );
  }

  // Load any data that the console needs to be initialised. If this fails, the console
  // can't be used so we dispatch an action that tells us that.
  try {
    yield call(loadRequiredOrgDataSaga, newOrgId);
  } catch (error) {
    yield put(
      bootstrapFailure(
        BootstrapFailureReason.OrgDataLoadingFailure,
        extractError(error),
        newOrgId
      )
    );
    return;
  }

  // Load any data that is used repeatedly (e.g. products, currencies) but that isn't strictly
  // required for all areas of the console. If it fails, the user can still use the console.
  try {
    yield call(loadOptionalOrgDataSaga, newOrgId);
  } catch (error) {
    // No-op
  }
  yield put(bootstrapSuccess(newOrgId, []));
}

// Persist any org ID that lead to a successful initialisation to localStorage.
export function* persistSelectedOrgIdSaga(
  action: BootstrapSuccessAction
): Generator<StrictEffect, void, any> {
  yield call(
    setPreference,
    PreferenceName.LAST_ACCESSED_ORG_ID,
    action.payload.orgId
  );
}

export default function* appSaga() {
  yield takeLatest(bootstrapApp.type, bootstrapAppSaga);
  yield takeLatest(changeOrg.type, changeOrgSaga);
  yield takeLatest(isBootstrapSuccessActionWithOrgId, persistSelectedOrgIdSaga);
}
