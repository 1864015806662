import React from 'react';

import { DataType, Product } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Form,
  FormActions,
  FormSection,
  NameCodeFields,
} from '@m3ter-com/console-core/components';
import { FormStack } from '@m3ter-com/ui-components';

import { FormCustomFieldsEditorDefaults } from '@/components/forms/FormCustomFieldsEditorDefaults';

export interface ProductFormProps {
  onSave: (item: Product) => void;
  onCancel: () => void;
  isSaving?: boolean;
  isEdit?: boolean;
  initialValues?: Product;
}

export const ProductForm: React.FC<ProductFormProps> = ({
  onSave,
  onCancel,
  isSaving = false,
  isEdit = false,
  initialValues = {},
}) => {
  const { t } = useTranslation();
  const entityName = t('common:product');

  return (
    <Form onSubmit={onSave} initialValues={initialValues}>
      <FormStack>
        <FormSection heading={t('common:entityDetails', { entityName })}>
          <NameCodeFields />
        </FormSection>
        <FormCustomFieldsEditorDefaults dataType={DataType.Product} />
        <FormActions
          cancelText={t('common:cancel')}
          submitText={
            isEdit
              ? t('forms:buttons.updateEntity', { entityName })
              : t('forms:buttons.createEntity', { entityName })
          }
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
