import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { CurrencyInput, CurrencyInputProps } from '@m3ter-com/ui-components';

export interface FormCurrencyInputProps extends CurrencyInputProps {
  name: string;
}

export const FormCurrencyInput: React.FC<FormCurrencyInputProps> = ({
  name,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <CurrencyInput value={value} onChange={onChange} {...props} />
      )}
    />
  );
};
