import i18next from 'i18next';
import isEqual from 'lodash/isEqual';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';

import {
  AggregationSegment,
  CurrencyCode,
  CounterPricing,
  Pricing,
  PricingBand,
  PricingCommon,
  SegmentedAggregation,
  SegmentedCompoundAggregation,
} from '@m3ter-com/m3ter-api';

import { getDatesSortOrder } from './date';
import { formatNumber } from './number';

export enum PricingType {
  Tiered = 'TIERED',
  Volume = 'VOLUME',
  Stairstep = 'STAIRSTEP',
  CustomTiered = 'CUSTOM_TIERED',
  CustomVolume = 'CUSTOM_VOLUME',
}

export enum PricingActivityStatus {
  Active = 'active',
  Future = 'future',
  Historic = 'historic',
}

export interface PricingByPlanOrPlanTemplate<P extends PricingCommon> {
  [planOrPlanTemplateId: string]: Array<P>;
}

export interface GroupedPricing<P extends PricingCommon> {
  [usageEntityId: string]: PricingByPlanOrPlanTemplate<P>;
}

export interface CollatedPricingEntry {
  status: PricingActivityStatus;
  pricing: Pricing | CounterPricing | null;
}

export type CollatedPricing = Array<CollatedPricingEntry>;

type CurrencyFormatter = (
  value: number,
  currency: CurrencyCode,
  precise?: boolean
) => string;

const pricingTypesWithUnitPrice: Array<PricingType> = [
  PricingType.Tiered,
  PricingType.Volume,
  PricingType.CustomVolume,
  PricingType.CustomTiered,
];

const pricingTypesWithFixedPrice: Array<PricingType> = [
  PricingType.Stairstep,
  PricingType.CustomVolume,
  PricingType.CustomTiered,
];

const pricingTypesCumulative: Array<PricingType> = [
  PricingType.Tiered,
  PricingType.CustomTiered,
];

export const isPricing = (pricing: PricingCommon): pricing is Pricing =>
  Object.hasOwn(pricing, 'type');

export const isTransactionPricing = (pricing: PricingCommon) =>
  pricing.pricingBands &&
  pricing.pricingBands.some((band) => !!band.percentagePrice);

/**
 * Gets the pricing type for a Pricing data object based on cumulative
 * flag and the pricing bands.
 *
 * Tiered - cumulative = true, all bands only have unitPrice
 * Volume - cumulative = false, all bands only have unitPrice
 * Stairstep - cumulative = false, all bands only have fixedPrice
 * CustomTiered - cumulative = true, bands are a mixture of unitPrice / fixedPrice or both 0
 * CustomVolume - cumulative = false, bands are a mixture of unitPrice / fixedPrice or both 0
 * Unknown - none of the above or no pricing bands
 */
export const getPricingType = (pricing: PricingCommon): PricingType => {
  const { pricingBands = [] } = pricing;
  const hasAnyPricePerUnit = pricingBands.some((band) => band.unitPrice !== 0);
  const hasAnyFixedPrice = pricingBands.some((band) => band.fixedPrice !== 0);

  if (isTransactionPricing(pricing)) {
    // Only Tiered and Volume make sense for per-transaction pricing.
    return pricing.cumulative ? PricingType.Tiered : PricingType.Volume;
  }

  if (pricing.cumulative) {
    if (hasAnyPricePerUnit && !hasAnyFixedPrice) {
      return PricingType.Tiered;
    }
    return PricingType.CustomTiered;
  }

  if (hasAnyPricePerUnit && !hasAnyFixedPrice) {
    return PricingType.Volume;
  }

  if (hasAnyFixedPrice && !hasAnyPricePerUnit) {
    return PricingType.Stairstep;
  }

  return PricingType.CustomVolume;
};

/**
 * Takes a PricingType and returns a translated user-friendly string
 */
export const formatPricingType = (pricingType: PricingType): string =>
  i18next.t(`features:pricing.pricingTypes.${pricingType}`);

/**
 * Takes a Pricing object and returns it's PricingType as a translated,
 * user-friendly string
 */
export const getFormattedPricingType = (pricing: PricingCommon): string => {
  const pricingType = getPricingType(pricing);
  return formatPricingType(pricingType);
};

/**
 * Takes the a pricing band and, optionally, the one that follows and uses them to build
 * a summary string that describes that particular pricing band range
 */
export const getBandRangeDescription = (
  pricingBand: PricingBand,
  nextPricingBand?: PricingBand
): string => {
  let result = i18next.t('features:pricing.bandRangeLower', {
    lowerLimit: formatNumber(pricingBand.lowerLimit),
  });
  if (nextPricingBand)
    result += i18next.t('features:pricing.bandRangeUpper', {
      upperLimit: formatNumber(nextPricingBand.lowerLimit),
    });

  return result;
};

/**
 * Takes a pricing band and currency code and returns a string describing the
 * pricing of that particular band
 */
export const getBandPriceDescription = (
  pricingBand: PricingBand,
  currencyCode: string,
  formatter: CurrencyFormatter
): string => {
  // Need to use band prices could be very small and we need
  // to display an accurate number.
  return pricingBand.fixedPrice !== 0
    ? formatter(pricingBand.fixedPrice, currencyCode, true)
    : i18next.t('features:pricing.bandPricePerUnit', {
        price: formatter(pricingBand.unitPrice, currencyCode, true),
      });
};

export const getTransactionBandPriceDescription = (
  pricingBand: PricingBand,
  currencyCode: string,
  formatter: CurrencyFormatter
): string => {
  return `${formatter(pricingBand.fixedPrice, currencyCode, true)} + ${
    pricingBand.percentagePrice
  }%`;
};

export const getPricingRange = (
  pricingBands: Array<PricingBand>,
  currencyCode: string,
  formatter: CurrencyFormatter
): string => {
  const hasAnyPricePerUnit = pricingBands.some((band) => band.unitPrice !== 0);

  const prices = pricingBands
    .map((band) => (hasAnyPricePerUnit ? band.unitPrice : band.fixedPrice))
    .sort((a, b) => a - b);

  // Return a min–max range.
  const range = `${formatter(prices[0], currencyCode, true)}–${formatter(
    prices[prices.length - 1],
    currencyCode,
    true
  )}`;

  return hasAnyPricePerUnit
    ? i18next.t('features:pricing.bandPricePerUnit', {
        price: range,
      })
    : range;
};

/**
 * Returns a one-line summary of pricing. Where possible it includes a price range.
 * For example, 'Tiered: $0.10–$0.50'
 */
export const getPricingDescription = (
  pricing: PricingCommon,
  currencyCode: string,
  formatter: CurrencyFormatter
): string => {
  const type = getPricingType(pricing);
  const typeDescription = i18next.t(`features:pricing.pricingTypes.${type}`);

  const { pricingBands = [] } = pricing;

  // We can't describe empty pricing.
  if (pricingBands.length === 0) {
    return '-';
  }

  // For custom pricing we can't guarantee showing anything sensible.
  if (type === PricingType.CustomTiered || type === PricingType.CustomVolume) {
    return typeDescription;
  }

  // For a single band show either the unit or fixed price.
  if (pricingBands.length === 1) {
    const band = pricingBands[0];
    return band.fixedPrice !== 0
      ? formatter(band.fixedPrice, currencyCode)
      : i18next.t('features:pricing.bandPricePerUnit', {
          price: formatter(band.unitPrice, currencyCode, true),
        });
  }

  // For more than 1 band, show the type and range.
  return `${typeDescription}: ${getPricingRange(
    pricingBands,
    currencyCode,
    formatter
  )}`;
};

export const isFuture = (pricing: PricingCommon): boolean => {
  const now = new Date();
  const start = new Date(pricing.startDate);
  return start > now;
};

export const isPast = (pricing: PricingCommon): boolean => {
  if (pricing.endDate) {
    const now = new Date();
    const end = new Date(pricing.endDate);
    return end < now;
  }
  return false;
};

export const isActive = (pricing: PricingCommon): boolean => {
  return !isPast(pricing) && !isFuture(pricing);
};

/**
 * Groups pricing by relevant ID (aggregation, compound aggregation, or item counter) then by plan (template) ID.
 */
export const getGroupedPricings = <P extends Pricing | CounterPricing>(
  allPricings: Array<P>
): GroupedPricing<P> => {
  const pricingByRelevantId = groupBy(allPricings, (pricing) => {
    if (isPricing(pricing)) {
      return pricing.aggregationId ?? pricing.compoundAggregationId;
    }
    return pricing.counterId;
  });

  return mapValues(pricingByRelevantId, (value) =>
    groupBy(value, (pricing) => pricing.planId ?? pricing.planTemplateId)
  );
};

/**
 * Takes a set of pricings and a corresponding segmented aggregation.
 * Returns all the pricings that are tied to a segment that exists on the aggregation.
 * This is needed because it is possible to fetch pricings that are tied to segments that
 * have since been removed from the aggregation.
 */
export const getValidSegmentedPricings = (
  aggregation: SegmentedAggregation | SegmentedCompoundAggregation,
  pricings: Array<Pricing>
): Array<Pricing> =>
  pricings.filter((pricing) => {
    if (!pricing.segment) {
      return false;
    }

    return aggregation.segments.some((aggregationSegment) =>
      isEqual(pricing.segment, aggregationSegment)
    );
  });

/**
 * Takes a set of pricings and an aggregation segment.
 * Returns the first pricing that is tied to a segment that exists on the aggregation.
 * This is needed because it is possible to fetch pricings that are tied to segments that
 * have since been removed from the aggregation.
 */
export const getFirstValidSegmentedPricing = (
  segment: AggregationSegment,
  pricings: Array<Pricing>
): Pricing | undefined =>
  pricings.find((pricing) => {
    if (!pricing.segment) {
      return false;
    }

    return isEqual(pricing.segment, segment);
  });

/**
 * Returns whether there are any configured bands other than the default 1st one.
 */
export const hasConfiguredBands = (pricingBands: Array<PricingBand>): boolean =>
  pricingBands.length > 1 ||
  (pricingBands.length === 1 &&
    (pricingBands[0].fixedPrice !== 0 || pricingBands[0].unitPrice !== 0));

export const hasUnitPrice = (pricingType: PricingType): boolean =>
  pricingTypesWithUnitPrice.includes(pricingType);
export const hasFixedPrice = (pricingType: PricingType): boolean =>
  pricingTypesWithFixedPrice.includes(pricingType);

export const convertPricingBands = (
  pricingBands: Array<PricingBand>,
  type: PricingType,
  newType: PricingType
): Array<PricingBand> => {
  // Switching to / from tiered/volume and stairstep is the only time we need to
  // reset any pricing data.
  if (
    (type === PricingType.Tiered || type === PricingType.Volume) &&
    newType === PricingType.Stairstep
  ) {
    // Reset all unitPrice to 0
    return pricingBands.map((band) => ({
      ...band,
      unitPrice: 0,
    }));
  }

  if (
    type === PricingType.Stairstep &&
    (newType === PricingType.Tiered || newType === PricingType.Volume)
  ) {
    // Reset all fixedPrice to 0
    return pricingBands.map((band) => ({
      ...band,
      fixedPrice: 0,
    }));
  }

  // No need to change anything.
  return pricingBands;
};

export const isPlanPricing = (pricing: PricingCommon): boolean =>
  !!pricing.planId;

export const isPlanTemplatePricing = (pricing: PricingCommon): boolean =>
  !!pricing.planTemplateId;

export const combineAndSortPricing = <P extends Pricing | CounterPricing>(
  ...pricings: Array<Array<P>>
): Array<P> =>
  new Array<P>()
    .concat(...pricings)
    .sort((a, b) => getDatesSortOrder(a.startDate, b.startDate));

export const collatePricing = <P extends Pricing | CounterPricing>(
  pricings: Array<P>
): CollatedPricing => {
  const collated: CollatedPricing = pricings.map((pricing) => {
    // eslint-disable-next-line no-nested-ternary
    const status = isFuture(pricing)
      ? PricingActivityStatus.Future
      : isPast(pricing)
      ? PricingActivityStatus.Historic
      : PricingActivityStatus.Active;
    return { status, pricing };
  });

  if (!collated.some(({ status }) => status === PricingActivityStatus.Active)) {
    // We need to always include a placeholder for active pricing.
    let lastPastPricingIndex = -1;
    collated.forEach(({ status }, index) => {
      if (status === PricingActivityStatus.Historic) {
        lastPastPricingIndex = index;
      }
    });
    collated.splice(lastPastPricingIndex + 1, 0, {
      status: PricingActivityStatus.Active,
      pricing: null,
    });
  }

  return collated;
};

export const isCumulative = (pricingType: PricingType) =>
  pricingTypesCumulative.includes(pricingType);
