import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataType, Id } from '@m3ter-com/m3ter-api';

import type {
  CompletionMeta,
  NotificationDefinition,
  RequestMeta,
} from '@/store/store';

const name = 'utils/linkEntity';

export type LinkEntityState = Record<
  string,
  { isLinkingEntity: boolean; isUnlinkingEntity: boolean } | undefined
>;

const initialState: LinkEntityState = {};

interface BaseEntityLinkPayload {
  childDataType: DataType;
  parentDataType: DataType;
}

interface AddEntityLinkPayload extends BaseEntityLinkPayload {
  childIds: Array<Id>;
  parentId: Id | null;
  orgId?: Id;
}

export type AddEntityLinkAction = PayloadAction<
  AddEntityLinkPayload,
  string,
  RequestMeta
>;
export type AddEntityLinkSuccessAction = PayloadAction<
  BaseEntityLinkPayload,
  string,
  CompletionMeta
>;
export type AddEntityLinkFailureAction = PayloadAction<
  BaseEntityLinkPayload,
  string,
  CompletionMeta
>;

interface RemoveEntityLinkPayload extends BaseEntityLinkPayload {
  childId: Id | null;
  parentIds: Array<Id> | null;
}

export type RemoveEntityLinkAction = PayloadAction<
  RemoveEntityLinkPayload,
  string,
  RequestMeta
>;
export type RemoveEntityLinkSuccessAction = PayloadAction<
  BaseEntityLinkPayload,
  string,
  CompletionMeta
>;
export type RemoveEntityLinkFailureAction = PayloadAction<
  BaseEntityLinkPayload,
  string,
  CompletionMeta
>;

export const getLinkEntityStateKey = (
  parentDataType: DataType,
  childDataType: DataType
) => `${parentDataType}-${childDataType}`;

const getLinkEntityState = (
  state: LinkEntityState,
  action: PayloadAction<BaseEntityLinkPayload>
) => {
  const stateKey = getLinkEntityStateKey(
    action.payload.parentDataType,
    action.payload.childDataType
  );

  if (!state[stateKey]) {
    state[stateKey] = {
      isLinkingEntity: false,
      isUnlinkingEntity: false,
    };
  }

  return state[stateKey]!;
};

const linkEntitySlice = createSlice({
  name,
  initialState,
  reducers: {
    addEntityLink: {
      reducer: (state: LinkEntityState, action: AddEntityLinkAction) => {
        const dataTypeState = getLinkEntityState(state, action);
        dataTypeState.isLinkingEntity = true;
      },
      prepare: (
        parentDataType: DataType,
        parentId: Id | null,
        childDataType: DataType,
        childIds: Array<Id>,
        successNotification?: NotificationDefinition,
        failureNotification?: NotificationDefinition,
        orgId?: Id
      ) => ({
        payload: { parentDataType, parentId, childDataType, childIds, orgId },
        meta: {
          onSuccess: {
            notification: successNotification,
          },
          onFailure: {
            notification: failureNotification,
          },
        },
      }),
    },
    addEntityLinkSuccess: {
      reducer: (state: LinkEntityState, action: AddEntityLinkSuccessAction) => {
        const dataTypeState = getLinkEntityState(state, action);
        dataTypeState.isLinkingEntity = false;
      },
      prepare: (
        parentDataType: DataType,
        childDataType: DataType,
        meta: CompletionMeta
      ) => ({
        payload: { parentDataType, childDataType },
        meta,
      }),
    },
    addEntityLinkFailure: {
      reducer: (state: LinkEntityState, action: AddEntityLinkFailureAction) => {
        const dataTypeState = getLinkEntityState(state, action);
        dataTypeState.isLinkingEntity = false;
      },
      prepare: (
        parentDataType: DataType,
        childDataType: DataType,
        meta: CompletionMeta
      ) => ({
        payload: { parentDataType, childDataType },
        meta,
      }),
    },
    removeEntityLink: {
      reducer: (state: LinkEntityState, action: RemoveEntityLinkAction) => {
        const dataTypeState = getLinkEntityState(state, action);
        dataTypeState.isUnlinkingEntity = true;
      },
      prepare: (
        parentDataType: DataType,
        parentIds: Array<Id> | null,
        childDataType: DataType,
        childId: Id | null,
        successNotification?: NotificationDefinition,
        failureNotification?: NotificationDefinition
      ) => ({
        payload: { parentDataType, parentIds, childDataType, childId },
        meta: {
          onSuccess: {
            notification: successNotification,
          },
          onFailure: {
            notification: failureNotification,
          },
        },
      }),
    },
    removeEntityLinkSuccess: {
      reducer: (
        state: LinkEntityState,
        action: RemoveEntityLinkSuccessAction
      ) => {
        const dataTypeState = getLinkEntityState(state, action);
        dataTypeState.isUnlinkingEntity = false;
      },
      prepare: (
        parentDataType: DataType,
        childDataType: DataType,
        meta: CompletionMeta
      ) => ({
        payload: { parentDataType, childDataType },
        meta,
      }),
    },
    removeEntityLinkFailure: {
      reducer: (
        state: LinkEntityState,
        action: RemoveEntityLinkFailureAction
      ) => {
        const dataTypeState = getLinkEntityState(state, action);
        dataTypeState.isUnlinkingEntity = false;
      },
      prepare: (
        parentDataType: DataType,
        childDataType: DataType,
        meta: CompletionMeta
      ) => ({
        payload: { parentDataType, childDataType },
        meta,
      }),
    },
  },
});

export const {
  addEntityLink,
  addEntityLinkSuccess,
  addEntityLinkFailure,
  removeEntityLink,
  removeEntityLinkSuccess,
  removeEntityLinkFailure,
} = linkEntitySlice.actions;

const selectLinkEntityState = (state: {
  utils: { linkEntity: LinkEntityState };
}): LinkEntityState => state.utils.linkEntity;

const selectLinkEntityDataTypeState = (
  parentDataType: DataType,
  childDataType: DataType
) =>
  createSelector(selectLinkEntityState, (linkEntityState) => {
    const stateKey = getLinkEntityStateKey(parentDataType, childDataType);
    return linkEntityState[stateKey];
  });

export const selectIsLinkingEntity = (
  parentDataType: DataType,
  childDataType: DataType
) =>
  createSelector(
    selectLinkEntityDataTypeState(parentDataType, childDataType),
    (linkEntityState) => !!linkEntityState?.isLinkingEntity
  );

export const selectIsUnlinkingEntity = (
  parentDataType: DataType,
  childDataType: DataType
) =>
  createSelector(
    selectLinkEntityDataTypeState(parentDataType, childDataType),
    (linkEntityState) => !!linkEntityState?.isUnlinkingEntity
  );

export default linkEntitySlice.reducer;
