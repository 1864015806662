import React from 'react';

import { Link, Params } from 'react-router-dom';
import { forwardRef } from '@chakra-ui/react';

import useNamedLink from '@/hooks/navigation/useNamedLink';

export interface NamedLinkProps {
  name: string;
  params?: Params;
  queryParams?: Record<string, any>;
  disabled?: boolean;
}

// This component purposefully doesn't use the chakra <Link> component with an `as` prop, because we
// like to use it both with link and button styling in various places.
// This means it comes un-styled as standard and should be used to render a chakra Button or Link, using
// NamedLink as the `as` prop, to get the desired styling.
export const NamedLink = forwardRef<NamedLinkProps, 'a'>(
  ({ name, params, queryParams, children, ...rest }, ref) => {
    const link = useNamedLink(name, params, queryParams);

    // When used within a `Button` we get a `disabled` prop that can't be applied
    // to `a` elements. Instead, we render a `span`.
    const { disabled } = rest;

    return !disabled ? (
      <Link ref={ref} to={link} {...rest}>
        {children}
      </Link>
    ) : (
      <span ref={ref} {...rest}>
        {children}
      </span>
    );
  }
);
