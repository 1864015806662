import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { MeasurementsDeletion } from '@m3ter-com/m3ter-api';

const suite = create(
  'userAdmin',
  (data: Partial<MeasurementsDeletion & { orgName: string }>) => {
    test(
      'orgName',
      i18next.t('forms:validations.common.organizationNameRequired'),
      () => {
        enforce(data.orgName).isNotEmpty();
      }
    );

    test(
      'tsStart',
      i18next.t('forms:validations.common.startDateRequired'),
      () => {
        enforce(data.tsStart).isNotEmpty();
      }
    );

    test('tsEnd', i18next.t('forms:validations.common.endDateRequired'), () => {
      enforce(data.tsEnd).isNotEmpty();
    });
  }
);

export default suite;
