import { Entity } from '@m3ter-com/m3ter-api';

import { ListSearchComparator, ListSearchCriteria } from '@/types/lists';

import { Accessor, getAccessorValue } from '@/util/data';

export const getTitle = <T extends Entity>(
  item: T,
  accessor?: Accessor<T>
): string => {
  if (!item || !accessor) return '';
  return getAccessorValue(item, accessor);
};

export const createSearchCriteria = (
  query: string,
  searchFields: Array<string>
): ListSearchCriteria =>
  searchFields.reduce((acc, fieldName) => {
    acc[fieldName] = {
      comparator: ListSearchComparator.Contains,
      value: query,
    };
    return acc;
  }, {} as ListSearchCriteria);
