import React, { useCallback, useState } from 'react';

import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormField } from '@m3ter-com/console-core/components';

import {
  DataTypeFormEntitySelect,
  FormEntitySelect,
} from '@/components/forms/FormEntitySelect';

export interface TransactionalPricingFieldsProps {}

export const TransactionalPricingFields: React.FC<
  TransactionalPricingFieldsProps
> = () => {
  const { t } = useTranslation();

  const transactionPercentAggregationId = useWatch({
    name: 'transactionPercentAggregationId',
  });
  const transactionFixedAggregationId = useWatch({
    name: 'transactionFixedAggregationId',
  });
  const { setValue } = useFormContext();

  const [isEnabled, setIsEnabled] = useState(
    !!transactionPercentAggregationId && !!transactionFixedAggregationId
  );

  const onSwitchChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = ev.target;
      setIsEnabled(checked);
      if (!checked) {
        setValue('transactionPercentAggregationId', null);
        setValue('transactionFixedAggregationId', null);
      }
    },
    [setValue]
  );

  return (
    <React.Fragment>
      <FormControl display="flex" alignItems="center">
        <Switch onChange={onSwitchChange} isChecked={isEnabled} mr={2} />
        <FormLabel mb={0}>
          {t('forms:labels.enableTransactionPricing')}
        </FormLabel>
      </FormControl>
      <FormField
        name="transactionPercentAggregationId"
        label={t('forms:labels.transactionPercentAggregationId')}
        control={
          FormEntitySelect as DataTypeFormEntitySelect<DataType.Aggregation>
        }
        isClearable
        isDisabled={!isEnabled}
        dataType={DataType.Aggregation}
        accessor="name"
        detailAccessor="code"
      />
      <FormField
        name="transactionFixedAggregationId"
        label={t('forms:labels.transactionFixedAggregationId')}
        control={
          FormEntitySelect as DataTypeFormEntitySelect<DataType.Aggregation>
        }
        isClearable
        isDisabled={!isEnabled}
        dataType={DataType.Aggregation}
        accessor="name"
        detailAccessor="code"
      />
    </React.Fragment>
  );
};
