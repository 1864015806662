import {
  cancel,
  fork,
  race,
  take,
  ActionPattern,
  StrictEffect,
} from 'redux-saga/effects';

import type { AnyAction } from 'redux';
import type { Task } from 'redux-saga';

type AnySaga = (...args: Array<any>) => Generator<StrictEffect, any, any>;

export function* takeLatestWithCancelSaga(
  startPattern: ActionPattern,
  cancelPattern: ActionPattern,
  saga: AnySaga
): Generator<StrictEffect, void, any> {
  let lastTask: Task | undefined;

  while (true) {
    // Before doing anything, wait for an action matching the provided
    // startPattern or the provided cancelPattern.
    const [runSagaAction]: Array<AnyAction | undefined> = yield race([
      take(startPattern),
      take(cancelPattern),
    ]);

    // After recieving one of the actions from above, we always want to cancel
    // the previous task if one exists.
    // If the action is another action that matches the startPattern, we will
    // want to cancel the previous task. Same goes if it is an action that
    // matches the cancelPattern.
    if (lastTask) {
      yield cancel(lastTask);
    }

    // The race effect returns whichever of the actions we were waiting for was
    // dispatched first, the other will === undefined.
    // If we have recieved an action matching the startPattern, fork the provided
    // saga and pass the action. If not, let the loop start again so we can wait for
    // the next matching action.
    if (runSagaAction) {
      lastTask = yield fork(saga, runSagaAction);
    }
  }
}
export const takeLatestWithCancel = (
  startPattern: ActionPattern,
  cancelPattern: ActionPattern,
  saga: AnySaga
) => fork(takeLatestWithCancelSaga, startPattern, cancelPattern, saga);
