import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from '@chakra-ui/react';

import { selectBreadcrumbs } from '@/store/page/page';

export interface BreadcrumbsProps {}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = () => {
  const breadcrumbs = useSelector(selectBreadcrumbs);
  return breadcrumbs.length > 0 ? (
    <Breadcrumb>
      {breadcrumbs.map(({ to, title }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <BreadcrumbItem key={index}>
          {to ? (
            <BreadcrumbLink as={Link} to={to}>
              {title}
            </BreadcrumbLink>
          ) : (
            <Text>{title}</Text>
          )}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  ) : null;
};
