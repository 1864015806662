import {
  all,
  call,
  put,
  select,
  StrictEffect,
  takeEvery,
} from 'redux-saga/effects';
import i18next from 'i18next';

import { DataType, BillingEntity, Id } from '@m3ter-com/m3ter-api';

import { OtherListIds } from '@/types/lists';

import { extractError } from '@/util/error';
import { performDataAction } from '@/services/api';
import { listData } from '@/store/data/data.saga';
import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';
import { addNotification } from '@/store/notifications/notifications';
import { refreshList } from '@/store/crud';

import {
  endBillingEntities,
  EndBillingEntitiesAction,
  endBillingEntitiesFailure,
  endBillingEntitiesSuccess,
} from './endBillingEntities';

const BillingEntityToDataType: Record<
  BillingEntity,
  | DataType.AccountPlan
  | DataType.Contract
  | DataType.Commitment
  | DataType.Pricing
> = {
  [BillingEntity.AccountPlan]: DataType.AccountPlan,
  [BillingEntity.Contract]: DataType.Contract,
  [BillingEntity.Prepayment]: DataType.Commitment,
  [BillingEntity.Pricings]: DataType.Pricing,
};

interface EndDateBillingEntitiesResponse {
  updatedEntities?: Record<BillingEntity, Array<Id>>;
  failedEntities?: Record<BillingEntity, Array<Id>>;
}

function* failedEntitiesNotification(
  failedEntities: Record<BillingEntity, Array<Id>>
): Generator<StrictEffect, void, any> {
  const failedEntitiesMessage = Object.entries(failedEntities)
    .map(
      ([type, ids]) =>
        `${ids.length} ${i18next.t(`common:billingEntities.${type}`)}(s)`
    )
    .join(', ');

  yield put(
    addNotification(
      i18next.t('notifications:endBillingEntitiesWarningNotification', {
        failedEntities: failedEntitiesMessage,
      }),
      'warning'
    )
  );
}

function* updatedEntitiesNotifications(
  updatedEntities: Record<BillingEntity, Array<Id>>
): Generator<StrictEffect, void, any> {
  const updatedEntitiesMessage = Object.entries(updatedEntities)
    .map(
      ([type, ids]) =>
        `${ids.length} ${i18next.t(`common:billingEntities.${type}`)}(s)`
    )
    .join(', ');

  yield put(
    addNotification(
      i18next.t('notifications:endBillingEntitiesSuccessNotification', {
        updatedEntities: updatedEntitiesMessage,
      }),
      'success',
      5000
    )
  );
}

export function* endDateBillingEntitiesSaga(
  action: EndBillingEntitiesAction
): Generator<StrictEffect, void, any> {
  const { id, dataType, ...data } = action.payload;
  const { onFailure } = action.meta;

  const organizationId: Id = yield select(selectCurrentOrgId);

  try {
    const { updatedEntities, failedEntities }: EndDateBillingEntitiesResponse =
      yield call(
        performDataAction,
        dataType,
        'endDateBillingEntities',
        {
          organizationId,
          [`${dataType}Id`]: id,
        },
        undefined,
        data
      );

    if (failedEntities) {
      yield call(failedEntitiesNotification, failedEntities);
    }

    if (updatedEntities) {
      // Refresh the accounts updated entities in the UI.
      if (dataType === DataType.Account) {
        yield all(
          Object.entries(updatedEntities).map(([billingEntity, ids]) =>
            call(
              listData,
              BillingEntityToDataType[billingEntity as BillingEntity],
              { ids }
            )
          )
        );
        // Refresh the contract and contracts lists in the UI.
      } else if (dataType === DataType.Contract) {
        yield call(listData, DataType.Contract, { ids: [id] });
        yield put(
          refreshList(DataType.AccountPlan, OtherListIds.ContractAccountPlans)
        );
        yield put(
          refreshList(DataType.Commitment, OtherListIds.ContractCommitments)
        );
      }

      yield call(updatedEntitiesNotifications, updatedEntities);
    }

    // Success without any updates.
    if (!failedEntities && !updatedEntities) {
      yield put(
        addNotification(
          i18next.t('notifications:endBillingEntitiesInfoNotification'),
          'info',
          5000
        )
      );
    }

    yield put(endBillingEntitiesSuccess());
  } catch (error) {
    yield put(endBillingEntitiesFailure(extractError(error), onFailure));
  }
}

export default function* endBillingEntitiesSaga(): Generator<
  StrictEffect,
  void,
  any
> {
  yield takeEvery(endBillingEntities.type, endDateBillingEntitiesSaga);
}
