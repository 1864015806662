import React from 'react';

import { Contract } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { ContractForm } from '@/components/features/accounts/ContractForm';

export const ContractsEditRoute: React.FC = () => (
  <CrudEdit<Contract> form={ContractForm} />
);
