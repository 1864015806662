import { call, StrictEffect, takeEvery } from 'redux-saga/effects';
import { setUser } from '@sentry/react';

import { isSentryEnabled } from '@/config';
import { signInSuccess, SignInSuccessAction } from '@/store/app/auth/auth';

export function* setSentryUserSaga(
  action: SignInSuccessAction
): Generator<StrictEffect, void, any> {
  const enabled = yield call(isSentryEnabled);

  if (enabled) {
    const { id } = action.payload.user;
    yield call(setUser, { id });
  }
}

export default function* sentrySaga() {
  // On sign in success we can configure Sentry with the user details.
  yield takeEvery(signInSuccess.type, setSentryUserSaga);
}
