import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';

import { DataType, OrganizationAdmin } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { NotificationDefinition } from '@/store/store';
import { addEntityLink, selectIsLinkingEntity } from '@/store/utils/linkEntity';
import { OrganizationAdminPermissionsModalFormValues } from '@/components/features/admin/organizations/OrganizationAdminPermissionsModal';

const useOrgUserAdmin = (org: OrganizationAdmin) => {
  const orgId = org.id;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isOpen: isOrgUserModalOpen,
    onOpen: onOpenOrgUserModal,
    onClose: onCloseOrgUserModal,
  } = useDisclosure();

  const {
    isOpen: isUserPermissionsModalOpen,
    onOpen: onOpenUserPermissionsModal,
    onClose: onCloseUserPermissionsModal,
  } = useDisclosure();

  const userAddedSuccessNotification = useMemo<NotificationDefinition>(
    () => ({
      type: 'success',
      message: t('features:admin.userAddedToOrgSuccess'),
      removeAfter: 5000,
    }),
    [t]
  );

  const permissionAddedSuccessNotification = useMemo<NotificationDefinition>(
    () => ({
      type: 'success',
      message: t('features:admin.permissionAddedToUserSuccess'),
      removeAfter: 5000,
    }),
    [t]
  );

  const onAddUserToOrg = useCallback(
    (userIds: Array<string>) => {
      onCloseOrgUserModal();
      if (userIds.length > 0) {
        dispatch(
          addEntityLink(
            DataType.OrganizationAdmin,
            orgId,
            DataType.UserAdmin,
            userIds,
            userAddedSuccessNotification
          )
        );
      }
    },
    [dispatch, onCloseOrgUserModal, orgId, userAddedSuccessNotification]
  );

  const onAddPermissionToUser = useCallback(
    (data: OrganizationAdminPermissionsModalFormValues) => {
      onCloseUserPermissionsModal();
      if (data.permissionPolicyIds.length > 0) {
        dispatch(
          addEntityLink(
            DataType.UserAdmin,
            data.userId,
            DataType.PermissionPolicy,
            data.permissionPolicyIds,
            permissionAddedSuccessNotification,
            undefined,
            orgId
          )
        );
      }
    },
    [
      dispatch,
      onCloseUserPermissionsModal,
      orgId,
      permissionAddedSuccessNotification,
    ]
  );

  const isAddingUserPermissionSelector = useMemo(
    () => selectIsLinkingEntity(DataType.UserAdmin, DataType.PermissionPolicy),
    []
  );
  const isAddingUserPermission = useSelector(isAddingUserPermissionSelector);

  const isAddingOrgUserSelector = useMemo(
    () => selectIsLinkingEntity(DataType.OrganizationAdmin, DataType.UserAdmin),
    []
  );
  const isAddingOrgUser = useSelector(isAddingOrgUserSelector);

  return {
    isAddingOrgUser,
    isAddingUserPermission,
    isOrgUserModalOpen,
    isUserPermissionsModalOpen,
    onAddPermissionToUser,
    onAddUserToOrg,
    onCloseUserPermissionsModal,
    onCloseOrgUserModal,
    onOpenUserPermissionsModal,
    onOpenOrgUserModal,
  };
};

export default useOrgUserAdmin;
