import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const parts = ['footerContainer', 'paginationContainer'];

const interactiveTable: ComponentMultiStyleConfig = {
  parts,
  baseStyle: {
    footerContainer: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'auto max-content',
      gridTemplateRows: 'max-content',
      gap: 4,
      marginTop: 2,
    },
    paginationContainer: {
      gridColumn: '2 / 3',
    },
  },
};

export default interactiveTable;
