import { ComponentSingleStyleConfig } from '@chakra-ui/react';

const textarea: ComponentSingleStyleConfig = {
  variants: {
    outline: {
      color: 'text-light',
      bg: 'white',
      _placeholder: {
        color: 'text-subtle-light',
      },
    },
  },
};

export default textarea;
