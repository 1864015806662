import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';

import {
  DataType,
  AccountPlan,
  PlanGroup,
  UnsavedEntity,
} from '@m3ter-com/m3ter-api';

import { extractError } from '@/util/error';
import { createData, deleteData } from '@/store/data/data.saga';
import { AccountCustomPlanGroupFormValues } from '@/components/features/accounts/AccountCustomPlanGroupForm';

import {
  createCustomPlanGroup,
  createCustomPlanGroupSuccess,
  createCustomPlanGroupFailure,
  CreateCustomPlanGroupAction,
} from './customPlanGroupForm';

export function* createPlanGroupAndAccountPlan(
  formValues: AccountCustomPlanGroupFormValues
): Generator<StrictEffect, AccountPlan, any> {
  const planGroupData: UnsavedEntity<PlanGroup> = {
    accountId: formValues.accountId,
    name: formValues.name,
    code: formValues.code,
    currency: formValues.currency,
    standingCharge: formValues.standingCharge,
    minimumSpend: formValues.minimumSpend,
  };

  const planGroup: PlanGroup = yield call(
    createData,
    DataType.PlanGroup,
    planGroupData
  );

  try {
    // Create the account plan referencing the newly-created plan group.
    const accountPlanData: UnsavedEntity<AccountPlan> = {
      planGroupId: planGroup.id,
      accountId: formValues.accountId,
      startDate: formValues.startDate,
      endDate: formValues.endDate,
      contractId: formValues.contractId,
      childBillingMode: formValues.childBillingMode,
      billEpoch: formValues.billEpoch,
    };

    const accountPlan = yield call(
      createData,
      DataType.AccountPlan,
      accountPlanData
    );
    return accountPlan;
  } catch (error) {
    // Delete the plan group so it's not left hanging.
    yield call(deleteData, DataType.PlanGroup, planGroup.id);

    throw error;
  }
}

export function* createCustomPlanGroupSaga(
  action: CreateCustomPlanGroupAction
): Generator<StrictEffect, void, any> {
  const { formValues } = action.payload;
  const { onSuccess, onFailure } = action.meta;

  try {
    const accountPlan: AccountPlan = yield call(
      createPlanGroupAndAccountPlan,
      formValues
    );
    yield put(createCustomPlanGroupSuccess(accountPlan, onSuccess));
  } catch (error) {
    yield put(createCustomPlanGroupFailure(extractError(error), onFailure));
  }
}

export default function* customPlanGroupFormSaga() {
  yield takeEvery(createCustomPlanGroup.type, createCustomPlanGroupSaga);
}
