import React, { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormField } from '@m3ter-com/console-core/components';
import { Alert } from '@m3ter-com/ui-components';

import { NotificationDefinition } from '@/store/store';
import { endAccountPlan } from '@/store/features/accounts/accountPlans';
import { dataTypeListAllQuery } from '@/queries/crud';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useAppQuery from '@/hooks/data/useAppQuery';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import { FormDatePicker } from '@/components/forms/FormDatePicker';

export interface AccountPlanDateFieldsProps {
  checkOverlap?: boolean;
}

export const AccountPlanDateFields: React.FC<AccountPlanDateFieldsProps> = ({
  checkOverlap,
}) => {
  const { t } = useTranslation();

  const { toLongDateTime } = useDateFormatter();
  const dispatch = useDispatch();

  const accountId = useWatch({ name: 'accountId' });
  const productId = useWatch({ name: 'productId' });
  const startDate = useWatch({ name: 'startDate' });

  const pathParams = useOrgPathParams();
  const { data = [] } = useAppQuery(
    dataTypeListAllQuery(
      {
        dataType: DataType.AccountPlan,
        pathParams,
        queryParams: { account: accountId, product: productId },
      },
      // We don't want to enable the overlap check until both the account and product are known.
      { enabled: !!checkOverlap && !!productId && !!accountId }
    )
  );

  const overlappingAccountPlan = useMemo(() => {
    // We know any plans in the data are active so it's just a case of checking
    // if they end before the entered start date. There should only be one active
    // plan per product/account combo but we still iterate with `find` just in case.
    return checkOverlap && startDate
      ? data.find(
          (accountPlan) =>
            !accountPlan.endDate || accountPlan.endDate > startDate
        )
      : undefined;
  }, [data, checkOverlap, startDate]);

  const onEndPlanClick = useCallback(() => {
    if (overlappingAccountPlan && startDate) {
      const successNotification: NotificationDefinition = {
        type: 'success',
        message: t('features:account.overlapPlanEnded'),
        removeAfter: 5000,
      };
      const failureNotification: NotificationDefinition = {
        type: 'error',
        message: t('features:account.overlapPlanEndedError'),
      };
      dispatch(
        endAccountPlan(
          overlappingAccountPlan.id,
          startDate,
          successNotification,
          failureNotification
        )
      );
    }
  }, [dispatch, t, overlappingAccountPlan, startDate]);

  return (
    <VStack width="100%" spacing={4} alignItems="stretch">
      <HStack spacing={4}>
        <FormField
          isRequired
          name="startDate"
          label={t('forms:labels.startDateInclusive')}
          control={FormDatePicker}
        />
        <FormField
          name="endDate"
          label={t('forms:labels.endDateExclusive')}
          control={FormDatePicker}
        />
      </HStack>
      {overlappingAccountPlan && (
        <Alert status="warning">
          <Box>
            <Text size="xs" mb={4}>
              {overlappingAccountPlan.endDate
                ? t('features:account.overlappingPlanNoEnd', {
                    end: toLongDateTime(overlappingAccountPlan.endDate),
                  })
                : t('features:account.overlappingPlanNoEnd')}
            </Text>
            <Button size="sm" colorScheme="orange" onClick={onEndPlanClick}>
              {t('features:account.endAttachedPlan')}
            </Button>
          </Box>
        </Alert>
      )}
    </VStack>
  );
};
