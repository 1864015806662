import { ComponentSingleStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const fileDropzone: ComponentSingleStyleConfig = {
  baseStyle: (props) => {
    let borderColor = mode('border-light', 'border-dark')(props);
    let bgColor = 'transparent';

    if (props.isAccept) {
      borderColor = 'green.500';
      bgColor = mode('green.50', 'green.900')(props);
    } else if (props.isReject) {
      borderColor = 'red.500';
      bgColor = mode('red.50', 'red.900')(props);
    }

    return {
      padding: 4,
      borderStyle: 'dashed',
      borderWidth: 2,
      textAlign: 'center',
      borderColor,
      bgColor,
    };
  },
};

export default fileDropzone;
