import React from 'react';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';
import { SupportAccessDetails } from '@/components/features/access/support-access/SupportAccessDetails';

export const SupportAccessDetailsRoute: React.FC = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <BreadcrumbItem title={t('features:access.supportAccess')} />
      <SupportAccessDetails />
    </React.Fragment>
  );
};
