import React, { ReactNode } from 'react';

import { Box, forwardRef } from '@chakra-ui/react';

import { ButtonBadge } from './ButtonBadge';
import { IconButton, IconButtonProps } from './IconButton';

export interface BadgeIconButtonProps extends IconButtonProps {
  badge?: ReactNode;
}

export const BadgeIconButton = forwardRef<BadgeIconButtonProps, 'button'>(
  ({ badge, ...buttonProps }, ref) => (
    <Box position="relative" display="inline-block">
      <IconButton ref={ref} {...buttonProps} />
      {badge && <ButtonBadge>{badge}</ButtonBadge>}
    </Box>
  )
);
