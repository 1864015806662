import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';

import { CreditType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

export interface CreditOrderFieldProps {
  name: string;
  defaultLabel?: string;
  includeOrgConfig?: boolean;
}

export const CreditOrderField: React.FC<CreditOrderFieldProps> = ({
  name,
  defaultLabel,
  includeOrgConfig = false,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => {
        return (
          <RadioGroup
            value={value ? value.join(',') : ''}
            onChange={(newValue) => {
              onChange(newValue !== '' ? newValue.split(',') : null);
            }}
          >
            <Stack alignItems="start">
              {includeOrgConfig && !!defaultLabel && (
                <Radio value="">{defaultLabel}</Radio>
              )}
              <Radio value={`${CreditType.Prepayment},${CreditType.Balance}`}>
                {t('forms:labels.prepaymentThenBalance')}
              </Radio>
              <Radio value={`${CreditType.Balance},${CreditType.Prepayment}`}>
                {t('forms:labels.balanceThenPrepayment')}
              </Radio>
              <Radio value={CreditType.Prepayment}>
                {t('forms:labels.prepaymentOnly')}
              </Radio>
              <Radio value={CreditType.Balance}>
                {t('forms:labels.balanceOnly')}
              </Radio>
            </Stack>
          </RadioGroup>
        );
      }}
    />
  );
};
