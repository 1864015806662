import React from 'react';

import { UserAdmin } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormInput,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import userAdminSchema from '@/validation/userAdmin';

export interface UserAdminFormProps extends BaseFormProps<UserAdmin> {}

const defaultInitialValues: Partial<UserAdmin> = {};

export const UserAdminForm: React.FC<UserAdminFormProps> = ({
  onSave,
  onCancel,
  isSaving = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();
  const entityName = t('common:user');

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={userAdminSchema}
    >
      <FormStack>
        <FormSection
          heading={t('common:entityDetails', { entityName })}
          helpText={t('forms:helpText.userAdminCreateUser')}
        >
          <FormField
            isRequired
            name="firstName"
            label={t('forms:labels.firstName')}
            control={FormInput}
          />
          <FormField
            isRequired
            name="lastName"
            label={t('forms:labels.lastName')}
            control={FormInput}
          />
          <FormField
            isRequired
            name="email"
            label={t('forms:labels.emailAddress')}
            control={FormInput}
            type="email"
          />
          <FormField
            isRequired
            name="tempPassword"
            label={t('forms:labels.tempPassword')}
            control={FormInput}
            type="password"
            helpText={t('forms:helpText.userAdminTemporaryPassword')}
          />
          <FormField
            name="contactNumber"
            label={t('forms:labels.contactNumber')}
            control={FormInput}
            type="number"
          />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={t('forms:buttons.createEntity', { entityName })}
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
