import React from 'react';

import { AnalyticsJobType } from '@m3ter-com/m3ter-api';

import { ReportType } from '@/types/data';
import { NamedRoute } from '@/types/routes';

import { ReportsRootRoute } from '@/components/routes/reports/ReportsRootRoute';

import setupBillingBasedRecurringRevenueReportRoute, {
  name as billingBasedRecurringRevenueReportRouteName,
} from './billingBasedRecurringRevenue';
import setupMonthOnMonthReportRoute, {
  name as monthOnMonthReportRouteName,
} from './monthOnMonth';
import setupRecurringRevenueReportRoute, {
  name as recurringRevenueReportRouteName,
} from './recurringRevenue';
import setupPrepaymentsStatusReportRoute, {
  name as prepaymentsStatusReportRouteName,
} from './prepaymentsStatus';
import setupTotalContractValueReportRoute, {
  name as totalContractValueReportRouteName,
} from './totalContractValue';
import setupReportsListRoute from './reportsListRoute';

const reportTypeRouteNameMap: Record<ReportType, string> = {
  [AnalyticsJobType.BillingBasedRecurringRevenueReport]:
    billingBasedRecurringRevenueReportRouteName,
  [AnalyticsJobType.MonthOnMonthReport]: monthOnMonthReportRouteName,
  [AnalyticsJobType.PrepaymentsStatusReport]: prepaymentsStatusReportRouteName,
  [AnalyticsJobType.RecurringRevenueReport]: recurringRevenueReportRouteName,
  [AnalyticsJobType.TotalContractValueReport]:
    totalContractValueReportRouteName,
};

export const getReportRouteName = (reportType: ReportType): string =>
  reportTypeRouteNameMap[reportType];

export default (): NamedRoute => ({
  name: 'reports',
  path: 'reports',
  element: <ReportsRootRoute />,
  children: [
    setupBillingBasedRecurringRevenueReportRoute(),
    setupMonthOnMonthReportRoute(),
    setupRecurringRevenueReportRoute(),
    setupPrepaymentsStatusReportRoute(),
    setupTotalContractValueReportRoute(),
    setupReportsListRoute(),
  ],
});
