import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { ContractsRoute } from '@/components/routes/accounts/ContractsRoute';
import { ContractsListRoute } from '@/components/routes/accounts/ContractsListRoute';
import { ContractsDetailsRoute } from '@/components/routes/accounts/ContractsDetailsRoute';
import { ContractsCreateRoute } from '@/components/routes/accounts/ContractCreateRoute';
import { ContractsEditRoute } from '@/components/routes/accounts/ContractsEditRoute';

export default () =>
  createCrudRoute({
    path: 'contracts',
    dataType: DataType.Contract,
    element: <ContractsRoute />,
    list: <ContractsListRoute />,
    details: <ContractsDetailsRoute />,
    create: <ContractsCreateRoute />,
    edit: <ContractsEditRoute />,
  });
