import React, { useCallback, useRef } from 'react';

import { Button } from '@m3ter-com/ui-components';

interface AuthSubmitButtonProps {
  isLoading?: boolean;
  buttonText: string;
}

export const AuthSubmitButton: React.FC<AuthSubmitButtonProps> = ({
  buttonText,
  isLoading = false,
}) => {
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const handleSubmitKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (submitButtonRef.current && event.key === 'Enter') {
        submitButtonRef.current.click();
      }
    },
    []
  );

  return (
    <Button
      ref={submitButtonRef}
      w="100%"
      type="submit"
      intent="primary"
      isLoading={isLoading}
      onKeyDown={handleSubmitKeyPress}
    >
      {buttonText}
    </Button>
  );
};
