import React from 'react';

import { OrganizationCustomFieldsDetails } from '@/components/features/organization/OrganizationCustomFieldsDetails';

export interface OrganizationCustomFieldsDetailsRouteProps {}

export const OrganizationCustomFieldsDetailsRoute: React.FC<
  OrganizationCustomFieldsDetailsRouteProps
> = () => {
  return <OrganizationCustomFieldsDetails editUrl="edit" />;
};
