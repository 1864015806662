import React, { useMemo } from 'react';

import { Spinner } from '@chakra-ui/react';

import {
  DataType,
  StatementAggregationFrequency,
  StatementDefinition,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { DeepPartial } from '@m3ter-com/console-core/types';
import { FormStack, SelectOption } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormInput,
  FormSection,
  FormSelect,
  FormSwitch,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import statementDefinitionSchema from '@/validation/statementDefinition';
import { dataTypeListAllQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useAppQuery from '@/hooks/data/useAppQuery';

import { MeasuresField } from './MeasuresField';
import { DimensionsField } from './DimensionsField';

export interface StatementDefinitionFormProps
  extends BaseFormProps<StatementDefinition> {}

const defaultInitialValues: DeepPartial<StatementDefinition> = {
  aggregationFrequency: StatementAggregationFrequency.Day,
  measures: [],
  dimensions: [],
};

export const StatementDefinitionForm: React.FC<
  StatementDefinitionFormProps
> = ({
  initialValues = defaultInitialValues,
  isEdit,
  isSaving,
  onCancel,
  onSave,
}) => {
  const { t } = useTranslation();
  const entityName = t('common:statementDefinition');

  const pathParams = useOrgPathParams();
  const { data: meters, isLoading: isLoadingMeters } = useAppQuery(
    dataTypeListAllQuery({
      dataType: DataType.Meter,
      pathParams,
    })
  );

  const frequencyOptions = useMemo<
    Array<SelectOption<StatementAggregationFrequency>>
  >(
    () =>
      Object.values(StatementAggregationFrequency).map((frequencyValue) => ({
        value: frequencyValue,
        label: t(`features:statements.frequency.${frequencyValue}`),
      })),
    [t]
  );

  return isLoadingMeters || !meters ? (
    <Spinner />
  ) : (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={statementDefinitionSchema}
    >
      <FormStack>
        <FormSection heading={t('common:entityDetails', { entityName })}>
          <FormField
            isRequired
            name="name"
            label={t('forms:labels.name')}
            control={FormInput}
          />
          <FormField
            isRequired
            name="aggregationFrequency"
            label={t('forms:labels.aggregationFrequency')}
            control={FormSelect}
            options={frequencyOptions}
          />
          <FormField
            name="includePricePerUnit"
            label={t('forms:labels.includePricePerUnit')}
            control={FormSwitch}
            helpText={t('forms:helpText.includePricePerUnit')}
          />
        </FormSection>
        <FormSection w="60em" heading={t('features:statements.measures')}>
          <MeasuresField name="measures" meters={meters} />
        </FormSection>
        <FormSection
          isOptional
          w="60em"
          heading={t('features:statements.dimensions')}
        >
          <DimensionsField name="dimensions" meters={meters} />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={
            isEdit
              ? t('forms:buttons.updateEntity', { entityName })
              : t('forms:buttons.createEntity', { entityName })
          }
          onCancel={onCancel}
          isSaving={isSaving}
        />
      </FormStack>
    </Form>
  );
};
