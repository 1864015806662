import React, { useCallback, useMemo } from 'react';

import { HStack } from '@chakra-ui/react';

import { DataType, Frequency, PlanTemplate } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack, SelectOption } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormInput,
  FormSection,
  FormSelect,
  NameCodeFields,
} from '@m3ter-com/console-core/components';

import planTemplateSchema from '@/validation/planTemplate';
import useProducts from '@/hooks/features/products/useProducts';
import { FormCustomFieldsEditorDefaults } from '@/components/forms/FormCustomFieldsEditorDefaults';
import {
  DataTypeFormEntitySelect,
  FormEntitySelect,
} from '@/components/forms/FormEntitySelect';
import { FormPicklistSelect } from '@/components/features/accounts/FormPicklistSelect';
import { PlanTemplateFormFields } from '@/components/features/pricing/PlanTemplateFormFields';

const defaultInitialValues = {};

export interface PlanTemplateFormProps {
  onSave: (item: PlanTemplate) => void;
  onCancel: () => void;
  isSaving?: boolean;
  isEdit?: boolean;
  initialValues?: Partial<PlanTemplate>;
}

export const PlanTemplateForm: React.FC<PlanTemplateFormProps> = ({
  onSave,
  onCancel,
  isSaving = false,
  isEdit = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();
  const entityName = t('common:planTemplate');

  const { selectProduct } = useProducts();

  const frequencyOptions = useMemo<Array<SelectOption>>(
    () =>
      Object.values(Frequency).map((frequencyValue) => ({
        value: frequencyValue,
        label: t(`common:frequency.${frequencyValue}`),
      })),
    [t]
  );

  const onSubmit = useCallback(
    (data: PlanTemplate) => {
      onSave({
        ...data,
        // Code is optional but can't be sent as an empty string.
        code: data.code && data.code !== '' ? data.code : undefined,
      });
      if (data.productId) {
        selectProduct(data.productId);
      }
    },
    [onSave, selectProduct]
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={planTemplateSchema}
    >
      <FormStack mb={4}>
        <FormSection heading={t('common:entityDetails', { entityName })}>
          <FormField
            isRequired
            name="productId"
            label={t('common:product')}
            control={
              FormEntitySelect as DataTypeFormEntitySelect<DataType.Product>
            }
            dataType={DataType.Product}
            accessor="name"
            detailAccessor="code"
          />
          <NameCodeFields isCodeFieldRequired={false} />
          <FormField
            isRequired
            name="currency"
            label={t('forms:labels.currency')}
            control={FormPicklistSelect}
            useCodeAsValue
            dataType={DataType.Currency}
          />
          <HStack spacing={4} alignSelf="stretch">
            <FormField
              isRequired
              name="billFrequency"
              label={t('forms:labels.billFrequency')}
              control={FormSelect}
              options={frequencyOptions}
            />
            <FormField
              isRequired
              name="billFrequencyInterval"
              label={t('forms:labels.billInterval')}
              control={FormInput}
              type="number"
            />
          </HStack>
        </FormSection>
        <PlanTemplateFormFields />
        <FormCustomFieldsEditorDefaults dataType={DataType.PlanTemplate} />
      </FormStack>
      <FormActions
        cancelText={t('common:cancel')}
        submitText={
          isEdit
            ? t('forms:buttons.updateEntity', { entityName })
            : t('forms:buttons.createEntity', { entityName })
        }
        isSaving={isSaving}
        onCancel={onCancel}
      />
    </Form>
  );
};
