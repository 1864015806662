import React from 'react';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';

const LazyBillingBasedRecurringRevenueReportRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BillingBasedRecurringRevenueReportRoute" */ '@/components/routes/reports/BillingBasedRecurringRevenueReportRoute'
  ).then((module) => ({
    default: module.BillingBasedRecurringRevenueReportRoute,
  }))
);

export const name = 'reports.billingBasedRecurringRevenue';

export default (): NamedRoute => ({
  name,
  path: 'recurring-revenue/billing-based',
  element: <LazyBillingBasedRecurringRevenueReportRoute />,
});
