import { QueryParams } from '../types';

// Removes any undefined / null values from params and stringifies arrays.
export const cleanParams = (params: QueryParams): Record<string, string> =>
  Object.entries(params).reduce<Record<string, string>>((acc, [key, value]) => {
    if (value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        acc[key] = value.join(',');
      } else {
        acc[key] = `${value}`;
      }
    }
    return acc;
  }, {});
