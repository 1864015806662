import { useSelector } from 'react-redux';

import {
  selectCurrentOrg,
  selectCurrentOrgId,
  selectOrgConfig,
} from '@/store/app/bootstrap/bootstrap';

const useOrg = () => {
  const currentOrg = useSelector(selectCurrentOrg);
  const currentOrgId = useSelector(selectCurrentOrgId);
  const orgConfig = useSelector(selectOrgConfig);

  return {
    currentOrg,
    currentOrgId,
    orgConfig,
  };
};

export default useOrg;
