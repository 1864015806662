import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { BalanceTransactionsRoute } from '@/components/routes/accounts/BalanceTransactionsRoute';
import { BalanceTransactionsCreateRoute } from '@/components/routes/accounts/BalanceTransactionsCreateRoute';

export default () =>
  createCrudRoute({
    path: 'transactions',
    dataType: DataType.BalanceTransaction,
    element: <BalanceTransactionsRoute />,
    create: <BalanceTransactionsCreateRoute />,
  });
