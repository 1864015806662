import React from 'react';

import { NotificationRule } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { NotificationRuleForm } from '@/components/features/notification-rules/NotificationRuleForm';

export const NotificationRuleEditRoute: React.FC = () => (
  <CrudEdit<NotificationRule> form={NotificationRuleForm} />
);
