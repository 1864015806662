import React, { ComponentProps } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { AdvancedNumberInput } from '@m3ter-com/ui-components';

export interface FormAdvancedNumberInputProps
  extends Omit<
    ComponentProps<typeof AdvancedNumberInput>,
    'value' | 'onChange'
  > {
  name: string;
}

export const FormAdvancedNumberInput: React.FC<
  FormAdvancedNumberInputProps
> = ({ name, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <AdvancedNumberInput value={value} onChange={onChange} {...props} />
      )}
    />
  );
};
