import React, { useCallback } from 'react';

import { Flex, Icon } from '@chakra-ui/react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

export enum TableSortingDirection {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export interface TableSortingState {
  activeColumnId: string;
  sortingDirection: TableSortingDirection;
}

export interface TableColumnSortButtonProps {
  columnId: string;
  sortingState?: TableSortingState;
  setSortingState: (newSortingState: TableSortingState | undefined) => void;
}

export const TableColumnSortButton: React.FC<TableColumnSortButtonProps> = ({
  columnId,
  sortingState,
  setSortingState,
}) => {
  const showUpArrow =
    sortingState?.activeColumnId !== columnId ||
    sortingState.sortingDirection === TableSortingDirection.Ascending;
  const showDownArrow =
    sortingState?.activeColumnId !== columnId ||
    sortingState.sortingDirection === TableSortingDirection.Descending;

  // We only support sorting by one column at a time. When a sorting
  // button is clicked, we set the current sorting state based on that column.
  // The direction of the sorting state for any one column goes from
  // none -> ascending -> descending and then starts again.
  const onClick = useCallback(() => {
    if (!sortingState || sortingState.activeColumnId !== columnId) {
      setSortingState({
        activeColumnId: columnId,
        sortingDirection: TableSortingDirection.Ascending,
      });
    } else if (
      sortingState.sortingDirection === TableSortingDirection.Ascending
    ) {
      setSortingState({
        activeColumnId: columnId,
        sortingDirection: TableSortingDirection.Descending,
      });
    } else {
      setSortingState(undefined);
    }
  }, [columnId, sortingState, setSortingState]);

  return (
    <Flex
      data-testid={`table-column-sort-button-${columnId}`}
      flexFlow="column nowrap"
      as="button"
      pointerEvents="auto"
      type="button"
      onClick={onClick}
    >
      <Icon
        data-testid={`table-column-sort-button-${columnId}-asc-icon`}
        as={FaCaretUp}
        w={4}
        h={4}
        alignSelf="flex-start"
        visibility={showUpArrow ? 'visible' : 'hidden'}
      />
      <Icon
        data-testid={`table-column-sort-button-${columnId}-desc-icon`}
        as={FaCaretDown}
        w={4}
        h={4}
        alignSelf="flex-end"
        visibility={showDownArrow ? 'visible' : 'hidden'}
      />
    </Flex>
  );
};
