import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataType, User } from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

import { selectSingleton } from '@/store/data/data';

export interface AuthState {
  isAcceptingTerms: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  isM3terAdmin: boolean;
  isRestoringSession: boolean;
  isSigningOut: boolean;
  expirationTime?: number;
  authError?: AppError;
  termsError?: AppError;
}

interface SignInPayload {
  email?: string;
  password?: string;
}
export type SignInAction = PayloadAction<SignInPayload>;

interface SignInSuccessPayload {
  user: User;
  expirationTime: number;
  isM3terAdmin: boolean;
}
interface SignInPartialSuccessPayload {}
export type SignInPartialSuccessAction =
  PayloadAction<SignInPartialSuccessPayload>;
export type SignInSuccessAction = PayloadAction<SignInSuccessPayload>;
export type SignInFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

export type SignOutFailureAction = PayloadAction<void, string, never, AppError>;

interface ForgotPasswordPayload {
  email: string;
}
export type ForgotPasswordAction = PayloadAction<ForgotPasswordPayload>;

export type ForgotPasswordFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

interface ForgotPasswordSubmitPayload {
  email: string;
  code: string;
  newPassword: string;
}
export type ForgotPasswordSubmitAction =
  PayloadAction<ForgotPasswordSubmitPayload>;
export type ForgotPasswordSubmitFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

interface CompleteNewPasswordPayload {
  name: string;
  newPassword: string;
}
export type CompleteNewPasswordAction =
  PayloadAction<CompleteNewPasswordPayload>;
export type CompleteNewPasswordFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

export type AcceptTermsFailureAction = PayloadAction<
  void,
  string,
  never,
  AppError
>;

const initialState: AuthState = {
  isAcceptingTerms: false,
  isAuthenticated: false,
  isLoading: false,
  isM3terAdmin: false,
  isRestoringSession: false,
  isSigningOut: false,
};

const authSlice = createSlice({
  name: 'app/auth',
  initialState,
  reducers: {
    restoreSession: (state: AuthState) => {
      state.isRestoringSession = true;
      state.authError = undefined;
    },
    restoreSessionComplete: (state: AuthState) => {
      state.isRestoringSession = false;
    },
    signIn: {
      reducer: (state: AuthState, _action: SignInAction) => {
        state.isLoading = true;
        state.authError = undefined;
      },
      prepare: (email?: string, password?: string) => ({
        payload: { email, password },
      }),
    },
    signInPartialSuccess: (state: AuthState) => {
      state.isLoading = false;
    },
    signInSuccess: {
      reducer: (state: AuthState, action: SignInSuccessAction) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.isM3terAdmin = action.payload.isM3terAdmin;
        state.expirationTime = action.payload.expirationTime;
        state.authError = undefined;
      },
      prepare: (
        user: User,
        expirationTime: number,
        isM3terAdmin: boolean = false
      ) => ({
        payload: { user, expirationTime, isM3terAdmin },
      }),
    },
    signInFailure: {
      reducer: (state: AuthState, action: SignInFailureAction) => {
        state.isLoading = false;
        state.authError = action.error;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    forgotPassword: {
      reducer: (state: AuthState, _action: ForgotPasswordAction) => {
        state.isLoading = true;
        state.authError = undefined;
      },
      prepare: (email: string) => ({ payload: { email } }),
    },
    forgotPasswordSuccess: (state) => {
      state.isLoading = false;
    },
    forgotPasswordFailure: {
      reducer: (state: AuthState, action: ForgotPasswordFailureAction) => {
        state.isLoading = false;
        state.authError = action.error;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    forgotPasswordSubmit: {
      reducer: (state: AuthState, _action: ForgotPasswordSubmitAction) => {
        state.isLoading = true;
        state.authError = undefined;
      },
      prepare: (email: string, code: string, newPassword: string) => ({
        payload: { email, code, newPassword },
      }),
    },
    forgotPasswordSubmitSuccess: (state: AuthState) => {
      state.isLoading = false;
    },
    forgotPasswordSubmitFailure: {
      reducer: (state: AuthState, action: ForgotPasswordFailureAction) => {
        state.isLoading = false;
        state.authError = action.error;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    completeNewPassword: {
      reducer: (state: AuthState, _action: CompleteNewPasswordAction) => {
        state.isLoading = true;
        state.authError = undefined;
      },
      prepare: (newPassword: string, name: string) => ({
        payload: { newPassword, name },
      }),
    },
    completeNewPasswordSuccess: (state: AuthState) => {
      state.isLoading = false;
    },
    completeNewPasswordFailure: {
      reducer: (state: AuthState, action: CompleteNewPasswordFailureAction) => {
        state.isLoading = false;
        state.authError = action.error;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    signOut: (state: AuthState) => {
      state.isSigningOut = true;
    },
    signOutFailure: {
      reducer: (state: AuthState, action: SignOutFailureAction) => {
        state.isSigningOut = false;
        state.authError = action.error;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    acceptTerms: (state: AuthState) => {
      state.isAcceptingTerms = true;
    },
    acceptTermsSuccess: (state: AuthState) => {
      state.isAcceptingTerms = false;
    },
    acceptTermsFailure: {
      reducer: (state: AuthState, action: AcceptTermsFailureAction) => {
        state.isAcceptingTerms = false;
        state.termsError = action.error;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    reset: (state: AuthState) => {
      state.authError = undefined;
      state.isLoading = false;
    },
  },
});

// Export actions.
export const {
  acceptTerms,
  acceptTermsFailure,
  acceptTermsSuccess,
  completeNewPassword,
  completeNewPasswordFailure,
  completeNewPasswordSuccess,
  forgotPassword,
  forgotPasswordFailure,
  forgotPasswordSubmit,
  forgotPasswordSubmitFailure,
  forgotPasswordSubmitSuccess,
  forgotPasswordSuccess,
  reset,
  restoreSession,
  restoreSessionComplete,
  signIn,
  signInFailure,
  signInPartialSuccess,
  signInSuccess,
  signOut,
  signOutFailure,
} = authSlice.actions;

// Selectors.

const selectAuthState = (state: { app: { auth: AuthState } }): AuthState =>
  state.app.auth;

export const selectUser = (state: any) =>
  selectSingleton<User>(DataType.CurrentUser)(state)!;

export const selectAuthError = createSelector(
  selectAuthState,
  (state) => state.authError
);
export const selectTermsError = createSelector(
  selectAuthState,
  (state) => state.termsError
);

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  (state) => state.isAuthenticated
);

export const selectIsRestoringSession = createSelector(
  selectAuthState,
  (state) => state.isRestoringSession
);

export const selectIsLoading = createSelector(
  selectAuthState,
  (state) => state.isLoading
);

export const selectIsAcceptingTerms = createSelector(
  selectAuthState,
  (state) => state.isAcceptingTerms
);

export const selectIsM3terAdmin = createSelector(
  selectAuthState,
  (state) => state.isM3terAdmin
);

export const selectIsSigningOut = createSelector(
  selectAuthState,
  (state) => state.isSigningOut
);

export const selectExpirationTime = createSelector(
  selectAuthState,
  (state) => state.expirationTime
);

// Default export is the reducer itself.

export default authSlice.reducer;
