import React from 'react';

import { DataType, CounterPricing } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { ItemCounterPricingRoute } from '@/components/routes/pricing/ItemCounterPricingRoute';

const LazyItemCounterPricingCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "ItemCounterPricingCreate" */ '@/components/routes/pricing/editor/ItemCounterPricingCreateRoute'
  ).then((module) => ({ default: module.ItemCounterPricingCreateRoute }))
);

const LazyItemCounterPricingEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "ItemCounterPricingEdit" */ '@/components/routes/pricing/editor/ItemCounterPricingEditRoute'
  ).then((module) => ({ default: module.ItemCounterPricingEditRoute }))
);

const LazyItemCounterPricingDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "ItemCounterPricingDetails" */ '@/components/routes/pricing/editor/ItemCounterPricingDetailsRoute'
  ).then((module) => ({ default: module.ItemCounterPricingDetailsRoute }))
);

export default () =>
  createCrudRoute<CounterPricing>({
    path: 'counter-pricing',
    dataType: DataType.CounterPricing,
    element: <ItemCounterPricingRoute />,
    create: <LazyItemCounterPricingCreateRoute />,
    edit: <LazyItemCounterPricingEditRoute />,
    details: <LazyItemCounterPricingDetailsRoute />,
  });
