import {
  takeLatest,
  all,
  call,
  put,
  CallEffect,
  StrictEffect,
} from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';

import { DataType, Id, CounterPricing, Pricing } from '@m3ter-com/m3ter-api';
import { combineAndSortPricing } from '@m3ter-com/console-core/utils';

import { ids } from '@/util/data';
import { extractError } from '@/util/error';
import { listData, retrieveData } from '@/store/data/data.saga';
import {
  loadPricingSchedule,
  loadPricingScheduleSuccess,
  LoadPricingScheduleAction,
  loadPricingScheduleFailure,
} from '@/store/features/pricing/pricingSchedule';

export function* loadPricingScheduleSaga(
  action: LoadPricingScheduleAction
): Generator<StrictEffect, void, any> {
  const {
    planId,
    planTemplateId,
    aggregationId,
    compoundAggregationId,
    counterId,
    segment,
  } = action.payload;

  if (counterId) {
    yield call(
      loadItemCounterPricingScheduleSaga,
      counterId,
      planId,
      planTemplateId
    );
    return;
  }

  // Need to load all pricing for both the plan and plan template ID then find the
  // relevant ones for the (compound) aggregation and segment. We also need to load the
  // plan / plan template and aggregation to display the details.

  // In the case of viewing pricing schedule for a plan, we also load the plan
  // template ID as some of the pricing may come from the template. We can load
  // these in parallel.

  const requests: Record<string, CallEffect> = {};
  if (planId) {
    requests.planPricing = call(listData, DataType.Pricing, { planId });
    requests.plan = call(retrieveData, DataType.Plan, planId);
  }

  if (planTemplateId) {
    requests.planTemplatePricing = call(listData, DataType.Pricing, {
      planTemplateId,
    });
    requests.planTemplate = call(
      retrieveData,
      DataType.PlanTemplate,
      planTemplateId
    );
  }

  if (aggregationId) {
    requests.aggregation = call(
      retrieveData,
      DataType.Aggregation,
      aggregationId
    );
  }

  if (compoundAggregationId) {
    requests.compoundAggregation = call(
      retrieveData,
      DataType.CompoundAggregation,
      compoundAggregationId
    );
  }

  try {
    const responses = yield all(requests);

    // Filter pricing by (compound) aggregation / compound aggregation and optionally segment.
    const filterPricing = (pricings: Array<Pricing>): Array<Pricing> =>
      pricings.filter(
        (pricing) =>
          ((aggregationId && pricing.aggregationId === aggregationId) ||
            (compoundAggregationId &&
              pricing.compoundAggregationId === compoundAggregationId)) &&
          (!segment || isEqual(pricing.segment, segment))
      );

    const relevantPlanPricing = responses.planPricing
      ? filterPricing(responses.planPricing.data)
      : [];
    const relevantPlanTemplatePricing = responses.planTemplatePricing
      ? filterPricing(responses.planTemplatePricing.data)
      : [];

    const allPricings = combineAndSortPricing(
      relevantPlanTemplatePricing,
      relevantPlanPricing
    );

    yield put(
      loadPricingScheduleSuccess(
        ids(allPricings),
        [],
        planId,
        planTemplateId,
        aggregationId,
        compoundAggregationId,
        counterId,
        segment
      )
    );
  } catch (error) {
    yield put(loadPricingScheduleFailure(extractError(error)));
  }
}

export function* loadItemCounterPricingScheduleSaga(
  counterId: Id,
  planId?: Id,
  planTemplateId?: Id
): Generator<StrictEffect, void, any> {
  const requests: Record<string, CallEffect> = {};
  if (planId) {
    requests.planItemCounterPricing = call(listData, DataType.CounterPricing, {
      planId,
    });
    requests.plan = call(retrieveData, DataType.Plan, planId);
  }

  if (planTemplateId) {
    requests.planTemplateItemCounterPricing = call(
      listData,
      DataType.CounterPricing,
      {
        planTemplateId,
      }
    );
    requests.planTemplate = call(
      retrieveData,
      DataType.PlanTemplate,
      planTemplateId
    );
  }

  if (counterId) {
    requests.ItemCounter = call(retrieveData, DataType.Counter, counterId);
  }

  try {
    const responses = yield all(requests);

    const filterItemCounterPricing = (
      itemCounterPricings: Array<CounterPricing>
    ): Array<CounterPricing> =>
      itemCounterPricings.filter(
        (itemCounterPricing) => itemCounterPricing.counterId === counterId
      );

    const relevantPlanItemCounterPricing = responses.planItemCounterPricing
      ? filterItemCounterPricing(responses.planItemCounterPricing.data)
      : [];
    const relevantPlanTemplateItemCounterPricing =
      responses.planTemplateItemCounterPricing
        ? filterItemCounterPricing(
            responses.planTemplateItemCounterPricing.data
          )
        : [];

    const allItemCounterPricings = combineAndSortPricing(
      relevantPlanTemplateItemCounterPricing,
      relevantPlanItemCounterPricing
    );

    yield put(
      loadPricingScheduleSuccess(
        [],
        ids(allItemCounterPricings),
        planId,
        planTemplateId,
        undefined,
        undefined,
        counterId,
        undefined
      )
    );
  } catch (error) {
    yield put(loadPricingScheduleFailure(extractError(error)));
  }
}

export default function* pricingScheduleSaga() {
  yield takeLatest(loadPricingSchedule.type, loadPricingScheduleSaga);
}
