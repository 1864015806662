import React, { Suspense } from 'react';

import { useSelector } from 'react-redux';
import { Box, Spinner } from '@chakra-ui/react';
import { Link, Outlet } from 'react-router-dom';

import { AppLayout, LoadingScreen } from '@m3ter-com/ui-components';

import {
  removeAuthRedirectPath,
  setAuthRedirectPath,
} from '@/util/localStorage';
import { selectIsBootstrapping } from '@/store/app/bootstrap/bootstrap';
import { TimezoneContextProvider } from '@/hooks/util/useDateFormatter';
import { Logo } from '@/components/common/brand/Logo';
import { AdminNavigation } from '@/components/common/navigation/AdminNavigation';
import { Header } from '@/components/common/navigation/Header';

export const AdminRoute: React.FC = () => {
  const isAppBootstrapping = useSelector(selectIsBootstrapping);

  if (isAppBootstrapping) {
    setAuthRedirectPath('/admin');
    return <LoadingScreen />;
  }
  removeAuthRedirectPath();

  return (
    <TimezoneContextProvider timeZone="UTC">
      <AppLayout
        header={<Header />}
        navigation={
          <Box width="100%">
            <Link to="/">
              <Logo marginRight="auto" marginLeft="auto" marginBottom={6} />
            </Link>
            <AdminNavigation />
          </Box>
        }
      >
        <Suspense fallback={<Spinner />}>
          <Outlet />
        </Suspense>
      </AppLayout>
    </TimezoneContextProvider>
  );
};
