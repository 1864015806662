import React from 'react';

import { DataType, ExternalMapping } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { createCrudRoute } from '@/routes/crud';
import { ExternalMappingsRoute } from '@/components/routes/integrations/external-mappings/ExternalMappingsRoute';
import { ExternalMappingsListRoute } from '@/components/routes/integrations/external-mappings/ExternalMappingsListRoute';
import { ExternalMappingsEditRoute } from '@/components/routes/integrations/external-mappings/ExternalMappingsEditRoute';
import { ExternalMappingsBulkCreateRoute } from '@/components/routes/integrations/external-mappings/ExternalMappingsBulkCreateRoute';
import { ExternalMappingsCreateRoute } from '@/components/routes/integrations/external-mappings/ExternalMappingsCreateRoute';

export default (): NamedRoute =>
  createCrudRoute<ExternalMapping>({
    path: 'external-mappings',
    dataType: DataType.ExternalMapping,
    element: <ExternalMappingsRoute />,
    list: <ExternalMappingsListRoute />,
    edit: <ExternalMappingsEditRoute />,
    create: <ExternalMappingsCreateRoute />,
    directChildRoutes: [
      {
        path: 'bulk-create',
        name: 'external-mappings.bulk-create',
        element: <ExternalMappingsBulkCreateRoute />,
      },
    ],
  });
