import React, { useMemo } from 'react';

import { useWatch } from 'react-hook-form';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormField } from '@m3ter-com/console-core/components';

import {
  DataTypeFormEntitySelect,
  FormEntitySelect,
} from '@/components/forms/FormEntitySelect';

export interface AccountContractFieldProps {
  name: string;
}

export const AccountContractField: React.FC<AccountContractFieldProps> = ({
  name,
}) => {
  const { t } = useTranslation();

  const accountId = useWatch({ name: 'accountId' });

  const params = useMemo(
    () => ({
      accountId,
    }),
    [accountId]
  );

  return (
    <FormField
      name={name}
      label={t('forms:labels.contract')}
      control={FormEntitySelect as DataTypeFormEntitySelect<DataType.Contract>}
      isClearable
      dataType={DataType.Contract}
      params={params}
      accessor="name"
      detailAccessor="code"
    />
  );
};
