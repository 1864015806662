import { useCallback, useMemo } from 'react';

import { createSearchCriteria } from '@/util/crud';
import { EntityNamings } from '@/hooks/util/useEntityNamings';
import { useCrudListContext } from '@/components/common/crud/CrudList';

export interface UseCrudListSearchReturn {
  clearSearch: () => void;
  entityNamings: EntityNamings;
  search: (newSearchQuery: string) => void;
  searchFields?: Array<string>;
  searchQuery?: string;
}

const useCrudListSearch = (): UseCrudListSearchReturn => {
  const { entityNamings, searchCriteria, searchFields, searchList } =
    useCrudListContext();

  const searchQuery = useMemo<string | undefined>(() => {
    if (!searchCriteria || !searchFields?.length) {
      return undefined;
    }

    const firstSearchFieldCriteria = Object.entries(searchCriteria).find(
      ([fieldName]) => searchFields.includes(fieldName)
    )?.[1];
    if (!firstSearchFieldCriteria) {
      return undefined;
    }

    const firstSearchFieldCriteriaValue = Array.isArray(
      firstSearchFieldCriteria
    )
      ? firstSearchFieldCriteria[0].value
      : firstSearchFieldCriteria.value;
    return `${firstSearchFieldCriteriaValue}`;
  }, [searchCriteria, searchFields]);

  const clearSearch = useCallback(() => {
    searchList(undefined);
  }, [searchList]);

  const search = useCallback(
    (newSearchQuery: string) => {
      if (!searchFields?.length) {
        return;
      }
      searchList(createSearchCriteria(newSearchQuery, searchFields));
    },
    [searchFields, searchList]
  );

  return {
    entityNamings,
    clearSearch,
    search,
    searchFields,
    searchQuery,
  };
};

export default useCrudListSearch;
