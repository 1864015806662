import { ComponentMultiStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const parts = ['container', 'header', 'body'];

const card: ComponentMultiStyleConfig = {
  parts,
  baseStyle: {
    container: {
      borderRadius: 'md',
    },
    header: {
      borderBottomWidth: 1,
    },
    footer: {
      borderTopWidth: 1,
    },
  },
  variants: {
    elevated: {
      container: {
        bg: 'chakra-subtle-bg',
        boxShadow: 'base',
      },
    },
    ghost: (props) => ({
      container: {
        borderStyle: 'dashed',
        borderWidth: 2,
        borderColor: mode('border-light', 'border-dark')(props),
      },
    }),
  },
  sizes: {
    sm: {
      header: {
        padding: 3,
      },
      body: {
        padding: 3,
      },
      footer: {
        padding: 3,
      },
    },
    md: {
      header: {
        padding: 4,
      },
      body: {
        padding: 4,
      },
      footer: {
        padding: 4,
      },
    },
  },
};

export default card;
