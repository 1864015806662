import React, { useMemo } from 'react';

import { VStack } from '@chakra-ui/react';

import { AppError } from '@/types/errors';

import { ErrorAlert } from '@/components/common/errors/ErrorAlert';

export interface ErrorAlertsProps {
  errors: Array<AppError | undefined>;
}

export const ErrorAlerts: React.FC<ErrorAlertsProps> = ({ errors }) => {
  const errorsToShow = useMemo(
    () => errors.filter((error): error is AppError => error !== undefined),
    [errors]
  );

  return errorsToShow.length > 0 ? (
    <VStack spacing={2} data-testid="errors">
      {errorsToShow.map((error) => (
        <ErrorAlert key={error.message} error={error} />
      ))}
    </VStack>
  ) : null;
};
