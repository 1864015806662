import { AnyAction } from 'redux';
import { call, takeEvery, select, StrictEffect } from 'redux-saga/effects';

import { DataType, Id } from '@m3ter-com/m3ter-api';

import {
  dataCreated,
  dataDeleted,
  dataUpdated,
  selectById,
  DataCreatedAction,
  DataUpdatedAction,
  DataDeletedAction,
} from '@/store/data/data';
import { retrieveData } from '@/store/data/data.saga';

type DataChangeAction =
  | DataCreatedAction
  | DataUpdatedAction
  | DataDeletedAction;

export function* billLineItemChangeSaga(
  action: DataChangeAction
): Generator<StrictEffect, void, any> {
  const billId = action.meta.pathParams?.billId;

  if (billId) {
    // Check the bill has been loaded.
    const billsSelector = yield call(selectById, DataType.Bill, billId as Id);
    const bill = yield select(billsSelector);

    if (bill) {
      yield call(retrieveData, DataType.Bill, billId as Id);
    }
  }
}

// When credit or debit line item(s) are created/updated/deleted we need to
// reload the relevant bill(s).
export default function* billLineItemsSaga() {
  yield takeEvery(
    (action: AnyAction) =>
      (action.type === dataCreated.type ||
        action.type === dataUpdated.type ||
        action.type === dataDeleted.type) &&
      (action.payload.dataType === DataType.CreditLineItem ||
        action.payload.dataType === DataType.DebitLineItem),
    billLineItemChangeSaga
  );
}
