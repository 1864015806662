import { ComponentMultiStyleConfig } from '@chakra-ui/react';
import { getColor } from '@chakra-ui/theme-tools';

const parts = [
  'calendarContentWrapper',
  'calendarControlsWrapper',
  'calendarGrid',
  'calendarGridHeader',
  'calendarControlButton',
  'calendarOpenButton',
  'calendarOpenButtonWrapper',
  'timeZoneLabelWrapper',
  'rangeIndicator',
];

const datePicker: ComponentMultiStyleConfig = {
  parts,
  baseStyle: (props) => ({
    calendarContentWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '10px',
    },
    calendarControlButton: {
      background: 'transparent',
    },
    calendarControlsWrapper: {
      marginTop: 2,
      marginBottom: 4,
    },
    calendarGrid: {
      display: 'grid',
      gridTemplateRows: 'repeat(7, 1fr)',
      gridTemplateColumns: 'repeat(7, 1fr)',
      width: '100%',
      height: '100%',
    },
    calendarGridHeader: {
      fontWeight: 900,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    calendarHeader: {
      fontSize: 'xl',
      textAlign: 'center',
    },
    calendarOpenButton: {
      position: 'relative',
      borderRadius: 'inherit',
    },
    calendarOpenButtonWrapper: {
      padding: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: props.isClearable ? 0 : 'md',
      borderBottomRightRadius: props.isClearable ? 0 : 'md',
      borderBottomLeftRadius: 0,
    },
    calendarPopover: {
      width: '400px',
      display: props.isCalendarOpen ? 'block' : 'none',
    },
    clearButtonWrapper: {
      padding: 0,
    },
    dateInput: {
      borderRightRadius: 0,
      borderColor: props.isFocussed ? 'misc-highlight' : 'border-light',
      boxShadow: props.isFocussed
        ? `0 0 0 1px ${getColor(props.theme, 'misc-highlight')}`
        : 'inherit',
    },
    timeSelectWrapper: {
      width: '100%',
    },
    timeZoneLabelWrapper: {
      borderRadius: 0,
    },
    rangeIndicator: {
      display: 'flex',
      alignSelf: 'stretch',
      padding: 2,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderColor: 'border-light',
      alignItems: 'center',
      bgColor: 'white',
      color: 'text-light',
    },
  }),
};

export default datePicker;
