import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

import { Notification, NotificationType } from '@/types/notifications';

const name = 'notifications';

export type NotificationsState = Array<Notification>;

export interface AddNotificationPayload {
  id: string;
  type: NotificationType;
  message: string;
  removeAfter: number;
}

export interface RemoveNotificationPayload {
  id: string;
}

const initialState: NotificationsState = [];

const notificationSlice = createSlice({
  name,
  initialState,
  reducers: {
    addNotification: {
      reducer: (
        state: NotificationsState,
        action: PayloadAction<AddNotificationPayload>
      ) => {
        const { id, type, message } = action.payload;
        return [{ id, type, message }, ...state];
      },
      prepare: (
        message: string,
        type: NotificationType = 'info',
        removeAfter: number = 0
      ) => ({
        payload: { id: v4(), message, type, removeAfter },
      }),
    },
    removeNotification: {
      reducer: (
        state: NotificationsState,
        action: PayloadAction<RemoveNotificationPayload>
      ) => {
        const { id } = action.payload;
        return state.filter((n) => n.id !== id);
      },
      prepare: (id: string) => ({ payload: { id } }),
    },
    clearNotifications: () => [],
  },
});

// Export action creators.

export const { addNotification, removeNotification, clearNotifications } =
  notificationSlice.actions;

// Selectors.

export const selectNotifications = (state: {
  notifications: NotificationsState;
}): NotificationsState => state.notifications;

// Default export is the reducer.
export default notificationSlice.reducer;
