import React from 'react';

import { Outlet } from 'react-router-dom';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';

export const ReportsRootRoute: React.FC = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <BreadcrumbItem title={t('features:reports.title')} />
      <Outlet />
    </React.Fragment>
  );
};
