import { NamedRoute } from '@/types/routes';

import setupConfigsRoute from './configs';
import setupCredentialsRoute from './credentials';
import setupDestinationsRoute from './destinations';
import setupEventsRoute from './events';
import setupExternalMappingsRoute from './external-mappings';
import setupRunsRoute from './runs';

export default (): NamedRoute => ({
  name: 'integrations',
  path: 'integrations',
  children: [
    setupConfigsRoute(),
    setupCredentialsRoute(),
    setupDestinationsRoute(),
    setupEventsRoute(),
    setupExternalMappingsRoute(),
    setupRunsRoute(),
  ],
});
