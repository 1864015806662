import React from 'react';

import { FaBuilding, FaCubes, FaTrashAlt, FaUser } from 'react-icons/fa';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Navigation } from '@m3ter-com/ui-components';

import { NamedNavLink } from '@/components/common/navigation/NamedNavLink';
import { CrudRouteType, getCrudRouteName } from '@/routes/crud';

export const AdminNavigation: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Navigation>
      <NamedNavLink
        name={getCrudRouteName(DataType.OrganizationAdmin, CrudRouteType.List)}
        icon={<FaCubes />}
      >
        {t('common:organizations')}
      </NamedNavLink>
      <NamedNavLink
        name={getCrudRouteName(DataType.Customer, CrudRouteType.List)}
        icon={<FaBuilding />}
      >
        {t('common:customers')}
      </NamedNavLink>
      <NamedNavLink
        name={getCrudRouteName(DataType.UserAdmin, CrudRouteType.List)}
        icon={<FaUser />}
      >
        {t('common:users')}
      </NamedNavLink>
      <NamedNavLink
        name={getCrudRouteName(
          DataType.MeasurmentsDeletion,
          CrudRouteType.List
        )}
        icon={<FaTrashAlt />}
      >
        {t('common:measurementsDeletions')}
      </NamedNavLink>
    </Navigation>
  );
};
