import React, { useMemo } from 'react';

import { Destination } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds } from '@/types/lists';

import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';

const searchFields = ['name', 'code'];

export const DestinationsListRoute: React.FC = () => {
  const { t } = useTranslation();

  const columns = useMemo<Array<ColumnDefinition<Destination>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: 'name',
      },
      {
        id: 'code',
        header: t('forms:labels.code'),
        accessor: 'code',
      },
      {
        id: 'description',
        header: t('forms:labels.description'),
        accessor: 'description',
      },
    ],
    [t]
  );

  return (
    <CrudList<Destination>
      listId={EntityRouteListIds.Destination}
      columns={columns}
      searchFields={searchFields}
    />
  );
};
