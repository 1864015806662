import React from 'react';

import { ExternalMapping } from '@m3ter-com/m3ter-api';

import { ExternalMappingEditForm } from '@/components/features/external-mappings/ExternalMappingForm';
import { CrudEdit } from '@/components/common/crud/CrudEdit';

export const ExternalMappingsEditRoute: React.FC = () => {
  return <CrudEdit<ExternalMapping> form={ExternalMappingEditForm} />;
};
