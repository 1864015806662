import React from 'react';

import { Customer } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { CustomerForm } from '@/components/features/admin/customers/CustomerForm';

export const CustomersAdminEditRoute: React.FC = () => (
  <CrudEdit<Customer> form={CustomerForm} />
);
