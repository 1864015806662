import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DataType,
  AccountPlan,
  Commitment,
  Id,
  Plan,
  PlanGroup,
  Product,
} from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

import {
  CompletionMeta,
  NotificationDefinition,
  RequestMeta,
} from '@/store/store';
import { selectAllByDataType } from '@/store/data/data';

export interface PlanAccountPlan extends AccountPlan {
  plan: Plan;
  product: Product;
}
export interface PlanAccountPlansByProduct {
  [productId: string]: Array<PlanAccountPlan>;
}

export interface PlanGroupAccountPlan extends AccountPlan {
  planGroup: PlanGroup;
}

export interface PlanAccountPlanDatum {
  accountPlanId: string;
  planId: string;
  productId: string;
}
export interface PlanAccountPlanDataByProduct {
  [productId: string]: Array<PlanAccountPlanDatum>;
}

export interface PlanGroupAccountPlanDatum {
  accountPlanId: string;
  planGroupId: string;
}

export interface AccountPlansState {
  activeAndPendingPlanGroupsData: Array<PlanGroupAccountPlanDatum>;
  activeAndPendingPlansData: PlanAccountPlanDataByProduct;
  previousPlanGroupsData: Array<PlanGroupAccountPlanDatum>;
  previousPlansData: PlanAccountPlanDataByProduct;
  isLoading: boolean;
  error?: AppError;
  isEndingPlan?: boolean;
  endingPlanError?: AppError;
  isLoadingProductPlans?: boolean;
  productPlanIds?: Array<Id>;
  productPlansError?: AppError;
}

interface LoadAccountPlansPayload {
  accountId: string;
}
export type LoadAccountPlansAction = PayloadAction<LoadAccountPlansPayload>;

interface LoadAccountPlansSuccessPayload {
  activeAndPendingPlanGroupsData: Array<PlanGroupAccountPlanDatum>;
  activeAndPendingPlansData: PlanAccountPlanDataByProduct;
  previousPlanGroupsData: Array<PlanGroupAccountPlanDatum>;
  previousPlansData: PlanAccountPlanDataByProduct;
}
export type LoadAccountPlansSuccessAction =
  PayloadAction<LoadAccountPlansSuccessPayload>;

export type LoadAccountPlansFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

interface EndAccountPlanPayload {
  accountPlanId: Id;
  endDate: string;
}

export type EndAccountPlanAction = PayloadAction<
  EndAccountPlanPayload,
  string,
  RequestMeta
>;

export type EndAccountPlanSuccessAction = PayloadAction<
  undefined,
  string,
  CompletionMeta
>;

export type EndAccountPlanFailureAction = PayloadAction<
  undefined,
  string,
  CompletionMeta,
  AppError
>;

export const initialState: AccountPlansState = {
  activeAndPendingPlanGroupsData: [],
  activeAndPendingPlansData: {},
  previousPlanGroupsData: [],
  previousPlansData: {},
  isLoading: false,
};
const name = 'features/accounts/accountPlans';

const accountPlansState = createSlice({
  name,
  initialState,
  reducers: {
    loadAccountPlans: {
      reducer: (state: AccountPlansState, _action: LoadAccountPlansAction) => {
        state.isLoading = true;
      },
      prepare: (accountId: string) => ({ payload: { accountId } }),
    },
    loadAccountPlansSuccess: {
      reducer: (
        state: AccountPlansState,
        action: LoadAccountPlansSuccessAction
      ) => {
        state.activeAndPendingPlanGroupsData =
          action.payload.activeAndPendingPlanGroupsData;
        state.activeAndPendingPlansData =
          action.payload.activeAndPendingPlansData;
        state.previousPlanGroupsData = action.payload.previousPlanGroupsData;
        state.previousPlansData = action.payload.previousPlansData;
        state.error = undefined;
        state.isLoading = false;
      },
      prepare: (
        activeAndPendingPlanGroupsData: Array<PlanGroupAccountPlanDatum>,
        activeAndPendingPlansData: PlanAccountPlanDataByProduct,
        previousPlanGroupsData: Array<PlanGroupAccountPlanDatum>,
        previousPlansData: PlanAccountPlanDataByProduct
      ) => ({
        payload: {
          activeAndPendingPlanGroupsData,
          activeAndPendingPlansData,
          previousPlanGroupsData,
          previousPlansData,
        },
      }),
    },
    loadAccountPlansFailure: {
      reducer: (
        state: AccountPlansState,
        action: LoadAccountPlansFailureAction
      ) => {
        state.error = action.error;
        state.isLoading = false;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    endAccountPlan: {
      reducer: (state: AccountPlansState, _action: EndAccountPlanAction) => {
        state.endingPlanError = undefined;
        state.isEndingPlan = true;
      },
      prepare: (
        accountPlanId: Id,
        endDate: string,
        successNotification?: NotificationDefinition,
        failureNotification?: NotificationDefinition
      ) => ({
        payload: { accountPlanId, endDate },
        meta: {
          onSuccess: {
            notification: successNotification,
          },
          onFailure: {
            notification: failureNotification,
          },
        },
      }),
    },
    endAccountPlanSuccess: {
      reducer: (
        state: AccountPlansState,
        _action: EndAccountPlanSuccessAction
      ) => {
        state.isEndingPlan = false;
      },
      prepare: (meta?: any) => ({
        payload: undefined,
        meta,
      }),
    },
    endAccountPlanFailure: {
      reducer: (
        state: AccountPlansState,
        action: EndAccountPlanFailureAction
      ) => {
        state.isEndingPlan = false;
        state.endingPlanError = action.error;
      },
      prepare: (error: AppError, meta?: any) => ({
        payload: undefined,
        error,
        meta,
      }),
    },
    reset: () => initialState,
  },
});

export default accountPlansState.reducer;

export const {
  endAccountPlan,
  endAccountPlanFailure,
  endAccountPlanSuccess,
  loadAccountPlans,
  loadAccountPlansFailure,
  loadAccountPlansSuccess,
  reset,
} = accountPlansState.actions;

const selectAccountPlansState = (state: {
  features: { accounts: { accountPlans: AccountPlansState } };
}): AccountPlansState => state.features.accounts.accountPlans;

export const selectIsAccountPlansLoading = createSelector(
  selectAccountPlansState,
  (state) => state.isLoading
);

export const selectAccountPlansError = createSelector(
  selectAccountPlansState,
  (state) => state.error
);

const selectAllAccountPlans = selectAllByDataType<Commitment>(
  DataType.AccountPlan
);
const selectAllPlans = selectAllByDataType<Plan>(DataType.Plan);
const selectAllProducts = selectAllByDataType<Product>(DataType.Product);
const selectAllPlanGroups = selectAllByDataType<PlanGroup>(DataType.PlanGroup);

const buildPlanAccountPlans = (
  planAccountPlanDataByProduct: PlanAccountPlanDataByProduct,
  accountPlans: Array<AccountPlan>,
  plans: Array<Plan>,
  products: Array<Product>
): PlanAccountPlansByProduct => {
  const result: PlanAccountPlansByProduct = {};

  Object.entries(planAccountPlanDataByProduct).forEach(
    ([productId, planAccountPlanData]) => {
      const product = products.find((p) => p.id === productId);
      if (!product) {
        return;
      }

      planAccountPlanData.forEach((datum) => {
        const accountPlan = accountPlans.find(
          (ap) => ap.id === datum.accountPlanId
        );
        const plan = plans.find((p) => p.id === datum.planId);
        if (accountPlan && plan) {
          if (!result[productId]) {
            result[productId] = new Array<PlanAccountPlan>();
          }

          result[productId].push({
            ...accountPlan,
            plan,
            product,
          });
        }
      });
    }
  );

  return result;
};

export const selectActiveAndPendingPlanAccountPlans = createSelector(
  selectAccountPlansState,
  selectAllAccountPlans,
  selectAllPlans,
  selectAllProducts,
  (state, accountPlans, plans, products) =>
    buildPlanAccountPlans(
      state.activeAndPendingPlansData,
      accountPlans,
      plans,
      products
    )
);

export const selectPreviousPlanAccountPlans = createSelector(
  selectAccountPlansState,
  selectAllAccountPlans,
  selectAllPlans,
  selectAllProducts,
  (state, accountPlans, plans, products) =>
    buildPlanAccountPlans(
      state.previousPlansData,
      accountPlans,
      plans,
      products
    )
);

const buildPlanGroupAccountPlans = (
  planGroupData: Array<PlanGroupAccountPlanDatum>,
  accountPlans: Array<AccountPlan>,
  planGroups: Array<PlanGroup>
): Array<PlanGroupAccountPlan> =>
  planGroupData
    .map((datum) => {
      const accountPlan = accountPlans.find(
        (ap) => ap.id === datum.accountPlanId
      );
      const planGroup = planGroups.find((pg) => pg.id === datum.planGroupId);
      if (!accountPlan || !planGroup) {
        return undefined;
      }

      return {
        ...accountPlan,
        planGroup,
      };
    })
    .filter(Boolean) as Array<PlanGroupAccountPlan>;

export const selectActiveAndPendingPlanGroupAccountPlans = createSelector(
  selectAccountPlansState,
  selectAllAccountPlans,
  selectAllPlanGroups,
  (state, accountPlans, planGroups) =>
    buildPlanGroupAccountPlans(
      state.activeAndPendingPlanGroupsData,
      accountPlans,
      planGroups
    )
);

export const selectPreviousPlanGroupAccountPlans = createSelector(
  selectAccountPlansState,
  selectAllAccountPlans,
  selectAllPlanGroups,
  (state, accountPlans, planGroups) =>
    buildPlanGroupAccountPlans(
      state.previousPlanGroupsData,
      accountPlans,
      planGroups
    )
);
