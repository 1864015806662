import React, { ReactNode } from 'react';

import { LoaderFunction } from 'react-router-dom';

import { DataType, Entity } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { Accessor } from '@/util/data';
import { CrudRetrieve } from '@/components/common/crud/CrudRetrieve';

interface CrudRouteConfig<T> {
  path: string;
  dataType: DataType;
  element?: ReactNode;
  list?: ReactNode;
  listLoader?: LoaderFunction;
  details?: ReactNode;
  create?: ReactNode;
  edit?: ReactNode;
  retrieve?: ReactNode;
  entityChildRoutes?: Array<NamedRoute>;
  directChildRoutes?: Array<NamedRoute>;
  titleAccessor?: Accessor<T>;
}

export enum CrudRouteType {
  List = 'list',
  Details = 'details',
  Create = 'create',
  Edit = 'edit',
}

export const getCrudRouteName = (dataType: DataType, type: CrudRouteType) =>
  `${dataType}.${type}`;

export const getIdParamName = (dataType: DataType) => `${dataType}Id`;

const createCrudChildRoutes = <EntityType extends Entity = Entity>(
  config: CrudRouteConfig<EntityType>
): Array<NamedRoute> => {
  const {
    dataType,
    list,
    listLoader,
    details,
    create,
    edit,
    retrieve,
    titleAccessor = 'name' as Accessor<EntityType>,
    entityChildRoutes = [],
    directChildRoutes = [],
  } = config;

  const routes = new Array<NamedRoute>();

  if (list) {
    routes.push({
      index: true,
      name: getCrudRouteName(dataType, CrudRouteType.List),
      element: list,
      loader: listLoader,
    });
  }

  // If there is a details view or any entity child routes we need a wrapping
  // component that retrieves them.

  if (details || entityChildRoutes.length > 0) {
    routes.push({
      path: `:${getIdParamName(dataType)}`,
      element: retrieve ?? (
        <CrudRetrieve<EntityType> titleAccessor={titleAccessor} />
      ),
      children: details
        ? [
            {
              index: true,
              name: getCrudRouteName(dataType, CrudRouteType.Details),
              element: details,
            },
            ...entityChildRoutes,
          ]
        : entityChildRoutes,
    });
  }

  if (create) {
    routes.push({
      name: getCrudRouteName(dataType, CrudRouteType.Create),
      path: 'create',
      element: create,
    });
  }

  if (edit) {
    routes.push({
      name: getCrudRouteName(dataType, CrudRouteType.Edit),
      path: `:${getIdParamName(dataType)}/edit`,
      element: edit,
    });
  }

  routes.push(...directChildRoutes);

  return routes;
};

export const createCrudRoute = <EntityType extends Entity = Entity>(
  config: CrudRouteConfig<EntityType>
): NamedRoute => {
  const { path, element } = config;

  return {
    name: path,
    path,
    element,
    children: createCrudChildRoutes<EntityType>(config),
  };
};
