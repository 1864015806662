import React from 'react';

import { Textarea, TextareaProps } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

export interface FormTextareaProps extends TextareaProps {
  name: string;
}

export const FormTextarea: React.FC<FormTextareaProps> = ({
  name,
  ...props
}) => {
  const { register } = useFormContext();

  return (
    <Textarea
      {...register(name, {
        disabled: props.isDisabled,
      })}
      {...props}
    />
  );
};
