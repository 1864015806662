import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const parts = ['container', 'item', 'icon'];

const list: ComponentMultiStyleConfig = {
  parts,
  baseStyle: {
    container: {
      listStylePosition: 'inside',
      marginInlineStart: 0,
    },
  },
};

export default list;
