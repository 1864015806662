import React, { ComponentProps, useMemo } from 'react';

import { CurrencyCode } from '@m3ter-com/m3ter-api';
import { Select, SelectOption } from '@m3ter-com/ui-components';

import { getAllCurrencies } from '@/data/currencies';

export interface CurrencySelectProps
  extends Omit<ComponentProps<typeof Select>, 'options'> {
  currencies?: Array<CurrencyCode>; // Optional list of codes to limit the options.
}

export const CurrencySelect: React.FC<CurrencySelectProps> = ({
  currencies,
  ...selectProps
}) => {
  const options: Array<SelectOption> = useMemo(() => {
    const allCurrencies = getAllCurrencies();

    // Use the specified codes or default to all currencies sorted by code.
    const codes = currencies ?? Object.keys(allCurrencies).sort();

    return codes.map((code) => {
      // Try to find the currency or fall back to just showing the code, for custom currencies.
      const currency = allCurrencies[code];
      return {
        value: code,
        label: currency
          ? `${code} - ${allCurrencies[code].name} (${allCurrencies[code].symbol})`
          : code,
      };
    });
  }, [currencies]);

  return (
    <Select<CurrencyCode>
      isSearchable
      searchMatchFrom="any"
      options={options}
      {...selectProps}
    />
  );
};
