import React, { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import {
  Box,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Spinner,
} from '@chakra-ui/react';

import {
  DataType,
  Aggregation,
  CompoundAggregation,
  Id,
  Counter,
  CounterPricing,
  Plan,
  PlanTemplate,
  Pricing,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert, Button, Select, SelectOption } from '@m3ter-com/ui-components';

import { PricingUsageEntity } from '@/types/data';

import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import {
  removePricingUsageEntity,
  PlanDetailsPricingDataEntities,
} from '@/store/features/pricing/planDetails';
import { PlanDetails } from '@/components/features/pricing/PlanDetails';
import { PricingGrid } from '@/components/features/pricing/grid/PricingGrid';
import { PlanGroupAddPlansButton } from '@/components/features/pricing/PlanGroupAddPlansButton';
import { PlanDetailsPricingGridActions } from '@/components/features/pricing/PlanDetailsPricingGridActions';
import usePlanPricingQueryParams from '@/hooks/features/pricing/usePlanPricingQueryParams';

export interface PlanGroupPlanDetailsAndPricingProps {
  accountId: Id;
  extraPricingAggregations: Array<Aggregation>;
  extraPricingCompoundAggregations: Array<CompoundAggregation>;
  extraPricingItemCounters: Array<Counter>;
  isPlanGroupCustom: boolean;
  isLoading: boolean;
  pricingData: PlanDetailsPricingDataEntities;
  onAddPlansToPlanGroup: (planIds: string[]) => void;
}

const emptyArray = new Array<any>();

export const PlanGroupPlanDetailsAndPricing: React.FC<
  PlanGroupPlanDetailsAndPricingProps
> = ({
  accountId,
  extraPricingAggregations,
  extraPricingCompoundAggregations,
  extraPricingItemCounters,
  isPlanGroupCustom,
  isLoading,
  pricingData,
  onAddPlansToPlanGroup,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { removeUsageEntitiesFromQueryParams } = usePlanPricingQueryParams();

  const planOptions = useMemo<Array<SelectOption>>(
    () =>
      orderBy(
        pricingData.plans.map((plan) => ({ label: plan.name, value: plan.id })),
        'label'
      ),
    [pricingData]
  );

  // We use the url as the source of truth to persist the selected plan id so
  // we can re-select the plan when returning to the page from the pricing edit page.
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedPlanId = searchParams.get('selectedPlanId');

  const onSelectPlan = useCallback(
    (newPlanId: string) => {
      setSearchParams({ selectedPlanId: newPlanId }, { replace: true });
    },
    [setSearchParams]
  );

  const selectedPlan = useMemo<Plan | undefined>(
    () =>
      selectedPlanId
        ? pricingData.plans.find((plan) => plan.id === selectedPlanId)
        : undefined,
    [pricingData, selectedPlanId]
  );
  const relevantPlanTemplate = useMemo<PlanTemplate | undefined>(
    () =>
      selectedPlan
        ? pricingData.planTemplates.find(
            (planTemplate) => planTemplate.id === selectedPlan.planTemplateId
          )
        : undefined,
    [pricingData, selectedPlan]
  );
  const selectedPlans = useMemo<Array<Plan>>(
    () => (selectedPlan ? [selectedPlan] : []),
    [selectedPlan]
  );
  const relevantPlanTemplates = useMemo<Array<PlanTemplate>>(
    () => (relevantPlanTemplate ? [relevantPlanTemplate] : []),
    [relevantPlanTemplate]
  );
  const relevantPricings = useMemo<Array<Pricing>>(() => {
    const planPricings = selectedPlan
      ? pricingData.pricings.filter(
          (pricing) => !!pricing.planId && pricing.planId === selectedPlan.id
        )
      : [];
    const planTemplatePricings = relevantPlanTemplate
      ? pricingData.pricings.filter(
          (pricing) =>
            !!pricing.planTemplateId &&
            pricing.planTemplateId === relevantPlanTemplate.id
        )
      : [];

    return [...planPricings, ...planTemplatePricings];
  }, [pricingData, selectedPlan, relevantPlanTemplate]);
  const relevantItemCounterPricings = useMemo<Array<CounterPricing>>(() => {
    const planItemCounterPricings = selectedPlan
      ? pricingData.itemCounterPricings.filter(
          (itemCounterPricing) =>
            !!itemCounterPricing.planId &&
            itemCounterPricing.planId === selectedPlan.id
        )
      : [];
    const planTemplateItemCounterPricings = relevantPlanTemplate
      ? pricingData.itemCounterPricings.filter(
          (itemCounterPricing) =>
            !!itemCounterPricing.planTemplateId &&
            itemCounterPricing.planTemplateId === relevantPlanTemplate.id
        )
      : [];

    return [...planItemCounterPricings, ...planTemplateItemCounterPricings];
  }, [pricingData, selectedPlan, relevantPlanTemplate]);
  const relevantAggregations = useMemo<Array<Aggregation>>(
    () =>
      pricingData.aggregations.filter((aggregation) =>
        relevantPricings.some(
          (pricing) =>
            !!pricing.aggregationId && pricing.aggregationId === aggregation.id
        )
      ),
    [pricingData, relevantPricings]
  );
  const selectedAggregations = useMemo<Array<Aggregation>>(
    () => [...relevantAggregations, ...extraPricingAggregations],
    [extraPricingAggregations, relevantAggregations]
  );
  const relevantCompoundAggregations = useMemo<Array<CompoundAggregation>>(
    () =>
      pricingData.compoundAggregations.filter((compoundAggregation) =>
        relevantPricings.some(
          (pricing) =>
            !!pricing.compoundAggregationId &&
            pricing.compoundAggregationId === compoundAggregation.id
        )
      ),
    [pricingData, relevantPricings]
  );
  const selectedCompoundAggregations = useMemo<Array<CompoundAggregation>>(
    () => [
      ...relevantCompoundAggregations,
      ...extraPricingCompoundAggregations,
    ],
    [extraPricingCompoundAggregations, relevantCompoundAggregations]
  );
  const relevantItemCounters = useMemo<Array<Counter>>(
    () =>
      pricingData.itemCounters.filter((itemCounter) =>
        relevantItemCounterPricings.some(
          (itemCounterPricing) =>
            !!itemCounterPricing.counterId &&
            itemCounterPricing.counterId === itemCounter.id
        )
      ),
    [pricingData, relevantItemCounterPricings]
  );
  const selectedItemCounters = useMemo<Array<Counter>>(
    () => [...relevantItemCounters, ...extraPricingItemCounters],
    [extraPricingItemCounters, relevantItemCounters]
  );

  const onRemovePricingUsageEntity = useCallback(
    (pricingUsageEntity: PricingUsageEntity, isCompound?: boolean) => {
      dispatch(removePricingUsageEntity(pricingUsageEntity.id, isCompound));
      removeUsageEntitiesFromQueryParams(pricingUsageEntity.id, isCompound);
    },
    [dispatch, removeUsageEntitiesFromQueryParams]
  );

  const isCustomPlan = !!selectedPlan?.accountId;

  if (!pricingData.planGroup) {
    return null;
  }

  return (
    <React.Fragment>
      <Flex justifyContent="space-between" alignItems="flex-end" gap={4}>
        <HStack w="50%">
          <FormLabel mb={0} whiteSpace="nowrap">
            {t('features:account.selectPlan')}:
          </FormLabel>
          <Box w="100%">
            <Select
              isSearchable
              isDisabled={planOptions.length === 0}
              options={planOptions}
              onChange={onSelectPlan as (planId: string | null) => void} // We can safely cast here since we aren't making the <Select> clearable
              value={selectedPlanId}
              placeholder={t('features:account.selectPlan')}
            />
          </Box>
        </HStack>
        <ButtonGroup>
          <Button
            as={CrudDetailsLink}
            dataType={DataType.PlanGroup}
            id={pricingData.planGroup!.id}
            isDisabled={!pricingData.plans.length}
          >
            {t('features:planGroups.editLinkedPlans')}
          </Button>
          <PlanGroupAddPlansButton
            accountId={accountId}
            size="md"
            onAddPlans={onAddPlansToPlanGroup}
          />
        </ButtonGroup>
      </Flex>
      {!pricingData.plans.length && (
        <Alert status="info" w="100%">
          {t('features:planGroups.noPlansInPlanGroup')}
        </Alert>
      )}
      {selectedPlan && (
        <React.Fragment>
          {relevantPlanTemplate && (
            <PlanDetails
              plan={selectedPlan}
              planTemplate={relevantPlanTemplate}
            />
          )}
          <Card>
            <CardHeader>
              <Heading size="md">
                {t('features:pricing.associatedPricing')}
              </Heading>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Spinner data-testid="plan-group-plan-details-and-pricing-loading" />
              ) : (
                <React.Fragment>
                  <PlanDetailsPricingGridActions
                    productId={relevantPlanTemplate?.productId}
                    selectedAggregations={selectedAggregations}
                    selectedCompoundAggregations={selectedCompoundAggregations}
                    selectedItemCounters={selectedItemCounters}
                  />
                  <PricingGrid
                    onRemovePricingUsageEntity={onRemovePricingUsageEntity}
                    linkedPlanTemplates={relevantPlanTemplates}
                    selectedAggregations={selectedAggregations}
                    selectedCompoundAggregations={selectedCompoundAggregations}
                    selectedItemCounters={selectedItemCounters}
                    selectedPlans={selectedPlans}
                    selectedPlanTemplates={emptyArray}
                    pricings={relevantPricings}
                    itemCounterPricings={relevantItemCounterPricings}
                    canEditPlanOrTemplate={false}
                    canEditPricing={isPlanGroupCustom && isCustomPlan}
                    canEditTemplatePricing={false}
                  />
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
