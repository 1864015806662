import { useMemo } from 'react';

import { ColumnDefinition, ColumnDisplay } from './types';

const useColumnDisplay = <D>(
  columnDefinitions: Array<ColumnDefinition<D>>,
  columnDisplay?: Array<ColumnDisplay>
) => {
  const visibleColumns: Array<ColumnDefinition<D>> = useMemo(() => {
    if (!columnDisplay) {
      return columnDefinitions;
    }
    return columnDisplay
      .filter((display) => display.visible)
      .map((display) =>
        columnDefinitions.find((column) => column.id === display.id)
      )
      .filter(Boolean) as Array<ColumnDefinition<D>>;
  }, [columnDefinitions, columnDisplay]);

  return { visibleColumns };
};

export default useColumnDisplay;
