import React, { ReactElement, ReactNode } from 'react';

import { Flex } from '@chakra-ui/react';

import { Entity } from '@m3ter-com/m3ter-api';

import {
  CrudListContextProvider,
  CrudListContextProviderProps,
} from './CrudListContext';
import { CrudListHeader } from './CrudListHeader';
import { CrudListTable, CrudListTableProps } from './CrudListTable';
import { CrudListFooter } from './CrudListFooter';

export type DefaultCrudListProps<E extends Entity> =
  CrudListContextProviderProps<E> &
    CrudListTableProps<E> & { children?: never };

export type CustomCrudListProps<E extends Entity> =
  CrudListContextProviderProps<E> & { children: ReactNode };

export type CrudListProps<E extends Entity> =
  | DefaultCrudListProps<E>
  | CustomCrudListProps<E>;

const isRenderingDefaultCrudList = <E extends Entity>(
  remainingProps: Omit<CrudListProps<E>, keyof CrudListContextProviderProps<E>>
): remainingProps is Omit<
  DefaultCrudListProps<E>,
  keyof CrudListContextProviderProps<E>
> => React.Children.count(remainingProps.children) === 0;

export function CrudList<E extends Entity = Entity>({
  actionName,
  createRouteLabel,
  createRouteName,
  createRouteParams,
  dataType,
  detailsRouteName,
  editRouteName,
  filterDefinitions,
  initialFilterCriteria,
  initialSearchCriteria,
  initialSortCriteria,
  isItemDeletable,
  isItemEditable,
  listId,
  loadOnMount = true,
  onDelete,
  params,
  pathParams,
  relationships,
  searchFields,
  searchOperator,
  ...rest
}: CrudListProps<E>): ReactElement<any, any> | null {
  return (
    <CrudListContextProvider
      actionName={actionName}
      createRouteLabel={createRouteLabel}
      createRouteName={createRouteName}
      createRouteParams={createRouteParams}
      dataType={dataType}
      detailsRouteName={detailsRouteName}
      editRouteName={editRouteName}
      filterDefinitions={filterDefinitions}
      initialFilterCriteria={initialFilterCriteria}
      initialSearchCriteria={initialSearchCriteria}
      initialSortCriteria={initialSortCriteria}
      isItemDeletable={isItemDeletable}
      isItemEditable={isItemEditable}
      listId={listId}
      loadOnMount={loadOnMount}
      onDelete={onDelete}
      params={params}
      pathParams={pathParams}
      relationships={relationships}
      searchFields={searchFields}
      searchOperator={searchOperator}
    >
      <Flex
        alignItems="stretch"
        flexFlow="column nowrap"
        gap={4}
        justifyContent="flex-start"
        width="100%"
      >
        {isRenderingDefaultCrudList(rest) ? (
          <React.Fragment>
            <CrudListHeader />
            <CrudListTable {...rest} />
            <CrudListFooter />
          </React.Fragment>
        ) : (
          rest.children
        )}
      </Flex>
    </CrudListContextProvider>
  );
}
