import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';

import { DataType, PlanTemplate } from '@m3ter-com/m3ter-api';

import { ids } from '@/util/data';
import { extractError } from '@/util/error';
import { listAllData } from '@/store/data/data.saga';

import {
  loadPlanTemplates,
  loadPlanTemplatesFailure,
  loadPlanTemplatesSuccess,
} from './planTemplates';

export function* loadPlanTemplatesSaga(): Generator<StrictEffect, void, any> {
  try {
    const planTemplates: Array<PlanTemplate> = yield call(
      listAllData,
      DataType.PlanTemplate
    );
    yield put(loadPlanTemplatesSuccess(ids(planTemplates)));
  } catch (error) {
    yield put(loadPlanTemplatesFailure(extractError(error)));
  }
}
export default function* planTemplatesSaga(): Generator<
  StrictEffect,
  void,
  any
> {
  yield takeLatest(loadPlanTemplates.type, loadPlanTemplatesSaga);
}
