import { combineReducers } from 'redux';

import externalMappingsReducer from './external-mappings/externalMappings';
import integrationRunsReducer from './integrationRuns';

const integrationsReducer = combineReducers({
  externalMappings: externalMappingsReducer,
  integrationRuns: integrationRunsReducer,
});

export default integrationsReducer;
