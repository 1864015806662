import React from 'react';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';

const LazyIngestEventsListRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IngestEventsListRoute" */ '@/components/routes/meters/IngestEventsListRoute'
  ).then((module) => ({ default: module.IngestEventsListRoute }))
);

export default (): NamedRoute => ({
  path: 'ingest-events',
  name: 'ingest.events',
  element: <LazyIngestEventsListRoute />,
});
