import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';

import {
  DataType,
  Account,
  Bill,
  BillJobFrequency,
  BillStatus,
  CurrencyCode,
  DateString,
  Id,
} from '@m3ter-com/m3ter-api';
import { DateStringRange } from '@m3ter-com/ui-components';

import { isBillLocked as isBillLockedUtil } from '@/util/billing';
import {
  selectIsSaving as selectIsSavingSingleton,
  selectError as selectSingletonError,
} from '@/store/singletons/singletons';
import { selectByIds } from '@/store/data/data';
import {
  approveBills,
  lockBills,
  selectBillingError,
} from '@/store/features/billing/billing';
import {
  generateBills,
  recalculateBills,
  selectBillJobsError,
  selectRecalculatingBillIds,
  selectUserInitiatedBillJobIds,
} from '@/store/features/billing/billJobs';
import {
  selectIsApprovingAll,
  loadInitialData,
  selectSelectedStartDate,
  selectSelectedEndDate,
  setSelectedDates,
  BillListStatus,
  selectSelectedStatus,
  setSelectedStatus,
} from '@/store/features/billing/billsList';
import useOrg from '@/hooks/data/crud/useOrg';
import { selectBillConfig } from '@/store/app/bootstrap/bootstrap';

export interface BillAndAccount extends Bill {
  account?: Account;
}

const useBillsList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadInitialData());
  }, [dispatch]);

  const { orgConfig } = useOrg();
  const billConfig = useSelector(selectBillConfig);
  const isUpdatingBillConfig = useSelector(
    selectIsSavingSingleton(DataType.BillConfig)
  );
  const billConfigErrorSelector = useMemo(
    () => selectSingletonError(DataType.BillConfig),
    []
  );
  const billConfigError = useSelector(billConfigErrorSelector);

  const billingError = useSelector(selectBillingError);
  const billJobsError = useSelector(selectBillJobsError);
  const isApprovingAllBills = useSelector(selectIsApprovingAll);
  const recalculatingBillIds = useSelector(selectRecalculatingBillIds);
  const userInitiatedBillJobIds = useSelector(selectUserInitiatedBillJobIds);
  const selectedStartDate = useSelector(selectSelectedStartDate);
  const selectedEndDate = useSelector(selectSelectedEndDate);
  const selectedBillStatus = useSelector(selectSelectedStatus);

  const isBillLocked = useCallback(
    (bill: Bill) => isBillLockedUtil(bill, billConfig),
    [billConfig]
  );

  // Selected bills functionality
  const [selectedBillIds, setSelectedBillIds] = useState<Array<string>>([]);
  const selectedBillsSelector = useMemo(
    () => selectByIds<Bill>(DataType.Bill, selectedBillIds),
    [selectedBillIds]
  );
  const selectedBills = useSelector(selectedBillsSelector);
  const clearSelectedBillIds = useCallback(() => {
    setSelectedBillIds([]);
  }, []);

  const selectedBillsAccountIds = useMemo(
    () => selectedBills.map(({ accountId }) => accountId),
    [selectedBills]
  );
  const selectedBillsAccountsSelector = useMemo(
    () => selectByIds<Account>(DataType.Account, selectedBillsAccountIds),
    [selectedBillsAccountIds]
  );
  const selectedBillsAccounts = useSelector(selectedBillsAccountsSelector);
  const isSomeSelectedChildAccountBills = useMemo(
    () =>
      selectedBillsAccounts.some(({ parentAccountId }) => !!parentAccountId),
    [selectedBillsAccounts]
  );

  const approveSelectedBills = useCallback(() => {
    dispatch(approveBills(selectedBillIds));
  }, [selectedBillIds, dispatch]);

  const lockSelectedBills = useCallback(() => {
    dispatch(lockBills(selectedBillIds));
  }, [selectedBillIds, dispatch]);

  const recalculateSelectedBills = useCallback(() => {
    dispatch(recalculateBills(selectedBillIds));
  }, [selectedBillIds, dispatch]);

  const isReclaclulatingSomeSelectedBill = useMemo<boolean>(() => {
    if (selectedBillIds.length === 0 || recalculatingBillIds.length === 0) {
      return false;
    }

    return selectedBillIds.some((billId) =>
      recalculatingBillIds.includes(billId)
    );
  }, [recalculatingBillIds, selectedBillIds]);

  const isSomeSelectedBillApproved = useMemo<boolean>(
    () => selectedBills.some((bill) => bill.status === BillStatus.Approved),
    [selectedBills]
  );

  const isSomeSelectedBillPending = useMemo<boolean>(
    () => selectedBills.some((bill) => bill.status === BillStatus.Pending),
    [selectedBills]
  );

  const isSomeSelectedBillLocked = useMemo<boolean>(
    () => selectedBills.some(isBillLocked),
    [isBillLocked, selectedBills]
  );

  const selectedDateRange = useMemo(
    () =>
      selectedStartDate && selectedEndDate
        ? {
            start: selectedStartDate,
            end: selectedEndDate,
          }
        : null,
    [selectedStartDate, selectedEndDate]
  );

  const onSelectedDateRangeChange = useCallback(
    (range: DateStringRange | null) => {
      dispatch(setSelectedDates(range?.start ?? null, range?.end ?? null));
      setSelectedBillIds([]);
    },
    [dispatch]
  );

  const onSelectedBillStatusChange = useCallback(
    (status: BillListStatus) => {
      dispatch(setSelectedStatus(status));
      setSelectedBillIds([]);
    },
    [dispatch]
  );

  const runBilling = useCallback(
    (
      externalInvoiceDate: DateString,
      billingFrequency?: BillJobFrequency,
      accountIds?: Array<Id>,
      targetCurrency?: CurrencyCode
    ) => {
      dispatch(
        generateBills(
          externalInvoiceDate,
          billingFrequency,
          accountIds,
          targetCurrency
        )
      );
    },
    [dispatch]
  );

  const {
    isOpen: isApproveBillsModalOpen,
    onOpen: openApproveBillsModal,
    onClose: closeApproveBillsModal,
  } = useDisclosure();

  const {
    isOpen: isGlobalLockDateModalOpen,
    onOpen: openGlobalLockDateModal,
    onClose: closeGlobalLockDateModal,
  } = useDisclosure();

  const {
    isOpen: isRunBillingModalOpen,
    onOpen: openRunBillingModal,
    onClose: closeRunBillingModal,
  } = useDisclosure();

  const isSelectedDateLocked = useMemo(() => {
    if (!billConfig?.billLockDate || !selectedEndDate) {
      return false;
    }

    const globalLockDate = new Date(billConfig.billLockDate);
    const selected = new Date(selectedEndDate);
    return globalLockDate >= selected;
  }, [billConfig, selectedEndDate]);

  const isGeneratingBills = userInitiatedBillJobIds.length > 0;

  return {
    billListError: billingError || billConfigError || billJobsError,
    includeFrequency: !orgConfig.consolidateBills,
    isApprovingAllBills,
    isApproveBillsModalOpen,
    isGeneratingBills,
    isGlobalLockDateModalOpen,
    isRunBillingModalOpen,
    isReclaclulatingSomeSelectedBill,
    isSomeSelectedBillApproved,
    isSomeSelectedBillPending,
    isSomeSelectedBillLocked,
    isSomeSelectedChildAccountBills,
    isSelectedDateLocked,
    isUpdatingGlobalLockDate: isUpdatingBillConfig,
    selectedBillIds,
    selectedBillStatus,
    selectedStartDate,
    selectedEndDate,
    selectedDateRange,
    approveSelectedBills,
    closeApproveBillsModal,
    closeGlobalLockDateModal,
    closeRunBillingModal,
    isBillLocked,
    lockSelectedBills,
    openApproveBillsModal,
    onSelectedBillStatusChange,
    onSelectedDateRangeChange,
    openGlobalLockDateModal,
    openRunBillingModal,
    recalculateSelectedBills,
    runBilling,
    setSelectedBillIds,
    clearSelectedBillIds,
  };
};

export default useBillsList;
