import { useCallback } from 'react';

import { PreferenceName } from '@/types/preferences';
import { ListSize } from '@/types/lists';

import usePreference from './usePreference';

export interface UseListSizePreferenceReturn {
  listSize: ListSize;
  onListSizeChange: (newListSize: ListSize) => void;
}

type ListSizePreferences = Partial<Record<string, ListSize>>;

const defaultListSizePreferences: ListSizePreferences = {};

const useListSizePreference = (listId: string): UseListSizePreferenceReturn => {
  const [listSizePreferences, setListSizePreferences] = usePreference(
    PreferenceName.LIST_SIZES,
    defaultListSizePreferences
  );

  const onListSizeChange = useCallback(
    (newListSize: ListSize) => {
      setListSizePreferences((currentPreferences) => ({
        ...currentPreferences,
        [listId]: newListSize,
      }));
    },
    [listId, setListSizePreferences]
  );

  return {
    listSize: listSizePreferences[listId] ?? ListSize.TwentyItems,
    onListSizeChange,
  };
};

export default useListSizePreference;
