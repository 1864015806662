import React from 'react';

import { chakra, Text, useMultiStyleConfig } from '@chakra-ui/react';
import { MonthType, useMonth } from '@datepicker-react/hooks';

import { useCalendarContext } from './CalendarContext';
import { CalendarCell } from './CalendarCell';

export interface CalendarGridProps {
  month: MonthType;
}

export const CalendarGrid: React.FC<CalendarGridProps> = ({ month }) => {
  const { firstDayOfWeek, translations } = useCalendarContext();
  const { days } = useMonth({
    year: month.year,
    month: month.month,
    firstDayOfWeek,
  });

  const styles = useMultiStyleConfig('DatePicker', {});

  return (
    <chakra.div sx={styles.calendarGrid}>
      <Text
        data-testid="date-picker-calendar-monday-column-header"
        sx={styles.calendarGridHeader}
      >
        {translations.mondayInitial}
      </Text>
      <Text
        data-testid="date-picker-calendar-tuesday-column-header"
        sx={styles.calendarGridHeader}
      >
        {translations.tuesdayInitial}
      </Text>
      <Text
        data-testid="date-picker-calendar-wednesday-column-header"
        sx={styles.calendarGridHeader}
      >
        {translations.wednesdayInitial}
      </Text>
      <Text
        data-testid="date-picker-calendar-thursday-column-header"
        sx={styles.calendarGridHeader}
      >
        {translations.thursdayInitial}
      </Text>
      <Text
        data-testid="date-picker-calendar-friday-column-header"
        sx={styles.calendarGridHeader}
      >
        {translations.fridayInitial}
      </Text>
      <Text
        data-testid="date-picker-calendar-saturday-column-header"
        sx={styles.calendarGridHeader}
      >
        {translations.saturdayInitial}
      </Text>
      <Text
        data-testid="date-picker-calendar-sunday-column-header"
        sx={styles.calendarGridHeader}
      >
        {translations.sundayInitial}
      </Text>
      {days.map((day, dayIndex) =>
        typeof day === 'number' ? (
          <div
            data-testid={`date-picker-calendar-day-cell-${dayIndex + 1}`}
            key={dayIndex} // eslint-disable-line react/no-array-index-key
          />
        ) : (
          <CalendarCell
            data-testid={`date-picker-calendar-day-cell-${dayIndex + 1}`}
            key={dayIndex} // eslint-disable-line react/no-array-index-key
            date={day.date}
          >
            {day.dayLabel}
          </CalendarCell>
        )
      )}
    </chakra.div>
  );
};
