import React from 'react';

import { NamedRoute } from '@/types/routes';

import { AccountChildrenRoute } from '@/components/routes/accounts/AccountChildrenRoute';

export default (): NamedRoute => ({
  name: 'account.children',
  path: 'children',
  element: <AccountChildrenRoute />,
});
