import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';

import { DataType } from '@m3ter-com/m3ter-api';

import { EntityRouteListIds } from '@/types/lists';

import { updateData } from '@/store/data/data.saga';
import { refreshList } from '@/store/crud';
import { extractError } from '@/util/error';

import {
  updateUserActivation,
  UpdateUserActivationAction,
  updateUserActivationFailure,
  updateUserActivationSuccess,
} from './userActivation';

export function* updateUserActivationSaga(
  action: UpdateUserActivationAction
): Generator<StrictEffect, void, any> {
  const { user, accessEndDateTime } = action.payload;
  const { onSuccess, onFailure } = action.meta;

  try {
    yield call(updateData, DataType.User, user.id, {
      ...user,
      dtEndAccess: accessEndDateTime,
    });

    yield put(updateUserActivationSuccess(onSuccess));
    yield put(refreshList(DataType.User, EntityRouteListIds.User));
  } catch (error) {
    yield put(updateUserActivationFailure(extractError(error), onFailure));
  }
}

export default function* userActivationSaga() {
  yield takeEvery(updateUserActivation.type, updateUserActivationSaga);
}
