import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DataType,
  Id,
  Plan,
  PlanTemplate,
  Pricing,
  SegmentedAggregation,
  SegmentedCompoundAggregation,
} from '@m3ter-com/m3ter-api';
import { OneOfType } from '@m3ter-com/console-core/types';

import { AppError } from '@/types/errors';

import { createSelectById, createSelectByIds } from '@/store/data/data';

interface LoadPricingDataPayload {
  aggregationOrCompoundAggregationId: Id;
  isCompoundAggregation: boolean;
  planTemplateId?: Id;
  planId?: Id;
}
export type LoadPricingDataAction = PayloadAction<LoadPricingDataPayload>;

interface LoadPricingDataSuccessPayload extends SegmentsEditorPricingData {}
export type LoadPricingDataSuccessAction =
  PayloadAction<LoadPricingDataSuccessPayload>;

export type LoadPricingDataFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

export interface SegmentsEditorPricingData {
  aggregationOrCompoundAggregationId: Id;
  isCompoundAggregation: boolean;
  planPricingIds: Array<Id>;
  planTemplatePricingIds: Array<Id>;
  planTemplateId: Id;
  planId?: Id;
}

export type SegmentsEditorPricingDataEntities = {
  planPricings: Array<Pricing>;
  planTemplatePricings: Array<Pricing>;
  planTemplate: PlanTemplate;
  plan?: Plan;
} & OneOfType<{
  aggregation: SegmentedAggregation;
  compoundAggregation: SegmentedCompoundAggregation;
}>;

export interface SegmentsEditorState {
  isLoadingPricingData: boolean;
  error?: AppError;
  pricingData?: SegmentsEditorPricingData;
}

const name = 'features/pricing/segmentsEditor';

export const initialState: SegmentsEditorState = {
  isLoadingPricingData: false,
};

const segmentsEditorSlice = createSlice({
  name,
  initialState,
  reducers: {
    loadPricingData: {
      reducer: (state: SegmentsEditorState, _action: LoadPricingDataAction) => {
        state.isLoadingPricingData = true;
      },
      prepare: (
        aggregationOrCompoundAggregationId: Id,
        isCompoundAggregation: boolean,
        planTemplateId?: Id,
        planId?: Id
      ) => ({
        payload: {
          aggregationOrCompoundAggregationId,
          isCompoundAggregation,
          planTemplateId,
          planId,
        },
      }),
    },
    loadPricingDataFailure: {
      reducer: (
        state: SegmentsEditorState,
        action: LoadPricingDataFailureAction
      ) => {
        state.error = action.error;
        state.isLoadingPricingData = false;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    loadPricingDataSuccess: {
      reducer: (
        state: SegmentsEditorState,
        action: LoadPricingDataSuccessAction
      ) => {
        state.pricingData = action.payload;
        state.error = undefined;
        state.isLoadingPricingData = false;
      },
      prepare: (
        aggregationOrCompoundAggregationId: Id,
        isCompoundAggregation: boolean,
        planPricingIds: Array<Id>,
        planTemplatePricingIds: Array<Id>,
        planTemplateId: Id,
        planId?: Id
      ) => ({
        payload: {
          aggregationOrCompoundAggregationId,
          isCompoundAggregation,
          planPricingIds,
          planTemplatePricingIds,
          planTemplateId,
          planId,
        },
      }),
    },
    reset: () => initialState,
  },
});

export const {
  loadPricingData,
  loadPricingDataFailure,
  loadPricingDataSuccess,
  reset,
} = segmentsEditorSlice.actions;

const selectSegmentsEditorState = (state: {
  features: { pricing: { segmentsEditor: SegmentsEditorState } };
}): SegmentsEditorState => state.features.pricing.segmentsEditor;

export const selectIsLoadingPricingData = createSelector(
  selectSegmentsEditorState,
  (state) => state.isLoadingPricingData
);

export const selectSegmentsEditorError = createSelector(
  selectSegmentsEditorState,
  (state) => state.error
);

export const selectPricingData = createSelector(
  selectSegmentsEditorState,
  (state) => state.pricingData
);

const selectAggregationById = createSelectById<SegmentedAggregation>(
  DataType.Aggregation
);
const selectCompoundAggregationById =
  createSelectById<SegmentedCompoundAggregation>(DataType.CompoundAggregation);
const selectPricingsByIds = createSelectByIds<Pricing>(DataType.Pricing);
const selectPlanTemplateById = createSelectById<PlanTemplate>(
  DataType.PlanTemplate
);
const selectPlanById = createSelectById<Plan>(DataType.Plan);
export const selectPricingDataEntities = createSelector(
  selectPricingData,
  selectAggregationById,
  selectCompoundAggregationById,
  selectPricingsByIds,
  selectPlanTemplateById,
  selectPlanById,
  (
    pricingData,
    aggregationSelector,
    compoundAggregationSelector,
    pricingsSelector,
    planTemplateSelector,
    planSelector
  ): SegmentsEditorPricingDataEntities | undefined => {
    if (!pricingData) {
      return undefined;
    }

    const planPricings = pricingsSelector(pricingData.planPricingIds);
    const planTemplatePricings = pricingsSelector(
      pricingData.planTemplatePricingIds
    );
    const planTemplate = planTemplateSelector(pricingData.planTemplateId)!;
    const plan = pricingData.planId
      ? planSelector(pricingData.planId)
      : undefined;

    if (pricingData.isCompoundAggregation) {
      return {
        compoundAggregation: compoundAggregationSelector(
          pricingData.aggregationOrCompoundAggregationId
        )!,
        planPricings,
        planTemplatePricings,
        planTemplate,
        plan,
      };
    }

    return {
      aggregation: aggregationSelector(
        pricingData.aggregationOrCompoundAggregationId
      )!,
      planPricings,
      planTemplatePricings,
      planTemplate,
      plan,
    };
  }
);

export default segmentsEditorSlice.reducer;
