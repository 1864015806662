import React from 'react';

import { PlanGroup } from '@m3ter-com/m3ter-api';

import { PlanGroupForm } from '@/components/features/pricing/PlanGroupForm';
import { CrudEdit } from '@/components/common/crud/CrudEdit';

export const PlanGroupsEditRoute: React.FC = () => {
  return <CrudEdit<PlanGroup> form={PlanGroupForm} />;
};
