import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import type { AccountIngestModalStepOneFormValues } from '@/components/features/accounts/AccountIngestModal/AccountIngestModalStepOne';

export const accountIngestModalStepOneSchema = create(
  'accountIngestModalStepOne',
  (data: AccountIngestModalStepOneFormValues) => {
    test(
      'meterId',
      i18next.t('forms:validations.accountIngestModal.meterRequired'),
      () => {
        enforce(data.meterId).isNotEmpty();
      }
    );
  }
);
