import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BillConfig, Id } from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

import { uniq } from '@/util/array';

import billHistoryReducer, {
  initialState as initialBillHistoryState,
  BillHistoryState,
} from './billHistory';
import billJobsReducer, {
  initialState as initialBillJobsState,
  BillJobsState,
} from './billJobs';
import billsListReducer, {
  initialState as initialBillsListState,
  BillsListState,
} from './billsList';
import statementJobsReducer, {
  initialState as initialStatementJobsState,
  StatementJobsState,
} from './statementJobs';

export interface BillingState {
  billHistory: BillHistoryState;
  billJobs: BillJobsState;
  billsList: BillsListState;
  statementJobs: StatementJobsState;
  updatingBillIds: Array<string>;
  billConfig?: BillConfig;
  error?: AppError;
}

interface UpdatingBillsGenericPayload {
  billIds: Array<Id>;
}

interface ApproveBillPayload {
  billId: string;
}

export type ApproveBillAction = PayloadAction<ApproveBillPayload>;

export type ApproveBillsAction = PayloadAction<UpdatingBillsGenericPayload>;

export type ApproveBillsSuccessAction =
  PayloadAction<UpdatingBillsGenericPayload>;

export type ApproveBillsFailureAction = PayloadAction<
  UpdatingBillsGenericPayload,
  string,
  never,
  AppError
>;

export type LockBillsAction = PayloadAction<UpdatingBillsGenericPayload>;

export type LockBillsSuccessAction = PayloadAction<UpdatingBillsGenericPayload>;

export type LockBillsFailureAction = PayloadAction<
  UpdatingBillsGenericPayload,
  string,
  never,
  AppError
>;

const name = 'features/billing';

export const initialState: BillingState = {
  billHistory: initialBillHistoryState,
  billJobs: initialBillJobsState,
  billsList: initialBillsListState,
  statementJobs: initialStatementJobsState,
  updatingBillIds: [],
};

const addUpdatingBillIds = (state: BillingState, billIds: Array<string>) =>
  uniq([...state.updatingBillIds, ...billIds]);

const removeUpdatingBillIds = (state: BillingState, billIds: Array<string>) =>
  state.updatingBillIds.filter((billId) => !billIds.includes(billId));

const billIdsPrepare = (billIds: Array<Id>) => ({ payload: { billIds } });

const billingState = createSlice({
  name,
  initialState,
  reducers: {
    approveBill: {
      reducer: (state: BillingState, action: ApproveBillAction) => {
        state.updatingBillIds = addUpdatingBillIds(state, [
          action.payload.billId,
        ]);
      },
      prepare: (billId: string) => ({ payload: { billId } }),
    },
    approveBills: {
      reducer: (state: BillingState, action: ApproveBillsAction) => {
        state.updatingBillIds = addUpdatingBillIds(
          state,
          action.payload.billIds
        );
      },
      prepare: (billIds: Array<string>) => ({ payload: { billIds } }),
    },
    approveBillsFailure: {
      reducer: (state: BillingState, action: ApproveBillsFailureAction) => {
        state.error = action.error;
        state.updatingBillIds = removeUpdatingBillIds(
          state,
          action.payload.billIds
        );
      },
      prepare: (error: AppError, billIds: Array<string>) => ({
        payload: { billIds },
        error,
      }),
    },
    approveBillsSuccess: {
      reducer: (state: BillingState, action: ApproveBillsSuccessAction) => {
        state.error = undefined;
        state.updatingBillIds = removeUpdatingBillIds(
          state,
          action.payload.billIds
        );
      },
      prepare: (billIds: Array<string>) => ({ payload: { billIds } }),
    },
    lockBills: {
      reducer: (state: BillingState, action: LockBillsAction) => {
        state.updatingBillIds = addUpdatingBillIds(
          state,
          action.payload.billIds
        );
      },
      prepare: billIdsPrepare,
    },
    lockBillsFailure: {
      reducer: (state: BillingState, action: LockBillsFailureAction) => {
        state.error = action.error;
        state.updatingBillIds = removeUpdatingBillIds(
          state,
          action.payload.billIds
        );
      },
      prepare: (error: AppError, billIds: Array<Id>) => ({
        payload: { billIds },
        error,
      }),
    },
    lockBillsSuccess: {
      reducer: (state: BillingState, action: LockBillsSuccessAction) => {
        state.error = undefined;
        state.updatingBillIds = removeUpdatingBillIds(
          state,
          action.payload.billIds
        );
      },
      prepare: billIdsPrepare,
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => {
      state.billHistory = billHistoryReducer(state.billHistory, action);
      state.billJobs = billJobsReducer(state.billJobs, action);
      state.billsList = billsListReducer(state.billsList, action);
      state.statementJobs = statementJobsReducer(state.statementJobs, action);
    });
  },
});

// Actions

export const {
  approveBill,
  approveBills,
  approveBillsFailure,
  approveBillsSuccess,
  lockBills,
  lockBillsFailure,
  lockBillsSuccess,
  reset,
} = billingState.actions;

// Selectors
const selectBillingState = (state: { features: { billing: BillingState } }) =>
  state.features.billing;

export const selectBillingError = createSelector(
  selectBillingState,
  (state) => state.error
);

export const selectUpdatingBillIds = createSelector(
  selectBillingState,
  (state) => state.updatingBillIds
);

export const selectIsBillBeingUpdated = (billId: string) =>
  createSelector(selectUpdatingBillIds, (updatingBillIds) =>
    updatingBillIds.includes(billId)
  );

export default billingState.reducer;
