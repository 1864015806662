import React, { useMemo } from 'react';

import orderBy from 'lodash/orderBy';
import {
  Avatar,
  Box,
  Heading,
  List,
  ListItem,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';

import { DataType, User } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, KeyValue } from '@m3ter-com/ui-components';

import useEntityPermissionPolicyList from '@/hooks/features/access/useEntityPermissionPolicyList';
import useResendTempPassword from '@/hooks/features/access/useResendTempPassword';
import useUserGroupList from '@/hooks/features/access/useUserGroupList';
import { ActiveStatusBadge } from '@/components/common/data/ActiveStatusBadge';
import { AuditData } from '@/components/common/data/AuditData';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch';
import { ReferenceLink } from '@/components/common/data/ReferenceLink';

export interface UserOverviewProps {
  user: User;
}

export const UserOverview: React.FC<UserOverviewProps> = ({ user }) => {
  const { t } = useTranslation();

  const { userGroups, userGroupsError, userGroupsLoading } = useUserGroupList(
    user.id
  );

  const { resendTempPassword, isResendingPassword } = useResendTempPassword(
    user.id
  );

  const {
    permissionPolicies,
    permissionPoliciesError,
    permissionPoliciesLoading,
  } = useEntityPermissionPolicyList({ dataType: DataType.User, id: user.id });

  const isUserActive = useMemo(
    () =>
      !user.dtEndAccess || new Date(user.dtEndAccess) > new Date(Date.now()),
    [user]
  );

  const fullName = `${user.firstName} ${user.lastName}`;

  return (
    <LoadingErrorContentSwitch
      showContentOnError
      isLoading={permissionPoliciesLoading || userGroupsLoading}
      error={permissionPoliciesError ?? userGroupsError}
    >
      <VStack spacing={4} divider={<StackDivider />}>
        <Box>
          <Avatar name={fullName} verticalAlign="middle" size="lg" />
          <Heading as="h2" size="lg">
            {fullName}
          </Heading>
          <Text>{user.email}</Text>
          <Box w="100%">
            <ActiveStatusBadge ml={0} active={isUserActive} />
          </Box>
          <Button
            mt={4}
            intent="primary"
            as={CrudDetailsLink}
            dataType={DataType.User}
            id={user.id}
          >
            {t('common:entityDetails', { entityName: t('common:user') })}
          </Button>
        </Box>
        <VStack width="100%" alignItems="stretch">
          <KeyValue
            label={t('common:permissionPolicies')}
            value={
              permissionPolicies.length > 0 ? (
                <List>
                  {orderBy(permissionPolicies, 'name').map((permission) => (
                    <ListItem key={permission.id}>
                      <ReferenceLink
                        dataType={DataType.PermissionPolicy}
                        id={permission.id}
                        accessor="name"
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                '-'
              )
            }
          />
          <KeyValue
            label={t('common:userGroups')}
            value={
              userGroups.length > 0 ? (
                <List>
                  {orderBy(userGroups, 'name').map((userGroup) => (
                    <ListItem key={userGroup.id}>
                      <ReferenceLink
                        dataType={DataType.UserGroup}
                        id={userGroup.id}
                        accessor="name"
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                '-'
              )
            }
          />
        </VStack>
        <Button onClick={resendTempPassword} isLoading={isResendingPassword}>
          {t('features:access.resendTempPassword')}
        </Button>
        <AuditData data={user} />
      </VStack>
    </LoadingErrorContentSwitch>
  );
};
