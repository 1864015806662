import { combineReducers } from 'redux';

import externalMappingsCreateFormReducer from './externalMappingsCreateForm';
import externalMappingsDataReducer from './externalMappingsData';

const externalMappingsReducer = combineReducers({
  createForm: externalMappingsCreateFormReducer,
  data: externalMappingsDataReducer,
});

export default externalMappingsReducer;
