import { createSelector } from '@reduxjs/toolkit';

import { AccountPlan } from '@m3ter-com/m3ter-api';

import { AccountCustomPlanFormValues } from '@/components/features/accounts/AccountCustomPlanForm';
import createFormSlice, {
  CreateAction,
  FormState,
} from '@/store/forms/createFormSlice';

export type CreateCustomPlanAction = CreateAction<AccountCustomPlanFormValues>;

const customPlanFormSlice = createFormSlice<
  AccountPlan,
  AccountCustomPlanFormValues
>('features/accounts/customPlanForm');

export const {
  create: createCustomPlan,
  createSuccess: createCustomPlanSuccess,
  createFailure: createCustomPlanFailure,
} = customPlanFormSlice.actions;

// Selectors

const selectPlanFormState = (state: {
  features: { accounts: { customPlanForm: FormState } };
}): FormState => state.features.accounts.customPlanForm;

export const selectIsSaving = createSelector(
  selectPlanFormState,
  (planFormState) => planFormState.isSaving
);

export const selectError = createSelector(
  selectPlanFormState,
  (planFormState) => planFormState.error
);

export default customPlanFormSlice.reducer;
