import React, { ReactElement, useMemo } from 'react';

import { TableProps } from '@chakra-ui/react';

import { DataTypeToEntity } from '@m3ter-com/m3ter-api';
import {
  getFormattedPricingType,
  isTransactionPricing,
} from '@m3ter-com/console-core/utils';

import { PricingDataType } from '@/types/data';

import { PricingBandsTable } from '@/components/features/pricing/PricingBandsTable';

export interface PricingSummaryProps<DT extends PricingDataType>
  extends TableProps {
  currencyCode: string;
  pricing: DataTypeToEntity[DT];
  showHeading?: boolean;
}

export function PricingSummary<DT extends PricingDataType>({
  currencyCode,
  pricing,
  showHeading = true,
  ...tableProps
}: PricingSummaryProps<DT>): ReactElement<any, any> | null {
  const pricingType = useMemo(
    () => getFormattedPricingType(pricing),
    [pricing]
  );

  return (
    <PricingBandsTable
      pricingBands={pricing.pricingBands ?? []}
      currencyCode={currencyCode}
      heading={showHeading ? pricingType : undefined}
      isTransactional={isTransactionPricing(pricing)}
      {...tableProps}
    />
  );
}
