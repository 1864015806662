import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataType, Destination, Id } from '@m3ter-com/m3ter-api';

import { ConnectedEvents } from '@/types/data';
import { AppError } from '@/types/errors';

import { createSelectByIds } from '@/store/data/data';

export interface NotificationsDataState {
  destinationIds?: Array<Id>;
  isLoading: boolean;
  notificationEventsData?: Array<ConnectedEvents>;
  error?: AppError;
}

interface LoadNotificationsDataPayload {
  notificationId: Id;
}

export type LoadNotificationsDataAction =
  PayloadAction<LoadNotificationsDataPayload>;

interface LoadNotificationDestinationsSuccessPayload {
  destinationIds?: Array<Id>;
}

export type LoadNotificationDestinationsSuccessAction =
  PayloadAction<LoadNotificationDestinationsSuccessPayload>;

export type LoadNotificationDestinationsFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

interface LoadNotificationEventsDataSuccessPayload {
  notificationEventsData?: Array<ConnectedEvents>;
}

export type LoadNotificationEventsDataSuccessAction =
  PayloadAction<LoadNotificationEventsDataSuccessPayload>;

export type LoadNotificationEventsDataFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

const name = 'features/eventsNotifications/notifications';

const initialState: NotificationsDataState = {
  isLoading: false,
};

const notificationsSlice = createSlice({
  name,
  initialState,
  reducers: {
    loadNotificationDestinations: {
      reducer: (
        state: NotificationsDataState,
        _action: LoadNotificationsDataAction
      ) => {
        state.isLoading = true;
        state.error = undefined;
      },
      prepare: (notificationId: Id) => ({ payload: { notificationId } }),
    },
    loadNotificationDestinationsSuccess: {
      reducer: (
        state: NotificationsDataState,
        action: LoadNotificationDestinationsSuccessAction
      ) => {
        state.isLoading = false;
        state.destinationIds = action.payload.destinationIds;
      },
      prepare: (destinationIds: Array<Id>) => ({
        payload: { destinationIds },
      }),
    },
    loadNotificationDestinationsFailure: {
      reducer: (
        state: NotificationsDataState,
        action: LoadNotificationDestinationsFailureAction
      ) => {
        state.isLoading = false;
        state.error = action.error;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    loadNotificationEventsData: (state: NotificationsDataState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    loadNotificationEventsDataSuccess: {
      reducer: (
        state: NotificationsDataState,
        action: LoadNotificationEventsDataSuccessAction
      ) => {
        state.isLoading = false;
        state.notificationEventsData = action.payload.notificationEventsData;
      },
      prepare: (notificationEventsData: Array<ConnectedEvents>) => ({
        payload: { notificationEventsData },
      }),
    },
    loadNotificationEventsDataFailure: {
      reducer: (
        state: NotificationsDataState,
        action: LoadNotificationEventsDataFailureAction
      ) => {
        state.isLoading = false;
        state.error = action.error;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    reset: () => initialState,
  },
});

// // Export action creators.
export const {
  loadNotificationDestinations,
  loadNotificationDestinationsSuccess,
  loadNotificationDestinationsFailure,
  loadNotificationEventsData,
  loadNotificationEventsDataSuccess,
  loadNotificationEventsDataFailure,
  reset,
} = notificationsSlice.actions;

// Selectors.
const selectNotificationsState = (state: {
  features: { eventsNotifications: { notifications: NotificationsDataState } };
}): NotificationsDataState => state.features.eventsNotifications.notifications;

export const selectIsLoading = createSelector(
  selectNotificationsState,
  (notificationsState) => notificationsState.isLoading
);

export const selectError = createSelector(
  selectNotificationsState,
  (notificationsState) => notificationsState.error
);

export const selectDestinationIds = createSelector(
  selectNotificationsState,
  (notificationsState) => notificationsState.destinationIds
);

export const selectDestinations = createSelector(
  selectDestinationIds,
  createSelectByIds<Destination>(DataType.Destination),
  (destinationIds, selectByIds) => selectByIds(destinationIds)
);

export const selectNotificationEventsData = createSelector(
  selectNotificationsState,
  (notificationsState) => notificationsState.notificationEventsData
);

export default notificationsSlice.reducer;
