import React, { useMemo } from 'react';

import { IntegrationConfigParameterType } from '@m3ter-com/m3ter-api';

import { useIntegrationConfigSchemaContext } from '@/components/features/integrations/IntegrationConfigSchemaContext';

import { IntegrationCredentialConfigureExternalContent } from './IntegrationCredentialConfigureExternalContent';
import { IntegrationCredentialConfigureInternalContent } from './IntegrationCredentialConfigureInternalContent';

export interface IntegrationCredentialCreateFormConfigureStepProps {
  destination: string;
  name: string;
  onBack: () => void;
  onCancel: () => void;
}

export const IntegrationCredentialCreateFormConfigureStep: React.FC<
  IntegrationCredentialCreateFormConfigureStepProps
> = ({ destination, name, onBack, onCancel }) => {
  const { configSchema } = useIntegrationConfigSchemaContext();

  const destinationSchema = useMemo(() => {
    const allDestinationSchemas = Object.values(
      configSchema.supportedEntities
    ).flatMap((entitySchema) => entitySchema!.destinations);

    return allDestinationSchemas.find((d) => d.destination === destination);
  }, [configSchema, destination]);

  const renderExternalLink = useMemo(
    () =>
      !!destinationSchema?.authConfigOptions?.some(
        (authParamaterSchema) =>
          authParamaterSchema.type === IntegrationConfigParameterType.AuthLink
      ),
    [destinationSchema]
  );

  if (!destinationSchema) {
    return null;
  }

  return renderExternalLink ? (
    <IntegrationCredentialConfigureExternalContent
      destinationSchema={destinationSchema}
      name={name}
      onBack={onBack}
      onCancel={onCancel}
    />
  ) : (
    <IntegrationCredentialConfigureInternalContent
      destinationSchema={destinationSchema}
      name={name}
      onBack={onBack}
      onCancel={onCancel}
    />
  );
};
