import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { PlanTemplatesRoute } from '@/components/routes/plan-templates/PlanTemplatesRoute';
import { PlanTemplatesListRoute } from '@/components/routes/plan-templates/PlanTemplatesListRoute';
import { PlanTemplatesDetailsRoute } from '@/components/routes/plan-templates/PlanTemplatesDetailsRoute';
import { PlanTemplatesCreateRoute } from '@/components/routes/plan-templates/PlanTemplatesCreateRoute';
import { PlanTemplatesEditRoute } from '@/components/routes/plan-templates/PlanTemplatesEditRoute';

export default () =>
  createCrudRoute({
    path: 'plan-templates',
    dataType: DataType.PlanTemplate,
    element: <PlanTemplatesRoute />,
    list: <PlanTemplatesListRoute />,
    details: <PlanTemplatesDetailsRoute />,
    create: <PlanTemplatesCreateRoute />,
    edit: <PlanTemplatesEditRoute />,
  });
