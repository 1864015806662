import { ComponentSingleStyleConfig } from '@chakra-ui/react';

const well: ComponentSingleStyleConfig = {
  baseStyle: {
    padding: 2,
    bg: 'chakra-subtle-bg',
    borderRadius: 'md',
    boxShadow: 'inner',
  },
};

export default well;
