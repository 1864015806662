import { all, call, StrictEffect } from 'redux-saga/effects';
import get from 'lodash/get';

import { DataType } from '@m3ter-com/m3ter-api';
import { UnknownEntity } from '@m3ter-com/console-core/types';

import { uniq } from '@/util/array';
import { ids } from '@/util/data';
import { getRelationship } from '@/services/api/entities';
import { listData } from '@/store/data/data.saga';
import { RelatedData } from '@/store/crud';

export function* loadRelatedData(
  dataType: DataType,
  data: Array<UnknownEntity>,
  relationships?: Array<string>
): Generator<StrictEffect, RelatedData, any> {
  const relatedData: RelatedData = {};
  if (data.length > 0 && !!relationships?.length) {
    // There is related data to load. Get all the unique foreign key (id) values
    // and load the data.
    const relatedResponses = yield all(
      // Create a map of relationship name to `call` effect, if there is data to load.
      relationships.reduce<Record<string, StrictEffect>>(
        (acc, relationshipName) => {
          const relationship = getRelationship(dataType, relationshipName);
          // Handle one-to-many (array of IDs) by flattening.
          const relatedIds = uniq(
            data
              .map((item) =>
                // Allows access to relationships in nested objects using dot-notation
                get(item, relationship.foreignKey)
              )
              .flat()
          ).filter(Boolean);
          // Only add the list call if there are ids to load.
          if (relatedIds.length > 0) {
            acc[relationshipName] = call(listData, relationship.dataType, {
              ids: relatedIds,
            });
          }
          return acc;
        },
        {}
      )
    );

    relationships.forEach((relationshipName) => {
      const relationship = getRelationship(dataType, relationshipName);
      const relationshipEntityIds = relatedResponses[relationshipName]
        ? ids(relatedResponses[relationshipName].data)
        : [];
      relatedData[relationshipName] = {
        dataType: relationship.dataType,
        ids: relationshipEntityIds,
      };
    });
  }
  return relatedData;
}
