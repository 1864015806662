import React from 'react';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';

const LazyMonthOnMonthReportRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "MonthOnMonthReportRoute" */ '@/components/routes/reports/MonthOnMonthReportRoute'
  ).then((module) => ({
    default: module.MonthOnMonthReportRoute,
  }))
);

export const name = 'reports.monthOnMonth';

export default (): NamedRoute => ({
  name,
  path: 'month-on-month',
  element: <LazyMonthOnMonthReportRoute />,
});
