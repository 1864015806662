import React from 'react';

import { usePricingGridRowContext } from '@/components/features/pricing/grid/PricingGridRowContext';
import { PricingGridPricingNavigator } from '@/components/features/pricing/grid/PricingGridPricingNavigator';
import { SegmentedPricingLink } from '@/components/features/pricing/grid/SegmentedPricingLink';

export const PricingGridDataCell: React.FC = () => {
  const { isSegmented } = usePricingGridRowContext();

  return isSegmented ? (
    <SegmentedPricingLink />
  ) : (
    <PricingGridPricingNavigator />
  );
};
