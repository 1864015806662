import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { IngestRequestBody } from '@m3ter-com/m3ter-api';

import {
  reset as resetIngestSlice,
  submitMeasurements as submitMeasurementsAction,
  selectIsSubmittingMeasurements,
  selectSubmissions,
} from '@/store/features/usage/ingest';

const useIngest = () => {
  const dispatch = useDispatch();

  const isSubmitting = useSelector(selectIsSubmittingMeasurements);
  const submissions = useSelector(selectSubmissions);

  const submitMeasurements = useCallback(
    (body: IngestRequestBody) => {
      dispatch(submitMeasurementsAction(body));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(resetIngestSlice());
    };
  }, [dispatch]);

  return {
    isSubmitting,
    submissions,
    submitMeasurements,
  };
};

export default useIngest;
