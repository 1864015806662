import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { Badge, Spinner, Text } from '@chakra-ui/react';

import { DataType, CompoundAggregation } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, Well } from '@m3ter-com/ui-components';

import { EntityRouteListIds } from '@/types/lists';

import { dataTypeListQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useAppQuery from '@/hooks/data/useAppQuery';
import { selectSelectedProductIdIfNotGlobal } from '@/store/products/products';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink';
import {
  ColumnDefinition,
  CrudList,
  CrudListFooter,
  CrudListHeader,
  CrudListTable,
} from '@/components/common/crud/CrudList';
import { ProductSelector } from '@/components/features/products/ProductSelector';

const searchFields = ['name'];

export const CompoundAggregationsListRoute: React.FC = () => {
  const { t } = useTranslation();
  const productId = useSelector(selectSelectedProductIdIfNotGlobal);

  const pathParams = useOrgPathParams();
  const { data: aggregations, isLoading: isLoadingAggregations } = useAppQuery(
    dataTypeListQuery({
      dataType: DataType.Aggregation,
      pathParams,
      queryParams: {
        pageSize: 1,
        // When a product is selected we check both that product's aggregations
        // and global aggregations. If no product, we just check global aggregations.
        productId: productId ? ['', productId] : '',
      },
    })
  );

  const columns = useMemo<Array<ColumnDefinition<CompoundAggregation>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (compoundAggregation) => (
          <Text>
            {compoundAggregation.name}
            {!compoundAggregation.productId && (
              <Badge ml={2}>{t('common:global')}</Badge>
            )}
          </Text>
        ),
      },
      { id: 'code', header: t('forms:labels.code'), accessor: 'code' },
    ],
    [t]
  );

  // When a product is selected, we show both global aggs and aggs for that product.
  // If not, we just show global aggs.
  const listParams = useMemo(
    () => ({ productId: productId ? ['', productId] : '' }),
    [productId]
  );

  if (isLoadingAggregations) {
    return <Spinner />;
  }

  const hasNoAggregations = !aggregations?.length;

  return (
    <CrudList
      listId={EntityRouteListIds.CompoundAggregation}
      params={listParams}
      searchFields={searchFields}
    >
      <CrudListHeader isCreateDisabled={hasNoAggregations}>
        <ProductSelector includeGlobal />
      </CrudListHeader>
      {hasNoAggregations ? (
        <Well
          alignItems="center"
          display="flex"
          flexFlow="column nowrap"
          gap={4}
          justifyContent="center"
        >
          <Text>{t('errors:compoundAggregations.noAggregationsCreated')}</Text>
          <Button as={CrudCreateLink} dataType={DataType.Aggregation}>
            {t('forms:buttons.createEntity', {
              entityName: '$t(common:aggregation)',
            })}
          </Button>
        </Well>
      ) : (
        <React.Fragment>
          <CrudListTable columns={columns} />
          <CrudListFooter />
        </React.Fragment>
      )}
    </CrudList>
  );
};
