import React from 'react';

import { Flex, Spinner } from '@chakra-ui/react';

export const LoadingScreen: React.FC<{}> = () => (
  <Flex
    w="100vw"
    h="100vh"
    flexFlow="row nowrap"
    justifyContent="center"
    alignItems="center"
  >
    <Spinner size="xl" data-testid="spinner" />
  </Flex>
);
