import React, { ReactNode } from 'react';

import { chakra, useMultiStyleConfig, HTMLChakraProps } from '@chakra-ui/react';

export interface KeyValueListProps extends HTMLChakraProps<'dt'> {}
export interface KeyValueListItemProps {
  label: ReactNode;
  value: ReactNode | Array<ReactNode>;
}

export const KeyValueList: React.FC<KeyValueListProps> = ({
  children,
  ...listProps
}) => {
  const styles = useMultiStyleConfig('KeyValueList', {});

  return (
    <chakra.dl __css={styles.list} {...listProps}>
      {children}
    </chakra.dl>
  );
};

export const KeyValueListItem: React.FC<KeyValueListItemProps> = ({
  label,
  value,
}) => {
  const styles = useMultiStyleConfig('KeyValueList', {});
  const values = Array.isArray(value) ? value : [value];

  return (
    <React.Fragment>
      <chakra.dt __css={styles.label}>{label}</chakra.dt>
      {values.map((v, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <chakra.dd key={index} __css={styles.value}>
          {v}
        </chakra.dd>
      ))}
    </React.Fragment>
  );
};
