import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { UsersRoute } from '@/components/routes/settings/users/UsersRoute';
import { UsersListRoute } from '@/components/routes/settings/users/UsersListRoute';
import { UsersDetailsRoute } from '@/components/routes/settings/users/UsersDetailsRoute';

export default () =>
  createCrudRoute({
    path: 'users',
    dataType: DataType.User,
    element: <UsersRoute />,
    list: <UsersListRoute />,
    details: <UsersDetailsRoute />,
  });
