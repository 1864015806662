import { PayloadAction } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { delay, put, takeEvery } from 'redux-saga/effects';

import {
  addNotification,
  removeNotification,
  AddNotificationPayload,
} from './notifications';

export function* handleNoticationSaga({ meta, error }: AnyAction) {
  if (meta?.notification) {
    const { message, type, removeAfter } = meta.notification;
    yield put(
      addNotification(
        error ? message.replace('{error}', error.message) : message,
        type,
        removeAfter
      )
    );
  }
}

/**
 * Handles add notification actions, adding a timeout to automatically remove
 * them after their removeAfter time.
 */
export function* addNotificationSaga({
  payload,
}: PayloadAction<AddNotificationPayload>) {
  if (payload.removeAfter > 0) {
    yield delay(payload.removeAfter);
    yield put(removeNotification(payload.id));
  }
}

export default function* notificationsSaga() {
  // Take any action that has a notification description in the meta.
  yield takeEvery(
    (action: AnyAction) => action?.meta?.notification !== undefined,
    handleNoticationSaga
  );

  yield takeEvery(addNotification.type, addNotificationSaga);
}
