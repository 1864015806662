import React from 'react';

import { Destination } from '@m3ter-com/m3ter-api';

import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { DestinationForm } from '@/components/features/destinations/DestinationForm';

export const DestinationCreateRoute: React.FC = () => (
  <CrudCreate<Destination> form={DestinationForm} />
);
