import { spawn } from 'redux-saga/effects';

import type { Context } from '@/store/rootSaga';

import authSaga from './auth/auth.saga';
import boostrapSaga from './bootstrap/bootstrap.saga';

export default function* appSaga(context: Context) {
  yield spawn(authSaga, context);
  yield spawn(boostrapSaga);
}
