import i18n from 'i18next';
import {
  initReactI18next,
  useTranslation as defaultUseTranslation,
} from 'react-i18next';

import enCommon from './en/common.json';
import enErrors from './en/errors.json';
import enFeatures from './en/features.json';
import enForms from './en/forms.json';
import enNotifications from './en/notifications.json';

const resources = {
  en: {
    common: enCommon,
    errors: enErrors,
    features: enFeatures,
    forms: enForms,
    notifications: enNotifications,
  },
};

export const setupI18n = () => {
  i18n.use(initReactI18next).init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
  });
};

/**
 * Currently a simple wrapper hook for the default useTranslation that
 * loads all of our translation files so we don't have to pass the same
 * parameters into the hook every time we consume it in some component.
 *
 * TODO: Build this out to be a bit more complex and lazy load
 * translation files. Maybe even just load the current language when we get
 * there.
 * Look at https://www.i18next.com/how-to/add-or-load-translations
 */
interface UseTranslationReturn {
  t: (key: string, interpolationData?: Record<string, any>) => string;
}
export const useTranslation = () =>
  defaultUseTranslation([
    'common',
    'errors',
    'features',
    'forms',
    'notifications',
  ]) as UseTranslationReturn;
