import React, {
  createContext,
  useContext,
  useMemo,
  Context,
  PropsWithChildren,
} from 'react';

import {
  Aggregation,
  AggregationSegment,
  CompoundAggregation,
  Counter,
} from '@m3ter-com/m3ter-api';

import {
  isAggregationSegmented,
  isCompoundAggregationSegmented,
} from '@/util/aggregation';

export interface PricingGridRowContextValues {
  isSegmented: boolean;
  segments: Array<AggregationSegment>;
  aggregation?: Aggregation;
  compoundAggregation?: CompoundAggregation;
  itemCounter?: Counter;
}

const PricingGridRowContext = createContext<PricingGridRowContextValues | null>(
  null
);

export type PricingGridRowContextProviderProps = Omit<
  PricingGridRowContextValues,
  'isSegmented' | 'segments'
>;

const defaultSegments: Array<AggregationSegment> = [];

export const PricingGridRowContextProvider: React.FC<
  PropsWithChildren<PricingGridRowContextProviderProps>
> = ({ aggregation, compoundAggregation, itemCounter, children }) => {
  let isSegmented = false;
  let segments = defaultSegments;

  if (aggregation && isAggregationSegmented(aggregation)) {
    isSegmented = true;
    segments = aggregation.segments;
  } else if (
    compoundAggregation &&
    isCompoundAggregationSegmented(compoundAggregation)
  ) {
    isSegmented = true;
    segments = compoundAggregation.segments;
  }

  const value = useMemo<PricingGridRowContextValues>(
    () => ({
      aggregation,
      compoundAggregation,
      itemCounter,
      isSegmented,
      segments,
    }),
    [aggregation, compoundAggregation, isSegmented, itemCounter, segments]
  );

  return (
    <PricingGridRowContext.Provider value={value}>
      {children}
    </PricingGridRowContext.Provider>
  );
};

export const usePricingGridRowContext = () =>
  useContext(PricingGridRowContext as Context<PricingGridRowContextValues>);
