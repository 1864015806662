import React from 'react';

import { AccountPlan } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { AccountPlanOverview } from '@/components/features/accounts/AccountPlanOverview';

export const AccountPlanDetailsRoute: React.FC = () => (
  <CrudDetails<AccountPlan> component={AccountPlanOverview} />
);
