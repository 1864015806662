import React from 'react';

import { Outlet } from 'react-router-dom';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';

export interface OrganizationCustomFieldsRouteProps {}

export const OrganizationCustomFieldsRoute: React.FC<
  OrganizationCustomFieldsRouteProps
> = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <BreadcrumbItem title={t('common:customFields')} />
      <Outlet />
    </React.Fragment>
  );
};
