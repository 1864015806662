import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { Feature, selectFeaturesEnabled } from '@/store/features/flags/flags';

export { Feature };

const useFeatureFlags = () => {
  const featuresEnabled = useSelector(selectFeaturesEnabled);

  const isFeatureEnabled = useCallback(
    (feature: Feature) => featuresEnabled.includes(feature),
    [featuresEnabled]
  );

  return {
    isFeatureEnabled,
  };
};

export default useFeatureFlags;
