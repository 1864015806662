import React from 'react';

import { PermissionEffect, PermissionPolicy } from '@m3ter-com/m3ter-api';
import { DeepPartial } from '@m3ter-com/console-core/types';

import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { PermissionForm } from '@/components/features/access/permission-policy/PermissionForm';

const initialValues: DeepPartial<PermissionPolicy> = {
  permissionPolicy: [
    { effect: PermissionEffect.Allow, action: [], resource: ['*'] },
  ],
};

export const PermissionsCreateRoute: React.FC = () => (
  <CrudCreate<PermissionPolicy>
    form={PermissionForm}
    initialValues={initialValues}
  />
);
