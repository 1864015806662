import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';

import { DataType, Entity } from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

import {
  CompletionMeta,
  NotificationDefinition,
  RequestMeta,
} from '@/store/store';

interface SingletonState {
  isLoading: boolean;
  isLoaded: boolean;
  isSaving: boolean;
  error?: AppError;
}

export interface SingletonsState
  extends Partial<Record<DataType, SingletonState>> {}

interface BaseSingletonPayload {
  dataType: DataType;
}

interface SingletonDataPayload extends BaseSingletonPayload {
  data: Entity;
}

export type LoadSingletonAction = PayloadAction<BaseSingletonPayload>;

export type LoadSingletonSuccessAction = PayloadAction<SingletonDataPayload>;

export type LoadSingletonFailureAction = PayloadAction<
  BaseSingletonPayload,
  string,
  never,
  AppError
>;

export type UpdateSingletonAction = PayloadAction<
  SingletonDataPayload,
  string,
  RequestMeta
>;

export type UpdateSingletonSuccessAction = PayloadAction<
  SingletonDataPayload,
  string,
  CompletionMeta | undefined
>;

export type UpdateSingletonFailureAction = PayloadAction<
  BaseSingletonPayload,
  string,
  CompletionMeta | undefined,
  AppError
>;

const name = 'singletons';

const initialState: SingletonsState = {};

// Gets the state for the data type, creating it if required.
const getDataTypeState = (
  state: SingletonsState,
  dataType: DataType
): SingletonState => {
  if (!state[dataType]) {
    state[dataType] = {
      isLoading: false,
      isLoaded: false,
      isSaving: false,
    };
  }
  return state[dataType] as SingletonState;
};

const singletonsSlice = createSlice({
  name,
  initialState,
  reducers: {
    loadSingleton: {
      reducer: (state: SingletonsState, action: LoadSingletonAction) => {
        const dataTypeState = getDataTypeState(state, action.payload.dataType);
        dataTypeState.error = undefined;
        dataTypeState.isLoading = true;
      },
      prepare: (dataType: DataType) => ({
        payload: { dataType },
      }),
    },
    loadSingletonSuccess: {
      reducer: (state: SingletonsState, action: LoadSingletonSuccessAction) => {
        const dataTypeState = getDataTypeState(state, action.payload.dataType);
        dataTypeState.isLoading = false;
        dataTypeState.isLoaded = true;
      },
      prepare: (dataType: DataType, data: Entity) => ({
        payload: { dataType, data },
      }),
    },
    loadSingletonFailure: {
      reducer: (state: SingletonsState, action: LoadSingletonFailureAction) => {
        const dataTypeState = getDataTypeState(state, action.payload.dataType);
        dataTypeState.isLoading = false;
        dataTypeState.error = action.error;
      },
      prepare: (dataType: DataType, error: AppError) => ({
        payload: { dataType },
        error,
      }),
    },
    updateSingleton: {
      reducer: (state: SingletonsState, action: UpdateSingletonAction) => {
        const dataTypeState = getDataTypeState(state, action.payload.dataType);
        dataTypeState.isSaving = true;
        dataTypeState.error = undefined;
      },
      prepare: (
        dataType: DataType,
        data: Entity,
        successNotification?: NotificationDefinition,
        failureNotification?: NotificationDefinition,
        redirectTo?: string
      ) => ({
        payload: { dataType, data },
        meta: {
          onSuccess: { redirectTo, notification: successNotification },
          onFailure: { notification: failureNotification },
        },
      }),
    },
    updateSingletonSuccess: {
      reducer: (
        state: SingletonsState,
        action: UpdateSingletonSuccessAction
      ) => {
        const dataTypeState = getDataTypeState(state, action.payload.dataType);
        dataTypeState.isSaving = false;
      },
      prepare: (dataType: DataType, data: Entity, meta?: CompletionMeta) => ({
        payload: { dataType, data },
        meta,
      }),
    },
    updateSingletonFailure: {
      reducer: (
        state: SingletonsState,
        action: UpdateSingletonFailureAction
      ) => {
        const dataTypeState = getDataTypeState(state, action.payload.dataType);
        dataTypeState.isSaving = false;
        dataTypeState.error = action.error;
      },
      prepare: (
        dataType: DataType,
        error: AppError,
        meta?: CompletionMeta
      ) => ({
        payload: { dataType },
        error,
        meta,
      }),
    },
  },
});

export const {
  loadSingleton,
  loadSingletonSuccess,
  loadSingletonFailure,
  updateSingleton,
  updateSingletonSuccess,
  updateSingletonFailure,
} = singletonsSlice.actions;

// Selectors

const selectSingletonsState = (state: { singletons: SingletonsState }) =>
  state.singletons;

const selectDataTypeState = (dataType: DataType) =>
  createSelector(
    selectSingletonsState,
    (singletonsState) => singletonsState[dataType]
  );

export const selectIsLoading = (dataType: DataType) =>
  createSelector(
    selectDataTypeState(dataType),
    (dataTypeState) => !!dataTypeState?.isLoading
  );

export const selectIsSaving = (dataType: DataType) =>
  createSelector(
    selectDataTypeState(dataType),
    (dataTypeState) => !!dataTypeState?.isSaving
  );

export const selectError = (dataType: DataType) =>
  createSelector(
    selectDataTypeState(dataType),
    (dataTypeState) => dataTypeState?.error
  );

export default singletonsSlice.reducer;
