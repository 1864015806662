import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppError } from '@/types/errors';

interface EntityFlags {
  hasProduct: boolean;
  hasMeter: boolean;
  hasAggregation: boolean;
  hasPricing: boolean;
}

export interface OnboardingState extends EntityFlags {
  isLoading: boolean;
  error?: AppError;
}

interface LoadDataSuccessPayload extends EntityFlags {}
export type LoadDataSuccessAction = PayloadAction<LoadDataSuccessPayload>;

export type LoadDataFailureAction = PayloadAction<
  void,
  string,
  never,
  AppError
>;

const name = 'features/onboarding';

const initialState: OnboardingState = {
  isLoading: false,
  hasProduct: false,
  hasMeter: false,
  hasAggregation: false,
  hasPricing: false,
};

const onboardingSlice = createSlice({
  name,
  initialState,
  reducers: {
    loadData: (state: OnboardingState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    loadDataSuccess: {
      reducer: (state: OnboardingState, action: LoadDataSuccessAction) => {
        state.isLoading = false;
        state.hasProduct = action.payload.hasProduct;
        state.hasMeter = action.payload.hasMeter;
        state.hasAggregation = action.payload.hasAggregation;
        state.hasPricing = action.payload.hasPricing;
      },
      prepare: (
        hasProduct: boolean,
        hasMeter: boolean,
        hasAggregation: boolean,
        hasPricing: boolean
      ) => ({
        payload: {
          hasProduct,
          hasMeter,
          hasAggregation,
          hasPricing,
        },
      }),
    },
    loadDataFailure: {
      reducer: (state: OnboardingState, action: LoadDataFailureAction) => {
        state.isLoading = false;
        state.error = action.error;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
  },
});

// Actions

export const { loadData, loadDataSuccess, loadDataFailure } =
  onboardingSlice.actions;

// Selectors

const selectOnboardingState = (state: {
  features: { onboarding: OnboardingState };
}) => state.features.onboarding;

export const selectIsLoading = createSelector(
  selectOnboardingState,
  (dashboardState) => dashboardState.isLoading
);

export const selectError = createSelector(
  selectOnboardingState,
  (dashboardState) => dashboardState.error
);

export const selectHasProduct = createSelector(
  selectOnboardingState,
  (dashboardState) => dashboardState.hasProduct
);

export const selectHasMeter = createSelector(
  selectOnboardingState,
  (dashboardState) => dashboardState.hasMeter
);

export const selectHasAggregation = createSelector(
  selectOnboardingState,
  (dashboardState) => dashboardState.hasAggregation
);

export const selectHasPricing = createSelector(
  selectOnboardingState,
  (dashboardState) => dashboardState.hasPricing
);

export default onboardingSlice.reducer;
