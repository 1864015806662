import { combineReducers } from '@reduxjs/toolkit';

import planDetailsReducer from './planDetails';
import planEditorReducer from './planEditor';
import planTemplatesReducer from './planTemplates';
import pricingDataReducer from './pricingData';
import pricingScheduleReducer from './pricingSchedule';
import segmentsEditorReducer from './segmentsEditor';

const pricingReducer = combineReducers({
  planDetails: planDetailsReducer,
  planEditor: planEditorReducer,
  planTemplates: planTemplatesReducer,
  pricingData: pricingDataReducer,
  pricingSchedule: pricingScheduleReducer,
  segmentsEditor: segmentsEditorReducer,
});

export default pricingReducer;
