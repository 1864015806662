import React, { useMemo } from 'react';

import {
  forwardRef,
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
  useColorMode,
} from '@chakra-ui/react';

import { getColorScheme, Intent } from './Button';

export interface IconButtonProps extends ChakraIconButtonProps {
  intent?: Intent;
}

export const IconButton = forwardRef<IconButtonProps, 'button'>(
  ({ intent = 'default', ...props }, ref) => {
    const { colorMode } = useColorMode();
    const colorScheme = useMemo(
      () => getColorScheme(intent, colorMode),
      [intent, colorMode]
    );

    return <ChakraIconButton ref={ref} colorScheme={colorScheme} {...props} />;
  }
);
