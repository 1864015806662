import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';

import { Id, ServiceUserCredential } from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

export interface ServiceUserCredentialsState {
  isLoading: boolean;
  isCreatingCredential: boolean;
  credentials: Array<ServiceUserCredential>;
  error?: AppError;
  newCredential?: ServiceUserCredential;
}

interface LoadServiceUserCredentialsPayload {
  serviceUserId: string;
}
interface LoadServiceUserCredentialsSuccessPayload {
  data: Array<ServiceUserCredential>;
}
export type LoadServiceUserCredentialsAction =
  PayloadAction<LoadServiceUserCredentialsPayload>;
type LoadServiceUserCredentialsSuccessAction =
  PayloadAction<LoadServiceUserCredentialsSuccessPayload>;
type LoadServiceUserCredentialsFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

export interface RemoveServiceUserCredentialPayload {
  serviceUserId: string;
  apiKey: string;
}
export interface RemoveServiceUserCredentialSuccessPayload {
  apiKey: string;
}
type RemoveServiceUserCredentialAction =
  PayloadAction<RemoveServiceUserCredentialPayload>;
type RemoveServiceUserCredentialSuccessAction =
  PayloadAction<RemoveServiceUserCredentialSuccessPayload>;
type RemoveServiceUserCredentialFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

interface CreateServiceUserCredentialPayload {
  serviceUserId: Id;
}
export type CreateServiceUserCredentialAction =
  PayloadAction<CreateServiceUserCredentialPayload>;

export interface ServiceUserCredentialStatusChangePayload {
  serviceUserId: string;
  apiKey: string;
}
interface ServiceUserCredentialStatusChangeSuccessPayload {
  updatedCredential: ServiceUserCredential;
}
type ServiceUserCredentialStatusChangeAction =
  PayloadAction<ServiceUserCredentialStatusChangePayload>;
type ServiceUserCredentialStatusChangeSuccessAction =
  PayloadAction<ServiceUserCredentialStatusChangeSuccessPayload>;
type ServiceUserCredentialStatusChangeFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

interface CreateServiceUserCredentialSuccessPayload {
  credential: ServiceUserCredential;
}
export type CreateServiceUserCredentialSuccessAction =
  PayloadAction<CreateServiceUserCredentialSuccessPayload>;
export type CreateServiceUserCredentialFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

export const mergeCredentialState = (
  state: ServiceUserCredentialsState,
  updatedCredential: ServiceUserCredential
) => {
  state.credentials = state.credentials.map((credential) => {
    if (credential.apiKey === updatedCredential.apiKey) {
      return updatedCredential;
    }
    return credential;
  });
};

const name = 'features/access/serviceUserCredentials';

const initialState: ServiceUserCredentialsState = {
  isCreatingCredential: false,
  isLoading: false,
  credentials: [],
};

const serviceUserCredentialsState = createSlice({
  name,
  initialState,
  reducers: {
    loadServiceUserCredentials: {
      reducer: (
        state: ServiceUserCredentialsState,
        _action: LoadServiceUserCredentialsAction
      ) => {
        state.isLoading = true;
        state.error = undefined;
      },
      prepare: (serviceUserId: string) => ({
        payload: { serviceUserId },
      }),
    },
    loadServiceUserCredentialsSuccess: {
      reducer: (
        state: ServiceUserCredentialsState,
        action: LoadServiceUserCredentialsSuccessAction
      ) => {
        state.isLoading = false;
        state.credentials = action.payload.data;
      },
      prepare: (data: Array<ServiceUserCredential>) => ({
        payload: { data },
      }),
    },
    loadServiceUserCredentialsFailure: {
      reducer: (
        state: ServiceUserCredentialsState,
        action: LoadServiceUserCredentialsFailureAction
      ) => {
        state.isLoading = false;
        state.error = action.error;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    removeServiceUserCredential: {
      reducer: (
        state: ServiceUserCredentialsState,
        _action: RemoveServiceUserCredentialAction
      ) => {
        state.isLoading = true;
      },
      prepare: (serviceUserId: string, apiKey: string) => ({
        payload: { serviceUserId, apiKey },
      }),
    },
    removeServiceUserCredentialSuccess: {
      reducer: (
        state: ServiceUserCredentialsState,
        action: RemoveServiceUserCredentialSuccessAction
      ) => {
        const { apiKey } = action.payload;

        state.credentials = state.credentials.filter(
          (credential) => credential.apiKey !== apiKey
        );

        state.isLoading = false;
        state.error = undefined;
      },
      prepare: (apiKey: string) => ({
        payload: { apiKey },
      }),
    },
    removeServiceUserCredentialFailure: {
      reducer: (
        state: ServiceUserCredentialsState,
        action: RemoveServiceUserCredentialFailureAction
      ) => {
        state.isLoading = false;

        state.error = action.error;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    makeServiceUserCredentialActive: {
      reducer: (
        state: ServiceUserCredentialsState,
        _action: ServiceUserCredentialStatusChangeAction
      ) => {
        state.isLoading = true;
      },
      prepare: (serviceUserId: string, apiKey: string) => ({
        payload: { serviceUserId, apiKey },
      }),
    },
    makeServiceUserCredentialActiveSuccess: {
      reducer: (
        state: ServiceUserCredentialsState,
        action: ServiceUserCredentialStatusChangeSuccessAction
      ) => {
        mergeCredentialState(state, action.payload.updatedCredential);
        state.isLoading = false;
        state.error = undefined;
      },
      prepare: (updatedCredential: ServiceUserCredential) => ({
        payload: { updatedCredential },
      }),
    },
    makeServiceUserCredentialActiveFailure: {
      reducer: (
        state: ServiceUserCredentialsState,
        action: ServiceUserCredentialStatusChangeFailureAction
      ) => {
        state.error = action.error;
        state.isLoading = false;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    makeServiceUserCredentialInactive: {
      reducer: (
        state: ServiceUserCredentialsState,
        _action: ServiceUserCredentialStatusChangeAction
      ) => {
        state.isLoading = true;
      },
      prepare: (serviceUserId: string, apiKey: string) => ({
        payload: { serviceUserId, apiKey },
      }),
    },
    makeServiceUserCredentialInactiveSuccess: {
      reducer: (
        state: ServiceUserCredentialsState,
        action: ServiceUserCredentialStatusChangeSuccessAction
      ) => {
        mergeCredentialState(state, action.payload.updatedCredential);
        state.isLoading = false;
        state.error = undefined;
      },
      prepare: (updatedCredential: ServiceUserCredential) => ({
        payload: { updatedCredential },
      }),
    },
    makeServiceUserCredentialInactiveFailure: {
      reducer: (
        state: ServiceUserCredentialsState,
        action: ServiceUserCredentialStatusChangeFailureAction
      ) => {
        state.isLoading = false;
        state.error = action.error;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    createServiceUserCredential: {
      reducer: (
        state: ServiceUserCredentialsState,
        _action: CreateServiceUserCredentialAction
      ) => {
        state.isCreatingCredential = true;
      },
      prepare: (serviceUserId: Id) => ({ payload: { serviceUserId } }),
    },
    createServiceUserCredentialSuccess: {
      reducer: (
        state: ServiceUserCredentialsState,
        action: CreateServiceUserCredentialSuccessAction
      ) => {
        state.isCreatingCredential = false;
        state.newCredential = action.payload.credential;
      },
      prepare: (credential: ServiceUserCredential) => ({
        payload: { credential },
      }),
    },
    createServiceUserCredentialFailure: {
      reducer: (
        state: ServiceUserCredentialsState,
        action: CreateServiceUserCredentialFailureAction
      ) => {
        state.isCreatingCredential = false;
        state.error = action.error;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    clearServiceUserCredential: (state: ServiceUserCredentialsState) => {
      state.newCredential = undefined;
    },
    reset: () => initialState,
  },
});

export const {
  loadServiceUserCredentials,
  loadServiceUserCredentialsFailure,
  loadServiceUserCredentialsSuccess,
  removeServiceUserCredential,
  removeServiceUserCredentialFailure,
  removeServiceUserCredentialSuccess,
  makeServiceUserCredentialActive,
  makeServiceUserCredentialActiveFailure,
  makeServiceUserCredentialActiveSuccess,
  makeServiceUserCredentialInactive,
  makeServiceUserCredentialInactiveFailure,
  makeServiceUserCredentialInactiveSuccess,
  createServiceUserCredential,
  createServiceUserCredentialFailure,
  createServiceUserCredentialSuccess,
  clearServiceUserCredential,
  reset,
} = serviceUserCredentialsState.actions;

// Selectors

const selectServiceUserCredentialsState = (state: {
  features: { access: { serviceUserCredentials: ServiceUserCredentialsState } };
}): ServiceUserCredentialsState => state.features.access.serviceUserCredentials;

export const selectServiceUserCredentialsError = createSelector(
  selectServiceUserCredentialsState,
  (state) => state.error
);

export const selectIsLoadingCredentials = createSelector(
  selectServiceUserCredentialsState,
  (state) => state.isLoading
);

export const selectServiceUserCredentials = createSelector(
  selectServiceUserCredentialsState,
  (state) => state.credentials
);

export const selectNewCredential = createSelector(
  selectServiceUserCredentialsState,
  (state) => state.newCredential
);

export default serviceUserCredentialsState.reducer;
