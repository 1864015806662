import { useMemo } from 'react';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

export interface EntityNamings {
  singular: string;
  plural: string;
}

const useEntityNamings = (dataType: DataType) => {
  const { t } = useTranslation();

  return useMemo<EntityNamings>(
    () => ({
      singular: t(`common:${dataType}`),
      plural: t(`common:${dataType}s`),
    }),
    [dataType, t]
  );
};

export default useEntityNamings;
