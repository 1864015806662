import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { ProductsRoute } from '@/components/routes/products/ProductsRoute';
import { ProductsListRoute } from '@/components/routes/products/ProductsListRoute';
import { ProductsCreateRoute } from '@/components/routes/products/ProductsCreateRoute';
import { ProductsEditRoute } from '@/components/routes/products/ProductsEditRoute';
import { ProductsDetailsRoute } from '@/components/routes/products/ProductsDetailsRoute';

export default () =>
  createCrudRoute({
    path: 'products',
    dataType: DataType.Product,
    element: <ProductsRoute />,
    details: <ProductsDetailsRoute />,
    list: <ProductsListRoute />,
    create: <ProductsCreateRoute />,
    edit: <ProductsEditRoute />,
  });
