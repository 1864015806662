import { createSelector } from '@reduxjs/toolkit';

import { ExternalMapping } from '@m3ter-com/m3ter-api';

import type { ExternalMappingCreateFormValues } from '@/components/features/external-mappings/ExternalMappingForm';
import createFormSlice, {
  CreateAction,
  FormState,
} from '@/store/forms/createFormSlice';

export type CreateExternalMappingsAction =
  CreateAction<ExternalMappingCreateFormValues>;

const externalMappingsCreateFormSlice = createFormSlice<
  ExternalMapping,
  ExternalMappingCreateFormValues
>('features/integrations/externalMappings/createForm');

export const {
  create: createExternalMappings,
  createSuccess: createExternalMappingsSuccess,
  createFailure: createExternalMappingsFailure,
} = externalMappingsCreateFormSlice.actions;

// Selectors
const selectExternalMappingsCreateFormState = (state: {
  features: {
    integrations: { externalMappings: { createForm: FormState } };
  };
}): FormState => state.features.integrations.externalMappings.createForm;

export const selectIsSavingExternalMappings = createSelector(
  selectExternalMappingsCreateFormState,
  (state) => state.isSaving
);

export const selectExternalMappingsCreateError = createSelector(
  selectExternalMappingsCreateFormState,
  (state) => state.error
);

export default externalMappingsCreateFormSlice.reducer;
