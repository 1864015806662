interface CognitoConfig {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  domain: string;
}

interface UserflowConfig {
  token: string;
}

export interface EnvironmentConfig {
  apiEndpoint: string;
  ingestEndpoint: string;
  cognito: CognitoConfig;
  userflow: UserflowConfig;
}

enum EnvironmentName {
  Alpha = 'alpha',
  Gamma = 'gamma',
  Production = 'prod',
}

const hostEnvironments: Record<string, EnvironmentName> = {
  'console.alpha.m3ter.com': EnvironmentName.Alpha,
  'console.gamma.m3ter.com': EnvironmentName.Gamma,
  'console.m3ter.com': EnvironmentName.Production,
};

const environments: Record<EnvironmentName, EnvironmentConfig> = {
  [EnvironmentName.Alpha]: {
    apiEndpoint: 'https://api.alpha.m3ter.com',
    ingestEndpoint: 'https://ingest.alpha.m3ter.com',
    cognito: {
      region: 'eu-west-2',
      userPoolId: 'eu-west-2_y8kDS8xHl',
      userPoolWebClientId: '2qb6bco9decm6mab0683r8bc31',
      domain: 'm3ter-alpha.auth.eu-west-2.amazoncognito.com',
    },
    userflow: {
      token: 'ct_6x5q7xt32beqdhunzfwxtoygue',
    },
  },
  [EnvironmentName.Gamma]: {
    apiEndpoint: 'https://api.gamma.m3ter.com',
    ingestEndpoint: 'https://ingest.gamma.m3ter.com',
    cognito: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_YMAxrUUmb',
      userPoolWebClientId: '4l3f5krhiqe332us12tb8ju9lr',
      domain: 'm3ter-gamma.auth.us-east-1.amazoncognito.com',
    },
    userflow: {
      token: 'ct_6x5q7xt32beqdhunzfwxtoygue',
    },
  },
  [EnvironmentName.Production]: {
    apiEndpoint: 'https://api.m3ter.com',
    ingestEndpoint: 'https://ingest.m3ter.com',
    cognito: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_9OJBvIFUw',
      userPoolWebClientId: '73711n9plh5vtn9ekf5gkk0mhk',
      domain: 'm3ter.auth.us-east-1.amazoncognito.com',
    },
    userflow: {
      token: 'ct_hc5nhm3idzamla2idgdo2uhc5u',
    },
  },
};

export const getEnvironmentName = (): EnvironmentName | undefined =>
  hostEnvironments[window.location.hostname] ??
  process.env.REACT_APP_ENVIRONMENT;

export const isEnvironment = (environmentName: EnvironmentName): boolean =>
  getEnvironmentName() === environmentName;

export const isLocal = () => process.env.NODE_ENV !== 'production';

export const isAlpha = (): boolean => isEnvironment(EnvironmentName.Alpha);

export const isGamma = (): boolean => isEnvironment(EnvironmentName.Gamma);

export const isProd = (): boolean => isEnvironment(EnvironmentName.Production);

export const isLocalOrAlpha = (): boolean => isLocal() || isAlpha();

export const isSentryEnabled = (): boolean => !isLocalOrAlpha();

// Export config for the current environment, which defaults to alpha.
export default environments[getEnvironmentName() ?? EnvironmentName.Alpha];
