import React, { useMemo } from 'react';

import { Card, CardBody, Heading, Link } from '@chakra-ui/react';

import { DataType, Account } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, CardActionsHeader } from '@m3ter-com/ui-components';

import { OtherListIds } from '@/types/lists';

import useOrg from '@/hooks/data/crud/useOrg';
import useAccountUpdate from '@/hooks/features/accounts/useAccountUpdate';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';
import { EntityMultiSelectModal } from '@/components/common/data/EntitySelectModal';

export interface AccountChildrenProps {
  account: Account;
}

const addChildrenModalSearchFields = ['code', 'name'];

const emptyArray = new Array<any>();

export const AccountChildren: React.FC<AccountChildrenProps> = ({
  account,
}) => {
  const { t } = useTranslation();
  const { currentOrgId: organizationId } = useOrg();
  const { id: accountId } = account;

  const childAccountsListPathParams = useMemo(
    () => ({
      accountId,
      organizationId,
    }),
    [accountId, organizationId]
  );

  const {
    addChildrenToParentAccount,
    removeChildFromParentAccount,
    isAddingChildren,
    isRemovingChild,
    isAccountSelectionDisabled,
    isChildrenModalOpen,
    onCloseChildrenModal,
    onOpenChildrenModal,
  } = useAccountUpdate(accountId);

  const accountChildrenListColumns = useMemo<Array<ColumnDefinition<Account>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (item: Account) => (
          <Link as={CrudDetailsLink} dataType={DataType.Account} id={item.id}>
            {item.name}
          </Link>
        ),
      },
      {
        id: 'code',
        header: t('forms:labels.code'),
        accessor: 'code',
      },
      {
        id: 'remove-child-button',
        align: 'right',
        header: '',
        accessor: (item: Account) => (
          <Button
            onClick={() => removeChildFromParentAccount(item.id)}
            size="sm"
            isLoading={isRemovingChild}
          >
            {t('features:account.removeChildAccount')}
          </Button>
        ),
      },
    ],
    [t, isRemovingChild, removeChildFromParentAccount]
  );

  const addChildrenModalColumns = useMemo<Array<ColumnDefinition<Account>>>(
    () => [
      { id: 'name', accessor: 'name', header: t('forms:labels.name') },
      { id: 'code', accessor: 'code', header: t('forms:labels.code') },
    ],
    [t]
  );

  return (
    <React.Fragment>
      <Card>
        <CardActionsHeader
          actions={
            <Button
              size="sm"
              onClick={onOpenChildrenModal}
              isLoading={isAddingChildren}
            >
              {t('features:account.addChildren')}
            </Button>
          }
        >
          <Heading size="md">{t('features:account.childAccounts')}</Heading>
        </CardActionsHeader>
        <CardBody>
          <CrudList<Account>
            listId={OtherListIds.AccountChildren}
            actionName="listChildren"
            columns={accountChildrenListColumns}
            dataType={DataType.Account}
            pathParams={childAccountsListPathParams}
          />
        </CardBody>
      </Card>
      <EntityMultiSelectModal<Account>
        dataType={DataType.Account}
        columns={addChildrenModalColumns}
        isItemDisabled={isAccountSelectionDisabled}
        isOpen={isChildrenModalOpen}
        onClose={onCloseChildrenModal}
        onConfirm={addChildrenToParentAccount}
        searchFields={addChildrenModalSearchFields}
        selected={emptyArray}
      />
    </React.Fragment>
  );
};
