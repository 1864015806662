import i18next from 'i18next';

import { DataType, DebitReason } from '@m3ter-com/m3ter-api';

import { EntityRouteListIds } from '@/types/lists';

import { createPickListRoute } from '@/routes/picklist';

export default () =>
  createPickListRoute<DebitReason>({
    path: 'debit-reasons',
    dataType: DataType.DebitReason,
    title: i18next.t('common:debitReasons'),
    listId: EntityRouteListIds.DebitReason,
  });
