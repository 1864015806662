import React, {
  useCallback,
  useMemo,
  PropsWithChildren,
  ReactElement,
} from 'react';

import ReactSelect, {
  ActionMeta,
  createFilter,
  SingleValue,
} from 'react-select';
import { useFormControl } from '@chakra-ui/react';

import useReactSelectStyles from '../../../../theme/hooks/useReactSelectStyles';
import { Option, PassthroughProps, SelectBaseProps } from '../types';
import { getStringValue, isOptionNotOptionGroup } from '../utils';
import { OptionWithSecondaryLabel } from '../OptionWithSecondaryLabel';
import useSelectKeyDown from '../../../../hooks/useSelectKeyDown';

export interface SelectProps<T extends string = string>
  extends SelectBaseProps<T>,
    PassthroughProps {}

const customComponents = {
  Option: OptionWithSecondaryLabel,
};

export function Select<T extends string = string>({
  options,
  value,
  onChange,
  inputId,
  isSearchable = false,
  searchMatchFrom = 'start',
  ...selectProps
}: PropsWithChildren<SelectProps<T>>): ReactElement<any, any> {
  const { onMenuOpen, onMenuClose, onSelectKeyDown } = useSelectKeyDown();
  const { styles, theme } = useReactSelectStyles<Option<T>>();

  // Get the ID from the Chakra `FormControl` if there is one, so that
  // the label and form field are connected.
  const { id } = useFormControl({});

  const filterOption = useMemo(
    () =>
      createFilter<Option<T>>({
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: searchMatchFrom,
        stringify: ({ data }) => getStringValue(data),
        trim: true,
      }),
    [searchMatchFrom]
  );

  const selectedOption = useMemo(() => {
    // Can't use .find here since we might have options that are "buried"
    // inside of a group and we want to return a single Option, not an
    // OptionGroup
    let matchingOption: Option<T> | undefined;
    options.forEach((optionOrGroup) => {
      if (matchingOption) {
        return;
      }

      if (isOptionNotOptionGroup(optionOrGroup)) {
        if (optionOrGroup.value === value) {
          matchingOption = optionOrGroup;
        }
      } else {
        matchingOption = optionOrGroup.options.find(
          (option) => option.value === value
        );
      }
    });

    // ReactSelect expects `null` for no selection, rather than `undefined`.
    return matchingOption ?? null;
  }, [options, value]);

  const handleChange = useCallback(
    (newOption: SingleValue<Option<T>>, actionMeta: ActionMeta<Option<T>>) => {
      if (!newOption || actionMeta.action === 'clear') {
        onChange(null);
      } else {
        onChange(newOption.value);
      }
    },
    [onChange]
  );

  return (
    <ReactSelect<Option<T>, false>
      filterOption={filterOption}
      inputId={inputId ?? id}
      isMulti={false}
      isSearchable={isSearchable}
      onKeyDown={onSelectKeyDown}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      options={options}
      styles={styles}
      theme={theme}
      value={selectedOption}
      onChange={handleChange}
      components={customComponents}
      {...selectProps}
    />
  );
}
