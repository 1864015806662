import React, { useMemo } from 'react';

import { Combobox, ComboboxProps } from '../Combobox';

export interface AutocompleteInputProps extends Omit<ComboboxProps, 'options'> {
  options?: Array<string>;
}

const emptyOptions = new Array<string>();

export const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  options = emptyOptions,
  ...comboboxProps
}) => {
  const comboboxOptions = useMemo(
    () => options.map((option) => ({ value: option, label: option })),
    [options]
  );

  return <Combobox options={comboboxOptions} {...comboboxProps} />;
};
