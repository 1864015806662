import React from 'react';

import { Code, SimpleGrid, VStack } from '@chakra-ui/react';

import { DataType, Organization } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { CopyToClipboard, KeyValue } from '@m3ter-com/ui-components';

import { DetailsCard } from '@/components/common/data/DetailsCard';
import { AddressDetails } from '@/components/common/data/AddressDetails';
import { ReferenceLink } from '@/components/common/data/ReferenceLink';

export interface OrganizationDetailsProps {
  org: Organization;
  adminActions?: React.ReactNode;
  showCustomer?: boolean;
}

export const OrganizationDetails: React.FC<OrganizationDetailsProps> = ({
  org,
  adminActions,
  showCustomer = false,
}) => {
  const { t } = useTranslation();

  return (
    <DetailsCard
      data={org}
      dataType={DataType.Organization}
      header={adminActions}
      details={
        <SimpleGrid columns={3} gap={6} w="100%">
          <VStack alignItems="start">
            <KeyValue
              label={t('features:organizations.name')}
              value={org.organizationName}
            />
            <KeyValue
              label={t('features:organizations.shortName')}
              value={org.shortName}
            />
          </VStack>
          <VStack alignItems="start">
            <KeyValue
              label={t('features:organizations.orgId')}
              value={
                <CopyToClipboard value={org.id}>
                  <Code>{org.id}</Code>
                </CopyToClipboard>
              }
            />
            {showCustomer && (
              <KeyValue
                label={t('common:customer')}
                value={
                  org.customerId ? (
                    <ReferenceLink
                      dataType={DataType.Customer}
                      id={org.customerId}
                      accessor="name"
                    />
                  ) : (
                    '-'
                  )
                }
              />
            )}
          </VStack>
          <KeyValue
            label={t('forms:labels.address')}
            value={<AddressDetails data={org} />}
          />
        </SimpleGrid>
      }
    />
  );
};
