import React, { ReactElement, ElementType } from 'react';

import { Entity, PathParams } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import useCrudEdit from '@/hooks/data/crud/useCrudEdit';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch';

export interface CrudEditProps {
  form: ElementType;
  extraData?: Record<string, any>;
  pathParams?: PathParams;
}

export function CrudEdit<E extends Entity>({
  form: Form,
  extraData,
  pathParams,
}: CrudEditProps): ReactElement<any, any> | null {
  const { t } = useTranslation();

  const {
    dataType,
    isLoading,
    isSaving,
    itemData,
    loadingError,
    savingError,
    onCancel,
    updateItem,
  } = useCrudEdit<E>(pathParams);

  const { singular: entityName } = useEntityNamings(dataType);

  return (
    <React.Fragment>
      <BreadcrumbItem title={t('common:edit')} />
      <LoadingErrorContentSwitch
        showContentOnError
        isLoading={isLoading}
        error={loadingError || savingError}
        errorTitle={t(
          `errors:generic.${
            loadingError ? 'problemLoadingData' : 'problemSavingData'
          }`,
          { entityName }
        )}
        showContent={!!itemData}
      >
        <Form
          isEdit
          onSave={updateItem}
          onCancel={onCancel}
          isSaving={isSaving}
          initialValues={itemData}
          extraData={extraData}
          entityName={entityName}
          dataType={dataType}
        />
      </LoadingErrorContentSwitch>
    </React.Fragment>
  );
}
