import React from 'react';

import { Box, useToken, BoxProps } from '@chakra-ui/react';

export interface HorizontalScrollBoxProps extends BoxProps {}

/**
 * Chakra doesn't support these webkit pseudo selectors in their theme objects
 * so they need to go into the css prop
 */
export const HorizontalScrollBox: React.FC<HorizontalScrollBoxProps> = (
  props
) => {
  const [borderColor] = useToken('colors', ['chakra-border-color']);

  return (
    <Box
      w="100%"
      overflowX="scroll"
      pb={4}
      css={{
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: borderColor,
          borderRadius: '24px',
        },
      }}
      {...props}
    />
  );
};
