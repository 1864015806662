import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { ForgotPasswordSubmitFormValues } from '@m3ter-com/console-core/types';

const suite = create(
  'forgotPasswordSubmitForm',
  (data: Partial<ForgotPasswordSubmitFormValues>) => {
    test(
      'email',
      i18next.t('forms:validations.users.emailAddressRequired'),
      () => {
        enforce(data.email).isNotEmpty();
      }
    );

    test('code', i18next.t('forms:validations.users.codeRequired'), () => {
      enforce(data.code).isNotEmpty();
    });

    test(
      'newPassword',
      i18next.t('forms:validations.users.passwordRequired'),
      () => {
        enforce(data.newPassword).isNotEmpty();
      }
    );

    test(
      'confirmPassword',
      i18next.t('forms:validations.users.confirmNewPassword'),
      () => {
        enforce(data.confirmPassword).isNotEmpty();
      }
    );

    test(
      'confirmPassword',
      i18next.t('forms:validations.users.passwordsMustMatch'),
      () => {
        enforce(data.newPassword).equals(data.confirmPassword);
      }
    );
  }
);

export default suite;
