import React from 'react';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';

const LazyIntegrationsRunRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationsRun" */ '@/components/routes/integrations/IntegrationRunRoute'
  ).then((module) => ({ default: module.IntegrationRunRoute }))
);

export default (): NamedRoute => ({
  path: 'runs/*',
  name: 'integrations.runs',
  element: <LazyIntegrationsRunRoute />,
});
