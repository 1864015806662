import React from 'react';

import { Badge, Heading, HStack, Link, VStack } from '@chakra-ui/react';

import { DataType, CompoundAggregation } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValueList, KeyValueListItem } from '@m3ter-com/ui-components';
import { formatNumber } from '@m3ter-com/console-core/utils';

import { formatEntityUnit } from '@/util/data';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import { isCompoundAggregationSegmented } from '@/util/aggregation';

export interface PricingGridCompoundAggregationDetailsProps {
  compoundAggregation: CompoundAggregation;
}

export const PricingGridCompoundAggregationDetails: React.FC<
  PricingGridCompoundAggregationDetailsProps
> = ({ compoundAggregation }) => {
  const { t } = useTranslation();

  return (
    <VStack alignItems="start">
      <Link
        as={CrudDetailsLink}
        dataType={DataType.CompoundAggregation}
        id={compoundAggregation.id}
      >
        <Heading as="h3" size="sm" maxW="300px" wordBreak="break-word">
          {compoundAggregation.name}
        </Heading>
      </Link>
      <HStack>
        <Badge variant="outline" display="block" w="max-content">
          {t('features:pricing.compound')}
        </Badge>
        {isCompoundAggregationSegmented(compoundAggregation) && (
          <Badge variant="outline">{t('features:aggregation.segmented')}</Badge>
        )}
      </HStack>
      <KeyValueList>
        <KeyValueListItem
          label={t('forms:labels.unit')}
          value={formatEntityUnit(compoundAggregation.unit)}
        />
        <KeyValueListItem
          label={t('forms:labels.quantityPerUnit')}
          value={formatNumber(compoundAggregation.quantityPerUnit)}
        />
        <KeyValueListItem
          label={t('forms:labels.rounding')}
          value={t(`common:rounding.${compoundAggregation.rounding}`)}
        />
      </KeyValueList>
    </VStack>
  );
};
