import React from 'react';

import { Balance } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { BalanceForm } from '@/components/features/accounts/BalanceForm';

export const BalancesEditRoute: React.FC = () => (
  <CrudEdit<Balance> form={BalanceForm} />
);
