import React, { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useResolvedPath } from 'react-router-dom';

import { DataType, SupportAccess } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useSingleton from '@/hooks/data/useSingleton';
import useEntityPermissionPolicyList from '@/hooks/features/access/useEntityPermissionPolicyList';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch';
import {
  SupportAccessForm,
  SupportAccessUpdateFormValues,
} from '@/components/features/access/support-access/SupportAccessForm';
import {
  selectError,
  selectIsSaving,
  updateSupportAccess,
} from '@/store/features/access/support-access/supportAccessUpdate';

export const SupportAccessEditRoute: React.FC = () => {
  const { t } = useTranslation();
  const path = useResolvedPath('..');
  const dispatch = useDispatch();

  const {
    data: supportAccess,
    error: supportAccessError,
    isLoading: supportAccessLoading,
  } = useSingleton<SupportAccess>(DataType.SupportAccess);

  const {
    permissionPolicies,
    permissionPoliciesError,
    permissionPoliciesLoading,
  } = useEntityPermissionPolicyList({ dataType: DataType.SupportAccess });

  const isSaving = useSelector(selectIsSaving);
  const updateError = useSelector(selectError);
  const onSave = useCallback(
    (updateData: SupportAccessUpdateFormValues) => {
      dispatch(updateSupportAccess(updateData, path.pathname));
    },
    [dispatch, path.pathname]
  );

  const initialValues = useMemo(
    () => ({
      ...supportAccess,
      permissionPolicyIds: permissionPolicies.map(({ id }) => id),
    }),
    [supportAccess, permissionPolicies]
  );

  return (
    <React.Fragment>
      <BreadcrumbItem title={t('common:edit')} />
      <LoadingErrorContentSwitch
        showContentOnError
        error={supportAccessError ?? permissionPoliciesError}
        isLoading={supportAccessLoading || permissionPoliciesLoading}
      >
        {updateError && <ErrorAlert error={updateError} />}
        {!!supportAccess && (
          <SupportAccessForm
            initialValues={initialValues}
            isSaving={isSaving}
            returnUrl={path.pathname}
            onSave={onSave}
          />
        )}
      </LoadingErrorContentSwitch>
    </React.Fragment>
  );
};
