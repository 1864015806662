import { Id, PrincipalPermission } from '../types';

import { getOrganizationPath } from '../util/path';
import { get, post } from '../client';

export interface UpdateEntityPermissionPolicyBody {
  principalId: Id;
}

interface UpdateEntityPermissionPolicyData {
  actionName: string;
  organizationId: Id;
  permissionPolicyId: Id;
  data?: Partial<UpdateEntityPermissionPolicyBody>;
}

export interface UpdateUserGroupsUsersBody {
  targetType: 'item';
  targetId: Id;
}

const emptyBody: Partial<UpdateEntityPermissionPolicyBody> = {};

export const getResourceTypes = () =>
  get({ path: '/resourcetypes' }) as Promise<Array<string>>;

export const updateEntityPermissionPolicy = ({
  actionName,
  organizationId,
  permissionPolicyId,
  data = emptyBody,
}: UpdateEntityPermissionPolicyData): Promise<PrincipalPermission> =>
  post({
    body: data,
    path: getOrganizationPath(
      '/permissionpolicies/:permissionPolicyId/:actionName'
    ),
    pathParams: {
      organizationId,
      permissionPolicyId,
      actionName,
    },
  }) as Promise<PrincipalPermission>;
