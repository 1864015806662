import { ComponentSingleStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const button: ComponentSingleStyleConfig = {
  baseStyle: {
    fontWeight: 'normal',
  },
  variants: {
    subtle: (props) => {
      const { colorScheme: c } = props;

      return {
        color: mode(`${c}.800`, `${c}.200`)(props),
        bg: mode(`${c}.100`, `${c}.900`)(props),
        _hover: {
          bg: mode(`${c}.200`, `${c}.800`)(props),
        },
      };
    },
  },
};

export default button;
