import { createSelector } from '@reduxjs/toolkit';

import { UserGroup } from '@m3ter-com/m3ter-api';

import createFormSlice, {
  CreateAction,
  FormState,
} from '@/store/forms/createFormSlice';
import { UserGroupCreateFormValues } from '@/components/features/access/user-group/UserGroupCreateForm';

export interface UserGroupCreateState extends FormState {}

export type CreateUserGroupAction = CreateAction<UserGroupCreateFormValues>;

const userGroupCreateSlice = createFormSlice<
  UserGroup,
  UserGroupCreateFormValues
>('features/access/userGroupCreate');

export const {
  create: createUserGroup,
  createSuccess: createUserGroupSuccess,
  createFailure: createUserGroupFailure,
} = userGroupCreateSlice.actions;

const selectUserGroupCreateState = (state: {
  features: { access: { userGroupCreate: UserGroupCreateState } };
}): UserGroupCreateState => state.features.access.userGroupCreate;

export const selectIsSaving = createSelector(
  selectUserGroupCreateState,
  (state) => state.isSaving
);

export const selectError = createSelector(
  selectUserGroupCreateState,
  (state) => state.error
);

export default userGroupCreateSlice.reducer;
