import React from 'react';

import {
  Box,
  BoxProps,
  forwardRef,
  Link,
  LinkProps,
  ThemingProps,
  useStyleConfig,
} from '@chakra-ui/react';

export interface TabLinkProps extends LinkProps {
  active?: boolean;
}

export const TabbedLinks = forwardRef<BoxProps, 'div'>((props, ref) => {
  const styles = useStyleConfig('TabbedLinks');

  return <Box ref={ref} sx={styles} {...props} />;
});

export const TabLink = forwardRef<TabLinkProps, 'a'>(
  ({ children, active, ...rest }, ref) => {
    const styleProps: ThemingProps = active ? { variant: 'active' } : {};
    const styles = useStyleConfig('TabLink', styleProps);

    return (
      <Link ref={ref} sx={styles} {...rest}>
        {children}
      </Link>
    );
  }
);
