import React, { ReactElement, useCallback, useMemo } from 'react';

import { Flex, Grid, Tag, TagLabel, Wrap, WrapItem } from '@chakra-ui/react';

import { Entity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button } from '@m3ter-com/ui-components';

import { ListSearchCriteria, ListSearchCriterion } from '@/types/lists';

import useCrudListFilters from '@/hooks/data/crud/useCrudListFilters';

interface FilterCriterionWithField extends ListSearchCriterion {
  field: string;
}

export function CrudListActiveFilters<
  E extends Entity = Entity
>(): ReactElement<any, any> | null {
  const { t } = useTranslation();

  const { currentFilters, filterDefinitions, removeAllFilters, searchFields } =
    useCrudListFilters<E>();

  const filtersWithoutSearchFields = useMemo<ListSearchCriteria>(() => {
    if (!currentFilters) {
      return {};
    }

    if (!searchFields?.length) {
      return currentFilters;
    }

    const filteredFilters = { ...currentFilters };
    searchFields.forEach((field) => {
      if (filteredFilters[field]) {
        delete filteredFilters[field];
      }
    });
    return filteredFilters;
  }, [currentFilters, searchFields]);

  const transformTagLabel = useCallback(
    (criterion: FilterCriterionWithField) => {
      const { comparator, field, value } = criterion;
      const definition = filterDefinitions?.find(({ name }) => name === field);

      const label = definition?.label ?? field;

      const comparatorTranslation = t(
        `common:searchComparator.${comparator}`
      ).toLowerCase();

      const translatedValue = definition?.valueTranslator
        ? definition.valueTranslator(value)
        : value;

      return (
        <span>
          {`${label} ${comparatorTranslation}: `}
          {translatedValue}
        </span>
      );
    },
    [filterDefinitions, t]
  );

  const filterTags = useMemo(() => {
    return Object.entries(filtersWithoutSearchFields).flatMap(
      ([field, criteria]) => {
        return (Array.isArray(criteria) ? criteria : [criteria]).map(
          ({ comparator, value }) => ({
            field,
            comparator,
            value,
          })
        );
      }
    );
  }, [filtersWithoutSearchFields]);

  return Object.keys(filterDefinitions || {}).length > 0 &&
    filterTags.length > 0 ? (
    <Grid gap={4} gridTemplateColumns="1fr auto" alignItems="center">
      <Flex gap={2} alignItems="center" display="inline-flex">
        <Wrap>
          {filterTags.map((filter) => (
            <WrapItem
              key={`${filter.field}-${filter.comparator}-${filter.value}`}
            >
              <Tag size="md" data-testid="filter-tag">
                <TagLabel>{transformTagLabel(filter)}</TagLabel>
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
      </Flex>
      <Button
        size="md"
        w="fit-content"
        onClick={removeAllFilters}
        data-testid="clear-all-filters-button"
      >
        {t('features:search.clearAllFilters')}
      </Button>
    </Grid>
  ) : null;
}
