import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DataType,
  Aggregation,
  CompoundAggregation,
  Id,
  Counter,
  CounterPricing,
  Plan,
  PlanGroup,
  PlanTemplate,
  Pricing,
} from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

import { uniq } from '@/util/array';
import { createSelectById, createSelectByIds } from '@/store/data/data';

interface AddPlansToPlanGroupPayload {
  planGroupId: Id;
  planIds: Array<Id>;
}
export type AddPlansToPlanGroupAction =
  PayloadAction<AddPlansToPlanGroupPayload>;

interface AddPricingAggregationsPayload {
  ids: Array<Id>;
  isCompound?: boolean;
}
export type AddPricingAggregationsAction =
  PayloadAction<AddPricingAggregationsPayload>;

export type FailureAction = PayloadAction<undefined, string, never, AppError>;

interface LoadAccountPlanPricingDataPayload {
  accountPlanId: string;
  addAggregationId?: string;
  addCompoundAggregationId?: string;
  addItemCounterId?: Id;
}
export type LoadAccountPlanPricingDataAction =
  PayloadAction<LoadAccountPlanPricingDataPayload>;

interface LoadPlanPricingDataPayload {
  planTemplateId: string;
  planId?: string;
  addAggregationId?: string;
  addCompoundAggregationId?: string;
  addItemCounterId?: Id;
}
export type LoadPlanPricingDataAction =
  PayloadAction<LoadPlanPricingDataPayload>;

interface LoadPricingDataSuccessPayload extends PlanDetailsPricingData {}
export type LoadPricingDataSuccessAction =
  PayloadAction<LoadPricingDataSuccessPayload>;

interface RemovePricingUsageEntityPayload {
  id: Id;
  isCompound?: boolean;
}
export type RemovePricingUsageEntityAction =
  PayloadAction<RemovePricingUsageEntityPayload>;

interface LoadExtraUsageEntitiesPayload {
  extraAggregationIds?: Array<Id>;
  extraCompoundAggregationIds?: Array<Id>;
  extraItemCounterIds?: Array<Id>;
}
export type LoadExtraUsageEntitiesAction =
  PayloadAction<LoadExtraUsageEntitiesPayload>;

export type LoadExtraUsageEntitiesSuccessAction = PayloadAction<undefined>;

export interface PlanDetailsPricingData {
  aggregationIds: Array<Id>;
  compoundAggregationIds: Array<Id>;
  itemCounterIds: Array<Id>;
  pricingIds: Array<Id>;
  itemCounterPricingIds: Array<Id>;
  planIds: Array<Id>;
  planTemplateIds: Array<Id>;
  planGroupId?: Id;
}

export interface PlanDetailsPricingDataEntities {
  aggregations: Array<Aggregation>;
  compoundAggregations: Array<CompoundAggregation>;
  itemCounters: Array<Counter>;
  pricings: Array<Pricing>;
  itemCounterPricings: Array<CounterPricing>;
  plans: Array<Plan>;
  planTemplates: Array<PlanTemplate>;
  planGroup?: PlanGroup;
}

export interface PlanDetailsState {
  extraPricingAggregationIds: Array<Id>;
  extraPricingCompoundAggregationIds: Array<Id>;
  extraPricingItemCounterIds: Array<Id>;
  isLoadingPricingData: boolean;
  isLoadingExtraUsageEntities: boolean;
  error?: AppError;
  extraUsageEntitiesError?: AppError;
  pricingData?: PlanDetailsPricingData;
}

type KeysWithIds = keyof {
  [P in keyof PlanDetailsState as PlanDetailsState[P] extends Array<Id>
    ? P
    : never]: P;
};

const getStateFieldToUpdate = (isCompound?: boolean): KeysWithIds =>
  // eslint-disable-next-line no-nested-ternary
  isCompound === true
    ? 'extraPricingCompoundAggregationIds'
    : isCompound === false
    ? 'extraPricingAggregationIds'
    : 'extraPricingItemCounterIds';

const updateStateIds = (
  state: PlanDetailsState,
  stateFieldToUpdate: KeysWithIds,
  ids: Array<Id>
): void => {
  state[stateFieldToUpdate] = uniq([...state[stateFieldToUpdate], ...ids]);
};

const removeIdFromState = (
  state: PlanDetailsState,
  stateFieldToUpdate: KeysWithIds,
  idToRemove: Id
): void => {
  state[stateFieldToUpdate] = state[stateFieldToUpdate].filter(
    (id) => id !== idToRemove
  );
};

const name = 'features/pricing/planDetails';

export const initialState: PlanDetailsState = {
  extraPricingAggregationIds: [],
  extraPricingCompoundAggregationIds: [],
  extraPricingItemCounterIds: [],
  isLoadingPricingData: false,
  isLoadingExtraUsageEntities: false,
};

const planDetailsSlice = createSlice({
  name,
  initialState,
  reducers: {
    addPlansToPlanGroup: {
      reducer: (
        state: PlanDetailsState,
        _action: AddPlansToPlanGroupAction
      ) => {
        state.isLoadingPricingData = true;
      },
      prepare: (planGroupId: Id, planIds: Array<Id>) => ({
        payload: { planGroupId, planIds },
      }),
    },
    addPlansToPlanGroupFailure: (state: PlanDetailsState) => {
      state.isLoadingPricingData = false;
    },
    addPricingUsageEntities: {
      reducer: (
        state: PlanDetailsState,
        action: AddPricingAggregationsAction
      ) => {
        const { ids, isCompound } = action.payload;
        updateStateIds(state, getStateFieldToUpdate(isCompound), ids);
      },
      prepare: (ids: Array<Id>, isCompound?: boolean) => ({
        payload: { ids, isCompound },
      }),
    },
    loadAndAddExtraUsageEntities: {
      reducer: (
        state: PlanDetailsState,
        _action: LoadExtraUsageEntitiesAction
      ) => {
        state.isLoadingExtraUsageEntities = true;
      },
      prepare: (
        extraAggregationIds?: Array<Id>,
        extraCompoundAggregationIds?: Array<Id>,
        extraItemCounterIds?: Array<Id>
      ) => ({
        payload: {
          extraAggregationIds,
          extraCompoundAggregationIds,
          extraItemCounterIds,
        },
      }),
    },
    loadExtraUsageEntitiesSuccess: (
      state: PlanDetailsState,
      _action: LoadExtraUsageEntitiesSuccessAction
    ) => {
      state.extraUsageEntitiesError = undefined;
      state.isLoadingExtraUsageEntities = false;
    },
    loadExtraUsageEntitiesFailure: {
      reducer: (state: PlanDetailsState, action: FailureAction) => {
        state.extraUsageEntitiesError = action.error;
        state.isLoadingExtraUsageEntities = false;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    loadAccountPlanPricingData: {
      reducer: (
        state: PlanDetailsState,
        _action: LoadAccountPlanPricingDataAction
      ) => {
        state.isLoadingPricingData = true;
      },
      prepare: (
        accountPlanId: Id,
        addAggregationId?: Id,
        addCompoundAggregationId?: Id,
        addItemCounterId?: Id
      ) => ({
        payload: {
          accountPlanId,
          addAggregationId,
          addCompoundAggregationId,
          addItemCounterId,
        },
      }),
    },
    loadPlanPricingData: {
      reducer: (
        state: PlanDetailsState,
        _action: LoadPlanPricingDataAction
      ) => {
        state.isLoadingPricingData = true;
      },
      prepare: (
        planTemplateId: Id,
        planId?: Id,
        addAggregationId?: Id,
        addCompoundAggregationId?: Id,
        addItemCounterId?: Id
      ) => ({
        payload: {
          planTemplateId,
          planId,
          addAggregationId,
          addCompoundAggregationId,
          addItemCounterId,
        },
      }),
    },
    loadPricingDataFailure: {
      reducer: (state: PlanDetailsState, action: FailureAction) => {
        state.error = action.error;
        state.isLoadingPricingData = false;
      },
      prepare: (error: AppError) => ({ payload: undefined, error }),
    },
    loadPricingDataSuccess: {
      reducer: (
        state: PlanDetailsState,
        action: LoadPricingDataSuccessAction
      ) => {
        state.pricingData = action.payload;
        state.error = undefined;
        state.isLoadingPricingData = false;
      },
      prepare: (
        aggregationIds: Array<Id>,
        compoundAggregationIds: Array<Id>,
        itemCounterIds: Array<Id>,
        pricingIds: Array<Id>,
        itemCounterPricingIds: Array<Id>,
        planIds: Array<Id>,
        planTemplateIds: Array<Id>,
        planGroupId?: Id
      ) => ({
        payload: {
          aggregationIds,
          compoundAggregationIds,
          itemCounterIds,
          pricingIds,
          itemCounterPricingIds,
          planIds,
          planTemplateIds,
          planGroupId,
        },
      }),
    },
    removePricingUsageEntity: {
      reducer: (
        state: PlanDetailsState,
        action: RemovePricingUsageEntityAction
      ) => {
        const { id, isCompound } = action.payload;
        removeIdFromState(state, getStateFieldToUpdate(isCompound), id);
      },
      prepare: (id: Id, isCompound?: boolean) => ({
        payload: { id, isCompound },
      }),
    },
    reset: () => initialState,
  },
});

// Actions

export const {
  addPlansToPlanGroup,
  addPlansToPlanGroupFailure,
  addPricingUsageEntities,
  loadAccountPlanPricingData,
  loadPlanPricingData,
  loadPricingDataFailure,
  loadPricingDataSuccess,
  loadAndAddExtraUsageEntities,
  loadExtraUsageEntitiesSuccess,
  loadExtraUsageEntitiesFailure,
  removePricingUsageEntity,
  reset,
} = planDetailsSlice.actions;

const selectPlanDetailsState = (state: {
  features: { pricing: { planDetails: PlanDetailsState } };
}): PlanDetailsState => state.features.pricing.planDetails;

const selectAggregationsByIds = createSelectByIds<Aggregation>(
  DataType.Aggregation
);
const selectCompoundAggregationsByIds = createSelectByIds<CompoundAggregation>(
  DataType.CompoundAggregation
);
const selectItemCountersByIds = createSelectByIds<Counter>(DataType.Counter);
const selectPricingsByIds = createSelectByIds<Pricing>(DataType.Pricing);
const selectItemCounterPricingsByIds = createSelectByIds<CounterPricing>(
  DataType.CounterPricing
);
const selectPlansByIds = createSelectByIds<Plan>(DataType.Plan);
const selectPlanTemplatesByIds = createSelectByIds<PlanTemplate>(
  DataType.PlanTemplate
);
const selectPlanGroupById = createSelectById<PlanGroup>(DataType.PlanGroup);

export const selectIsLoadingPricingData = createSelector(
  selectPlanDetailsState,
  (state) => state.isLoadingPricingData
);

export const selectIsLoadingExtraUsageEntities = createSelector(
  selectPlanDetailsState,
  (state) => state.isLoadingExtraUsageEntities
);

export const selectPlanDetailsError = createSelector(
  selectPlanDetailsState,
  (state) => state.error
);

export const selectExtraUsageEntitiesError = createSelector(
  selectPlanDetailsState,
  (state) => state.extraUsageEntitiesError
);

export const selectPricingData = createSelector(
  selectPlanDetailsState,
  (state) => state.pricingData
);

export const selectPricingDataEntities = createSelector(
  selectPricingData,
  selectAggregationsByIds,
  selectCompoundAggregationsByIds,
  selectItemCountersByIds,
  selectPricingsByIds,
  selectItemCounterPricingsByIds,
  selectPlansByIds,
  selectPlanTemplatesByIds,
  selectPlanGroupById,
  (
    pricingData,
    aggregationsSelector,
    compoundAggregationsSelector,
    itemCountersSelector,
    pricingsSelector,
    itemCounterPricingsSelector,
    plansSelector,
    planTemplatesSelector,
    planGroupSelector
  ): PlanDetailsPricingDataEntities | undefined => {
    if (!pricingData) {
      return undefined;
    }

    const aggregations = aggregationsSelector(pricingData.aggregationIds);
    const compoundAggregations = compoundAggregationsSelector(
      pricingData.compoundAggregationIds
    );
    const itemCounters = itemCountersSelector(pricingData.itemCounterIds);
    const pricings = pricingsSelector(pricingData.pricingIds);
    const itemCounterPricings = itemCounterPricingsSelector(
      pricingData.itemCounterPricingIds
    );
    const plans = plansSelector(pricingData.planIds);
    const planTemplates = planTemplatesSelector(pricingData.planTemplateIds);
    const planGroup = pricingData.planGroupId
      ? planGroupSelector(pricingData.planGroupId)
      : undefined;

    return {
      aggregations,
      compoundAggregations,
      itemCounters,
      pricings,
      itemCounterPricings,
      plans,
      planTemplates,
      planGroup,
    };
  }
);

export const selectExtraPricingAggregations = createSelector(
  selectPlanDetailsState,
  selectAggregationsByIds,
  ({ pricingData, extraPricingAggregationIds }, aggregationsSelector) => {
    if (!pricingData) {
      return [];
    }
    const extraAggregationIds = extraPricingAggregationIds.filter(
      (id) => !pricingData.aggregationIds.includes(id)
    );
    const extraAggregations = aggregationsSelector(extraAggregationIds);
    return extraAggregations;
  }
);

export const selectExtraPricingCompoundAggregations = createSelector(
  selectPlanDetailsState,
  selectCompoundAggregationsByIds,
  (
    { pricingData, extraPricingCompoundAggregationIds },
    compoundAggregationsSelector
  ) => {
    if (!pricingData) {
      return [];
    }
    const extraCompoundAggregationIds =
      extraPricingCompoundAggregationIds.filter(
        (id) => !pricingData.compoundAggregationIds.includes(id)
      );
    const extraCompoundAggregations = compoundAggregationsSelector(
      extraCompoundAggregationIds
    );
    return extraCompoundAggregations;
  }
);

export const selectExtraPricingItemCounters = createSelector(
  selectPlanDetailsState,
  selectItemCountersByIds,
  ({ pricingData, extraPricingItemCounterIds }, itemCountersSelector) => {
    if (!pricingData) {
      return [];
    }
    const extraItemCounterIds = extraPricingItemCounterIds.filter(
      (id) => !pricingData.itemCounterIds.includes(id)
    );
    const extraItemCounters = itemCountersSelector(extraItemCounterIds);
    return extraItemCounters;
  }
);

export default planDetailsSlice.reducer;
