import React, { useCallback, useMemo } from 'react';

import { DataType, IntegrationCredential } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds } from '@/types/lists';

import useDateFormatter from '@/hooks/util/useDateFormatter';
import useEntityDeleteMutation from '@/hooks/data/useEntityDeleteMutation';
import {
  ColumnDefinition,
  CrudList,
  CrudListFooter,
  CrudListHeader,
  CrudListTable,
} from '@/components/common/crud/CrudList';
import { EntityCrudActions } from '@/components/common/data/EntityCrudActions';

export const IntegrationCredentialsListRoute: React.FC = () => {
  const { t } = useTranslation();
  const { toLongDateTime } = useDateFormatter();

  const { deleteEntity } = useEntityDeleteMutation(
    DataType.IntegrationCredential
  );
  const onDeleteCredential = useCallback(
    (credential: IntegrationCredential) => {
      deleteEntity({
        entity: credential,
        pathParams: { destination: credential.destination.toLowerCase() },
      });
    },
    [deleteEntity]
  );

  const columns = useMemo<Array<ColumnDefinition<IntegrationCredential>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: 'name',
      },
      {
        id: 'destination',
        header: t('features:integrations.destination'),
        accessor: 'destination',
      },
      {
        id: 'dtCreated',
        header: t('common:createdDate'),
        accessor: (item) => toLongDateTime(item.dtCreated),
      },
      {
        id: 'delete',
        header: '',
        accessor: (item) => (
          <EntityCrudActions<IntegrationCredential>
            dataType={DataType.IntegrationCredential}
            item={item}
            onDelete={onDeleteCredential}
          />
        ),
      },
    ],
    [onDeleteCredential, t, toLongDateTime]
  );

  return (
    <CrudList<IntegrationCredential>
      listId={EntityRouteListIds.IntegrationCredential}
    >
      <CrudListHeader />
      <CrudListTable columns={columns} addActions={false} />
      <CrudListFooter />
    </CrudList>
  );
};
