import userflow from 'userflow.js';
import { call, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { signInSuccess, SignInSuccessAction } from '@/store/app/auth/auth';
import {
  selectColorMode,
  setColorMode,
  SetColorModeAction,
} from '@/store/page/page';

export function* setupUserflowSaga(
  action: SignInSuccessAction
): Generator<StrictEffect, void, any> {
  const { id } = action.payload.user;
  const colorMode = yield select(selectColorMode);
  // Only send the user ID and color mode so we don't expose any customer data to Userflow.
  yield call([userflow, userflow.identify], id, { colorMode });
}

export function* setColorModeSaga(
  action: SetColorModeAction
): Generator<StrictEffect, void, any> {
  // Update the color mode attribute if the user has already been identified.
  const isUserIdentified = yield call([userflow, userflow.isIdentified]);
  if (isUserIdentified) {
    yield call([userflow, userflow.updateUser], {
      colorMode: action.payload,
    });
  }
}

export default function* userflowSaga() {
  // On sign in success we can configure Userflow with the user details.
  yield takeEvery(signInSuccess.type, setupUserflowSaga);

  // On color mode change update the user attribute.
  yield takeEvery(setColorMode.type, setColorModeSaga);
}
