import React, {
  useCallback,
  useContext,
  useMemo,
  PropsWithChildren,
} from 'react';

import { generatePath, Params } from 'react-router-dom';
import qs from 'query-string';

import { NamedRoute } from '@/types/routes';

interface AppRoutesContextValue {
  routes: Array<NamedRoute>;
  routeExists: (name: string) => boolean;
  getPathByName: (name: string) => string | undefined;
  getLink: (
    name: string,
    pathParams?: Params,
    queryParams?: Record<string, any>
  ) => string;
}

interface AppRoutesContextProviderProps {
  routes: Array<NamedRoute>;
}

const getRoutePathsByName = (
  routes: Array<NamedRoute>,
  basePath: string
): Record<string, string> =>
  routes.reduce<Record<string, string>>((acc, route) => {
    let path = basePath;
    if (route.path) {
      // Remove trailing `/*` from route path.
      const trimmedRoutePath = route.path.endsWith('/*')
        ? route.path.slice(0, -2)
        : route.path;
      path = `${basePath}/${trimmedRoutePath}`;
    }

    if (route.name) {
      acc[route.name] = path;
    }

    if (route.children) {
      return { ...acc, ...getRoutePathsByName(route.children, path) };
    }
    return acc;
  }, {});

const AppRoutesContext = React.createContext<AppRoutesContextValue | null>(
  null
);

export const AppRoutesContextProvider: React.FC<
  PropsWithChildren<AppRoutesContextProviderProps>
> = ({ routes, children }) => {
  // Build a map from name to path so we don't have to traverse the tree each
  // time we want a path for a name.
  const routePathsByName = useMemo(
    () => getRoutePathsByName(routes, ''),
    [routes]
  );

  // Returns a path for a named route or undefined if it doesn't exist.
  const getPathByName = useCallback(
    (name: string): string | undefined => routePathsByName[name],
    [routePathsByName]
  );

  // Returns whether or not a named route exists.
  const routeExists = useCallback(
    (name: string): boolean => !!getPathByName(name),
    [getPathByName]
  );

  // Builds a link to a named route with optional path and query params.
  const getLink = useCallback(
    (
      name: string,
      pathParams: Params = {},
      queryParams: Record<string, any> = {}
    ): string => {
      const path = getPathByName(name);
      const queryString = qs.stringify(queryParams);

      if (!path) {
        throw new Error(`Unable to find path for route ${name}`);
      }

      return `${generatePath(path, pathParams)}${
        queryString && `?${queryString}`
      }`;
    },
    [getPathByName]
  );

  const value = useMemo<AppRoutesContextValue>(
    () => ({
      routes,
      routeExists,
      getPathByName,
      getLink,
    }),
    [routes, routeExists, getPathByName, getLink]
  );

  return (
    <AppRoutesContext.Provider value={value}>
      {children}
    </AppRoutesContext.Provider>
  );
};

export const useAppRoutesContext = () =>
  useContext(AppRoutesContext) as AppRoutesContextValue;
