import React, { useCallback } from 'react';

import { HStack, Radio, RadioGroup, VStack } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { CreatableMultiSelect } from '@m3ter-com/ui-components';

export interface FilterFieldProps {
  name: string;
  selectAllValue?: string;
}

interface FilterFieldInnerProps {
  selectAllValue: string;
  value: Array<string>;
  onChange: (value: Array<string>) => void;
}

enum FilterType {
  NoFilter = 'nofilter',
  Values = 'values',
}

const emptyArray: Array<never> = [];

const FilterFieldInner: React.FC<FilterFieldInnerProps> = ({
  selectAllValue,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const filterType =
    value[0] === selectAllValue ? FilterType.NoFilter : FilterType.Values;

  const handleRadioChange = useCallback(
    (newOption: FilterType) => {
      if (newOption === FilterType.NoFilter) {
        onChange([selectAllValue]);
      } else {
        onChange(emptyArray);
      }
    },
    [onChange, selectAllValue]
  );

  return (
    <VStack alignItems="stretch">
      <RadioGroup value={filterType} onChange={handleRadioChange}>
        <HStack>
          <Radio value={FilterType.NoFilter}>
            {t('features:statements.noFilter')}
          </Radio>
          <Radio value={FilterType.Values}>
            {t('features:statements.selectedValues')}
          </Radio>
        </HStack>
      </RadioGroup>
      <CreatableMultiSelect
        isDisabled={filterType === FilterType.NoFilter}
        options={emptyArray}
        value={filterType === FilterType.NoFilter ? emptyArray : value}
        placeholder={t('features:statements.addValues')}
        onChange={onChange}
      />
    </VStack>
  );
};

export const FilterField: React.FC<FilterFieldProps> = ({
  name,
  selectAllValue = '*',
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value = [], onChange } }) => (
        <FilterFieldInner
          value={value}
          onChange={onChange}
          selectAllValue={selectAllValue}
        />
      )}
    />
  );
};
