import { createSelector } from '@reduxjs/toolkit';

import { ServiceUser, ServiceUserCredential } from '@m3ter-com/m3ter-api';

import createFormSlice, {
  CreateAction,
  FormState,
} from '@/store/forms/createFormSlice';
import { ServiceUserCreateFormValues } from '@/components/features/access/service-user/ServiceUserCreateForm';

export interface ServiceUserCreateState extends FormState {
  credential?: ServiceUserCredential;
}

export type CreateServiceUserAction = CreateAction<ServiceUserCreateFormValues>;

const serviceUserCreateSlice = createFormSlice<
  ServiceUser,
  ServiceUserCreateFormValues
>('features/access/serviceUserCreate');

export const {
  create: createServiceUser,
  createSuccess: createServiceUserSuccess,
  createFailure: createServiceUserFailure,
} = serviceUserCreateSlice.actions;

// Selectors

const selectServiceUserCreateState = (state: {
  features: { access: { serviceUserCreate: ServiceUserCreateState } };
}): ServiceUserCreateState => state.features.access.serviceUserCreate;

export const selectIsSaving = createSelector(
  selectServiceUserCreateState,
  (state) => state.isSaving
);

export const selectError = createSelector(
  selectServiceUserCreateState,
  (state) => state.error
);

export default serviceUserCreateSlice.reducer;
