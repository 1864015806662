import { DateTimeISOString, Entity } from './common';

export enum UploadJobStatus {
  NotUploaded = 'notUploaded',
  Running = 'running',
  Failed = 'failed',
  Succeeded = 'succeeded',
}

export interface UploadJob extends Entity {
  contentLength: number;
  fileName: string;
  status: UploadJobStatus;
  uploadDate: DateTimeISOString;
  failedRows?: number;
  processedRows?: number;
  totalRows?: number;
}
