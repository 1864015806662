import { useMemo } from 'react';

import sortBy from 'lodash/sortBy';

import { DataType, DataTypeToEntity, QueryParams } from '@m3ter-com/m3ter-api';
import { SelectOption } from '@m3ter-com/ui-components';

import { EntityOptionsBaseProps } from '@/types/entity-options';

import { buildSelectOptions } from '@/util/data';
import { dataTypeListAllQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useAppQuery from '@/hooks/data/useAppQuery';

export interface UseEntityOptionsProps<DT extends DataType>
  extends EntityOptionsBaseProps<DT> {
  filter?: (entity: DataTypeToEntity[DT]) => boolean;
  isDisabled?: boolean;
  params?: QueryParams;
}

const defaultFilter = () => true;

const useEntityOptions = <DT extends DataType>({
  dataType,
  accessor,
  detailAccessor,
  params,
  filter = defaultFilter,
  optionValueAccessor = 'id',
  isDisabled = false,
}: UseEntityOptionsProps<DT>) => {
  const pathParams = useOrgPathParams();
  const { isLoading, error, data } = useAppQuery(
    dataTypeListAllQuery(
      {
        dataType,
        pathParams,
        queryParams: params,
      },
      { enabled: !isDisabled }
    )
  );

  const filteredData = useMemo(
    () => (data ? data.filter(filter) : []),
    [data, filter]
  );

  const options = useMemo<Array<SelectOption>>(
    () =>
      buildSelectOptions(
        sortBy(filteredData, accessor),
        optionValueAccessor,
        accessor,
        detailAccessor
      ),
    [filteredData, accessor, optionValueAccessor, detailAccessor]
  );

  return {
    isLoading,
    error,
    options,
    data: filteredData,
  };
};

export default useEntityOptions;
