import {
  call,
  put,
  select,
  StrictEffect,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { addMonths, startOfMonth } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import { DataType } from '@m3ter-com/m3ter-api';
import {
  copySystemDateInTimeZone,
  shortDateFormatKey,
} from '@m3ter-com/console-core/utils';

import { EntityRouteListIds } from '@/types/lists';

import { extractError } from '@/util/error';
import { performDataAction } from '@/services/api';
import { refreshList } from '@/store/crud';
import {
  selectOrgTimezone,
  selectCurrentOrgId,
} from '@/store/app/bootstrap/bootstrap';

import {
  approveAllBills,
  approveAllBillsFailure,
  approveAllBillsSuccess,
  loadInitialData,
  ApproveAllBillsAction,
  setSelectedDates,
  selectSelectedStartDate,
  selectSelectedEndDate,
} from './billsList';

export function* loadInitialDataSaga(): Generator<StrictEffect, void, any> {
  // Set the default dates if there aren't already values in state.
  const selectedStartDate = yield select(selectSelectedStartDate);
  const selectedEndDate = yield select(selectSelectedEndDate);

  if (!selectedStartDate || !selectedEndDate) {
    const orgTimeZone = yield select(selectOrgTimezone);

    const now = Date.now();
    const startOfThisMonth = copySystemDateInTimeZone(
      startOfMonth(now),
      orgTimeZone
    );
    const startOfNextMonth = copySystemDateInTimeZone(
      addMonths(startOfMonth(now), 1),
      orgTimeZone
    );

    yield put(
      setSelectedDates(
        startOfThisMonth.toISOString(),
        startOfNextMonth.toISOString()
      )
    );
  }
}

export function* approveAllBillsSaga(
  action: ApproveAllBillsAction
): Generator<StrictEffect, void, any> {
  try {
    const { externalInvoiceDateEnd, externalInvoiceDateStart } = action.payload;
    const organizationId = yield select(selectCurrentOrgId);
    const orgTimeZone = yield select(selectOrgTimezone);

    yield call(
      performDataAction,
      DataType.Bill,
      'approveAllBills',
      { organizationId },
      {
        externalInvoiceDateStart: formatInTimeZone(
          externalInvoiceDateStart,
          orgTimeZone,
          shortDateFormatKey
        ),
        externalInvoiceDateEnd: formatInTimeZone(
          externalInvoiceDateEnd,
          orgTimeZone,
          shortDateFormatKey
        ),
      }
    );
    yield put(approveAllBillsSuccess());
    yield put(refreshList(DataType.Bill, EntityRouteListIds.Bill));
  } catch (error) {
    yield put(approveAllBillsFailure(extractError(error)));
  }
}

export default function* billsListSaga() {
  yield takeEvery(approveAllBills.type, approveAllBillsSaga);
  yield takeLatest(loadInitialData.type, loadInitialDataSaga);
}
