import React, { ComponentProps } from 'react';

import { forwardRef } from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Confirmation, IconButton } from '@m3ter-com/ui-components';

export type EntityDeleteButtonProps = Omit<
  ComponentProps<typeof IconButton>,
  'aria-label' | 'icon'
> & {
  onConfirm: () => void;
};

export const EntityDeleteButton = forwardRef<EntityDeleteButtonProps, 'button'>(
  ({ onConfirm, ...buttonProps }, ref) => {
    const { t } = useTranslation();

    return (
      <Confirmation
        onConfirm={onConfirm}
        trigger={
          <IconButton
            ref={ref}
            aria-label={t('common:delete')}
            size="sm"
            icon={<FaTrash />}
            {...buttonProps}
          />
        }
      />
    );
  }
);
