import {
  ComponentSingleStyleConfig,
  ComponentMultiStyleConfig,
} from '@chakra-ui/react';

export const step: ComponentMultiStyleConfig = {
  parts: ['wrapper', 'number', 'content'],
  baseStyle: {
    wrapper: {
      '&:not(:last-of-type) .content': {
        borderLeftWidth: 1,
      },
    },
    number: {
      w: 8,
      h: 8,
      minW: 8,
      mr: 2,
      p: 0,
      borderWidth: 1,
      fontWeight: 'bold',
    },
    content: {
      ml: 4,
      my: 2,
      pl: 6,
      py: 2,
    },
  },
  variants: {
    active: {
      number: {
        bg: 'green-bright',
        color: 'white',
        borderWidth: 0,
      },
    },
    completed: {
      number: {
        bg: 'transparent',
        borderColor: 'green-bright',
        color: 'green-bright',
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
};

const steps: ComponentSingleStyleConfig = {
  baseStyle: {},
};

export default steps;
