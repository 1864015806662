import React, { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { NotificationRule } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds } from '@/types/lists';

import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';
import { ActiveStatusBadge } from '@/components/common/data/ActiveStatusBadge';

const searchFields = ['code', 'name'];

export const NotificationRulesListRoute: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams();

  const queryParams = useMemo(
    () => ({
      eventName: params.eventName,
    }),
    [params]
  );

  const columns = useMemo<Array<ColumnDefinition<NotificationRule>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: 'name',
      },
      {
        id: 'status',
        header: t('forms:labels.status'),
        accessor: (item) => (
          <ActiveStatusBadge
            active={item.active}
            inactiveLabel={t('common:inactive')}
            ml={0}
          />
        ),
      },
      {
        id: 'code',
        header: t('forms:labels.code'),
        accessor: 'code',
      },
      {
        id: 'event',
        header: t('forms:labels.event'),
        accessor: 'eventName',
      },
    ],
    [t]
  );

  return (
    <CrudList<NotificationRule>
      listId={EntityRouteListIds.NotificationRules}
      searchFields={searchFields}
      columns={columns}
      params={queryParams}
    />
  );
};
