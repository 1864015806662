import React, { useMemo } from 'react';

import { Box, Grid } from '@chakra-ui/react';

import {
  Plan,
  PlanTemplate,
  Pricing,
  SegmentedAggregation,
  SegmentedCompoundAggregation,
} from '@m3ter-com/m3ter-api';
import { PropsWithOneOf } from '@m3ter-com/console-core/types';
import { HorizontalScrollBox, Pagination } from '@m3ter-com/ui-components';

import { uniq } from '@/util/array';
import usePricingSegmentsEditor from '@/hooks/features/pricing/usePricingSegmentsEditor';
import { PricingGridContextProvider } from '@/components/features/pricing/grid/PricingGridContext';
import { PricingGridRowContextProvider } from '@/components/features/pricing/grid/PricingGridRowContext';

import { SegmentedPricingGridHeaders } from './SegmentedPricingGridHeaders';
import { SegmentedPricingGridRow } from './SegmentedPricingGridRow';

export interface SegmentedPricingGridCommonProps {
  planPricings: Array<Pricing>;
  planTemplatePricings: Array<Pricing>;
  planTemplate: PlanTemplate;
  plan?: Plan;
}

export type SegmentedPricingGridProps = PropsWithOneOf<
  SegmentedPricingGridCommonProps,
  {
    aggregation: SegmentedAggregation;
    compoundAggregation: SegmentedCompoundAggregation;
  }
>;

export const SegmentedPricingGrid: React.FC<SegmentedPricingGridProps> = ({
  aggregation,
  compoundAggregation,
  planPricings,
  planTemplatePricings,
  planTemplate,
  plan,
}) => {
  const segments = useMemo(() => {
    // We can non-null assert here because we have to be passed one or the other.
    return aggregation ? aggregation.segments : compoundAggregation!.segments;
  }, [aggregation, compoundAggregation]);

  const segmentedFields = useMemo(() => {
    // Aggregations have `segmentedFields` but compound aggregations do not so
    // we need to work it out from the `segments`.
    if (aggregation) {
      return aggregation.segmentedFields;
    }
    // We can non-null assert here because we have to be passed one or the other.
    // Get a unique array of keys from all the segment objects.
    return uniq(
      compoundAggregation!.segments.reduce((acc, segment) => {
        acc.push(...Object.keys(segment));
        return acc;
      }, new Array<string>())
    );
  }, [aggregation, compoundAggregation]);

  const {
    currentPage,
    filterState,
    pageCount,
    pageData,
    segmentedFieldValues,
    sortingState,
    onNextPage,
    onFieldFilterSelect,
    onPageChange,
    onSortButtonClick,
  } = usePricingSegmentsEditor(
    segmentedFields,
    segments,
    planPricings,
    planTemplatePricings
  );

  return (
    <Box w="100%">
      <HorizontalScrollBox my={8}>
        <PricingGridContextProvider canEditPricing canEditTemplatePricing>
          <Grid
            width="max-content"
            borderWidth="2px 0 0 2px"
            templateColumns={`repeat(${segmentedFields.length + 1}, 1fr)`}
            templateRows={`repeat(${segments.length + 1}, max-content)`}
          >
            <SegmentedPricingGridHeaders
              segmentedFields={segmentedFields}
              filterState={filterState}
              segmentedFieldValues={segmentedFieldValues}
              sortingState={sortingState}
              onFieldFilterSelect={onFieldFilterSelect}
              onSortButtonClick={onSortButtonClick}
            />
            {/* Use a single row context provider because we have the same (compound) aggregation across all rows */}
            <PricingGridRowContextProvider
              aggregation={aggregation}
              compoundAggregation={compoundAggregation}
            >
              {pageData.map((rowData) => (
                <SegmentedPricingGridRow
                  key={JSON.stringify(rowData.segment)}
                  segmentedFields={segmentedFields}
                  rowData={rowData}
                  plan={plan}
                  planTemplate={planTemplate}
                />
              ))}
            </PricingGridRowContextProvider>
          </Grid>
        </PricingGridContextProvider>
      </HorizontalScrollBox>
      <Pagination
        currentPage={currentPage}
        hasMore={currentPage < pageCount}
        pageCount={pageCount}
        onChange={onPageChange}
        onNext={onNextPage}
      />
    </Box>
  );
};
