import React, { ComponentProps } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { Combobox } from '@m3ter-com/ui-components';

export interface FormComboboxProps
  extends Omit<ComponentProps<typeof Combobox>, 'value' | 'onChange'> {
  name: string;
}

export const FormCombobox: React.FC<FormComboboxProps> = ({
  name,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Combobox value={value} onChange={onChange} {...props} />
      )}
    />
  );
};
