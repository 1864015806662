import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';

import { DataType, Id } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { NotificationDefinition } from '@/store/store';
import {
  endBillingEntities,
  selectError,
  selectIsSubmitting,
} from '@/store/features/accounts/endBillingEntities';
import { EndBillingEntitiesFormValues } from '@/components/features/accounts/EndBillingEntitiesModal';

const useEndBillingEntities = (
  id: Id,
  dataType: DataType.Account | DataType.Contract
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    isOpen: isEndBillingEntitiesModalOpen,
    onOpen: onOpenEndBillingEntitiesModal,
    onClose: onCloseEndBillingEntitiesModal,
  } = useDisclosure();

  const endBillingEntitiesFailureNotification: NotificationDefinition = useMemo(
    () => ({
      type: 'error',
      message: t('notifications:endBillingEntitiesFailureNotification', {
        entityName: t(`common:${dataType}`),
      }),
      removeAfter: 5000,
    }),
    [dataType, t]
  );

  const isSubmitting = useSelector(selectIsSubmitting);
  const submissionError = useSelector(selectError);

  const onEndBillingEntities = useCallback(
    (data: EndBillingEntitiesFormValues) => {
      dispatch(
        endBillingEntities(
          id,
          dataType,
          data.endDate,
          data.billingEntities,
          data.applyToChildren,
          endBillingEntitiesFailureNotification
        )
      );
      onCloseEndBillingEntitiesModal();
    },
    [
      dataType,
      dispatch,
      endBillingEntitiesFailureNotification,
      id,
      onCloseEndBillingEntitiesModal,
    ]
  );

  return {
    isSubmitting,
    submissionError,
    onEndBillingEntities,
    isEndBillingEntitiesModalOpen,
    onOpenEndBillingEntitiesModal,
    onCloseEndBillingEntitiesModal,
  };
};

export default useEndBillingEntities;
