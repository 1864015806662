import React from 'react';

import { Plan } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { PlanForm } from '@/components/features/pricing/PlanForm';

export const PlansEditRoute: React.FC = () => (
  <CrudEdit<Plan> form={PlanForm} />
);
