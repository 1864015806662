export const findAllByKey = <V = any>(
  record: Record<string, any>,
  matchingKey: string
): Array<V> => {
  return Object.entries(record).reduce((result, [key, value]) => {
    if (key === matchingKey) {
      result.push(value);
    }

    if (typeof value === 'object') {
      const nestedValues = findAllByKey(value, matchingKey);
      result.push(...nestedValues);
    }

    return result;
  }, new Array<V>());
};
