import React, { ReactNode, useMemo } from 'react';

import { Table, TableProps, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';

import { PricingBand } from '@m3ter-com/m3ter-api';
import {
  getBandPriceDescription,
  getBandRangeDescription,
  getTransactionBandPriceDescription,
} from '@m3ter-com/console-core/utils';

import useCurrencies from '@/hooks/util/useCurrencies';

export interface PricingBandsTableProps extends TableProps {
  currencyCode: string;
  pricingBands: Array<PricingBand>;
  heading?: ReactNode;
  isTransactional?: boolean;
}

export const PricingBandsTable: React.FC<PricingBandsTableProps> = ({
  currencyCode,
  heading,
  pricingBands,
  isTransactional = false,
  ...tableProps
}) => {
  const { formatCurrency } = useCurrencies();

  const sortedBands = useMemo(
    () =>
      [...pricingBands].sort(
        (bandA, bandB) => bandA.lowerLimit - bandB.lowerLimit
      ),
    [pricingBands]
  );

  return (
    <Table {...tableProps}>
      {heading && (
        <Thead>
          <Tr>
            <Th colSpan={2} paddingX={0}>
              {heading}
            </Th>
          </Tr>
        </Thead>
      )}
      <Tbody>
        {sortedBands.map((pricingBand, index) => {
          const nextPricingBand: PricingBand | undefined =
            sortedBands[index + 1];
          const rangeText = getBandRangeDescription(
            pricingBand,
            nextPricingBand
          );
          const priceText = isTransactional
            ? getTransactionBandPriceDescription(
                pricingBand,
                currencyCode,
                formatCurrency
              )
            : getBandPriceDescription(
                pricingBand,
                currencyCode,
                formatCurrency
              );

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Tr key={index}>
              <Td textAlign="left" paddingLeft={0}>
                {rangeText}
              </Td>
              <Td textAlign="right" paddingRight={0}>
                {priceText}
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
