import React, { ReactNode } from 'react';

import {
  Button,
  ButtonGroup,
  ButtonProps,
  Placement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';

export interface ConfirmationProps {
  trigger: ReactNode;
  message?: string;
  cancelButtonProps?: ButtonProps;
  confirmButtonProps?: ButtonProps;
  cancelText?: string;
  confirmText?: string;
  placement?: Placement;
  onConfirm: () => void;
}

export const Confirmation: React.FC<ConfirmationProps> = ({
  trigger,
  onConfirm,
  message = 'Are you sure?',
  confirmText = 'Yes',
  cancelText = 'No',
  confirmButtonProps,
  cancelButtonProps,
  placement = 'top-start',
}) => {
  const initialFocusRef = React.useRef(null);

  return (
    <Popover placement={placement} initialFocusRef={initialFocusRef}>
      {({ onClose }) => (
        <React.Fragment>
          <PopoverTrigger>{trigger}</PopoverTrigger>
          <PopoverContent data-testid="dialog">
            <PopoverArrow />
            <PopoverHeader textAlign="left">{message}</PopoverHeader>
            <PopoverBody textAlign="left">
              <ButtonGroup>
                <Button
                  ref={initialFocusRef}
                  onClick={(event) => {
                    event.stopPropagation();
                    onClose();
                  }}
                  data-testid="cancel"
                  {...cancelButtonProps}
                >
                  {cancelText}
                </Button>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    onConfirm();
                    onClose();
                  }}
                  data-testid="confirm"
                  colorScheme="red"
                  {...confirmButtonProps}
                >
                  {confirmText}
                </Button>
              </ButtonGroup>
            </PopoverBody>
          </PopoverContent>
        </React.Fragment>
      )}
    </Popover>
  );
};
