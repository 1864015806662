import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  selectIsLoading,
  selectError,
  selectPlanTemplates,
  loadPlanTemplates,
  reset,
  selectIsLoaded,
} from '@/store/features/pricing/planTemplates';

const usePlanTemplates = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadPlanTemplates());

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const isLoading = useSelector(selectIsLoading);
  const isLoaded = useSelector(selectIsLoaded);
  const error = useSelector(selectError);
  const planTemplates = useSelector(selectPlanTemplates);

  return {
    error,
    isLoaded,
    isLoading,
    planTemplates,
  };
};

export default usePlanTemplates;
