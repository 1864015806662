import { lazy } from 'react';

import {
  LazyWidgetContentComponent,
  RenderableWidgetDefinition,
  StoredWidgetDefinition,
  WidgetType,
} from '@/types/widgets';

import { LazyLoadFailureWidget } from '@/components/widgets/LazyLoadFailureWidget/LazyLoadFailureWidget';

export class WidgetBank {
  private widgetDefinitions = new Map<WidgetType, StoredWidgetDefinition>();

  public getDefinition(type: WidgetType): RenderableWidgetDefinition {
    const storedDefinition = this.widgetDefinitions.get(type);
    if (!storedDefinition) {
      return {
        contentComponent: LazyLoadFailureWidget as LazyWidgetContentComponent,
        configFieldSchemas: [],
        initialConfigData: {},
        type,
      };
    }

    return {
      type,
      configFieldSchemas: storedDefinition.configFieldSchemas,
      contentComponent: lazy(() =>
        storedDefinition
          .contentComponentLoader()
          .catch(() => ({ default: LazyLoadFailureWidget }))
      ),
      initialConfigData: storedDefinition.initialConfigData,
    };
  }

  public getRegisteredWidgetTypes(): Array<WidgetType> {
    return Array.from(this.widgetDefinitions.keys());
  }

  public registerWidget(definition: StoredWidgetDefinition): void {
    if (this.widgetDefinitions.has(definition.type)) {
      throw new Error(`Widget already registered for type ${definition.type}`);
    }
    this.widgetDefinitions.set(definition.type, definition);
  }
}

export default new WidgetBank();
