import { ComponentSingleStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const fileInput: ComponentSingleStyleConfig = {
  baseStyle: (props) => ({
    backgroundColor: 'transparent',
    color: 'chakra-subtle-text',
    fontWeight: 'semibold',
    fontSize: 'sm',
    padding: 0,
    border: 'none',
    '::file-selector-button': {
      marginRight: 2,
      backgroundColor: mode('gray.100', 'whiteAlpha.200')(props),
      color: mode('gray.800', 'whiteAlpha.900')(props),
      fontWeight: 'normal',
      fontSize: 'md',
      padding: 2,
      border: 'none',
      outline: 'none',
      borderRadius: 'md',
      cursor: 'pointer',
      transition: 'background-color 200ms',
      ':hover': {
        backgroundColor: mode('gray.200', 'whiteAlpha.300')(props),
      },
    },
  }),
};

export default fileInput;
