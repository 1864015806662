import React, { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  ExternalMapping,
  ExternalMappingEntityType,
} from '@m3ter-com/m3ter-api';
import { DeepPartial } from '@m3ter-com/console-core/types';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudQueryParams } from '@/types/routes';

import {
  createExternalMappings,
  selectExternalMappingsCreateError,
  selectIsSavingExternalMappings,
} from '@/store/features/integrations/external-mappings/externalMappingsCreateForm';
import { useCrudContext } from '@/components/common/crud/CrudContext';
import useQueryString from '@/hooks/navigation/useQueryString';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import {
  ExternalMappingCreateForm,
  ExternalMappingCreateFormValues,
  ExternalMappingCreateMode,
} from '@/components/features/external-mappings/ExternalMappingForm';

interface ExternalMappingsCreateRouteQueryParams {
  m3terEntity?: ExternalMappingEntityType;
  m3terId?: string;
}

export const ExternalMappingsCreateRoute: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const error = useSelector(selectExternalMappingsCreateError);
  const isSaving = useSelector(selectIsSavingExternalMappings);
  const { basePath, onCancel } = useCrudContext<ExternalMapping>();
  const {
    m3terEntity,
    m3terId,
    returnPath = basePath,
  } = useQueryString<
    CrudQueryParams & ExternalMappingsCreateRouteQueryParams
  >();

  const onSave = useCallback(
    (data: ExternalMappingCreateFormValues) => {
      dispatch(createExternalMappings(data, returnPath));
    },
    [dispatch, returnPath]
  );

  const initialValues = useMemo<DeepPartial<ExternalMappingCreateFormValues>>(
    () => ({
      mappings: [
        {
          m3terId,
        },
      ],
      m3terEntity,
    }),
    [m3terEntity, m3terId]
  );

  return (
    <React.Fragment>
      <BreadcrumbItem title={t('common:create')} />
      {error && (
        <ErrorAlert
          title={t('errors:generic.problemSavingData', {
            entityName: t('common:externalMapping'),
          })}
          error={error}
          mb={4}
        />
      )}
      <ExternalMappingCreateForm
        initialValues={initialValues}
        isSaving={isSaving}
        mode={ExternalMappingCreateMode.Single}
        onCancel={onCancel}
        onSave={onSave}
      />
    </React.Fragment>
  );
};
