import React, { ComponentProps, useCallback } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { FileInput } from '@m3ter-com/ui-components';

interface Base64OutputFileInputProps
  extends Omit<ComponentProps<typeof FileInput>, 'onChange'> {
  onChange: (fileString: string) => void;
}

export interface FormFileInputProps
  extends Omit<Base64OutputFileInputProps, 'onChange'> {
  name: string;
}

const readFileAsDataUrl = async (file: File): Promise<string> => {
  const reader = new FileReader();
  return new Promise((resolve) => {
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64data = reader.result?.toString().split(',')[1] ?? '';
      resolve(base64data);
    };
  });
};

const Base64OutputFileInput: React.FC<Base64OutputFileInputProps> = ({
  onChange,
  ...props
}) => {
  const handleChange = useCallback(
    (file: File | null) => {
      if (file) {
        readFileAsDataUrl(file).then((fileString) => {
          onChange(fileString);
        });
      } else {
        onChange('');
      }
    },
    [onChange]
  );

  return <FileInput onChange={handleChange} {...props} />;
};

export const FormFileInput: React.FC<FormFileInputProps> = ({
  name,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange },
      }: {
        field: { onChange: (fileString: string) => void };
      }) => <Base64OutputFileInput onChange={onChange} {...props} />}
    />
  );
};
