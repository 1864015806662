import React from 'react';

import { Customer } from '@m3ter-com/m3ter-api';

import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { CustomerForm } from '@/components/features/admin/customers/CustomerForm';

export const CustomersAdminCreateRoute: React.FC = () => (
  <CrudCreate<Customer> form={CustomerForm} />
);
