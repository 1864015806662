import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataType, PathParams } from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';
import type {
  CrudBaseState,
  BaseItemPayload,
  BasePayload,
  RelatedData,
} from '.';

import { CompletionMeta } from '@/store/store';

export interface ViewState extends CrudBaseState {
  isLoading: boolean;
  error?: AppError;
}

export interface LoadItemPayload extends BaseItemPayload {
  pathParams?: PathParams;
  relationships: Array<string>;
}
export type LoadItemAction = PayloadAction<LoadItemPayload>;

export type LoadItemFailureAction = PayloadAction<
  BasePayload,
  string,
  CompletionMeta,
  AppError
>;

export interface LoadItemSuccessPayload extends BaseItemPayload {
  response: any;
  relatedData?: RelatedData;
}
export type LoadItemSuccessAction = PayloadAction<LoadItemSuccessPayload>;

export interface ClearItemPayload extends BasePayload {}
export type ClearItemAction = PayloadAction<ClearItemPayload>;

const initialState: ViewState = {
  isLoading: false,
};

const viewSlice = createSlice({
  name: 'crud/view',
  initialState,
  reducers: {
    loadItem: {
      reducer: (state: ViewState, _action: LoadItemAction) => {
        state.isLoading = true;
        state.error = undefined;
      },
      prepare: (
        dataType: DataType,
        id: string,
        relationships: Array<string> = [],
        pathParams?: PathParams
      ) => ({
        payload: { dataType, id, pathParams, relationships },
      }),
    },
    loadItemSuccess: {
      reducer: (state: ViewState, action: LoadItemSuccessAction) => {
        const { id, relatedData } = action.payload;
        state.relatedData = relatedData;
        state.id = id;
        state.isLoading = false;
      },
      prepare: (
        dataType: DataType,
        id: string,
        response: any,
        relatedData: RelatedData
      ) => ({
        payload: { dataType, id, response, relatedData },
      }),
    },
    loadItemFailure: {
      reducer: (state: ViewState, action: LoadItemFailureAction) => {
        state.isLoading = false;
        state.error = action.error;
      },
      prepare: (dataType: DataType, error: AppError, meta?: any) => ({
        payload: { dataType },
        error,
        meta,
      }),
    },
    clearItem: {
      reducer: (state: ViewState, _action: ClearItemAction) => {
        state.id = undefined;
      },
      prepare: (dataType: DataType) => ({
        payload: { dataType },
      }),
    },
  },
});

export const { loadItem, loadItemSuccess, loadItemFailure, clearItem } =
  viewSlice.actions;

export default viewSlice.reducer;
