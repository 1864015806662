import React, { ComponentProps, ReactElement } from 'react';

import { DataType } from '@m3ter-com/m3ter-api';
import { MultiSelect } from '@m3ter-com/ui-components';

import useEntityOptions, {
  UseEntityOptionsProps,
} from '@/hooks/data/useEntityOptions';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch';

export interface EntityMultiSelectProps<DT extends DataType>
  extends Omit<ComponentProps<typeof MultiSelect>, 'options'>,
    UseEntityOptionsProps<DT> {}

export function EntityMultiSelect<DT extends DataType>({
  dataType,
  accessor,
  detailAccessor,
  params,
  filter,
  optionValueAccessor,
  isDisabled,
  ...selectProps
}: EntityMultiSelectProps<DT>): ReactElement<any, any> {
  const { isLoading, error, options } = useEntityOptions({
    dataType,
    accessor,
    detailAccessor,
    params,
    filter,
    optionValueAccessor,
    isDisabled,
  });

  return (
    <LoadingErrorContentSwitch isLoading={isLoading} error={error}>
      <MultiSelect
        isSearchable
        isDisabled={isDisabled}
        searchMatchFrom="any"
        options={options}
        {...selectProps}
      />
    </LoadingErrorContentSwitch>
  );
}
