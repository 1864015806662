import omit from 'lodash/omit';

import { Plan, UnsavedEntity } from '@m3ter-com/m3ter-api';

const FIXED_CUSTOM_FIELD = '_tr_fixedAggId';
const PERCENT_CUSTOM_FIELD = '_tr_percentAggId';

export const beforeCreateOrUpdatePlan = (
  plan: UnsavedEntity<Plan>
): UnsavedEntity<Plan> => {
  // Move values to the custom fields.
  const {
    transactionFixedAggregationId,
    transactionPercentAggregationId,
    customFields,
    ...rest
  } = plan;

  if (transactionFixedAggregationId && transactionPercentAggregationId) {
    return {
      ...rest,
      customFields: {
        ...customFields,
        [FIXED_CUSTOM_FIELD]: transactionFixedAggregationId,
        [PERCENT_CUSTOM_FIELD]: transactionPercentAggregationId,
      },
    };
  }

  return plan;
};

export const afterRetrievePlan = (plan: Plan): Plan => {
  // Move custom fields back to properties on the plan.
  const { customFields } = plan;
  if (
    customFields &&
    customFields[FIXED_CUSTOM_FIELD] &&
    customFields[PERCENT_CUSTOM_FIELD]
  ) {
    return {
      ...plan,
      transactionFixedAggregationId: customFields[FIXED_CUSTOM_FIELD] as string,
      transactionPercentAggregationId: customFields[
        PERCENT_CUSTOM_FIELD
      ] as string,
      customFields: omit(customFields, [
        FIXED_CUSTOM_FIELD,
        PERCENT_CUSTOM_FIELD,
      ]),
    };
  }

  return plan;
};

export const afterListPlans = (plans: Array<Plan>): Array<Plan> => {
  return plans.map(afterRetrievePlan);
};
