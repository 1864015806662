import React from 'react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert } from '@m3ter-com/ui-components';

export interface ItemCounterPricingListAlertProps {
  isPricingEditorAlert?: boolean;
}

export const ItemCounterPricingListAlert: React.FC<
  ItemCounterPricingListAlertProps
> = ({ isPricingEditorAlert = false }) => {
  const { t } = useTranslation();

  return (
    <Alert mb={4} w="100%" status="info">
      {t(
        `features:pricing.${
          isPricingEditorAlert
            ? 'countersEditorListSelectInfo'
            : 'countersListSelectInfo'
        }`
      )}
    </Alert>
  );
};
