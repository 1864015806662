import React from 'react';

import { Checkbox, Radio } from '@chakra-ui/react';

import { SelectionControlProps, SelectionType } from './types';

export interface DataTableSelectionControlProps extends SelectionControlProps {
  selectionType: SelectionType;
}

export const DataTableSelectionControl: React.FC<
  DataTableSelectionControlProps
> = ({ selectionType, ...controlProps }) =>
  selectionType === 'multi' ? (
    <Checkbox {...controlProps} />
  ) : (
    <Radio {...controlProps} />
  );
