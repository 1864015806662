import React from 'react';

import { DataType, Bill } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { BillsRoute } from '@/components/routes/billing/BillsRoute';
import { BillDetailsRoute } from '@/components/routes/billing/BillDetailsRoute';

import setupCreditLineItemsRoute from './credit-line-items';
import setupDebitLineItemsRoute from './debit-line-items';
import setupStatementDefinitionsRoute from './statement-definitions';

const LazyBillHistoryRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BillHistoryRoute" */ '@/components/routes/billing/BillHistoryRoute'
  ).then((module) => ({ default: module.BillHistoryRoute }))
);

const LazyBillsList = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BillsList" */ '@/components/features/billing/BillsList'
  ).then((module) => ({ default: module.BillsList }))
);

export default (): NamedRoute => ({
  path: 'billing',
  children: [
    {
      path: 'history',
      name: 'billing.history',
      element: <LazyBillHistoryRoute />,
    },
    createCrudRoute<Bill>({
      path: 'management',
      dataType: DataType.Bill,
      element: <BillsRoute />,
      list: <LazyBillsList />,
      details: <BillDetailsRoute />,
      entityChildRoutes: [
        setupCreditLineItemsRoute(),
        setupDebitLineItemsRoute(),
      ],
    }),
    setupStatementDefinitionsRoute(),
  ],
});
