import React from 'react';

import { chakra, useMultiStyleConfig } from '@chakra-ui/react';

import { Notification, NotificationProps } from '../Notification';

export interface NotificationsListProps {
  notifications: Array<NotificationProps>;
  onClose: (id: string) => void;
}

export const NotificationsList: React.FC<NotificationsListProps> = ({
  notifications,
  onClose,
}) => {
  const styles = useMultiStyleConfig('NotificationsList', {});

  return (
    <chakra.ul sx={styles.list}>
      {notifications.map((notification) => (
        <chakra.li key={notification.id} sx={styles.item}>
          <Notification onClose={onClose} {...notification} />
        </chakra.li>
      ))}
    </chakra.ul>
  );
};
