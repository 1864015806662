import { Option, OptionGroup } from './types';

export const isOptionNotOptionGroup = <T extends string = string>(
  optionOrGroup: Option<T> | OptionGroup<T>
): optionOrGroup is Option<T> =>
  Object.prototype.hasOwnProperty.call(optionOrGroup, 'value');

// Get a string value for an option, used for filtering.
export const getStringValue = <T extends string = string>(
  option: Option<T>
): string =>
  option.secondaryLabel
    ? `${option.secondaryLabel} ${option.label}`
    : option.label;

// Prevent the enter key submitting forms when using dropdowns
export const onKeyDown = (
  event: React.KeyboardEvent<HTMLElement>,
  isMenuOpen: boolean = false
) => {
  if (event.key === 'Enter' && !isMenuOpen) {
    event.preventDefault();
  }
};
