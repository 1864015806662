import { useCallback, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  signIn,
  signOut,
  forgotPassword,
  forgotPasswordSubmit,
  completeNewPassword,
  reset,
  selectAuthError,
  selectIsAuthenticated,
  selectIsLoading,
  selectIsM3terAdmin,
  selectIsRestoringSession,
  selectIsSigningOut,
  selectUser,
} from '@/store/app/auth/auth';

const useAuth = (resetOnUnmount = false) => {
  const user = useSelector(selectUser);

  const authError = useSelector(selectAuthError);

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isLoading = useSelector(selectIsLoading);
  const isM3terAdmin = useSelector(selectIsM3terAdmin);
  const isRestoringSession = useSelector(selectIsRestoringSession);
  const isSigningOut = useSelector(selectIsSigningOut);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      if (resetOnUnmount) {
        dispatch(reset());
      }
    };
  }, [dispatch, resetOnUnmount]);

  const isAuthenticatedUser = useMemo(
    () => isAuthenticated && !!user,
    [isAuthenticated, user]
  );

  const onSignOut = useCallback(() => {
    dispatch(signOut());
  }, [dispatch]);

  const onSignIn = useCallback(
    (email: string, password: string) => {
      dispatch(signIn(email, password));
    },
    [dispatch]
  );

  const onFederatedSignIn = useCallback(
    (email: string) => {
      dispatch(signIn(email));
    },
    [dispatch]
  );

  const onForgotPassword = useCallback(
    (email: string) => {
      dispatch(forgotPassword(email));
    },
    [dispatch]
  );

  const onForgotPasswordSubmit = useCallback(
    (email: string, code: string, password: string) => {
      dispatch(forgotPasswordSubmit(email, code, password));
    },
    [dispatch]
  );

  const onCompleteNewPassword = useCallback(
    (password: string, name: string) => {
      dispatch(completeNewPassword(password, name));
    },
    [dispatch]
  );

  return {
    authError,
    isAuthenticated,
    isAuthenticatedUser,
    isLoading,
    isM3terAdmin,
    isSigningOut,
    isRestoringSession,
    onCompleteNewPassword,
    onFederatedSignIn,
    onForgotPassword,
    onForgotPasswordSubmit,
    onSignIn,
    onSignOut,
    user,
  };
};

export default useAuth;
