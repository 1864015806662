import React from 'react';

import { NamedRoute } from '@/types/routes';

import { NotFoundRoute } from '@/components/routes/NotFoundRoute';

export default (): NamedRoute => ({
  name: 'not-found',
  path: '*',
  element: <NotFoundRoute />,
});
