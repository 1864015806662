import React from 'react';

import { NotificationRule } from '@m3ter-com/m3ter-api';

import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { NotificationRuleForm } from '@/components/features/notification-rules/NotificationRuleForm';

export const NotificationRulesCreateRoute: React.FC = () => (
  <CrudCreate<NotificationRule> form={NotificationRuleForm} />
);
