import React, { useMemo } from 'react';

import { Link, Text } from '@chakra-ui/react';

import { DataType, BillLineItem, Id } from '@m3ter-com/m3ter-api';

import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';

interface BillLineItemDescriptionProps {
  lineItem: BillLineItem;
  accountId: Id;
}
/**
 * Creates details link for a line item based on a line items properties (e.g. if a
 * specifc id exists on the line item) uses the line item description for the link
 * text. Defaults to line item description with no associated link when no specific id
 * is persent on the line item.
 */
export const BillLineItemDescription: React.FC<
  BillLineItemDescriptionProps
> = ({ accountId, lineItem }) => {
  const params = useMemo(() => ({ accountId }), [accountId]);

  let decription: JSX.Element = <Text>{lineItem.description}</Text>;

  if (lineItem.planId) {
    decription = (
      <Link as={CrudDetailsLink} dataType={DataType.Plan} id={lineItem.planId}>
        {lineItem.description}
      </Link>
    );
  }

  if (lineItem.planGroupId) {
    decription = (
      <Link
        as={CrudDetailsLink}
        dataType={DataType.PlanGroup}
        id={lineItem.planGroupId}
      >
        {lineItem.description}
      </Link>
    );
  }

  if (lineItem.commitmentId) {
    decription = (
      <Link
        as={CrudDetailsLink}
        dataType={DataType.Commitment}
        id={lineItem.commitmentId}
        params={params}
      >
        {lineItem.description}
      </Link>
    );
  }

  return decription;
};
