import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { CompoundAggregationsRoute } from '@/components/routes/compound-aggregations/CompoundAggregationsRoute';
import { CompoundAggregationsDetailsRoute } from '@/components/routes/compound-aggregations/CompoundAggregationsDetailsRoute';
import { CompoundAggregationsCreateRoute } from '@/components/routes/compound-aggregations/CompoundAggregationsCreateRoute';
import { CompoundAggregationsListRoute } from '@/components/routes/compound-aggregations/CompoundAggregationsListRoute';
import { CompoundAggregationsEditRoute } from '@/components/routes/compound-aggregations/CompoundAggregationsEditRoute';

export default () =>
  createCrudRoute({
    path: 'compound-aggregations',
    dataType: DataType.CompoundAggregation,
    element: <CompoundAggregationsRoute />,
    details: <CompoundAggregationsDetailsRoute />,
    list: <CompoundAggregationsListRoute />,
    create: <CompoundAggregationsCreateRoute />,
    edit: <CompoundAggregationsEditRoute />,
  });
