import React from 'react';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Spacer,
} from '@chakra-ui/react';

import { Button } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';

const LazyDashboard = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "Dashboard" */ '@/components/features/dashboard/Dashboard'
  ).then((module) => ({ default: module.Dashboard }))
);

export const OrganizationIndexRoute: React.FC = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <BreadcrumbItem title={t('common:dashboard')} />
      <Alert mb={2}>
        <AlertIcon />
        <Box>
          <AlertTitle>{t('features:preview.previewBannerTitle')}</AlertTitle>
          <AlertDescription>
            {t('features:preview.previewBannerDescription')}
          </AlertDescription>
        </Box>
        <Spacer />
        <Button
          size="sm"
          colorScheme="blue"
          ml={4}
          flexShrink={0}
          as="a"
          href="https://preview-ux.console.m3ter.com"
        >
          {t('features:preview.exploreNewConsole')}
        </Button>
      </Alert>
      <LazyDashboard />
    </React.Fragment>
  );
};
