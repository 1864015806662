import i18next from 'i18next';

import { AppError } from '@/types/errors';

export const extractErrorMessage = (error: any): string =>
  typeof error === 'string'
    ? error
    : error?.response?.data?.message ?? error?.json?.message ?? error?.message;

// Extracts a code and message from various errors that arise
// from API requests:
//
// - String errors (the Amplify Auth class throws them for some reason)
// - Standard JS Error objects
// - Wretch errors (from m3ter API client), which contain the server response.
// - Axios errors (from Amplify REST api), which contain a response object with details
export const extractError = (error: any): AppError => ({
  code: error?.response?.status,
  message: extractErrorMessage(error),
});

export class AnalyticsJobError extends Error {
  public isSizeLimitError: boolean;

  constructor(error: any, public analyticsJobId?: string) {
    const { code, message } = extractError(error);
    super(message);
    this.isSizeLimitError = code === 413;
  }
}

export const extractAnalyticsJobError = (error: any) => {
  let analyticsJobId: string | undefined;
  let canLoadExportUrl = false;
  let errorMessage: string;
  if (
    error instanceof AnalyticsJobError &&
    error.analyticsJobId &&
    error.isSizeLimitError
  ) {
    // A 413 response is returned when the results payload would be too large.
    // In this case, the analytics job API can still generate a CSV.
    analyticsJobId = error.analyticsJobId;
    canLoadExportUrl = true;
    errorMessage = i18next.t('features:analytics.analyticsJobDataLimitError');
  } else {
    errorMessage = extractError(error).message;
  }

  return {
    analyticsJobId,
    canLoadExportUrl,
    errorMessage,
  };
};
