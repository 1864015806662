import { StrictEffect } from 'redux-saga/effects';

import {
  DataType,
  DataTypeToEntity,
  Entity,
  UnsavedEntity,
  QueryParams,
} from '@m3ter-com/m3ter-api';

import {
  afterListPlans,
  afterRetrievePlan,
  beforeCreateOrUpdatePlan,
} from './interceptors/planInterceptors.saga';
import {
  afterDeletePricing,
  afterListPricings,
  afterRetrievePricing,
  beforeCreatePricing,
  beforeUpdatePricing,
} from './interceptors/pricingInterceptors.saga';

/**
 * Interceptors allow us to augment API request / responses. They should be used
 * very sparingly; if there's a problem with the API data shape we should be
 * seeking API changes rather than working around it here.
 *
 * We can intercept retrieve / list responses to alter the data returned and
 * create / update requests to alter the data sent.
 */

export enum InterceptorType {
  AfterDelete = 'afterDelete',
  AfterRetrieve = 'afterRetrieve',
  AfterList = 'afterList',
  BeforeCreate = 'beforeCreate',
  BeforeUpdate = 'beforeUpdate',
}

interface InterceptorConfig<T extends Entity> {
  [InterceptorType.AfterDelete]?: (
    entity: T
  ) => void | Generator<StrictEffect, void, any>;
  [InterceptorType.AfterRetrieve]?: (
    entity: T
  ) => T | Generator<StrictEffect, T, any>;
  [InterceptorType.AfterList]?: (
    entities: Array<T>,
    queryParams: QueryParams
  ) => Array<T> | Generator<StrictEffect, Array<T>, any>;
  [InterceptorType.BeforeCreate]?: (
    data: UnsavedEntity<T>
  ) => UnsavedEntity<T> | Generator<StrictEffect, UnsavedEntity<T>, any>;
  [InterceptorType.BeforeUpdate]?: (
    data: T
  ) => UnsavedEntity<T> | Generator<StrictEffect, T, any>;
}

type Interceptors = {
  [DT in DataType]?: InterceptorConfig<DataTypeToEntity[DT]>;
};

const interceptors: Interceptors = {
  [DataType.Plan]: {
    [InterceptorType.AfterList]: afterListPlans,
    [InterceptorType.AfterRetrieve]: afterRetrievePlan,
    [InterceptorType.BeforeCreate]: beforeCreateOrUpdatePlan,
    [InterceptorType.BeforeUpdate]: beforeCreateOrUpdatePlan,
  },
  [DataType.Pricing]: {
    [InterceptorType.AfterDelete]: afterDeletePricing,
    [InterceptorType.AfterList]: afterListPricings,
    [InterceptorType.AfterRetrieve]: afterRetrievePricing,
    [InterceptorType.BeforeCreate]: beforeCreatePricing,
    [InterceptorType.BeforeUpdate]: beforeUpdatePricing,
  },
};

export const getInterceptor = (dataType: DataType, type: InterceptorType) =>
  interceptors?.[dataType]?.[type];
