import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { Plan } from '@m3ter-com/m3ter-api';

import { selectSelectedProductId } from '@/store/products/products';
import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { PlanForm } from '@/components/features/pricing/PlanForm';

export const PlansCreateRoute: React.FC = () => {
  const selectedProductId = useSelector(selectSelectedProductId)!;

  const initialValues = useMemo(
    () => ({ productId: selectedProductId }),
    [selectedProductId]
  );

  return <CrudCreate<Plan> form={PlanForm} initialValues={initialValues} />;
};
