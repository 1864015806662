import React, { useMemo } from 'react';

import { Link } from '@chakra-ui/react';

import { DataType, OrganizationAdmin } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { EntityWithRelationships } from '@m3ter-com/console-core/types';

import { EntityRouteListIds } from '@/types/lists';

import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';

const searchFields = ['organizationName', 'orgId'];
const listRelationships = ['customer'];

export const OrganizationAdminListRoute: React.FC = () => {
  const { t } = useTranslation();

  const columns = useMemo<Array<ColumnDefinition<OrganizationAdmin>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: 'organizationName',
      },
      {
        id: 'orgId',
        header: t('features:organizations.orgId'),
        accessor: 'orgId',
      },
      {
        id: 'customer',
        header: t('common:customer'),
        accessor: (organization: EntityWithRelationships<OrganizationAdmin>) =>
          organization.customer ? (
            <Link
              as={CrudDetailsLink}
              dataType={DataType.Customer}
              id={organization.customerId}
            >
              {organization.customer.name}
            </Link>
          ) : (
            '-'
          ),
      },
    ],
    [t]
  );

  return (
    <CrudList<OrganizationAdmin>
      listId={EntityRouteListIds.OrganizationAdmin}
      searchFields={searchFields}
      columns={columns}
      relationships={listRelationships}
    />
  );
};
