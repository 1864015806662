import { ComponentMultiStyleConfig } from '@chakra-ui/react';

const parts = ['label', 'value'];

const keyValue: ComponentMultiStyleConfig = {
  parts,
  baseStyle: {
    label: {
      color: 'chakra-subtle-text',
      fontSize: 'sm',
      lineBreak: 'loose',
    },
    value: {
      color: 'chakra-body-text',
      lineBreak: 'loose',
      wordBreak: 'break-word',
      overflowWrap: 'break-word',
      whiteSpace: 'pre-line',
    },
  },
};

export default keyValue;
