import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { DataType, Currency, CurrencyCode } from '@m3ter-com/m3ter-api';

import { selectAllByDataType } from '@/store/data/data';

const DEFAULT_DECIMAL_PLACES = 2;
const PRECISE_DECIMAL_PLACES = 15;

const selectAllCurrencies = selectAllByDataType<Currency>(DataType.Currency);

const useCurrencies = () => {
  const currencies: Array<Currency> = useSelector(selectAllCurrencies);

  const getMaxDecimalPlaces = useCallback(
    (currency: CurrencyCode) =>
      currencies.find(({ code }) => code === currency)?.maxDecimalPlaces ??
      DEFAULT_DECIMAL_PLACES,
    [currencies]
  );

  const formatCurrency = useCallback(
    (value: number, currency: CurrencyCode, precise = false): string => {
      const locale = navigator.language;

      // The `precise` option overrides the currency decimal places.
      const maximumFractionDigits = precise
        ? PRECISE_DECIMAL_PLACES
        : getMaxDecimalPlaces(currency);

      try {
        return new Intl.NumberFormat(locale, {
          style: 'currency',
          currency,
          maximumFractionDigits,
        }).format(value);
      } catch (e) {
        return new Intl.NumberFormat(locale, {
          maximumFractionDigits,
        }).format(value);
      }
    },
    [getMaxDecimalPlaces]
  );

  return { currencies, formatCurrency, getMaxDecimalPlaces };
};

export default useCurrencies;
