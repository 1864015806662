import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { Plan } from '@m3ter-com/m3ter-api';

const suite = create('planCreate', (data: Partial<Plan>) => {
  test(
    'productId',
    i18next.t('forms:validations.common.productRequired'),
    () => {
      enforce(data.productId).isNotEmpty();
    }
  );

  test(
    'planTemplateId',
    i18next.t('forms:validations.plan.planTemplateRequired'),
    () => {
      enforce(data.planTemplateId).isNotEmpty();
    }
  );

  test('name', i18next.t('forms:validations.common.nameRequired'), () => {
    enforce(data.name).isNotEmpty();
  });

  test('code', i18next.t('forms:validations.common.codeRequired'), () => {
    enforce(data.code).isNotEmpty();
  });
});

export default suite;
