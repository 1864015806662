import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { UserGroup } from '@m3ter-com/m3ter-api';

const suite = create('userGroup', (data: Partial<UserGroup>) => {
  test('name', i18next.t('forms:validations.common.nameRequired'), () => {
    enforce(data.name).isNotEmpty();
  });
});

export default suite;
