import React from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  VStack,
  Flex,
  Heading,
  Button,
} from '@chakra-ui/react';

import { Id } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

interface RuleCalculationParams {
  calculation?: string;
  eventName?: string;
  eventId?: Id;
}

export interface NotificationRuleCalculationDetailsProps {
  calculationResult: string | undefined;
  calculationParams: RuleCalculationParams;
  isDisabled: boolean;
  onTestCalculation: () => void;
}

export const NotificationRuleCalculationDetails: React.FC<
  NotificationRuleCalculationDetailsProps
> = ({
  calculationResult,
  calculationParams,
  isDisabled,
  onTestCalculation,
}) => {
  const { t } = useTranslation();
  const { eventName, eventId, calculation } = calculationParams;

  return (
    <Card width="100%">
      <CardHeader>
        <Flex justify="space-between" alignItems="center">
          <Heading size="md" mr={4}>
            {t('features:notifications.ruleCalculation')}
          </Heading>
          <Button
            size="sm"
            onClick={onTestCalculation}
            isDisabled={!(eventName || eventId) || !calculation || isDisabled}
          >
            {t('common:testCalculation')}
          </Button>
        </Flex>
      </CardHeader>
      <CardBody>
        <VStack alignItems="start">
          <KeyValue label={t('forms:labels.event')} value={eventName ?? '-'} />
          <KeyValue
            label={t('forms:labels.calculation')}
            value={calculation ?? '-'}
          />
          <KeyValue
            label={t('common:calculationPassed')}
            value={calculationResult ?? '-'}
          />
        </VStack>
      </CardBody>
    </Card>
  );
};
