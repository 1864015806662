import React, { useMemo } from 'react';

import { Product } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds } from '@/types/lists';

import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';

const searchFields = ['name', 'code'];

export const ProductsListRoute: React.FC = () => {
  const { t } = useTranslation();

  const columns = useMemo<Array<ColumnDefinition<Product>>>(
    () => [
      { id: 'name', header: t('forms:labels.name'), accessor: 'name' },
      { id: 'code', header: t('forms:labels.code'), accessor: 'code' },
    ],
    [t]
  );

  return (
    <CrudList<Product>
      listId={EntityRouteListIds.Product}
      columns={columns}
      searchFields={searchFields}
    />
  );
};
