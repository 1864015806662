import { spawn } from 'redux-saga/effects';

import planDetailsSaga from './planDetails.saga';
import planEditorSaga from './planEditor.saga';
import planTemplatesSaga from './planTemplates.saga';
import pricingDataSaga from './pricingData.saga';
import pricingScheduleSaga from './pricingSchedule.saga';
import segmentsEditorSaga from './segmentsEditor.saga';

export default function* pricingSaga() {
  yield spawn(planDetailsSaga);
  yield spawn(planEditorSaga);
  yield spawn(planTemplatesSaga);
  yield spawn(pricingDataSaga);
  yield spawn(pricingScheduleSaga);
  yield spawn(segmentsEditorSaga);
}
