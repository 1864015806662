import React from 'react';

import { Invitation } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { InvitationDetails } from '@/components/features/access/invitation/InvitationDetails';

export const InvitationsDetailsRoute: React.FC = () => (
  <CrudDetails<Invitation> component={InvitationDetails} />
);
