import { takeEvery, StrictEffect, select, call, put } from 'redux-saga/effects';

import { submitMeasurements } from '@m3ter-com/m3ter-api';

import { extractErrorMessage } from '@/util/error';
import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';

import {
  submitMeasurements as submitMeasurementsAction,
  SubmitMeasurementsAction,
  submitMeasurementsFailure,
  submitMeasurementsSuccess,
} from './ingest';

export function* submitMeasurementsSaga(
  action: SubmitMeasurementsAction
): Generator<StrictEffect, void, any> {
  const { body } = action.payload;

  try {
    const orgId = yield select(selectCurrentOrgId);
    const response = yield call(submitMeasurements, orgId, body);
    yield put(submitMeasurementsSuccess(JSON.stringify(response)));
  } catch (error) {
    yield put(submitMeasurementsFailure(extractErrorMessage(error)));
  }
}

export default function* ingestSaga() {
  yield takeEvery(submitMeasurementsAction.type, submitMeasurementsSaga);
}
