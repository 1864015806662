import React, { useMemo } from 'react';

import { ExternalMappingConfig } from '@m3ter-com/m3ter-api';
import { SelectOption } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormField, FormSelect } from '@m3ter-com/console-core/components';

export interface ExternalMappingExternalTableFieldProps {
  externalMappingConfig: ExternalMappingConfig;
  name: string;
  externalSystem?: string;
}

export const ExternalMappingExternalTableField: React.FC<
  ExternalMappingExternalTableFieldProps
> = ({ externalMappingConfig, name, externalSystem }) => {
  const { t } = useTranslation();

  const externalTableOptions = useMemo<Array<SelectOption>>(() => {
    if (!externalSystem) {
      return [];
    }

    const externalSystemConfig = (
      externalMappingConfig.externalSystems || []
    ).find((system) => system.name === externalSystem);
    return (externalSystemConfig?.tables || []).map((tableName) => ({
      value: tableName,
      label: tableName,
    }));
  }, [externalMappingConfig, externalSystem]);

  return (
    <FormField
      isRequired
      name={name}
      label={t('forms:labels.externalTable')}
      isDisabled={!externalSystem}
      control={FormSelect}
      options={externalTableOptions}
    />
  );
};
