import type { RecentEntities } from '@/store/page/page';

enum LSKeys {
  AuthRedirect = 'M3TER__AR',
  RecentEntities = 'M3TER_REs',
  SelectedProduct = 'M3TER__LSP',
  SelectedGlobal = 'M3TER__SG',
  WhatsNewDate = 'M3TER__WND',
  PlanEditor = 'M3TER__PPE',
  Preferences = 'M3TER__PREFS',
}

export interface PlanEditorSaveState {
  selectedAggregationIds: Array<string>;
  selectedCompoundAggregationIds: Array<string>;
  selectedItemCounterIds: Array<string>;
  selectedPlanIds: Array<string>;
  selectedPlanTemplateIds: Array<string>;
}

export const getAuthRedirectPath = (): string | null => {
  return localStorage.getItem(LSKeys.AuthRedirect);
};

export const setAuthRedirectPath = (path: string): void => {
  localStorage.setItem(LSKeys.AuthRedirect, path);
};

export const removeAuthRedirectPath = (): void => {
  localStorage.removeItem(LSKeys.AuthRedirect);
};

export const setPlanEditorState = (
  state: PlanEditorSaveState,
  keySuffix: string
) => {
  localStorage.setItem(
    `${LSKeys.PlanEditor}:${keySuffix}`,
    JSON.stringify(state)
  );
};

export const getPlanEditorState = (
  keySuffix: string
): PlanEditorSaveState | null => {
  const savedState = localStorage.getItem(`${LSKeys.PlanEditor}:${keySuffix}`);
  return savedState && JSON.parse(savedState);
};

export const setLastSelectedProductId = (
  productId: string,
  keySuffix: string
): void => {
  localStorage.setItem(`${LSKeys.SelectedProduct}:${keySuffix}`, productId);
};

export const getLastSelectedProductId = (keySuffix: string): string | null =>
  localStorage.getItem(`${LSKeys.SelectedProduct}:${keySuffix}`);

export const setSelectedGlobal = (global: boolean, keySuffix: string): void =>
  localStorage.setItem(
    `${LSKeys.SelectedGlobal}:${keySuffix}`,
    JSON.stringify(global)
  );

export const getSelectedGlobal = (keySuffix: string): boolean => {
  const value = localStorage.getItem(`${LSKeys.SelectedGlobal}:${keySuffix}`);
  return value !== null && Boolean(JSON.parse(value));
};

const getPreferences = (): Record<string, any> => {
  const stored = localStorage.getItem(LSKeys.Preferences);
  return stored ? JSON.parse(stored) : {};
};

export const getPreference = (name: string): any => getPreferences()[name];

export const setPreference = (name: string, value: any) => {
  const preferences = {
    ...getPreferences(),
    [name]: value,
  };
  localStorage.setItem(LSKeys.Preferences, JSON.stringify(preferences));
};

export const getWhatsNewLastOpenedDate = (): string | null =>
  localStorage.getItem(LSKeys.WhatsNewDate);

export const setWhatsNewLastOpenedDate = (date: string) => {
  localStorage.setItem(LSKeys.WhatsNewDate, date);
};

export const getRecentEntities = (keySuffix: string): RecentEntities | null => {
  const value = localStorage.getItem(`${LSKeys.RecentEntities}:${keySuffix}`);
  return value && JSON.parse(value);
};

export const setRecentEntities = (
  keySuffix: string,
  recentlyVisited: RecentEntities
): void =>
  localStorage.setItem(
    `${LSKeys.RecentEntities}:${keySuffix}`,
    JSON.stringify(recentlyVisited)
  );
