import React, { useCallback } from 'react';

import { Box, Flex } from '@chakra-ui/react';

import { Meter } from '@m3ter-com/m3ter-api';

import { IngestFormValues } from '@/validation/ingest';
import useIngest from '@/hooks/features/meters/useIngest';
import { IngestForm } from '@/components/features/meters/IngestForm';
import { IngestSubmissionLog } from '@/components/features/meters/IngestSubmissionLog';

export interface IngestSubmitterProps {
  meter: Meter;
  accountCode?: string;
  canEditAccount?: boolean;
  onCancel?: () => void;
  onBack?: () => void;
}

export const IngestSubmitter: React.FC<IngestSubmitterProps> = ({
  meter,
  onBack,
  onCancel,
  accountCode,
  canEditAccount,
}) => {
  const { isSubmitting, submissions, submitMeasurements } = useIngest();

  const onSubmit = useCallback(
    (data: IngestFormValues) => {
      submitMeasurements(data.requestBody);
    },
    [submitMeasurements]
  );

  return (
    <Flex gap={4} width="100%">
      <Box width="50%">
        <IngestForm
          isSubmitting={isSubmitting}
          meter={meter}
          accountCode={accountCode}
          canEditAccount={canEditAccount}
          onBack={onBack}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </Box>
      <IngestSubmissionLog submissions={submissions} width="50%" />
    </Flex>
  );
};
