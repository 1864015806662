import { useEffect, useMemo } from 'react';

import { AnalyticsJobType, CurrencyCode } from '@m3ter-com/m3ter-api';

import { uniq } from '@/util/array';
import {
  getBillingBasedRecurringRevenueReportDefaultFilterState,
  getRecurringRevenueReportDefaultFilterState,
} from '@/util/reports';
import useReportData from '@/hooks/features/analytics/reports/useReportData';

export interface AccountRecurringRevenueData {
  billingBasedArr: number;
  billingBasedMrr: number;
  standardArr: number;
  standardMrr: number;
  billingBasedCurrency?: CurrencyCode;
  standardCurrency?: CurrencyCode;
}

const useAccountRecurringRevenue = (accountCode: string) => {
  const {
    isLoading: isLoadingBillingBasedData,
    response: billingBasedResponse,
    loadReportData: loadBillingBasedData,
    resetReportState: resetBillingBasedState,
  } = useReportData(AnalyticsJobType.BillingBasedRecurringRevenueReport);
  const {
    isLoading: isLoadingStandardData,
    response: standardResponse,
    loadReportData: loadStandardData,
    resetReportState: resetStandardState,
  } = useReportData(AnalyticsJobType.RecurringRevenueReport);

  useEffect(() => {
    loadBillingBasedData(
      getBillingBasedRecurringRevenueReportDefaultFilterState(accountCode),
      false
    );
    loadStandardData(
      getRecurringRevenueReportDefaultFilterState(accountCode),
      false
    );

    return () => {
      resetBillingBasedState();
      resetStandardState();
    };
  }, [
    accountCode,
    loadBillingBasedData,
    loadStandardData,
    resetBillingBasedState,
    resetStandardState,
  ]);

  const recurringRevenueData = useMemo<AccountRecurringRevenueData>(() => {
    let billingBasedCurrency: CurrencyCode | undefined;
    let billingBasedArr = 0;
    let billingBasedMrr = 0;
    if (billingBasedResponse?.values.length) {
      // Since billing-based recurring revenue is calculated using actual bills, it's
      // possible that the report deals with multiple currencies.
      // We can hope that a single account is only ever billed in once currency but we
      // can't be sure.
      // If we have multiple currenices, we can't calculate the total MRR for the account.
      const billCurrencies = uniq(
        billingBasedResponse.values.map(
          (bill) => bill.billDetails.dimensions.currency.code
        )
      );
      if (billCurrencies.length === 1) {
        billingBasedCurrency = billCurrencies[0];
        billingBasedResponse.values.forEach((bill) => {
          billingBasedMrr += bill.billMrr;
        });
        billingBasedArr = billingBasedMrr * 12;
      }
    }

    let standardCurrency: CurrencyCode | undefined;
    let standardArr = 0;
    let standardMrr = 0;
    // For the standard recurring revenue report, we get back a row per account.
    // If we don't have just the one row, something has gone wrong so we cannot grab
    // the data we need and be sure it is accurate.
    if (standardResponse?.values.length === 1) {
      standardCurrency = standardResponse.values[0].currency;
      standardArr = standardResponse.values[0].totalArr;
      standardMrr = standardResponse.values[0].totalMrr;
    }

    return {
      billingBasedArr,
      billingBasedMrr,
      billingBasedCurrency,
      standardArr,
      standardMrr,
      standardCurrency,
    };
  }, [billingBasedResponse, standardResponse]);

  return {
    isLoading: isLoadingBillingBasedData || isLoadingStandardData,
    recurringRevenueData,
  };
};

export default useAccountRecurringRevenue;
