import { call, put, StrictEffect } from 'redux-saga/effects';

import {
  DataType,
  AnalyticsJob,
  AnalyticsJobStatus,
  AnalyticsJobType,
  DataExplorerCommitmentsAccountsResponse,
  DataExplorerCommitmentsDataRequestBody,
  DataExplorerCommitmentsDataResponse,
  DataExplorerCommitmentsLineItemsRequestBody,
  DataExplorerCommitmentsObligationsResponse,
  DataExplorerExportResponse,
  PathParams,
  QueryParams,
} from '@m3ter-com/m3ter-api';
import {
  getDatesSortOrder,
  getPercentageOfDateRangePassed,
} from '@m3ter-com/console-core/utils';

import {
  DataExplorerCommitmentsDataTableRow,
  DataExplorerCommitmentsLineItemsDataTableRow,
  DataExplorerCommitmentsObligationsDataTableRow,
  DataExplorerDataType,
  FeatureCluster,
} from '@/types/data';

import { uniq } from '@/util/array';
import { extractAnalyticsJobError, extractError } from '@/util/error';
import { performDataAction, performFeatureAction } from '@/services/api';
import { getAnalyticsResult } from '@/store/features/analytics/analyticsJobs.saga';
import {
  loadDataExplorerDataFailure,
  loadDataExplorerDataSuccess,
  loadDataExplorerExportUrlFailure,
  loadDataExplorerExportUrlStarted,
  loadDataExplorerExportUrlSuccess,
} from '@/store/features/analytics/data-explorer/dataExplorer';

export function* commitmentsAccountsLoader(
  organizationId: string
): Generator<StrictEffect, void, any> {
  try {
    const response: DataExplorerCommitmentsAccountsResponse = yield call(
      performFeatureAction,
      FeatureCluster.Analytics,
      'getDataExplorerCommitmentsAccounts',
      { organizationId }
    );
    yield put(
      loadDataExplorerDataSuccess(
        DataExplorerDataType.CommitmentsAccounts,
        response
      )
    );
  } catch (error) {
    yield put(
      loadDataExplorerDataFailure(
        DataExplorerDataType.CommitmentsAccounts,
        extractError(error)
      )
    );
  }
}

export function* commitmentsDataLoader(
  organizationId: string,
  body: DataExplorerCommitmentsDataRequestBody,
  _pathParams?: PathParams,
  _queryParams?: QueryParams,
  loadExportUrl?: boolean
): Generator<StrictEffect, void, any> {
  let hasLoadedData = false;

  try {
    const response: DataExplorerCommitmentsDataResponse = yield call(
      performFeatureAction,
      FeatureCluster.Analytics,
      'getDataExplorerCommitmentsData',
      { organizationId },
      undefined,
      body
    );

    hasLoadedData = response.values.length > 0;
    const tableData = new Array<DataExplorerCommitmentsDataTableRow>();
    response.values.forEach((responseValue, responseValueIndex) => {
      const percentageTimePassed = getPercentageOfDateRangePassed(
        responseValue.startDate,
        responseValue.endDate
      );
      tableData.push({
        id: `row-${responseValueIndex}`,
        accountCode: responseValue.dimensions.account.code,
        accountName: responseValue.dimensions.account.name,
        amountCommitted: responseValue.measures.amount,
        amountPrepaid: responseValue.measures.amountPrepaid,
        amountConsumed: responseValue.usedAmount,
        amountRemaining: responseValue.remainingAmount,
        billedPercentage: responseValue.usedPercentage / 100,
        currencyCode: responseValue.units.amountPrepaid,
        endDate: responseValue.endDate,
        percentageTimePassed,
        startDate: responseValue.startDate,
      });
    });

    yield put(
      loadDataExplorerDataSuccess(DataExplorerDataType.CommitmentsData, {
        tableData,
      })
    );
  } catch (error) {
    yield put(
      loadDataExplorerDataFailure(
        DataExplorerDataType.CommitmentsData,
        extractError(error)
      )
    );
    return;
  }

  if (loadExportUrl && hasLoadedData) {
    try {
      yield put(
        loadDataExplorerExportUrlStarted(DataExplorerDataType.CommitmentsData)
      );
      const exportResponse: DataExplorerExportResponse = yield call(
        performFeatureAction,
        FeatureCluster.Analytics,
        'getDataExplorerCommitmentsDataExportLink',
        { organizationId },
        undefined,
        body
      );
      yield put(
        loadDataExplorerExportUrlSuccess(
          DataExplorerDataType.CommitmentsData,
          exportResponse.downloadUrl
        )
      );
    } catch (error) {
      yield put(
        loadDataExplorerExportUrlFailure(
          DataExplorerDataType.CommitmentsData,
          extractError(error)
        )
      );
    }
  }
}

export function* lineItemsDataLoader(
  organizationId: string,
  body: DataExplorerCommitmentsLineItemsRequestBody,
  _pathParams?: PathParams,
  _queryParams?: QueryParams,
  loadExportUrl?: boolean
): Generator<StrictEffect, void, any> {
  let analyticsJobId: string | undefined;
  let canLoadExportUrl = false;

  try {
    const analyticsJob: AnalyticsJob<AnalyticsJobType.CommitmentsLineItemsData> =
      yield call(
        getAnalyticsResult,
        AnalyticsJobType.CommitmentsLineItemsData,
        body
      );
    if (
      analyticsJob.status !== AnalyticsJobStatus.Succeeded ||
      !analyticsJob.data
    ) {
      throw new Error('Failed to load commitment line items.');
    }

    analyticsJobId = analyticsJob.id;
    canLoadExportUrl = !!analyticsJob.data?.values?.length;
    const tableData: Array<DataExplorerCommitmentsLineItemsDataTableRow> =
      analyticsJob.data.values.flatMap((responseValue) =>
        responseValue.lineItems.map((lineItem) => ({
          id: lineItem.lineItemDetails.dimensions.billLineItem.id,
          accountCode: responseValue.billDetails.dimensions.account.code,
          billDate: responseValue.billDetails.measures.billDate,
          convertedSubtotal:
            lineItem.lineItemDetails.measures.convertedSubtotal,
          currencyCode: lineItem.lineItemDetails.units.convertedSubtotal,
          endDate: responseValue.billDetails.measures.endDate,
          lineItemType: lineItem.lineItemType,
          startDate: responseValue.billDetails.measures.startDate,
        }))
      );

    yield put(
      loadDataExplorerDataSuccess(
        DataExplorerDataType.CommitmentsLineItemsData,
        { tableData }
      )
    );
  } catch (error) {
    let errorMessage: string;
    ({ analyticsJobId, canLoadExportUrl, errorMessage } =
      extractAnalyticsJobError(error));

    yield put(
      loadDataExplorerDataFailure(
        DataExplorerDataType.CommitmentsLineItemsData,
        {
          code: undefined,
          message: errorMessage,
        }
      )
    );
  }

  if (loadExportUrl && analyticsJobId && canLoadExportUrl) {
    try {
      yield put(
        loadDataExplorerExportUrlStarted(
          DataExplorerDataType.CommitmentsLineItemsData
        )
      );
      const exportAnalyticsJob: AnalyticsJob<AnalyticsJobType.CommitmentsLineItemsData> =
        yield call(
          performDataAction,
          DataType.AnalyticsJob,
          'getCsvDownloadUrl',
          { organizationId, id: analyticsJobId }
        );
      if (!exportAnalyticsJob.downloadUrl) {
        throw new Error('Failed to load line items data export URL.');
      }
      yield put(
        loadDataExplorerExportUrlSuccess(
          DataExplorerDataType.CommitmentsLineItemsData,
          exportAnalyticsJob.downloadUrl!
        )
      );
    } catch (error) {
      yield put(
        loadDataExplorerExportUrlFailure(
          DataExplorerDataType.CommitmentsLineItemsData,
          extractError(error)
        )
      );
    }
  }
}

export function* obligationsDataLoader(
  organizationId: string,
  body: DataExplorerCommitmentsDataRequestBody,
  _pathParams?: PathParams,
  _queryParams?: QueryParams,
  loadExportUrl?: boolean
): Generator<StrictEffect, void, any> {
  let hasLoadedData = false;

  try {
    const response: DataExplorerCommitmentsObligationsResponse = yield call(
      performFeatureAction,
      FeatureCluster.Analytics,
      'getDataExplorerCommitmentsObligationsData',
      { organizationId },
      undefined,
      body
    );

    hasLoadedData = response.length > 0;
    // Make sure the obligations are sorted in ascending date order
    response.sort((a, b) => getDatesSortOrder(a.dateMonth, b.dateMonth));
    const timestamps = response.map(({ dateMonth }) => dateMonth);
    // Find all unqiue account codes in the response
    const uniqueAccountCodes = uniq(
      response.flatMap((responseValue) =>
        Object.keys(responseValue.obligations)
      )
    );
    // Create a row for each unique account code, with each dateMonth
    // from the response mapped to the account's obligation for the month.
    const tableData =
      uniqueAccountCodes.map<DataExplorerCommitmentsObligationsDataTableRow>(
        (accountCode, accountCodeIndex) => {
          const monthlyObligationAmounts = Object.fromEntries(
            response.map((responseValue) => {
              const { dateMonth, obligations } = responseValue;
              const totalRemaining = obligations[accountCode] ?? 0;

              return [dateMonth, totalRemaining];
            })
          );

          return {
            id: `row-${accountCodeIndex}`,
            accountCode,
            ...monthlyObligationAmounts,
          };
        }
      );

    yield put(
      loadDataExplorerDataSuccess(
        DataExplorerDataType.CommitmentsObligationsData,
        {
          timestamps,
          tableData,
        }
      )
    );
  } catch (error) {
    yield put(
      loadDataExplorerDataFailure(
        DataExplorerDataType.CommitmentsObligationsData,
        extractError(error)
      )
    );
    return;
  }

  if (loadExportUrl && hasLoadedData) {
    try {
      yield put(
        loadDataExplorerExportUrlStarted(
          DataExplorerDataType.CommitmentsObligationsData
        )
      );
      const exportResponse: DataExplorerExportResponse = yield call(
        performFeatureAction,
        FeatureCluster.Analytics,
        'getDataExplorerCommitmentsObligationsDataExportLink',
        { organizationId },
        undefined,
        body
      );
      yield put(
        loadDataExplorerExportUrlSuccess(
          DataExplorerDataType.CommitmentsObligationsData,
          exportResponse.downloadUrl
        )
      );
    } catch (error) {
      yield put(
        loadDataExplorerExportUrlFailure(
          DataExplorerDataType.CommitmentsObligationsData,
          extractError(error)
        )
      );
    }
  }
}
