import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Account } from '@m3ter-com/m3ter-api';

import {
  loadAccountPlans,
  reset,
  selectAccountPlansError,
  selectActiveAndPendingPlanAccountPlans,
  selectActiveAndPendingPlanGroupAccountPlans,
  selectIsAccountPlansLoading,
  selectPreviousPlanAccountPlans,
  selectPreviousPlanGroupAccountPlans,
} from '@/store/features/accounts/accountPlans';

const useAccountPlans = (account: Account) => {
  const dispatch = useDispatch();

  const accountId = account.id;
  useEffect(() => {
    dispatch(loadAccountPlans(accountId));

    return () => {
      dispatch(reset());
    };
  }, [accountId, dispatch]);

  const isLoading = useSelector(selectIsAccountPlansLoading);
  const error = useSelector(selectAccountPlansError);
  const activeAndPendingPlanAccountPlans = useSelector(
    selectActiveAndPendingPlanAccountPlans
  );
  const activeAndPendingPlanGroupAccountPlans = useSelector(
    selectActiveAndPendingPlanGroupAccountPlans
  );
  const previousPlanAccountPlans = useSelector(selectPreviousPlanAccountPlans);
  const previousPlanGroupAccountPlans = useSelector(
    selectPreviousPlanGroupAccountPlans
  );

  return {
    isLoading,
    error,
    activeAndPendingPlanAccountPlans,
    activeAndPendingPlanGroupAccountPlans,
    previousPlanAccountPlans,
    previousPlanGroupAccountPlans,
  };
};

export default useAccountPlans;
