/* eslint-disable no-console */
import React, {
  createContext,
  useCallback,
  useMemo,
  PropsWithChildren,
  useContext,
} from 'react';

import { subSeconds } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import {
  getCleanDateInstance,
  longDateFormatKey,
  longDateTimeFormatKey,
  monthYearFormatKey,
  shortDateFormatKey,
} from '@m3ter-com/console-core/utils';

interface TimezoneContextValue {
  timeZone: string;
}

const TimezoneContext = createContext<TimezoneContextValue | null>(null);

export const TimezoneContextProvider: React.FC<
  PropsWithChildren<TimezoneContextValue>
> = ({ children, timeZone }) => {
  const value = useMemo<TimezoneContextValue>(
    () => ({
      timeZone,
    }),
    [timeZone]
  );

  return (
    <TimezoneContext.Provider value={value}>
      {children}
    </TimezoneContext.Provider>
  );
};

const useTimezoneContext = () => useContext(TimezoneContext)!;

const useDateFormatter = () => {
  const { timeZone } = useTimezoneContext();

  const formatDateRangeWithExclusiveEndDate = useCallback(
    (startDate: string | Date, endDate: string | Date) => {
      try {
        const adjustedEndDate = subSeconds(
          getCleanDateInstance(endDate, timeZone),
          1
        );

        return `${formatInTimeZone(
          getCleanDateInstance(startDate, timeZone),
          timeZone,
          longDateFormatKey
        )} - ${formatInTimeZone(adjustedEndDate, timeZone, longDateFormatKey)}`;
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    [timeZone]
  );

  const toLongDate = useCallback(
    (date: string | Date) => {
      try {
        return formatInTimeZone(
          getCleanDateInstance(date, timeZone),
          timeZone,
          longDateFormatKey
        );
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    [timeZone]
  );
  const toLongDateTime = useCallback(
    (date: string | Date) => {
      try {
        return formatInTimeZone(
          getCleanDateInstance(date, timeZone),
          timeZone,
          longDateTimeFormatKey
        );
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    [timeZone]
  );

  const toMonthYear = useCallback(
    (date: string | Date) => {
      try {
        return formatInTimeZone(
          getCleanDateInstance(date, timeZone),
          timeZone,
          monthYearFormatKey
        );
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    [timeZone]
  );

  const toShortDate = useCallback(
    (date: string | Date) => {
      try {
        return formatInTimeZone(
          getCleanDateInstance(date, timeZone),
          timeZone,
          shortDateFormatKey
        );
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    [timeZone]
  );

  return {
    formatDateRangeWithExclusiveEndDate,
    timeZone,
    toLongDate,
    toLongDateTime,
    toMonthYear,
    toShortDate,
  };
};

export default useDateFormatter;
