import { useMemo } from 'react';

import { DataType, Id, PermissionPolicy } from '@m3ter-com/m3ter-api';

import {
  PermissionDataType,
  permissionDataTypeConfigMap,
} from '@/types/data/permissions';

import useEntityList, {
  UseEntityListOptions,
} from '@/hooks/data/crud/useEntityList';
import useOrg from '@/hooks/data/crud/useOrg';

export interface UseEntityPermissionPolicyListProps<
  DT extends PermissionDataType
> {
  dataType: DT;
  id?: Id;
}

const useEntityPermissionPolicyList = <DT extends PermissionDataType>({
  dataType,
  id,
}: UseEntityPermissionPolicyListProps<DT>) => {
  const { currentOrgId: organizationId } = useOrg();

  const { listAction, listId } = permissionDataTypeConfigMap[dataType];

  const pathParams = useMemo(
    () =>
      dataType === DataType.SupportAccess
        ? { organizationId }
        : { organizationId, id },
    [dataType, id, organizationId]
  );

  const permissionPolicylistOptions = useMemo<UseEntityListOptions>(
    () => ({ actionName: listAction, pathParams }),
    [listAction, pathParams]
  );

  const {
    allEntities: permissionPolicies,
    error: permissionPoliciesError,
    isLoading: permissionPoliciesLoading,
  } = useEntityList<PermissionPolicy>(
    DataType.PermissionPolicy,
    listId,
    permissionPolicylistOptions
  );

  return {
    permissionPolicies,
    permissionPoliciesError,
    permissionPoliciesLoading,
  };
};

export default useEntityPermissionPolicyList;
