import React, { PropsWithChildren, ReactNode } from 'react';

import {
  chakra,
  Box,
  Link,
  useMultiStyleConfig,
  forwardRef,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  LinkProps,
} from '@chakra-ui/react';

interface NavigationIconProps {
  icon: ReactNode;
}

const NavigationIcon: React.FC<NavigationIconProps> = ({ icon }) => {
  const styles = useMultiStyleConfig('Navigation', {});

  return <chakra.span sx={styles.icon}>{icon}</chakra.span>;
};

export interface NavigationProps {}

export const Navigation: React.FC<PropsWithChildren<NavigationProps>> = ({
  children,
}) => {
  return (
    <Accordion allowToggle role="menu">
      {children}
    </Accordion>
  );
};

export interface NavigationItemProps extends LinkProps {
  active?: boolean;
  icon?: ReactNode;
}

export const NavigationItem = forwardRef<NavigationItemProps, 'a'>(
  ({ active, icon, children, ...rest }, ref) => {
    const styles = useMultiStyleConfig('Navigation', { active });

    return (
      <Link ref={ref} {...rest} sx={styles.link} role="menuitem">
        {icon && <NavigationIcon icon={icon} />}
        {children}
      </Link>
    );
  }
);

export interface NavigationHeaderProps {}

export const NavigationHeader: React.FC<
  PropsWithChildren<NavigationHeaderProps>
> = ({ children }) => {
  const styles = useMultiStyleConfig('Navigation', {});

  return <chakra.div sx={styles.header}>{children}</chakra.div>;
};

export interface NavigationDividerProps {}

export const NavigationDivider: React.FC<NavigationDividerProps> = () => {
  const styles = useMultiStyleConfig('Navigation', {});

  return <chakra.hr sx={styles.divider} />;
};

export interface NavigationGroupProps {
  title: string;
  icon?: ReactNode;
}

export const NavigationGroup: React.FC<
  PropsWithChildren<NavigationGroupProps>
> = ({ title, icon, children }) => {
  const styles = useMultiStyleConfig('Navigation', {});

  return (
    <AccordionItem>
      <AccordionButton sx={styles.groupTitle}>
        {icon && <NavigationIcon icon={icon} />}
        <Box flex={1} textAlign="left">
          {title}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel sx={styles.groupPanel}>{children}</AccordionPanel>
    </AccordionItem>
  );
};
