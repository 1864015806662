import React from 'react';

import { DataType, Destination } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { createCrudRoute } from '@/routes/crud';
import { DestinationsRoute } from '@/components/routes/integrations/destinations/DestinationsRoute';
import { DestinationsListRoute } from '@/components/routes/integrations/destinations/DestinationsListRoute';
import { DestinationCreateRoute } from '@/components/routes/integrations/destinations/DestinationCreateRoute';
import { DestinationEditRoute } from '@/components/routes/integrations/destinations/DestinationEditRoute';
import { DestinationDetailsRoute } from '@/components/routes/integrations/destinations/DestinationDetailsRoute';

export default (): NamedRoute =>
  createCrudRoute<Destination>({
    path: 'destinations',
    dataType: DataType.Destination,
    element: <DestinationsRoute />,
    list: <DestinationsListRoute />,
    create: <DestinationCreateRoute />,
    edit: <DestinationEditRoute />,
    details: <DestinationDetailsRoute />,
  });
