import React, { useMemo } from 'react';

import { useWatch } from 'react-hook-form';

import { ExternalMappingConfig } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import { createStepOne } from '@/validation/externalMapping';
import { ExternalMappingEntityTypeField } from '@/components/features/external-mappings/ExternalMappingEntityTypeField';
import { ExternalMappingExternalSystemField } from '@/components/features/external-mappings/ExternalMappingExternalSystemField';
import { ExternalMappingExternalTableField } from '@/components/features/external-mappings/ExternalMappingExternalTableField';

import { ExternalMappingCreateFormStepOneValues } from './types';

interface ExternalMappingCreateFormStepOneFieldsProps {
  externalMappingConfig: ExternalMappingConfig;
}

export interface ExternalMappingCreateFormStepOneProps
  extends BaseFormProps<ExternalMappingCreateFormStepOneValues> {
  externalMappingConfig: ExternalMappingConfig;
}

const ExternalMappingCreateFormStepOneFields: React.FC<
  ExternalMappingCreateFormStepOneFieldsProps
> = ({ externalMappingConfig }) => {
  const selectedExternalSystem: string | undefined = useWatch({
    name: 'externalSystem',
  });

  return (
    <React.Fragment>
      <ExternalMappingEntityTypeField
        externalMappingConfig={externalMappingConfig}
        name="m3terEntity"
      />
      <ExternalMappingExternalSystemField
        externalMappingConfig={externalMappingConfig}
        name="externalSystem"
      />
      <ExternalMappingExternalTableField
        externalMappingConfig={externalMappingConfig}
        externalSystem={selectedExternalSystem}
        name="externalTable"
      />
    </React.Fragment>
  );
};

export const ExternalMappingCreateFormStepOne: React.FC<
  ExternalMappingCreateFormStepOneProps
> = ({ externalMappingConfig, initialValues, onCancel, onSave }) => {
  const { t } = useTranslation();

  const defaultedInitialValues = useMemo(
    () => ({ ...initialValues }),
    [initialValues]
  );

  return (
    <Form
      initialValues={defaultedInitialValues}
      onSubmit={onSave}
      validationSchema={createStepOne}
    >
      <FormStack>
        <FormSection
          heading={t('forms:labels.entitySettings', {
            entityName: t('common:externalMapping'),
          })}
        >
          <ExternalMappingCreateFormStepOneFields
            externalMappingConfig={externalMappingConfig}
          />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={t('common:next')}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
