/* eslint-disable import/no-import-module-exports */
// Use of module.hot causes the import rules to assume this is a CJS file.
import { Reducer, ReducersMapObject, Store } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware, { Saga } from 'redux-saga';
import { captureException } from '@sentry/react';

import { isLocal, isSentryEnabled } from '@/config';

import createRootReducer, { LazyState } from './rootReducer';
import rootSaga, { Context } from './rootSaga';

export interface InjectableStore extends Store {
  injectReducer: (key: keyof LazyState, reducer: Reducer) => void;
  injectSaga: (key: keyof LazyState, saga: Saga) => void;
}

const setupStore = (sagaContext: Context) => {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error, { sagaStack }) => {
      if (isLocal()) {
        console.error(error); // eslint-disable-line no-console
        console.error(sagaStack); // eslint-disable-line no-console
      }
      if (isSentryEnabled()) {
        captureException(error, (scope) => {
          scope.addBreadcrumb({
            type: 'error',
            level: 'error',
            category: 'exception',
            message: sagaStack,
          });
          return scope;
        });
      }
    },
  });

  const store = {
    ...configureStore({
      reducer: createRootReducer(),
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(sagaMiddleware),
    }),
    injectReducer(key: keyof LazyState, reducer: Reducer) {
      if (!this.lazyReducers[key]) {
        this.lazyReducers[key] = reducer;
        this.replaceReducer(createRootReducer(this.lazyReducers));
      }
    },
    injectSaga(key: keyof LazyState, saga: Saga) {
      if (!this.lazySagas.has(key)) {
        this.lazySagas.add(key);
        sagaMiddleware.run(saga, sagaContext);
      }
    },
    lazyReducers: {} as ReducersMapObject<LazyState>,
    lazySagas: new Set<keyof LazyState>(),
  };

  sagaMiddleware.run(rootSaga, sagaContext);

  if (isLocal() && module.hot) {
    module.hot.accept('./rootReducer', () => {
      const newCreateRootReducer = require('./rootReducer').default; // eslint-disable-line global-require
      store.replaceReducer(newCreateRootReducer(store.lazyReducers));
    });
  }

  return store;
};

export default setupStore;
