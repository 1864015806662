import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DataType,
  Aggregation,
  AggregationSegment,
  CompoundAggregation,
  Id,
  Counter,
  CounterPricing,
  Plan,
  PlanTemplate,
  Pricing,
} from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';

import { createSelectById, createSelectByIds } from '@/store/data/data';

export interface PricingScheduleState {
  isLoading: boolean;
  error?: AppError;
  pricingIds?: Array<Id>;
  itemCounterPricingIds?: Array<Id>;
  planId?: Id;
  planTemplateId?: Id;
  aggregationId?: Id;
  compoundAggregationId?: Id;
  counterId?: Id;
  segment?: AggregationSegment;
}

interface LoadPricingSchedulePayload {
  planId?: Id;
  planTemplateId?: Id;
  aggregationId?: Id;
  compoundAggregationId?: Id;
  counterId?: Id;
  segment?: AggregationSegment;
}

export type LoadPricingScheduleAction =
  PayloadAction<LoadPricingSchedulePayload>;

interface LoadPricingScheduleSuccessPayload {
  pricingIds: Array<Id>;
  itemCounterPricingIds: Array<Id>;
  planId?: Id;
  planTemplateId?: Id;
  aggregationId?: Id;
  compoundAggregationId?: Id;
  counterId?: Id;
  segment?: AggregationSegment;
}

export type LoadPricingScheduleSuccessAction =
  PayloadAction<LoadPricingScheduleSuccessPayload>;

export type LoadPricingScheduleFailureAction = PayloadAction<
  undefined,
  string,
  never,
  AppError
>;

const name = 'features/pricing/pricingSchedule';

const initialState: PricingScheduleState = {
  isLoading: false,
};

const pricingScheduleSlice = createSlice({
  name,
  initialState,
  reducers: {
    loadPricingSchedule: {
      reducer: (
        state: PricingScheduleState,
        _action: LoadPricingScheduleAction
      ) => {
        state.isLoading = true;
        state.error = undefined;
      },
      prepare: (
        planId?: Id,
        planTemplateId?: Id,
        aggregationId?: Id,
        compoundAggregationId?: Id,
        counterId?: Id,
        segment?: AggregationSegment
      ) => ({
        payload: {
          planId,
          planTemplateId,
          aggregationId,
          compoundAggregationId,
          counterId,
          segment,
        },
      }),
    },
    loadPricingScheduleSuccess: {
      reducer: (
        state: PricingScheduleState,
        action: LoadPricingScheduleSuccessAction
      ) => {
        state.isLoading = false;
        state.pricingIds = action.payload.pricingIds;
        state.itemCounterPricingIds = action.payload.itemCounterPricingIds;
        state.planId = action.payload.planId;
        state.planTemplateId = action.payload.planTemplateId;
        state.aggregationId = action.payload.aggregationId;
        state.compoundAggregationId = action.payload.compoundAggregationId;
        state.counterId = action.payload.counterId;
        state.segment = action.payload.segment;
      },
      prepare: (
        pricingIds: Array<Id>,
        itemCounterPricingIds: Array<Id>,
        planId?: Id,
        planTemplateId?: Id,
        aggregationId?: Id,
        compoundAggregationId?: Id,
        counterId?: Id,
        segment?: AggregationSegment
      ) => ({
        payload: {
          pricingIds,
          itemCounterPricingIds,
          planId,
          planTemplateId,
          aggregationId,
          compoundAggregationId,
          counterId,
          segment,
        },
      }),
    },
    loadPricingScheduleFailure: {
      reducer: (
        state: PricingScheduleState,
        action: LoadPricingScheduleFailureAction
      ) => {
        state.isLoading = false;
        state.error = action.error;
      },
      prepare: (error: AppError) => ({
        payload: undefined,
        error,
      }),
    },
    reset: () => initialState,
  },
});

export const {
  loadPricingSchedule,
  loadPricingScheduleSuccess,
  loadPricingScheduleFailure,
  reset,
} = pricingScheduleSlice.actions;

// Selectors

const selectPricingScheduleState = (state: {
  features: { pricing: { pricingSchedule: PricingScheduleState } };
}): PricingScheduleState => state.features.pricing.pricingSchedule;

export const selectIsLoading = createSelector(
  selectPricingScheduleState,
  (state) => state.isLoading
);

export const selectError = createSelector(
  selectPricingScheduleState,
  (state) => state.error
);

export const selectPricingIds = createSelector(
  selectPricingScheduleState,
  (state) => state.pricingIds
);

export const selectPricings = createSelector(
  selectPricingIds,
  createSelectByIds<Pricing>(DataType.Pricing),
  (pricingIds, selectByIds) => selectByIds(pricingIds)
);

export const selectItemCounterPricingIds = createSelector(
  selectPricingScheduleState,
  (state) => state.itemCounterPricingIds
);

export const selectItemCounterPricings = createSelector(
  selectItemCounterPricingIds,
  createSelectByIds<CounterPricing>(DataType.CounterPricing),
  (itemCounterPricingIds, selectByIds) => selectByIds(itemCounterPricingIds)
);

export const selectPlanId = createSelector(
  selectPricingScheduleState,
  (state) => state.planId
);

export const selectPlan = createSelector(
  selectPlanId,
  createSelectById<Plan>(DataType.Plan),
  (planId, selectById) => selectById(planId)
);

export const selectPlanTemplateId = createSelector(
  selectPricingScheduleState,
  (state) => state.planTemplateId
);

export const selectPlanTemplate = createSelector(
  selectPlanTemplateId,
  createSelectById<PlanTemplate>(DataType.PlanTemplate),
  (planTemplateId, selectById) => selectById(planTemplateId)
);

export const selectAggregationId = createSelector(
  selectPricingScheduleState,
  (state) => state.aggregationId
);

export const selectAggregation = createSelector(
  selectAggregationId,
  createSelectById<Aggregation>(DataType.Aggregation),
  (aggregationId, selectById) => selectById(aggregationId)
);

export const selectCompoundAggregationId = createSelector(
  selectPricingScheduleState,
  (state) => state.compoundAggregationId
);

export const selectCompoundAggregation = createSelector(
  selectCompoundAggregationId,
  createSelectById<CompoundAggregation>(DataType.CompoundAggregation),
  (compoundAggregationId, selectById) => selectById(compoundAggregationId)
);
export const selectItemCounterId = createSelector(
  selectPricingScheduleState,
  (state) => state.counterId
);

export const selectItemCounter = createSelector(
  selectItemCounterId,
  createSelectById<Counter>(DataType.Counter),
  (counterId, selectById) => selectById(counterId)
);

export default pricingScheduleSlice.reducer;
