import React from 'react';

import { FaDumpsterFire } from 'react-icons/fa';
import { AlertIcon } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert } from '@m3ter-com/ui-components';

import { WidgetBody } from '@/components/widgets/WidgetBody';

export const LazyLoadFailureWidget: React.FC = () => {
  const { t } = useTranslation();

  return (
    <WidgetBody>
      <Alert
        status="error"
        width="100%"
        alertIcon={<AlertIcon as={FaDumpsterFire} />}
      />

      {t('features:widgets.componentLoadFailure')}
    </WidgetBody>
  );
};
