import { useCallback, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';

import { DataType, Id, PermissionPolicy } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { PermissionDataType } from '@/types/data/permissions';

import { NotificationDefinition } from '@/store/store';
import {
  addEntityLink,
  removeEntityLink,
  selectIsLinkingEntity,
  selectIsUnlinkingEntity,
} from '@/store/utils/linkEntity';

import useEntityPermissionPolicyList from './useEntityPermissionPolicyList';

export interface UseEntityPermissionControlProps<
  DT extends PermissionDataType
> {
  dataType: DT;
  id?: Id;
}

const useEntityPermissionPolicyControl = <DT extends PermissionDataType>({
  dataType,
  id,
}: UseEntityPermissionControlProps<DT>) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedPermissionIds, setSelectedPermissionIds] = useState<Array<Id>>(
    []
  );
  const {
    isOpen: isPermissionsModalOpen,
    onClose: onClosePermissionsModal,
    onOpen: onOpenPermissionsModal,
  } = useDisclosure();

  const { permissionPolicies } = useEntityPermissionPolicyList({
    dataType,
    id,
  });

  const isPermissionDisabled = useCallback(
    (item: PermissionPolicy) =>
      permissionPolicies.some((permission) => permission.id === item.id),
    [permissionPolicies]
  );

  const permissionPoliciesAddedSuccessNotification =
    useMemo<NotificationDefinition>(
      () => ({
        type: 'success',
        message: t('notifications:permissionPoliciesAddedSuccess'),
        removeAfter: 5000,
      }),
      [t]
    );
  const permissionPoliciesRemovedSuccessNotification =
    useMemo<NotificationDefinition>(
      () => ({
        type: 'success',
        message: t('notifications:permissionPoliciesRemovedSuccess'),
        removeAfter: 5000,
      }),
      [t]
    );

  const isAddingPermissionsToEntitySelector = useMemo(
    () => selectIsLinkingEntity(dataType, DataType.PermissionPolicy),
    [dataType]
  );
  const isAddingPermissionsToEntity = useSelector(
    isAddingPermissionsToEntitySelector
  );

  const isRemovingPermissionFromEntitySelector = useMemo(
    () => selectIsUnlinkingEntity(DataType.PermissionPolicy, dataType),
    [dataType]
  );
  const isRemovingPermissionFromEntity = useSelector(
    isRemovingPermissionFromEntitySelector
  );

  const onAddPermissionsToEntity = useCallback(
    (permissionPolicyIds: Array<Id>) => {
      if (permissionPolicyIds.length > 0) {
        dispatch(
          addEntityLink(
            dataType,
            id ?? null,
            DataType.PermissionPolicy,
            permissionPolicyIds,
            permissionPoliciesAddedSuccessNotification
          )
        );
      }
      onClosePermissionsModal();
    },
    [
      dataType,
      dispatch,
      id,
      onClosePermissionsModal,
      permissionPoliciesAddedSuccessNotification,
    ]
  );

  const onRemovePermissionsFromEntity = useCallback(() => {
    if (selectedPermissionIds.length > 0) {
      dispatch(
        removeEntityLink(
          DataType.PermissionPolicy,
          selectedPermissionIds,
          dataType,
          id ?? null,
          permissionPoliciesRemovedSuccessNotification
        )
      );
    }
    setSelectedPermissionIds([]);
  }, [
    dataType,
    dispatch,
    id,
    permissionPoliciesRemovedSuccessNotification,
    selectedPermissionIds,
  ]);

  return {
    isAddingPermissionsToEntity,
    isPermissionDisabled,
    isPermissionsModalOpen,
    isRemovingPermissionFromEntity,
    onAddPermissionsToEntity,
    onClosePermissionsModal,
    onOpenPermissionsModal,
    onRemovePermissionsFromEntity,
    selectedPermissionIds,
    setSelectedPermissionIds,
  };
};

export default useEntityPermissionPolicyControl;
